import React from 'react';
import {MedicineTypeFkSubscription, useMedicineTypeFkSubscription, useServiceTypeFkSubscription, ServiceTypeFkSubscription, useMedicineTypeModelFkQuery, useServiceTypeModelFkQuery} from 'gql'
import { ListItemText } from '@mui/material';

import 'moment/locale/ru';
import { useErrorAndLoadChecking } from 'hex/components/material/hooks';
import { ForeignKeyProps } from 'hex/components/material/operations/fields/foreign_key_modal';

// Medicines:
export type MedicineFkArray = MedicineTypeFkSubscription["Medicine"];
export type MedicineFk = MedicineFkArray[number];

function RenderMedicineTypeModel(model: MedicineFk) : React.ReactNode
{
    return (
        <ListItemText primary={`${model.name} - ${model.price} руб.`} />
    );
}

function RenderSelectedMedicineTypeModel(model: MedicineFk) : string
{
    return model.name;
}

function useMedicineTypeSearch(searchText: string) : Array<MedicineFk> | undefined
{
    const {data, loading, error} = useMedicineTypeFkSubscription({variables: {searchText: `%${searchText}%`}});
    useErrorAndLoadChecking({loading, error});

    if (data !== undefined)
        return data.Medicine;

    return undefined;
}

function useMedicineModel(id: any) : MedicineFk | undefined
{
    const idCalc = id !== 0 && id !== "" ? id as number : 0;

    const {data, loading, error} = useMedicineTypeModelFkQuery({variables: {id: idCalc}, fetchPolicy: 'network-only'});
    useErrorAndLoadChecking({loading, error});

    if ((data !== undefined) && (data.Medicine_by_pk))
        return data.Medicine_by_pk;

    return undefined;
}

export const MedicineFkData : Omit<ForeignKeyProps<MedicineFk>, "label" | "value" | "onChange" | "disabled"> = {
    renderModel: RenderMedicineTypeModel,
    renderSelectedModel: RenderSelectedMedicineTypeModel,
    useSearch: useMedicineTypeSearch,
    useModel: useMedicineModel
}

// Services:
export type ServiceFkArray = ServiceTypeFkSubscription["Service"];
export type ServiceFk = ServiceFkArray[number];

function RenderServiceTypeModel(model: ServiceFk) : React.ReactNode 
{
    return (
        <ListItemText primary={model.name} />
    );
}

function RenderSelectedServiceTypeModel(model: ServiceFk) : string
{
    return model.name;
}

function useServiceTypeSearch(searchText: string) : Array<ServiceFk> | undefined
{
    const {data, loading, error} = useServiceTypeFkSubscription({variables: {searchText: `%${searchText}%`}});
    useErrorAndLoadChecking({loading, error});

    if (data !== undefined)
        return data.Service;

    return undefined;
}

function useServiceModel(id: any) : ServiceFk | undefined
{
    const idCalc = id !== 0 && id !== "" ? id as number : 0;

    const {data, loading, error} = useServiceTypeModelFkQuery({variables: {id: idCalc}, fetchPolicy: 'network-only'});
    useErrorAndLoadChecking({loading, error});

    if ((data !== undefined) && (data.Service_by_pk))
        return data.Service_by_pk;

    return undefined;
}

export const ServiceFkData : Omit<ForeignKeyProps<ServiceFk>, "label" | "value" | "onChange" | "disabled"> = {
    renderModel: RenderServiceTypeModel,
    renderSelectedModel: RenderSelectedServiceTypeModel,
    useSearch: useServiceTypeSearch,
    useModel: useServiceModel
}