import React from 'react';
import BrowserCache from ".";

function useSavedValue<T>(name: string, defaultValue: T) : [ T, (value: T) => void]
{
    var cache = React.useMemo(() => new BrowserCache("SavedDataCache-" + name), [name]);
    var savedValue = React.useMemo(() => cache.getValue(), [cache]) as T | undefined;
    var [state, setState] = React.useState<T>(savedValue === undefined ? defaultValue : savedValue);

    return [
        state,
        (value: T) => {
            cache.setValue(value);
            setState(value);
        },
    ]
}

export default useSavedValue;