import React from 'react';
import Modal from 'hex/components/material/modal'
import Loading from 'hex/components/loading'
import {useDeleteUserMutation} from 'gql'
import { Typography } from '@mui/material';

// Props:
type DeleteProps = {
    open: boolean;
    close: () => void;
    id: string;
    name: string;
};


const Delete: React.FunctionComponent<DeleteProps> = ({open, close, id, name}) => {
    const [updateUserMutation, { loading }] = useDeleteUserMutation({
        variables: {
            ID: id,
        },
    });

    const DeleteAction = () => {
        updateUserMutation().then(() => {
            close();
        });
    }

    return (
        <>
            <Loading loading={loading} />
            <Modal
                open={open}
                title='Удаление'
                contentTitle={`Удаление пользователя`}
                closeButtonText='Отмена'
                closeButtonAction={() => {
                    close();
                }}
                actionButtonText='Удалить'
                actionButtonAction={DeleteAction}
                maxWidth='xl'
            >
                <Typography variant='body1'>
                    {`Вы действительно уверены, что хотите удалить пользователя ${name}?`}
                </Typography>
            </Modal>
        </>
    )
}

export default Delete;