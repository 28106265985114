import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


// Props:
type ModalProps = {
    open: boolean;
    title: string;
    contentTitle: string;
    closeButtonText: string;
    closeButtonAction: () => void;
    actionButtonText?: string;
    actionButtonAction?: () => void;
    actionButtonEnabled?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    fullScreen?: boolean;
    children?: React.ReactNode;

    secondActionButtonAction?: () => void;
    secondActionButtonText?: string;
    secondActionButtonEnabled?: boolean;
};


const Modal : React.FunctionComponent<ModalProps> = ({open, fullScreen, title, contentTitle, closeButtonText, closeButtonAction, actionButtonText, actionButtonAction, children, maxWidth, actionButtonEnabled, secondActionButtonAction, secondActionButtonText, secondActionButtonEnabled}) => 
{
    return (
        <Dialog open={open} onClose={closeButtonAction} maxWidth={maxWidth} fullScreen={fullScreen} TransitionComponent={Transition} disableEnforceFocus>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent style={{minWidth: "600px"}}>
                <DialogContentText>{contentTitle}</DialogContentText>
                {children}
                <Divider />
            </DialogContent>
            <DialogActions>
                {secondActionButtonText && secondActionButtonAction && 
                    <Button variant="contained" onClick={secondActionButtonAction} color="primary" disabled={secondActionButtonEnabled === undefined ? false : !secondActionButtonEnabled}>{secondActionButtonText}</Button>
                }
                {actionButtonText && actionButtonAction && 
                    <Button variant="contained" onClick={actionButtonAction} color="primary" disabled={actionButtonEnabled === undefined ? false : !actionButtonEnabled}>{actionButtonText}</Button>
                }
                <Button variant="contained" onClick={closeButtonAction} color="secondary">{closeButtonText}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default Modal;