import { Box, CircularProgress } from '@mui/material';
import { useGetPetForEditQuery } from 'gql';
import { useErrorAndLoadChecking } from 'hex/components/material/hooks';
import Modal from 'hex/components/material/modal';
import { useValidator } from 'hex/hooks/validator';
import { defaultModelWithClient, updateMutation as useUpdateMutation, validations, renderFields } from 'pages/pets/operations';
import { TablePet } from 'pages/pets/types';
import React from 'react'

type PetEditProps = {
    pet_id: number;
    client_id: number;
    onClose: () => void;
}

const PetEdit : React.FC<PetEditProps> = ({pet_id, client_id, onClose}) => {
    const { data, loading, error } = useGetPetForEditQuery({fetchPolicy: 'network-only', variables: {
        id: pet_id,
    }});

    const [pet, setPet] = React.useState<TablePet>(defaultModelWithClient(client_id));
    
    const [updateMutation, updateMutationData] = useUpdateMutation(pet);
    const [validation, validator, modelValidation] = useValidator<TablePet>(validations);

    React.useEffect(() => {
        if ((data !== undefined) && (data.Pet.length !== 0))
        {
            setPet(data.Pet[0]);
        }
    }, [data, setPet]);

    useErrorAndLoadChecking({loading, error});
    useErrorAndLoadChecking(updateMutationData);

    const setModelField = React.useCallback((field: keyof TablePet) => (value: any) => {
        setPet(oldModel => {
            var copy = {...oldModel};

            copy[field] = value as never;

            return copy;
        });
    }, [setPet]);

    const onSave = async () => {
        if (modelValidation(pet))
        {
            await updateMutation();
            onClose();
        }
    }

    const renderBody = () => {
        if (data === undefined)
            return (
                <>
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>
                </>
            );
        return (
            <>
                {renderFields(pet, setModelField, validator, validation, false, true)}
            </>
        )
    }

    return (
        <Modal open title='Редактирование клиента' contentTitle='Редактирование: ' closeButtonText='Отмена' closeButtonAction={onClose} actionButtonText='Сохранить' actionButtonAction={onSave} >
            {renderBody()}
        </Modal>
    )
}

export default PetEdit;