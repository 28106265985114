import { ScopedCssBaseline } from "@mui/material";
import React from "react";

import './printer.css'

type ComponentToPrintProps = {
    text: string;
    styles?: string;
};

export const ComponentToPrint = React.forwardRef<HTMLDivElement, ComponentToPrintProps>((props, ref) => {
    return (
        <div style={{ display: "none" }}>
            <ScopedCssBaseline>
                <div ref={ref}>
                    <div style={{margin: 25}} className="hex-component-printer" dangerouslySetInnerHTML={{
                        __html: props.text
                    }}>
                    </div>
                </div>
            </ScopedCssBaseline>
        </div>
    );
});