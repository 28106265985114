import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Paper, Slide, TextField, Typography, useTheme } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import 'moment/locale/ru';

import {useGetReceptionMedicinesAndServicesQuery, useGetMedicineUnitsQuery, useSaveVisitReceptionMutation} from 'gql'
import { useErrorChecking } from 'hex/components/material/hooks';
import Loading from 'hex/components/loading';
import ListData from 'pages/index/doctor/list_data';
import ListDataMed from 'pages/index/doctor/list_data_med'
import { Reception } from './types';
import { MedicinesReceptionVisitType, ServicesReceptionVisitType } from 'pages/index/doctor/types';
import { useErrorAndLoadChecking } from 'hex/components/material/hooks/index';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type VisitDetailProps = {
    open: boolean;
    close: () => void;
    reception: Reception;
    patient_name: string;
};

type DescriptionState = {
    internal_recomendation: string;
    patient_recomendation: string;
}

const VisitDetail: React.FunctionComponent<VisitDetailProps> = ({open, close, patient_name, reception}) => {
    const theme = useTheme();

    const [textState, setTextState] = React.useState<DescriptionState>({
        internal_recomendation: reception.internal_recomendation,
        patient_recomendation: reception.patient_recomendation,
    });

    const [services, setServices] = React.useState<ServicesReceptionVisitType[]>([]);
    const [medicines, setMedicines] = React.useState<MedicinesReceptionVisitType[]>([]);


    const { data, loading, error } = useGetReceptionMedicinesAndServicesQuery({
        variables: {
            reception_id: reception.id
        },
        fetchPolicy: 'network-only'
    });

    const medicineUnitsData = useGetMedicineUnitsQuery({fetchPolicy: 'network-only'});

    React.useEffect(
        () => {
            if (data !== undefined) {
                setServices(data.ReceptionServices);
                setMedicines(data.ReceptionMedicines);
            }
        },
        [data, setServices, setMedicines]
    );

    const [saveVisitMutation, saveVisitMutationData] = useSaveVisitReceptionMutation();

    useErrorAndLoadChecking(saveVisitMutationData);
    useErrorChecking(medicineUnitsData.error);
    useErrorChecking(error);


    const onSaveVisit = async () => {

        const result = await saveVisitMutation({variables: {
            id: reception.id,
            internal_recomendation: textState.internal_recomendation,
            patient_recomendation: textState.patient_recomendation,
            services: services.filter((obj) => obj.id <= 0).map((service) => ({ reception_id: reception.id, service_id: service.service_id, price: service.price, value: service.value })),
            medicines: medicines.filter((obj) => obj.id <= 0).map((medicine) => ({ reception_id: reception.id, medicine_id: medicine.medicine_id, value: medicine.value })),
        }});

        if (result.errors !== undefined)
            return;
            
        close();
    }

    return (
        <Dialog 
            open={open} 
            onClose={close} 
            TransitionComponent={Transition} 
            fullScreen 
            PaperProps={{
                style: {
                    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800],
                }
            }}
        >
            <Loading loading={loading || medicineUnitsData.loading} />
            <DialogTitle>{`Прием пациента: ${patient_name}`}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ marginBottom: 1 }}>{'Изменение приема'}</DialogContentText>
                <Divider sx={{ marginBottom: 3 }} />

                {/* ЗДЕСЬ БУДЕТ Назначение специалиста */ }
                <Box sx={{marginTop: 3, marginBottom: 1}}>
                    <Paper sx={{p: 1}}>
                        <Box sx={{marginBottom: 3}}>
                            <Typography variant='h6'>{'Назначение специалиста:'}</Typography>
                        </Box>
                        <Box sx={{marginBottom: 3}}>
                            <TextField label="Примечание (для личной карточки пациента)" multiline rows={10} fullWidth value={textState.internal_recomendation} onChange={(event) => setTextState((oldState) => ({internal_recomendation: event.target.value, patient_recomendation: oldState.patient_recomendation}))} />
                        </Box>
                        <Box sx={{marginBottom: 3}}>
                            <TextField label="Рекомендации (для пациента)" multiline rows={10} fullWidth value={textState.patient_recomendation} onChange={(event) => setTextState((oldState) => ({patient_recomendation: event.target.value, internal_recomendation: oldState.internal_recomendation}))} />
                        </Box>
                    </Paper>
                </Box>

                {/* ЗДЕСЬ БУДЕТ Список услуг */}
                <ListData
                    data={services}
                    services={(data !== undefined) ? data.Service : []}
                    on_delete={async (model) => {
                        setServices(
                            (oldServices) => {
                                var copy = [...oldServices];

                                const index = copy.findIndex((element) => element.id === model.id);

                                if (index !== -1) {
                                    copy.splice(index, 1);
                                }

                                return copy;
                            }
                        );
                    }}
                    on_add={async (model) => {
                        setServices(
                            (oldServices) => {
                                const copy = [...oldServices];

                                copy.push(model);

                                return copy;
                            }
                        );
                    }}
                    on_update={async (model) => {
                        setServices(
                            (oldServices) => {
                                var copy = [...oldServices];

                                const index = copy.findIndex((element) => element.id === model.id);

                                if (index !== -1) {
                                    copy[index] = model;
                                }

                                return copy;
                            }
                        );
                    }}
                />
                {/* ЗДЕСЬ БУДЕТ Список препаратов */}
                <ListDataMed
                    data={medicines}
                    medicines={(data !== undefined) ? data.Medicine : []}
                    on_delete={async (model) => {
                        setMedicines(
                            (oldMedicines) => {
                                var copy = [...oldMedicines];

                                const index = copy.findIndex((element) => element.id === model.id);

                                if (index !== -1) {
                                    copy.splice(index, 1);
                                }

                                return copy;
                            }
                        );
                    }}
                    on_add={async (model) => {
                        setMedicines(
                            (oldMedicines) => {
                                const copy = [...oldMedicines];

                                copy.push(model);

                                return copy;
                            }
                        );
                    }}
                    on_update={async (model) => {
                        setMedicines(
                            (oldMedicines) => {
                                var copy = [...oldMedicines];

                                const index = copy.findIndex((element) => element.id === model.id);

                                if (index !== -1) {
                                    copy[index] = model;
                                }

                                return copy;
                            }
                        );
                    }}
                />

                <Divider sx={{ marginBottom: 3, marginTop: 3 }} />

                <DialogActions>
                    <Button variant="contained" onClick={close} color="secondary">Отмена</Button>
                    <Button variant="contained" onClick={onSaveVisit} color="primary">Сохранить</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}

export default VisitDetail;