import React from 'react';
import Title from 'hex/components/title'
import { useAuth } from 'hex/hooks/auth';

import { 
    useTakeDoctorsVisitsSubscription, 
    useGetDoctorsDepartmentsSubscription, 
    useChangeVisitStatusMutation, 
    useChangeDoctorInVisitMutation, 
    useMakeReceptionMutation, 
    useChangeReceptionInVisitMutation, 
    TakeDoctorsVisitsSubscription,
    useSetVisitTimeMutation
} from 'gql'
import Loading from 'hex/components/loading';

import { dateFormat, timeFormat } from 'pages/index/registrator/actions/steps/date';
import { useErrorChecking } from 'hex/components/material/hooks';
import { Box, Fab, Grid, IconButton, Paper, Tooltip, Typography, Zoom } from '@mui/material';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import GetAppIcon from '@mui/icons-material/GetApp';
import EditIcon from '@mui/icons-material/Edit';

import moment from 'moment-timezone';
import 'moment/locale/ru';
import { dateDiff } from 'hex/utils/dates';
import SelectDate from './actions/date';
import { TranslateStatus, VisitStatus, getPaperColor } from 'config';
import VisitDetail from './visit';
import { roundTimeQuarterHour } from '../registrator/actions/make_visit';

import * as Sentry from "@sentry/react";

const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// Props:
type IndexPageProps = {

};

function GetVisitById(id: number, data: TakeDoctorsVisitsSubscription | undefined)
{
    if ((data === undefined) || (id === 0))
        return undefined;

    let outputModel = undefined;

    for (let index = 0; index < data.Department.length; index++) {
        const department = data.Department[index];
        
        for (let jndex = 0; jndex < department.Visits.length; jndex++) {
            const element = department.Visits[jndex];
            
            if (id === element.id)
            {
                outputModel = element;
                break;
            }
        }

        if (outputModel !== undefined)
            break;
    }

    return outputModel;
    
}

const IndexPage: React.FunctionComponent<IndexPageProps> = () => {
    const auth = useAuth();

    const [dateOpen, setDateOpen] = React.useState<boolean>(false);
    const [visitOpen, setVisitOpen] = React.useState<boolean>(false);
    const [currentDate, setCurrentDate] = React.useState<string>(moment(Date.now()).format(dateFormat));
    const [selectedId, setSelectedId] = React.useState<number>(0);
    const [receptionId, setReceptionId] = React.useState<number>(0);

    const isEditingIsDisabled = React.useMemo(() => moment(currentDate, dateFormat).diff(moment(Date.now()), 'days') < 0, [currentDate]);

    const departmentsData = useGetDoctorsDepartmentsSubscription({ variables: { doctor_id: auth.getUserId() } });
    const { data, loading, error } = useTakeDoctorsVisitsSubscription({
        variables: {
            date: currentDate,
            doctor_id: auth.getUserId(),
            doctors_departments: departmentsData.data === undefined ? [] : departmentsData.data.DoctorsDepartment.map((model) => model.department_id),
        },
    });

    const [changeStatusMutation, changeStatusMutationData] = useChangeVisitStatusMutation({variables: {
        id: selectedId
    }});

    const [changeDoctorInVisitMutation, changeDoctorInVisitMutationData] = useChangeDoctorInVisitMutation({variables: {
        id: selectedId,
        doctor_id: auth.getUserId(),
    }});

    const [makeReceptionMutation, makeReceptionMutationData] = useMakeReceptionMutation();
    const [setVisitTimeMutation, setVisitTimeMutationData] = useSetVisitTimeMutation();
    const [changeReceptionInVisitMutation, changeReceptionInVisitMutationData] = useChangeReceptionInVisitMutation();

    useErrorChecking(departmentsData.error);
    useErrorChecking(changeStatusMutationData.error);
    useErrorChecking(changeDoctorInVisitMutationData.error);
    useErrorChecking(makeReceptionMutationData.error);
    useErrorChecking(changeReceptionInVisitMutationData.error);
    useErrorChecking(setVisitTimeMutationData.error);
    useErrorChecking(error);

    const selectedVisit = React.useMemo(() => {
        var result = GetVisitById(selectedId, data);

        if ((result === undefined) && (selectedId !== 0))
            setSelectedId(0);
        else if ((result !== undefined) && (result.doctor_id !== undefined) && (result.doctor_id !== auth.getUserId()))
            setSelectedId(0);
        else if ((result !== undefined) && (result.doctor_id === undefined))
            setSelectedId(0);

        return result;
    }, [selectedId, data, setSelectedId, auth]);

    const onVisitStart = (id: number) => async () => {
        setSelectedId(id);

        const currentVisit = GetVisitById(id, data);

        if ((currentVisit !== undefined) && ((currentVisit.current_reception_id === undefined) || (currentVisit.current_reception_id === null)))
        {
            const result = await makeReceptionMutation({
                variables: {
                    doctor_id: auth.getUserId(),
                    from_id: currentVisit.from_id,
                    visit_id: id,
                    internal_recomendation: "",
                    patient_recomendation: ""
                }
            });

            if (result.errors !== undefined)
                return;
            
            const result2 = await changeReceptionInVisitMutation({
                variables: {
                    id,
                    current_reception_id: result.data?.insert_Reception?.returning[0].id
                }
            });
            
            if (result2.errors !== undefined)
                return;

            const newReceptionId = result.data?.insert_Reception?.returning[0].id;

            if (newReceptionId !== undefined)
                setReceptionId(newReceptionId);

            if (currentVisit.without_time == true)
            {
                await setVisitTimeMutation({
                    variables: {
                        id: currentVisit.id,
                        time: moment(roundTimeQuarterHour(new Date(Date.now()))).format(timeFormat)
                    }
                });
            }
        }

        const result = await changeStatusMutation({ variables: {
            id,
            status: VisitStatus.InProgress,
        }});

        if (result.errors === undefined)
        {
            setVisitOpen(true);
        }
    }

    const onVisitContinue = (id: number, reception_id: number) => async () => {
        setSelectedId(id);
        setReceptionId(reception_id);
        setVisitOpen(true);
    }

    const onVisitEdit = (id: number) => async () => {
        setSelectedId(id);
        setVisitOpen(true);
    }

    const onVisitGet = (id: number) => async () => {
        await changeDoctorInVisitMutation({ variables: {
            id,
            doctor_id: auth.getUserId(),
        }});
    }

    const renderElements = () => 
    {
        if (data === undefined)
            return (<></>);
        
        return data.Department.map((department, index) => (
            <React.Fragment key={department.id}>
                <Box>
                    <Paper sx={{p: 1, m: 1, bgcolor: 'primary.main', color: 'common.white', textAlign: 'left'}}>{department.name}</Paper>
                </Box>
                {department.Visits.map((visit) => (
                    <Box key={visit.id}>
                        <Paper sx={(theme) => ({p: 1, m: 1, bgcolor: getPaperColor(theme, visit.status, !(visit.doctor_id === undefined || visit.doctor_id === null))})}>
                            <Grid container spacing={2} alignItems='center'>
                                <Grid item>
                                    <Typography variant='button'>{!visit.without_time ? moment.utc(visit.time, timeFormat).tz(clientTimeZone).format('HH:mm') : '<--:-->'}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant='button'>{visit.Client.name}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant='caption'>{`${visit.Pet.name} (${visit.Pet.PetType.name})`}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant='caption'>{visit.Pet.birthdate ? `${dateDiff(new Date(visit.Pet.birthdate), new Date(Date.now()))} (${moment.utc(visit.Pet.birthdate).tz(clientTimeZone).format("DD-MM-YYYY")})` : '<Не указано>'}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant='caption'>{TranslateStatus(visit.status)}</Typography>
                                </Grid>
                                {
                                    (visit.doctor_id === undefined || visit.doctor_id === null) && 
                                    <Grid item>
                                        <Typography variant='caption'>{"<Врач не назначен>"}</Typography>
                                    </Grid>
                                }
                                <Grid item sx={{marginLeft: 'auto'}}>
                                    {(visit.status === VisitStatus.Created || visit.status === VisitStatus.Redirected || visit.status === VisitStatus.InCenter) && visit.doctor_id !== undefined && visit.doctor_id !== null && <Tooltip title="Начать прием">
                                        <IconButton onClick={onVisitStart(visit.id)} disabled={isEditingIsDisabled}><ContentPasteIcon /></IconButton>
                                    </Tooltip>}
                                    {(visit.status === VisitStatus.Created || visit.status === VisitStatus.Redirected || visit.status === VisitStatus.InCenter) && (visit.doctor_id === undefined || visit.doctor_id === null) && <Tooltip title="Взять пациента">
                                        <IconButton onClick={onVisitGet(visit.id)} disabled={isEditingIsDisabled}><GetAppIcon /></IconButton>
                                    </Tooltip>}
                                    {visit.status === VisitStatus.InProgress && <Tooltip title="Продолжить прием">
                                        <IconButton onClick={onVisitContinue(visit.id, (visit.current_reception_id !== undefined) && (visit.current_reception_id !== null) ? visit.current_reception_id : 0)} disabled={isEditingIsDisabled}><ContentPasteGoIcon /></IconButton>
                                    </Tooltip>}
                                    {visit.status === VisitStatus.Done && <Tooltip title="Редактировать прием">
                                        <IconButton onClick={onVisitEdit(visit.id)} disabled={isEditingIsDisabled}><EditIcon /></IconButton>
                                    </Tooltip>}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                ))}
            </React.Fragment>
        ));
    }

    const renderBody = () =>
    {
        if (data === undefined)
            return (<></>);
        
        if (data.Department.length === 0)
            return (<Typography variant='h6'>Нет ни одной записи о приеме</Typography>)
        
        return (
            <Paper sx={{display: 'flex', flexDirection: 'column', minWidth: '100%', width: 'fit-content', p: 1, bgcolor: 'grey.200'}}>
                {renderElements()}
            </Paper>
        )
    }

    return (
        <React.Fragment>
            <Title title='Главная страница - Врач' />
            <Loading loading={
                departmentsData.loading || 
                loading || 
                changeStatusMutationData.loading || 
                changeDoctorInVisitMutationData.loading || 
                makeReceptionMutationData.loading || 
                changeReceptionInVisitMutationData.loading ||
                setVisitTimeMutationData.loading
                }
            />
            <h1>Приемы</h1>

            {renderBody()}
            
            <Zoom in={true} timeout={1000} style={{ transitionDelay: '100ms'}} unmountOnExit>
                <Fab sx={{ position: 'absolute', bottom: 50, right: 110}} color='primary' onClick={() => setDateOpen(true)}>
                    <CalendarTodayIcon />
                </Fab>
            </Zoom>
            {dateOpen && <SelectDate open={dateOpen} close={() => setDateOpen(false)} date={currentDate} setDate={setCurrentDate} />}
            {visitOpen && selectedId !== 0 && selectedVisit !== undefined && receptionId !== 0 && <VisitDetail open={visitOpen} close={() => setVisitOpen(false)} visit={selectedVisit} reception_id={receptionId} />}
        </React.Fragment>
    )
}

export default IndexPage;