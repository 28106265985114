import React from 'react';
import { FormControl, FormGroup, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Props:
type PasswordFieldDataProps = {
    label?: string;
    value: any;
    onChange: (value: any) => any;
    validator?: (value: any) => void;
    errors?: Array<string>;
};

// Element:
const PasswordFieldData : React.FunctionComponent<PasswordFieldDataProps> = ({label, value, onChange, validator, errors}) => {
    const id = React.useId();
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const onChangeEventWrapper = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChange(event.target.value);
        if (validator !== undefined) validator(event.target.value);
    };

    const error = (errors !== undefined) ? errors.length !== 0 : false;
    const helperText = (errors !== undefined) ? errors.join("; ") : undefined;

    return (
        <FormGroup>
            <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                <InputLabel htmlFor={`outlined-adornment-${id}`}>{label}</InputLabel>
                <OutlinedInput
                        id={`outlined-adornment-${id}`}
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="Показать пароль"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                        label={label}
                        error={error}
                        value={value || ""}
                        onChange={onChangeEventWrapper}
                        autoComplete="off"
                    />
                    {helperText && <FormHelperText style={{color: "#ff1744"}}>{helperText}</FormHelperText>}
            </FormControl>
        </FormGroup>
    );
}

export default PasswordFieldData;