import React from 'react';
import { StepData } from './types';
import { Box, Grid, List, ListItemButton, ListItemText, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

import {usePetStepSearchingSubscription} from 'gql'
import Loading from 'hex/components/loading';

import { PetSexToString, TablePet } from 'pages/pets/types';
import { defaultModel, defaultModelWithClient, createMutation, renderFields, validations } from 'pages/pets/operations';
import { useErrorChecking } from 'hex/components/material/hooks';
import { useLoading } from 'hex/hooks/loading';
import { ValidationResultData, ValidatorCallback, useValidator } from 'hex/hooks/validator';

import moment from 'moment-timezone';
import 'moment/locale/ru';
import { dateDiff } from 'hex/utils/dates';
import { VisitData } from '../types';

const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export enum PetStepVariant {
    SEARCHING = 1,
    CREATING = 2
};

export type PetStepState = {
    variant: PetStepVariant;
    setVariant: React.Dispatch<React.SetStateAction<PetStepVariant>>;
    onNext: () => Promise<boolean>;
    isEnabled: () => boolean;
    pet: TablePet;
    setPet: React.Dispatch<React.SetStateAction<TablePet>>;
    setModelField: (field: keyof TablePet) => (value: any) => void;
    validation: ValidationResultData<TablePet>;
    validator: ValidatorCallback<TablePet>
};

type PetStepProps = StepData & PetStepState;

export function usePetStep(data : StepData) : PetStepState
{
    const [variant, setVariant] = React.useState<PetStepVariant>(PetStepVariant.SEARCHING);
    const [pet, setPet] = React.useState<TablePet>(defaultModel);

    const [validation, validator, modelValidation] = useValidator<TablePet>(validations);
    const [petMutation, mutationData] = createMutation(pet);
    useErrorChecking(mutationData.error);

    React.useMemo(() => {
        if (data.visitData.client_id !== pet.client_id)
            setPet(defaultModelWithClient(data.visitData.client_id));
    }, [data.visitData.client_id, setPet, pet.client_id]);

    // eslint-disable-next-line
    const [_loadingState, setLoading] = useLoading();

    React.useEffect(() => {
        setLoading(mutationData.loading);

        return () => {setLoading(false);};
    }, [setLoading, mutationData.loading]);

    const setModelField = React.useCallback((field: keyof TablePet) => (value: any) => {
        setPet(oldModel => {
            var copy = {...oldModel};

            (copy as any)[field] = value;

            return copy;
        });
    }, [setPet]);

    const onNext = async () => {
        if (variant === PetStepVariant.SEARCHING)
            return true;
        else 
        {
            if (data.visitData.patient_id !== 0)
                return true;

            if (modelValidation(pet))
            {
                const result = await petMutation();
                
                if ((result.data !== undefined) && (result.data?.insert_Pet !== undefined) && (result.data.insert_Pet?.returning.length !== 0))
                {
                    let visitCopy = {...data.visitData};

                    visitCopy.patient_id = result.data.insert_Pet?.returning[0].id as number;

                    data.setVisit(visitCopy);
                }

                return result.errors === undefined;
            }

            return false;
        }
    }

    const isEnabled = () => {
        if (variant === PetStepVariant.SEARCHING)
            return data.visitData.patient_id !== 0;
        
        return true;
    }

    return {
        variant,
        setVariant,
        onNext,
        isEnabled,
        pet,
        setPet,
        setModelField,
        validation,
        validator
    };
}

const PetStep: React.FunctionComponent<PetStepProps> = ({visitData, setVisit, variant, setVariant, pet, setPet, setModelField, validator, validation}) => {
    const [searchText, setSearchText] = React.useState<string>("");
    const { data, loading, error } = usePetStepSearchingSubscription({ variables: {limit: 5, searchText: `%${searchText}%`, client_id: visitData.client_id }});

    useErrorChecking(error);

    const handleChange = (_event: React.MouseEvent<HTMLElement>, newVariant: string) => {
        const parsed = parseInt(newVariant, 10);

        if (!isNaN(parsed))
            setVariant(parsed);
        
        setSearchText("");

        let visitCopy = {...visitData};
        visitCopy.patient_id = 0;
        setVisit(visitCopy);
        setPet(defaultModel);
    };

    const renderSearching = () => (
        <>
            <Box sx={{ width: '100%', marginTop: 0, marginBottom: '4px' }}>
                <TextField value={searchText} onChange={(event) => setSearchText(event.target.value)} margin="dense" label='Поиск' type="text" fullWidth />
            </Box>
            <List component="nav" aria-label="main mailbox folders" sx={{minWidth: '540px'}}>
                {data !== undefined && data.Pet.map((pet) => 
                (
                    <ListItemButton key={pet.id} onClick={() => {
                        let visitCopy = {...visitData};
                        visitCopy.patient_id = pet.id;
                        setVisit(visitCopy);
                    }} sx={(theme) => ({bgcolor: visitData.patient_id === pet.id ? theme.palette.secondary.light : undefined})}>
                        <ListItemText primary={pet.name} secondary={
                            <React.Fragment>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    {`${pet.PetType.name} - ${PetSexToString(pet.sex)}`}
                                </Typography>
                                {` Чип: ${pet.chip_number}, порода: ${pet.breed}, Дата рождения: ${pet.birthdate ? moment.utc(pet.birthdate).tz(clientTimeZone).format("DD-MM-YYYY") : '<Не указан>'} (${pet.birthdate ? dateDiff(new Date(pet.birthdate), new Date(Date.now())) : '<Не указан>'})`}
                            </React.Fragment>
                        } />
                    </ListItemButton>   
                ))}
            </List>
        </>
    )

    const renderCreating = () => (
        <Box sx={{minWidth: '540px'}}>
            {
                renderFields(
                        pet, 
                        setModelField, 
                        validator, 
                        validation, 
                        (visitData as VisitData).patient_id !== 0, 
                        true
                )
            }
        </Box>
    )

    return (
        <>
            <Loading loading={loading} />
            <Grid container sx={{flexDirection: 'row'}} spacing={2}>
                <Grid item sx={{marginTop: '8px'}}>
                    <ToggleButtonGroup color="primary" value={variant} exclusive onChange={handleChange} orientation="vertical" >
                        <ToggleButton value={PetStepVariant.SEARCHING}>Поиск</ToggleButton>
                        <ToggleButton value={PetStepVariant.CREATING}>Создание</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item>
                    {variant === PetStepVariant.SEARCHING && renderSearching()}
                    {variant === PetStepVariant.CREATING && renderCreating()}
                </Grid>
            </Grid>
        </>
    );
}

export default PetStep;