import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

import {useGetReceptionsOfVisitQuery} from 'gql'
import { useErrorChecking } from 'hex/components/material/hooks';
import Loading from 'hex/components/loading';

import moment from 'moment-timezone';
import 'moment/locale/ru';

import { clientTimeZone } from "hex/utils/dates";

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { timeFormat } from './steps/date';
import { useAuth } from 'hex/hooks/auth';

const calculateSum = (services: Array<{price: any, value: any}>) => {
    let sum = 0;
    services.forEach((element) => sum += (element.price as number * element.value as number));

    return sum;
}



type VisitHistoryStackProps = {
    visit_id: number;
    current_reception_id: number;
};

const VisitHistoryStack: React.FunctionComponent<VisitHistoryStackProps> = ({visit_id, current_reception_id}) => {
    const auth = useAuth();
    const { data, loading, error } = useGetReceptionsOfVisitQuery({variables: {
        id: visit_id,
        current_reception_id: current_reception_id
    }, fetchPolicy: 'network-only'});

    useErrorChecking(error);

    const renderPage = () => {
        if (data === undefined)
            return (<></>);

        return (<>
            <Timeline
                sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.08,
                    },
                }}
                >
                    {data.Reception.map(reception => (
                        <TimelineItem>
                            <TimelineOppositeContent color="textSecondary">
                                {reception.end_time ? `${moment.utc(reception.start_time, timeFormat).tz(clientTimeZone).format('HH:mm')} - ${moment.utc(reception.end_time, timeFormat).tz(clientTimeZone).format('HH:mm')}` : `${moment.utc(reception.start_time, timeFormat).tz(clientTimeZone).format('HH:mm')}`}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                            <TimelineDot />
                            <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Box>
                                    <Typography variant='h6'>{'Врач'} - <b><i>{reception.Doctor.Name}</i></b> - <i>{`(направил: ${reception.From.Name})`}</i></Typography>
                                </Box>
                                <Box>
                                    <Typography variant='button'>
                                        <b>Примечание:</b>
                                    </Typography>
                                    <Typography style={{whiteSpace: 'pre-line'}}>
                                        {reception.internal_recomendation}
                                    </Typography>
                                    <Typography variant='button'>
                                        <b>Рекомендации:</b>
                                    </Typography>
                                    <Typography style={{whiteSpace: 'pre-line'}}>
                                        {reception.patient_recomendation}
                                    </Typography>
                                    <Typography variant='button'>
                                        <b>Услуги:</b>
                                    </Typography>
                                    <Typography>
                                        {reception.Services.length === 0 && <Typography>Отсутствуют</Typography>}
                                        {reception.Services.length !== 0 && 
                                            <ul style={{margin: 0}}>
                                                {reception.Services.map((service) => (<li key={service.id}>{service.Service.name} {auth.getUserId() === reception.doctor_id ? `${service.price} * ${service.value} = ${service.price as number * service.value as number}` : ''}</li>))}
                                            </ul>
                                        }
                                        {
                                            auth.getUserId() === reception.doctor_id && <Typography>Сумма: {calculateSum(reception.Services)} руб.</Typography>
                                        }
                                    </Typography>
                                    <Typography variant='button'>
                                        <b>Препараты:</b>
                                    </Typography>
                                    <Typography>
                                        {reception.Medicines.length === 0 && <Typography>Отсутствуют</Typography>}
                                        {reception.Medicines.length !== 0 && 
                                            <ul style={{margin: 0}}>
                                                {reception.Medicines.map((medicine) => (<li key={medicine.id}>{medicine.done ? '✓' : ''}{medicine.Medicine.name}</li>))}
                                            </ul>
                                        }
                                    </Typography>
                                </Box>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
            </Timeline>
        </>);
    }

    return (
        <Box sx={{marginTop: 3}}>    
            <Loading loading={loading} />
            <Paper sx={{p: 1}}>
                <Typography variant='h6'>{'Приемы:'}</Typography>
                <Box>
                    {renderPage()}
                </Box>
            </Paper>
        </Box>
    );
}

export default VisitHistoryStack;