import React from 'react';
import {LoadingContextType} from './types';

// Context:
export const HexLoadingContext = React.createContext<LoadingContextType>({loading: false, setLoading: (arg) => {}});

// Props:
type HexLoadingProviderProps = {
    children?: React.ReactNode
};

class HexLoadingProvider extends React.Component<HexLoadingProviderProps, LoadingContextType> {

    setLoading: (arg: boolean) => void = (arg) => {};

    constructor(props: HexLoadingProviderProps) {
        super(props);

        this.setLoading = (arg) => {
            this.setState(state => ({
                loading: arg,
                setLoading: state.setLoading
            }));
        };

        this.state = {
            loading: false,
            setLoading: this.setLoading,
        };
    };

    render()
    {
        return (
            <HexLoadingContext.Provider value={this.state}>
                {this.props.children}
            </HexLoadingContext.Provider>
        );
    }
};

export default HexLoadingProvider;