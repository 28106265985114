import { Box, FormControl, FormControlLabel, FormLabel, Grid, List, ListItemButton, ListItemText, Radio, RadioGroup } from '@mui/material';
import Loading from 'hex/components/loading';
import React from 'react';
import { StepData } from './types';

import {useGetDepartmentWithDoctorsQuery} from 'gql'
import { useErrorChecking } from 'hex/components/material/hooks';


type DoctorStepProps = StepData;

const DoctorStep: React.FunctionComponent<DoctorStepProps> = ({visitData, setVisit}) => {
    const id = React.useId();

    const { data, loading, error } = useGetDepartmentWithDoctorsQuery({fetchPolicy: 'network-only'});
    useErrorChecking(error);

    const handleDoctorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;

        setVisit(oldModel => {
            var copy = {...oldModel};

            copy.doctor_id = value === "" ? undefined : value;

            return copy;
        });
    };

    const renderDepartments = () => {
        if (data === undefined)
            return (<></>);
    
        return data.Department.map((model) => 
        {
            const handleClick = () => {
                setVisit(oldModel => {
                    var copy = {...oldModel};
        
                    copy.department_id = model.id;
        
                    return copy;
                });
            }

            return (
                <ListItemButton key={model.id} onClick={handleClick} selected={visitData.department_id === model.id}>
                    <ListItemText primary={model.name} />
                </ListItemButton>
            )
        }
        );
    }

    const renderDoctors = () => {
        if ((data === undefined) || visitData.department_id === 0)
            return (<></>);

        const index = data.Department.findIndex((model) => model.id === visitData.department_id);

        if (index === -1)
            return (<></>);
        
        return data.Department[index].DoctorsDepartments.map((model) => 
        (
            <FormControlLabel key={model.Doctor.ID} value={model.Doctor.ID} control={<Radio />} label={model.Doctor.Name} />
        ))
    }
    
    return (
        <>
            <Loading loading={loading} />
            <Grid container sx={{flexDirection: 'row', marginTop: '8px'}} spacing={2}>
                <Grid item>
                    <Box sx={{ width: 360, maxWidth: 360, bgcolor: 'background.paper' }}>
                        <List component="nav" aria-label="main mailbox folders">
                            {renderDepartments()}
                        </List>
                    </Box>
                </Grid>
                <Grid item>
                    {visitData.department_id !== 0 && <FormControl>
                        <FormLabel id={id}>Врач</FormLabel>
                        <RadioGroup
                            aria-labelledby={id}
                            name="radio-buttons-group"
                            value={visitData.doctor_id === undefined ? "" : visitData.doctor_id}
                            onChange={handleDoctorChange}
                        >
                            <FormControlLabel value="" control={<Radio />} label="<Оставить без выбора>" />
                            {renderDoctors()}
                        </RadioGroup>
                    </FormControl>}
                </Grid>
            </Grid>
        </>
    );
}

export default DoctorStep;