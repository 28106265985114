import React from 'react';
import { IconButton, Paper, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { TranslateStatus, getPaperColor } from 'config';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';

// Props:
type ReceptionCardProps = {
    time: string;
    doctor: string;
    client: string;
    animal: string;
    animalType: string;
    animalAge: string;
    isDisabled: boolean;
    canBeTurned: boolean;
    status: number;
    hasDoctor: boolean;
    withoutTime: boolean;
    phone: string;
    onDelete: () => void;
    onTime: () => void;
    onDocument: () => void;
    onChangeStatus?: () => void;
    onReturn?: () => void;
};

const ReceptionCard: React.FunctionComponent<ReceptionCardProps> = ({status, time, doctor, client, animal, animalType, animalAge, isDisabled, hasDoctor, withoutTime, phone, onDelete, onTime, onDocument, onChangeStatus, canBeTurned, onReturn}) => {
    return (
        <Paper  sx={(theme) => ({m: 1, bgcolor: getPaperColor(theme, status, hasDoctor)})}>
            <Grid container spacing={1} sx={{p: 1}}>
                <Grid item xs={4} sx={{borderRight: '1px solid grey'}}>
                    <Typography variant='h5' sx={{textAlign: 'center'}}>{withoutTime ? "<--:-->" : time}</Typography>
                    <Typography variant='body1' sx={{textAlign: 'center'}}>{doctor}</Typography>
                </Grid>
                <Grid item xs={6} sx={{borderRight: '1px solid grey'}}>
                    <Tooltip title={TranslateStatus(status)}>
                        <Typography variant='overline'>{client}</Typography>
                    </Tooltip>
                    <Tooltip title={TranslateStatus(status)}>
                        <Typography variant='body2'>{phone}</Typography>
                    </Tooltip>
                    <Tooltip title={TranslateStatus(status)}>
                        <Typography sx={{mt: 1}} variant='body2'>{`${animal} (${animalType}), ${animalAge}`}</Typography>
                    </Tooltip>
                </Grid>
                <Grid item xs={2} sx={{paddingTop: 0}}>
                    <Grid container sx={{ justifyContent: 'end' }}>
                        <Grid item> 
                            <Tooltip title="Перенести время приема">
                                <IconButton size={'small'} color="primary" disabled={isDisabled} onClick={onTime}><AccessTimeIcon /></IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Информация о приеме">
                                <IconButton size={'small'} color="primary" onClick={onDocument}><FilePresentIcon /></IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Удалить прием">
                                <IconButton size={'small'} color="error" disabled={isDisabled} onClick={onDelete}><DeleteForeverIcon /></IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Отметить посещение">
                                <IconButton size={'small'} color="secondary" disabled={isDisabled || onChangeStatus === undefined} onClick={onChangeStatus ? onChangeStatus : () => {}}><AutorenewIcon /></IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Вернуть прием">
                                <IconButton size={'small'} color="warning" disabled={!(isDisabled && canBeTurned)} onClick={onReturn ? onReturn : () => {}}><AssignmentReturnIcon /></IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default ReceptionCard;