import { OperationType } from 'hex/components/material/types'
import { TableTemplate } from './types'

import AddIcon from '@mui/icons-material/Add';
import Create from 'hex/components/material/operations/create';
import Update from 'hex/components/material/operations/update';
import { ApolloError } from '@apollo/client';
import { ConvertToFieldErrors, ValidationResultData, Validations, ValidatorCallback } from 'hex/hooks/validator';
import { StringLengthMinMax } from 'hex/validations';
import TextFieldData from 'hex/components/material/operations/fields/text';

import {useAddTemplateMutation, useUpdateTemplateMutation, useDeleteTemplateMutation, useDeleteTemplatesByIdsMutation} from 'gql'
import Delete from 'hex/components/material/operations/delete';
import { DeleteForever } from '@mui/icons-material';
import DeleteSelected from 'hex/components/material/operations/delete_selected';
import ManyToManyField from 'hex/components/material/operations/fields/many_to_many';

import MedicinesMtm from './mtm/medicines'
import ServicesMtm from './mtm/services'
import OperationNotifier from 'hex/components/material/operations/operation_notifier';
import TextAreaFieldData from 'hex/components/material/operations/fields/textarea';
import { useAuth } from 'hex/hooks/auth';


const validations : Validations<TableTemplate> = [
    ["name", StringLengthMinMax(2, 100)],
]

var operationNotifier = new OperationNotifier<TableTemplate>();

const renderFields = (model: TableTemplate, setFieldValue: (field: keyof TableTemplate) => (value: any) => void, validator: ValidatorCallback<TableTemplate>, validation: ValidationResultData<TableTemplate>) => {
    return (
        <>
            <TextFieldData label='Наименование' value={model.name} onChange={setFieldValue("name")} validator={validator("name")} errors={ConvertToFieldErrors("name", validation)} />
            <TextAreaFieldData label='Внутренние рекомендации' value={model.internal_recomendation} onChange={setFieldValue("internal_recomendation")} validator={validator("internal_recomendation")} errors={ConvertToFieldErrors("internal_recomendation", validation)} rows={10} maxRows={20} />
            <TextAreaFieldData label='Рекомендации пациента' value={model.patient_recomendation} onChange={setFieldValue("patient_recomendation")} validator={validator("patient_recomendation")} errors={ConvertToFieldErrors("patient_recomendation", validation)} rows={10} maxRows={20} />
            <ManyToManyField values={model.TemplateMedicines} {...MedicinesMtm} operationNotifierAfterCreation={operationNotifier.AddOperationNotifierAfterOperation("MedicinesMtm")} />
            <ManyToManyField values={model.TemplateServices} {...ServicesMtm} operationNotifierAfterCreation={operationNotifier.AddOperationNotifierAfterOperation("ServicesMtm")} />
        </>
    );
}

const renderDeleteText = (model: TableTemplate) => {
    return `Вы действительно уверены, что хотите удалить шаблон ${model.name}?`;
}

const renderDeleteSelectedTitle = () => {
    return `Вы действительно уверены, что хотите удалить следующие шаблоны?`;
}

const renderDeleteSelectedText = (model: TableTemplate) => {
    return `${model.name}`;
}

function createMutation(model: TableTemplate)
{
    const auth = useAuth();

    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useAddTemplateMutation({variables: {
        name: model.name,
        internal_recomendation: model.internal_recomendation,
        patient_recomendation: model.patient_recomendation,
        user_id: auth.getUserId(),
    }});
}

function updateMutation(model: TableTemplate)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useUpdateTemplateMutation({
        variables: {
            id: model.id,
            name: model.name,
            internal_recomendation: model.internal_recomendation,
            patient_recomendation: model.patient_recomendation,
        }
    })
}

function deleteMutation(model: TableTemplate)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useDeleteTemplateMutation({
        variables: {
            id: model.id,
        }
    });
}

function deleteByIdsMutation(models: Array<TableTemplate>)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useDeleteTemplatesByIdsMutation({
        variables: {
            ids: models.map((x) => x.id),
        }
    });
}

const translateError = (error: ApolloError) => {
    return error.message;
}

const defaultModel : TableTemplate = {
    id: 0, 
    name: "",
    internal_recomendation: "",
    patient_recomendation: "",
    TemplateMedicines: [],
    TemplateServices: []
};

export function CreateOperation() : OperationType<TableTemplate>
{
    return {
        type: 'global',
        label: 'Создать',
        icon: (<AddIcon />),
        render: (close) => (
            <Create 
                close={close} 
                defaultModel={defaultModel} 
                translateError={translateError} 
                validations={validations} 
                renderFields={renderFields} 
                useMutation={createMutation}
                operationNotifier={operationNotifier}
            />
        ),
    }
}

export function UpdateOperation() : OperationType<TableTemplate>
{
    return {
        type: 'individual',
        label: 'Изменить',
        enabled: true,
        render: (model, close) => (
            <Update 
                close={close} 
                defaultModel={model} 
                translateError={translateError}
                validations={validations}
                renderFields={renderFields}
                useMutation={updateMutation}
                operationNotifier={operationNotifier}
            />
        ),
    }
}

export function DeleteOperation() : OperationType<TableTemplate>
{
    return {
        type: 'individual',
        label: 'Удалить',
        enabled: true,
        render: (model, close) => (
            <Delete 
                close={close} 
                model={model}
                translateError={translateError}
                renderText={renderDeleteText}
                useMutation={deleteMutation}
            />
        ),
    }
}

export function DeleteSelectedOperation() : OperationType<TableTemplate>
{
    return {
        type: 'selected',
        label: 'Удалить выбранное',
        icon: (<DeleteForever />),
        render: (models, close) => (
            <DeleteSelected
                close={close} 
                models={models}
                translateError={translateError}
                renderTitle={renderDeleteSelectedTitle}
                renderText={renderDeleteSelectedText}
                useMutation={deleteByIdsMutation}
            />
        ),
    }
}