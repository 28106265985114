import React from 'react';
import { ModelType } from '../table/types';
import Modal from 'hex/components/material/modal'
import { ApolloCache, ApolloError, DefaultContext, MutationTuple, OperationVariables } from '@apollo/client';
import Loading from 'hex/components/loading';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';


// Props:
type DeleteProps<TModel extends ModelType, TData = any, TVariables = OperationVariables, TContext = DefaultContext, TCache extends ApolloCache<any> = ApolloCache<any>> = {
    renderText: (model: TModel) => React.ReactNode;
    useMutation: (model: TModel) => MutationTuple<TData, TVariables, TContext, TCache>;
    translateError: (error: ApolloError) => string;
    close: () => void;
    model: TModel;
};


function Delete<TModel extends ModelType>(props: DeleteProps<TModel>)
{
    var {useMutation, close, translateError, model, renderText} = props;

    const [deleteMutation, { data, loading, error }] = useMutation(model);

    const onDelete = () => {
        deleteMutation().then(() => {
            close();
        });
    }

    return (
        <>
            <Loading loading={loading} />
            <Modal open={true} title='Удалить объект' contentTitle='Удаление объекта' closeButtonText='Отмена' closeButtonAction={close} actionButtonText='Удалить' actionButtonAction={onDelete}>
                {error !== undefined && (
                    <Alert severity="error">{translateError(error)}</Alert>
                )}
                <Typography variant='body1'>
                    {renderText(model)}
                </Typography>
            </Modal>
        </>
    );
};

export default Delete;