import React from 'react'
import { OperationType } from 'hex/components/material/types'
import { TableDocuments } from './types'

import AddIcon from '@mui/icons-material/Add';
import Create from 'hex/components/material/operations/create';
import Update from 'hex/components/material/operations/update';
import { ApolloError } from '@apollo/client';
import { ConvertToFieldErrors, ValidationResultData, Validations, ValidatorCallback } from 'hex/hooks/validator';
import { StringLengthMinMax } from 'hex/validations';
import TextFieldData from 'hex/components/material/operations/fields/text';
import TextAreaFieldData from 'hex/components/material/operations/fields/textarea'

import {useAddDocumentMutation, useUpdateDocumentMutation, useDeleteDocumentMutation, useDeleteDocumentsByIdsMutation, Documents_Bool_Exp} from 'gql'
import Delete from 'hex/components/material/operations/delete';
import { DeleteForever, FilterList } from '@mui/icons-material';
import DeleteSelected from 'hex/components/material/operations/delete_selected';
import Filters from 'hex/components/material/operations/filters';
import TextFilter from 'hex/components/material/operations/filters/text_filter';

import RichTextPopup from 'components/wysiwyg/popup';


export type FilterType = {
    Name: string;
}

function ToString(data: any) : string
{
    if (typeof data === 'string' || data instanceof String)
    {
        return data as string;
    }

    return JSON.stringify(data);
}

function ToObject(data: any) : string | undefined
{
    if (typeof data === 'string' || data instanceof String)
    {
        try {
            return JSON.parse(data as string);
        } catch (e) {
            console.error(e); // error in the above string (in this case, yes)!
            return undefined;
        }
    }

    return data;
}

const validations : Validations<TableDocuments> = [
    ["name", StringLengthMinMax(2, 100)],
]

const renderFields = (model: TableDocuments, setFieldValue: (field: keyof TableDocuments) => (value: any) => void, validator: ValidatorCallback<TableDocuments>, validation: ValidationResultData<TableDocuments>) => {
    return (
        <>
            <TextFieldData label='Наименование' value={model.name} onChange={setFieldValue("name")} validator={validator("name")} errors={ConvertToFieldErrors("name", validation)} />
            <RichTextPopup name='Тело документа' text={model.body} setText={setFieldValue("body")} />
            <TextAreaFieldData label='Переменные' value={ToString(model.variables)} onChange={setFieldValue("variables")} validator={validator("variables")} errors={ConvertToFieldErrors("variables", validation)} rows={10} maxRows={20} />
            <TextAreaFieldData label='Стили' value={model.styles} onChange={setFieldValue("styles")} validator={validator("styles")} errors={ConvertToFieldErrors("styles", validation)} rows={10} maxRows={20} />
        </>
    );
}

const renderFilterFields = (model: FilterType, setFieldValue: (field: keyof FilterType) => (value: any) => void) => {
    return (
        <>
            <TextFilter label='Наименование' value={model.Name} onChange={setFieldValue("Name")} />
        </>
    );
}

const renderDeleteText = (model: TableDocuments) => {
    return `Вы действительно уверены, что хотите удалить документ ${model.name}?`;
}

const renderDeleteSelectedTitle = () => {
    return `Вы действительно уверены, что хотите удалить следующие документы?`;
}

const renderDeleteSelectedText = (model: TableDocuments) => {
    return `${model.name}`;
}

function createMutation(model: TableDocuments)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useAddDocumentMutation({variables: {
        name: model.name,
        body: model.body,
        variables: ToObject(model.variables) as any,
        styles: model.styles,
    }});
}

function updateMutation(model: TableDocuments)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useUpdateDocumentMutation({
        variables: {
            id: model.id,
            name: model.name,
            body: model.body,
            variables: ToObject(model.variables) as any,
            styles: model.styles
        }
    })
}

function deleteMutation(model: TableDocuments)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useDeleteDocumentMutation({
        variables: {
            id: model.id,
        }
    });
}

function deleteByIdsMutation(models: Array<TableDocuments>)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useDeleteDocumentsByIdsMutation({
        variables: {
            ids: models.map((x) => x.id),
        }
    });
}

const translateError = (error: ApolloError) => {
    return error.message;
}

const defaultModel : TableDocuments = {id: 0, name: "", body: "", variables: ""};

export const defaultFilter : FilterType = {
    Name: ""
}

export function CreateOperation() : OperationType<TableDocuments>
{
    return {
        type: 'global',
        label: 'Создать',
        icon: (<AddIcon />),
        render: (close) => (
            <Create 
                close={close} 
                defaultModel={defaultModel} 
                translateError={translateError} 
                validations={validations} 
                renderFields={renderFields} 
                useMutation={createMutation} 
            />
        ),
    }
}

export function UpdateOperation() : OperationType<TableDocuments>
{
    return {
        type: 'individual',
        label: 'Изменить',
        enabled: true,
        render: (model, close) => (
            <Update 
                close={close} 
                defaultModel={model} 
                translateError={translateError}
                validations={validations}
                renderFields={renderFields}
                useMutation={updateMutation}
            />
        ),
    }
}

export function DeleteOperation() : OperationType<TableDocuments>
{
    return {
        type: 'individual',
        label: 'Удалить',
        enabled: true,
        render: (model, close) => (
            <Delete 
                close={close}
                model={model}
                translateError={translateError}
                renderText={renderDeleteText}
                useMutation={deleteMutation}
            />
        ),
    }
}

export function DeleteSelectedOperation() : OperationType<TableDocuments>
{
    return {
        type: 'selected',
        label: 'Удалить выбранное',
        icon: (<DeleteForever />),
        render: (models, close) => (
            <DeleteSelected
                close={close} 
                models={models}
                translateError={translateError}
                renderTitle={renderDeleteSelectedTitle}
                renderText={renderDeleteSelectedText}
                useMutation={deleteByIdsMutation}
            />
        ),
    }
}

export function FiltrationOperation(filter: FilterType, setFilter: React.Dispatch<React.SetStateAction<FilterType>>)  : OperationType<TableDocuments>
{
    return {
        type: 'global',
        icon: (<FilterList />),
        label: 'Поиск',
        render: (close) => (
            <Filters 
                close={close}
                defaultModel={defaultFilter}
                model={filter}
                setModel={setFilter}
                renderFields={renderFilterFields}
            />
        )
    }
}

export function TranslateFiltrationToWhere(filter: FilterType) : Documents_Bool_Exp | undefined
{
    if (filter.Name.trim() !== "")
    {
        return {
            name: {
                _ilike: `%${filter.Name}%`
            }
        }
    }
    
    return undefined;
}