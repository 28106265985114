import React from 'react';
import { FormGroup } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';

export const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// Props:
type DateInputProps = {
    label?: string;
    value: any;
    onChange: (value: any) => any;
    disabled?: boolean;
};

const DateInput : React.FunctionComponent<DateInputProps> = ({label, value, onChange, disabled}) => 
{
    const onChangeWrapper = (value: Dayjs | null) => {
        if (value)
            onChange(dayjs(value).tz(clientTimeZone).format("YYYY-MM-DD"));
    }

    return (
        <FormGroup style={{marginTop: "12px", marginBottom: "4px"}}>
            <DatePicker disabled={disabled} label={label} value={value ? dayjs(value).tz(clientTimeZone) : dayjs(new Date(Date.now()))} onChange={onChangeWrapper} />
        </FormGroup>
    );
};

export default DateInput;