import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Slide, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

import { useDeleteVisitMutation } from 'gql'
import { useErrorChecking } from 'hex/components/material/hooks';
import Loading from 'hex/components/loading';


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type DeleteVisitProps = {
    open: boolean;
    close: () => void;
    visit_id: number;
};

const DeleteVisit: React.FunctionComponent<DeleteVisitProps> = ({ visit_id, open, close }) => {
    const [deleteVisitMutation, { data, loading, error }] = useDeleteVisitMutation({
        variables: {
            id: visit_id,
        },
    });
    useErrorChecking(error);

    const handleDeletion = async () => {
        const result = await deleteVisitMutation();

        if (result.errors === undefined)
            close();
    }

    return (
        <Dialog open={open} onClose={close} TransitionComponent={Transition} fullWidth maxWidth='xs'>
            <Loading loading={loading} />
            <DialogTitle>{'Удаление записи о приеме'}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ marginBottom: 1 }}>{'Запрос подтверждения'}</DialogContentText>
                <Divider sx={{ marginBottom: 3 }} />
                <Typography>Вы действительно хотите удалить запись о приеме?</Typography>
                <Divider sx={{ marginBottom: 3 }} />
                <DialogActions>
                    <Button variant="contained" onClick={handleDeletion} color="primary">Удалить</Button>
                    <Button variant="contained" onClick={close} color="secondary">Отмена</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}

export default DeleteVisit;