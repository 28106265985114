import React from 'react';
import TextField from '@mui/material/TextField';
import { FormGroup } from '@mui/material';

// Props:
type NumberFieldProps = {
    label?: string;
    value: any;
    errors?: Array<string>;
    validator?: (value: any) => void;
    onChange: (value: any) => any;
    disabled?: boolean;
    decimal?: boolean;
};

// Element:
const NumberField : React.FunctionComponent<NumberFieldProps> = ({label, value, onChange, errors, disabled, validator, decimal}) => {
    const onChangeEventWrapper = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChange(event.target.value);
        if (validator !== undefined) validator(event.target.value);
    };

    const error = (errors !== undefined) ? errors.length !== 0 : false;
    const helperText = (errors !== undefined) ? errors.join("; ") : undefined;

    return (
        <FormGroup>
            <TextField inputProps={decimal !== undefined && decimal ? { step: 0.01 } : {}} disabled={disabled} error={error} helperText={helperText} margin="dense" label={label} type="number" fullWidth onChange={onChangeEventWrapper} value={value} />
        </FormGroup>
    );
}

export default NumberField;