import React from 'react';
import Title from 'hex/components/title'
import Loading from 'hex/components/loading'

import HexTable from 'hex/components/material/table'

import { useSnackbar } from 'notistack';
import { HeadCell } from 'hex/components/material/table/types';
import { useOperations, usePagination, useSorting } from 'hex/components/material/hooks';

import {useMedicineUnitsIndexSubscription, useMedicineUnitsIndexCountSubscription} from 'gql'
import { MedicineUnitType } from './types';
import { OperationType } from 'hex/components/material/types';
import Operations from 'hex/components/material/operations';

import {CreateOperation, defaultFilter, DeleteOperation, DeleteSelectedOperation, FilterType, FiltrationOperation, TranslateFiltrationToWhere, UpdateOperation} from './operations'

const headCells : HeadCell<MedicineUnitType>[] = [
    {
        id: "name",
        label: "Наименование",
        sort: true,
        render: (petType) => (<React.Fragment>{petType.name}</React.Fragment>)
    }
];

type IndexProps = {

}

const Index : React.FunctionComponent<IndexProps> = ({}) => {
    // eslint-disable-next-line
    const { enqueueSnackbar } = useSnackbar();

    const [pagination, setPagination] = usePagination();
    const [order, onSetOrder] = useSorting<MedicineUnitType>({orderBy: "name", order: "asc"});
    const [filter, setFilter] = React.useState<FilterType>(defaultFilter);
    const operationsArray = React.useMemo<Array<OperationType<MedicineUnitType>>>(() => {
        return [
            FiltrationOperation(filter, setFilter),
            CreateOperation(),
            UpdateOperation(),
            DeleteOperation(),
            DeleteSelectedOperation(),
        ];
    }, [filter, setFilter]);
    const operations = useOperations<MedicineUnitType>(operationsArray);

    const { data, loading, error } = useMedicineUnitsIndexSubscription({variables: { offset: pagination.offset, limit: pagination.limit, order_by: { [order.orderBy]: order.order, }, where: TranslateFiltrationToWhere(filter)}});
    const countResult = useMedicineUnitsIndexCountSubscription({variables: { where: TranslateFiltrationToWhere(filter) }});

    const models : MedicineUnitType[] | undefined = (data === undefined) ? undefined : data.MedicineUnit;

    var count = React.useMemo<number>(() => {
        if ((countResult.data !== undefined) && (countResult.data.MedicineUnit_aggregate.aggregate !== undefined) && (countResult.data.MedicineUnit_aggregate.aggregate !== null))
            return countResult.data.MedicineUnit_aggregate.aggregate.count;
        
        return 0;
    }, [countResult]);

    React.useMemo(() => {
        if (error !== undefined)
        {
            enqueueSnackbar(`Ошибка получения данных! ${error.message}`, {variant: 'error'});
        }
        else if (countResult.error !== undefined)
        {
            enqueueSnackbar(`Ошибка получения данных! ${countResult.error.message}`, {variant: 'error'});
        }
    }, [error, enqueueSnackbar, countResult]);
    

    return (
        <React.Fragment>
            <Title title='Единицы измерения'/>
            <Loading loading={loading || countResult.loading} />
            <h1>Единицы измерения</h1>
            <HexTable {...pagination} order={order.order} orderBy={order.orderBy as keyof MedicineUnitType} 
                      count={count} onPageChange={setPagination} models={models} headCells={headCells} onOrderSet={onSetOrder}
                      globalActions={operations.globalActions} selectedActions={operations.selectedActions} actions={operations.actions}
            /> 
            <Operations args={operationsArray} eventIndex={operations.eventIndex} model={operations.model} selectedModels={operations.selectedModels} close={operations.close} />
        </React.Fragment>
    )
}

export default Index;