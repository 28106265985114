import React from "react";
import { ServiceVisitType, ServicesReceptionVisitType } from "./types";
import { Autocomplete, Avatar, Box, Grid, IconButton, ListItem, ListItemAvatar, ListItemText, TextField } from "@mui/material";
import { Delete } from "@mui/icons-material";
import CreateIcon from '@mui/icons-material/Create';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';


type ServicesDataItemProps = {
    model: ServicesReceptionVisitType;
    services: Array<ServiceVisitType>;
    onUpdate: (model: ServicesReceptionVisitType) => Promise<void>;
    onDelete: (model: ServicesReceptionVisitType) => Promise<void>;
};

function ParseFloatFromString(data: string)
{
    const result = parseFloat(data);
    if (isNaN(result))
        return 0;

    return result;
}

const ServicesDataItem : React.FC<ServicesDataItemProps> = ({model, onUpdate, onDelete, services}) => {
    const [edit, setEdit] = React.useState<boolean>(false);

    const [selectedService, setSelectedService] = React.useState<number>(model.service_id);
    const [selectedValue, setSelectedValue] = React.useState<number>(model.value);
    const [selectedPrice, setSelectedPrice] = React.useState<number>(model.price);

    const servicesRef = React.useRef<HTMLInputElement>();
    const priceRef = React.useRef<HTMLInputElement>();
    const countRef = React.useRef<HTMLInputElement>();

    const getServiceByID = (serviceId : number) => {
        const index = services.findIndex((service) => service.id === serviceId);

        if (index === -1)
            return undefined;

        return services[index];
    }

    const getServiceName = (serviceId : number) => {
        const service = getServiceByID(serviceId);

        if (service === undefined)
            return "<Не определено>";

        return service.name;
    }

    const setSearchingText = (data: string) => {
        const index = services.findIndex(element => element.name.toLowerCase().includes(data.toLowerCase()));

        if (index !== -1) 
        {
            setSelectedService(services[index].id);
            const service = getServiceByID(services[index].id);
            if (service)
            {
                setSelectedPrice(service.price);
                setSelectedValue(1);
            }
            
            if (priceRef.current) priceRef.current.focus();
        }
    }

    const onUpdateData = async () => {
        var newModel = model;

        newModel.service_id = selectedService;
        newModel.price = selectedPrice;
        newModel.value = selectedValue;

        await onUpdate(newModel);

        setSelectedService(newModel.service_id);
        setSelectedPrice(newModel.price);
        setSelectedValue(newModel.value);

        setEdit(false);
    }

    const onServiceKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.code.toLowerCase() === 'enter' && (event.target as any).value) {
            setSearchingText((event.target as any).value);
        }
    }

    const onPriceKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.code.toLowerCase() === 'enter' && (event.target as any).value) {
            if (countRef.current) countRef.current.focus();
        }
    }

    const onValueKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.code.toLowerCase() === 'enter' && (event.target as any).value) {
            onUpdateData();
            if (servicesRef.current) servicesRef.current.focus();
        }
    }


    const renderReadableElement = () => (
        <ListItem sx={{
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: 'grey',
            borderRadius: '4px',
            marginBottom: '5px'
        }} secondaryAction={
            <Grid container spacing={2}>
                <Grid item>
                    <IconButton edge="end" onClick={() => {
                        setEdit(true);
                        setTimeout(() => {
                            if (servicesRef.current) servicesRef.current.focus();
                        }, 500);
                    }}><CreateIcon /></IconButton>
                </Grid>
                <Grid item>
                    <IconButton edge="end" onClick={async () =>  await onDelete(model)}><Delete /></IconButton>
                </Grid>
            </Grid>
        }>
            <ListItemAvatar>
                <Avatar>
                    <MedicalServicesIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={model.Service ? `${model.Service.name} (${model.Service.price} руб) - ${model.value} * ${model.price} = ${model.value * model.price} руб` : '<не определено>'} />
        </ListItem>
    )

    const renderEditableElement = () => (
        <Box sx={{marginBottom: 3}}>
            <Grid container alignItems='center' spacing={2}>
                <Grid item flexBasis='calc(60% - 80px)'>
                    <Autocomplete 
                        value={(selectedService === 0) ? null : {label: getServiceName(selectedService), id: selectedService}}
                        disablePortal
                        options={services.map((service) => ({label: service.name, id: service.id}))}
                        sx={{ width: '100%' }}
                        renderInput={(params) => <TextField inputRef={servicesRef} onKeyDown={onServiceKeyDown} {...params} label="Введите название" />}
                        onChange={(event, value) => {
                            if (value !== null)
                            {
                                const service = getServiceByID(value.id);

                                if (service)
                                {
                                    setSelectedPrice(service.price);
                                    setSelectedValue(1);
                                    setSelectedService(value.id);

                                    return;
                                }
                            }
                            
                            setSelectedService(0);
                            setSelectedPrice(0);
                            setSelectedValue(0);
                        }}
                    />
                </Grid>
                <Grid item flexBasis='calc(20% - 80px)'>
                    <TextField inputRef={priceRef} onKeyDown={onPriceKeyDown} inputProps={{ step: 0.01 }} margin="dense" label="Цена" type="number" fullWidth onChange={(val) => setSelectedPrice(ParseFloatFromString(val.target.value))} value={selectedPrice.toString()} />
                </Grid>
                <Grid item flexBasis='calc(20% - 80px)'>
                    <TextField inputRef={countRef} onKeyDown={onValueKeyDown} inputProps={{ step: 0.01 }} margin="dense" label="Значение" type="number" fullWidth onChange={(val) => setSelectedValue(ParseFloatFromString(val.target.value))} value={selectedValue.toString()} />
                </Grid>
                <Grid item flexBasis='40px'>
                    <IconButton onClick={onUpdateData}>
                        <SaveIcon />
                    </IconButton>
                </Grid>
                <Grid item flexBasis='40px'>
                    <IconButton onClick={async () => {
                        setSelectedService(model.service_id);
                        setSelectedPrice(model.price);
                        setSelectedValue(model.value);
                        
                        setEdit(false);
                    }}>
                        <CloseIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        </Box>
    )

    const renderBody = () => {
        if (edit)
            return renderEditableElement();
        else
            return renderReadableElement();
    }

    return (
        <>
            {renderBody()}
        </>
    );
}

export default ServicesDataItem;