import Modal from 'hex/components/material/modal';
import React from 'react';
import { ComponentToPrint } from './printer';
import { useReactToPrint } from 'react-to-print';
import { proceedDocument } from 'hex/utils/documents';
import { Box, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { DocumentPrintType } from './types';
import { VariablesBlock } from './variables';
import Preview from './preview';

type DocumentProps = {
    documents: DocumentPrintType[];
    variables: Record<string, string>;
    text: string;
    onClose: () => void;
    onPrint?: (name: string, data: string) => void;
};

const Document : React.FunctionComponent<DocumentProps> = ({documents, variables, onClose, text, onPrint}) => 
{
    const [previewOpen, setPreviewOpen] = React.useState<boolean>(false);
    const [selected, setSelected] = React.useState<number>(0);
    const [varValue, setVarValue] = React.useState<Record<string, string>>({});
    const [dataToPrint, setDataToPrint] = React.useState<string>("");

    const componentRef = React.useRef<HTMLDivElement>();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current as any,
    });

    const getElementName = () => {
        if (selected === 0)
            return "<Не определено>";

        var index = documents.findIndex((element) => element.id === selected);
        if (index === -1)
            return "<Не определено>";

        return documents[index].name;
    }

    const documentData = React.useMemo(() => {
        var documentIndex = documents.findIndex((element) => {
            return element.id === selected;
        });

        if (documentIndex !== -1)
            return proceedDocument(documents[documentIndex].body, variables, varValue);
        else
            return "Не найден документ.";
    }, [selected, varValue, documents, variables]);

    React.useEffect(() => {
        setDataToPrint(documentData);
    }, [documentData]);

    const documentStyle = React.useMemo(() => {
        var documentIndex = documents.findIndex((element) => {
            return element.id === selected;
        });

        if (documentIndex !== -1)
            return documents[documentIndex].styles;
        else
            return "Не найден документ.";
    }, [selected, varValue, documents, variables]);

    const printAction = () => {
        setPreviewOpen(true);
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = (event.target as HTMLInputElement).value;

        setSelected(parseInt(val, 10));
        setVarValue({});
    };

    const renderVariablesBlock = () => {
        if (selected === 0)
            return (<><Typography variant='body2'>Документ не выбран.</Typography></>);

        var documentIndex = documents.findIndex((element) => {
            return element.id === selected;
        });

        if (documentIndex !== -1)
            return (<VariablesBlock data={documents[documentIndex].variables} value={varValue} onChange={setVarValue} />);
        else
            return (<><Typography variant='body2'>Документ не найден.</Typography></>);
    }
    

    const renderBody = () => {
        return (
            <>
                <Grid container sx={{width: "800px"}}>
                    <Grid item flexBasis="50%">
                        <Typography variant="body1">Документ:</Typography>
                        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            <RadioGroup value={selected.toString()} onChange={handleChange}>
                                {
                                    documents.map((element) => (
                                        <FormControlLabel key={element.id} value={element.id.toString()} control={<Radio />} label={element.name} />
                                    ))
                                }
                            </RadioGroup>
                        </Box>
                    </Grid>
                    <Grid item flexBasis="50%">
                        <Typography variant="body1">Поля для заполнения:</Typography>
                        {renderVariablesBlock()}
                    </Grid>
                </Grid>
                {previewOpen && selected !== 0 && <Preview name={getElementName()} text={dataToPrint} onSave={(text) => { 
                    if (onPrint !== undefined) onPrint(getElementName(), text);
                    onClose();
                    
                }} onPrint={(text) => {
                    if (onPrint !== undefined) onPrint(getElementName(), text);
                    setDataToPrint(text);

                    setTimeout(() => {
                        handlePrint();
                        onClose();
                    }, 500);
                }}
                
                onClose={() => setPreviewOpen(false)} styles={documentStyle} />}
            </>
        );
    }

    return (
        <>
            <Modal
                open={true}
                title='Выбор документов'
                contentTitle={text}
                closeButtonText='Отмена'
                closeButtonAction={onClose}
                actionButtonText='Предпросмотр'
                actionButtonAction={printAction}
                maxWidth='xl'
                actionButtonEnabled={selected !== 0}
            >
                {renderBody()}
                <ComponentToPrint ref={componentRef as any} text={dataToPrint} styles={documentStyle} />
            </Modal>
        </>
    );
}

export default Document;