import React from 'react';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from './documents'

import "./editor.css"

type RichTextProps = {
    text: string;
    setText: (text: string) => void;
};

const RichText : React.FunctionComponent<RichTextProps> = ({text, setText}) => {
    const [savedEditor, setSavedEditor] = React.useState<any>(text);

    // Ref:
    const toolbar = React.createRef<HTMLDivElement>();

    return (
        <div className="document-editor">
            <div className="document-editor__toolbar" ref={toolbar}></div>
            <div className="document-editor__editable-container">
                <CKEditor
                    onReady={ editor => {
                        if (toolbar.current !== null)
                        {
                            toolbar.current.innerHTML = "";
                            toolbar.current.appendChild((editor.ui.view as any).toolbar.element);
                        }

                        setSavedEditor(editor);
                    } }
                    onError={ ( error, { willEditorRestart } ) => {
                        if ( willEditorRestart ) {
                            savedEditor.ui.view.toolbar.element.remove();
                        }
                    } }
                    onChange={ ( event, editor ) => {
                        setText(editor.getData());
                    }}
                    editor={ Editor }
                    data={text}
                />
            </div>
        </div>
    )
}

export default RichText;