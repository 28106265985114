import React from 'react';
import { OperationType } from 'hex/components/material/types';
import { TableClient } from './types';

import AddIcon from '@mui/icons-material/Add';
import Create from 'hex/components/material/operations/create';
import Update from 'hex/components/material/operations/update';
import { ApolloError } from '@apollo/client';
import { ConvertToFieldErrors, ValidationResultData, Validations, ValidatorCallback } from 'hex/hooks/validator';
import { StringLengthMinMax, StringNotEmpty } from 'hex/validations';
import TextFieldData from 'hex/components/material/operations/fields/text';

import {useAddClientMutation, useUpdateClientMutation, useDeleteClientMutation, useDeleteClientsByIdsMutation, Client_Bool_Exp, InputMaybe} from 'gql';
import Delete from 'hex/components/material/operations/delete';
import { DeleteForever, FilterList } from '@mui/icons-material';
import DeleteSelected from 'hex/components/material/operations/delete_selected';
import Filters from 'hex/components/material/operations/filters';
import TextFilter from 'hex/components/material/operations/filters/text_filter';
import DateInput from 'hex/components/material/operations/fields/date';
import PhoneField from 'hex/components/material/operations/fields/phone';
import PassportField from 'hex/components/material/operations/fields/passport';

export type FilterType = {
    Name: string;
    Email: string;
    Phone: string;
}

export const validations : Validations<TableClient> = [
    ["name", StringLengthMinMax(2, 100)],
    ["name", StringNotEmpty()],
    ["phone_number", StringNotEmpty()],
]

export const renderFields = (model: TableClient, setFieldValue: (field: keyof TableClient) => (value: any) => void, validator: ValidatorCallback<TableClient>, validation: ValidationResultData<TableClient>, disabled?: boolean) => {
    return (
        <>
            <TextFieldData label='ФИО' value={model.name} onChange={setFieldValue("name")} validator={validator("name")} errors={ConvertToFieldErrors("name", validation)} disabled={disabled} />
            <TextFieldData label='Email' value={model.email} onChange={setFieldValue("email")} validator={validator("email")} errors={ConvertToFieldErrors("email", validation)} disabled={disabled} />
            <TextFieldData label='Адрес' value={model.address} onChange={setFieldValue("address")} validator={validator("address")} errors={ConvertToFieldErrors("address", validation)} disabled={disabled} />
            <PhoneField label='Телефон' value={model.phone_number} onChange={setFieldValue("phone_number")} validator={validator("phone_number")} errors={ConvertToFieldErrors("phone_number", validation)} disabled={disabled} />
            <PassportField label='Паспорт: серия и номер' value={model.passport} onChange={setFieldValue("passport")} validator={validator("passport")} errors={ConvertToFieldErrors("passport", validation)} disabled={disabled} />
            <TextFieldData label='Паспорт: где выдан' value={model.passport_where} onChange={setFieldValue("passport_where")} validator={validator("passport_where")} errors={ConvertToFieldErrors("passport_where", validation)} disabled={disabled} />
            <DateInput label='Паспорт: когда выдан' value={model.passport_when} onChange={setFieldValue("passport_when")} disabled={disabled} />
            <TextFieldData label='Код для сканера' value={model.serial} onChange={setFieldValue("serial")} validator={validator("serial")} errors={ConvertToFieldErrors("serial", validation)} disabled={disabled} />
        </>
    );
}

const renderFilterFields = (model: FilterType, setFieldValue: (field: keyof FilterType) => (value: any) => void) => {
    return (
        <>
            <TextFilter label='ФИО' value={model.Name} onChange={setFieldValue("Name")} />
            <TextFilter label='Email' value={model.Email} onChange={setFieldValue("Email")} />
            <TextFilter label='Телефон' value={model.Phone} onChange={setFieldValue("Phone")} />
        </>
    );
}

const renderDeleteText = (model: TableClient) => {
    return `Вы действительно уверены, что хотите удалить клиента ${model.name}?`;
}

const renderDeleteSelectedTitle = () => {
    return `Вы действительно уверены, что хотите удалить следующих клиентов?`;
}

const renderDeleteSelectedText = (model: TableClient) => {
    return `${model.name}`;
}

export function createMutation(model: TableClient)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useAddClientMutation({variables: {
        name: model.name,
        email: model.email,
        passport: model.passport,
        passport_where: model.passport_where,
        passport_when: model.passport_when,
        phone_number: model.phone_number,
        address: model.address,
        serial: model.serial
    }});
}

export function updateMutation(model: TableClient)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useUpdateClientMutation({
        variables: {
            id: model.id,
            name: model.name,
            email: model.email,
            passport: model.passport,
            passport_where: model.passport_where,
            passport_when: model.passport_when,
            phone_number: model.phone_number,
            address: model.address,
            serial: model.serial
        }
    })
}

function deleteMutation(model: TableClient)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useDeleteClientMutation({
        variables: {
            id: model.id,
        }
    });
}

function deleteByIdsMutation(models: Array<TableClient>)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useDeleteClientsByIdsMutation({
        variables: {
            ids: models.map((x) => x.id),
        }
    });
}

export const translateError = (error: ApolloError) => {
    return error.message;
}

export const defaultModel : TableClient = 
{
    id: 0, 
    email: null, 
    name: "", 
    passport: null, 
    passport_when: null,
    passport_where: null, 
    phone_number: "",
    address: null
};

export const defaultFilter : FilterType = {
    Name: "",
    Email: "",
    Phone: ""
}

export function CreateOperation() : OperationType<TableClient>
{
    return {
        type: 'global',
        label: 'Создать',
        icon: (<AddIcon />),
        render: (close) => (
            <Create 
                close={close} 
                defaultModel={defaultModel} 
                translateError={translateError} 
                validations={validations} 
                renderFields={renderFields} 
                useMutation={createMutation} 
            />
        ),
    }
}

export function UpdateOperation() : OperationType<TableClient>
{
    return {
        type: 'individual',
        label: 'Изменить',
        enabled: true,
        render: (model, close) => (
            <Update 
                close={close} 
                defaultModel={model} 
                translateError={translateError}
                validations={validations}
                renderFields={renderFields}
                useMutation={updateMutation}
            />
        ),
    }
}

export function DeleteOperation() : OperationType<TableClient>
{
    return {
        type: 'individual',
        label: 'Удалить',
        enabled: true,
        render: (model, close) => (
            <Delete 
                close={close} 
                model={model}
                translateError={translateError}
                renderText={renderDeleteText}
                useMutation={deleteMutation}
            />
        ),
    }
}

export function DeleteSelectedOperation() : OperationType<TableClient>
{
    return {
        type: 'selected',
        label: 'Удалить выбранное',
        icon: (<DeleteForever />),
        render: (models, close) => (
            <DeleteSelected
                close={close} 
                models={models}
                translateError={translateError}
                renderTitle={renderDeleteSelectedTitle}
                renderText={renderDeleteSelectedText}
                useMutation={deleteByIdsMutation}
            />
        ),
    }
}

export function FiltrationOperation(filter: FilterType, setFilter: React.Dispatch<React.SetStateAction<FilterType>>)  : OperationType<TableClient>
{
    return {
        type: 'global',
        icon: (<FilterList />),
        label: 'Поиск',
        render: (close) => (
            <Filters 
                close={close}
                defaultModel={defaultFilter}
                model={filter}
                setModel={setFilter}
                renderFields={renderFilterFields}
            />
        )
    }
}

export function TranslateFiltrationToWhere(filter: FilterType) : Client_Bool_Exp | undefined
{
    let filtersData: InputMaybe<Client_Bool_Exp> = {};
    let flag = false;

    if (filter.Name.trim() !== "")
    {
        let newData = {
            name: {
                _ilike: `%${filter.Name}%`,
            }
        }

        filtersData = {...filtersData, ...newData}
        flag = true;
    }

    if (filter.Email.trim() !== "")
    {
        let newData = {
            email: {
                _ilike: `%${filter.Email}%`,
            }
        }

        filtersData = {...filtersData, ...newData}
        flag = true;
    }

    if (filter.Phone.trim() !== "")
    {
        let newData = {
            phone_number: {
                _ilike: `%${filter.Phone}%`,
            }
        }

        filtersData = {...filtersData, ...newData}
        flag = true;
    }
    
    if (flag)
        return filtersData;
    else
        return undefined;
}