import React from 'react';
import Title from 'hex/components/title'
import Loading from 'hex/components/loading'

import HexTable from 'hex/components/material/table'
import {PaginationParameters} from 'hex/components/material/table/pagination'

import {useUsersIndexSubscription, useUsersIndexCountSubscription, UsersIndexSubscriptionVariables, Users_Order_By, InputMaybe, Users_Bool_Exp} from 'gql'
import { useSnackbar } from 'notistack';
import { ActionType, GlobalActionType, HeadCell, Order, SelectedActionType } from 'hex/components/material/table/types';

import moment from 'moment-timezone';
import 'moment/locale/ru';

import AddIcon from '@mui/icons-material/Add';

import Create from './create'
import Password from './password';
import { TableUsers } from './types';
import Update from './update';
import Delete from './delete';
import Filter, { defaultModel, FiltersType } from './filter';
import { DeleteForever, FilterList } from '@mui/icons-material';
import DeleteSelected from './delete_selected';
import Departments from './departments'
import PricesBlock from './prices';

const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// Props:
type UsersPageProps = {

};

const headCells : HeadCell<TableUsers>[] = [
    {
        id: 'Name',
        label: "ФИО",
        sort: true,
        render: (user) => (<React.Fragment>{user.Name}</React.Fragment>)
    },
    {
        id: 'Login',
        label: "Логин",
        sort: true,
        render: (user) => (<React.Fragment>{user.Login}</React.Fragment>)
    },
    {
        id: 'IsActive',
        label: "Активен",
        sort: true,
        render: (user) => (<React.Fragment>{user.IsActive ? "Да" : "Нет"}</React.Fragment>)
    },
    {
        id: 'Role',
        label: "Роль",
        sort: true,
        render: (user) => (<React.Fragment>{user.Role.Name}</React.Fragment>)
    },
    {
        id: 'LastSeen',
        label: "Последний вход",
        sort: true,
        render: (user) => (<React.Fragment>{moment.utc(user.LastSeen).tz(clientTimeZone).format("DD-MM-YYYY HH:mm")}</React.Fragment>)
    },
];

const UsersPage: React.FunctionComponent<UsersPageProps> = () => {
    const [paginationState, setPaginationState] = React.useState<PaginationParameters>({limit: 10, offset: 0});
    const [order, setOrder] = React.useState<{orderBy: keyof Users_Order_By; order: Order}>({orderBy: 'Name', order: 'asc'});
    const [createOpen, setCreateOpen] = React.useState<boolean>(false);
    const [passwordOpen, setPasswordOpen] = React.useState<boolean>(false);
    const [departmentOpen, setDepartmentOpen] = React.useState<boolean>(false);
    const [updateOpen, setUpdateOpen] = React.useState<boolean>(false);
    const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);
    const [deleteSelectedOpen, setDeleteSelectedOpen] = React.useState<boolean>(false);
    const [selectedModels, setSelectedModels] = React.useState<TableUsers[] | undefined>(undefined);
    const [filterOpen, setFilterOpen] = React.useState<boolean>(false);
    const [filters, setFilters] = React.useState<InputMaybe<Users_Bool_Exp> | undefined>(undefined);
    const [filtersModel, setFiltersModel] = React.useState<FiltersType>(defaultModel);

    const [pricesOpen, setPricesOpen] = React.useState<boolean>(false);

    // eslint-disable-next-line
    const { enqueueSnackbar } = useSnackbar();

    var variables = React.useMemo<UsersIndexSubscriptionVariables>(() => {
        let result : UsersIndexSubscriptionVariables = {
            ...paginationState
        };

        result.order_by = {};
        result.order_by[order.orderBy] = order.order;

        return result;
    }, [paginationState, order]);

    const { loading, error, data } = useUsersIndexSubscription({
        variables: {
            ...variables,
            where: filters
        }
    });

    const countResult = useUsersIndexCountSubscription({
        variables: {
            where: filters
        }
    });
    
    const models : TableUsers[] | undefined = (data === undefined) ? undefined : data.Users;

    React.useMemo(() => {
        if (error !== undefined)
        {
            enqueueSnackbar(`Ошибка получения данных! ${error.message}`, {variant: 'error'});
        }
    }, [error, enqueueSnackbar]);

    var count = React.useMemo<number>(() => {
        if ((countResult.data !== undefined) && (countResult.data.Users_aggregate.aggregate !== undefined) && (countResult.data.Users_aggregate.aggregate !== null))
            return countResult.data.Users_aggregate.aggregate.count;
        
        return 0;
    }, [countResult]);

    const onPageChange = (limit : number, offset : number) => {
        setPaginationState({limit: limit, offset: offset})
    }

    const onSetOrder = (orderBy: keyof TableUsers, order: Order) => {
        setOrder({orderBy: orderBy as keyof Users_Order_By, order: order});
    }

    const globalActions : Array<GlobalActionType> = [
        {
            label: 'Фильтры',
            icon: (<FilterList />),
            action: () => setFilterOpen(true)
        },
        {
            label: "Добавить", 
            icon: (<AddIcon />), 
            action: () => setCreateOpen(true)
        }
    ];

    const selectedActions : Array<SelectedActionType<TableUsers>> = [
        {
            label: 'Удалить',
            icon: (<DeleteForever />),
            action: (selModels) => {
                setSelectedModels(selModels);
                setDeleteSelectedOpen(true);
            }
        }
    ];

    const [model, setModel] = React.useState<TableUsers | undefined>(undefined);
    const actions : Array<ActionType<TableUsers>> = [
        {
            label: "Установка пароля",
            enabled: true,
            action: (actModel) => {
                setModel(actModel);
                setPasswordOpen(true);
            }
        },
        {
            label: "Редактирование оплаты",
            enabled: true,
            action: (actModel) => {
                setModel(actModel);
                setPricesOpen(true);
            }
        },
        {
            label: "Отделения пользователя",
            enabled: true,
            action: (actModel) => {
                setModel(actModel);
                setDepartmentOpen(true);
            }
        },
        {
            label: "Изменить",
            enabled: true,
            action: (actModel) => {
                setModel(actModel);
                setUpdateOpen(true);
            }
        },
        {
            label: "Удалить",
            enabled: true,
            action: (actModel) => {
                setModel(actModel);
                setDeleteOpen(true);
            }
        }
    ]

    return (
        <React.Fragment>
            <Title title='Пользователи'/>
            <Loading loading={loading} />
            <h1>Пользователи</h1>
            <HexTable {...paginationState} order={order.order} orderBy={order.orderBy as keyof TableUsers} 
                      count={count} onPageChange={onPageChange} models={models} headCells={headCells} onOrderSet={onSetOrder}
                      globalActions={globalActions}
                      selectedActions={selectedActions}
                      actions={actions}
            />
            <Create open={createOpen} close={() => setCreateOpen(false)} />
            {model !== undefined && <Password open={passwordOpen} close={() => {
                setModel(undefined);
                setPasswordOpen(false);
            }} login={model.Login} name={model.Name} />}
            {model !== undefined && <Update open={updateOpen} close={() => {
                setModel(undefined);
                setUpdateOpen(false);
            }} updateModel={model} />}
            {model !== undefined && <Delete open={deleteOpen} close={() => {
                setModel(undefined);
                setDeleteOpen(false);
            }} id={model.ID} name={model.Name} />}
            {selectedModels !== undefined && <DeleteSelected open={deleteSelectedOpen} close={() => {
                setSelectedModels(undefined);
                setDeleteSelectedOpen(false);
            }} models={selectedModels} />}
            <Filter open={filterOpen} close={() => setFilterOpen(false)} filters={filtersModel} setFilters={
                (model) => {
                    setFiltersModel(model);
                    
                    let filtersData: InputMaybe<Users_Bool_Exp> = {};
                    let flag = false;

                    if (model.Name.trim() !== "")
                    {
                        let newData = {
                            Name: {
                                _ilike: `%${model.Name}%`,
                            }
                        }

                        filtersData = {...filtersData, ...newData}
                        flag = true;
                    }

                    if (model.Login.trim() !== "")
                    {
                        let newData = {
                            Login: {
                                _ilike: `%${model.Login}%`,
                            }
                        }

                        filtersData = {...filtersData, ...newData}
                        flag = true;
                    }

                    if (flag)
                        setFilters(filtersData);
                    else
                        setFilters(undefined);
                }
            } />

            {model !== undefined && departmentOpen && <Departments open={departmentOpen} close={() => {
                setModel(undefined);
                setDepartmentOpen(false);
            }} user_id={model.ID} name={model.Name} />}
            {pricesOpen && model !== undefined && <PricesBlock doctor_id={model.ID} onClose={() => {
                setModel(undefined);
                setPricesOpen(false);
            }} />}
        </React.Fragment>
    )
}

export default UsersPage;