import moment from 'moment-timezone';
import 'moment/locale/ru';

export const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const dateDiff = (startdate : Date, enddate : Date) => {
    //define moments for the startdate and enddate
    var startdateMoment = moment.utc(startdate).tz(clientTimeZone);
    var enddateMoment = moment.utc(enddate).tz(clientTimeZone);

    if (startdateMoment.isValid() === true && enddateMoment.isValid() === true) {
        var result = "";
        var duration = moment.duration(enddateMoment.diff(startdateMoment));

        var years = duration.years();
        var months = duration.months();
        var days = duration.days();

        if (years !== 0)
            result += `${years.toString()} лет `;

        if ((months !== 0))
            result += `${months.toString()} месяцев`;
        
        if ((months === 0) && (years === 0))
            result += `${days.toString()} дней`;


        return result.trim();
    }
    else {
        return "<Не указано>";
    }
}