import { Box, CircularProgress, Typography } from "@mui/material";
import { useTakeVisitForInformationSubscription } from "gql";
import { useErrorAndLoadChecking } from "hex/components/material/hooks";
import Modal from "hex/components/material/modal";
import ReceptionView from "pages/receptions_edit/reception_view";
import React from "react";
import DocumentBlock from "./document_block";


// Props:
type DocumentsProps = {
    onClose: () => void;
    visit_id: number;
    disabled: boolean;
};


const Document: React.FunctionComponent<DocumentsProps> = ({ onClose, visit_id, disabled }) => {
    const { data, loading, error } = useTakeVisitForInformationSubscription({
        variables: {
            id: visit_id
        },
    });

    useErrorAndLoadChecking({ error, loading });

    const renderBody = () => {
        if (data === undefined)
            return (
                <>
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>
                </>
            );

        if (data.Visit.length === 0)
            return (
                <>
                    <Typography>Запись на прием не найдена!</Typography>
                </>
            );

        return (
            <>
                <ReceptionView visit={data.Visit[0]} isEditingIsDisabled onCommonInformationEdit={() => {}} onReceptionEdit={() => {}} ShowAddDocumentsAndAddFilesButtons={!disabled} documentsBlock={(props) => (<DocumentBlock visit_id={props.visit_id} onClose={props.onClose} />)} />
            </>
        );
    }

    return (
        <>
            <Modal fullScreen open title="Просмотр информации о приеме" contentTitle="Данные приема" closeButtonText="Закрыть" closeButtonAction={onClose}>
                {renderBody()}
            </Modal>
        </>
    );
};

export default Document;