import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { FormControl, FormGroup, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';

function randomIntFromInterval(min : number, max : number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

// Props:
type TextFilterProps = {
    label?: string;
    value: any | undefined;
    onChange: (value: any | undefined) => any | undefined;
};

// Element:
const TextFilter : React.FunctionComponent<TextFilterProps> = ({label, value, onChange}) => {
    const id = React.useId();

    const onChangeEventWrapper = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChange(event.target.value);
    };

    const handleClickClear = () => onChange("");

    const handleMouseDownClear = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <FormGroup>
            <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                <InputLabel htmlFor={`outlined-adornment-${id}`}>{label}</InputLabel>
                <OutlinedInput
                        id={`outlined-filter-text-${id}`}
                        type='text'
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="Очистить"
                            onClick={handleClickClear}
                            onMouseDown={handleMouseDownClear}
                            edge="end"
                            >
                                <ClearIcon />
                            </IconButton>
                        </InputAdornment>
                        }
                        label={label}
                        value={value || ""}
                        onChange={onChangeEventWrapper}
                        autoComplete="off"
                    />
            </FormControl>
        </FormGroup>
    );
}

export default TextFilter;