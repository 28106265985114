import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Slide, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import HorizontalLinearStepper, { Step } from 'hex/components/material/stepper';
import React from 'react';
import DoctorStep from './steps/doctor';
import DateStep, { DateStepState, useDateStep } from './steps/date';
import { StepData } from './steps/types';
import { MoveToData, defaultMoveTo } from './types';
import { defaultVisitData } from '../registrator/actions/make_visit';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function madeSteps(props: StepData, stepsState: {date: DateStepState}) : Array<Step>
{
    const {date} = stepsState;

    return [
        {
            name: "Выбор отдела и врача",
            optional: false,
            body: (<DoctorStep {...props} />),
            isNextEnabled: () => props.visitData.department_id !== 0,
            actionOnNext: async () => true
        },
        {
            name: "Выбор даты и времени",
            optional: false,
            body: (<DateStep {...props} {...date}/>),
            isNextEnabled: date.isEnabled,
            actionOnNext: date.onNext
        },
        {
            name: "Завершение",
            optional: false,
            body: (<Typography>Запись на прием успешно создана!</Typography>),
            isNextEnabled: () => true,
            disableBack: true
        },
    ];
}

type MoveToProps = {
    visit_id: number;
    open: boolean;
    onDone: () => void;
    onCancel: () => void;
    forwardDisable?: boolean;
};

const MoveTo: React.FunctionComponent<MoveToProps> = ({visit_id, open, onDone, onCancel, forwardDisable}) => {
    const [visitData, setVisitData] = React.useState<MoveToData>(defaultMoveTo);
    const dateStep = useDateStep({visitData, setVisit: setVisitData, onDone}, visit_id, forwardDisable);

    const steps = React.useMemo(() => {
        return madeSteps({visitData, setVisit: setVisitData, onDone}, {date: dateStep});
    }, [visitData, setVisitData, dateStep]);
    
    return (
        <Dialog open={open} onClose={() => {
            if (dateStep.done)
            {
                onDone();
            }
            else
            {
                onCancel();
            }
        }} TransitionComponent={Transition} fullWidth maxWidth='lg'>
            <DialogTitle>{'Направить к специалисту'}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ marginBottom: 1 }}>{'Выбор специалиста, даты и времени приема'}</DialogContentText>
                <Divider sx={{ marginBottom: 3 }} />
                <HorizontalLinearStepper onClose={onCancel} steps={steps}/>
            </DialogContent>
        </Dialog>
    );
}

export default MoveTo;