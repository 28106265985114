import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import HorizontalLinearStepper, { Step } from 'hex/components/material/stepper';
import { Typography } from '@mui/material';
import { VisitData } from './types';
import { StepData } from './steps/types';
import ClientStep, { ClientStepState, useClientStep } from './steps/client';
import PetStep, { PetStepState, usePetStep } from './steps/pet';
import DoctorStep from './steps/doctor';
import DateStep, { DateStepState, dateFormat, timeFormat, useDateStep } from './steps/date';
import moment from 'moment';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function roundTimeQuarterHour(time : Date) {
    var timeToReturn = new Date(time);

    timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 5) * 5);

    return timeToReturn;
}

type MakeVisitProps = {
    open: boolean;
    close: () => void;
};

function madeSteps(props: StepData, stepsState: {client: ClientStepState, pet: PetStepState, date: DateStepState}) : Array<Step>
{
    const {client, pet, date} = stepsState;

    return [
        {
            name: "Поиск/создание клиента",
            optional: false,
            body: (<ClientStep {...props} {...client}/>),
            isNextEnabled: client.isEnabled,
            actionOnNext: client.onNext
        },
        {
            name: "Поиск/создание пациента",
            optional: false,
            body: (<PetStep {...props} {...pet}/>),
            isNextEnabled: pet.isEnabled,
            actionOnNext: pet.onNext
        },
        {
            name: "Выбор отдела и врача",
            optional: false,
            body: (<DoctorStep {...props} />),
            isNextEnabled: () => props.visitData.department_id !== 0,
            actionOnNext: async () => true
        },
        {
            name: "Выбор даты и времени",
            optional: false,
            body: (<DateStep {...props} {...date}/>),
            isNextEnabled: date.isEnabled,
            actionOnNext: date.onNext
        },
        {
            name: "Завершение",
            optional: false,
            body: (<Typography>Запись на прием успешно создана!</Typography>),
            isNextEnabled: () => true,
            disableBack: true
        },
    ];
}

export const defaultVisitData : () => VisitData = () => ({
    from_id: '',
    client_id: 0,
    patient_id: 0,

    department_id: 0,
    doctor_id: undefined,

    date: moment(Date.now()).format(dateFormat),
    time: moment(roundTimeQuarterHour(new Date(Date.now()))).format(timeFormat),
    without_time: false
});

const MakeVisit: React.FunctionComponent<MakeVisitProps> = ({open, close}) => {
    const [visitData, setVisitData] = React.useState<VisitData>(defaultVisitData);

    const clientStepData = useClientStep({visitData, setVisit: setVisitData});
    const petStepData = usePetStep({visitData, setVisit: setVisitData});
    const dateStep = useDateStep({visitData, setVisit: setVisitData});

    const steps = React.useMemo(() => {
        return madeSteps({visitData, setVisit: setVisitData}, {client: clientStepData, pet: petStepData, date: dateStep});
    }, [visitData, setVisitData, clientStepData, petStepData, dateStep]);

    return (
        <Dialog open={open} onClose={close} TransitionComponent={Transition} fullWidth maxWidth='lg'>
            <DialogTitle>{'Запись на приём'}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{marginBottom: 1}}>{'Внести запись на прием'}</DialogContentText>
                <Divider sx={{marginBottom: 3}} />
                <HorizontalLinearStepper onClose={close} steps={steps}/>
            </DialogContent>
        </Dialog>
    );
}

export default MakeVisit;