import React from 'react';
import {TitleContextType} from './types';

// Context:
export const HexTitleContext = React.createContext<TitleContextType>({text: "Загрузка...", setText: (arg) => {}});

// Props:
type HexTitleProviderProps = {
    children?: React.ReactNode
};

const titlePostfix = "Hex.Universal";

class HexTitleProvider extends React.Component<HexTitleProviderProps, TitleContextType> {

    setText: (arg: string) => void = (arg) => {};

    constructor(props: HexTitleProviderProps) {
        super(props);

        this.setText = (arg) => {
            document.title = `${arg} - ${titlePostfix}`;
            this.setState(state => ({
                text: arg,
                setText: state.setText
            }));
        };

        this.state = {
            text: "Загрузка...",
            setText: this.setText,
        };
    };

    render()
    {
        return (
            <HexTitleContext.Provider value={this.state}>
                {this.props.children}
            </HexTitleContext.Provider>
        );
    }
};

export default HexTitleProvider;