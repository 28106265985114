import { ConvertToFieldErrors, ValidationResultData, Validations, ValidatorCallback } from 'hex/hooks/validator';
import { NumberNotNegative, NumberNotZero } from 'hex/validations';
import NumericFieldData from 'hex/components/material/operations/fields/numeric';

import { MtmMedicines } from '../types';
import { ManyToManyFieldProps } from 'hex/components/material/operations/fields/many_to_many';
import ForeignKeyModal from 'hex/components/material/operations/fields/foreign_key_modal';
import { MedicineFkData } from './foreign_keys';
import { ListItemText } from '@mui/material';
import { AddTemplateMutation, UpdateTemplateMutation, useAddTemplateMedicinesMutation, useDeleteTemplateMedicineMutation, useUpdateTemplateMedicineMutation } from 'gql';


const validations : Validations<MtmMedicines> = [
    ["value", NumberNotZero()],
    ["value", NumberNotNegative()],
]

const renderFields = (model: MtmMedicines, setFieldValue: (field: keyof MtmMedicines) => (value: any) => void, validator: ValidatorCallback<MtmMedicines>, validation: ValidationResultData<MtmMedicines>) => {
    return (
        <>
            <ForeignKeyModal label='Препарат' value={model.medicine_id} onChange={setFieldValue("medicine_id")} {...MedicineFkData} onChangeModel={setFieldValue("Medicine")} />
            <NumericFieldData label='Значение' value={model.value} onChange={setFieldValue("value")} validator={validator("value")} errors={ConvertToFieldErrors("value", validation)} decimal />
        </>
    );
}

const renderModel = (model: MtmMedicines) => {
    return (
        <ListItemText primary={`Препарат: ${model.Medicine.name} Значение: ${model.value ? model.value : '<Отсутствует>'}`} />
    );
}

const useDeleteMutation = (model: MtmMedicines) => {
    return useDeleteTemplateMedicineMutation({
        variables: {
            id: model.id,
        }
    });
}

const useUpdateMutation = (model: MtmMedicines) => {
    return useUpdateTemplateMedicineMutation({
        variables: {
            id: model.id,
            medicine_id: model.medicine_id,
            value: model.value
        }
    })
}

const useAddMutation = (models: Array<MtmMedicines>) => {
    return useAddTemplateMedicinesMutation({
        variables: {
            objects: models.map((element) => {
                return {
                    medicine_id: element.medicine_id,
                    template_id: element.template_id,
                    value: element.value,
                }
            })
        }
    })
}

const setParentID: (models: Array<MtmMedicines>, parentId: any) => Array<MtmMedicines> = (models: Array<MtmMedicines>, parentId: any) => {
    var newArray = [...models];

    newArray.forEach((element) => element.template_id = parentId);

    return newArray.map((element) => {
        return {
            medicine_id: element.medicine_id,
            template_id: element.template_id,
            value: element.value,
        } as any
    });
}

const getIdFromNotifyer = (data: any) => {
    {
        const returnData : AddTemplateMutation = data.data;
    
        if (returnData.insert_Template && returnData.insert_Template.returning.length !== 0)
            return returnData.insert_Template.returning[0].id;
    }
    {
        const returnData : UpdateTemplateMutation = data.data;
        if (returnData.update_Template && returnData.update_Template.returning.length !== 0)
            return returnData.update_Template.returning[0].id;
    }
    
    return 0;
}


const defaultModel : MtmMedicines = {
    id: 0,
    template_id: 0,
    medicine_id: 0,
    value: undefined,
    Medicine: {
        name: "",
        price: 0,
        id: 0,
    },
};

const defaultMtm : Omit<ManyToManyFieldProps<MtmMedicines>, "baseModelID" | "values" | "operationNotifierAfterCreation"> = {
    label: "Препараты",
    renderFields,
    renderModel,
    defaultModel,
    validations,
    useAddMutation,
    useDeleteMutation,
    useUpdateMutation,
    setParentID,
    getIdFromNotifyer
}

export default defaultMtm;