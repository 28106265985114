import React from 'react';
import {usePetTypeFkSubscription, useClientFkSubscription, usePetTypeModelFkQuery, useClientModelFkQuery} from 'gql'
import { ListItemText, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import moment from 'moment-timezone';
import 'moment/locale/ru';
import { useErrorAndLoadChecking } from 'hex/components/material/hooks';

const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// PetType:
export type PetTypeFk = {
    id: number;
    name: string;
};

export function RenderPetTypeModel(model: PetTypeFk) : React.ReactNode 
{
    return (
        <ListItemText primary={model.name} />
    );
}

export function RenderSelectedPetTypeModel(model: PetTypeFk) : string
{
    return model.name;
}

export function usePetTypeSearch(searchText: string) : Array<PetTypeFk> | undefined
{
    // eslint-disable-next-line
    const { enqueueSnackbar } = useSnackbar();
    const {data, loading, error} = usePetTypeFkSubscription({variables: {searchText: `%${searchText}%`}});

    React.useMemo(() => {
        if (error !== undefined)
        {
            enqueueSnackbar(`Ошибка получения данных! ${error.message}`, {variant: 'error'});
        }
    }, [error, enqueueSnackbar]);

    if ((loading === false) && (data !== undefined))
        return data.PetType;

    return undefined;
}

export function usePetTypeModel(id: any) : PetTypeFk | undefined
{
    const idCalc = id !== 0 && id !== "" ? id as number : 0;

    const {data, loading, error} = usePetTypeModelFkQuery({variables: {id: idCalc}, fetchPolicy: 'network-only'});
    useErrorAndLoadChecking({loading, error});

    if ((data !== undefined) && (data.PetType_by_pk))
        return data.PetType_by_pk;

    return undefined;
}

// Client:
export type ClientFk = {
    id: number;
    name: string;
    email?: string | null;
    passport?: string | null;
    passport_when?: any;
    passport_where?: string | null;
    phone_number: string;
};

export function RenderClientModel(model: ClientFk) : React.ReactNode 
{
    return (
        <ListItemText primary={model.name} secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {model.phone_number}{(model.email ? ` - ${model.email}` : "")}
              </Typography>
              {` Паспорт: ${model.passport} от ${moment.utc(model.passport_when).tz(clientTimeZone).format("DD-MM-YYYY")}`}
            </React.Fragment>
          } />
    );
}

export function RenderSelectedClientModel(model: ClientFk) : string
{
    return model.name;
}

export function useClientSearch(searchText: string) : Array<ClientFk> | undefined
{
    // eslint-disable-next-line
    const { enqueueSnackbar } = useSnackbar();
    const {data, loading, error} = useClientFkSubscription({variables: {searchText: `%${searchText}%`}});

    React.useMemo(() => {
        if (error !== undefined)
        {
            enqueueSnackbar(`Ошибка получения данных! ${error.message}`, {variant: 'error'});
        }
    }, [error, enqueueSnackbar]);

    if ((loading === false) && (data !== undefined))
        return data.Client;

    return undefined;
}

export function useClientModel(id: any) : ClientFk | undefined
{
    const idCalc = id !== 0 && id !== "" ? id as number : 0;

    const {data, loading, error} = useClientModelFkQuery({variables: {id: idCalc}, fetchPolicy: 'network-only'});
    useErrorAndLoadChecking({loading, error});

    if ((data !== undefined) && (data.Client_by_pk))
        return data.Client_by_pk;

    return undefined;
}