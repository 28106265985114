import React from 'react';
import { Typography } from '@mui/material';
import moment from 'moment'
import UsernameBlock from 'startup/username';


// Props:
type TimerProps = {
    
};

const translateDateToStr = (date: Date) => {
    return moment(date).format("HH:mm:ss DD-MM-YYYY");
}

const Timer : React.FunctionComponent<TimerProps> = ({}) => 
{
    const [date, setDate] = React.useState<Date>(new Date());

    function refreshClock() {
        setDate(new Date());
    }

    React.useEffect(() => {
        const timerId = setInterval(refreshClock, 1000);

        return function cleanup() {
            clearInterval(timerId);
        };
      }, []);

    return (
        <Typography component="h1" variant="h6" color="inherit">
            <UsernameBlock /> - {translateDateToStr(date)}
        </Typography>
    );
};

export default Timer;