import { Typography } from '@mui/material';
import { useGetUserNameQuery } from 'gql';
import { useErrorAndLoadChecking } from 'hex/components/material/hooks';
import { useAuth } from 'hex/hooks/auth';
import React from 'react';


type UsernameBlockProps = {
    
};

const UsernameBlock: React.FunctionComponent<UsernameBlockProps> = ({}) => {
    const auth = useAuth();

    const {data, loading, error} = useGetUserNameQuery({
        variables: {
            ID: auth.getUserId(),
        }
    });

    useErrorAndLoadChecking({loading, error});

    return (
        <>{data && data.Users_by_pk ? data.Users_by_pk.Name : '<Загрузка...>'}</>
    )
}

export default UsernameBlock;
