import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  date: { input: any; output: any; }
  float8: { input: any; output: any; }
  jsonb: { input: any; output: any; }
  time: { input: any; output: any; }
  timestamp: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
  timetz: { input: any; output: any; }
  uuid: { input: any; output: any; }
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export interface Boolean_Comparison_Exp {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
}

/** columns and relationships of "ChatMessages" */
export interface ChatMessages {
  __typename?: 'ChatMessages';
  /** An object relationship */
  User: Users;
  date: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  user_id: Scalars['uuid']['output'];
}

/** aggregated selection of "ChatMessages" */
export interface ChatMessages_Aggregate {
  __typename?: 'ChatMessages_aggregate';
  aggregate?: Maybe<ChatMessages_Aggregate_Fields>;
  nodes: Array<ChatMessages>;
}

/** aggregate fields of "ChatMessages" */
export interface ChatMessages_Aggregate_Fields {
  __typename?: 'ChatMessages_aggregate_fields';
  avg?: Maybe<ChatMessages_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ChatMessages_Max_Fields>;
  min?: Maybe<ChatMessages_Min_Fields>;
  stddev?: Maybe<ChatMessages_Stddev_Fields>;
  stddev_pop?: Maybe<ChatMessages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ChatMessages_Stddev_Samp_Fields>;
  sum?: Maybe<ChatMessages_Sum_Fields>;
  var_pop?: Maybe<ChatMessages_Var_Pop_Fields>;
  var_samp?: Maybe<ChatMessages_Var_Samp_Fields>;
  variance?: Maybe<ChatMessages_Variance_Fields>;
}


/** aggregate fields of "ChatMessages" */
export interface ChatMessages_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<ChatMessages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate avg on columns */
export interface ChatMessages_Avg_Fields {
  __typename?: 'ChatMessages_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to filter rows from the table "ChatMessages". All fields are combined with a logical 'AND'. */
export interface ChatMessages_Bool_Exp {
  User?: InputMaybe<Users_Bool_Exp>;
  _and?: InputMaybe<Array<ChatMessages_Bool_Exp>>;
  _not?: InputMaybe<ChatMessages_Bool_Exp>;
  _or?: InputMaybe<Array<ChatMessages_Bool_Exp>>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "ChatMessages" */
export type ChatMessages_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'ChatMessages_pkey';

/** input type for incrementing numeric columns in table "ChatMessages" */
export interface ChatMessages_Inc_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "ChatMessages" */
export interface ChatMessages_Insert_Input {
  User?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface ChatMessages_Max_Fields {
  __typename?: 'ChatMessages_max_fields';
  date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** aggregate min on columns */
export interface ChatMessages_Min_Fields {
  __typename?: 'ChatMessages_min_fields';
  date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** response of any mutation on the table "ChatMessages" */
export interface ChatMessages_Mutation_Response {
  __typename?: 'ChatMessages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ChatMessages>;
}

/** on_conflict condition type for table "ChatMessages" */
export interface ChatMessages_On_Conflict {
  constraint: ChatMessages_Constraint;
  update_columns?: Array<ChatMessages_Update_Column>;
  where?: InputMaybe<ChatMessages_Bool_Exp>;
}

/** Ordering options when selecting data from "ChatMessages". */
export interface ChatMessages_Order_By {
  User?: InputMaybe<Users_Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: ChatMessages */
export interface ChatMessages_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "ChatMessages" */
export type ChatMessages_Select_Column =
  /** column name */
  | 'date'
  /** column name */
  | 'id'
  /** column name */
  | 'message'
  /** column name */
  | 'user_id';

/** input type for updating data in table "ChatMessages" */
export interface ChatMessages_Set_Input {
  date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate stddev on columns */
export interface ChatMessages_Stddev_Fields {
  __typename?: 'ChatMessages_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_pop on columns */
export interface ChatMessages_Stddev_Pop_Fields {
  __typename?: 'ChatMessages_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_samp on columns */
export interface ChatMessages_Stddev_Samp_Fields {
  __typename?: 'ChatMessages_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** Streaming cursor of the table "ChatMessages" */
export interface ChatMessages_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: ChatMessages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface ChatMessages_Stream_Cursor_Value_Input {
  date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate sum on columns */
export interface ChatMessages_Sum_Fields {
  __typename?: 'ChatMessages_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
}

/** update columns of table "ChatMessages" */
export type ChatMessages_Update_Column =
  /** column name */
  | 'date'
  /** column name */
  | 'id'
  /** column name */
  | 'message'
  /** column name */
  | 'user_id';

export interface ChatMessages_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ChatMessages_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChatMessages_Set_Input>;
  where: ChatMessages_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface ChatMessages_Var_Pop_Fields {
  __typename?: 'ChatMessages_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate var_samp on columns */
export interface ChatMessages_Var_Samp_Fields {
  __typename?: 'ChatMessages_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate variance on columns */
export interface ChatMessages_Variance_Fields {
  __typename?: 'ChatMessages_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** Клиент организации */
export interface Client {
  __typename?: 'Client';
  /** An array relationship */
  Pets: Array<Pet>;
  /** An aggregate relationship */
  Pets_aggregate: Pet_Aggregate;
  address?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  passport?: Maybe<Scalars['String']['output']>;
  passport_when?: Maybe<Scalars['date']['output']>;
  passport_where?: Maybe<Scalars['String']['output']>;
  phone_number: Scalars['String']['output'];
  serial?: Maybe<Scalars['String']['output']>;
}


/** Клиент организации */
export interface ClientPetsArgs {
  distinct_on?: InputMaybe<Array<Pet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Pet_Order_By>>;
  where?: InputMaybe<Pet_Bool_Exp>;
}


/** Клиент организации */
export interface ClientPets_AggregateArgs {
  distinct_on?: InputMaybe<Array<Pet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Pet_Order_By>>;
  where?: InputMaybe<Pet_Bool_Exp>;
}

/** aggregated selection of "Client" */
export interface Client_Aggregate {
  __typename?: 'Client_aggregate';
  aggregate?: Maybe<Client_Aggregate_Fields>;
  nodes: Array<Client>;
}

/** aggregate fields of "Client" */
export interface Client_Aggregate_Fields {
  __typename?: 'Client_aggregate_fields';
  avg?: Maybe<Client_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Client_Max_Fields>;
  min?: Maybe<Client_Min_Fields>;
  stddev?: Maybe<Client_Stddev_Fields>;
  stddev_pop?: Maybe<Client_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Client_Stddev_Samp_Fields>;
  sum?: Maybe<Client_Sum_Fields>;
  var_pop?: Maybe<Client_Var_Pop_Fields>;
  var_samp?: Maybe<Client_Var_Samp_Fields>;
  variance?: Maybe<Client_Variance_Fields>;
}


/** aggregate fields of "Client" */
export interface Client_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Client_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate avg on columns */
export interface Client_Avg_Fields {
  __typename?: 'Client_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to filter rows from the table "Client". All fields are combined with a logical 'AND'. */
export interface Client_Bool_Exp {
  Pets?: InputMaybe<Pet_Bool_Exp>;
  Pets_aggregate?: InputMaybe<Pet_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Client_Bool_Exp>>;
  _not?: InputMaybe<Client_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  passport?: InputMaybe<String_Comparison_Exp>;
  passport_when?: InputMaybe<Date_Comparison_Exp>;
  passport_where?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  serial?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "Client" */
export type Client_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'Client_pkey';

/** input type for incrementing numeric columns in table "Client" */
export interface Client_Inc_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "Client" */
export interface Client_Insert_Input {
  Pets?: InputMaybe<Pet_Arr_Rel_Insert_Input>;
  address?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  passport?: InputMaybe<Scalars['String']['input']>;
  passport_when?: InputMaybe<Scalars['date']['input']>;
  passport_where?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  serial?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface Client_Max_Fields {
  __typename?: 'Client_max_fields';
  address?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  passport?: Maybe<Scalars['String']['output']>;
  passport_when?: Maybe<Scalars['date']['output']>;
  passport_where?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  serial?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface Client_Min_Fields {
  __typename?: 'Client_min_fields';
  address?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  passport?: Maybe<Scalars['String']['output']>;
  passport_when?: Maybe<Scalars['date']['output']>;
  passport_where?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  serial?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "Client" */
export interface Client_Mutation_Response {
  __typename?: 'Client_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Client>;
}

/** input type for inserting object relation for remote table "Client" */
export interface Client_Obj_Rel_Insert_Input {
  data: Client_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_On_Conflict>;
}

/** on_conflict condition type for table "Client" */
export interface Client_On_Conflict {
  constraint: Client_Constraint;
  update_columns?: Array<Client_Update_Column>;
  where?: InputMaybe<Client_Bool_Exp>;
}

/** Ordering options when selecting data from "Client". */
export interface Client_Order_By {
  Pets_aggregate?: InputMaybe<Pet_Aggregate_Order_By>;
  address?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  passport?: InputMaybe<Order_By>;
  passport_when?: InputMaybe<Order_By>;
  passport_where?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  serial?: InputMaybe<Order_By>;
}

/** primary key columns input for table: Client */
export interface Client_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "Client" */
export type Client_Select_Column =
  /** column name */
  | 'address'
  /** column name */
  | 'email'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'passport'
  /** column name */
  | 'passport_when'
  /** column name */
  | 'passport_where'
  /** column name */
  | 'phone_number'
  /** column name */
  | 'serial';

/** input type for updating data in table "Client" */
export interface Client_Set_Input {
  address?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  passport?: InputMaybe<Scalars['String']['input']>;
  passport_when?: InputMaybe<Scalars['date']['input']>;
  passport_where?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  serial?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate stddev on columns */
export interface Client_Stddev_Fields {
  __typename?: 'Client_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_pop on columns */
export interface Client_Stddev_Pop_Fields {
  __typename?: 'Client_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_samp on columns */
export interface Client_Stddev_Samp_Fields {
  __typename?: 'Client_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** Streaming cursor of the table "Client" */
export interface Client_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Client_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Client_Stream_Cursor_Value_Input {
  address?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  passport?: InputMaybe<Scalars['String']['input']>;
  passport_when?: InputMaybe<Scalars['date']['input']>;
  passport_where?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  serial?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate sum on columns */
export interface Client_Sum_Fields {
  __typename?: 'Client_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
}

/** update columns of table "Client" */
export type Client_Update_Column =
  /** column name */
  | 'address'
  /** column name */
  | 'email'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'passport'
  /** column name */
  | 'passport_when'
  /** column name */
  | 'passport_where'
  /** column name */
  | 'phone_number'
  /** column name */
  | 'serial';

export interface Client_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Client_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Set_Input>;
  where: Client_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Client_Var_Pop_Fields {
  __typename?: 'Client_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate var_samp on columns */
export interface Client_Var_Samp_Fields {
  __typename?: 'Client_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate variance on columns */
export interface Client_Variance_Fields {
  __typename?: 'Client_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** Отдел */
export interface Department {
  __typename?: 'Department';
  /** An array relationship */
  DepartmentDocuments: Array<DepartmentDocument>;
  /** An aggregate relationship */
  DepartmentDocuments_aggregate: DepartmentDocument_Aggregate;
  /** An array relationship */
  DoctorsDepartments: Array<DoctorsDepartment>;
  /** An aggregate relationship */
  DoctorsDepartments_aggregate: DoctorsDepartment_Aggregate;
  /** An array relationship */
  Visits: Array<Visit>;
  /** An aggregate relationship */
  Visits_aggregate: Visit_Aggregate;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}


/** Отдел */
export interface DepartmentDepartmentDocumentsArgs {
  distinct_on?: InputMaybe<Array<DepartmentDocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentDocument_Order_By>>;
  where?: InputMaybe<DepartmentDocument_Bool_Exp>;
}


/** Отдел */
export interface DepartmentDepartmentDocuments_AggregateArgs {
  distinct_on?: InputMaybe<Array<DepartmentDocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentDocument_Order_By>>;
  where?: InputMaybe<DepartmentDocument_Bool_Exp>;
}


/** Отдел */
export interface DepartmentDoctorsDepartmentsArgs {
  distinct_on?: InputMaybe<Array<DoctorsDepartment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DoctorsDepartment_Order_By>>;
  where?: InputMaybe<DoctorsDepartment_Bool_Exp>;
}


/** Отдел */
export interface DepartmentDoctorsDepartments_AggregateArgs {
  distinct_on?: InputMaybe<Array<DoctorsDepartment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DoctorsDepartment_Order_By>>;
  where?: InputMaybe<DoctorsDepartment_Bool_Exp>;
}


/** Отдел */
export interface DepartmentVisitsArgs {
  distinct_on?: InputMaybe<Array<Visit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Visit_Order_By>>;
  where?: InputMaybe<Visit_Bool_Exp>;
}


/** Отдел */
export interface DepartmentVisits_AggregateArgs {
  distinct_on?: InputMaybe<Array<Visit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Visit_Order_By>>;
  where?: InputMaybe<Visit_Bool_Exp>;
}

/** Документы по разделам */
export interface DepartmentDocument {
  __typename?: 'DepartmentDocument';
  /** An object relationship */
  Department?: Maybe<Department>;
  /** An object relationship */
  Document: Documents;
  department_id?: Maybe<Scalars['Int']['output']>;
  document_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
}

/** aggregated selection of "DepartmentDocument" */
export interface DepartmentDocument_Aggregate {
  __typename?: 'DepartmentDocument_aggregate';
  aggregate?: Maybe<DepartmentDocument_Aggregate_Fields>;
  nodes: Array<DepartmentDocument>;
}

export interface DepartmentDocument_Aggregate_Bool_Exp {
  count?: InputMaybe<DepartmentDocument_Aggregate_Bool_Exp_Count>;
}

export interface DepartmentDocument_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<DepartmentDocument_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<DepartmentDocument_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "DepartmentDocument" */
export interface DepartmentDocument_Aggregate_Fields {
  __typename?: 'DepartmentDocument_aggregate_fields';
  avg?: Maybe<DepartmentDocument_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<DepartmentDocument_Max_Fields>;
  min?: Maybe<DepartmentDocument_Min_Fields>;
  stddev?: Maybe<DepartmentDocument_Stddev_Fields>;
  stddev_pop?: Maybe<DepartmentDocument_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DepartmentDocument_Stddev_Samp_Fields>;
  sum?: Maybe<DepartmentDocument_Sum_Fields>;
  var_pop?: Maybe<DepartmentDocument_Var_Pop_Fields>;
  var_samp?: Maybe<DepartmentDocument_Var_Samp_Fields>;
  variance?: Maybe<DepartmentDocument_Variance_Fields>;
}


/** aggregate fields of "DepartmentDocument" */
export interface DepartmentDocument_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<DepartmentDocument_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "DepartmentDocument" */
export interface DepartmentDocument_Aggregate_Order_By {
  avg?: InputMaybe<DepartmentDocument_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DepartmentDocument_Max_Order_By>;
  min?: InputMaybe<DepartmentDocument_Min_Order_By>;
  stddev?: InputMaybe<DepartmentDocument_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DepartmentDocument_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DepartmentDocument_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DepartmentDocument_Sum_Order_By>;
  var_pop?: InputMaybe<DepartmentDocument_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DepartmentDocument_Var_Samp_Order_By>;
  variance?: InputMaybe<DepartmentDocument_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "DepartmentDocument" */
export interface DepartmentDocument_Arr_Rel_Insert_Input {
  data: Array<DepartmentDocument_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DepartmentDocument_On_Conflict>;
}

/** aggregate avg on columns */
export interface DepartmentDocument_Avg_Fields {
  __typename?: 'DepartmentDocument_avg_fields';
  department_id?: Maybe<Scalars['Float']['output']>;
  document_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "DepartmentDocument" */
export interface DepartmentDocument_Avg_Order_By {
  department_id?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** Boolean expression to filter rows from the table "DepartmentDocument". All fields are combined with a logical 'AND'. */
export interface DepartmentDocument_Bool_Exp {
  Department?: InputMaybe<Department_Bool_Exp>;
  Document?: InputMaybe<Documents_Bool_Exp>;
  _and?: InputMaybe<Array<DepartmentDocument_Bool_Exp>>;
  _not?: InputMaybe<DepartmentDocument_Bool_Exp>;
  _or?: InputMaybe<Array<DepartmentDocument_Bool_Exp>>;
  department_id?: InputMaybe<Int_Comparison_Exp>;
  document_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
}

/** unique or primary key constraints on table "DepartmentDocument" */
export type DepartmentDocument_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'DepartmentDocument_pkey';

/** input type for incrementing numeric columns in table "DepartmentDocument" */
export interface DepartmentDocument_Inc_Input {
  department_id?: InputMaybe<Scalars['Int']['input']>;
  document_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "DepartmentDocument" */
export interface DepartmentDocument_Insert_Input {
  Department?: InputMaybe<Department_Obj_Rel_Insert_Input>;
  Document?: InputMaybe<Documents_Obj_Rel_Insert_Input>;
  department_id?: InputMaybe<Scalars['Int']['input']>;
  document_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate max on columns */
export interface DepartmentDocument_Max_Fields {
  __typename?: 'DepartmentDocument_max_fields';
  department_id?: Maybe<Scalars['Int']['output']>;
  document_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
}

/** order by max() on columns of table "DepartmentDocument" */
export interface DepartmentDocument_Max_Order_By {
  department_id?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface DepartmentDocument_Min_Fields {
  __typename?: 'DepartmentDocument_min_fields';
  department_id?: Maybe<Scalars['Int']['output']>;
  document_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
}

/** order by min() on columns of table "DepartmentDocument" */
export interface DepartmentDocument_Min_Order_By {
  department_id?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "DepartmentDocument" */
export interface DepartmentDocument_Mutation_Response {
  __typename?: 'DepartmentDocument_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DepartmentDocument>;
}

/** on_conflict condition type for table "DepartmentDocument" */
export interface DepartmentDocument_On_Conflict {
  constraint: DepartmentDocument_Constraint;
  update_columns?: Array<DepartmentDocument_Update_Column>;
  where?: InputMaybe<DepartmentDocument_Bool_Exp>;
}

/** Ordering options when selecting data from "DepartmentDocument". */
export interface DepartmentDocument_Order_By {
  Department?: InputMaybe<Department_Order_By>;
  Document?: InputMaybe<Documents_Order_By>;
  department_id?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: DepartmentDocument */
export interface DepartmentDocument_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "DepartmentDocument" */
export type DepartmentDocument_Select_Column =
  /** column name */
  | 'department_id'
  /** column name */
  | 'document_id'
  /** column name */
  | 'id';

/** input type for updating data in table "DepartmentDocument" */
export interface DepartmentDocument_Set_Input {
  department_id?: InputMaybe<Scalars['Int']['input']>;
  document_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate stddev on columns */
export interface DepartmentDocument_Stddev_Fields {
  __typename?: 'DepartmentDocument_stddev_fields';
  department_id?: Maybe<Scalars['Float']['output']>;
  document_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "DepartmentDocument" */
export interface DepartmentDocument_Stddev_Order_By {
  department_id?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface DepartmentDocument_Stddev_Pop_Fields {
  __typename?: 'DepartmentDocument_stddev_pop_fields';
  department_id?: Maybe<Scalars['Float']['output']>;
  document_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "DepartmentDocument" */
export interface DepartmentDocument_Stddev_Pop_Order_By {
  department_id?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface DepartmentDocument_Stddev_Samp_Fields {
  __typename?: 'DepartmentDocument_stddev_samp_fields';
  department_id?: Maybe<Scalars['Float']['output']>;
  document_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "DepartmentDocument" */
export interface DepartmentDocument_Stddev_Samp_Order_By {
  department_id?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** Streaming cursor of the table "DepartmentDocument" */
export interface DepartmentDocument_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: DepartmentDocument_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface DepartmentDocument_Stream_Cursor_Value_Input {
  department_id?: InputMaybe<Scalars['Int']['input']>;
  document_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate sum on columns */
export interface DepartmentDocument_Sum_Fields {
  __typename?: 'DepartmentDocument_sum_fields';
  department_id?: Maybe<Scalars['Int']['output']>;
  document_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "DepartmentDocument" */
export interface DepartmentDocument_Sum_Order_By {
  department_id?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** update columns of table "DepartmentDocument" */
export type DepartmentDocument_Update_Column =
  /** column name */
  | 'department_id'
  /** column name */
  | 'document_id'
  /** column name */
  | 'id';

export interface DepartmentDocument_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DepartmentDocument_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DepartmentDocument_Set_Input>;
  where: DepartmentDocument_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface DepartmentDocument_Var_Pop_Fields {
  __typename?: 'DepartmentDocument_var_pop_fields';
  department_id?: Maybe<Scalars['Float']['output']>;
  document_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "DepartmentDocument" */
export interface DepartmentDocument_Var_Pop_Order_By {
  department_id?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface DepartmentDocument_Var_Samp_Fields {
  __typename?: 'DepartmentDocument_var_samp_fields';
  department_id?: Maybe<Scalars['Float']['output']>;
  document_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "DepartmentDocument" */
export interface DepartmentDocument_Var_Samp_Order_By {
  department_id?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** aggregate variance on columns */
export interface DepartmentDocument_Variance_Fields {
  __typename?: 'DepartmentDocument_variance_fields';
  department_id?: Maybe<Scalars['Float']['output']>;
  document_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "DepartmentDocument" */
export interface DepartmentDocument_Variance_Order_By {
  department_id?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** aggregated selection of "Department" */
export interface Department_Aggregate {
  __typename?: 'Department_aggregate';
  aggregate?: Maybe<Department_Aggregate_Fields>;
  nodes: Array<Department>;
}

/** aggregate fields of "Department" */
export interface Department_Aggregate_Fields {
  __typename?: 'Department_aggregate_fields';
  avg?: Maybe<Department_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Department_Max_Fields>;
  min?: Maybe<Department_Min_Fields>;
  stddev?: Maybe<Department_Stddev_Fields>;
  stddev_pop?: Maybe<Department_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Department_Stddev_Samp_Fields>;
  sum?: Maybe<Department_Sum_Fields>;
  var_pop?: Maybe<Department_Var_Pop_Fields>;
  var_samp?: Maybe<Department_Var_Samp_Fields>;
  variance?: Maybe<Department_Variance_Fields>;
}


/** aggregate fields of "Department" */
export interface Department_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Department_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate avg on columns */
export interface Department_Avg_Fields {
  __typename?: 'Department_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to filter rows from the table "Department". All fields are combined with a logical 'AND'. */
export interface Department_Bool_Exp {
  DepartmentDocuments?: InputMaybe<DepartmentDocument_Bool_Exp>;
  DepartmentDocuments_aggregate?: InputMaybe<DepartmentDocument_Aggregate_Bool_Exp>;
  DoctorsDepartments?: InputMaybe<DoctorsDepartment_Bool_Exp>;
  DoctorsDepartments_aggregate?: InputMaybe<DoctorsDepartment_Aggregate_Bool_Exp>;
  Visits?: InputMaybe<Visit_Bool_Exp>;
  Visits_aggregate?: InputMaybe<Visit_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Department_Bool_Exp>>;
  _not?: InputMaybe<Department_Bool_Exp>;
  _or?: InputMaybe<Array<Department_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "Department" */
export type Department_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'Department_pkey';

/** input type for incrementing numeric columns in table "Department" */
export interface Department_Inc_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "Department" */
export interface Department_Insert_Input {
  DepartmentDocuments?: InputMaybe<DepartmentDocument_Arr_Rel_Insert_Input>;
  DoctorsDepartments?: InputMaybe<DoctorsDepartment_Arr_Rel_Insert_Input>;
  Visits?: InputMaybe<Visit_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface Department_Max_Fields {
  __typename?: 'Department_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface Department_Min_Fields {
  __typename?: 'Department_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "Department" */
export interface Department_Mutation_Response {
  __typename?: 'Department_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Department>;
}

/** input type for inserting object relation for remote table "Department" */
export interface Department_Obj_Rel_Insert_Input {
  data: Department_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Department_On_Conflict>;
}

/** on_conflict condition type for table "Department" */
export interface Department_On_Conflict {
  constraint: Department_Constraint;
  update_columns?: Array<Department_Update_Column>;
  where?: InputMaybe<Department_Bool_Exp>;
}

/** Ordering options when selecting data from "Department". */
export interface Department_Order_By {
  DepartmentDocuments_aggregate?: InputMaybe<DepartmentDocument_Aggregate_Order_By>;
  DoctorsDepartments_aggregate?: InputMaybe<DoctorsDepartment_Aggregate_Order_By>;
  Visits_aggregate?: InputMaybe<Visit_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
}

/** primary key columns input for table: Department */
export interface Department_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "Department" */
export type Department_Select_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'name';

/** input type for updating data in table "Department" */
export interface Department_Set_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate stddev on columns */
export interface Department_Stddev_Fields {
  __typename?: 'Department_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_pop on columns */
export interface Department_Stddev_Pop_Fields {
  __typename?: 'Department_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_samp on columns */
export interface Department_Stddev_Samp_Fields {
  __typename?: 'Department_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** Streaming cursor of the table "Department" */
export interface Department_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Department_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Department_Stream_Cursor_Value_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate sum on columns */
export interface Department_Sum_Fields {
  __typename?: 'Department_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
}

/** update columns of table "Department" */
export type Department_Update_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'name';

export interface Department_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Department_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Department_Set_Input>;
  where: Department_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Department_Var_Pop_Fields {
  __typename?: 'Department_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate var_samp on columns */
export interface Department_Var_Samp_Fields {
  __typename?: 'Department_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate variance on columns */
export interface Department_Variance_Fields {
  __typename?: 'Department_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** columns and relationships of "DoctorsCard" */
export interface DoctorsCard {
  __typename?: 'DoctorsCard';
  /** An object relationship */
  Medicine: Medicine;
  id: Scalars['Int']['output'];
  medicine_id: Scalars['Int']['output'];
}

/** aggregated selection of "DoctorsCard" */
export interface DoctorsCard_Aggregate {
  __typename?: 'DoctorsCard_aggregate';
  aggregate?: Maybe<DoctorsCard_Aggregate_Fields>;
  nodes: Array<DoctorsCard>;
}

/** aggregate fields of "DoctorsCard" */
export interface DoctorsCard_Aggregate_Fields {
  __typename?: 'DoctorsCard_aggregate_fields';
  avg?: Maybe<DoctorsCard_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<DoctorsCard_Max_Fields>;
  min?: Maybe<DoctorsCard_Min_Fields>;
  stddev?: Maybe<DoctorsCard_Stddev_Fields>;
  stddev_pop?: Maybe<DoctorsCard_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DoctorsCard_Stddev_Samp_Fields>;
  sum?: Maybe<DoctorsCard_Sum_Fields>;
  var_pop?: Maybe<DoctorsCard_Var_Pop_Fields>;
  var_samp?: Maybe<DoctorsCard_Var_Samp_Fields>;
  variance?: Maybe<DoctorsCard_Variance_Fields>;
}


/** aggregate fields of "DoctorsCard" */
export interface DoctorsCard_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<DoctorsCard_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate avg on columns */
export interface DoctorsCard_Avg_Fields {
  __typename?: 'DoctorsCard_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to filter rows from the table "DoctorsCard". All fields are combined with a logical 'AND'. */
export interface DoctorsCard_Bool_Exp {
  Medicine?: InputMaybe<Medicine_Bool_Exp>;
  _and?: InputMaybe<Array<DoctorsCard_Bool_Exp>>;
  _not?: InputMaybe<DoctorsCard_Bool_Exp>;
  _or?: InputMaybe<Array<DoctorsCard_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  medicine_id?: InputMaybe<Int_Comparison_Exp>;
}

/** unique or primary key constraints on table "DoctorsCard" */
export type DoctorsCard_Constraint =
  /** unique or primary key constraint on columns "medicine_id" */
  | 'DoctorsCard_medicine_id_key'
  /** unique or primary key constraint on columns "id" */
  | 'DoctorsCard_pkey';

/** input type for incrementing numeric columns in table "DoctorsCard" */
export interface DoctorsCard_Inc_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "DoctorsCard" */
export interface DoctorsCard_Insert_Input {
  Medicine?: InputMaybe<Medicine_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate max on columns */
export interface DoctorsCard_Max_Fields {
  __typename?: 'DoctorsCard_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  medicine_id?: Maybe<Scalars['Int']['output']>;
}

/** aggregate min on columns */
export interface DoctorsCard_Min_Fields {
  __typename?: 'DoctorsCard_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  medicine_id?: Maybe<Scalars['Int']['output']>;
}

/** response of any mutation on the table "DoctorsCard" */
export interface DoctorsCard_Mutation_Response {
  __typename?: 'DoctorsCard_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DoctorsCard>;
}

/** on_conflict condition type for table "DoctorsCard" */
export interface DoctorsCard_On_Conflict {
  constraint: DoctorsCard_Constraint;
  update_columns?: Array<DoctorsCard_Update_Column>;
  where?: InputMaybe<DoctorsCard_Bool_Exp>;
}

/** Ordering options when selecting data from "DoctorsCard". */
export interface DoctorsCard_Order_By {
  Medicine?: InputMaybe<Medicine_Order_By>;
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: DoctorsCard */
export interface DoctorsCard_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "DoctorsCard" */
export type DoctorsCard_Select_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'medicine_id';

/** input type for updating data in table "DoctorsCard" */
export interface DoctorsCard_Set_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate stddev on columns */
export interface DoctorsCard_Stddev_Fields {
  __typename?: 'DoctorsCard_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_pop on columns */
export interface DoctorsCard_Stddev_Pop_Fields {
  __typename?: 'DoctorsCard_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_samp on columns */
export interface DoctorsCard_Stddev_Samp_Fields {
  __typename?: 'DoctorsCard_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
}

/** Streaming cursor of the table "DoctorsCard" */
export interface DoctorsCard_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: DoctorsCard_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface DoctorsCard_Stream_Cursor_Value_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate sum on columns */
export interface DoctorsCard_Sum_Fields {
  __typename?: 'DoctorsCard_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  medicine_id?: Maybe<Scalars['Int']['output']>;
}

/** update columns of table "DoctorsCard" */
export type DoctorsCard_Update_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'medicine_id';

export interface DoctorsCard_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DoctorsCard_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DoctorsCard_Set_Input>;
  where: DoctorsCard_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface DoctorsCard_Var_Pop_Fields {
  __typename?: 'DoctorsCard_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate var_samp on columns */
export interface DoctorsCard_Var_Samp_Fields {
  __typename?: 'DoctorsCard_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate variance on columns */
export interface DoctorsCard_Variance_Fields {
  __typename?: 'DoctorsCard_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
}

/** Отделения врачей */
export interface DoctorsDepartment {
  __typename?: 'DoctorsDepartment';
  /** An object relationship */
  Department: Department;
  /** An object relationship */
  Doctor: Users;
  department_id: Scalars['Int']['output'];
  doctor_id: Scalars['uuid']['output'];
  id: Scalars['Int']['output'];
}

/** aggregated selection of "DoctorsDepartment" */
export interface DoctorsDepartment_Aggregate {
  __typename?: 'DoctorsDepartment_aggregate';
  aggregate?: Maybe<DoctorsDepartment_Aggregate_Fields>;
  nodes: Array<DoctorsDepartment>;
}

export interface DoctorsDepartment_Aggregate_Bool_Exp {
  count?: InputMaybe<DoctorsDepartment_Aggregate_Bool_Exp_Count>;
}

export interface DoctorsDepartment_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<DoctorsDepartment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<DoctorsDepartment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "DoctorsDepartment" */
export interface DoctorsDepartment_Aggregate_Fields {
  __typename?: 'DoctorsDepartment_aggregate_fields';
  avg?: Maybe<DoctorsDepartment_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<DoctorsDepartment_Max_Fields>;
  min?: Maybe<DoctorsDepartment_Min_Fields>;
  stddev?: Maybe<DoctorsDepartment_Stddev_Fields>;
  stddev_pop?: Maybe<DoctorsDepartment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DoctorsDepartment_Stddev_Samp_Fields>;
  sum?: Maybe<DoctorsDepartment_Sum_Fields>;
  var_pop?: Maybe<DoctorsDepartment_Var_Pop_Fields>;
  var_samp?: Maybe<DoctorsDepartment_Var_Samp_Fields>;
  variance?: Maybe<DoctorsDepartment_Variance_Fields>;
}


/** aggregate fields of "DoctorsDepartment" */
export interface DoctorsDepartment_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<DoctorsDepartment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "DoctorsDepartment" */
export interface DoctorsDepartment_Aggregate_Order_By {
  avg?: InputMaybe<DoctorsDepartment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DoctorsDepartment_Max_Order_By>;
  min?: InputMaybe<DoctorsDepartment_Min_Order_By>;
  stddev?: InputMaybe<DoctorsDepartment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DoctorsDepartment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DoctorsDepartment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DoctorsDepartment_Sum_Order_By>;
  var_pop?: InputMaybe<DoctorsDepartment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DoctorsDepartment_Var_Samp_Order_By>;
  variance?: InputMaybe<DoctorsDepartment_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "DoctorsDepartment" */
export interface DoctorsDepartment_Arr_Rel_Insert_Input {
  data: Array<DoctorsDepartment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DoctorsDepartment_On_Conflict>;
}

/** aggregate avg on columns */
export interface DoctorsDepartment_Avg_Fields {
  __typename?: 'DoctorsDepartment_avg_fields';
  department_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "DoctorsDepartment" */
export interface DoctorsDepartment_Avg_Order_By {
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** Boolean expression to filter rows from the table "DoctorsDepartment". All fields are combined with a logical 'AND'. */
export interface DoctorsDepartment_Bool_Exp {
  Department?: InputMaybe<Department_Bool_Exp>;
  Doctor?: InputMaybe<Users_Bool_Exp>;
  _and?: InputMaybe<Array<DoctorsDepartment_Bool_Exp>>;
  _not?: InputMaybe<DoctorsDepartment_Bool_Exp>;
  _or?: InputMaybe<Array<DoctorsDepartment_Bool_Exp>>;
  department_id?: InputMaybe<Int_Comparison_Exp>;
  doctor_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
}

/** unique or primary key constraints on table "DoctorsDepartment" */
export type DoctorsDepartment_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'DoctorsDepartment_pkey';

/** input type for incrementing numeric columns in table "DoctorsDepartment" */
export interface DoctorsDepartment_Inc_Input {
  department_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "DoctorsDepartment" */
export interface DoctorsDepartment_Insert_Input {
  Department?: InputMaybe<Department_Obj_Rel_Insert_Input>;
  Doctor?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  department_id?: InputMaybe<Scalars['Int']['input']>;
  doctor_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate max on columns */
export interface DoctorsDepartment_Max_Fields {
  __typename?: 'DoctorsDepartment_max_fields';
  department_id?: Maybe<Scalars['Int']['output']>;
  doctor_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
}

/** order by max() on columns of table "DoctorsDepartment" */
export interface DoctorsDepartment_Max_Order_By {
  department_id?: InputMaybe<Order_By>;
  doctor_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface DoctorsDepartment_Min_Fields {
  __typename?: 'DoctorsDepartment_min_fields';
  department_id?: Maybe<Scalars['Int']['output']>;
  doctor_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
}

/** order by min() on columns of table "DoctorsDepartment" */
export interface DoctorsDepartment_Min_Order_By {
  department_id?: InputMaybe<Order_By>;
  doctor_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "DoctorsDepartment" */
export interface DoctorsDepartment_Mutation_Response {
  __typename?: 'DoctorsDepartment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DoctorsDepartment>;
}

/** on_conflict condition type for table "DoctorsDepartment" */
export interface DoctorsDepartment_On_Conflict {
  constraint: DoctorsDepartment_Constraint;
  update_columns?: Array<DoctorsDepartment_Update_Column>;
  where?: InputMaybe<DoctorsDepartment_Bool_Exp>;
}

/** Ordering options when selecting data from "DoctorsDepartment". */
export interface DoctorsDepartment_Order_By {
  Department?: InputMaybe<Department_Order_By>;
  Doctor?: InputMaybe<Users_Order_By>;
  department_id?: InputMaybe<Order_By>;
  doctor_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: DoctorsDepartment */
export interface DoctorsDepartment_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "DoctorsDepartment" */
export type DoctorsDepartment_Select_Column =
  /** column name */
  | 'department_id'
  /** column name */
  | 'doctor_id'
  /** column name */
  | 'id';

/** input type for updating data in table "DoctorsDepartment" */
export interface DoctorsDepartment_Set_Input {
  department_id?: InputMaybe<Scalars['Int']['input']>;
  doctor_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate stddev on columns */
export interface DoctorsDepartment_Stddev_Fields {
  __typename?: 'DoctorsDepartment_stddev_fields';
  department_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "DoctorsDepartment" */
export interface DoctorsDepartment_Stddev_Order_By {
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface DoctorsDepartment_Stddev_Pop_Fields {
  __typename?: 'DoctorsDepartment_stddev_pop_fields';
  department_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "DoctorsDepartment" */
export interface DoctorsDepartment_Stddev_Pop_Order_By {
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface DoctorsDepartment_Stddev_Samp_Fields {
  __typename?: 'DoctorsDepartment_stddev_samp_fields';
  department_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "DoctorsDepartment" */
export interface DoctorsDepartment_Stddev_Samp_Order_By {
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** Streaming cursor of the table "DoctorsDepartment" */
export interface DoctorsDepartment_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: DoctorsDepartment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface DoctorsDepartment_Stream_Cursor_Value_Input {
  department_id?: InputMaybe<Scalars['Int']['input']>;
  doctor_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate sum on columns */
export interface DoctorsDepartment_Sum_Fields {
  __typename?: 'DoctorsDepartment_sum_fields';
  department_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "DoctorsDepartment" */
export interface DoctorsDepartment_Sum_Order_By {
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** update columns of table "DoctorsDepartment" */
export type DoctorsDepartment_Update_Column =
  /** column name */
  | 'department_id'
  /** column name */
  | 'doctor_id'
  /** column name */
  | 'id';

export interface DoctorsDepartment_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DoctorsDepartment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DoctorsDepartment_Set_Input>;
  where: DoctorsDepartment_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface DoctorsDepartment_Var_Pop_Fields {
  __typename?: 'DoctorsDepartment_var_pop_fields';
  department_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "DoctorsDepartment" */
export interface DoctorsDepartment_Var_Pop_Order_By {
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface DoctorsDepartment_Var_Samp_Fields {
  __typename?: 'DoctorsDepartment_var_samp_fields';
  department_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "DoctorsDepartment" */
export interface DoctorsDepartment_Var_Samp_Order_By {
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** aggregate variance on columns */
export interface DoctorsDepartment_Variance_Fields {
  __typename?: 'DoctorsDepartment_variance_fields';
  department_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "DoctorsDepartment" */
export interface DoctorsDepartment_Variance_Order_By {
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** Документы в системе */
export interface Documents {
  __typename?: 'Documents';
  body: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  styles?: Maybe<Scalars['String']['output']>;
  variables: Scalars['jsonb']['output'];
}


/** Документы в системе */
export interface DocumentsVariablesArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}

/** aggregated selection of "Documents" */
export interface Documents_Aggregate {
  __typename?: 'Documents_aggregate';
  aggregate?: Maybe<Documents_Aggregate_Fields>;
  nodes: Array<Documents>;
}

/** aggregate fields of "Documents" */
export interface Documents_Aggregate_Fields {
  __typename?: 'Documents_aggregate_fields';
  avg?: Maybe<Documents_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Documents_Max_Fields>;
  min?: Maybe<Documents_Min_Fields>;
  stddev?: Maybe<Documents_Stddev_Fields>;
  stddev_pop?: Maybe<Documents_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Documents_Stddev_Samp_Fields>;
  sum?: Maybe<Documents_Sum_Fields>;
  var_pop?: Maybe<Documents_Var_Pop_Fields>;
  var_samp?: Maybe<Documents_Var_Samp_Fields>;
  variance?: Maybe<Documents_Variance_Fields>;
}


/** aggregate fields of "Documents" */
export interface Documents_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Documents_Append_Input {
  variables?: InputMaybe<Scalars['jsonb']['input']>;
}

/** aggregate avg on columns */
export interface Documents_Avg_Fields {
  __typename?: 'Documents_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to filter rows from the table "Documents". All fields are combined with a logical 'AND'. */
export interface Documents_Bool_Exp {
  _and?: InputMaybe<Array<Documents_Bool_Exp>>;
  _not?: InputMaybe<Documents_Bool_Exp>;
  _or?: InputMaybe<Array<Documents_Bool_Exp>>;
  body?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  styles?: InputMaybe<String_Comparison_Exp>;
  variables?: InputMaybe<Jsonb_Comparison_Exp>;
}

/** unique or primary key constraints on table "Documents" */
export type Documents_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'Documents_pkey';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Documents_Delete_At_Path_Input {
  variables?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Documents_Delete_Elem_Input {
  variables?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Documents_Delete_Key_Input {
  variables?: InputMaybe<Scalars['String']['input']>;
}

/** input type for incrementing numeric columns in table "Documents" */
export interface Documents_Inc_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "Documents" */
export interface Documents_Insert_Input {
  body?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  styles?: InputMaybe<Scalars['String']['input']>;
  variables?: InputMaybe<Scalars['jsonb']['input']>;
}

/** aggregate max on columns */
export interface Documents_Max_Fields {
  __typename?: 'Documents_max_fields';
  body?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  styles?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface Documents_Min_Fields {
  __typename?: 'Documents_min_fields';
  body?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  styles?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "Documents" */
export interface Documents_Mutation_Response {
  __typename?: 'Documents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Documents>;
}

/** input type for inserting object relation for remote table "Documents" */
export interface Documents_Obj_Rel_Insert_Input {
  data: Documents_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Documents_On_Conflict>;
}

/** on_conflict condition type for table "Documents" */
export interface Documents_On_Conflict {
  constraint: Documents_Constraint;
  update_columns?: Array<Documents_Update_Column>;
  where?: InputMaybe<Documents_Bool_Exp>;
}

/** Ordering options when selecting data from "Documents". */
export interface Documents_Order_By {
  body?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  styles?: InputMaybe<Order_By>;
  variables?: InputMaybe<Order_By>;
}

/** primary key columns input for table: Documents */
export interface Documents_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Documents_Prepend_Input {
  variables?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "Documents" */
export type Documents_Select_Column =
  /** column name */
  | 'body'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'styles'
  /** column name */
  | 'variables';

/** input type for updating data in table "Documents" */
export interface Documents_Set_Input {
  body?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  styles?: InputMaybe<Scalars['String']['input']>;
  variables?: InputMaybe<Scalars['jsonb']['input']>;
}

/** aggregate stddev on columns */
export interface Documents_Stddev_Fields {
  __typename?: 'Documents_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_pop on columns */
export interface Documents_Stddev_Pop_Fields {
  __typename?: 'Documents_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_samp on columns */
export interface Documents_Stddev_Samp_Fields {
  __typename?: 'Documents_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** Streaming cursor of the table "Documents" */
export interface Documents_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Documents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Documents_Stream_Cursor_Value_Input {
  body?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  styles?: InputMaybe<Scalars['String']['input']>;
  variables?: InputMaybe<Scalars['jsonb']['input']>;
}

/** aggregate sum on columns */
export interface Documents_Sum_Fields {
  __typename?: 'Documents_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
}

/** update columns of table "Documents" */
export type Documents_Update_Column =
  /** column name */
  | 'body'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'styles'
  /** column name */
  | 'variables';

export interface Documents_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Documents_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Documents_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Documents_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Documents_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Documents_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Documents_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Documents_Set_Input>;
  where: Documents_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Documents_Var_Pop_Fields {
  __typename?: 'Documents_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate var_samp on columns */
export interface Documents_Var_Samp_Fields {
  __typename?: 'Documents_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate variance on columns */
export interface Documents_Variance_Fields {
  __typename?: 'Documents_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export interface Int_Comparison_Exp {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
}

/** Препараты */
export interface Medicine {
  __typename?: 'Medicine';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  price: Scalars['float8']['output'];
  ut_id?: Maybe<Scalars['String']['output']>;
}

/** columns and relationships of "MedicineUnit" */
export interface MedicineUnit {
  __typename?: 'MedicineUnit';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

/** aggregated selection of "MedicineUnit" */
export interface MedicineUnit_Aggregate {
  __typename?: 'MedicineUnit_aggregate';
  aggregate?: Maybe<MedicineUnit_Aggregate_Fields>;
  nodes: Array<MedicineUnit>;
}

/** aggregate fields of "MedicineUnit" */
export interface MedicineUnit_Aggregate_Fields {
  __typename?: 'MedicineUnit_aggregate_fields';
  avg?: Maybe<MedicineUnit_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<MedicineUnit_Max_Fields>;
  min?: Maybe<MedicineUnit_Min_Fields>;
  stddev?: Maybe<MedicineUnit_Stddev_Fields>;
  stddev_pop?: Maybe<MedicineUnit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<MedicineUnit_Stddev_Samp_Fields>;
  sum?: Maybe<MedicineUnit_Sum_Fields>;
  var_pop?: Maybe<MedicineUnit_Var_Pop_Fields>;
  var_samp?: Maybe<MedicineUnit_Var_Samp_Fields>;
  variance?: Maybe<MedicineUnit_Variance_Fields>;
}


/** aggregate fields of "MedicineUnit" */
export interface MedicineUnit_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<MedicineUnit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate avg on columns */
export interface MedicineUnit_Avg_Fields {
  __typename?: 'MedicineUnit_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to filter rows from the table "MedicineUnit". All fields are combined with a logical 'AND'. */
export interface MedicineUnit_Bool_Exp {
  _and?: InputMaybe<Array<MedicineUnit_Bool_Exp>>;
  _not?: InputMaybe<MedicineUnit_Bool_Exp>;
  _or?: InputMaybe<Array<MedicineUnit_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "MedicineUnit" */
export type MedicineUnit_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'MedicineUnits_pkey';

/** input type for incrementing numeric columns in table "MedicineUnit" */
export interface MedicineUnit_Inc_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "MedicineUnit" */
export interface MedicineUnit_Insert_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface MedicineUnit_Max_Fields {
  __typename?: 'MedicineUnit_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface MedicineUnit_Min_Fields {
  __typename?: 'MedicineUnit_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "MedicineUnit" */
export interface MedicineUnit_Mutation_Response {
  __typename?: 'MedicineUnit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MedicineUnit>;
}

/** on_conflict condition type for table "MedicineUnit" */
export interface MedicineUnit_On_Conflict {
  constraint: MedicineUnit_Constraint;
  update_columns?: Array<MedicineUnit_Update_Column>;
  where?: InputMaybe<MedicineUnit_Bool_Exp>;
}

/** Ordering options when selecting data from "MedicineUnit". */
export interface MedicineUnit_Order_By {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
}

/** primary key columns input for table: MedicineUnit */
export interface MedicineUnit_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "MedicineUnit" */
export type MedicineUnit_Select_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'name';

/** input type for updating data in table "MedicineUnit" */
export interface MedicineUnit_Set_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate stddev on columns */
export interface MedicineUnit_Stddev_Fields {
  __typename?: 'MedicineUnit_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_pop on columns */
export interface MedicineUnit_Stddev_Pop_Fields {
  __typename?: 'MedicineUnit_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_samp on columns */
export interface MedicineUnit_Stddev_Samp_Fields {
  __typename?: 'MedicineUnit_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** Streaming cursor of the table "MedicineUnit" */
export interface MedicineUnit_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: MedicineUnit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface MedicineUnit_Stream_Cursor_Value_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate sum on columns */
export interface MedicineUnit_Sum_Fields {
  __typename?: 'MedicineUnit_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
}

/** update columns of table "MedicineUnit" */
export type MedicineUnit_Update_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'name';

export interface MedicineUnit_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MedicineUnit_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MedicineUnit_Set_Input>;
  where: MedicineUnit_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface MedicineUnit_Var_Pop_Fields {
  __typename?: 'MedicineUnit_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate var_samp on columns */
export interface MedicineUnit_Var_Samp_Fields {
  __typename?: 'MedicineUnit_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate variance on columns */
export interface MedicineUnit_Variance_Fields {
  __typename?: 'MedicineUnit_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregated selection of "Medicine" */
export interface Medicine_Aggregate {
  __typename?: 'Medicine_aggregate';
  aggregate?: Maybe<Medicine_Aggregate_Fields>;
  nodes: Array<Medicine>;
}

/** aggregate fields of "Medicine" */
export interface Medicine_Aggregate_Fields {
  __typename?: 'Medicine_aggregate_fields';
  avg?: Maybe<Medicine_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Medicine_Max_Fields>;
  min?: Maybe<Medicine_Min_Fields>;
  stddev?: Maybe<Medicine_Stddev_Fields>;
  stddev_pop?: Maybe<Medicine_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Medicine_Stddev_Samp_Fields>;
  sum?: Maybe<Medicine_Sum_Fields>;
  var_pop?: Maybe<Medicine_Var_Pop_Fields>;
  var_samp?: Maybe<Medicine_Var_Samp_Fields>;
  variance?: Maybe<Medicine_Variance_Fields>;
}


/** aggregate fields of "Medicine" */
export interface Medicine_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Medicine_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate avg on columns */
export interface Medicine_Avg_Fields {
  __typename?: 'Medicine_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to filter rows from the table "Medicine". All fields are combined with a logical 'AND'. */
export interface Medicine_Bool_Exp {
  _and?: InputMaybe<Array<Medicine_Bool_Exp>>;
  _not?: InputMaybe<Medicine_Bool_Exp>;
  _or?: InputMaybe<Array<Medicine_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  price?: InputMaybe<Float8_Comparison_Exp>;
  ut_id?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "Medicine" */
export type Medicine_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'Medicine_pkey';

/** input type for incrementing numeric columns in table "Medicine" */
export interface Medicine_Inc_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
}

/** input type for inserting data into table "Medicine" */
export interface Medicine_Insert_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  ut_id?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface Medicine_Max_Fields {
  __typename?: 'Medicine_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  ut_id?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface Medicine_Min_Fields {
  __typename?: 'Medicine_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  ut_id?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "Medicine" */
export interface Medicine_Mutation_Response {
  __typename?: 'Medicine_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Medicine>;
}

/** input type for inserting object relation for remote table "Medicine" */
export interface Medicine_Obj_Rel_Insert_Input {
  data: Medicine_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Medicine_On_Conflict>;
}

/** on_conflict condition type for table "Medicine" */
export interface Medicine_On_Conflict {
  constraint: Medicine_Constraint;
  update_columns?: Array<Medicine_Update_Column>;
  where?: InputMaybe<Medicine_Bool_Exp>;
}

/** Ordering options when selecting data from "Medicine". */
export interface Medicine_Order_By {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  ut_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: Medicine */
export interface Medicine_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "Medicine" */
export type Medicine_Select_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'price'
  /** column name */
  | 'ut_id';

/** input type for updating data in table "Medicine" */
export interface Medicine_Set_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  ut_id?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate stddev on columns */
export interface Medicine_Stddev_Fields {
  __typename?: 'Medicine_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_pop on columns */
export interface Medicine_Stddev_Pop_Fields {
  __typename?: 'Medicine_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_samp on columns */
export interface Medicine_Stddev_Samp_Fields {
  __typename?: 'Medicine_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
}

/** Streaming cursor of the table "Medicine" */
export interface Medicine_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Medicine_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Medicine_Stream_Cursor_Value_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  ut_id?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate sum on columns */
export interface Medicine_Sum_Fields {
  __typename?: 'Medicine_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
}

/** update columns of table "Medicine" */
export type Medicine_Update_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'price'
  /** column name */
  | 'ut_id';

export interface Medicine_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Medicine_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Medicine_Set_Input>;
  where: Medicine_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Medicine_Var_Pop_Fields {
  __typename?: 'Medicine_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
}

/** aggregate var_samp on columns */
export interface Medicine_Var_Samp_Fields {
  __typename?: 'Medicine_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
}

/** aggregate variance on columns */
export interface Medicine_Variance_Fields {
  __typename?: 'Medicine_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
}

/** Животные */
export interface Pet {
  __typename?: 'Pet';
  /** An object relationship */
  Client: Client;
  /** An object relationship */
  PetType: PetType;
  birthdate?: Maybe<Scalars['date']['output']>;
  breed?: Maybe<Scalars['String']['output']>;
  chip_number?: Maybe<Scalars['String']['output']>;
  client_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  sex: Scalars['Int']['output'];
  type_id: Scalars['Int']['output'];
}

/** Тип животного */
export interface PetType {
  __typename?: 'PetType';
  /** An array relationship */
  Pets: Array<Pet>;
  /** An aggregate relationship */
  Pets_aggregate: Pet_Aggregate;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}


/** Тип животного */
export interface PetTypePetsArgs {
  distinct_on?: InputMaybe<Array<Pet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Pet_Order_By>>;
  where?: InputMaybe<Pet_Bool_Exp>;
}


/** Тип животного */
export interface PetTypePets_AggregateArgs {
  distinct_on?: InputMaybe<Array<Pet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Pet_Order_By>>;
  where?: InputMaybe<Pet_Bool_Exp>;
}

/** aggregated selection of "PetType" */
export interface PetType_Aggregate {
  __typename?: 'PetType_aggregate';
  aggregate?: Maybe<PetType_Aggregate_Fields>;
  nodes: Array<PetType>;
}

/** aggregate fields of "PetType" */
export interface PetType_Aggregate_Fields {
  __typename?: 'PetType_aggregate_fields';
  avg?: Maybe<PetType_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<PetType_Max_Fields>;
  min?: Maybe<PetType_Min_Fields>;
  stddev?: Maybe<PetType_Stddev_Fields>;
  stddev_pop?: Maybe<PetType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PetType_Stddev_Samp_Fields>;
  sum?: Maybe<PetType_Sum_Fields>;
  var_pop?: Maybe<PetType_Var_Pop_Fields>;
  var_samp?: Maybe<PetType_Var_Samp_Fields>;
  variance?: Maybe<PetType_Variance_Fields>;
}


/** aggregate fields of "PetType" */
export interface PetType_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<PetType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate avg on columns */
export interface PetType_Avg_Fields {
  __typename?: 'PetType_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to filter rows from the table "PetType". All fields are combined with a logical 'AND'. */
export interface PetType_Bool_Exp {
  Pets?: InputMaybe<Pet_Bool_Exp>;
  Pets_aggregate?: InputMaybe<Pet_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<PetType_Bool_Exp>>;
  _not?: InputMaybe<PetType_Bool_Exp>;
  _or?: InputMaybe<Array<PetType_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "PetType" */
export type PetType_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'PetType_pkey';

/** input type for incrementing numeric columns in table "PetType" */
export interface PetType_Inc_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "PetType" */
export interface PetType_Insert_Input {
  Pets?: InputMaybe<Pet_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface PetType_Max_Fields {
  __typename?: 'PetType_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface PetType_Min_Fields {
  __typename?: 'PetType_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "PetType" */
export interface PetType_Mutation_Response {
  __typename?: 'PetType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PetType>;
}

/** input type for inserting object relation for remote table "PetType" */
export interface PetType_Obj_Rel_Insert_Input {
  data: PetType_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<PetType_On_Conflict>;
}

/** on_conflict condition type for table "PetType" */
export interface PetType_On_Conflict {
  constraint: PetType_Constraint;
  update_columns?: Array<PetType_Update_Column>;
  where?: InputMaybe<PetType_Bool_Exp>;
}

/** Ordering options when selecting data from "PetType". */
export interface PetType_Order_By {
  Pets_aggregate?: InputMaybe<Pet_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
}

/** primary key columns input for table: PetType */
export interface PetType_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "PetType" */
export type PetType_Select_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'name';

/** input type for updating data in table "PetType" */
export interface PetType_Set_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate stddev on columns */
export interface PetType_Stddev_Fields {
  __typename?: 'PetType_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_pop on columns */
export interface PetType_Stddev_Pop_Fields {
  __typename?: 'PetType_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_samp on columns */
export interface PetType_Stddev_Samp_Fields {
  __typename?: 'PetType_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** Streaming cursor of the table "PetType" */
export interface PetType_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: PetType_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface PetType_Stream_Cursor_Value_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate sum on columns */
export interface PetType_Sum_Fields {
  __typename?: 'PetType_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
}

/** update columns of table "PetType" */
export type PetType_Update_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'name';

export interface PetType_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PetType_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PetType_Set_Input>;
  where: PetType_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface PetType_Var_Pop_Fields {
  __typename?: 'PetType_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate var_samp on columns */
export interface PetType_Var_Samp_Fields {
  __typename?: 'PetType_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate variance on columns */
export interface PetType_Variance_Fields {
  __typename?: 'PetType_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregated selection of "Pet" */
export interface Pet_Aggregate {
  __typename?: 'Pet_aggregate';
  aggregate?: Maybe<Pet_Aggregate_Fields>;
  nodes: Array<Pet>;
}

export interface Pet_Aggregate_Bool_Exp {
  count?: InputMaybe<Pet_Aggregate_Bool_Exp_Count>;
}

export interface Pet_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Pet_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Pet_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "Pet" */
export interface Pet_Aggregate_Fields {
  __typename?: 'Pet_aggregate_fields';
  avg?: Maybe<Pet_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Pet_Max_Fields>;
  min?: Maybe<Pet_Min_Fields>;
  stddev?: Maybe<Pet_Stddev_Fields>;
  stddev_pop?: Maybe<Pet_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Pet_Stddev_Samp_Fields>;
  sum?: Maybe<Pet_Sum_Fields>;
  var_pop?: Maybe<Pet_Var_Pop_Fields>;
  var_samp?: Maybe<Pet_Var_Samp_Fields>;
  variance?: Maybe<Pet_Variance_Fields>;
}


/** aggregate fields of "Pet" */
export interface Pet_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Pet_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "Pet" */
export interface Pet_Aggregate_Order_By {
  avg?: InputMaybe<Pet_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Pet_Max_Order_By>;
  min?: InputMaybe<Pet_Min_Order_By>;
  stddev?: InputMaybe<Pet_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Pet_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Pet_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Pet_Sum_Order_By>;
  var_pop?: InputMaybe<Pet_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Pet_Var_Samp_Order_By>;
  variance?: InputMaybe<Pet_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "Pet" */
export interface Pet_Arr_Rel_Insert_Input {
  data: Array<Pet_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Pet_On_Conflict>;
}

/** aggregate avg on columns */
export interface Pet_Avg_Fields {
  __typename?: 'Pet_avg_fields';
  client_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sex?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "Pet" */
export interface Pet_Avg_Order_By {
  client_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sex?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
}

/** Boolean expression to filter rows from the table "Pet". All fields are combined with a logical 'AND'. */
export interface Pet_Bool_Exp {
  Client?: InputMaybe<Client_Bool_Exp>;
  PetType?: InputMaybe<PetType_Bool_Exp>;
  _and?: InputMaybe<Array<Pet_Bool_Exp>>;
  _not?: InputMaybe<Pet_Bool_Exp>;
  _or?: InputMaybe<Array<Pet_Bool_Exp>>;
  birthdate?: InputMaybe<Date_Comparison_Exp>;
  breed?: InputMaybe<String_Comparison_Exp>;
  chip_number?: InputMaybe<String_Comparison_Exp>;
  client_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  sex?: InputMaybe<Int_Comparison_Exp>;
  type_id?: InputMaybe<Int_Comparison_Exp>;
}

/** unique or primary key constraints on table "Pet" */
export type Pet_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'Pet_pkey';

/** input type for incrementing numeric columns in table "Pet" */
export interface Pet_Inc_Input {
  client_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  sex?: InputMaybe<Scalars['Int']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "Pet" */
export interface Pet_Insert_Input {
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  PetType?: InputMaybe<PetType_Obj_Rel_Insert_Input>;
  birthdate?: InputMaybe<Scalars['date']['input']>;
  breed?: InputMaybe<Scalars['String']['input']>;
  chip_number?: InputMaybe<Scalars['String']['input']>;
  client_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sex?: InputMaybe<Scalars['Int']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate max on columns */
export interface Pet_Max_Fields {
  __typename?: 'Pet_max_fields';
  birthdate?: Maybe<Scalars['date']['output']>;
  breed?: Maybe<Scalars['String']['output']>;
  chip_number?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sex?: Maybe<Scalars['Int']['output']>;
  type_id?: Maybe<Scalars['Int']['output']>;
}

/** order by max() on columns of table "Pet" */
export interface Pet_Max_Order_By {
  birthdate?: InputMaybe<Order_By>;
  breed?: InputMaybe<Order_By>;
  chip_number?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sex?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface Pet_Min_Fields {
  __typename?: 'Pet_min_fields';
  birthdate?: Maybe<Scalars['date']['output']>;
  breed?: Maybe<Scalars['String']['output']>;
  chip_number?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sex?: Maybe<Scalars['Int']['output']>;
  type_id?: Maybe<Scalars['Int']['output']>;
}

/** order by min() on columns of table "Pet" */
export interface Pet_Min_Order_By {
  birthdate?: InputMaybe<Order_By>;
  breed?: InputMaybe<Order_By>;
  chip_number?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sex?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "Pet" */
export interface Pet_Mutation_Response {
  __typename?: 'Pet_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Pet>;
}

/** input type for inserting object relation for remote table "Pet" */
export interface Pet_Obj_Rel_Insert_Input {
  data: Pet_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Pet_On_Conflict>;
}

/** on_conflict condition type for table "Pet" */
export interface Pet_On_Conflict {
  constraint: Pet_Constraint;
  update_columns?: Array<Pet_Update_Column>;
  where?: InputMaybe<Pet_Bool_Exp>;
}

/** Ordering options when selecting data from "Pet". */
export interface Pet_Order_By {
  Client?: InputMaybe<Client_Order_By>;
  PetType?: InputMaybe<PetType_Order_By>;
  birthdate?: InputMaybe<Order_By>;
  breed?: InputMaybe<Order_By>;
  chip_number?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sex?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: Pet */
export interface Pet_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "Pet" */
export type Pet_Select_Column =
  /** column name */
  | 'birthdate'
  /** column name */
  | 'breed'
  /** column name */
  | 'chip_number'
  /** column name */
  | 'client_id'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'sex'
  /** column name */
  | 'type_id';

/** input type for updating data in table "Pet" */
export interface Pet_Set_Input {
  birthdate?: InputMaybe<Scalars['date']['input']>;
  breed?: InputMaybe<Scalars['String']['input']>;
  chip_number?: InputMaybe<Scalars['String']['input']>;
  client_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sex?: InputMaybe<Scalars['Int']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate stddev on columns */
export interface Pet_Stddev_Fields {
  __typename?: 'Pet_stddev_fields';
  client_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sex?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "Pet" */
export interface Pet_Stddev_Order_By {
  client_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sex?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface Pet_Stddev_Pop_Fields {
  __typename?: 'Pet_stddev_pop_fields';
  client_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sex?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "Pet" */
export interface Pet_Stddev_Pop_Order_By {
  client_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sex?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface Pet_Stddev_Samp_Fields {
  __typename?: 'Pet_stddev_samp_fields';
  client_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sex?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "Pet" */
export interface Pet_Stddev_Samp_Order_By {
  client_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sex?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
}

/** Streaming cursor of the table "Pet" */
export interface Pet_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Pet_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Pet_Stream_Cursor_Value_Input {
  birthdate?: InputMaybe<Scalars['date']['input']>;
  breed?: InputMaybe<Scalars['String']['input']>;
  chip_number?: InputMaybe<Scalars['String']['input']>;
  client_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sex?: InputMaybe<Scalars['Int']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate sum on columns */
export interface Pet_Sum_Fields {
  __typename?: 'Pet_sum_fields';
  client_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  sex?: Maybe<Scalars['Int']['output']>;
  type_id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "Pet" */
export interface Pet_Sum_Order_By {
  client_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sex?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
}

/** update columns of table "Pet" */
export type Pet_Update_Column =
  /** column name */
  | 'birthdate'
  /** column name */
  | 'breed'
  /** column name */
  | 'chip_number'
  /** column name */
  | 'client_id'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'sex'
  /** column name */
  | 'type_id';

export interface Pet_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Pet_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Pet_Set_Input>;
  where: Pet_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Pet_Var_Pop_Fields {
  __typename?: 'Pet_var_pop_fields';
  client_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sex?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "Pet" */
export interface Pet_Var_Pop_Order_By {
  client_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sex?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface Pet_Var_Samp_Fields {
  __typename?: 'Pet_var_samp_fields';
  client_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sex?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "Pet" */
export interface Pet_Var_Samp_Order_By {
  client_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sex?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
}

/** aggregate variance on columns */
export interface Pet_Variance_Fields {
  __typename?: 'Pet_variance_fields';
  client_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sex?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "Pet" */
export interface Pet_Variance_Order_By {
  client_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sex?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
}

/** Прием врача (назначение) */
export interface Reception {
  __typename?: 'Reception';
  /** An object relationship */
  Doctor: Users;
  /** An object relationship */
  From: Users;
  /** An array relationship */
  Medicines: Array<ReceptionMedicines>;
  /** An aggregate relationship */
  Medicines_aggregate: ReceptionMedicines_Aggregate;
  /** An array relationship */
  Services: Array<ReceptionServices>;
  /** An aggregate relationship */
  Services_aggregate: ReceptionServices_Aggregate;
  /** An object relationship */
  Visit: Visit;
  doctor_id: Scalars['uuid']['output'];
  end_time?: Maybe<Scalars['timetz']['output']>;
  from_id: Scalars['uuid']['output'];
  id: Scalars['Int']['output'];
  internal_recomendation: Scalars['String']['output'];
  patient_recomendation: Scalars['String']['output'];
  start_time: Scalars['timetz']['output'];
  visit_id: Scalars['Int']['output'];
}


/** Прием врача (назначение) */
export interface ReceptionMedicinesArgs {
  distinct_on?: InputMaybe<Array<ReceptionMedicines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReceptionMedicines_Order_By>>;
  where?: InputMaybe<ReceptionMedicines_Bool_Exp>;
}


/** Прием врача (назначение) */
export interface ReceptionMedicines_AggregateArgs {
  distinct_on?: InputMaybe<Array<ReceptionMedicines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReceptionMedicines_Order_By>>;
  where?: InputMaybe<ReceptionMedicines_Bool_Exp>;
}


/** Прием врача (назначение) */
export interface ReceptionServicesArgs {
  distinct_on?: InputMaybe<Array<ReceptionServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReceptionServices_Order_By>>;
  where?: InputMaybe<ReceptionServices_Bool_Exp>;
}


/** Прием врача (назначение) */
export interface ReceptionServices_AggregateArgs {
  distinct_on?: InputMaybe<Array<ReceptionServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReceptionServices_Order_By>>;
  where?: InputMaybe<ReceptionServices_Bool_Exp>;
}

/** Препараты к приему */
export interface ReceptionMedicines {
  __typename?: 'ReceptionMedicines';
  /** An object relationship */
  Medicine: Medicine;
  /** An object relationship */
  Reception: Reception;
  done: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  medicine_id: Scalars['Int']['output'];
  reception_id: Scalars['Int']['output'];
  value: Scalars['float8']['output'];
}

/** aggregated selection of "ReceptionMedicines" */
export interface ReceptionMedicines_Aggregate {
  __typename?: 'ReceptionMedicines_aggregate';
  aggregate?: Maybe<ReceptionMedicines_Aggregate_Fields>;
  nodes: Array<ReceptionMedicines>;
}

export interface ReceptionMedicines_Aggregate_Bool_Exp {
  avg?: InputMaybe<ReceptionMedicines_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<ReceptionMedicines_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<ReceptionMedicines_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<ReceptionMedicines_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<ReceptionMedicines_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<ReceptionMedicines_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<ReceptionMedicines_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<ReceptionMedicines_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<ReceptionMedicines_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<ReceptionMedicines_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<ReceptionMedicines_Aggregate_Bool_Exp_Var_Samp>;
}

export interface ReceptionMedicines_Aggregate_Bool_Exp_Avg {
  arguments: ReceptionMedicines_Select_Column_ReceptionMedicines_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReceptionMedicines_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface ReceptionMedicines_Aggregate_Bool_Exp_Bool_And {
  arguments: ReceptionMedicines_Select_Column_ReceptionMedicines_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReceptionMedicines_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface ReceptionMedicines_Aggregate_Bool_Exp_Bool_Or {
  arguments: ReceptionMedicines_Select_Column_ReceptionMedicines_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReceptionMedicines_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface ReceptionMedicines_Aggregate_Bool_Exp_Corr {
  arguments: ReceptionMedicines_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReceptionMedicines_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface ReceptionMedicines_Aggregate_Bool_Exp_Corr_Arguments {
  X: ReceptionMedicines_Select_Column_ReceptionMedicines_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: ReceptionMedicines_Select_Column_ReceptionMedicines_Aggregate_Bool_Exp_Corr_Arguments_Columns;
}

export interface ReceptionMedicines_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<ReceptionMedicines_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReceptionMedicines_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

export interface ReceptionMedicines_Aggregate_Bool_Exp_Covar_Samp {
  arguments: ReceptionMedicines_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReceptionMedicines_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface ReceptionMedicines_Aggregate_Bool_Exp_Covar_Samp_Arguments {
  X: ReceptionMedicines_Select_Column_ReceptionMedicines_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: ReceptionMedicines_Select_Column_ReceptionMedicines_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
}

export interface ReceptionMedicines_Aggregate_Bool_Exp_Max {
  arguments: ReceptionMedicines_Select_Column_ReceptionMedicines_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReceptionMedicines_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface ReceptionMedicines_Aggregate_Bool_Exp_Min {
  arguments: ReceptionMedicines_Select_Column_ReceptionMedicines_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReceptionMedicines_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface ReceptionMedicines_Aggregate_Bool_Exp_Stddev_Samp {
  arguments: ReceptionMedicines_Select_Column_ReceptionMedicines_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReceptionMedicines_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface ReceptionMedicines_Aggregate_Bool_Exp_Sum {
  arguments: ReceptionMedicines_Select_Column_ReceptionMedicines_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReceptionMedicines_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface ReceptionMedicines_Aggregate_Bool_Exp_Var_Samp {
  arguments: ReceptionMedicines_Select_Column_ReceptionMedicines_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReceptionMedicines_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

/** aggregate fields of "ReceptionMedicines" */
export interface ReceptionMedicines_Aggregate_Fields {
  __typename?: 'ReceptionMedicines_aggregate_fields';
  avg?: Maybe<ReceptionMedicines_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ReceptionMedicines_Max_Fields>;
  min?: Maybe<ReceptionMedicines_Min_Fields>;
  stddev?: Maybe<ReceptionMedicines_Stddev_Fields>;
  stddev_pop?: Maybe<ReceptionMedicines_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ReceptionMedicines_Stddev_Samp_Fields>;
  sum?: Maybe<ReceptionMedicines_Sum_Fields>;
  var_pop?: Maybe<ReceptionMedicines_Var_Pop_Fields>;
  var_samp?: Maybe<ReceptionMedicines_Var_Samp_Fields>;
  variance?: Maybe<ReceptionMedicines_Variance_Fields>;
}


/** aggregate fields of "ReceptionMedicines" */
export interface ReceptionMedicines_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<ReceptionMedicines_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "ReceptionMedicines" */
export interface ReceptionMedicines_Aggregate_Order_By {
  avg?: InputMaybe<ReceptionMedicines_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ReceptionMedicines_Max_Order_By>;
  min?: InputMaybe<ReceptionMedicines_Min_Order_By>;
  stddev?: InputMaybe<ReceptionMedicines_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ReceptionMedicines_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ReceptionMedicines_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ReceptionMedicines_Sum_Order_By>;
  var_pop?: InputMaybe<ReceptionMedicines_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ReceptionMedicines_Var_Samp_Order_By>;
  variance?: InputMaybe<ReceptionMedicines_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "ReceptionMedicines" */
export interface ReceptionMedicines_Arr_Rel_Insert_Input {
  data: Array<ReceptionMedicines_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ReceptionMedicines_On_Conflict>;
}

/** aggregate avg on columns */
export interface ReceptionMedicines_Avg_Fields {
  __typename?: 'ReceptionMedicines_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  reception_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "ReceptionMedicines" */
export interface ReceptionMedicines_Avg_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  reception_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** Boolean expression to filter rows from the table "ReceptionMedicines". All fields are combined with a logical 'AND'. */
export interface ReceptionMedicines_Bool_Exp {
  Medicine?: InputMaybe<Medicine_Bool_Exp>;
  Reception?: InputMaybe<Reception_Bool_Exp>;
  _and?: InputMaybe<Array<ReceptionMedicines_Bool_Exp>>;
  _not?: InputMaybe<ReceptionMedicines_Bool_Exp>;
  _or?: InputMaybe<Array<ReceptionMedicines_Bool_Exp>>;
  done?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  medicine_id?: InputMaybe<Int_Comparison_Exp>;
  reception_id?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<Float8_Comparison_Exp>;
}

/** unique or primary key constraints on table "ReceptionMedicines" */
export type ReceptionMedicines_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'ReceptionMedicines_pkey';

/** input type for incrementing numeric columns in table "ReceptionMedicines" */
export interface ReceptionMedicines_Inc_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
  reception_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** input type for inserting data into table "ReceptionMedicines" */
export interface ReceptionMedicines_Insert_Input {
  Medicine?: InputMaybe<Medicine_Obj_Rel_Insert_Input>;
  Reception?: InputMaybe<Reception_Obj_Rel_Insert_Input>;
  done?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
  reception_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** aggregate max on columns */
export interface ReceptionMedicines_Max_Fields {
  __typename?: 'ReceptionMedicines_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  medicine_id?: Maybe<Scalars['Int']['output']>;
  reception_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
}

/** order by max() on columns of table "ReceptionMedicines" */
export interface ReceptionMedicines_Max_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  reception_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface ReceptionMedicines_Min_Fields {
  __typename?: 'ReceptionMedicines_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  medicine_id?: Maybe<Scalars['Int']['output']>;
  reception_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
}

/** order by min() on columns of table "ReceptionMedicines" */
export interface ReceptionMedicines_Min_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  reception_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "ReceptionMedicines" */
export interface ReceptionMedicines_Mutation_Response {
  __typename?: 'ReceptionMedicines_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ReceptionMedicines>;
}

/** on_conflict condition type for table "ReceptionMedicines" */
export interface ReceptionMedicines_On_Conflict {
  constraint: ReceptionMedicines_Constraint;
  update_columns?: Array<ReceptionMedicines_Update_Column>;
  where?: InputMaybe<ReceptionMedicines_Bool_Exp>;
}

/** Ordering options when selecting data from "ReceptionMedicines". */
export interface ReceptionMedicines_Order_By {
  Medicine?: InputMaybe<Medicine_Order_By>;
  Reception?: InputMaybe<Reception_Order_By>;
  done?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  reception_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** primary key columns input for table: ReceptionMedicines */
export interface ReceptionMedicines_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "ReceptionMedicines" */
export type ReceptionMedicines_Select_Column =
  /** column name */
  | 'done'
  /** column name */
  | 'id'
  /** column name */
  | 'medicine_id'
  /** column name */
  | 'reception_id'
  /** column name */
  | 'value';

/** select "ReceptionMedicines_aggregate_bool_exp_avg_arguments_columns" columns of table "ReceptionMedicines" */
export type ReceptionMedicines_Select_Column_ReceptionMedicines_Aggregate_Bool_Exp_Avg_Arguments_Columns =
  /** column name */
  | 'value';

/** select "ReceptionMedicines_aggregate_bool_exp_bool_and_arguments_columns" columns of table "ReceptionMedicines" */
export type ReceptionMedicines_Select_Column_ReceptionMedicines_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  | 'done';

/** select "ReceptionMedicines_aggregate_bool_exp_bool_or_arguments_columns" columns of table "ReceptionMedicines" */
export type ReceptionMedicines_Select_Column_ReceptionMedicines_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  | 'done';

/** select "ReceptionMedicines_aggregate_bool_exp_corr_arguments_columns" columns of table "ReceptionMedicines" */
export type ReceptionMedicines_Select_Column_ReceptionMedicines_Aggregate_Bool_Exp_Corr_Arguments_Columns =
  /** column name */
  | 'value';

/** select "ReceptionMedicines_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "ReceptionMedicines" */
export type ReceptionMedicines_Select_Column_ReceptionMedicines_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns =
  /** column name */
  | 'value';

/** select "ReceptionMedicines_aggregate_bool_exp_max_arguments_columns" columns of table "ReceptionMedicines" */
export type ReceptionMedicines_Select_Column_ReceptionMedicines_Aggregate_Bool_Exp_Max_Arguments_Columns =
  /** column name */
  | 'value';

/** select "ReceptionMedicines_aggregate_bool_exp_min_arguments_columns" columns of table "ReceptionMedicines" */
export type ReceptionMedicines_Select_Column_ReceptionMedicines_Aggregate_Bool_Exp_Min_Arguments_Columns =
  /** column name */
  | 'value';

/** select "ReceptionMedicines_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "ReceptionMedicines" */
export type ReceptionMedicines_Select_Column_ReceptionMedicines_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns =
  /** column name */
  | 'value';

/** select "ReceptionMedicines_aggregate_bool_exp_sum_arguments_columns" columns of table "ReceptionMedicines" */
export type ReceptionMedicines_Select_Column_ReceptionMedicines_Aggregate_Bool_Exp_Sum_Arguments_Columns =
  /** column name */
  | 'value';

/** select "ReceptionMedicines_aggregate_bool_exp_var_samp_arguments_columns" columns of table "ReceptionMedicines" */
export type ReceptionMedicines_Select_Column_ReceptionMedicines_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns =
  /** column name */
  | 'value';

/** input type for updating data in table "ReceptionMedicines" */
export interface ReceptionMedicines_Set_Input {
  done?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
  reception_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** aggregate stddev on columns */
export interface ReceptionMedicines_Stddev_Fields {
  __typename?: 'ReceptionMedicines_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  reception_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "ReceptionMedicines" */
export interface ReceptionMedicines_Stddev_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  reception_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface ReceptionMedicines_Stddev_Pop_Fields {
  __typename?: 'ReceptionMedicines_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  reception_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "ReceptionMedicines" */
export interface ReceptionMedicines_Stddev_Pop_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  reception_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface ReceptionMedicines_Stddev_Samp_Fields {
  __typename?: 'ReceptionMedicines_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  reception_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "ReceptionMedicines" */
export interface ReceptionMedicines_Stddev_Samp_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  reception_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** Streaming cursor of the table "ReceptionMedicines" */
export interface ReceptionMedicines_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: ReceptionMedicines_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface ReceptionMedicines_Stream_Cursor_Value_Input {
  done?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
  reception_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** aggregate sum on columns */
export interface ReceptionMedicines_Sum_Fields {
  __typename?: 'ReceptionMedicines_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  medicine_id?: Maybe<Scalars['Int']['output']>;
  reception_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
}

/** order by sum() on columns of table "ReceptionMedicines" */
export interface ReceptionMedicines_Sum_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  reception_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** update columns of table "ReceptionMedicines" */
export type ReceptionMedicines_Update_Column =
  /** column name */
  | 'done'
  /** column name */
  | 'id'
  /** column name */
  | 'medicine_id'
  /** column name */
  | 'reception_id'
  /** column name */
  | 'value';

export interface ReceptionMedicines_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ReceptionMedicines_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReceptionMedicines_Set_Input>;
  where: ReceptionMedicines_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface ReceptionMedicines_Var_Pop_Fields {
  __typename?: 'ReceptionMedicines_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  reception_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "ReceptionMedicines" */
export interface ReceptionMedicines_Var_Pop_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  reception_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface ReceptionMedicines_Var_Samp_Fields {
  __typename?: 'ReceptionMedicines_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  reception_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "ReceptionMedicines" */
export interface ReceptionMedicines_Var_Samp_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  reception_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate variance on columns */
export interface ReceptionMedicines_Variance_Fields {
  __typename?: 'ReceptionMedicines_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  reception_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "ReceptionMedicines" */
export interface ReceptionMedicines_Variance_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  reception_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** Услуги оказанные при приеме */
export interface ReceptionServices {
  __typename?: 'ReceptionServices';
  /** An object relationship */
  Reception: Reception;
  /** An object relationship */
  Service: Service;
  id: Scalars['Int']['output'];
  price: Scalars['float8']['output'];
  reception_id: Scalars['Int']['output'];
  service_id: Scalars['Int']['output'];
  value: Scalars['float8']['output'];
}

/** aggregated selection of "ReceptionServices" */
export interface ReceptionServices_Aggregate {
  __typename?: 'ReceptionServices_aggregate';
  aggregate?: Maybe<ReceptionServices_Aggregate_Fields>;
  nodes: Array<ReceptionServices>;
}

export interface ReceptionServices_Aggregate_Bool_Exp {
  avg?: InputMaybe<ReceptionServices_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<ReceptionServices_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<ReceptionServices_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<ReceptionServices_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<ReceptionServices_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<ReceptionServices_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<ReceptionServices_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<ReceptionServices_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<ReceptionServices_Aggregate_Bool_Exp_Var_Samp>;
}

export interface ReceptionServices_Aggregate_Bool_Exp_Avg {
  arguments: ReceptionServices_Select_Column_ReceptionServices_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReceptionServices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface ReceptionServices_Aggregate_Bool_Exp_Corr {
  arguments: ReceptionServices_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReceptionServices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface ReceptionServices_Aggregate_Bool_Exp_Corr_Arguments {
  X: ReceptionServices_Select_Column_ReceptionServices_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: ReceptionServices_Select_Column_ReceptionServices_Aggregate_Bool_Exp_Corr_Arguments_Columns;
}

export interface ReceptionServices_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<ReceptionServices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReceptionServices_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

export interface ReceptionServices_Aggregate_Bool_Exp_Covar_Samp {
  arguments: ReceptionServices_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReceptionServices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface ReceptionServices_Aggregate_Bool_Exp_Covar_Samp_Arguments {
  X: ReceptionServices_Select_Column_ReceptionServices_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: ReceptionServices_Select_Column_ReceptionServices_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
}

export interface ReceptionServices_Aggregate_Bool_Exp_Max {
  arguments: ReceptionServices_Select_Column_ReceptionServices_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReceptionServices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface ReceptionServices_Aggregate_Bool_Exp_Min {
  arguments: ReceptionServices_Select_Column_ReceptionServices_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReceptionServices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface ReceptionServices_Aggregate_Bool_Exp_Stddev_Samp {
  arguments: ReceptionServices_Select_Column_ReceptionServices_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReceptionServices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface ReceptionServices_Aggregate_Bool_Exp_Sum {
  arguments: ReceptionServices_Select_Column_ReceptionServices_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReceptionServices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface ReceptionServices_Aggregate_Bool_Exp_Var_Samp {
  arguments: ReceptionServices_Select_Column_ReceptionServices_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ReceptionServices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

/** aggregate fields of "ReceptionServices" */
export interface ReceptionServices_Aggregate_Fields {
  __typename?: 'ReceptionServices_aggregate_fields';
  avg?: Maybe<ReceptionServices_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ReceptionServices_Max_Fields>;
  min?: Maybe<ReceptionServices_Min_Fields>;
  stddev?: Maybe<ReceptionServices_Stddev_Fields>;
  stddev_pop?: Maybe<ReceptionServices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ReceptionServices_Stddev_Samp_Fields>;
  sum?: Maybe<ReceptionServices_Sum_Fields>;
  var_pop?: Maybe<ReceptionServices_Var_Pop_Fields>;
  var_samp?: Maybe<ReceptionServices_Var_Samp_Fields>;
  variance?: Maybe<ReceptionServices_Variance_Fields>;
}


/** aggregate fields of "ReceptionServices" */
export interface ReceptionServices_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<ReceptionServices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "ReceptionServices" */
export interface ReceptionServices_Aggregate_Order_By {
  avg?: InputMaybe<ReceptionServices_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ReceptionServices_Max_Order_By>;
  min?: InputMaybe<ReceptionServices_Min_Order_By>;
  stddev?: InputMaybe<ReceptionServices_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ReceptionServices_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ReceptionServices_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ReceptionServices_Sum_Order_By>;
  var_pop?: InputMaybe<ReceptionServices_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ReceptionServices_Var_Samp_Order_By>;
  variance?: InputMaybe<ReceptionServices_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "ReceptionServices" */
export interface ReceptionServices_Arr_Rel_Insert_Input {
  data: Array<ReceptionServices_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ReceptionServices_On_Conflict>;
}

/** aggregate avg on columns */
export interface ReceptionServices_Avg_Fields {
  __typename?: 'ReceptionServices_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  reception_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "ReceptionServices" */
export interface ReceptionServices_Avg_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  reception_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** Boolean expression to filter rows from the table "ReceptionServices". All fields are combined with a logical 'AND'. */
export interface ReceptionServices_Bool_Exp {
  Reception?: InputMaybe<Reception_Bool_Exp>;
  Service?: InputMaybe<Service_Bool_Exp>;
  _and?: InputMaybe<Array<ReceptionServices_Bool_Exp>>;
  _not?: InputMaybe<ReceptionServices_Bool_Exp>;
  _or?: InputMaybe<Array<ReceptionServices_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  price?: InputMaybe<Float8_Comparison_Exp>;
  reception_id?: InputMaybe<Int_Comparison_Exp>;
  service_id?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<Float8_Comparison_Exp>;
}

/** unique or primary key constraints on table "ReceptionServices" */
export type ReceptionServices_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'ReceptionServices_pkey';

/** input type for incrementing numeric columns in table "ReceptionServices" */
export interface ReceptionServices_Inc_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  reception_id?: InputMaybe<Scalars['Int']['input']>;
  service_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** input type for inserting data into table "ReceptionServices" */
export interface ReceptionServices_Insert_Input {
  Reception?: InputMaybe<Reception_Obj_Rel_Insert_Input>;
  Service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  reception_id?: InputMaybe<Scalars['Int']['input']>;
  service_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** aggregate max on columns */
export interface ReceptionServices_Max_Fields {
  __typename?: 'ReceptionServices_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  reception_id?: Maybe<Scalars['Int']['output']>;
  service_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
}

/** order by max() on columns of table "ReceptionServices" */
export interface ReceptionServices_Max_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  reception_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface ReceptionServices_Min_Fields {
  __typename?: 'ReceptionServices_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  reception_id?: Maybe<Scalars['Int']['output']>;
  service_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
}

/** order by min() on columns of table "ReceptionServices" */
export interface ReceptionServices_Min_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  reception_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "ReceptionServices" */
export interface ReceptionServices_Mutation_Response {
  __typename?: 'ReceptionServices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ReceptionServices>;
}

/** on_conflict condition type for table "ReceptionServices" */
export interface ReceptionServices_On_Conflict {
  constraint: ReceptionServices_Constraint;
  update_columns?: Array<ReceptionServices_Update_Column>;
  where?: InputMaybe<ReceptionServices_Bool_Exp>;
}

/** Ordering options when selecting data from "ReceptionServices". */
export interface ReceptionServices_Order_By {
  Reception?: InputMaybe<Reception_Order_By>;
  Service?: InputMaybe<Service_Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  reception_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** primary key columns input for table: ReceptionServices */
export interface ReceptionServices_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "ReceptionServices" */
export type ReceptionServices_Select_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'price'
  /** column name */
  | 'reception_id'
  /** column name */
  | 'service_id'
  /** column name */
  | 'value';

/** select "ReceptionServices_aggregate_bool_exp_avg_arguments_columns" columns of table "ReceptionServices" */
export type ReceptionServices_Select_Column_ReceptionServices_Aggregate_Bool_Exp_Avg_Arguments_Columns =
  /** column name */
  | 'price'
  /** column name */
  | 'value';

/** select "ReceptionServices_aggregate_bool_exp_corr_arguments_columns" columns of table "ReceptionServices" */
export type ReceptionServices_Select_Column_ReceptionServices_Aggregate_Bool_Exp_Corr_Arguments_Columns =
  /** column name */
  | 'price'
  /** column name */
  | 'value';

/** select "ReceptionServices_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "ReceptionServices" */
export type ReceptionServices_Select_Column_ReceptionServices_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns =
  /** column name */
  | 'price'
  /** column name */
  | 'value';

/** select "ReceptionServices_aggregate_bool_exp_max_arguments_columns" columns of table "ReceptionServices" */
export type ReceptionServices_Select_Column_ReceptionServices_Aggregate_Bool_Exp_Max_Arguments_Columns =
  /** column name */
  | 'price'
  /** column name */
  | 'value';

/** select "ReceptionServices_aggregate_bool_exp_min_arguments_columns" columns of table "ReceptionServices" */
export type ReceptionServices_Select_Column_ReceptionServices_Aggregate_Bool_Exp_Min_Arguments_Columns =
  /** column name */
  | 'price'
  /** column name */
  | 'value';

/** select "ReceptionServices_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "ReceptionServices" */
export type ReceptionServices_Select_Column_ReceptionServices_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns =
  /** column name */
  | 'price'
  /** column name */
  | 'value';

/** select "ReceptionServices_aggregate_bool_exp_sum_arguments_columns" columns of table "ReceptionServices" */
export type ReceptionServices_Select_Column_ReceptionServices_Aggregate_Bool_Exp_Sum_Arguments_Columns =
  /** column name */
  | 'price'
  /** column name */
  | 'value';

/** select "ReceptionServices_aggregate_bool_exp_var_samp_arguments_columns" columns of table "ReceptionServices" */
export type ReceptionServices_Select_Column_ReceptionServices_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns =
  /** column name */
  | 'price'
  /** column name */
  | 'value';

/** input type for updating data in table "ReceptionServices" */
export interface ReceptionServices_Set_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  reception_id?: InputMaybe<Scalars['Int']['input']>;
  service_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** aggregate stddev on columns */
export interface ReceptionServices_Stddev_Fields {
  __typename?: 'ReceptionServices_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  reception_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "ReceptionServices" */
export interface ReceptionServices_Stddev_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  reception_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface ReceptionServices_Stddev_Pop_Fields {
  __typename?: 'ReceptionServices_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  reception_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "ReceptionServices" */
export interface ReceptionServices_Stddev_Pop_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  reception_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface ReceptionServices_Stddev_Samp_Fields {
  __typename?: 'ReceptionServices_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  reception_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "ReceptionServices" */
export interface ReceptionServices_Stddev_Samp_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  reception_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** Streaming cursor of the table "ReceptionServices" */
export interface ReceptionServices_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: ReceptionServices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface ReceptionServices_Stream_Cursor_Value_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  reception_id?: InputMaybe<Scalars['Int']['input']>;
  service_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** aggregate sum on columns */
export interface ReceptionServices_Sum_Fields {
  __typename?: 'ReceptionServices_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  reception_id?: Maybe<Scalars['Int']['output']>;
  service_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
}

/** order by sum() on columns of table "ReceptionServices" */
export interface ReceptionServices_Sum_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  reception_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** update columns of table "ReceptionServices" */
export type ReceptionServices_Update_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'price'
  /** column name */
  | 'reception_id'
  /** column name */
  | 'service_id'
  /** column name */
  | 'value';

export interface ReceptionServices_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ReceptionServices_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReceptionServices_Set_Input>;
  where: ReceptionServices_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface ReceptionServices_Var_Pop_Fields {
  __typename?: 'ReceptionServices_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  reception_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "ReceptionServices" */
export interface ReceptionServices_Var_Pop_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  reception_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface ReceptionServices_Var_Samp_Fields {
  __typename?: 'ReceptionServices_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  reception_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "ReceptionServices" */
export interface ReceptionServices_Var_Samp_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  reception_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate variance on columns */
export interface ReceptionServices_Variance_Fields {
  __typename?: 'ReceptionServices_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  reception_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "ReceptionServices" */
export interface ReceptionServices_Variance_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  reception_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregated selection of "Reception" */
export interface Reception_Aggregate {
  __typename?: 'Reception_aggregate';
  aggregate?: Maybe<Reception_Aggregate_Fields>;
  nodes: Array<Reception>;
}

export interface Reception_Aggregate_Bool_Exp {
  count?: InputMaybe<Reception_Aggregate_Bool_Exp_Count>;
}

export interface Reception_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Reception_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Reception_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "Reception" */
export interface Reception_Aggregate_Fields {
  __typename?: 'Reception_aggregate_fields';
  avg?: Maybe<Reception_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Reception_Max_Fields>;
  min?: Maybe<Reception_Min_Fields>;
  stddev?: Maybe<Reception_Stddev_Fields>;
  stddev_pop?: Maybe<Reception_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reception_Stddev_Samp_Fields>;
  sum?: Maybe<Reception_Sum_Fields>;
  var_pop?: Maybe<Reception_Var_Pop_Fields>;
  var_samp?: Maybe<Reception_Var_Samp_Fields>;
  variance?: Maybe<Reception_Variance_Fields>;
}


/** aggregate fields of "Reception" */
export interface Reception_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Reception_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "Reception" */
export interface Reception_Aggregate_Order_By {
  avg?: InputMaybe<Reception_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reception_Max_Order_By>;
  min?: InputMaybe<Reception_Min_Order_By>;
  stddev?: InputMaybe<Reception_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Reception_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Reception_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Reception_Sum_Order_By>;
  var_pop?: InputMaybe<Reception_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Reception_Var_Samp_Order_By>;
  variance?: InputMaybe<Reception_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "Reception" */
export interface Reception_Arr_Rel_Insert_Input {
  data: Array<Reception_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reception_On_Conflict>;
}

/** aggregate avg on columns */
export interface Reception_Avg_Fields {
  __typename?: 'Reception_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  visit_id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "Reception" */
export interface Reception_Avg_Order_By {
  id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** Boolean expression to filter rows from the table "Reception". All fields are combined with a logical 'AND'. */
export interface Reception_Bool_Exp {
  Doctor?: InputMaybe<Users_Bool_Exp>;
  From?: InputMaybe<Users_Bool_Exp>;
  Medicines?: InputMaybe<ReceptionMedicines_Bool_Exp>;
  Medicines_aggregate?: InputMaybe<ReceptionMedicines_Aggregate_Bool_Exp>;
  Services?: InputMaybe<ReceptionServices_Bool_Exp>;
  Services_aggregate?: InputMaybe<ReceptionServices_Aggregate_Bool_Exp>;
  Visit?: InputMaybe<Visit_Bool_Exp>;
  _and?: InputMaybe<Array<Reception_Bool_Exp>>;
  _not?: InputMaybe<Reception_Bool_Exp>;
  _or?: InputMaybe<Array<Reception_Bool_Exp>>;
  doctor_id?: InputMaybe<Uuid_Comparison_Exp>;
  end_time?: InputMaybe<Timetz_Comparison_Exp>;
  from_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  internal_recomendation?: InputMaybe<String_Comparison_Exp>;
  patient_recomendation?: InputMaybe<String_Comparison_Exp>;
  start_time?: InputMaybe<Timetz_Comparison_Exp>;
  visit_id?: InputMaybe<Int_Comparison_Exp>;
}

/** unique or primary key constraints on table "Reception" */
export type Reception_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'Reception_pkey';

/** input type for incrementing numeric columns in table "Reception" */
export interface Reception_Inc_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  visit_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "Reception" */
export interface Reception_Insert_Input {
  Doctor?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  From?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  Medicines?: InputMaybe<ReceptionMedicines_Arr_Rel_Insert_Input>;
  Services?: InputMaybe<ReceptionServices_Arr_Rel_Insert_Input>;
  Visit?: InputMaybe<Visit_Obj_Rel_Insert_Input>;
  doctor_id?: InputMaybe<Scalars['uuid']['input']>;
  end_time?: InputMaybe<Scalars['timetz']['input']>;
  from_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  internal_recomendation?: InputMaybe<Scalars['String']['input']>;
  patient_recomendation?: InputMaybe<Scalars['String']['input']>;
  start_time?: InputMaybe<Scalars['timetz']['input']>;
  visit_id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate max on columns */
export interface Reception_Max_Fields {
  __typename?: 'Reception_max_fields';
  doctor_id?: Maybe<Scalars['uuid']['output']>;
  end_time?: Maybe<Scalars['timetz']['output']>;
  from_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  internal_recomendation?: Maybe<Scalars['String']['output']>;
  patient_recomendation?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['timetz']['output']>;
  visit_id?: Maybe<Scalars['Int']['output']>;
}

/** order by max() on columns of table "Reception" */
export interface Reception_Max_Order_By {
  doctor_id?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  from_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_recomendation?: InputMaybe<Order_By>;
  patient_recomendation?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface Reception_Min_Fields {
  __typename?: 'Reception_min_fields';
  doctor_id?: Maybe<Scalars['uuid']['output']>;
  end_time?: Maybe<Scalars['timetz']['output']>;
  from_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  internal_recomendation?: Maybe<Scalars['String']['output']>;
  patient_recomendation?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['timetz']['output']>;
  visit_id?: Maybe<Scalars['Int']['output']>;
}

/** order by min() on columns of table "Reception" */
export interface Reception_Min_Order_By {
  doctor_id?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  from_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_recomendation?: InputMaybe<Order_By>;
  patient_recomendation?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "Reception" */
export interface Reception_Mutation_Response {
  __typename?: 'Reception_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Reception>;
}

/** input type for inserting object relation for remote table "Reception" */
export interface Reception_Obj_Rel_Insert_Input {
  data: Reception_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Reception_On_Conflict>;
}

/** on_conflict condition type for table "Reception" */
export interface Reception_On_Conflict {
  constraint: Reception_Constraint;
  update_columns?: Array<Reception_Update_Column>;
  where?: InputMaybe<Reception_Bool_Exp>;
}

/** Ordering options when selecting data from "Reception". */
export interface Reception_Order_By {
  Doctor?: InputMaybe<Users_Order_By>;
  From?: InputMaybe<Users_Order_By>;
  Medicines_aggregate?: InputMaybe<ReceptionMedicines_Aggregate_Order_By>;
  Services_aggregate?: InputMaybe<ReceptionServices_Aggregate_Order_By>;
  Visit?: InputMaybe<Visit_Order_By>;
  doctor_id?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  from_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_recomendation?: InputMaybe<Order_By>;
  patient_recomendation?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: Reception */
export interface Reception_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "Reception" */
export type Reception_Select_Column =
  /** column name */
  | 'doctor_id'
  /** column name */
  | 'end_time'
  /** column name */
  | 'from_id'
  /** column name */
  | 'id'
  /** column name */
  | 'internal_recomendation'
  /** column name */
  | 'patient_recomendation'
  /** column name */
  | 'start_time'
  /** column name */
  | 'visit_id';

/** input type for updating data in table "Reception" */
export interface Reception_Set_Input {
  doctor_id?: InputMaybe<Scalars['uuid']['input']>;
  end_time?: InputMaybe<Scalars['timetz']['input']>;
  from_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  internal_recomendation?: InputMaybe<Scalars['String']['input']>;
  patient_recomendation?: InputMaybe<Scalars['String']['input']>;
  start_time?: InputMaybe<Scalars['timetz']['input']>;
  visit_id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate stddev on columns */
export interface Reception_Stddev_Fields {
  __typename?: 'Reception_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  visit_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "Reception" */
export interface Reception_Stddev_Order_By {
  id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface Reception_Stddev_Pop_Fields {
  __typename?: 'Reception_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  visit_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "Reception" */
export interface Reception_Stddev_Pop_Order_By {
  id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface Reception_Stddev_Samp_Fields {
  __typename?: 'Reception_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  visit_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "Reception" */
export interface Reception_Stddev_Samp_Order_By {
  id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** Streaming cursor of the table "Reception" */
export interface Reception_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Reception_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Reception_Stream_Cursor_Value_Input {
  doctor_id?: InputMaybe<Scalars['uuid']['input']>;
  end_time?: InputMaybe<Scalars['timetz']['input']>;
  from_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  internal_recomendation?: InputMaybe<Scalars['String']['input']>;
  patient_recomendation?: InputMaybe<Scalars['String']['input']>;
  start_time?: InputMaybe<Scalars['timetz']['input']>;
  visit_id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate sum on columns */
export interface Reception_Sum_Fields {
  __typename?: 'Reception_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  visit_id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "Reception" */
export interface Reception_Sum_Order_By {
  id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** update columns of table "Reception" */
export type Reception_Update_Column =
  /** column name */
  | 'doctor_id'
  /** column name */
  | 'end_time'
  /** column name */
  | 'from_id'
  /** column name */
  | 'id'
  /** column name */
  | 'internal_recomendation'
  /** column name */
  | 'patient_recomendation'
  /** column name */
  | 'start_time'
  /** column name */
  | 'visit_id';

export interface Reception_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reception_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reception_Set_Input>;
  where: Reception_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Reception_Var_Pop_Fields {
  __typename?: 'Reception_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  visit_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "Reception" */
export interface Reception_Var_Pop_Order_By {
  id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface Reception_Var_Samp_Fields {
  __typename?: 'Reception_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  visit_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "Reception" */
export interface Reception_Var_Samp_Order_By {
  id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** aggregate variance on columns */
export interface Reception_Variance_Fields {
  __typename?: 'Reception_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  visit_id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "Reception" */
export interface Reception_Variance_Order_By {
  id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** columns and relationships of "RegistrationDocument" */
export interface RegistrationDocument {
  __typename?: 'RegistrationDocument';
  /** An object relationship */
  Document: Documents;
  document_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
}

/** aggregated selection of "RegistrationDocument" */
export interface RegistrationDocument_Aggregate {
  __typename?: 'RegistrationDocument_aggregate';
  aggregate?: Maybe<RegistrationDocument_Aggregate_Fields>;
  nodes: Array<RegistrationDocument>;
}

/** aggregate fields of "RegistrationDocument" */
export interface RegistrationDocument_Aggregate_Fields {
  __typename?: 'RegistrationDocument_aggregate_fields';
  avg?: Maybe<RegistrationDocument_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RegistrationDocument_Max_Fields>;
  min?: Maybe<RegistrationDocument_Min_Fields>;
  stddev?: Maybe<RegistrationDocument_Stddev_Fields>;
  stddev_pop?: Maybe<RegistrationDocument_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RegistrationDocument_Stddev_Samp_Fields>;
  sum?: Maybe<RegistrationDocument_Sum_Fields>;
  var_pop?: Maybe<RegistrationDocument_Var_Pop_Fields>;
  var_samp?: Maybe<RegistrationDocument_Var_Samp_Fields>;
  variance?: Maybe<RegistrationDocument_Variance_Fields>;
}


/** aggregate fields of "RegistrationDocument" */
export interface RegistrationDocument_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<RegistrationDocument_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate avg on columns */
export interface RegistrationDocument_Avg_Fields {
  __typename?: 'RegistrationDocument_avg_fields';
  document_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to filter rows from the table "RegistrationDocument". All fields are combined with a logical 'AND'. */
export interface RegistrationDocument_Bool_Exp {
  Document?: InputMaybe<Documents_Bool_Exp>;
  _and?: InputMaybe<Array<RegistrationDocument_Bool_Exp>>;
  _not?: InputMaybe<RegistrationDocument_Bool_Exp>;
  _or?: InputMaybe<Array<RegistrationDocument_Bool_Exp>>;
  document_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
}

/** unique or primary key constraints on table "RegistrationDocument" */
export type RegistrationDocument_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'RegistrationDocument_pkey';

/** input type for incrementing numeric columns in table "RegistrationDocument" */
export interface RegistrationDocument_Inc_Input {
  document_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "RegistrationDocument" */
export interface RegistrationDocument_Insert_Input {
  Document?: InputMaybe<Documents_Obj_Rel_Insert_Input>;
  document_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate max on columns */
export interface RegistrationDocument_Max_Fields {
  __typename?: 'RegistrationDocument_max_fields';
  document_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
}

/** aggregate min on columns */
export interface RegistrationDocument_Min_Fields {
  __typename?: 'RegistrationDocument_min_fields';
  document_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
}

/** response of any mutation on the table "RegistrationDocument" */
export interface RegistrationDocument_Mutation_Response {
  __typename?: 'RegistrationDocument_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RegistrationDocument>;
}

/** on_conflict condition type for table "RegistrationDocument" */
export interface RegistrationDocument_On_Conflict {
  constraint: RegistrationDocument_Constraint;
  update_columns?: Array<RegistrationDocument_Update_Column>;
  where?: InputMaybe<RegistrationDocument_Bool_Exp>;
}

/** Ordering options when selecting data from "RegistrationDocument". */
export interface RegistrationDocument_Order_By {
  Document?: InputMaybe<Documents_Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: RegistrationDocument */
export interface RegistrationDocument_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "RegistrationDocument" */
export type RegistrationDocument_Select_Column =
  /** column name */
  | 'document_id'
  /** column name */
  | 'id';

/** input type for updating data in table "RegistrationDocument" */
export interface RegistrationDocument_Set_Input {
  document_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate stddev on columns */
export interface RegistrationDocument_Stddev_Fields {
  __typename?: 'RegistrationDocument_stddev_fields';
  document_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_pop on columns */
export interface RegistrationDocument_Stddev_Pop_Fields {
  __typename?: 'RegistrationDocument_stddev_pop_fields';
  document_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_samp on columns */
export interface RegistrationDocument_Stddev_Samp_Fields {
  __typename?: 'RegistrationDocument_stddev_samp_fields';
  document_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** Streaming cursor of the table "RegistrationDocument" */
export interface RegistrationDocument_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: RegistrationDocument_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface RegistrationDocument_Stream_Cursor_Value_Input {
  document_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate sum on columns */
export interface RegistrationDocument_Sum_Fields {
  __typename?: 'RegistrationDocument_sum_fields';
  document_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
}

/** update columns of table "RegistrationDocument" */
export type RegistrationDocument_Update_Column =
  /** column name */
  | 'document_id'
  /** column name */
  | 'id';

export interface RegistrationDocument_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RegistrationDocument_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RegistrationDocument_Set_Input>;
  where: RegistrationDocument_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface RegistrationDocument_Var_Pop_Fields {
  __typename?: 'RegistrationDocument_var_pop_fields';
  document_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate var_samp on columns */
export interface RegistrationDocument_Var_Samp_Fields {
  __typename?: 'RegistrationDocument_var_samp_fields';
  document_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate variance on columns */
export interface RegistrationDocument_Variance_Fields {
  __typename?: 'RegistrationDocument_variance_fields';
  document_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** columns and relationships of "Roles" */
export interface Roles {
  __typename?: 'Roles';
  ID: Scalars['uuid']['output'];
  Name: Scalars['String']['output'];
  /** An array relationship */
  Users: Array<Users>;
  /** An aggregate relationship */
  Users_aggregate: Users_Aggregate;
}


/** columns and relationships of "Roles" */
export interface RolesUsersArgs {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
}


/** columns and relationships of "Roles" */
export interface RolesUsers_AggregateArgs {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
}

/** aggregated selection of "Roles" */
export interface Roles_Aggregate {
  __typename?: 'Roles_aggregate';
  aggregate?: Maybe<Roles_Aggregate_Fields>;
  nodes: Array<Roles>;
}

/** aggregate fields of "Roles" */
export interface Roles_Aggregate_Fields {
  __typename?: 'Roles_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Roles_Max_Fields>;
  min?: Maybe<Roles_Min_Fields>;
}


/** aggregate fields of "Roles" */
export interface Roles_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "Roles". All fields are combined with a logical 'AND'. */
export interface Roles_Bool_Exp {
  ID?: InputMaybe<Uuid_Comparison_Exp>;
  Name?: InputMaybe<String_Comparison_Exp>;
  Users?: InputMaybe<Users_Bool_Exp>;
  Users_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Roles_Bool_Exp>>;
  _not?: InputMaybe<Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Roles_Bool_Exp>>;
}

/** unique or primary key constraints on table "Roles" */
export type Roles_Constraint =
  /** unique or primary key constraint on columns "ID" */
  | 'PK_Roles';

/** input type for inserting data into table "Roles" */
export interface Roles_Insert_Input {
  ID?: InputMaybe<Scalars['uuid']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  Users?: InputMaybe<Users_Arr_Rel_Insert_Input>;
}

/** aggregate max on columns */
export interface Roles_Max_Fields {
  __typename?: 'Roles_max_fields';
  ID?: Maybe<Scalars['uuid']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface Roles_Min_Fields {
  __typename?: 'Roles_min_fields';
  ID?: Maybe<Scalars['uuid']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "Roles" */
export interface Roles_Mutation_Response {
  __typename?: 'Roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Roles>;
}

/** input type for inserting object relation for remote table "Roles" */
export interface Roles_Obj_Rel_Insert_Input {
  data: Roles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Roles_On_Conflict>;
}

/** on_conflict condition type for table "Roles" */
export interface Roles_On_Conflict {
  constraint: Roles_Constraint;
  update_columns?: Array<Roles_Update_Column>;
  where?: InputMaybe<Roles_Bool_Exp>;
}

/** Ordering options when selecting data from "Roles". */
export interface Roles_Order_By {
  ID?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  Users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
}

/** primary key columns input for table: Roles */
export interface Roles_Pk_Columns_Input {
  ID: Scalars['uuid']['input'];
}

/** select columns of table "Roles" */
export type Roles_Select_Column =
  /** column name */
  | 'ID'
  /** column name */
  | 'Name';

/** input type for updating data in table "Roles" */
export interface Roles_Set_Input {
  ID?: InputMaybe<Scalars['uuid']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "Roles" */
export interface Roles_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Roles_Stream_Cursor_Value_Input {
  ID?: InputMaybe<Scalars['uuid']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "Roles" */
export type Roles_Update_Column =
  /** column name */
  | 'ID'
  /** column name */
  | 'Name';

export interface Roles_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Roles_Set_Input>;
  where: Roles_Bool_Exp;
}

/** columns and relationships of "Schema" */
export interface Schema {
  __typename?: 'Schema';
  /** An object relationship */
  Category: SchemaCategory;
  /** An array relationship */
  SchemaMedicines: Array<SchemaMedicine>;
  /** An aggregate relationship */
  SchemaMedicines_aggregate: SchemaMedicine_Aggregate;
  /** An array relationship */
  SchemaServices: Array<SchemaService>;
  /** An aggregate relationship */
  SchemaServices_aggregate: SchemaService_Aggregate;
  category_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}


/** columns and relationships of "Schema" */
export interface SchemaSchemaMedicinesArgs {
  distinct_on?: InputMaybe<Array<SchemaMedicine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SchemaMedicine_Order_By>>;
  where?: InputMaybe<SchemaMedicine_Bool_Exp>;
}


/** columns and relationships of "Schema" */
export interface SchemaSchemaMedicines_AggregateArgs {
  distinct_on?: InputMaybe<Array<SchemaMedicine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SchemaMedicine_Order_By>>;
  where?: InputMaybe<SchemaMedicine_Bool_Exp>;
}


/** columns and relationships of "Schema" */
export interface SchemaSchemaServicesArgs {
  distinct_on?: InputMaybe<Array<SchemaService_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SchemaService_Order_By>>;
  where?: InputMaybe<SchemaService_Bool_Exp>;
}


/** columns and relationships of "Schema" */
export interface SchemaSchemaServices_AggregateArgs {
  distinct_on?: InputMaybe<Array<SchemaService_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SchemaService_Order_By>>;
  where?: InputMaybe<SchemaService_Bool_Exp>;
}

/** columns and relationships of "SchemaCategory" */
export interface SchemaCategory {
  __typename?: 'SchemaCategory';
  /** An array relationship */
  Childs: Array<SchemaCategory>;
  /** An aggregate relationship */
  Childs_aggregate: SchemaCategory_Aggregate;
  /** An object relationship */
  Parent?: Maybe<SchemaCategory>;
  /** An array relationship */
  Schemas: Array<Schema>;
  /** An aggregate relationship */
  Schemas_aggregate: Schema_Aggregate;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  parent_id?: Maybe<Scalars['Int']['output']>;
}


/** columns and relationships of "SchemaCategory" */
export interface SchemaCategoryChildsArgs {
  distinct_on?: InputMaybe<Array<SchemaCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SchemaCategory_Order_By>>;
  where?: InputMaybe<SchemaCategory_Bool_Exp>;
}


/** columns and relationships of "SchemaCategory" */
export interface SchemaCategoryChilds_AggregateArgs {
  distinct_on?: InputMaybe<Array<SchemaCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SchemaCategory_Order_By>>;
  where?: InputMaybe<SchemaCategory_Bool_Exp>;
}


/** columns and relationships of "SchemaCategory" */
export interface SchemaCategorySchemasArgs {
  distinct_on?: InputMaybe<Array<Schema_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Schema_Order_By>>;
  where?: InputMaybe<Schema_Bool_Exp>;
}


/** columns and relationships of "SchemaCategory" */
export interface SchemaCategorySchemas_AggregateArgs {
  distinct_on?: InputMaybe<Array<Schema_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Schema_Order_By>>;
  where?: InputMaybe<Schema_Bool_Exp>;
}

/** aggregated selection of "SchemaCategory" */
export interface SchemaCategory_Aggregate {
  __typename?: 'SchemaCategory_aggregate';
  aggregate?: Maybe<SchemaCategory_Aggregate_Fields>;
  nodes: Array<SchemaCategory>;
}

export interface SchemaCategory_Aggregate_Bool_Exp {
  count?: InputMaybe<SchemaCategory_Aggregate_Bool_Exp_Count>;
}

export interface SchemaCategory_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<SchemaCategory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SchemaCategory_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "SchemaCategory" */
export interface SchemaCategory_Aggregate_Fields {
  __typename?: 'SchemaCategory_aggregate_fields';
  avg?: Maybe<SchemaCategory_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<SchemaCategory_Max_Fields>;
  min?: Maybe<SchemaCategory_Min_Fields>;
  stddev?: Maybe<SchemaCategory_Stddev_Fields>;
  stddev_pop?: Maybe<SchemaCategory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SchemaCategory_Stddev_Samp_Fields>;
  sum?: Maybe<SchemaCategory_Sum_Fields>;
  var_pop?: Maybe<SchemaCategory_Var_Pop_Fields>;
  var_samp?: Maybe<SchemaCategory_Var_Samp_Fields>;
  variance?: Maybe<SchemaCategory_Variance_Fields>;
}


/** aggregate fields of "SchemaCategory" */
export interface SchemaCategory_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<SchemaCategory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "SchemaCategory" */
export interface SchemaCategory_Aggregate_Order_By {
  avg?: InputMaybe<SchemaCategory_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<SchemaCategory_Max_Order_By>;
  min?: InputMaybe<SchemaCategory_Min_Order_By>;
  stddev?: InputMaybe<SchemaCategory_Stddev_Order_By>;
  stddev_pop?: InputMaybe<SchemaCategory_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<SchemaCategory_Stddev_Samp_Order_By>;
  sum?: InputMaybe<SchemaCategory_Sum_Order_By>;
  var_pop?: InputMaybe<SchemaCategory_Var_Pop_Order_By>;
  var_samp?: InputMaybe<SchemaCategory_Var_Samp_Order_By>;
  variance?: InputMaybe<SchemaCategory_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "SchemaCategory" */
export interface SchemaCategory_Arr_Rel_Insert_Input {
  data: Array<SchemaCategory_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<SchemaCategory_On_Conflict>;
}

/** aggregate avg on columns */
export interface SchemaCategory_Avg_Fields {
  __typename?: 'SchemaCategory_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "SchemaCategory" */
export interface SchemaCategory_Avg_Order_By {
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
}

/** Boolean expression to filter rows from the table "SchemaCategory". All fields are combined with a logical 'AND'. */
export interface SchemaCategory_Bool_Exp {
  Childs?: InputMaybe<SchemaCategory_Bool_Exp>;
  Childs_aggregate?: InputMaybe<SchemaCategory_Aggregate_Bool_Exp>;
  Parent?: InputMaybe<SchemaCategory_Bool_Exp>;
  Schemas?: InputMaybe<Schema_Bool_Exp>;
  Schemas_aggregate?: InputMaybe<Schema_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<SchemaCategory_Bool_Exp>>;
  _not?: InputMaybe<SchemaCategory_Bool_Exp>;
  _or?: InputMaybe<Array<SchemaCategory_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  parent_id?: InputMaybe<Int_Comparison_Exp>;
}

/** unique or primary key constraints on table "SchemaCategory" */
export type SchemaCategory_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'SchemaCategory_pkey';

/** input type for incrementing numeric columns in table "SchemaCategory" */
export interface SchemaCategory_Inc_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  parent_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "SchemaCategory" */
export interface SchemaCategory_Insert_Input {
  Childs?: InputMaybe<SchemaCategory_Arr_Rel_Insert_Input>;
  Parent?: InputMaybe<SchemaCategory_Obj_Rel_Insert_Input>;
  Schemas?: InputMaybe<Schema_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate max on columns */
export interface SchemaCategory_Max_Fields {
  __typename?: 'SchemaCategory_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['Int']['output']>;
}

/** order by max() on columns of table "SchemaCategory" */
export interface SchemaCategory_Max_Order_By {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface SchemaCategory_Min_Fields {
  __typename?: 'SchemaCategory_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['Int']['output']>;
}

/** order by min() on columns of table "SchemaCategory" */
export interface SchemaCategory_Min_Order_By {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "SchemaCategory" */
export interface SchemaCategory_Mutation_Response {
  __typename?: 'SchemaCategory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<SchemaCategory>;
}

/** input type for inserting object relation for remote table "SchemaCategory" */
export interface SchemaCategory_Obj_Rel_Insert_Input {
  data: SchemaCategory_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<SchemaCategory_On_Conflict>;
}

/** on_conflict condition type for table "SchemaCategory" */
export interface SchemaCategory_On_Conflict {
  constraint: SchemaCategory_Constraint;
  update_columns?: Array<SchemaCategory_Update_Column>;
  where?: InputMaybe<SchemaCategory_Bool_Exp>;
}

/** Ordering options when selecting data from "SchemaCategory". */
export interface SchemaCategory_Order_By {
  Childs_aggregate?: InputMaybe<SchemaCategory_Aggregate_Order_By>;
  Parent?: InputMaybe<SchemaCategory_Order_By>;
  Schemas_aggregate?: InputMaybe<Schema_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: SchemaCategory */
export interface SchemaCategory_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "SchemaCategory" */
export type SchemaCategory_Select_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'parent_id';

/** input type for updating data in table "SchemaCategory" */
export interface SchemaCategory_Set_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate stddev on columns */
export interface SchemaCategory_Stddev_Fields {
  __typename?: 'SchemaCategory_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "SchemaCategory" */
export interface SchemaCategory_Stddev_Order_By {
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface SchemaCategory_Stddev_Pop_Fields {
  __typename?: 'SchemaCategory_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "SchemaCategory" */
export interface SchemaCategory_Stddev_Pop_Order_By {
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface SchemaCategory_Stddev_Samp_Fields {
  __typename?: 'SchemaCategory_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "SchemaCategory" */
export interface SchemaCategory_Stddev_Samp_Order_By {
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
}

/** Streaming cursor of the table "SchemaCategory" */
export interface SchemaCategory_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: SchemaCategory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface SchemaCategory_Stream_Cursor_Value_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate sum on columns */
export interface SchemaCategory_Sum_Fields {
  __typename?: 'SchemaCategory_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  parent_id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "SchemaCategory" */
export interface SchemaCategory_Sum_Order_By {
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
}

/** update columns of table "SchemaCategory" */
export type SchemaCategory_Update_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'parent_id';

export interface SchemaCategory_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SchemaCategory_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SchemaCategory_Set_Input>;
  where: SchemaCategory_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface SchemaCategory_Var_Pop_Fields {
  __typename?: 'SchemaCategory_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "SchemaCategory" */
export interface SchemaCategory_Var_Pop_Order_By {
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface SchemaCategory_Var_Samp_Fields {
  __typename?: 'SchemaCategory_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "SchemaCategory" */
export interface SchemaCategory_Var_Samp_Order_By {
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
}

/** aggregate variance on columns */
export interface SchemaCategory_Variance_Fields {
  __typename?: 'SchemaCategory_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "SchemaCategory" */
export interface SchemaCategory_Variance_Order_By {
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
}

/** columns and relationships of "SchemaMedicine" */
export interface SchemaMedicine {
  __typename?: 'SchemaMedicine';
  /** An object relationship */
  Medicine: Medicine;
  /** An object relationship */
  Schema: Schema;
  id: Scalars['Int']['output'];
  medicine_id: Scalars['Int']['output'];
  schema_id: Scalars['Int']['output'];
  value?: Maybe<Scalars['float8']['output']>;
}

/** aggregated selection of "SchemaMedicine" */
export interface SchemaMedicine_Aggregate {
  __typename?: 'SchemaMedicine_aggregate';
  aggregate?: Maybe<SchemaMedicine_Aggregate_Fields>;
  nodes: Array<SchemaMedicine>;
}

export interface SchemaMedicine_Aggregate_Bool_Exp {
  avg?: InputMaybe<SchemaMedicine_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<SchemaMedicine_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<SchemaMedicine_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<SchemaMedicine_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<SchemaMedicine_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<SchemaMedicine_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<SchemaMedicine_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<SchemaMedicine_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<SchemaMedicine_Aggregate_Bool_Exp_Var_Samp>;
}

export interface SchemaMedicine_Aggregate_Bool_Exp_Avg {
  arguments: SchemaMedicine_Select_Column_SchemaMedicine_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SchemaMedicine_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface SchemaMedicine_Aggregate_Bool_Exp_Corr {
  arguments: SchemaMedicine_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SchemaMedicine_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface SchemaMedicine_Aggregate_Bool_Exp_Corr_Arguments {
  X: SchemaMedicine_Select_Column_SchemaMedicine_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: SchemaMedicine_Select_Column_SchemaMedicine_Aggregate_Bool_Exp_Corr_Arguments_Columns;
}

export interface SchemaMedicine_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<SchemaMedicine_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SchemaMedicine_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

export interface SchemaMedicine_Aggregate_Bool_Exp_Covar_Samp {
  arguments: SchemaMedicine_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SchemaMedicine_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface SchemaMedicine_Aggregate_Bool_Exp_Covar_Samp_Arguments {
  X: SchemaMedicine_Select_Column_SchemaMedicine_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: SchemaMedicine_Select_Column_SchemaMedicine_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
}

export interface SchemaMedicine_Aggregate_Bool_Exp_Max {
  arguments: SchemaMedicine_Select_Column_SchemaMedicine_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SchemaMedicine_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface SchemaMedicine_Aggregate_Bool_Exp_Min {
  arguments: SchemaMedicine_Select_Column_SchemaMedicine_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SchemaMedicine_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface SchemaMedicine_Aggregate_Bool_Exp_Stddev_Samp {
  arguments: SchemaMedicine_Select_Column_SchemaMedicine_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SchemaMedicine_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface SchemaMedicine_Aggregate_Bool_Exp_Sum {
  arguments: SchemaMedicine_Select_Column_SchemaMedicine_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SchemaMedicine_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface SchemaMedicine_Aggregate_Bool_Exp_Var_Samp {
  arguments: SchemaMedicine_Select_Column_SchemaMedicine_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SchemaMedicine_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

/** aggregate fields of "SchemaMedicine" */
export interface SchemaMedicine_Aggregate_Fields {
  __typename?: 'SchemaMedicine_aggregate_fields';
  avg?: Maybe<SchemaMedicine_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<SchemaMedicine_Max_Fields>;
  min?: Maybe<SchemaMedicine_Min_Fields>;
  stddev?: Maybe<SchemaMedicine_Stddev_Fields>;
  stddev_pop?: Maybe<SchemaMedicine_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SchemaMedicine_Stddev_Samp_Fields>;
  sum?: Maybe<SchemaMedicine_Sum_Fields>;
  var_pop?: Maybe<SchemaMedicine_Var_Pop_Fields>;
  var_samp?: Maybe<SchemaMedicine_Var_Samp_Fields>;
  variance?: Maybe<SchemaMedicine_Variance_Fields>;
}


/** aggregate fields of "SchemaMedicine" */
export interface SchemaMedicine_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<SchemaMedicine_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "SchemaMedicine" */
export interface SchemaMedicine_Aggregate_Order_By {
  avg?: InputMaybe<SchemaMedicine_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<SchemaMedicine_Max_Order_By>;
  min?: InputMaybe<SchemaMedicine_Min_Order_By>;
  stddev?: InputMaybe<SchemaMedicine_Stddev_Order_By>;
  stddev_pop?: InputMaybe<SchemaMedicine_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<SchemaMedicine_Stddev_Samp_Order_By>;
  sum?: InputMaybe<SchemaMedicine_Sum_Order_By>;
  var_pop?: InputMaybe<SchemaMedicine_Var_Pop_Order_By>;
  var_samp?: InputMaybe<SchemaMedicine_Var_Samp_Order_By>;
  variance?: InputMaybe<SchemaMedicine_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "SchemaMedicine" */
export interface SchemaMedicine_Arr_Rel_Insert_Input {
  data: Array<SchemaMedicine_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<SchemaMedicine_On_Conflict>;
}

/** aggregate avg on columns */
export interface SchemaMedicine_Avg_Fields {
  __typename?: 'SchemaMedicine_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  schema_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "SchemaMedicine" */
export interface SchemaMedicine_Avg_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  schema_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** Boolean expression to filter rows from the table "SchemaMedicine". All fields are combined with a logical 'AND'. */
export interface SchemaMedicine_Bool_Exp {
  Medicine?: InputMaybe<Medicine_Bool_Exp>;
  Schema?: InputMaybe<Schema_Bool_Exp>;
  _and?: InputMaybe<Array<SchemaMedicine_Bool_Exp>>;
  _not?: InputMaybe<SchemaMedicine_Bool_Exp>;
  _or?: InputMaybe<Array<SchemaMedicine_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  medicine_id?: InputMaybe<Int_Comparison_Exp>;
  schema_id?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<Float8_Comparison_Exp>;
}

/** unique or primary key constraints on table "SchemaMedicine" */
export type SchemaMedicine_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'SchemaMedicine_pkey';

/** input type for incrementing numeric columns in table "SchemaMedicine" */
export interface SchemaMedicine_Inc_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
  schema_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** input type for inserting data into table "SchemaMedicine" */
export interface SchemaMedicine_Insert_Input {
  Medicine?: InputMaybe<Medicine_Obj_Rel_Insert_Input>;
  Schema?: InputMaybe<Schema_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
  schema_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** aggregate max on columns */
export interface SchemaMedicine_Max_Fields {
  __typename?: 'SchemaMedicine_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  medicine_id?: Maybe<Scalars['Int']['output']>;
  schema_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
}

/** order by max() on columns of table "SchemaMedicine" */
export interface SchemaMedicine_Max_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  schema_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface SchemaMedicine_Min_Fields {
  __typename?: 'SchemaMedicine_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  medicine_id?: Maybe<Scalars['Int']['output']>;
  schema_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
}

/** order by min() on columns of table "SchemaMedicine" */
export interface SchemaMedicine_Min_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  schema_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "SchemaMedicine" */
export interface SchemaMedicine_Mutation_Response {
  __typename?: 'SchemaMedicine_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<SchemaMedicine>;
}

/** on_conflict condition type for table "SchemaMedicine" */
export interface SchemaMedicine_On_Conflict {
  constraint: SchemaMedicine_Constraint;
  update_columns?: Array<SchemaMedicine_Update_Column>;
  where?: InputMaybe<SchemaMedicine_Bool_Exp>;
}

/** Ordering options when selecting data from "SchemaMedicine". */
export interface SchemaMedicine_Order_By {
  Medicine?: InputMaybe<Medicine_Order_By>;
  Schema?: InputMaybe<Schema_Order_By>;
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  schema_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** primary key columns input for table: SchemaMedicine */
export interface SchemaMedicine_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "SchemaMedicine" */
export type SchemaMedicine_Select_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'medicine_id'
  /** column name */
  | 'schema_id'
  /** column name */
  | 'value';

/** select "SchemaMedicine_aggregate_bool_exp_avg_arguments_columns" columns of table "SchemaMedicine" */
export type SchemaMedicine_Select_Column_SchemaMedicine_Aggregate_Bool_Exp_Avg_Arguments_Columns =
  /** column name */
  | 'value';

/** select "SchemaMedicine_aggregate_bool_exp_corr_arguments_columns" columns of table "SchemaMedicine" */
export type SchemaMedicine_Select_Column_SchemaMedicine_Aggregate_Bool_Exp_Corr_Arguments_Columns =
  /** column name */
  | 'value';

/** select "SchemaMedicine_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "SchemaMedicine" */
export type SchemaMedicine_Select_Column_SchemaMedicine_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns =
  /** column name */
  | 'value';

/** select "SchemaMedicine_aggregate_bool_exp_max_arguments_columns" columns of table "SchemaMedicine" */
export type SchemaMedicine_Select_Column_SchemaMedicine_Aggregate_Bool_Exp_Max_Arguments_Columns =
  /** column name */
  | 'value';

/** select "SchemaMedicine_aggregate_bool_exp_min_arguments_columns" columns of table "SchemaMedicine" */
export type SchemaMedicine_Select_Column_SchemaMedicine_Aggregate_Bool_Exp_Min_Arguments_Columns =
  /** column name */
  | 'value';

/** select "SchemaMedicine_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "SchemaMedicine" */
export type SchemaMedicine_Select_Column_SchemaMedicine_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns =
  /** column name */
  | 'value';

/** select "SchemaMedicine_aggregate_bool_exp_sum_arguments_columns" columns of table "SchemaMedicine" */
export type SchemaMedicine_Select_Column_SchemaMedicine_Aggregate_Bool_Exp_Sum_Arguments_Columns =
  /** column name */
  | 'value';

/** select "SchemaMedicine_aggregate_bool_exp_var_samp_arguments_columns" columns of table "SchemaMedicine" */
export type SchemaMedicine_Select_Column_SchemaMedicine_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns =
  /** column name */
  | 'value';

/** input type for updating data in table "SchemaMedicine" */
export interface SchemaMedicine_Set_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
  schema_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** aggregate stddev on columns */
export interface SchemaMedicine_Stddev_Fields {
  __typename?: 'SchemaMedicine_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  schema_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "SchemaMedicine" */
export interface SchemaMedicine_Stddev_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  schema_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface SchemaMedicine_Stddev_Pop_Fields {
  __typename?: 'SchemaMedicine_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  schema_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "SchemaMedicine" */
export interface SchemaMedicine_Stddev_Pop_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  schema_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface SchemaMedicine_Stddev_Samp_Fields {
  __typename?: 'SchemaMedicine_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  schema_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "SchemaMedicine" */
export interface SchemaMedicine_Stddev_Samp_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  schema_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** Streaming cursor of the table "SchemaMedicine" */
export interface SchemaMedicine_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: SchemaMedicine_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface SchemaMedicine_Stream_Cursor_Value_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
  schema_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** aggregate sum on columns */
export interface SchemaMedicine_Sum_Fields {
  __typename?: 'SchemaMedicine_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  medicine_id?: Maybe<Scalars['Int']['output']>;
  schema_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
}

/** order by sum() on columns of table "SchemaMedicine" */
export interface SchemaMedicine_Sum_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  schema_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** update columns of table "SchemaMedicine" */
export type SchemaMedicine_Update_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'medicine_id'
  /** column name */
  | 'schema_id'
  /** column name */
  | 'value';

export interface SchemaMedicine_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SchemaMedicine_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SchemaMedicine_Set_Input>;
  where: SchemaMedicine_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface SchemaMedicine_Var_Pop_Fields {
  __typename?: 'SchemaMedicine_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  schema_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "SchemaMedicine" */
export interface SchemaMedicine_Var_Pop_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  schema_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface SchemaMedicine_Var_Samp_Fields {
  __typename?: 'SchemaMedicine_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  schema_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "SchemaMedicine" */
export interface SchemaMedicine_Var_Samp_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  schema_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate variance on columns */
export interface SchemaMedicine_Variance_Fields {
  __typename?: 'SchemaMedicine_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  schema_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "SchemaMedicine" */
export interface SchemaMedicine_Variance_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  schema_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** columns and relationships of "SchemaService" */
export interface SchemaService {
  __typename?: 'SchemaService';
  /** An object relationship */
  Schema: Schema;
  /** An object relationship */
  Service: Service;
  id: Scalars['Int']['output'];
  price?: Maybe<Scalars['float8']['output']>;
  schema_id: Scalars['Int']['output'];
  service_id: Scalars['Int']['output'];
  value: Scalars['float8']['output'];
}

/** aggregated selection of "SchemaService" */
export interface SchemaService_Aggregate {
  __typename?: 'SchemaService_aggregate';
  aggregate?: Maybe<SchemaService_Aggregate_Fields>;
  nodes: Array<SchemaService>;
}

export interface SchemaService_Aggregate_Bool_Exp {
  avg?: InputMaybe<SchemaService_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<SchemaService_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<SchemaService_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<SchemaService_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<SchemaService_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<SchemaService_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<SchemaService_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<SchemaService_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<SchemaService_Aggregate_Bool_Exp_Var_Samp>;
}

export interface SchemaService_Aggregate_Bool_Exp_Avg {
  arguments: SchemaService_Select_Column_SchemaService_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SchemaService_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface SchemaService_Aggregate_Bool_Exp_Corr {
  arguments: SchemaService_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SchemaService_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface SchemaService_Aggregate_Bool_Exp_Corr_Arguments {
  X: SchemaService_Select_Column_SchemaService_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: SchemaService_Select_Column_SchemaService_Aggregate_Bool_Exp_Corr_Arguments_Columns;
}

export interface SchemaService_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<SchemaService_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SchemaService_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

export interface SchemaService_Aggregate_Bool_Exp_Covar_Samp {
  arguments: SchemaService_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SchemaService_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface SchemaService_Aggregate_Bool_Exp_Covar_Samp_Arguments {
  X: SchemaService_Select_Column_SchemaService_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: SchemaService_Select_Column_SchemaService_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
}

export interface SchemaService_Aggregate_Bool_Exp_Max {
  arguments: SchemaService_Select_Column_SchemaService_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SchemaService_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface SchemaService_Aggregate_Bool_Exp_Min {
  arguments: SchemaService_Select_Column_SchemaService_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SchemaService_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface SchemaService_Aggregate_Bool_Exp_Stddev_Samp {
  arguments: SchemaService_Select_Column_SchemaService_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SchemaService_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface SchemaService_Aggregate_Bool_Exp_Sum {
  arguments: SchemaService_Select_Column_SchemaService_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SchemaService_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface SchemaService_Aggregate_Bool_Exp_Var_Samp {
  arguments: SchemaService_Select_Column_SchemaService_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SchemaService_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

/** aggregate fields of "SchemaService" */
export interface SchemaService_Aggregate_Fields {
  __typename?: 'SchemaService_aggregate_fields';
  avg?: Maybe<SchemaService_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<SchemaService_Max_Fields>;
  min?: Maybe<SchemaService_Min_Fields>;
  stddev?: Maybe<SchemaService_Stddev_Fields>;
  stddev_pop?: Maybe<SchemaService_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SchemaService_Stddev_Samp_Fields>;
  sum?: Maybe<SchemaService_Sum_Fields>;
  var_pop?: Maybe<SchemaService_Var_Pop_Fields>;
  var_samp?: Maybe<SchemaService_Var_Samp_Fields>;
  variance?: Maybe<SchemaService_Variance_Fields>;
}


/** aggregate fields of "SchemaService" */
export interface SchemaService_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<SchemaService_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "SchemaService" */
export interface SchemaService_Aggregate_Order_By {
  avg?: InputMaybe<SchemaService_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<SchemaService_Max_Order_By>;
  min?: InputMaybe<SchemaService_Min_Order_By>;
  stddev?: InputMaybe<SchemaService_Stddev_Order_By>;
  stddev_pop?: InputMaybe<SchemaService_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<SchemaService_Stddev_Samp_Order_By>;
  sum?: InputMaybe<SchemaService_Sum_Order_By>;
  var_pop?: InputMaybe<SchemaService_Var_Pop_Order_By>;
  var_samp?: InputMaybe<SchemaService_Var_Samp_Order_By>;
  variance?: InputMaybe<SchemaService_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "SchemaService" */
export interface SchemaService_Arr_Rel_Insert_Input {
  data: Array<SchemaService_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<SchemaService_On_Conflict>;
}

/** aggregate avg on columns */
export interface SchemaService_Avg_Fields {
  __typename?: 'SchemaService_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  schema_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "SchemaService" */
export interface SchemaService_Avg_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  schema_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** Boolean expression to filter rows from the table "SchemaService". All fields are combined with a logical 'AND'. */
export interface SchemaService_Bool_Exp {
  Schema?: InputMaybe<Schema_Bool_Exp>;
  Service?: InputMaybe<Service_Bool_Exp>;
  _and?: InputMaybe<Array<SchemaService_Bool_Exp>>;
  _not?: InputMaybe<SchemaService_Bool_Exp>;
  _or?: InputMaybe<Array<SchemaService_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  price?: InputMaybe<Float8_Comparison_Exp>;
  schema_id?: InputMaybe<Int_Comparison_Exp>;
  service_id?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<Float8_Comparison_Exp>;
}

/** unique or primary key constraints on table "SchemaService" */
export type SchemaService_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'SchemaService_pkey';

/** input type for incrementing numeric columns in table "SchemaService" */
export interface SchemaService_Inc_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  schema_id?: InputMaybe<Scalars['Int']['input']>;
  service_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** input type for inserting data into table "SchemaService" */
export interface SchemaService_Insert_Input {
  Schema?: InputMaybe<Schema_Obj_Rel_Insert_Input>;
  Service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  schema_id?: InputMaybe<Scalars['Int']['input']>;
  service_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** aggregate max on columns */
export interface SchemaService_Max_Fields {
  __typename?: 'SchemaService_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  schema_id?: Maybe<Scalars['Int']['output']>;
  service_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
}

/** order by max() on columns of table "SchemaService" */
export interface SchemaService_Max_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  schema_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface SchemaService_Min_Fields {
  __typename?: 'SchemaService_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  schema_id?: Maybe<Scalars['Int']['output']>;
  service_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
}

/** order by min() on columns of table "SchemaService" */
export interface SchemaService_Min_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  schema_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "SchemaService" */
export interface SchemaService_Mutation_Response {
  __typename?: 'SchemaService_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<SchemaService>;
}

/** on_conflict condition type for table "SchemaService" */
export interface SchemaService_On_Conflict {
  constraint: SchemaService_Constraint;
  update_columns?: Array<SchemaService_Update_Column>;
  where?: InputMaybe<SchemaService_Bool_Exp>;
}

/** Ordering options when selecting data from "SchemaService". */
export interface SchemaService_Order_By {
  Schema?: InputMaybe<Schema_Order_By>;
  Service?: InputMaybe<Service_Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  schema_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** primary key columns input for table: SchemaService */
export interface SchemaService_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "SchemaService" */
export type SchemaService_Select_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'price'
  /** column name */
  | 'schema_id'
  /** column name */
  | 'service_id'
  /** column name */
  | 'value';

/** select "SchemaService_aggregate_bool_exp_avg_arguments_columns" columns of table "SchemaService" */
export type SchemaService_Select_Column_SchemaService_Aggregate_Bool_Exp_Avg_Arguments_Columns =
  /** column name */
  | 'price'
  /** column name */
  | 'value';

/** select "SchemaService_aggregate_bool_exp_corr_arguments_columns" columns of table "SchemaService" */
export type SchemaService_Select_Column_SchemaService_Aggregate_Bool_Exp_Corr_Arguments_Columns =
  /** column name */
  | 'price'
  /** column name */
  | 'value';

/** select "SchemaService_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "SchemaService" */
export type SchemaService_Select_Column_SchemaService_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns =
  /** column name */
  | 'price'
  /** column name */
  | 'value';

/** select "SchemaService_aggregate_bool_exp_max_arguments_columns" columns of table "SchemaService" */
export type SchemaService_Select_Column_SchemaService_Aggregate_Bool_Exp_Max_Arguments_Columns =
  /** column name */
  | 'price'
  /** column name */
  | 'value';

/** select "SchemaService_aggregate_bool_exp_min_arguments_columns" columns of table "SchemaService" */
export type SchemaService_Select_Column_SchemaService_Aggregate_Bool_Exp_Min_Arguments_Columns =
  /** column name */
  | 'price'
  /** column name */
  | 'value';

/** select "SchemaService_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "SchemaService" */
export type SchemaService_Select_Column_SchemaService_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns =
  /** column name */
  | 'price'
  /** column name */
  | 'value';

/** select "SchemaService_aggregate_bool_exp_sum_arguments_columns" columns of table "SchemaService" */
export type SchemaService_Select_Column_SchemaService_Aggregate_Bool_Exp_Sum_Arguments_Columns =
  /** column name */
  | 'price'
  /** column name */
  | 'value';

/** select "SchemaService_aggregate_bool_exp_var_samp_arguments_columns" columns of table "SchemaService" */
export type SchemaService_Select_Column_SchemaService_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns =
  /** column name */
  | 'price'
  /** column name */
  | 'value';

/** input type for updating data in table "SchemaService" */
export interface SchemaService_Set_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  schema_id?: InputMaybe<Scalars['Int']['input']>;
  service_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** aggregate stddev on columns */
export interface SchemaService_Stddev_Fields {
  __typename?: 'SchemaService_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  schema_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "SchemaService" */
export interface SchemaService_Stddev_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  schema_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface SchemaService_Stddev_Pop_Fields {
  __typename?: 'SchemaService_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  schema_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "SchemaService" */
export interface SchemaService_Stddev_Pop_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  schema_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface SchemaService_Stddev_Samp_Fields {
  __typename?: 'SchemaService_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  schema_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "SchemaService" */
export interface SchemaService_Stddev_Samp_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  schema_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** Streaming cursor of the table "SchemaService" */
export interface SchemaService_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: SchemaService_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface SchemaService_Stream_Cursor_Value_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  schema_id?: InputMaybe<Scalars['Int']['input']>;
  service_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** aggregate sum on columns */
export interface SchemaService_Sum_Fields {
  __typename?: 'SchemaService_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  schema_id?: Maybe<Scalars['Int']['output']>;
  service_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
}

/** order by sum() on columns of table "SchemaService" */
export interface SchemaService_Sum_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  schema_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** update columns of table "SchemaService" */
export type SchemaService_Update_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'price'
  /** column name */
  | 'schema_id'
  /** column name */
  | 'service_id'
  /** column name */
  | 'value';

export interface SchemaService_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SchemaService_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SchemaService_Set_Input>;
  where: SchemaService_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface SchemaService_Var_Pop_Fields {
  __typename?: 'SchemaService_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  schema_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "SchemaService" */
export interface SchemaService_Var_Pop_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  schema_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface SchemaService_Var_Samp_Fields {
  __typename?: 'SchemaService_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  schema_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "SchemaService" */
export interface SchemaService_Var_Samp_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  schema_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate variance on columns */
export interface SchemaService_Variance_Fields {
  __typename?: 'SchemaService_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  schema_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "SchemaService" */
export interface SchemaService_Variance_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  schema_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregated selection of "Schema" */
export interface Schema_Aggregate {
  __typename?: 'Schema_aggregate';
  aggregate?: Maybe<Schema_Aggregate_Fields>;
  nodes: Array<Schema>;
}

export interface Schema_Aggregate_Bool_Exp {
  count?: InputMaybe<Schema_Aggregate_Bool_Exp_Count>;
}

export interface Schema_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Schema_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Schema_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "Schema" */
export interface Schema_Aggregate_Fields {
  __typename?: 'Schema_aggregate_fields';
  avg?: Maybe<Schema_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Schema_Max_Fields>;
  min?: Maybe<Schema_Min_Fields>;
  stddev?: Maybe<Schema_Stddev_Fields>;
  stddev_pop?: Maybe<Schema_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Schema_Stddev_Samp_Fields>;
  sum?: Maybe<Schema_Sum_Fields>;
  var_pop?: Maybe<Schema_Var_Pop_Fields>;
  var_samp?: Maybe<Schema_Var_Samp_Fields>;
  variance?: Maybe<Schema_Variance_Fields>;
}


/** aggregate fields of "Schema" */
export interface Schema_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Schema_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "Schema" */
export interface Schema_Aggregate_Order_By {
  avg?: InputMaybe<Schema_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Schema_Max_Order_By>;
  min?: InputMaybe<Schema_Min_Order_By>;
  stddev?: InputMaybe<Schema_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Schema_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Schema_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Schema_Sum_Order_By>;
  var_pop?: InputMaybe<Schema_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Schema_Var_Samp_Order_By>;
  variance?: InputMaybe<Schema_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "Schema" */
export interface Schema_Arr_Rel_Insert_Input {
  data: Array<Schema_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Schema_On_Conflict>;
}

/** aggregate avg on columns */
export interface Schema_Avg_Fields {
  __typename?: 'Schema_avg_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "Schema" */
export interface Schema_Avg_Order_By {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** Boolean expression to filter rows from the table "Schema". All fields are combined with a logical 'AND'. */
export interface Schema_Bool_Exp {
  Category?: InputMaybe<SchemaCategory_Bool_Exp>;
  SchemaMedicines?: InputMaybe<SchemaMedicine_Bool_Exp>;
  SchemaMedicines_aggregate?: InputMaybe<SchemaMedicine_Aggregate_Bool_Exp>;
  SchemaServices?: InputMaybe<SchemaService_Bool_Exp>;
  SchemaServices_aggregate?: InputMaybe<SchemaService_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Schema_Bool_Exp>>;
  _not?: InputMaybe<Schema_Bool_Exp>;
  _or?: InputMaybe<Array<Schema_Bool_Exp>>;
  category_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "Schema" */
export type Schema_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'Schema_pkey';

/** input type for incrementing numeric columns in table "Schema" */
export interface Schema_Inc_Input {
  category_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "Schema" */
export interface Schema_Insert_Input {
  Category?: InputMaybe<SchemaCategory_Obj_Rel_Insert_Input>;
  SchemaMedicines?: InputMaybe<SchemaMedicine_Arr_Rel_Insert_Input>;
  SchemaServices?: InputMaybe<SchemaService_Arr_Rel_Insert_Input>;
  category_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface Schema_Max_Fields {
  __typename?: 'Schema_max_fields';
  category_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

/** order by max() on columns of table "Schema" */
export interface Schema_Max_Order_By {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface Schema_Min_Fields {
  __typename?: 'Schema_min_fields';
  category_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

/** order by min() on columns of table "Schema" */
export interface Schema_Min_Order_By {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "Schema" */
export interface Schema_Mutation_Response {
  __typename?: 'Schema_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Schema>;
}

/** input type for inserting object relation for remote table "Schema" */
export interface Schema_Obj_Rel_Insert_Input {
  data: Schema_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Schema_On_Conflict>;
}

/** on_conflict condition type for table "Schema" */
export interface Schema_On_Conflict {
  constraint: Schema_Constraint;
  update_columns?: Array<Schema_Update_Column>;
  where?: InputMaybe<Schema_Bool_Exp>;
}

/** Ordering options when selecting data from "Schema". */
export interface Schema_Order_By {
  Category?: InputMaybe<SchemaCategory_Order_By>;
  SchemaMedicines_aggregate?: InputMaybe<SchemaMedicine_Aggregate_Order_By>;
  SchemaServices_aggregate?: InputMaybe<SchemaService_Aggregate_Order_By>;
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
}

/** primary key columns input for table: Schema */
export interface Schema_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "Schema" */
export type Schema_Select_Column =
  /** column name */
  | 'category_id'
  /** column name */
  | 'id'
  /** column name */
  | 'name';

/** input type for updating data in table "Schema" */
export interface Schema_Set_Input {
  category_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate stddev on columns */
export interface Schema_Stddev_Fields {
  __typename?: 'Schema_stddev_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "Schema" */
export interface Schema_Stddev_Order_By {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface Schema_Stddev_Pop_Fields {
  __typename?: 'Schema_stddev_pop_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "Schema" */
export interface Schema_Stddev_Pop_Order_By {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface Schema_Stddev_Samp_Fields {
  __typename?: 'Schema_stddev_samp_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "Schema" */
export interface Schema_Stddev_Samp_Order_By {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** Streaming cursor of the table "Schema" */
export interface Schema_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Schema_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Schema_Stream_Cursor_Value_Input {
  category_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate sum on columns */
export interface Schema_Sum_Fields {
  __typename?: 'Schema_sum_fields';
  category_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "Schema" */
export interface Schema_Sum_Order_By {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** update columns of table "Schema" */
export type Schema_Update_Column =
  /** column name */
  | 'category_id'
  /** column name */
  | 'id'
  /** column name */
  | 'name';

export interface Schema_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Schema_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Schema_Set_Input>;
  where: Schema_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Schema_Var_Pop_Fields {
  __typename?: 'Schema_var_pop_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "Schema" */
export interface Schema_Var_Pop_Order_By {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface Schema_Var_Samp_Fields {
  __typename?: 'Schema_var_samp_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "Schema" */
export interface Schema_Var_Samp_Order_By {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** aggregate variance on columns */
export interface Schema_Variance_Fields {
  __typename?: 'Schema_variance_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "Schema" */
export interface Schema_Variance_Order_By {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
}

/** Услуга */
export interface Service {
  __typename?: 'Service';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  price: Scalars['float8']['output'];
  ut_id?: Maybe<Scalars['String']['output']>;
}

/** aggregated selection of "Service" */
export interface Service_Aggregate {
  __typename?: 'Service_aggregate';
  aggregate?: Maybe<Service_Aggregate_Fields>;
  nodes: Array<Service>;
}

/** aggregate fields of "Service" */
export interface Service_Aggregate_Fields {
  __typename?: 'Service_aggregate_fields';
  avg?: Maybe<Service_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Service_Max_Fields>;
  min?: Maybe<Service_Min_Fields>;
  stddev?: Maybe<Service_Stddev_Fields>;
  stddev_pop?: Maybe<Service_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Service_Stddev_Samp_Fields>;
  sum?: Maybe<Service_Sum_Fields>;
  var_pop?: Maybe<Service_Var_Pop_Fields>;
  var_samp?: Maybe<Service_Var_Samp_Fields>;
  variance?: Maybe<Service_Variance_Fields>;
}


/** aggregate fields of "Service" */
export interface Service_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Service_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate avg on columns */
export interface Service_Avg_Fields {
  __typename?: 'Service_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to filter rows from the table "Service". All fields are combined with a logical 'AND'. */
export interface Service_Bool_Exp {
  _and?: InputMaybe<Array<Service_Bool_Exp>>;
  _not?: InputMaybe<Service_Bool_Exp>;
  _or?: InputMaybe<Array<Service_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  price?: InputMaybe<Float8_Comparison_Exp>;
  ut_id?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "Service" */
export type Service_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'Service_pkey';

/** input type for incrementing numeric columns in table "Service" */
export interface Service_Inc_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
}

/** input type for inserting data into table "Service" */
export interface Service_Insert_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  ut_id?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface Service_Max_Fields {
  __typename?: 'Service_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  ut_id?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface Service_Min_Fields {
  __typename?: 'Service_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  ut_id?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "Service" */
export interface Service_Mutation_Response {
  __typename?: 'Service_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Service>;
}

/** input type for inserting object relation for remote table "Service" */
export interface Service_Obj_Rel_Insert_Input {
  data: Service_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Service_On_Conflict>;
}

/** on_conflict condition type for table "Service" */
export interface Service_On_Conflict {
  constraint: Service_Constraint;
  update_columns?: Array<Service_Update_Column>;
  where?: InputMaybe<Service_Bool_Exp>;
}

/** Ordering options when selecting data from "Service". */
export interface Service_Order_By {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  ut_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: Service */
export interface Service_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "Service" */
export type Service_Select_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'price'
  /** column name */
  | 'ut_id';

/** input type for updating data in table "Service" */
export interface Service_Set_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  ut_id?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate stddev on columns */
export interface Service_Stddev_Fields {
  __typename?: 'Service_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_pop on columns */
export interface Service_Stddev_Pop_Fields {
  __typename?: 'Service_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_samp on columns */
export interface Service_Stddev_Samp_Fields {
  __typename?: 'Service_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
}

/** Streaming cursor of the table "Service" */
export interface Service_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Service_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Service_Stream_Cursor_Value_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  ut_id?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate sum on columns */
export interface Service_Sum_Fields {
  __typename?: 'Service_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
}

/** update columns of table "Service" */
export type Service_Update_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'price'
  /** column name */
  | 'ut_id';

export interface Service_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Service_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Service_Set_Input>;
  where: Service_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Service_Var_Pop_Fields {
  __typename?: 'Service_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
}

/** aggregate var_samp on columns */
export interface Service_Var_Samp_Fields {
  __typename?: 'Service_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
}

/** aggregate variance on columns */
export interface Service_Variance_Fields {
  __typename?: 'Service_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export interface String_Comparison_Exp {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
}

/** columns and relationships of "Template" */
export interface Template {
  __typename?: 'Template';
  /** An array relationship */
  TemplateMedicines: Array<TemplateMedicines>;
  /** An aggregate relationship */
  TemplateMedicines_aggregate: TemplateMedicines_Aggregate;
  /** An array relationship */
  TemplateServices: Array<TemplateServices>;
  /** An aggregate relationship */
  TemplateServices_aggregate: TemplateServices_Aggregate;
  /** An object relationship */
  User: Users;
  id: Scalars['Int']['output'];
  internal_recomendation?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  patient_recomendation?: Maybe<Scalars['String']['output']>;
  user_id: Scalars['uuid']['output'];
}


/** columns and relationships of "Template" */
export interface TemplateTemplateMedicinesArgs {
  distinct_on?: InputMaybe<Array<TemplateMedicines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TemplateMedicines_Order_By>>;
  where?: InputMaybe<TemplateMedicines_Bool_Exp>;
}


/** columns and relationships of "Template" */
export interface TemplateTemplateMedicines_AggregateArgs {
  distinct_on?: InputMaybe<Array<TemplateMedicines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TemplateMedicines_Order_By>>;
  where?: InputMaybe<TemplateMedicines_Bool_Exp>;
}


/** columns and relationships of "Template" */
export interface TemplateTemplateServicesArgs {
  distinct_on?: InputMaybe<Array<TemplateServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TemplateServices_Order_By>>;
  where?: InputMaybe<TemplateServices_Bool_Exp>;
}


/** columns and relationships of "Template" */
export interface TemplateTemplateServices_AggregateArgs {
  distinct_on?: InputMaybe<Array<TemplateServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TemplateServices_Order_By>>;
  where?: InputMaybe<TemplateServices_Bool_Exp>;
}

/** columns and relationships of "TemplateMedicines" */
export interface TemplateMedicines {
  __typename?: 'TemplateMedicines';
  /** An object relationship */
  Medicine: Medicine;
  /** An object relationship */
  Template: Template;
  id: Scalars['Int']['output'];
  medicine_id: Scalars['Int']['output'];
  template_id: Scalars['Int']['output'];
  value: Scalars['float8']['output'];
}

/** aggregated selection of "TemplateMedicines" */
export interface TemplateMedicines_Aggregate {
  __typename?: 'TemplateMedicines_aggregate';
  aggregate?: Maybe<TemplateMedicines_Aggregate_Fields>;
  nodes: Array<TemplateMedicines>;
}

export interface TemplateMedicines_Aggregate_Bool_Exp {
  avg?: InputMaybe<TemplateMedicines_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<TemplateMedicines_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<TemplateMedicines_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<TemplateMedicines_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<TemplateMedicines_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<TemplateMedicines_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<TemplateMedicines_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<TemplateMedicines_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<TemplateMedicines_Aggregate_Bool_Exp_Var_Samp>;
}

export interface TemplateMedicines_Aggregate_Bool_Exp_Avg {
  arguments: TemplateMedicines_Select_Column_TemplateMedicines_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TemplateMedicines_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface TemplateMedicines_Aggregate_Bool_Exp_Corr {
  arguments: TemplateMedicines_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TemplateMedicines_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface TemplateMedicines_Aggregate_Bool_Exp_Corr_Arguments {
  X: TemplateMedicines_Select_Column_TemplateMedicines_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: TemplateMedicines_Select_Column_TemplateMedicines_Aggregate_Bool_Exp_Corr_Arguments_Columns;
}

export interface TemplateMedicines_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<TemplateMedicines_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TemplateMedicines_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

export interface TemplateMedicines_Aggregate_Bool_Exp_Covar_Samp {
  arguments: TemplateMedicines_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TemplateMedicines_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface TemplateMedicines_Aggregate_Bool_Exp_Covar_Samp_Arguments {
  X: TemplateMedicines_Select_Column_TemplateMedicines_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: TemplateMedicines_Select_Column_TemplateMedicines_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
}

export interface TemplateMedicines_Aggregate_Bool_Exp_Max {
  arguments: TemplateMedicines_Select_Column_TemplateMedicines_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TemplateMedicines_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface TemplateMedicines_Aggregate_Bool_Exp_Min {
  arguments: TemplateMedicines_Select_Column_TemplateMedicines_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TemplateMedicines_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface TemplateMedicines_Aggregate_Bool_Exp_Stddev_Samp {
  arguments: TemplateMedicines_Select_Column_TemplateMedicines_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TemplateMedicines_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface TemplateMedicines_Aggregate_Bool_Exp_Sum {
  arguments: TemplateMedicines_Select_Column_TemplateMedicines_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TemplateMedicines_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface TemplateMedicines_Aggregate_Bool_Exp_Var_Samp {
  arguments: TemplateMedicines_Select_Column_TemplateMedicines_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TemplateMedicines_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

/** aggregate fields of "TemplateMedicines" */
export interface TemplateMedicines_Aggregate_Fields {
  __typename?: 'TemplateMedicines_aggregate_fields';
  avg?: Maybe<TemplateMedicines_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<TemplateMedicines_Max_Fields>;
  min?: Maybe<TemplateMedicines_Min_Fields>;
  stddev?: Maybe<TemplateMedicines_Stddev_Fields>;
  stddev_pop?: Maybe<TemplateMedicines_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<TemplateMedicines_Stddev_Samp_Fields>;
  sum?: Maybe<TemplateMedicines_Sum_Fields>;
  var_pop?: Maybe<TemplateMedicines_Var_Pop_Fields>;
  var_samp?: Maybe<TemplateMedicines_Var_Samp_Fields>;
  variance?: Maybe<TemplateMedicines_Variance_Fields>;
}


/** aggregate fields of "TemplateMedicines" */
export interface TemplateMedicines_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<TemplateMedicines_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "TemplateMedicines" */
export interface TemplateMedicines_Aggregate_Order_By {
  avg?: InputMaybe<TemplateMedicines_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<TemplateMedicines_Max_Order_By>;
  min?: InputMaybe<TemplateMedicines_Min_Order_By>;
  stddev?: InputMaybe<TemplateMedicines_Stddev_Order_By>;
  stddev_pop?: InputMaybe<TemplateMedicines_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<TemplateMedicines_Stddev_Samp_Order_By>;
  sum?: InputMaybe<TemplateMedicines_Sum_Order_By>;
  var_pop?: InputMaybe<TemplateMedicines_Var_Pop_Order_By>;
  var_samp?: InputMaybe<TemplateMedicines_Var_Samp_Order_By>;
  variance?: InputMaybe<TemplateMedicines_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "TemplateMedicines" */
export interface TemplateMedicines_Arr_Rel_Insert_Input {
  data: Array<TemplateMedicines_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<TemplateMedicines_On_Conflict>;
}

/** aggregate avg on columns */
export interface TemplateMedicines_Avg_Fields {
  __typename?: 'TemplateMedicines_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  template_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "TemplateMedicines" */
export interface TemplateMedicines_Avg_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** Boolean expression to filter rows from the table "TemplateMedicines". All fields are combined with a logical 'AND'. */
export interface TemplateMedicines_Bool_Exp {
  Medicine?: InputMaybe<Medicine_Bool_Exp>;
  Template?: InputMaybe<Template_Bool_Exp>;
  _and?: InputMaybe<Array<TemplateMedicines_Bool_Exp>>;
  _not?: InputMaybe<TemplateMedicines_Bool_Exp>;
  _or?: InputMaybe<Array<TemplateMedicines_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  medicine_id?: InputMaybe<Int_Comparison_Exp>;
  template_id?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<Float8_Comparison_Exp>;
}

/** unique or primary key constraints on table "TemplateMedicines" */
export type TemplateMedicines_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'TemplateMedicines_pkey';

/** input type for incrementing numeric columns in table "TemplateMedicines" */
export interface TemplateMedicines_Inc_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
  template_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** input type for inserting data into table "TemplateMedicines" */
export interface TemplateMedicines_Insert_Input {
  Medicine?: InputMaybe<Medicine_Obj_Rel_Insert_Input>;
  Template?: InputMaybe<Template_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
  template_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** aggregate max on columns */
export interface TemplateMedicines_Max_Fields {
  __typename?: 'TemplateMedicines_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  medicine_id?: Maybe<Scalars['Int']['output']>;
  template_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
}

/** order by max() on columns of table "TemplateMedicines" */
export interface TemplateMedicines_Max_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface TemplateMedicines_Min_Fields {
  __typename?: 'TemplateMedicines_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  medicine_id?: Maybe<Scalars['Int']['output']>;
  template_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
}

/** order by min() on columns of table "TemplateMedicines" */
export interface TemplateMedicines_Min_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "TemplateMedicines" */
export interface TemplateMedicines_Mutation_Response {
  __typename?: 'TemplateMedicines_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TemplateMedicines>;
}

/** on_conflict condition type for table "TemplateMedicines" */
export interface TemplateMedicines_On_Conflict {
  constraint: TemplateMedicines_Constraint;
  update_columns?: Array<TemplateMedicines_Update_Column>;
  where?: InputMaybe<TemplateMedicines_Bool_Exp>;
}

/** Ordering options when selecting data from "TemplateMedicines". */
export interface TemplateMedicines_Order_By {
  Medicine?: InputMaybe<Medicine_Order_By>;
  Template?: InputMaybe<Template_Order_By>;
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** primary key columns input for table: TemplateMedicines */
export interface TemplateMedicines_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "TemplateMedicines" */
export type TemplateMedicines_Select_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'medicine_id'
  /** column name */
  | 'template_id'
  /** column name */
  | 'value';

/** select "TemplateMedicines_aggregate_bool_exp_avg_arguments_columns" columns of table "TemplateMedicines" */
export type TemplateMedicines_Select_Column_TemplateMedicines_Aggregate_Bool_Exp_Avg_Arguments_Columns =
  /** column name */
  | 'value';

/** select "TemplateMedicines_aggregate_bool_exp_corr_arguments_columns" columns of table "TemplateMedicines" */
export type TemplateMedicines_Select_Column_TemplateMedicines_Aggregate_Bool_Exp_Corr_Arguments_Columns =
  /** column name */
  | 'value';

/** select "TemplateMedicines_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "TemplateMedicines" */
export type TemplateMedicines_Select_Column_TemplateMedicines_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns =
  /** column name */
  | 'value';

/** select "TemplateMedicines_aggregate_bool_exp_max_arguments_columns" columns of table "TemplateMedicines" */
export type TemplateMedicines_Select_Column_TemplateMedicines_Aggregate_Bool_Exp_Max_Arguments_Columns =
  /** column name */
  | 'value';

/** select "TemplateMedicines_aggregate_bool_exp_min_arguments_columns" columns of table "TemplateMedicines" */
export type TemplateMedicines_Select_Column_TemplateMedicines_Aggregate_Bool_Exp_Min_Arguments_Columns =
  /** column name */
  | 'value';

/** select "TemplateMedicines_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "TemplateMedicines" */
export type TemplateMedicines_Select_Column_TemplateMedicines_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns =
  /** column name */
  | 'value';

/** select "TemplateMedicines_aggregate_bool_exp_sum_arguments_columns" columns of table "TemplateMedicines" */
export type TemplateMedicines_Select_Column_TemplateMedicines_Aggregate_Bool_Exp_Sum_Arguments_Columns =
  /** column name */
  | 'value';

/** select "TemplateMedicines_aggregate_bool_exp_var_samp_arguments_columns" columns of table "TemplateMedicines" */
export type TemplateMedicines_Select_Column_TemplateMedicines_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns =
  /** column name */
  | 'value';

/** input type for updating data in table "TemplateMedicines" */
export interface TemplateMedicines_Set_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
  template_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** aggregate stddev on columns */
export interface TemplateMedicines_Stddev_Fields {
  __typename?: 'TemplateMedicines_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  template_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "TemplateMedicines" */
export interface TemplateMedicines_Stddev_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface TemplateMedicines_Stddev_Pop_Fields {
  __typename?: 'TemplateMedicines_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  template_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "TemplateMedicines" */
export interface TemplateMedicines_Stddev_Pop_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface TemplateMedicines_Stddev_Samp_Fields {
  __typename?: 'TemplateMedicines_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  template_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "TemplateMedicines" */
export interface TemplateMedicines_Stddev_Samp_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** Streaming cursor of the table "TemplateMedicines" */
export interface TemplateMedicines_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: TemplateMedicines_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface TemplateMedicines_Stream_Cursor_Value_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
  template_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** aggregate sum on columns */
export interface TemplateMedicines_Sum_Fields {
  __typename?: 'TemplateMedicines_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  medicine_id?: Maybe<Scalars['Int']['output']>;
  template_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
}

/** order by sum() on columns of table "TemplateMedicines" */
export interface TemplateMedicines_Sum_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** update columns of table "TemplateMedicines" */
export type TemplateMedicines_Update_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'medicine_id'
  /** column name */
  | 'template_id'
  /** column name */
  | 'value';

export interface TemplateMedicines_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TemplateMedicines_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TemplateMedicines_Set_Input>;
  where: TemplateMedicines_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface TemplateMedicines_Var_Pop_Fields {
  __typename?: 'TemplateMedicines_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  template_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "TemplateMedicines" */
export interface TemplateMedicines_Var_Pop_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface TemplateMedicines_Var_Samp_Fields {
  __typename?: 'TemplateMedicines_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  template_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "TemplateMedicines" */
export interface TemplateMedicines_Var_Samp_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate variance on columns */
export interface TemplateMedicines_Variance_Fields {
  __typename?: 'TemplateMedicines_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  template_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "TemplateMedicines" */
export interface TemplateMedicines_Variance_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** columns and relationships of "TemplateServices" */
export interface TemplateServices {
  __typename?: 'TemplateServices';
  /** An object relationship */
  Service: Service;
  /** An object relationship */
  Template: Template;
  id: Scalars['Int']['output'];
  price?: Maybe<Scalars['float8']['output']>;
  service_id: Scalars['Int']['output'];
  template_id: Scalars['Int']['output'];
  value: Scalars['float8']['output'];
}

/** aggregated selection of "TemplateServices" */
export interface TemplateServices_Aggregate {
  __typename?: 'TemplateServices_aggregate';
  aggregate?: Maybe<TemplateServices_Aggregate_Fields>;
  nodes: Array<TemplateServices>;
}

export interface TemplateServices_Aggregate_Bool_Exp {
  avg?: InputMaybe<TemplateServices_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<TemplateServices_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<TemplateServices_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<TemplateServices_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<TemplateServices_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<TemplateServices_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<TemplateServices_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<TemplateServices_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<TemplateServices_Aggregate_Bool_Exp_Var_Samp>;
}

export interface TemplateServices_Aggregate_Bool_Exp_Avg {
  arguments: TemplateServices_Select_Column_TemplateServices_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TemplateServices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface TemplateServices_Aggregate_Bool_Exp_Corr {
  arguments: TemplateServices_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TemplateServices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface TemplateServices_Aggregate_Bool_Exp_Corr_Arguments {
  X: TemplateServices_Select_Column_TemplateServices_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: TemplateServices_Select_Column_TemplateServices_Aggregate_Bool_Exp_Corr_Arguments_Columns;
}

export interface TemplateServices_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<TemplateServices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TemplateServices_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

export interface TemplateServices_Aggregate_Bool_Exp_Covar_Samp {
  arguments: TemplateServices_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TemplateServices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface TemplateServices_Aggregate_Bool_Exp_Covar_Samp_Arguments {
  X: TemplateServices_Select_Column_TemplateServices_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: TemplateServices_Select_Column_TemplateServices_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
}

export interface TemplateServices_Aggregate_Bool_Exp_Max {
  arguments: TemplateServices_Select_Column_TemplateServices_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TemplateServices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface TemplateServices_Aggregate_Bool_Exp_Min {
  arguments: TemplateServices_Select_Column_TemplateServices_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TemplateServices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface TemplateServices_Aggregate_Bool_Exp_Stddev_Samp {
  arguments: TemplateServices_Select_Column_TemplateServices_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TemplateServices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface TemplateServices_Aggregate_Bool_Exp_Sum {
  arguments: TemplateServices_Select_Column_TemplateServices_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TemplateServices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface TemplateServices_Aggregate_Bool_Exp_Var_Samp {
  arguments: TemplateServices_Select_Column_TemplateServices_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TemplateServices_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

/** aggregate fields of "TemplateServices" */
export interface TemplateServices_Aggregate_Fields {
  __typename?: 'TemplateServices_aggregate_fields';
  avg?: Maybe<TemplateServices_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<TemplateServices_Max_Fields>;
  min?: Maybe<TemplateServices_Min_Fields>;
  stddev?: Maybe<TemplateServices_Stddev_Fields>;
  stddev_pop?: Maybe<TemplateServices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<TemplateServices_Stddev_Samp_Fields>;
  sum?: Maybe<TemplateServices_Sum_Fields>;
  var_pop?: Maybe<TemplateServices_Var_Pop_Fields>;
  var_samp?: Maybe<TemplateServices_Var_Samp_Fields>;
  variance?: Maybe<TemplateServices_Variance_Fields>;
}


/** aggregate fields of "TemplateServices" */
export interface TemplateServices_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<TemplateServices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "TemplateServices" */
export interface TemplateServices_Aggregate_Order_By {
  avg?: InputMaybe<TemplateServices_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<TemplateServices_Max_Order_By>;
  min?: InputMaybe<TemplateServices_Min_Order_By>;
  stddev?: InputMaybe<TemplateServices_Stddev_Order_By>;
  stddev_pop?: InputMaybe<TemplateServices_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<TemplateServices_Stddev_Samp_Order_By>;
  sum?: InputMaybe<TemplateServices_Sum_Order_By>;
  var_pop?: InputMaybe<TemplateServices_Var_Pop_Order_By>;
  var_samp?: InputMaybe<TemplateServices_Var_Samp_Order_By>;
  variance?: InputMaybe<TemplateServices_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "TemplateServices" */
export interface TemplateServices_Arr_Rel_Insert_Input {
  data: Array<TemplateServices_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<TemplateServices_On_Conflict>;
}

/** aggregate avg on columns */
export interface TemplateServices_Avg_Fields {
  __typename?: 'TemplateServices_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  template_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "TemplateServices" */
export interface TemplateServices_Avg_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** Boolean expression to filter rows from the table "TemplateServices". All fields are combined with a logical 'AND'. */
export interface TemplateServices_Bool_Exp {
  Service?: InputMaybe<Service_Bool_Exp>;
  Template?: InputMaybe<Template_Bool_Exp>;
  _and?: InputMaybe<Array<TemplateServices_Bool_Exp>>;
  _not?: InputMaybe<TemplateServices_Bool_Exp>;
  _or?: InputMaybe<Array<TemplateServices_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  price?: InputMaybe<Float8_Comparison_Exp>;
  service_id?: InputMaybe<Int_Comparison_Exp>;
  template_id?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<Float8_Comparison_Exp>;
}

/** unique or primary key constraints on table "TemplateServices" */
export type TemplateServices_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'TemplateServices_pkey';

/** input type for incrementing numeric columns in table "TemplateServices" */
export interface TemplateServices_Inc_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  service_id?: InputMaybe<Scalars['Int']['input']>;
  template_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** input type for inserting data into table "TemplateServices" */
export interface TemplateServices_Insert_Input {
  Service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  Template?: InputMaybe<Template_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  service_id?: InputMaybe<Scalars['Int']['input']>;
  template_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** aggregate max on columns */
export interface TemplateServices_Max_Fields {
  __typename?: 'TemplateServices_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  service_id?: Maybe<Scalars['Int']['output']>;
  template_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
}

/** order by max() on columns of table "TemplateServices" */
export interface TemplateServices_Max_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface TemplateServices_Min_Fields {
  __typename?: 'TemplateServices_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  service_id?: Maybe<Scalars['Int']['output']>;
  template_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
}

/** order by min() on columns of table "TemplateServices" */
export interface TemplateServices_Min_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "TemplateServices" */
export interface TemplateServices_Mutation_Response {
  __typename?: 'TemplateServices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TemplateServices>;
}

/** on_conflict condition type for table "TemplateServices" */
export interface TemplateServices_On_Conflict {
  constraint: TemplateServices_Constraint;
  update_columns?: Array<TemplateServices_Update_Column>;
  where?: InputMaybe<TemplateServices_Bool_Exp>;
}

/** Ordering options when selecting data from "TemplateServices". */
export interface TemplateServices_Order_By {
  Service?: InputMaybe<Service_Order_By>;
  Template?: InputMaybe<Template_Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** primary key columns input for table: TemplateServices */
export interface TemplateServices_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "TemplateServices" */
export type TemplateServices_Select_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'price'
  /** column name */
  | 'service_id'
  /** column name */
  | 'template_id'
  /** column name */
  | 'value';

/** select "TemplateServices_aggregate_bool_exp_avg_arguments_columns" columns of table "TemplateServices" */
export type TemplateServices_Select_Column_TemplateServices_Aggregate_Bool_Exp_Avg_Arguments_Columns =
  /** column name */
  | 'price'
  /** column name */
  | 'value';

/** select "TemplateServices_aggregate_bool_exp_corr_arguments_columns" columns of table "TemplateServices" */
export type TemplateServices_Select_Column_TemplateServices_Aggregate_Bool_Exp_Corr_Arguments_Columns =
  /** column name */
  | 'price'
  /** column name */
  | 'value';

/** select "TemplateServices_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "TemplateServices" */
export type TemplateServices_Select_Column_TemplateServices_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns =
  /** column name */
  | 'price'
  /** column name */
  | 'value';

/** select "TemplateServices_aggregate_bool_exp_max_arguments_columns" columns of table "TemplateServices" */
export type TemplateServices_Select_Column_TemplateServices_Aggregate_Bool_Exp_Max_Arguments_Columns =
  /** column name */
  | 'price'
  /** column name */
  | 'value';

/** select "TemplateServices_aggregate_bool_exp_min_arguments_columns" columns of table "TemplateServices" */
export type TemplateServices_Select_Column_TemplateServices_Aggregate_Bool_Exp_Min_Arguments_Columns =
  /** column name */
  | 'price'
  /** column name */
  | 'value';

/** select "TemplateServices_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "TemplateServices" */
export type TemplateServices_Select_Column_TemplateServices_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns =
  /** column name */
  | 'price'
  /** column name */
  | 'value';

/** select "TemplateServices_aggregate_bool_exp_sum_arguments_columns" columns of table "TemplateServices" */
export type TemplateServices_Select_Column_TemplateServices_Aggregate_Bool_Exp_Sum_Arguments_Columns =
  /** column name */
  | 'price'
  /** column name */
  | 'value';

/** select "TemplateServices_aggregate_bool_exp_var_samp_arguments_columns" columns of table "TemplateServices" */
export type TemplateServices_Select_Column_TemplateServices_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns =
  /** column name */
  | 'price'
  /** column name */
  | 'value';

/** input type for updating data in table "TemplateServices" */
export interface TemplateServices_Set_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  service_id?: InputMaybe<Scalars['Int']['input']>;
  template_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** aggregate stddev on columns */
export interface TemplateServices_Stddev_Fields {
  __typename?: 'TemplateServices_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  template_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "TemplateServices" */
export interface TemplateServices_Stddev_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface TemplateServices_Stddev_Pop_Fields {
  __typename?: 'TemplateServices_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  template_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "TemplateServices" */
export interface TemplateServices_Stddev_Pop_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface TemplateServices_Stddev_Samp_Fields {
  __typename?: 'TemplateServices_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  template_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "TemplateServices" */
export interface TemplateServices_Stddev_Samp_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** Streaming cursor of the table "TemplateServices" */
export interface TemplateServices_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: TemplateServices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface TemplateServices_Stream_Cursor_Value_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  service_id?: InputMaybe<Scalars['Int']['input']>;
  template_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** aggregate sum on columns */
export interface TemplateServices_Sum_Fields {
  __typename?: 'TemplateServices_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  service_id?: Maybe<Scalars['Int']['output']>;
  template_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
}

/** order by sum() on columns of table "TemplateServices" */
export interface TemplateServices_Sum_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** update columns of table "TemplateServices" */
export type TemplateServices_Update_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'price'
  /** column name */
  | 'service_id'
  /** column name */
  | 'template_id'
  /** column name */
  | 'value';

export interface TemplateServices_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TemplateServices_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TemplateServices_Set_Input>;
  where: TemplateServices_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface TemplateServices_Var_Pop_Fields {
  __typename?: 'TemplateServices_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  template_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "TemplateServices" */
export interface TemplateServices_Var_Pop_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface TemplateServices_Var_Samp_Fields {
  __typename?: 'TemplateServices_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  template_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "TemplateServices" */
export interface TemplateServices_Var_Samp_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate variance on columns */
export interface TemplateServices_Variance_Fields {
  __typename?: 'TemplateServices_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  template_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "TemplateServices" */
export interface TemplateServices_Variance_Order_By {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregated selection of "Template" */
export interface Template_Aggregate {
  __typename?: 'Template_aggregate';
  aggregate?: Maybe<Template_Aggregate_Fields>;
  nodes: Array<Template>;
}

/** aggregate fields of "Template" */
export interface Template_Aggregate_Fields {
  __typename?: 'Template_aggregate_fields';
  avg?: Maybe<Template_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Template_Max_Fields>;
  min?: Maybe<Template_Min_Fields>;
  stddev?: Maybe<Template_Stddev_Fields>;
  stddev_pop?: Maybe<Template_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Template_Stddev_Samp_Fields>;
  sum?: Maybe<Template_Sum_Fields>;
  var_pop?: Maybe<Template_Var_Pop_Fields>;
  var_samp?: Maybe<Template_Var_Samp_Fields>;
  variance?: Maybe<Template_Variance_Fields>;
}


/** aggregate fields of "Template" */
export interface Template_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Template_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate avg on columns */
export interface Template_Avg_Fields {
  __typename?: 'Template_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to filter rows from the table "Template". All fields are combined with a logical 'AND'. */
export interface Template_Bool_Exp {
  TemplateMedicines?: InputMaybe<TemplateMedicines_Bool_Exp>;
  TemplateMedicines_aggregate?: InputMaybe<TemplateMedicines_Aggregate_Bool_Exp>;
  TemplateServices?: InputMaybe<TemplateServices_Bool_Exp>;
  TemplateServices_aggregate?: InputMaybe<TemplateServices_Aggregate_Bool_Exp>;
  User?: InputMaybe<Users_Bool_Exp>;
  _and?: InputMaybe<Array<Template_Bool_Exp>>;
  _not?: InputMaybe<Template_Bool_Exp>;
  _or?: InputMaybe<Array<Template_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  internal_recomendation?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  patient_recomendation?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "Template" */
export type Template_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'Template_pkey';

/** input type for incrementing numeric columns in table "Template" */
export interface Template_Inc_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "Template" */
export interface Template_Insert_Input {
  TemplateMedicines?: InputMaybe<TemplateMedicines_Arr_Rel_Insert_Input>;
  TemplateServices?: InputMaybe<TemplateServices_Arr_Rel_Insert_Input>;
  User?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  internal_recomendation?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  patient_recomendation?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface Template_Max_Fields {
  __typename?: 'Template_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  internal_recomendation?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  patient_recomendation?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** aggregate min on columns */
export interface Template_Min_Fields {
  __typename?: 'Template_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  internal_recomendation?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  patient_recomendation?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** response of any mutation on the table "Template" */
export interface Template_Mutation_Response {
  __typename?: 'Template_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Template>;
}

/** input type for inserting object relation for remote table "Template" */
export interface Template_Obj_Rel_Insert_Input {
  data: Template_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Template_On_Conflict>;
}

/** on_conflict condition type for table "Template" */
export interface Template_On_Conflict {
  constraint: Template_Constraint;
  update_columns?: Array<Template_Update_Column>;
  where?: InputMaybe<Template_Bool_Exp>;
}

/** Ordering options when selecting data from "Template". */
export interface Template_Order_By {
  TemplateMedicines_aggregate?: InputMaybe<TemplateMedicines_Aggregate_Order_By>;
  TemplateServices_aggregate?: InputMaybe<TemplateServices_Aggregate_Order_By>;
  User?: InputMaybe<Users_Order_By>;
  id?: InputMaybe<Order_By>;
  internal_recomendation?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  patient_recomendation?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: Template */
export interface Template_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "Template" */
export type Template_Select_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'internal_recomendation'
  /** column name */
  | 'name'
  /** column name */
  | 'patient_recomendation'
  /** column name */
  | 'user_id';

/** input type for updating data in table "Template" */
export interface Template_Set_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  internal_recomendation?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  patient_recomendation?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate stddev on columns */
export interface Template_Stddev_Fields {
  __typename?: 'Template_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_pop on columns */
export interface Template_Stddev_Pop_Fields {
  __typename?: 'Template_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_samp on columns */
export interface Template_Stddev_Samp_Fields {
  __typename?: 'Template_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** Streaming cursor of the table "Template" */
export interface Template_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Template_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Template_Stream_Cursor_Value_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  internal_recomendation?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  patient_recomendation?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate sum on columns */
export interface Template_Sum_Fields {
  __typename?: 'Template_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
}

/** update columns of table "Template" */
export type Template_Update_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'internal_recomendation'
  /** column name */
  | 'name'
  /** column name */
  | 'patient_recomendation'
  /** column name */
  | 'user_id';

export interface Template_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Template_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Template_Set_Input>;
  where: Template_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Template_Var_Pop_Fields {
  __typename?: 'Template_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate var_samp on columns */
export interface Template_Var_Samp_Fields {
  __typename?: 'Template_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate variance on columns */
export interface Template_Variance_Fields {
  __typename?: 'Template_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** columns and relationships of "UserMedicinePayments" */
export interface UserMedicinePayments {
  __typename?: 'UserMedicinePayments';
  /** An object relationship */
  Medicine: Medicine;
  /** An object relationship */
  User: Users;
  id: Scalars['Int']['output'];
  in_percent: Scalars['Boolean']['output'];
  medicine_id: Scalars['Int']['output'];
  user_id: Scalars['uuid']['output'];
  value: Scalars['float8']['output'];
}

/** aggregated selection of "UserMedicinePayments" */
export interface UserMedicinePayments_Aggregate {
  __typename?: 'UserMedicinePayments_aggregate';
  aggregate?: Maybe<UserMedicinePayments_Aggregate_Fields>;
  nodes: Array<UserMedicinePayments>;
}

export interface UserMedicinePayments_Aggregate_Bool_Exp {
  avg?: InputMaybe<UserMedicinePayments_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<UserMedicinePayments_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<UserMedicinePayments_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<UserMedicinePayments_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<UserMedicinePayments_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<UserMedicinePayments_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<UserMedicinePayments_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<UserMedicinePayments_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<UserMedicinePayments_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<UserMedicinePayments_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<UserMedicinePayments_Aggregate_Bool_Exp_Var_Samp>;
}

export interface UserMedicinePayments_Aggregate_Bool_Exp_Avg {
  arguments: UserMedicinePayments_Select_Column_UserMedicinePayments_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserMedicinePayments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface UserMedicinePayments_Aggregate_Bool_Exp_Bool_And {
  arguments: UserMedicinePayments_Select_Column_UserMedicinePayments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserMedicinePayments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface UserMedicinePayments_Aggregate_Bool_Exp_Bool_Or {
  arguments: UserMedicinePayments_Select_Column_UserMedicinePayments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserMedicinePayments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface UserMedicinePayments_Aggregate_Bool_Exp_Corr {
  arguments: UserMedicinePayments_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserMedicinePayments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface UserMedicinePayments_Aggregate_Bool_Exp_Corr_Arguments {
  X: UserMedicinePayments_Select_Column_UserMedicinePayments_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: UserMedicinePayments_Select_Column_UserMedicinePayments_Aggregate_Bool_Exp_Corr_Arguments_Columns;
}

export interface UserMedicinePayments_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<UserMedicinePayments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserMedicinePayments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

export interface UserMedicinePayments_Aggregate_Bool_Exp_Covar_Samp {
  arguments: UserMedicinePayments_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserMedicinePayments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface UserMedicinePayments_Aggregate_Bool_Exp_Covar_Samp_Arguments {
  X: UserMedicinePayments_Select_Column_UserMedicinePayments_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: UserMedicinePayments_Select_Column_UserMedicinePayments_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
}

export interface UserMedicinePayments_Aggregate_Bool_Exp_Max {
  arguments: UserMedicinePayments_Select_Column_UserMedicinePayments_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserMedicinePayments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface UserMedicinePayments_Aggregate_Bool_Exp_Min {
  arguments: UserMedicinePayments_Select_Column_UserMedicinePayments_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserMedicinePayments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface UserMedicinePayments_Aggregate_Bool_Exp_Stddev_Samp {
  arguments: UserMedicinePayments_Select_Column_UserMedicinePayments_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserMedicinePayments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface UserMedicinePayments_Aggregate_Bool_Exp_Sum {
  arguments: UserMedicinePayments_Select_Column_UserMedicinePayments_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserMedicinePayments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface UserMedicinePayments_Aggregate_Bool_Exp_Var_Samp {
  arguments: UserMedicinePayments_Select_Column_UserMedicinePayments_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserMedicinePayments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

/** aggregate fields of "UserMedicinePayments" */
export interface UserMedicinePayments_Aggregate_Fields {
  __typename?: 'UserMedicinePayments_aggregate_fields';
  avg?: Maybe<UserMedicinePayments_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<UserMedicinePayments_Max_Fields>;
  min?: Maybe<UserMedicinePayments_Min_Fields>;
  stddev?: Maybe<UserMedicinePayments_Stddev_Fields>;
  stddev_pop?: Maybe<UserMedicinePayments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserMedicinePayments_Stddev_Samp_Fields>;
  sum?: Maybe<UserMedicinePayments_Sum_Fields>;
  var_pop?: Maybe<UserMedicinePayments_Var_Pop_Fields>;
  var_samp?: Maybe<UserMedicinePayments_Var_Samp_Fields>;
  variance?: Maybe<UserMedicinePayments_Variance_Fields>;
}


/** aggregate fields of "UserMedicinePayments" */
export interface UserMedicinePayments_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<UserMedicinePayments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "UserMedicinePayments" */
export interface UserMedicinePayments_Aggregate_Order_By {
  avg?: InputMaybe<UserMedicinePayments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<UserMedicinePayments_Max_Order_By>;
  min?: InputMaybe<UserMedicinePayments_Min_Order_By>;
  stddev?: InputMaybe<UserMedicinePayments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<UserMedicinePayments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<UserMedicinePayments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<UserMedicinePayments_Sum_Order_By>;
  var_pop?: InputMaybe<UserMedicinePayments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<UserMedicinePayments_Var_Samp_Order_By>;
  variance?: InputMaybe<UserMedicinePayments_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "UserMedicinePayments" */
export interface UserMedicinePayments_Arr_Rel_Insert_Input {
  data: Array<UserMedicinePayments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserMedicinePayments_On_Conflict>;
}

/** aggregate avg on columns */
export interface UserMedicinePayments_Avg_Fields {
  __typename?: 'UserMedicinePayments_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "UserMedicinePayments" */
export interface UserMedicinePayments_Avg_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** Boolean expression to filter rows from the table "UserMedicinePayments". All fields are combined with a logical 'AND'. */
export interface UserMedicinePayments_Bool_Exp {
  Medicine?: InputMaybe<Medicine_Bool_Exp>;
  User?: InputMaybe<Users_Bool_Exp>;
  _and?: InputMaybe<Array<UserMedicinePayments_Bool_Exp>>;
  _not?: InputMaybe<UserMedicinePayments_Bool_Exp>;
  _or?: InputMaybe<Array<UserMedicinePayments_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  in_percent?: InputMaybe<Boolean_Comparison_Exp>;
  medicine_id?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<Float8_Comparison_Exp>;
}

/** unique or primary key constraints on table "UserMedicinePayments" */
export type UserMedicinePayments_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'UserMedicinePayments_pkey';

/** input type for incrementing numeric columns in table "UserMedicinePayments" */
export interface UserMedicinePayments_Inc_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** input type for inserting data into table "UserMedicinePayments" */
export interface UserMedicinePayments_Insert_Input {
  Medicine?: InputMaybe<Medicine_Obj_Rel_Insert_Input>;
  User?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  in_percent?: InputMaybe<Scalars['Boolean']['input']>;
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** aggregate max on columns */
export interface UserMedicinePayments_Max_Fields {
  __typename?: 'UserMedicinePayments_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  medicine_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
}

/** order by max() on columns of table "UserMedicinePayments" */
export interface UserMedicinePayments_Max_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface UserMedicinePayments_Min_Fields {
  __typename?: 'UserMedicinePayments_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  medicine_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
}

/** order by min() on columns of table "UserMedicinePayments" */
export interface UserMedicinePayments_Min_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "UserMedicinePayments" */
export interface UserMedicinePayments_Mutation_Response {
  __typename?: 'UserMedicinePayments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserMedicinePayments>;
}

/** on_conflict condition type for table "UserMedicinePayments" */
export interface UserMedicinePayments_On_Conflict {
  constraint: UserMedicinePayments_Constraint;
  update_columns?: Array<UserMedicinePayments_Update_Column>;
  where?: InputMaybe<UserMedicinePayments_Bool_Exp>;
}

/** Ordering options when selecting data from "UserMedicinePayments". */
export interface UserMedicinePayments_Order_By {
  Medicine?: InputMaybe<Medicine_Order_By>;
  User?: InputMaybe<Users_Order_By>;
  id?: InputMaybe<Order_By>;
  in_percent?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** primary key columns input for table: UserMedicinePayments */
export interface UserMedicinePayments_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "UserMedicinePayments" */
export type UserMedicinePayments_Select_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'in_percent'
  /** column name */
  | 'medicine_id'
  /** column name */
  | 'user_id'
  /** column name */
  | 'value';

/** select "UserMedicinePayments_aggregate_bool_exp_avg_arguments_columns" columns of table "UserMedicinePayments" */
export type UserMedicinePayments_Select_Column_UserMedicinePayments_Aggregate_Bool_Exp_Avg_Arguments_Columns =
  /** column name */
  | 'value';

/** select "UserMedicinePayments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "UserMedicinePayments" */
export type UserMedicinePayments_Select_Column_UserMedicinePayments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  | 'in_percent';

/** select "UserMedicinePayments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "UserMedicinePayments" */
export type UserMedicinePayments_Select_Column_UserMedicinePayments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  | 'in_percent';

/** select "UserMedicinePayments_aggregate_bool_exp_corr_arguments_columns" columns of table "UserMedicinePayments" */
export type UserMedicinePayments_Select_Column_UserMedicinePayments_Aggregate_Bool_Exp_Corr_Arguments_Columns =
  /** column name */
  | 'value';

/** select "UserMedicinePayments_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "UserMedicinePayments" */
export type UserMedicinePayments_Select_Column_UserMedicinePayments_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns =
  /** column name */
  | 'value';

/** select "UserMedicinePayments_aggregate_bool_exp_max_arguments_columns" columns of table "UserMedicinePayments" */
export type UserMedicinePayments_Select_Column_UserMedicinePayments_Aggregate_Bool_Exp_Max_Arguments_Columns =
  /** column name */
  | 'value';

/** select "UserMedicinePayments_aggregate_bool_exp_min_arguments_columns" columns of table "UserMedicinePayments" */
export type UserMedicinePayments_Select_Column_UserMedicinePayments_Aggregate_Bool_Exp_Min_Arguments_Columns =
  /** column name */
  | 'value';

/** select "UserMedicinePayments_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "UserMedicinePayments" */
export type UserMedicinePayments_Select_Column_UserMedicinePayments_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns =
  /** column name */
  | 'value';

/** select "UserMedicinePayments_aggregate_bool_exp_sum_arguments_columns" columns of table "UserMedicinePayments" */
export type UserMedicinePayments_Select_Column_UserMedicinePayments_Aggregate_Bool_Exp_Sum_Arguments_Columns =
  /** column name */
  | 'value';

/** select "UserMedicinePayments_aggregate_bool_exp_var_samp_arguments_columns" columns of table "UserMedicinePayments" */
export type UserMedicinePayments_Select_Column_UserMedicinePayments_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns =
  /** column name */
  | 'value';

/** input type for updating data in table "UserMedicinePayments" */
export interface UserMedicinePayments_Set_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  in_percent?: InputMaybe<Scalars['Boolean']['input']>;
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** aggregate stddev on columns */
export interface UserMedicinePayments_Stddev_Fields {
  __typename?: 'UserMedicinePayments_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "UserMedicinePayments" */
export interface UserMedicinePayments_Stddev_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface UserMedicinePayments_Stddev_Pop_Fields {
  __typename?: 'UserMedicinePayments_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "UserMedicinePayments" */
export interface UserMedicinePayments_Stddev_Pop_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface UserMedicinePayments_Stddev_Samp_Fields {
  __typename?: 'UserMedicinePayments_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "UserMedicinePayments" */
export interface UserMedicinePayments_Stddev_Samp_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** Streaming cursor of the table "UserMedicinePayments" */
export interface UserMedicinePayments_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: UserMedicinePayments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface UserMedicinePayments_Stream_Cursor_Value_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  in_percent?: InputMaybe<Scalars['Boolean']['input']>;
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** aggregate sum on columns */
export interface UserMedicinePayments_Sum_Fields {
  __typename?: 'UserMedicinePayments_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  medicine_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
}

/** order by sum() on columns of table "UserMedicinePayments" */
export interface UserMedicinePayments_Sum_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** update columns of table "UserMedicinePayments" */
export type UserMedicinePayments_Update_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'in_percent'
  /** column name */
  | 'medicine_id'
  /** column name */
  | 'user_id'
  /** column name */
  | 'value';

export interface UserMedicinePayments_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserMedicinePayments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserMedicinePayments_Set_Input>;
  where: UserMedicinePayments_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface UserMedicinePayments_Var_Pop_Fields {
  __typename?: 'UserMedicinePayments_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "UserMedicinePayments" */
export interface UserMedicinePayments_Var_Pop_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface UserMedicinePayments_Var_Samp_Fields {
  __typename?: 'UserMedicinePayments_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "UserMedicinePayments" */
export interface UserMedicinePayments_Var_Samp_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate variance on columns */
export interface UserMedicinePayments_Variance_Fields {
  __typename?: 'UserMedicinePayments_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medicine_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "UserMedicinePayments" */
export interface UserMedicinePayments_Variance_Order_By {
  id?: InputMaybe<Order_By>;
  medicine_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** columns and relationships of "UserServicePayments" */
export interface UserServicePayments {
  __typename?: 'UserServicePayments';
  /** An object relationship */
  Service: Service;
  /** An object relationship */
  User: Users;
  id: Scalars['Int']['output'];
  in_percent: Scalars['Boolean']['output'];
  service_id: Scalars['Int']['output'];
  user_id: Scalars['uuid']['output'];
  value: Scalars['float8']['output'];
}

/** aggregated selection of "UserServicePayments" */
export interface UserServicePayments_Aggregate {
  __typename?: 'UserServicePayments_aggregate';
  aggregate?: Maybe<UserServicePayments_Aggregate_Fields>;
  nodes: Array<UserServicePayments>;
}

export interface UserServicePayments_Aggregate_Bool_Exp {
  avg?: InputMaybe<UserServicePayments_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<UserServicePayments_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<UserServicePayments_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<UserServicePayments_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<UserServicePayments_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<UserServicePayments_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<UserServicePayments_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<UserServicePayments_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<UserServicePayments_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<UserServicePayments_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<UserServicePayments_Aggregate_Bool_Exp_Var_Samp>;
}

export interface UserServicePayments_Aggregate_Bool_Exp_Avg {
  arguments: UserServicePayments_Select_Column_UserServicePayments_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserServicePayments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface UserServicePayments_Aggregate_Bool_Exp_Bool_And {
  arguments: UserServicePayments_Select_Column_UserServicePayments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserServicePayments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface UserServicePayments_Aggregate_Bool_Exp_Bool_Or {
  arguments: UserServicePayments_Select_Column_UserServicePayments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserServicePayments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface UserServicePayments_Aggregate_Bool_Exp_Corr {
  arguments: UserServicePayments_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserServicePayments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface UserServicePayments_Aggregate_Bool_Exp_Corr_Arguments {
  X: UserServicePayments_Select_Column_UserServicePayments_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: UserServicePayments_Select_Column_UserServicePayments_Aggregate_Bool_Exp_Corr_Arguments_Columns;
}

export interface UserServicePayments_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<UserServicePayments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserServicePayments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

export interface UserServicePayments_Aggregate_Bool_Exp_Covar_Samp {
  arguments: UserServicePayments_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserServicePayments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface UserServicePayments_Aggregate_Bool_Exp_Covar_Samp_Arguments {
  X: UserServicePayments_Select_Column_UserServicePayments_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: UserServicePayments_Select_Column_UserServicePayments_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
}

export interface UserServicePayments_Aggregate_Bool_Exp_Max {
  arguments: UserServicePayments_Select_Column_UserServicePayments_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserServicePayments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface UserServicePayments_Aggregate_Bool_Exp_Min {
  arguments: UserServicePayments_Select_Column_UserServicePayments_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserServicePayments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface UserServicePayments_Aggregate_Bool_Exp_Stddev_Samp {
  arguments: UserServicePayments_Select_Column_UserServicePayments_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserServicePayments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface UserServicePayments_Aggregate_Bool_Exp_Sum {
  arguments: UserServicePayments_Select_Column_UserServicePayments_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserServicePayments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

export interface UserServicePayments_Aggregate_Bool_Exp_Var_Samp {
  arguments: UserServicePayments_Select_Column_UserServicePayments_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserServicePayments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
}

/** aggregate fields of "UserServicePayments" */
export interface UserServicePayments_Aggregate_Fields {
  __typename?: 'UserServicePayments_aggregate_fields';
  avg?: Maybe<UserServicePayments_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<UserServicePayments_Max_Fields>;
  min?: Maybe<UserServicePayments_Min_Fields>;
  stddev?: Maybe<UserServicePayments_Stddev_Fields>;
  stddev_pop?: Maybe<UserServicePayments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserServicePayments_Stddev_Samp_Fields>;
  sum?: Maybe<UserServicePayments_Sum_Fields>;
  var_pop?: Maybe<UserServicePayments_Var_Pop_Fields>;
  var_samp?: Maybe<UserServicePayments_Var_Samp_Fields>;
  variance?: Maybe<UserServicePayments_Variance_Fields>;
}


/** aggregate fields of "UserServicePayments" */
export interface UserServicePayments_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<UserServicePayments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "UserServicePayments" */
export interface UserServicePayments_Aggregate_Order_By {
  avg?: InputMaybe<UserServicePayments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<UserServicePayments_Max_Order_By>;
  min?: InputMaybe<UserServicePayments_Min_Order_By>;
  stddev?: InputMaybe<UserServicePayments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<UserServicePayments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<UserServicePayments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<UserServicePayments_Sum_Order_By>;
  var_pop?: InputMaybe<UserServicePayments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<UserServicePayments_Var_Samp_Order_By>;
  variance?: InputMaybe<UserServicePayments_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "UserServicePayments" */
export interface UserServicePayments_Arr_Rel_Insert_Input {
  data: Array<UserServicePayments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserServicePayments_On_Conflict>;
}

/** aggregate avg on columns */
export interface UserServicePayments_Avg_Fields {
  __typename?: 'UserServicePayments_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "UserServicePayments" */
export interface UserServicePayments_Avg_Order_By {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** Boolean expression to filter rows from the table "UserServicePayments". All fields are combined with a logical 'AND'. */
export interface UserServicePayments_Bool_Exp {
  Service?: InputMaybe<Service_Bool_Exp>;
  User?: InputMaybe<Users_Bool_Exp>;
  _and?: InputMaybe<Array<UserServicePayments_Bool_Exp>>;
  _not?: InputMaybe<UserServicePayments_Bool_Exp>;
  _or?: InputMaybe<Array<UserServicePayments_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  in_percent?: InputMaybe<Boolean_Comparison_Exp>;
  service_id?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<Float8_Comparison_Exp>;
}

/** unique or primary key constraints on table "UserServicePayments" */
export type UserServicePayments_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'UserServicePayments_pkey';

/** input type for incrementing numeric columns in table "UserServicePayments" */
export interface UserServicePayments_Inc_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  service_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** input type for inserting data into table "UserServicePayments" */
export interface UserServicePayments_Insert_Input {
  Service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  User?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  in_percent?: InputMaybe<Scalars['Boolean']['input']>;
  service_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** aggregate max on columns */
export interface UserServicePayments_Max_Fields {
  __typename?: 'UserServicePayments_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  service_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
}

/** order by max() on columns of table "UserServicePayments" */
export interface UserServicePayments_Max_Order_By {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface UserServicePayments_Min_Fields {
  __typename?: 'UserServicePayments_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  service_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
}

/** order by min() on columns of table "UserServicePayments" */
export interface UserServicePayments_Min_Order_By {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "UserServicePayments" */
export interface UserServicePayments_Mutation_Response {
  __typename?: 'UserServicePayments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserServicePayments>;
}

/** on_conflict condition type for table "UserServicePayments" */
export interface UserServicePayments_On_Conflict {
  constraint: UserServicePayments_Constraint;
  update_columns?: Array<UserServicePayments_Update_Column>;
  where?: InputMaybe<UserServicePayments_Bool_Exp>;
}

/** Ordering options when selecting data from "UserServicePayments". */
export interface UserServicePayments_Order_By {
  Service?: InputMaybe<Service_Order_By>;
  User?: InputMaybe<Users_Order_By>;
  id?: InputMaybe<Order_By>;
  in_percent?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** primary key columns input for table: UserServicePayments */
export interface UserServicePayments_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "UserServicePayments" */
export type UserServicePayments_Select_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'in_percent'
  /** column name */
  | 'service_id'
  /** column name */
  | 'user_id'
  /** column name */
  | 'value';

/** select "UserServicePayments_aggregate_bool_exp_avg_arguments_columns" columns of table "UserServicePayments" */
export type UserServicePayments_Select_Column_UserServicePayments_Aggregate_Bool_Exp_Avg_Arguments_Columns =
  /** column name */
  | 'value';

/** select "UserServicePayments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "UserServicePayments" */
export type UserServicePayments_Select_Column_UserServicePayments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  | 'in_percent';

/** select "UserServicePayments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "UserServicePayments" */
export type UserServicePayments_Select_Column_UserServicePayments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  | 'in_percent';

/** select "UserServicePayments_aggregate_bool_exp_corr_arguments_columns" columns of table "UserServicePayments" */
export type UserServicePayments_Select_Column_UserServicePayments_Aggregate_Bool_Exp_Corr_Arguments_Columns =
  /** column name */
  | 'value';

/** select "UserServicePayments_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "UserServicePayments" */
export type UserServicePayments_Select_Column_UserServicePayments_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns =
  /** column name */
  | 'value';

/** select "UserServicePayments_aggregate_bool_exp_max_arguments_columns" columns of table "UserServicePayments" */
export type UserServicePayments_Select_Column_UserServicePayments_Aggregate_Bool_Exp_Max_Arguments_Columns =
  /** column name */
  | 'value';

/** select "UserServicePayments_aggregate_bool_exp_min_arguments_columns" columns of table "UserServicePayments" */
export type UserServicePayments_Select_Column_UserServicePayments_Aggregate_Bool_Exp_Min_Arguments_Columns =
  /** column name */
  | 'value';

/** select "UserServicePayments_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "UserServicePayments" */
export type UserServicePayments_Select_Column_UserServicePayments_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns =
  /** column name */
  | 'value';

/** select "UserServicePayments_aggregate_bool_exp_sum_arguments_columns" columns of table "UserServicePayments" */
export type UserServicePayments_Select_Column_UserServicePayments_Aggregate_Bool_Exp_Sum_Arguments_Columns =
  /** column name */
  | 'value';

/** select "UserServicePayments_aggregate_bool_exp_var_samp_arguments_columns" columns of table "UserServicePayments" */
export type UserServicePayments_Select_Column_UserServicePayments_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns =
  /** column name */
  | 'value';

/** input type for updating data in table "UserServicePayments" */
export interface UserServicePayments_Set_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  in_percent?: InputMaybe<Scalars['Boolean']['input']>;
  service_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** aggregate stddev on columns */
export interface UserServicePayments_Stddev_Fields {
  __typename?: 'UserServicePayments_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "UserServicePayments" */
export interface UserServicePayments_Stddev_Order_By {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface UserServicePayments_Stddev_Pop_Fields {
  __typename?: 'UserServicePayments_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "UserServicePayments" */
export interface UserServicePayments_Stddev_Pop_Order_By {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface UserServicePayments_Stddev_Samp_Fields {
  __typename?: 'UserServicePayments_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "UserServicePayments" */
export interface UserServicePayments_Stddev_Samp_Order_By {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** Streaming cursor of the table "UserServicePayments" */
export interface UserServicePayments_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: UserServicePayments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface UserServicePayments_Stream_Cursor_Value_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  in_percent?: InputMaybe<Scalars['Boolean']['input']>;
  service_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}

/** aggregate sum on columns */
export interface UserServicePayments_Sum_Fields {
  __typename?: 'UserServicePayments_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  service_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
}

/** order by sum() on columns of table "UserServicePayments" */
export interface UserServicePayments_Sum_Order_By {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** update columns of table "UserServicePayments" */
export type UserServicePayments_Update_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'in_percent'
  /** column name */
  | 'service_id'
  /** column name */
  | 'user_id'
  /** column name */
  | 'value';

export interface UserServicePayments_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserServicePayments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserServicePayments_Set_Input>;
  where: UserServicePayments_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface UserServicePayments_Var_Pop_Fields {
  __typename?: 'UserServicePayments_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "UserServicePayments" */
export interface UserServicePayments_Var_Pop_Order_By {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface UserServicePayments_Var_Samp_Fields {
  __typename?: 'UserServicePayments_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "UserServicePayments" */
export interface UserServicePayments_Var_Samp_Order_By {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate variance on columns */
export interface UserServicePayments_Variance_Fields {
  __typename?: 'UserServicePayments_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "UserServicePayments" */
export interface UserServicePayments_Variance_Order_By {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** columns and relationships of "UserUT" */
export interface UserUt {
  __typename?: 'UserUT';
  /** An object relationship */
  User: Users;
  id: Scalars['Int']['output'];
  user_id: Scalars['uuid']['output'];
  ut_id: Scalars['String']['output'];
}

/** aggregated selection of "UserUT" */
export interface UserUt_Aggregate {
  __typename?: 'UserUT_aggregate';
  aggregate?: Maybe<UserUt_Aggregate_Fields>;
  nodes: Array<UserUt>;
}

/** aggregate fields of "UserUT" */
export interface UserUt_Aggregate_Fields {
  __typename?: 'UserUT_aggregate_fields';
  avg?: Maybe<UserUt_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<UserUt_Max_Fields>;
  min?: Maybe<UserUt_Min_Fields>;
  stddev?: Maybe<UserUt_Stddev_Fields>;
  stddev_pop?: Maybe<UserUt_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserUt_Stddev_Samp_Fields>;
  sum?: Maybe<UserUt_Sum_Fields>;
  var_pop?: Maybe<UserUt_Var_Pop_Fields>;
  var_samp?: Maybe<UserUt_Var_Samp_Fields>;
  variance?: Maybe<UserUt_Variance_Fields>;
}


/** aggregate fields of "UserUT" */
export interface UserUt_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<UserUt_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate avg on columns */
export interface UserUt_Avg_Fields {
  __typename?: 'UserUT_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to filter rows from the table "UserUT". All fields are combined with a logical 'AND'. */
export interface UserUt_Bool_Exp {
  User?: InputMaybe<Users_Bool_Exp>;
  _and?: InputMaybe<Array<UserUt_Bool_Exp>>;
  _not?: InputMaybe<UserUt_Bool_Exp>;
  _or?: InputMaybe<Array<UserUt_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  ut_id?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "UserUT" */
export type UserUt_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'UserUT_pkey'
  /** unique or primary key constraint on columns "user_id" */
  | 'UserUT_user_id_key';

/** input type for incrementing numeric columns in table "UserUT" */
export interface UserUt_Inc_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "UserUT" */
export interface UserUt_Insert_Input {
  User?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  ut_id?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface UserUt_Max_Fields {
  __typename?: 'UserUT_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  ut_id?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface UserUt_Min_Fields {
  __typename?: 'UserUT_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  ut_id?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "UserUT" */
export interface UserUt_Mutation_Response {
  __typename?: 'UserUT_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserUt>;
}

/** input type for inserting object relation for remote table "UserUT" */
export interface UserUt_Obj_Rel_Insert_Input {
  data: UserUt_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<UserUt_On_Conflict>;
}

/** on_conflict condition type for table "UserUT" */
export interface UserUt_On_Conflict {
  constraint: UserUt_Constraint;
  update_columns?: Array<UserUt_Update_Column>;
  where?: InputMaybe<UserUt_Bool_Exp>;
}

/** Ordering options when selecting data from "UserUT". */
export interface UserUt_Order_By {
  User?: InputMaybe<Users_Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  ut_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: UserUT */
export interface UserUt_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "UserUT" */
export type UserUt_Select_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'user_id'
  /** column name */
  | 'ut_id';

/** input type for updating data in table "UserUT" */
export interface UserUt_Set_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  ut_id?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate stddev on columns */
export interface UserUt_Stddev_Fields {
  __typename?: 'UserUT_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_pop on columns */
export interface UserUt_Stddev_Pop_Fields {
  __typename?: 'UserUT_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_samp on columns */
export interface UserUt_Stddev_Samp_Fields {
  __typename?: 'UserUT_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** Streaming cursor of the table "UserUT" */
export interface UserUt_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: UserUt_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface UserUt_Stream_Cursor_Value_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  ut_id?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate sum on columns */
export interface UserUt_Sum_Fields {
  __typename?: 'UserUT_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
}

/** update columns of table "UserUT" */
export type UserUt_Update_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'user_id'
  /** column name */
  | 'ut_id';

export interface UserUt_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserUt_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserUt_Set_Input>;
  where: UserUt_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface UserUt_Var_Pop_Fields {
  __typename?: 'UserUT_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate var_samp on columns */
export interface UserUt_Var_Samp_Fields {
  __typename?: 'UserUT_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** aggregate variance on columns */
export interface UserUt_Variance_Fields {
  __typename?: 'UserUT_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
}

/** columns and relationships of "Users" */
export interface Users {
  __typename?: 'Users';
  Created: Scalars['timestamp']['output'];
  /** An array relationship */
  CurrentVisits: Array<Visit>;
  /** An aggregate relationship */
  CurrentVisits_aggregate: Visit_Aggregate;
  DefaultRoleId: Scalars['uuid']['output'];
  /** An array relationship */
  Departments: Array<DoctorsDepartment>;
  /** An aggregate relationship */
  Departments_aggregate: DoctorsDepartment_Aggregate;
  /** An array relationship */
  DoctorsReceptions: Array<Reception>;
  /** An aggregate relationship */
  DoctorsReceptions_aggregate: Reception_Aggregate;
  /** An array relationship */
  FromReceptions: Array<Reception>;
  /** An aggregate relationship */
  FromReceptions_aggregate: Reception_Aggregate;
  ID: Scalars['uuid']['output'];
  IsActive: Scalars['Boolean']['output'];
  LastSeen: Scalars['timestamp']['output'];
  Login: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  Password: Scalars['String']['output'];
  /** An object relationship */
  Role: Roles;
  /** An array relationship */
  UserMedicinePayments: Array<UserMedicinePayments>;
  /** An aggregate relationship */
  UserMedicinePayments_aggregate: UserMedicinePayments_Aggregate;
  /** An array relationship */
  UserServicePayments: Array<UserServicePayments>;
  /** An aggregate relationship */
  UserServicePayments_aggregate: UserServicePayments_Aggregate;
  /** An object relationship */
  UserUT?: Maybe<UserUt>;
  /** An array relationship */
  VisitsFrom: Array<Visit>;
  /** An aggregate relationship */
  VisitsFrom_aggregate: Visit_Aggregate;
}


/** columns and relationships of "Users" */
export interface UsersCurrentVisitsArgs {
  distinct_on?: InputMaybe<Array<Visit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Visit_Order_By>>;
  where?: InputMaybe<Visit_Bool_Exp>;
}


/** columns and relationships of "Users" */
export interface UsersCurrentVisits_AggregateArgs {
  distinct_on?: InputMaybe<Array<Visit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Visit_Order_By>>;
  where?: InputMaybe<Visit_Bool_Exp>;
}


/** columns and relationships of "Users" */
export interface UsersDepartmentsArgs {
  distinct_on?: InputMaybe<Array<DoctorsDepartment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DoctorsDepartment_Order_By>>;
  where?: InputMaybe<DoctorsDepartment_Bool_Exp>;
}


/** columns and relationships of "Users" */
export interface UsersDepartments_AggregateArgs {
  distinct_on?: InputMaybe<Array<DoctorsDepartment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DoctorsDepartment_Order_By>>;
  where?: InputMaybe<DoctorsDepartment_Bool_Exp>;
}


/** columns and relationships of "Users" */
export interface UsersDoctorsReceptionsArgs {
  distinct_on?: InputMaybe<Array<Reception_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reception_Order_By>>;
  where?: InputMaybe<Reception_Bool_Exp>;
}


/** columns and relationships of "Users" */
export interface UsersDoctorsReceptions_AggregateArgs {
  distinct_on?: InputMaybe<Array<Reception_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reception_Order_By>>;
  where?: InputMaybe<Reception_Bool_Exp>;
}


/** columns and relationships of "Users" */
export interface UsersFromReceptionsArgs {
  distinct_on?: InputMaybe<Array<Reception_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reception_Order_By>>;
  where?: InputMaybe<Reception_Bool_Exp>;
}


/** columns and relationships of "Users" */
export interface UsersFromReceptions_AggregateArgs {
  distinct_on?: InputMaybe<Array<Reception_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reception_Order_By>>;
  where?: InputMaybe<Reception_Bool_Exp>;
}


/** columns and relationships of "Users" */
export interface UsersUserMedicinePaymentsArgs {
  distinct_on?: InputMaybe<Array<UserMedicinePayments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserMedicinePayments_Order_By>>;
  where?: InputMaybe<UserMedicinePayments_Bool_Exp>;
}


/** columns and relationships of "Users" */
export interface UsersUserMedicinePayments_AggregateArgs {
  distinct_on?: InputMaybe<Array<UserMedicinePayments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserMedicinePayments_Order_By>>;
  where?: InputMaybe<UserMedicinePayments_Bool_Exp>;
}


/** columns and relationships of "Users" */
export interface UsersUserServicePaymentsArgs {
  distinct_on?: InputMaybe<Array<UserServicePayments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserServicePayments_Order_By>>;
  where?: InputMaybe<UserServicePayments_Bool_Exp>;
}


/** columns and relationships of "Users" */
export interface UsersUserServicePayments_AggregateArgs {
  distinct_on?: InputMaybe<Array<UserServicePayments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserServicePayments_Order_By>>;
  where?: InputMaybe<UserServicePayments_Bool_Exp>;
}


/** columns and relationships of "Users" */
export interface UsersVisitsFromArgs {
  distinct_on?: InputMaybe<Array<Visit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Visit_Order_By>>;
  where?: InputMaybe<Visit_Bool_Exp>;
}


/** columns and relationships of "Users" */
export interface UsersVisitsFrom_AggregateArgs {
  distinct_on?: InputMaybe<Array<Visit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Visit_Order_By>>;
  where?: InputMaybe<Visit_Bool_Exp>;
}

/** aggregated selection of "Users" */
export interface Users_Aggregate {
  __typename?: 'Users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
}

export interface Users_Aggregate_Bool_Exp {
  bool_and?: InputMaybe<Users_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Users_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Users_Aggregate_Bool_Exp_Count>;
}

export interface Users_Aggregate_Bool_Exp_Bool_And {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Users_Aggregate_Bool_Exp_Bool_Or {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Users_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "Users" */
export interface Users_Aggregate_Fields {
  __typename?: 'Users_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
}


/** aggregate fields of "Users" */
export interface Users_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "Users" */
export interface Users_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Max_Order_By>;
  min?: InputMaybe<Users_Min_Order_By>;
}

/** input type for inserting array relation for remote table "Users" */
export interface Users_Arr_Rel_Insert_Input {
  data: Array<Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
}

/** Boolean expression to filter rows from the table "Users". All fields are combined with a logical 'AND'. */
export interface Users_Bool_Exp {
  Created?: InputMaybe<Timestamp_Comparison_Exp>;
  CurrentVisits?: InputMaybe<Visit_Bool_Exp>;
  CurrentVisits_aggregate?: InputMaybe<Visit_Aggregate_Bool_Exp>;
  DefaultRoleId?: InputMaybe<Uuid_Comparison_Exp>;
  Departments?: InputMaybe<DoctorsDepartment_Bool_Exp>;
  Departments_aggregate?: InputMaybe<DoctorsDepartment_Aggregate_Bool_Exp>;
  DoctorsReceptions?: InputMaybe<Reception_Bool_Exp>;
  DoctorsReceptions_aggregate?: InputMaybe<Reception_Aggregate_Bool_Exp>;
  FromReceptions?: InputMaybe<Reception_Bool_Exp>;
  FromReceptions_aggregate?: InputMaybe<Reception_Aggregate_Bool_Exp>;
  ID?: InputMaybe<Uuid_Comparison_Exp>;
  IsActive?: InputMaybe<Boolean_Comparison_Exp>;
  LastSeen?: InputMaybe<Timestamp_Comparison_Exp>;
  Login?: InputMaybe<String_Comparison_Exp>;
  Name?: InputMaybe<String_Comparison_Exp>;
  Password?: InputMaybe<String_Comparison_Exp>;
  Role?: InputMaybe<Roles_Bool_Exp>;
  UserMedicinePayments?: InputMaybe<UserMedicinePayments_Bool_Exp>;
  UserMedicinePayments_aggregate?: InputMaybe<UserMedicinePayments_Aggregate_Bool_Exp>;
  UserServicePayments?: InputMaybe<UserServicePayments_Bool_Exp>;
  UserServicePayments_aggregate?: InputMaybe<UserServicePayments_Aggregate_Bool_Exp>;
  UserUT?: InputMaybe<UserUt_Bool_Exp>;
  VisitsFrom?: InputMaybe<Visit_Bool_Exp>;
  VisitsFrom_aggregate?: InputMaybe<Visit_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
}

/** unique or primary key constraints on table "Users" */
export type Users_Constraint =
  /** unique or primary key constraint on columns "Login" */
  | 'IX_Users_Login'
  /** unique or primary key constraint on columns "ID" */
  | 'PK_Users';

/** input type for inserting data into table "Users" */
export interface Users_Insert_Input {
  Created?: InputMaybe<Scalars['timestamp']['input']>;
  CurrentVisits?: InputMaybe<Visit_Arr_Rel_Insert_Input>;
  DefaultRoleId?: InputMaybe<Scalars['uuid']['input']>;
  Departments?: InputMaybe<DoctorsDepartment_Arr_Rel_Insert_Input>;
  DoctorsReceptions?: InputMaybe<Reception_Arr_Rel_Insert_Input>;
  FromReceptions?: InputMaybe<Reception_Arr_Rel_Insert_Input>;
  ID?: InputMaybe<Scalars['uuid']['input']>;
  IsActive?: InputMaybe<Scalars['Boolean']['input']>;
  LastSeen?: InputMaybe<Scalars['timestamp']['input']>;
  Login?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  Password?: InputMaybe<Scalars['String']['input']>;
  Role?: InputMaybe<Roles_Obj_Rel_Insert_Input>;
  UserMedicinePayments?: InputMaybe<UserMedicinePayments_Arr_Rel_Insert_Input>;
  UserServicePayments?: InputMaybe<UserServicePayments_Arr_Rel_Insert_Input>;
  UserUT?: InputMaybe<UserUt_Obj_Rel_Insert_Input>;
  VisitsFrom?: InputMaybe<Visit_Arr_Rel_Insert_Input>;
}

/** aggregate max on columns */
export interface Users_Max_Fields {
  __typename?: 'Users_max_fields';
  Created?: Maybe<Scalars['timestamp']['output']>;
  DefaultRoleId?: Maybe<Scalars['uuid']['output']>;
  ID?: Maybe<Scalars['uuid']['output']>;
  LastSeen?: Maybe<Scalars['timestamp']['output']>;
  Login?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  Password?: Maybe<Scalars['String']['output']>;
}

/** order by max() on columns of table "Users" */
export interface Users_Max_Order_By {
  Created?: InputMaybe<Order_By>;
  DefaultRoleId?: InputMaybe<Order_By>;
  ID?: InputMaybe<Order_By>;
  LastSeen?: InputMaybe<Order_By>;
  Login?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  Password?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface Users_Min_Fields {
  __typename?: 'Users_min_fields';
  Created?: Maybe<Scalars['timestamp']['output']>;
  DefaultRoleId?: Maybe<Scalars['uuid']['output']>;
  ID?: Maybe<Scalars['uuid']['output']>;
  LastSeen?: Maybe<Scalars['timestamp']['output']>;
  Login?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  Password?: Maybe<Scalars['String']['output']>;
}

/** order by min() on columns of table "Users" */
export interface Users_Min_Order_By {
  Created?: InputMaybe<Order_By>;
  DefaultRoleId?: InputMaybe<Order_By>;
  ID?: InputMaybe<Order_By>;
  LastSeen?: InputMaybe<Order_By>;
  Login?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  Password?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "Users" */
export interface Users_Mutation_Response {
  __typename?: 'Users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
}

/** input type for inserting object relation for remote table "Users" */
export interface Users_Obj_Rel_Insert_Input {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
}

/** on_conflict condition type for table "Users" */
export interface Users_On_Conflict {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
}

/** Ordering options when selecting data from "Users". */
export interface Users_Order_By {
  Created?: InputMaybe<Order_By>;
  CurrentVisits_aggregate?: InputMaybe<Visit_Aggregate_Order_By>;
  DefaultRoleId?: InputMaybe<Order_By>;
  Departments_aggregate?: InputMaybe<DoctorsDepartment_Aggregate_Order_By>;
  DoctorsReceptions_aggregate?: InputMaybe<Reception_Aggregate_Order_By>;
  FromReceptions_aggregate?: InputMaybe<Reception_Aggregate_Order_By>;
  ID?: InputMaybe<Order_By>;
  IsActive?: InputMaybe<Order_By>;
  LastSeen?: InputMaybe<Order_By>;
  Login?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  Password?: InputMaybe<Order_By>;
  Role?: InputMaybe<Roles_Order_By>;
  UserMedicinePayments_aggregate?: InputMaybe<UserMedicinePayments_Aggregate_Order_By>;
  UserServicePayments_aggregate?: InputMaybe<UserServicePayments_Aggregate_Order_By>;
  UserUT?: InputMaybe<UserUt_Order_By>;
  VisitsFrom_aggregate?: InputMaybe<Visit_Aggregate_Order_By>;
}

/** primary key columns input for table: Users */
export interface Users_Pk_Columns_Input {
  ID: Scalars['uuid']['input'];
}

/** select columns of table "Users" */
export type Users_Select_Column =
  /** column name */
  | 'Created'
  /** column name */
  | 'DefaultRoleId'
  /** column name */
  | 'ID'
  /** column name */
  | 'IsActive'
  /** column name */
  | 'LastSeen'
  /** column name */
  | 'Login'
  /** column name */
  | 'Name'
  /** column name */
  | 'Password';

/** select "Users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Users" */
export type Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  | 'IsActive';

/** select "Users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Users" */
export type Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  | 'IsActive';

/** input type for updating data in table "Users" */
export interface Users_Set_Input {
  Created?: InputMaybe<Scalars['timestamp']['input']>;
  DefaultRoleId?: InputMaybe<Scalars['uuid']['input']>;
  ID?: InputMaybe<Scalars['uuid']['input']>;
  IsActive?: InputMaybe<Scalars['Boolean']['input']>;
  LastSeen?: InputMaybe<Scalars['timestamp']['input']>;
  Login?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  Password?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "Users" */
export interface Users_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Users_Stream_Cursor_Value_Input {
  Created?: InputMaybe<Scalars['timestamp']['input']>;
  DefaultRoleId?: InputMaybe<Scalars['uuid']['input']>;
  ID?: InputMaybe<Scalars['uuid']['input']>;
  IsActive?: InputMaybe<Scalars['Boolean']['input']>;
  LastSeen?: InputMaybe<Scalars['timestamp']['input']>;
  Login?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  Password?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "Users" */
export type Users_Update_Column =
  /** column name */
  | 'Created'
  /** column name */
  | 'DefaultRoleId'
  /** column name */
  | 'ID'
  /** column name */
  | 'IsActive'
  /** column name */
  | 'LastSeen'
  /** column name */
  | 'Login'
  /** column name */
  | 'Name'
  /** column name */
  | 'Password';

export interface Users_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
}

/** Посещение центра */
export interface Visit {
  __typename?: 'Visit';
  /** An object relationship */
  Client: Client;
  /** An object relationship */
  CurrentReception?: Maybe<Reception>;
  /** An object relationship */
  Department: Department;
  /** An object relationship */
  Doctor?: Maybe<Users>;
  /** An object relationship */
  From: Users;
  /** An object relationship */
  Pet: Pet;
  /** An array relationship */
  ReceptionsList: Array<Reception>;
  /** An aggregate relationship */
  ReceptionsList_aggregate: Reception_Aggregate;
  /** An array relationship */
  VisitDocuments: Array<VisitDocument>;
  /** An aggregate relationship */
  VisitDocuments_aggregate: VisitDocument_Aggregate;
  /** An array relationship */
  VisitFiles: Array<VisitFiles>;
  /** An aggregate relationship */
  VisitFiles_aggregate: VisitFiles_Aggregate;
  client_id: Scalars['Int']['output'];
  current_reception_id?: Maybe<Scalars['Int']['output']>;
  date: Scalars['date']['output'];
  department_id: Scalars['Int']['output'];
  doctor_id?: Maybe<Scalars['uuid']['output']>;
  from_id: Scalars['uuid']['output'];
  id: Scalars['Int']['output'];
  patient_card: Scalars['jsonb']['output'];
  patient_id: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  time: Scalars['timetz']['output'];
  visit_start?: Maybe<Scalars['time']['output']>;
  without_time: Scalars['Boolean']['output'];
}


/** Посещение центра */
export interface VisitReceptionsListArgs {
  distinct_on?: InputMaybe<Array<Reception_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reception_Order_By>>;
  where?: InputMaybe<Reception_Bool_Exp>;
}


/** Посещение центра */
export interface VisitReceptionsList_AggregateArgs {
  distinct_on?: InputMaybe<Array<Reception_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reception_Order_By>>;
  where?: InputMaybe<Reception_Bool_Exp>;
}


/** Посещение центра */
export interface VisitVisitDocumentsArgs {
  distinct_on?: InputMaybe<Array<VisitDocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VisitDocument_Order_By>>;
  where?: InputMaybe<VisitDocument_Bool_Exp>;
}


/** Посещение центра */
export interface VisitVisitDocuments_AggregateArgs {
  distinct_on?: InputMaybe<Array<VisitDocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VisitDocument_Order_By>>;
  where?: InputMaybe<VisitDocument_Bool_Exp>;
}


/** Посещение центра */
export interface VisitVisitFilesArgs {
  distinct_on?: InputMaybe<Array<VisitFiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VisitFiles_Order_By>>;
  where?: InputMaybe<VisitFiles_Bool_Exp>;
}


/** Посещение центра */
export interface VisitVisitFiles_AggregateArgs {
  distinct_on?: InputMaybe<Array<VisitFiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VisitFiles_Order_By>>;
  where?: InputMaybe<VisitFiles_Bool_Exp>;
}


/** Посещение центра */
export interface VisitPatient_CardArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}

/** columns and relationships of "VisitDocument" */
export interface VisitDocument {
  __typename?: 'VisitDocument';
  /** An object relationship */
  Visit: Visit;
  data: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  visit_id: Scalars['Int']['output'];
}

/** aggregated selection of "VisitDocument" */
export interface VisitDocument_Aggregate {
  __typename?: 'VisitDocument_aggregate';
  aggregate?: Maybe<VisitDocument_Aggregate_Fields>;
  nodes: Array<VisitDocument>;
}

export interface VisitDocument_Aggregate_Bool_Exp {
  count?: InputMaybe<VisitDocument_Aggregate_Bool_Exp_Count>;
}

export interface VisitDocument_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<VisitDocument_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<VisitDocument_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "VisitDocument" */
export interface VisitDocument_Aggregate_Fields {
  __typename?: 'VisitDocument_aggregate_fields';
  avg?: Maybe<VisitDocument_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<VisitDocument_Max_Fields>;
  min?: Maybe<VisitDocument_Min_Fields>;
  stddev?: Maybe<VisitDocument_Stddev_Fields>;
  stddev_pop?: Maybe<VisitDocument_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VisitDocument_Stddev_Samp_Fields>;
  sum?: Maybe<VisitDocument_Sum_Fields>;
  var_pop?: Maybe<VisitDocument_Var_Pop_Fields>;
  var_samp?: Maybe<VisitDocument_Var_Samp_Fields>;
  variance?: Maybe<VisitDocument_Variance_Fields>;
}


/** aggregate fields of "VisitDocument" */
export interface VisitDocument_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<VisitDocument_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "VisitDocument" */
export interface VisitDocument_Aggregate_Order_By {
  avg?: InputMaybe<VisitDocument_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<VisitDocument_Max_Order_By>;
  min?: InputMaybe<VisitDocument_Min_Order_By>;
  stddev?: InputMaybe<VisitDocument_Stddev_Order_By>;
  stddev_pop?: InputMaybe<VisitDocument_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<VisitDocument_Stddev_Samp_Order_By>;
  sum?: InputMaybe<VisitDocument_Sum_Order_By>;
  var_pop?: InputMaybe<VisitDocument_Var_Pop_Order_By>;
  var_samp?: InputMaybe<VisitDocument_Var_Samp_Order_By>;
  variance?: InputMaybe<VisitDocument_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "VisitDocument" */
export interface VisitDocument_Arr_Rel_Insert_Input {
  data: Array<VisitDocument_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<VisitDocument_On_Conflict>;
}

/** aggregate avg on columns */
export interface VisitDocument_Avg_Fields {
  __typename?: 'VisitDocument_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  visit_id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "VisitDocument" */
export interface VisitDocument_Avg_Order_By {
  id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** Boolean expression to filter rows from the table "VisitDocument". All fields are combined with a logical 'AND'. */
export interface VisitDocument_Bool_Exp {
  Visit?: InputMaybe<Visit_Bool_Exp>;
  _and?: InputMaybe<Array<VisitDocument_Bool_Exp>>;
  _not?: InputMaybe<VisitDocument_Bool_Exp>;
  _or?: InputMaybe<Array<VisitDocument_Bool_Exp>>;
  data?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  visit_id?: InputMaybe<Int_Comparison_Exp>;
}

/** unique or primary key constraints on table "VisitDocument" */
export type VisitDocument_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'VisitDocument_pkey';

/** input type for incrementing numeric columns in table "VisitDocument" */
export interface VisitDocument_Inc_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  visit_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "VisitDocument" */
export interface VisitDocument_Insert_Input {
  Visit?: InputMaybe<Visit_Obj_Rel_Insert_Input>;
  data?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  visit_id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate max on columns */
export interface VisitDocument_Max_Fields {
  __typename?: 'VisitDocument_max_fields';
  data?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  visit_id?: Maybe<Scalars['Int']['output']>;
}

/** order by max() on columns of table "VisitDocument" */
export interface VisitDocument_Max_Order_By {
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface VisitDocument_Min_Fields {
  __typename?: 'VisitDocument_min_fields';
  data?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  visit_id?: Maybe<Scalars['Int']['output']>;
}

/** order by min() on columns of table "VisitDocument" */
export interface VisitDocument_Min_Order_By {
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "VisitDocument" */
export interface VisitDocument_Mutation_Response {
  __typename?: 'VisitDocument_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<VisitDocument>;
}

/** on_conflict condition type for table "VisitDocument" */
export interface VisitDocument_On_Conflict {
  constraint: VisitDocument_Constraint;
  update_columns?: Array<VisitDocument_Update_Column>;
  where?: InputMaybe<VisitDocument_Bool_Exp>;
}

/** Ordering options when selecting data from "VisitDocument". */
export interface VisitDocument_Order_By {
  Visit?: InputMaybe<Visit_Order_By>;
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: VisitDocument */
export interface VisitDocument_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "VisitDocument" */
export type VisitDocument_Select_Column =
  /** column name */
  | 'data'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'visit_id';

/** input type for updating data in table "VisitDocument" */
export interface VisitDocument_Set_Input {
  data?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  visit_id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate stddev on columns */
export interface VisitDocument_Stddev_Fields {
  __typename?: 'VisitDocument_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  visit_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "VisitDocument" */
export interface VisitDocument_Stddev_Order_By {
  id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface VisitDocument_Stddev_Pop_Fields {
  __typename?: 'VisitDocument_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  visit_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "VisitDocument" */
export interface VisitDocument_Stddev_Pop_Order_By {
  id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface VisitDocument_Stddev_Samp_Fields {
  __typename?: 'VisitDocument_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  visit_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "VisitDocument" */
export interface VisitDocument_Stddev_Samp_Order_By {
  id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** Streaming cursor of the table "VisitDocument" */
export interface VisitDocument_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: VisitDocument_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface VisitDocument_Stream_Cursor_Value_Input {
  data?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  visit_id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate sum on columns */
export interface VisitDocument_Sum_Fields {
  __typename?: 'VisitDocument_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  visit_id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "VisitDocument" */
export interface VisitDocument_Sum_Order_By {
  id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** update columns of table "VisitDocument" */
export type VisitDocument_Update_Column =
  /** column name */
  | 'data'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'visit_id';

export interface VisitDocument_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<VisitDocument_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VisitDocument_Set_Input>;
  where: VisitDocument_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface VisitDocument_Var_Pop_Fields {
  __typename?: 'VisitDocument_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  visit_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "VisitDocument" */
export interface VisitDocument_Var_Pop_Order_By {
  id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface VisitDocument_Var_Samp_Fields {
  __typename?: 'VisitDocument_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  visit_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "VisitDocument" */
export interface VisitDocument_Var_Samp_Order_By {
  id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** aggregate variance on columns */
export interface VisitDocument_Variance_Fields {
  __typename?: 'VisitDocument_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  visit_id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "VisitDocument" */
export interface VisitDocument_Variance_Order_By {
  id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** columns and relationships of "VisitFiles" */
export interface VisitFiles {
  __typename?: 'VisitFiles';
  /** An object relationship */
  Visit: Visit;
  file_name: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  visit_id: Scalars['Int']['output'];
}

/** aggregated selection of "VisitFiles" */
export interface VisitFiles_Aggregate {
  __typename?: 'VisitFiles_aggregate';
  aggregate?: Maybe<VisitFiles_Aggregate_Fields>;
  nodes: Array<VisitFiles>;
}

export interface VisitFiles_Aggregate_Bool_Exp {
  count?: InputMaybe<VisitFiles_Aggregate_Bool_Exp_Count>;
}

export interface VisitFiles_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<VisitFiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<VisitFiles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "VisitFiles" */
export interface VisitFiles_Aggregate_Fields {
  __typename?: 'VisitFiles_aggregate_fields';
  avg?: Maybe<VisitFiles_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<VisitFiles_Max_Fields>;
  min?: Maybe<VisitFiles_Min_Fields>;
  stddev?: Maybe<VisitFiles_Stddev_Fields>;
  stddev_pop?: Maybe<VisitFiles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VisitFiles_Stddev_Samp_Fields>;
  sum?: Maybe<VisitFiles_Sum_Fields>;
  var_pop?: Maybe<VisitFiles_Var_Pop_Fields>;
  var_samp?: Maybe<VisitFiles_Var_Samp_Fields>;
  variance?: Maybe<VisitFiles_Variance_Fields>;
}


/** aggregate fields of "VisitFiles" */
export interface VisitFiles_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<VisitFiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "VisitFiles" */
export interface VisitFiles_Aggregate_Order_By {
  avg?: InputMaybe<VisitFiles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<VisitFiles_Max_Order_By>;
  min?: InputMaybe<VisitFiles_Min_Order_By>;
  stddev?: InputMaybe<VisitFiles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<VisitFiles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<VisitFiles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<VisitFiles_Sum_Order_By>;
  var_pop?: InputMaybe<VisitFiles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<VisitFiles_Var_Samp_Order_By>;
  variance?: InputMaybe<VisitFiles_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "VisitFiles" */
export interface VisitFiles_Arr_Rel_Insert_Input {
  data: Array<VisitFiles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<VisitFiles_On_Conflict>;
}

/** aggregate avg on columns */
export interface VisitFiles_Avg_Fields {
  __typename?: 'VisitFiles_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  visit_id?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "VisitFiles" */
export interface VisitFiles_Avg_Order_By {
  id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** Boolean expression to filter rows from the table "VisitFiles". All fields are combined with a logical 'AND'. */
export interface VisitFiles_Bool_Exp {
  Visit?: InputMaybe<Visit_Bool_Exp>;
  _and?: InputMaybe<Array<VisitFiles_Bool_Exp>>;
  _not?: InputMaybe<VisitFiles_Bool_Exp>;
  _or?: InputMaybe<Array<VisitFiles_Bool_Exp>>;
  file_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  visit_id?: InputMaybe<Int_Comparison_Exp>;
}

/** unique or primary key constraints on table "VisitFiles" */
export type VisitFiles_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'visit_files_pkey';

/** input type for incrementing numeric columns in table "VisitFiles" */
export interface VisitFiles_Inc_Input {
  id?: InputMaybe<Scalars['Int']['input']>;
  visit_id?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "VisitFiles" */
export interface VisitFiles_Insert_Input {
  Visit?: InputMaybe<Visit_Obj_Rel_Insert_Input>;
  file_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  visit_id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate max on columns */
export interface VisitFiles_Max_Fields {
  __typename?: 'VisitFiles_max_fields';
  file_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  visit_id?: Maybe<Scalars['Int']['output']>;
}

/** order by max() on columns of table "VisitFiles" */
export interface VisitFiles_Max_Order_By {
  file_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface VisitFiles_Min_Fields {
  __typename?: 'VisitFiles_min_fields';
  file_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  visit_id?: Maybe<Scalars['Int']['output']>;
}

/** order by min() on columns of table "VisitFiles" */
export interface VisitFiles_Min_Order_By {
  file_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "VisitFiles" */
export interface VisitFiles_Mutation_Response {
  __typename?: 'VisitFiles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<VisitFiles>;
}

/** on_conflict condition type for table "VisitFiles" */
export interface VisitFiles_On_Conflict {
  constraint: VisitFiles_Constraint;
  update_columns?: Array<VisitFiles_Update_Column>;
  where?: InputMaybe<VisitFiles_Bool_Exp>;
}

/** Ordering options when selecting data from "VisitFiles". */
export interface VisitFiles_Order_By {
  Visit?: InputMaybe<Visit_Order_By>;
  file_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: VisitFiles */
export interface VisitFiles_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** select columns of table "VisitFiles" */
export type VisitFiles_Select_Column =
  /** column name */
  | 'file_name'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'visit_id';

/** input type for updating data in table "VisitFiles" */
export interface VisitFiles_Set_Input {
  file_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  visit_id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate stddev on columns */
export interface VisitFiles_Stddev_Fields {
  __typename?: 'VisitFiles_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  visit_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "VisitFiles" */
export interface VisitFiles_Stddev_Order_By {
  id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface VisitFiles_Stddev_Pop_Fields {
  __typename?: 'VisitFiles_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  visit_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "VisitFiles" */
export interface VisitFiles_Stddev_Pop_Order_By {
  id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface VisitFiles_Stddev_Samp_Fields {
  __typename?: 'VisitFiles_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  visit_id?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "VisitFiles" */
export interface VisitFiles_Stddev_Samp_Order_By {
  id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** Streaming cursor of the table "VisitFiles" */
export interface VisitFiles_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: VisitFiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface VisitFiles_Stream_Cursor_Value_Input {
  file_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  visit_id?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate sum on columns */
export interface VisitFiles_Sum_Fields {
  __typename?: 'VisitFiles_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  visit_id?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "VisitFiles" */
export interface VisitFiles_Sum_Order_By {
  id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** update columns of table "VisitFiles" */
export type VisitFiles_Update_Column =
  /** column name */
  | 'file_name'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'visit_id';

export interface VisitFiles_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<VisitFiles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VisitFiles_Set_Input>;
  where: VisitFiles_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface VisitFiles_Var_Pop_Fields {
  __typename?: 'VisitFiles_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  visit_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "VisitFiles" */
export interface VisitFiles_Var_Pop_Order_By {
  id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface VisitFiles_Var_Samp_Fields {
  __typename?: 'VisitFiles_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  visit_id?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "VisitFiles" */
export interface VisitFiles_Var_Samp_Order_By {
  id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** aggregate variance on columns */
export interface VisitFiles_Variance_Fields {
  __typename?: 'VisitFiles_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  visit_id?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "VisitFiles" */
export interface VisitFiles_Variance_Order_By {
  id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
}

/** aggregated selection of "Visit" */
export interface Visit_Aggregate {
  __typename?: 'Visit_aggregate';
  aggregate?: Maybe<Visit_Aggregate_Fields>;
  nodes: Array<Visit>;
}

export interface Visit_Aggregate_Bool_Exp {
  bool_and?: InputMaybe<Visit_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Visit_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Visit_Aggregate_Bool_Exp_Count>;
}

export interface Visit_Aggregate_Bool_Exp_Bool_And {
  arguments: Visit_Select_Column_Visit_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Visit_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Visit_Aggregate_Bool_Exp_Bool_Or {
  arguments: Visit_Select_Column_Visit_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Visit_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Visit_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Visit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Visit_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "Visit" */
export interface Visit_Aggregate_Fields {
  __typename?: 'Visit_aggregate_fields';
  avg?: Maybe<Visit_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Visit_Max_Fields>;
  min?: Maybe<Visit_Min_Fields>;
  stddev?: Maybe<Visit_Stddev_Fields>;
  stddev_pop?: Maybe<Visit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Visit_Stddev_Samp_Fields>;
  sum?: Maybe<Visit_Sum_Fields>;
  var_pop?: Maybe<Visit_Var_Pop_Fields>;
  var_samp?: Maybe<Visit_Var_Samp_Fields>;
  variance?: Maybe<Visit_Variance_Fields>;
}


/** aggregate fields of "Visit" */
export interface Visit_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Visit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "Visit" */
export interface Visit_Aggregate_Order_By {
  avg?: InputMaybe<Visit_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Visit_Max_Order_By>;
  min?: InputMaybe<Visit_Min_Order_By>;
  stddev?: InputMaybe<Visit_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Visit_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Visit_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Visit_Sum_Order_By>;
  var_pop?: InputMaybe<Visit_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Visit_Var_Samp_Order_By>;
  variance?: InputMaybe<Visit_Variance_Order_By>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Visit_Append_Input {
  patient_card?: InputMaybe<Scalars['jsonb']['input']>;
}

/** input type for inserting array relation for remote table "Visit" */
export interface Visit_Arr_Rel_Insert_Input {
  data: Array<Visit_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Visit_On_Conflict>;
}

/** aggregate avg on columns */
export interface Visit_Avg_Fields {
  __typename?: 'Visit_avg_fields';
  client_id?: Maybe<Scalars['Float']['output']>;
  current_reception_id?: Maybe<Scalars['Float']['output']>;
  department_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  patient_id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "Visit" */
export interface Visit_Avg_Order_By {
  client_id?: InputMaybe<Order_By>;
  current_reception_id?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
}

/** Boolean expression to filter rows from the table "Visit". All fields are combined with a logical 'AND'. */
export interface Visit_Bool_Exp {
  Client?: InputMaybe<Client_Bool_Exp>;
  CurrentReception?: InputMaybe<Reception_Bool_Exp>;
  Department?: InputMaybe<Department_Bool_Exp>;
  Doctor?: InputMaybe<Users_Bool_Exp>;
  From?: InputMaybe<Users_Bool_Exp>;
  Pet?: InputMaybe<Pet_Bool_Exp>;
  ReceptionsList?: InputMaybe<Reception_Bool_Exp>;
  ReceptionsList_aggregate?: InputMaybe<Reception_Aggregate_Bool_Exp>;
  VisitDocuments?: InputMaybe<VisitDocument_Bool_Exp>;
  VisitDocuments_aggregate?: InputMaybe<VisitDocument_Aggregate_Bool_Exp>;
  VisitFiles?: InputMaybe<VisitFiles_Bool_Exp>;
  VisitFiles_aggregate?: InputMaybe<VisitFiles_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Visit_Bool_Exp>>;
  _not?: InputMaybe<Visit_Bool_Exp>;
  _or?: InputMaybe<Array<Visit_Bool_Exp>>;
  client_id?: InputMaybe<Int_Comparison_Exp>;
  current_reception_id?: InputMaybe<Int_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  department_id?: InputMaybe<Int_Comparison_Exp>;
  doctor_id?: InputMaybe<Uuid_Comparison_Exp>;
  from_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  patient_card?: InputMaybe<Jsonb_Comparison_Exp>;
  patient_id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<Int_Comparison_Exp>;
  time?: InputMaybe<Timetz_Comparison_Exp>;
  visit_start?: InputMaybe<Time_Comparison_Exp>;
  without_time?: InputMaybe<Boolean_Comparison_Exp>;
}

/** unique or primary key constraints on table "Visit" */
export type Visit_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'Visit_pkey';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Visit_Delete_At_Path_Input {
  patient_card?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Visit_Delete_Elem_Input {
  patient_card?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Visit_Delete_Key_Input {
  patient_card?: InputMaybe<Scalars['String']['input']>;
}

/** input type for incrementing numeric columns in table "Visit" */
export interface Visit_Inc_Input {
  client_id?: InputMaybe<Scalars['Int']['input']>;
  current_reception_id?: InputMaybe<Scalars['Int']['input']>;
  department_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  patient_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "Visit" */
export interface Visit_Insert_Input {
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  CurrentReception?: InputMaybe<Reception_Obj_Rel_Insert_Input>;
  Department?: InputMaybe<Department_Obj_Rel_Insert_Input>;
  Doctor?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  From?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  Pet?: InputMaybe<Pet_Obj_Rel_Insert_Input>;
  ReceptionsList?: InputMaybe<Reception_Arr_Rel_Insert_Input>;
  VisitDocuments?: InputMaybe<VisitDocument_Arr_Rel_Insert_Input>;
  VisitFiles?: InputMaybe<VisitFiles_Arr_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['Int']['input']>;
  current_reception_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  department_id?: InputMaybe<Scalars['Int']['input']>;
  doctor_id?: InputMaybe<Scalars['uuid']['input']>;
  from_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  patient_card?: InputMaybe<Scalars['jsonb']['input']>;
  patient_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['timetz']['input']>;
  visit_start?: InputMaybe<Scalars['time']['input']>;
  without_time?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate max on columns */
export interface Visit_Max_Fields {
  __typename?: 'Visit_max_fields';
  client_id?: Maybe<Scalars['Int']['output']>;
  current_reception_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  department_id?: Maybe<Scalars['Int']['output']>;
  doctor_id?: Maybe<Scalars['uuid']['output']>;
  from_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  patient_id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  time?: Maybe<Scalars['timetz']['output']>;
}

/** order by max() on columns of table "Visit" */
export interface Visit_Max_Order_By {
  client_id?: InputMaybe<Order_By>;
  current_reception_id?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  doctor_id?: InputMaybe<Order_By>;
  from_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface Visit_Min_Fields {
  __typename?: 'Visit_min_fields';
  client_id?: Maybe<Scalars['Int']['output']>;
  current_reception_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  department_id?: Maybe<Scalars['Int']['output']>;
  doctor_id?: Maybe<Scalars['uuid']['output']>;
  from_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  patient_id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  time?: Maybe<Scalars['timetz']['output']>;
}

/** order by min() on columns of table "Visit" */
export interface Visit_Min_Order_By {
  client_id?: InputMaybe<Order_By>;
  current_reception_id?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  doctor_id?: InputMaybe<Order_By>;
  from_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "Visit" */
export interface Visit_Mutation_Response {
  __typename?: 'Visit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Visit>;
}

/** input type for inserting object relation for remote table "Visit" */
export interface Visit_Obj_Rel_Insert_Input {
  data: Visit_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Visit_On_Conflict>;
}

/** on_conflict condition type for table "Visit" */
export interface Visit_On_Conflict {
  constraint: Visit_Constraint;
  update_columns?: Array<Visit_Update_Column>;
  where?: InputMaybe<Visit_Bool_Exp>;
}

/** Ordering options when selecting data from "Visit". */
export interface Visit_Order_By {
  Client?: InputMaybe<Client_Order_By>;
  CurrentReception?: InputMaybe<Reception_Order_By>;
  Department?: InputMaybe<Department_Order_By>;
  Doctor?: InputMaybe<Users_Order_By>;
  From?: InputMaybe<Users_Order_By>;
  Pet?: InputMaybe<Pet_Order_By>;
  ReceptionsList_aggregate?: InputMaybe<Reception_Aggregate_Order_By>;
  VisitDocuments_aggregate?: InputMaybe<VisitDocument_Aggregate_Order_By>;
  VisitFiles_aggregate?: InputMaybe<VisitFiles_Aggregate_Order_By>;
  client_id?: InputMaybe<Order_By>;
  current_reception_id?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  doctor_id?: InputMaybe<Order_By>;
  from_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_card?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  visit_start?: InputMaybe<Order_By>;
  without_time?: InputMaybe<Order_By>;
}

/** primary key columns input for table: Visit */
export interface Visit_Pk_Columns_Input {
  id: Scalars['Int']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Visit_Prepend_Input {
  patient_card?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "Visit" */
export type Visit_Select_Column =
  /** column name */
  | 'client_id'
  /** column name */
  | 'current_reception_id'
  /** column name */
  | 'date'
  /** column name */
  | 'department_id'
  /** column name */
  | 'doctor_id'
  /** column name */
  | 'from_id'
  /** column name */
  | 'id'
  /** column name */
  | 'patient_card'
  /** column name */
  | 'patient_id'
  /** column name */
  | 'status'
  /** column name */
  | 'time'
  /** column name */
  | 'visit_start'
  /** column name */
  | 'without_time';

/** select "Visit_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Visit" */
export type Visit_Select_Column_Visit_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  | 'without_time';

/** select "Visit_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Visit" */
export type Visit_Select_Column_Visit_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  | 'without_time';

/** input type for updating data in table "Visit" */
export interface Visit_Set_Input {
  client_id?: InputMaybe<Scalars['Int']['input']>;
  current_reception_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  department_id?: InputMaybe<Scalars['Int']['input']>;
  doctor_id?: InputMaybe<Scalars['uuid']['input']>;
  from_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  patient_card?: InputMaybe<Scalars['jsonb']['input']>;
  patient_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['timetz']['input']>;
  visit_start?: InputMaybe<Scalars['time']['input']>;
  without_time?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate stddev on columns */
export interface Visit_Stddev_Fields {
  __typename?: 'Visit_stddev_fields';
  client_id?: Maybe<Scalars['Float']['output']>;
  current_reception_id?: Maybe<Scalars['Float']['output']>;
  department_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  patient_id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "Visit" */
export interface Visit_Stddev_Order_By {
  client_id?: InputMaybe<Order_By>;
  current_reception_id?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface Visit_Stddev_Pop_Fields {
  __typename?: 'Visit_stddev_pop_fields';
  client_id?: Maybe<Scalars['Float']['output']>;
  current_reception_id?: Maybe<Scalars['Float']['output']>;
  department_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  patient_id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "Visit" */
export interface Visit_Stddev_Pop_Order_By {
  client_id?: InputMaybe<Order_By>;
  current_reception_id?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface Visit_Stddev_Samp_Fields {
  __typename?: 'Visit_stddev_samp_fields';
  client_id?: Maybe<Scalars['Float']['output']>;
  current_reception_id?: Maybe<Scalars['Float']['output']>;
  department_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  patient_id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "Visit" */
export interface Visit_Stddev_Samp_Order_By {
  client_id?: InputMaybe<Order_By>;
  current_reception_id?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
}

/** Streaming cursor of the table "Visit" */
export interface Visit_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Visit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Visit_Stream_Cursor_Value_Input {
  client_id?: InputMaybe<Scalars['Int']['input']>;
  current_reception_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  department_id?: InputMaybe<Scalars['Int']['input']>;
  doctor_id?: InputMaybe<Scalars['uuid']['input']>;
  from_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  patient_card?: InputMaybe<Scalars['jsonb']['input']>;
  patient_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['timetz']['input']>;
  visit_start?: InputMaybe<Scalars['time']['input']>;
  without_time?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate sum on columns */
export interface Visit_Sum_Fields {
  __typename?: 'Visit_sum_fields';
  client_id?: Maybe<Scalars['Int']['output']>;
  current_reception_id?: Maybe<Scalars['Int']['output']>;
  department_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  patient_id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "Visit" */
export interface Visit_Sum_Order_By {
  client_id?: InputMaybe<Order_By>;
  current_reception_id?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
}

/** update columns of table "Visit" */
export type Visit_Update_Column =
  /** column name */
  | 'client_id'
  /** column name */
  | 'current_reception_id'
  /** column name */
  | 'date'
  /** column name */
  | 'department_id'
  /** column name */
  | 'doctor_id'
  /** column name */
  | 'from_id'
  /** column name */
  | 'id'
  /** column name */
  | 'patient_card'
  /** column name */
  | 'patient_id'
  /** column name */
  | 'status'
  /** column name */
  | 'time'
  /** column name */
  | 'visit_start'
  /** column name */
  | 'without_time';

export interface Visit_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Visit_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Visit_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Visit_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Visit_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Visit_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Visit_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Visit_Set_Input>;
  where: Visit_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Visit_Var_Pop_Fields {
  __typename?: 'Visit_var_pop_fields';
  client_id?: Maybe<Scalars['Float']['output']>;
  current_reception_id?: Maybe<Scalars['Float']['output']>;
  department_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  patient_id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "Visit" */
export interface Visit_Var_Pop_Order_By {
  client_id?: InputMaybe<Order_By>;
  current_reception_id?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface Visit_Var_Samp_Fields {
  __typename?: 'Visit_var_samp_fields';
  client_id?: Maybe<Scalars['Float']['output']>;
  current_reception_id?: Maybe<Scalars['Float']['output']>;
  department_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  patient_id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "Visit" */
export interface Visit_Var_Samp_Order_By {
  client_id?: InputMaybe<Order_By>;
  current_reception_id?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
}

/** aggregate variance on columns */
export interface Visit_Variance_Fields {
  __typename?: 'Visit_variance_fields';
  client_id?: Maybe<Scalars['Float']['output']>;
  current_reception_id?: Maybe<Scalars['Float']['output']>;
  department_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  patient_id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "Visit" */
export interface Visit_Variance_Order_By {
  client_id?: InputMaybe<Order_By>;
  current_reception_id?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
}

/** ordering argument of a cursor */
export type Cursor_Ordering =
  /** ascending ordering of the cursor */
  | 'ASC'
  /** descending ordering of the cursor */
  | 'DESC';

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export interface Date_Comparison_Exp {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
}

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export interface Float8_Comparison_Exp {
  _eq?: InputMaybe<Scalars['float8']['input']>;
  _gt?: InputMaybe<Scalars['float8']['input']>;
  _gte?: InputMaybe<Scalars['float8']['input']>;
  _in?: InputMaybe<Array<Scalars['float8']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['float8']['input']>;
  _lte?: InputMaybe<Scalars['float8']['input']>;
  _neq?: InputMaybe<Scalars['float8']['input']>;
  _nin?: InputMaybe<Array<Scalars['float8']['input']>>;
}

export interface Jsonb_Cast_Exp {
  String?: InputMaybe<String_Comparison_Exp>;
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export interface Jsonb_Comparison_Exp {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
}

/** mutation root */
export interface Mutation_Root {
  __typename?: 'mutation_root';
  /** delete data from the table: "ChatMessages" */
  delete_ChatMessages?: Maybe<ChatMessages_Mutation_Response>;
  /** delete single row from the table: "ChatMessages" */
  delete_ChatMessages_by_pk?: Maybe<ChatMessages>;
  /** delete data from the table: "Client" */
  delete_Client?: Maybe<Client_Mutation_Response>;
  /** delete single row from the table: "Client" */
  delete_Client_by_pk?: Maybe<Client>;
  /** delete data from the table: "Department" */
  delete_Department?: Maybe<Department_Mutation_Response>;
  /** delete data from the table: "DepartmentDocument" */
  delete_DepartmentDocument?: Maybe<DepartmentDocument_Mutation_Response>;
  /** delete single row from the table: "DepartmentDocument" */
  delete_DepartmentDocument_by_pk?: Maybe<DepartmentDocument>;
  /** delete single row from the table: "Department" */
  delete_Department_by_pk?: Maybe<Department>;
  /** delete data from the table: "DoctorsCard" */
  delete_DoctorsCard?: Maybe<DoctorsCard_Mutation_Response>;
  /** delete single row from the table: "DoctorsCard" */
  delete_DoctorsCard_by_pk?: Maybe<DoctorsCard>;
  /** delete data from the table: "DoctorsDepartment" */
  delete_DoctorsDepartment?: Maybe<DoctorsDepartment_Mutation_Response>;
  /** delete single row from the table: "DoctorsDepartment" */
  delete_DoctorsDepartment_by_pk?: Maybe<DoctorsDepartment>;
  /** delete data from the table: "Documents" */
  delete_Documents?: Maybe<Documents_Mutation_Response>;
  /** delete single row from the table: "Documents" */
  delete_Documents_by_pk?: Maybe<Documents>;
  /** delete data from the table: "Medicine" */
  delete_Medicine?: Maybe<Medicine_Mutation_Response>;
  /** delete data from the table: "MedicineUnit" */
  delete_MedicineUnit?: Maybe<MedicineUnit_Mutation_Response>;
  /** delete single row from the table: "MedicineUnit" */
  delete_MedicineUnit_by_pk?: Maybe<MedicineUnit>;
  /** delete single row from the table: "Medicine" */
  delete_Medicine_by_pk?: Maybe<Medicine>;
  /** delete data from the table: "Pet" */
  delete_Pet?: Maybe<Pet_Mutation_Response>;
  /** delete data from the table: "PetType" */
  delete_PetType?: Maybe<PetType_Mutation_Response>;
  /** delete single row from the table: "PetType" */
  delete_PetType_by_pk?: Maybe<PetType>;
  /** delete single row from the table: "Pet" */
  delete_Pet_by_pk?: Maybe<Pet>;
  /** delete data from the table: "Reception" */
  delete_Reception?: Maybe<Reception_Mutation_Response>;
  /** delete data from the table: "ReceptionMedicines" */
  delete_ReceptionMedicines?: Maybe<ReceptionMedicines_Mutation_Response>;
  /** delete single row from the table: "ReceptionMedicines" */
  delete_ReceptionMedicines_by_pk?: Maybe<ReceptionMedicines>;
  /** delete data from the table: "ReceptionServices" */
  delete_ReceptionServices?: Maybe<ReceptionServices_Mutation_Response>;
  /** delete single row from the table: "ReceptionServices" */
  delete_ReceptionServices_by_pk?: Maybe<ReceptionServices>;
  /** delete single row from the table: "Reception" */
  delete_Reception_by_pk?: Maybe<Reception>;
  /** delete data from the table: "RegistrationDocument" */
  delete_RegistrationDocument?: Maybe<RegistrationDocument_Mutation_Response>;
  /** delete single row from the table: "RegistrationDocument" */
  delete_RegistrationDocument_by_pk?: Maybe<RegistrationDocument>;
  /** delete data from the table: "Roles" */
  delete_Roles?: Maybe<Roles_Mutation_Response>;
  /** delete single row from the table: "Roles" */
  delete_Roles_by_pk?: Maybe<Roles>;
  /** delete data from the table: "Schema" */
  delete_Schema?: Maybe<Schema_Mutation_Response>;
  /** delete data from the table: "SchemaCategory" */
  delete_SchemaCategory?: Maybe<SchemaCategory_Mutation_Response>;
  /** delete single row from the table: "SchemaCategory" */
  delete_SchemaCategory_by_pk?: Maybe<SchemaCategory>;
  /** delete data from the table: "SchemaMedicine" */
  delete_SchemaMedicine?: Maybe<SchemaMedicine_Mutation_Response>;
  /** delete single row from the table: "SchemaMedicine" */
  delete_SchemaMedicine_by_pk?: Maybe<SchemaMedicine>;
  /** delete data from the table: "SchemaService" */
  delete_SchemaService?: Maybe<SchemaService_Mutation_Response>;
  /** delete single row from the table: "SchemaService" */
  delete_SchemaService_by_pk?: Maybe<SchemaService>;
  /** delete single row from the table: "Schema" */
  delete_Schema_by_pk?: Maybe<Schema>;
  /** delete data from the table: "Service" */
  delete_Service?: Maybe<Service_Mutation_Response>;
  /** delete single row from the table: "Service" */
  delete_Service_by_pk?: Maybe<Service>;
  /** delete data from the table: "Template" */
  delete_Template?: Maybe<Template_Mutation_Response>;
  /** delete data from the table: "TemplateMedicines" */
  delete_TemplateMedicines?: Maybe<TemplateMedicines_Mutation_Response>;
  /** delete single row from the table: "TemplateMedicines" */
  delete_TemplateMedicines_by_pk?: Maybe<TemplateMedicines>;
  /** delete data from the table: "TemplateServices" */
  delete_TemplateServices?: Maybe<TemplateServices_Mutation_Response>;
  /** delete single row from the table: "TemplateServices" */
  delete_TemplateServices_by_pk?: Maybe<TemplateServices>;
  /** delete single row from the table: "Template" */
  delete_Template_by_pk?: Maybe<Template>;
  /** delete data from the table: "UserMedicinePayments" */
  delete_UserMedicinePayments?: Maybe<UserMedicinePayments_Mutation_Response>;
  /** delete single row from the table: "UserMedicinePayments" */
  delete_UserMedicinePayments_by_pk?: Maybe<UserMedicinePayments>;
  /** delete data from the table: "UserServicePayments" */
  delete_UserServicePayments?: Maybe<UserServicePayments_Mutation_Response>;
  /** delete single row from the table: "UserServicePayments" */
  delete_UserServicePayments_by_pk?: Maybe<UserServicePayments>;
  /** delete data from the table: "UserUT" */
  delete_UserUT?: Maybe<UserUt_Mutation_Response>;
  /** delete single row from the table: "UserUT" */
  delete_UserUT_by_pk?: Maybe<UserUt>;
  /** delete data from the table: "Users" */
  delete_Users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "Users" */
  delete_Users_by_pk?: Maybe<Users>;
  /** delete data from the table: "Visit" */
  delete_Visit?: Maybe<Visit_Mutation_Response>;
  /** delete data from the table: "VisitDocument" */
  delete_VisitDocument?: Maybe<VisitDocument_Mutation_Response>;
  /** delete single row from the table: "VisitDocument" */
  delete_VisitDocument_by_pk?: Maybe<VisitDocument>;
  /** delete data from the table: "VisitFiles" */
  delete_VisitFiles?: Maybe<VisitFiles_Mutation_Response>;
  /** delete single row from the table: "VisitFiles" */
  delete_VisitFiles_by_pk?: Maybe<VisitFiles>;
  /** delete single row from the table: "Visit" */
  delete_Visit_by_pk?: Maybe<Visit>;
  /** insert data into the table: "ChatMessages" */
  insert_ChatMessages?: Maybe<ChatMessages_Mutation_Response>;
  /** insert a single row into the table: "ChatMessages" */
  insert_ChatMessages_one?: Maybe<ChatMessages>;
  /** insert data into the table: "Client" */
  insert_Client?: Maybe<Client_Mutation_Response>;
  /** insert a single row into the table: "Client" */
  insert_Client_one?: Maybe<Client>;
  /** insert data into the table: "Department" */
  insert_Department?: Maybe<Department_Mutation_Response>;
  /** insert data into the table: "DepartmentDocument" */
  insert_DepartmentDocument?: Maybe<DepartmentDocument_Mutation_Response>;
  /** insert a single row into the table: "DepartmentDocument" */
  insert_DepartmentDocument_one?: Maybe<DepartmentDocument>;
  /** insert a single row into the table: "Department" */
  insert_Department_one?: Maybe<Department>;
  /** insert data into the table: "DoctorsCard" */
  insert_DoctorsCard?: Maybe<DoctorsCard_Mutation_Response>;
  /** insert a single row into the table: "DoctorsCard" */
  insert_DoctorsCard_one?: Maybe<DoctorsCard>;
  /** insert data into the table: "DoctorsDepartment" */
  insert_DoctorsDepartment?: Maybe<DoctorsDepartment_Mutation_Response>;
  /** insert a single row into the table: "DoctorsDepartment" */
  insert_DoctorsDepartment_one?: Maybe<DoctorsDepartment>;
  /** insert data into the table: "Documents" */
  insert_Documents?: Maybe<Documents_Mutation_Response>;
  /** insert a single row into the table: "Documents" */
  insert_Documents_one?: Maybe<Documents>;
  /** insert data into the table: "Medicine" */
  insert_Medicine?: Maybe<Medicine_Mutation_Response>;
  /** insert data into the table: "MedicineUnit" */
  insert_MedicineUnit?: Maybe<MedicineUnit_Mutation_Response>;
  /** insert a single row into the table: "MedicineUnit" */
  insert_MedicineUnit_one?: Maybe<MedicineUnit>;
  /** insert a single row into the table: "Medicine" */
  insert_Medicine_one?: Maybe<Medicine>;
  /** insert data into the table: "Pet" */
  insert_Pet?: Maybe<Pet_Mutation_Response>;
  /** insert data into the table: "PetType" */
  insert_PetType?: Maybe<PetType_Mutation_Response>;
  /** insert a single row into the table: "PetType" */
  insert_PetType_one?: Maybe<PetType>;
  /** insert a single row into the table: "Pet" */
  insert_Pet_one?: Maybe<Pet>;
  /** insert data into the table: "Reception" */
  insert_Reception?: Maybe<Reception_Mutation_Response>;
  /** insert data into the table: "ReceptionMedicines" */
  insert_ReceptionMedicines?: Maybe<ReceptionMedicines_Mutation_Response>;
  /** insert a single row into the table: "ReceptionMedicines" */
  insert_ReceptionMedicines_one?: Maybe<ReceptionMedicines>;
  /** insert data into the table: "ReceptionServices" */
  insert_ReceptionServices?: Maybe<ReceptionServices_Mutation_Response>;
  /** insert a single row into the table: "ReceptionServices" */
  insert_ReceptionServices_one?: Maybe<ReceptionServices>;
  /** insert a single row into the table: "Reception" */
  insert_Reception_one?: Maybe<Reception>;
  /** insert data into the table: "RegistrationDocument" */
  insert_RegistrationDocument?: Maybe<RegistrationDocument_Mutation_Response>;
  /** insert a single row into the table: "RegistrationDocument" */
  insert_RegistrationDocument_one?: Maybe<RegistrationDocument>;
  /** insert data into the table: "Roles" */
  insert_Roles?: Maybe<Roles_Mutation_Response>;
  /** insert a single row into the table: "Roles" */
  insert_Roles_one?: Maybe<Roles>;
  /** insert data into the table: "Schema" */
  insert_Schema?: Maybe<Schema_Mutation_Response>;
  /** insert data into the table: "SchemaCategory" */
  insert_SchemaCategory?: Maybe<SchemaCategory_Mutation_Response>;
  /** insert a single row into the table: "SchemaCategory" */
  insert_SchemaCategory_one?: Maybe<SchemaCategory>;
  /** insert data into the table: "SchemaMedicine" */
  insert_SchemaMedicine?: Maybe<SchemaMedicine_Mutation_Response>;
  /** insert a single row into the table: "SchemaMedicine" */
  insert_SchemaMedicine_one?: Maybe<SchemaMedicine>;
  /** insert data into the table: "SchemaService" */
  insert_SchemaService?: Maybe<SchemaService_Mutation_Response>;
  /** insert a single row into the table: "SchemaService" */
  insert_SchemaService_one?: Maybe<SchemaService>;
  /** insert a single row into the table: "Schema" */
  insert_Schema_one?: Maybe<Schema>;
  /** insert data into the table: "Service" */
  insert_Service?: Maybe<Service_Mutation_Response>;
  /** insert a single row into the table: "Service" */
  insert_Service_one?: Maybe<Service>;
  /** insert data into the table: "Template" */
  insert_Template?: Maybe<Template_Mutation_Response>;
  /** insert data into the table: "TemplateMedicines" */
  insert_TemplateMedicines?: Maybe<TemplateMedicines_Mutation_Response>;
  /** insert a single row into the table: "TemplateMedicines" */
  insert_TemplateMedicines_one?: Maybe<TemplateMedicines>;
  /** insert data into the table: "TemplateServices" */
  insert_TemplateServices?: Maybe<TemplateServices_Mutation_Response>;
  /** insert a single row into the table: "TemplateServices" */
  insert_TemplateServices_one?: Maybe<TemplateServices>;
  /** insert a single row into the table: "Template" */
  insert_Template_one?: Maybe<Template>;
  /** insert data into the table: "UserMedicinePayments" */
  insert_UserMedicinePayments?: Maybe<UserMedicinePayments_Mutation_Response>;
  /** insert a single row into the table: "UserMedicinePayments" */
  insert_UserMedicinePayments_one?: Maybe<UserMedicinePayments>;
  /** insert data into the table: "UserServicePayments" */
  insert_UserServicePayments?: Maybe<UserServicePayments_Mutation_Response>;
  /** insert a single row into the table: "UserServicePayments" */
  insert_UserServicePayments_one?: Maybe<UserServicePayments>;
  /** insert data into the table: "UserUT" */
  insert_UserUT?: Maybe<UserUt_Mutation_Response>;
  /** insert a single row into the table: "UserUT" */
  insert_UserUT_one?: Maybe<UserUt>;
  /** insert data into the table: "Users" */
  insert_Users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "Users" */
  insert_Users_one?: Maybe<Users>;
  /** insert data into the table: "Visit" */
  insert_Visit?: Maybe<Visit_Mutation_Response>;
  /** insert data into the table: "VisitDocument" */
  insert_VisitDocument?: Maybe<VisitDocument_Mutation_Response>;
  /** insert a single row into the table: "VisitDocument" */
  insert_VisitDocument_one?: Maybe<VisitDocument>;
  /** insert data into the table: "VisitFiles" */
  insert_VisitFiles?: Maybe<VisitFiles_Mutation_Response>;
  /** insert a single row into the table: "VisitFiles" */
  insert_VisitFiles_one?: Maybe<VisitFiles>;
  /** insert a single row into the table: "Visit" */
  insert_Visit_one?: Maybe<Visit>;
  /** update data of the table: "ChatMessages" */
  update_ChatMessages?: Maybe<ChatMessages_Mutation_Response>;
  /** update single row of the table: "ChatMessages" */
  update_ChatMessages_by_pk?: Maybe<ChatMessages>;
  /** update multiples rows of table: "ChatMessages" */
  update_ChatMessages_many?: Maybe<Array<Maybe<ChatMessages_Mutation_Response>>>;
  /** update data of the table: "Client" */
  update_Client?: Maybe<Client_Mutation_Response>;
  /** update single row of the table: "Client" */
  update_Client_by_pk?: Maybe<Client>;
  /** update multiples rows of table: "Client" */
  update_Client_many?: Maybe<Array<Maybe<Client_Mutation_Response>>>;
  /** update data of the table: "Department" */
  update_Department?: Maybe<Department_Mutation_Response>;
  /** update data of the table: "DepartmentDocument" */
  update_DepartmentDocument?: Maybe<DepartmentDocument_Mutation_Response>;
  /** update single row of the table: "DepartmentDocument" */
  update_DepartmentDocument_by_pk?: Maybe<DepartmentDocument>;
  /** update multiples rows of table: "DepartmentDocument" */
  update_DepartmentDocument_many?: Maybe<Array<Maybe<DepartmentDocument_Mutation_Response>>>;
  /** update single row of the table: "Department" */
  update_Department_by_pk?: Maybe<Department>;
  /** update multiples rows of table: "Department" */
  update_Department_many?: Maybe<Array<Maybe<Department_Mutation_Response>>>;
  /** update data of the table: "DoctorsCard" */
  update_DoctorsCard?: Maybe<DoctorsCard_Mutation_Response>;
  /** update single row of the table: "DoctorsCard" */
  update_DoctorsCard_by_pk?: Maybe<DoctorsCard>;
  /** update multiples rows of table: "DoctorsCard" */
  update_DoctorsCard_many?: Maybe<Array<Maybe<DoctorsCard_Mutation_Response>>>;
  /** update data of the table: "DoctorsDepartment" */
  update_DoctorsDepartment?: Maybe<DoctorsDepartment_Mutation_Response>;
  /** update single row of the table: "DoctorsDepartment" */
  update_DoctorsDepartment_by_pk?: Maybe<DoctorsDepartment>;
  /** update multiples rows of table: "DoctorsDepartment" */
  update_DoctorsDepartment_many?: Maybe<Array<Maybe<DoctorsDepartment_Mutation_Response>>>;
  /** update data of the table: "Documents" */
  update_Documents?: Maybe<Documents_Mutation_Response>;
  /** update single row of the table: "Documents" */
  update_Documents_by_pk?: Maybe<Documents>;
  /** update multiples rows of table: "Documents" */
  update_Documents_many?: Maybe<Array<Maybe<Documents_Mutation_Response>>>;
  /** update data of the table: "Medicine" */
  update_Medicine?: Maybe<Medicine_Mutation_Response>;
  /** update data of the table: "MedicineUnit" */
  update_MedicineUnit?: Maybe<MedicineUnit_Mutation_Response>;
  /** update single row of the table: "MedicineUnit" */
  update_MedicineUnit_by_pk?: Maybe<MedicineUnit>;
  /** update multiples rows of table: "MedicineUnit" */
  update_MedicineUnit_many?: Maybe<Array<Maybe<MedicineUnit_Mutation_Response>>>;
  /** update single row of the table: "Medicine" */
  update_Medicine_by_pk?: Maybe<Medicine>;
  /** update multiples rows of table: "Medicine" */
  update_Medicine_many?: Maybe<Array<Maybe<Medicine_Mutation_Response>>>;
  /** update data of the table: "Pet" */
  update_Pet?: Maybe<Pet_Mutation_Response>;
  /** update data of the table: "PetType" */
  update_PetType?: Maybe<PetType_Mutation_Response>;
  /** update single row of the table: "PetType" */
  update_PetType_by_pk?: Maybe<PetType>;
  /** update multiples rows of table: "PetType" */
  update_PetType_many?: Maybe<Array<Maybe<PetType_Mutation_Response>>>;
  /** update single row of the table: "Pet" */
  update_Pet_by_pk?: Maybe<Pet>;
  /** update multiples rows of table: "Pet" */
  update_Pet_many?: Maybe<Array<Maybe<Pet_Mutation_Response>>>;
  /** update data of the table: "Reception" */
  update_Reception?: Maybe<Reception_Mutation_Response>;
  /** update data of the table: "ReceptionMedicines" */
  update_ReceptionMedicines?: Maybe<ReceptionMedicines_Mutation_Response>;
  /** update single row of the table: "ReceptionMedicines" */
  update_ReceptionMedicines_by_pk?: Maybe<ReceptionMedicines>;
  /** update multiples rows of table: "ReceptionMedicines" */
  update_ReceptionMedicines_many?: Maybe<Array<Maybe<ReceptionMedicines_Mutation_Response>>>;
  /** update data of the table: "ReceptionServices" */
  update_ReceptionServices?: Maybe<ReceptionServices_Mutation_Response>;
  /** update single row of the table: "ReceptionServices" */
  update_ReceptionServices_by_pk?: Maybe<ReceptionServices>;
  /** update multiples rows of table: "ReceptionServices" */
  update_ReceptionServices_many?: Maybe<Array<Maybe<ReceptionServices_Mutation_Response>>>;
  /** update single row of the table: "Reception" */
  update_Reception_by_pk?: Maybe<Reception>;
  /** update multiples rows of table: "Reception" */
  update_Reception_many?: Maybe<Array<Maybe<Reception_Mutation_Response>>>;
  /** update data of the table: "RegistrationDocument" */
  update_RegistrationDocument?: Maybe<RegistrationDocument_Mutation_Response>;
  /** update single row of the table: "RegistrationDocument" */
  update_RegistrationDocument_by_pk?: Maybe<RegistrationDocument>;
  /** update multiples rows of table: "RegistrationDocument" */
  update_RegistrationDocument_many?: Maybe<Array<Maybe<RegistrationDocument_Mutation_Response>>>;
  /** update data of the table: "Roles" */
  update_Roles?: Maybe<Roles_Mutation_Response>;
  /** update single row of the table: "Roles" */
  update_Roles_by_pk?: Maybe<Roles>;
  /** update multiples rows of table: "Roles" */
  update_Roles_many?: Maybe<Array<Maybe<Roles_Mutation_Response>>>;
  /** update data of the table: "Schema" */
  update_Schema?: Maybe<Schema_Mutation_Response>;
  /** update data of the table: "SchemaCategory" */
  update_SchemaCategory?: Maybe<SchemaCategory_Mutation_Response>;
  /** update single row of the table: "SchemaCategory" */
  update_SchemaCategory_by_pk?: Maybe<SchemaCategory>;
  /** update multiples rows of table: "SchemaCategory" */
  update_SchemaCategory_many?: Maybe<Array<Maybe<SchemaCategory_Mutation_Response>>>;
  /** update data of the table: "SchemaMedicine" */
  update_SchemaMedicine?: Maybe<SchemaMedicine_Mutation_Response>;
  /** update single row of the table: "SchemaMedicine" */
  update_SchemaMedicine_by_pk?: Maybe<SchemaMedicine>;
  /** update multiples rows of table: "SchemaMedicine" */
  update_SchemaMedicine_many?: Maybe<Array<Maybe<SchemaMedicine_Mutation_Response>>>;
  /** update data of the table: "SchemaService" */
  update_SchemaService?: Maybe<SchemaService_Mutation_Response>;
  /** update single row of the table: "SchemaService" */
  update_SchemaService_by_pk?: Maybe<SchemaService>;
  /** update multiples rows of table: "SchemaService" */
  update_SchemaService_many?: Maybe<Array<Maybe<SchemaService_Mutation_Response>>>;
  /** update single row of the table: "Schema" */
  update_Schema_by_pk?: Maybe<Schema>;
  /** update multiples rows of table: "Schema" */
  update_Schema_many?: Maybe<Array<Maybe<Schema_Mutation_Response>>>;
  /** update data of the table: "Service" */
  update_Service?: Maybe<Service_Mutation_Response>;
  /** update single row of the table: "Service" */
  update_Service_by_pk?: Maybe<Service>;
  /** update multiples rows of table: "Service" */
  update_Service_many?: Maybe<Array<Maybe<Service_Mutation_Response>>>;
  /** update data of the table: "Template" */
  update_Template?: Maybe<Template_Mutation_Response>;
  /** update data of the table: "TemplateMedicines" */
  update_TemplateMedicines?: Maybe<TemplateMedicines_Mutation_Response>;
  /** update single row of the table: "TemplateMedicines" */
  update_TemplateMedicines_by_pk?: Maybe<TemplateMedicines>;
  /** update multiples rows of table: "TemplateMedicines" */
  update_TemplateMedicines_many?: Maybe<Array<Maybe<TemplateMedicines_Mutation_Response>>>;
  /** update data of the table: "TemplateServices" */
  update_TemplateServices?: Maybe<TemplateServices_Mutation_Response>;
  /** update single row of the table: "TemplateServices" */
  update_TemplateServices_by_pk?: Maybe<TemplateServices>;
  /** update multiples rows of table: "TemplateServices" */
  update_TemplateServices_many?: Maybe<Array<Maybe<TemplateServices_Mutation_Response>>>;
  /** update single row of the table: "Template" */
  update_Template_by_pk?: Maybe<Template>;
  /** update multiples rows of table: "Template" */
  update_Template_many?: Maybe<Array<Maybe<Template_Mutation_Response>>>;
  /** update data of the table: "UserMedicinePayments" */
  update_UserMedicinePayments?: Maybe<UserMedicinePayments_Mutation_Response>;
  /** update single row of the table: "UserMedicinePayments" */
  update_UserMedicinePayments_by_pk?: Maybe<UserMedicinePayments>;
  /** update multiples rows of table: "UserMedicinePayments" */
  update_UserMedicinePayments_many?: Maybe<Array<Maybe<UserMedicinePayments_Mutation_Response>>>;
  /** update data of the table: "UserServicePayments" */
  update_UserServicePayments?: Maybe<UserServicePayments_Mutation_Response>;
  /** update single row of the table: "UserServicePayments" */
  update_UserServicePayments_by_pk?: Maybe<UserServicePayments>;
  /** update multiples rows of table: "UserServicePayments" */
  update_UserServicePayments_many?: Maybe<Array<Maybe<UserServicePayments_Mutation_Response>>>;
  /** update data of the table: "UserUT" */
  update_UserUT?: Maybe<UserUt_Mutation_Response>;
  /** update single row of the table: "UserUT" */
  update_UserUT_by_pk?: Maybe<UserUt>;
  /** update multiples rows of table: "UserUT" */
  update_UserUT_many?: Maybe<Array<Maybe<UserUt_Mutation_Response>>>;
  /** update data of the table: "Users" */
  update_Users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "Users" */
  update_Users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "Users" */
  update_Users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "Visit" */
  update_Visit?: Maybe<Visit_Mutation_Response>;
  /** update data of the table: "VisitDocument" */
  update_VisitDocument?: Maybe<VisitDocument_Mutation_Response>;
  /** update single row of the table: "VisitDocument" */
  update_VisitDocument_by_pk?: Maybe<VisitDocument>;
  /** update multiples rows of table: "VisitDocument" */
  update_VisitDocument_many?: Maybe<Array<Maybe<VisitDocument_Mutation_Response>>>;
  /** update data of the table: "VisitFiles" */
  update_VisitFiles?: Maybe<VisitFiles_Mutation_Response>;
  /** update single row of the table: "VisitFiles" */
  update_VisitFiles_by_pk?: Maybe<VisitFiles>;
  /** update multiples rows of table: "VisitFiles" */
  update_VisitFiles_many?: Maybe<Array<Maybe<VisitFiles_Mutation_Response>>>;
  /** update single row of the table: "Visit" */
  update_Visit_by_pk?: Maybe<Visit>;
  /** update multiples rows of table: "Visit" */
  update_Visit_many?: Maybe<Array<Maybe<Visit_Mutation_Response>>>;
}


/** mutation root */
export interface Mutation_RootDelete_ChatMessagesArgs {
  where: ChatMessages_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_ChatMessages_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_ClientArgs {
  where: Client_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Client_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_DepartmentArgs {
  where: Department_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_DepartmentDocumentArgs {
  where: DepartmentDocument_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_DepartmentDocument_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Department_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_DoctorsCardArgs {
  where: DoctorsCard_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_DoctorsCard_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_DoctorsDepartmentArgs {
  where: DoctorsDepartment_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_DoctorsDepartment_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_DocumentsArgs {
  where: Documents_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Documents_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_MedicineArgs {
  where: Medicine_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_MedicineUnitArgs {
  where: MedicineUnit_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_MedicineUnit_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Medicine_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_PetArgs {
  where: Pet_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_PetTypeArgs {
  where: PetType_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_PetType_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Pet_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_ReceptionArgs {
  where: Reception_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_ReceptionMedicinesArgs {
  where: ReceptionMedicines_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_ReceptionMedicines_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_ReceptionServicesArgs {
  where: ReceptionServices_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_ReceptionServices_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Reception_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_RegistrationDocumentArgs {
  where: RegistrationDocument_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_RegistrationDocument_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_RolesArgs {
  where: Roles_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Roles_By_PkArgs {
  ID: Scalars['uuid']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_SchemaArgs {
  where: Schema_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_SchemaCategoryArgs {
  where: SchemaCategory_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_SchemaCategory_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_SchemaMedicineArgs {
  where: SchemaMedicine_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_SchemaMedicine_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_SchemaServiceArgs {
  where: SchemaService_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_SchemaService_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Schema_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_ServiceArgs {
  where: Service_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Service_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_TemplateArgs {
  where: Template_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_TemplateMedicinesArgs {
  where: TemplateMedicines_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_TemplateMedicines_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_TemplateServicesArgs {
  where: TemplateServices_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_TemplateServices_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Template_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_UserMedicinePaymentsArgs {
  where: UserMedicinePayments_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_UserMedicinePayments_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_UserServicePaymentsArgs {
  where: UserServicePayments_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_UserServicePayments_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_UserUtArgs {
  where: UserUt_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_UserUt_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_UsersArgs {
  where: Users_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Users_By_PkArgs {
  ID: Scalars['uuid']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_VisitArgs {
  where: Visit_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_VisitDocumentArgs {
  where: VisitDocument_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_VisitDocument_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_VisitFilesArgs {
  where: VisitFiles_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_VisitFiles_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Visit_By_PkArgs {
  id: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootInsert_ChatMessagesArgs {
  objects: Array<ChatMessages_Insert_Input>;
  on_conflict?: InputMaybe<ChatMessages_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_ChatMessages_OneArgs {
  object: ChatMessages_Insert_Input;
  on_conflict?: InputMaybe<ChatMessages_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_ClientArgs {
  objects: Array<Client_Insert_Input>;
  on_conflict?: InputMaybe<Client_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Client_OneArgs {
  object: Client_Insert_Input;
  on_conflict?: InputMaybe<Client_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_DepartmentArgs {
  objects: Array<Department_Insert_Input>;
  on_conflict?: InputMaybe<Department_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_DepartmentDocumentArgs {
  objects: Array<DepartmentDocument_Insert_Input>;
  on_conflict?: InputMaybe<DepartmentDocument_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_DepartmentDocument_OneArgs {
  object: DepartmentDocument_Insert_Input;
  on_conflict?: InputMaybe<DepartmentDocument_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Department_OneArgs {
  object: Department_Insert_Input;
  on_conflict?: InputMaybe<Department_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_DoctorsCardArgs {
  objects: Array<DoctorsCard_Insert_Input>;
  on_conflict?: InputMaybe<DoctorsCard_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_DoctorsCard_OneArgs {
  object: DoctorsCard_Insert_Input;
  on_conflict?: InputMaybe<DoctorsCard_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_DoctorsDepartmentArgs {
  objects: Array<DoctorsDepartment_Insert_Input>;
  on_conflict?: InputMaybe<DoctorsDepartment_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_DoctorsDepartment_OneArgs {
  object: DoctorsDepartment_Insert_Input;
  on_conflict?: InputMaybe<DoctorsDepartment_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_DocumentsArgs {
  objects: Array<Documents_Insert_Input>;
  on_conflict?: InputMaybe<Documents_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Documents_OneArgs {
  object: Documents_Insert_Input;
  on_conflict?: InputMaybe<Documents_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_MedicineArgs {
  objects: Array<Medicine_Insert_Input>;
  on_conflict?: InputMaybe<Medicine_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_MedicineUnitArgs {
  objects: Array<MedicineUnit_Insert_Input>;
  on_conflict?: InputMaybe<MedicineUnit_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_MedicineUnit_OneArgs {
  object: MedicineUnit_Insert_Input;
  on_conflict?: InputMaybe<MedicineUnit_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Medicine_OneArgs {
  object: Medicine_Insert_Input;
  on_conflict?: InputMaybe<Medicine_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_PetArgs {
  objects: Array<Pet_Insert_Input>;
  on_conflict?: InputMaybe<Pet_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_PetTypeArgs {
  objects: Array<PetType_Insert_Input>;
  on_conflict?: InputMaybe<PetType_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_PetType_OneArgs {
  object: PetType_Insert_Input;
  on_conflict?: InputMaybe<PetType_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Pet_OneArgs {
  object: Pet_Insert_Input;
  on_conflict?: InputMaybe<Pet_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_ReceptionArgs {
  objects: Array<Reception_Insert_Input>;
  on_conflict?: InputMaybe<Reception_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_ReceptionMedicinesArgs {
  objects: Array<ReceptionMedicines_Insert_Input>;
  on_conflict?: InputMaybe<ReceptionMedicines_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_ReceptionMedicines_OneArgs {
  object: ReceptionMedicines_Insert_Input;
  on_conflict?: InputMaybe<ReceptionMedicines_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_ReceptionServicesArgs {
  objects: Array<ReceptionServices_Insert_Input>;
  on_conflict?: InputMaybe<ReceptionServices_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_ReceptionServices_OneArgs {
  object: ReceptionServices_Insert_Input;
  on_conflict?: InputMaybe<ReceptionServices_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Reception_OneArgs {
  object: Reception_Insert_Input;
  on_conflict?: InputMaybe<Reception_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_RegistrationDocumentArgs {
  objects: Array<RegistrationDocument_Insert_Input>;
  on_conflict?: InputMaybe<RegistrationDocument_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_RegistrationDocument_OneArgs {
  object: RegistrationDocument_Insert_Input;
  on_conflict?: InputMaybe<RegistrationDocument_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_RolesArgs {
  objects: Array<Roles_Insert_Input>;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Roles_OneArgs {
  object: Roles_Insert_Input;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_SchemaArgs {
  objects: Array<Schema_Insert_Input>;
  on_conflict?: InputMaybe<Schema_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_SchemaCategoryArgs {
  objects: Array<SchemaCategory_Insert_Input>;
  on_conflict?: InputMaybe<SchemaCategory_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_SchemaCategory_OneArgs {
  object: SchemaCategory_Insert_Input;
  on_conflict?: InputMaybe<SchemaCategory_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_SchemaMedicineArgs {
  objects: Array<SchemaMedicine_Insert_Input>;
  on_conflict?: InputMaybe<SchemaMedicine_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_SchemaMedicine_OneArgs {
  object: SchemaMedicine_Insert_Input;
  on_conflict?: InputMaybe<SchemaMedicine_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_SchemaServiceArgs {
  objects: Array<SchemaService_Insert_Input>;
  on_conflict?: InputMaybe<SchemaService_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_SchemaService_OneArgs {
  object: SchemaService_Insert_Input;
  on_conflict?: InputMaybe<SchemaService_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Schema_OneArgs {
  object: Schema_Insert_Input;
  on_conflict?: InputMaybe<Schema_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_ServiceArgs {
  objects: Array<Service_Insert_Input>;
  on_conflict?: InputMaybe<Service_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Service_OneArgs {
  object: Service_Insert_Input;
  on_conflict?: InputMaybe<Service_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_TemplateArgs {
  objects: Array<Template_Insert_Input>;
  on_conflict?: InputMaybe<Template_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_TemplateMedicinesArgs {
  objects: Array<TemplateMedicines_Insert_Input>;
  on_conflict?: InputMaybe<TemplateMedicines_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_TemplateMedicines_OneArgs {
  object: TemplateMedicines_Insert_Input;
  on_conflict?: InputMaybe<TemplateMedicines_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_TemplateServicesArgs {
  objects: Array<TemplateServices_Insert_Input>;
  on_conflict?: InputMaybe<TemplateServices_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_TemplateServices_OneArgs {
  object: TemplateServices_Insert_Input;
  on_conflict?: InputMaybe<TemplateServices_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Template_OneArgs {
  object: Template_Insert_Input;
  on_conflict?: InputMaybe<Template_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_UserMedicinePaymentsArgs {
  objects: Array<UserMedicinePayments_Insert_Input>;
  on_conflict?: InputMaybe<UserMedicinePayments_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_UserMedicinePayments_OneArgs {
  object: UserMedicinePayments_Insert_Input;
  on_conflict?: InputMaybe<UserMedicinePayments_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_UserServicePaymentsArgs {
  objects: Array<UserServicePayments_Insert_Input>;
  on_conflict?: InputMaybe<UserServicePayments_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_UserServicePayments_OneArgs {
  object: UserServicePayments_Insert_Input;
  on_conflict?: InputMaybe<UserServicePayments_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_UserUtArgs {
  objects: Array<UserUt_Insert_Input>;
  on_conflict?: InputMaybe<UserUt_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_UserUt_OneArgs {
  object: UserUt_Insert_Input;
  on_conflict?: InputMaybe<UserUt_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_UsersArgs {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Users_OneArgs {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_VisitArgs {
  objects: Array<Visit_Insert_Input>;
  on_conflict?: InputMaybe<Visit_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_VisitDocumentArgs {
  objects: Array<VisitDocument_Insert_Input>;
  on_conflict?: InputMaybe<VisitDocument_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_VisitDocument_OneArgs {
  object: VisitDocument_Insert_Input;
  on_conflict?: InputMaybe<VisitDocument_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_VisitFilesArgs {
  objects: Array<VisitFiles_Insert_Input>;
  on_conflict?: InputMaybe<VisitFiles_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_VisitFiles_OneArgs {
  object: VisitFiles_Insert_Input;
  on_conflict?: InputMaybe<VisitFiles_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Visit_OneArgs {
  object: Visit_Insert_Input;
  on_conflict?: InputMaybe<Visit_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootUpdate_ChatMessagesArgs {
  _inc?: InputMaybe<ChatMessages_Inc_Input>;
  _set?: InputMaybe<ChatMessages_Set_Input>;
  where: ChatMessages_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_ChatMessages_By_PkArgs {
  _inc?: InputMaybe<ChatMessages_Inc_Input>;
  _set?: InputMaybe<ChatMessages_Set_Input>;
  pk_columns: ChatMessages_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_ChatMessages_ManyArgs {
  updates: Array<ChatMessages_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_ClientArgs {
  _inc?: InputMaybe<Client_Inc_Input>;
  _set?: InputMaybe<Client_Set_Input>;
  where: Client_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Client_By_PkArgs {
  _inc?: InputMaybe<Client_Inc_Input>;
  _set?: InputMaybe<Client_Set_Input>;
  pk_columns: Client_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Client_ManyArgs {
  updates: Array<Client_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_DepartmentArgs {
  _inc?: InputMaybe<Department_Inc_Input>;
  _set?: InputMaybe<Department_Set_Input>;
  where: Department_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_DepartmentDocumentArgs {
  _inc?: InputMaybe<DepartmentDocument_Inc_Input>;
  _set?: InputMaybe<DepartmentDocument_Set_Input>;
  where: DepartmentDocument_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_DepartmentDocument_By_PkArgs {
  _inc?: InputMaybe<DepartmentDocument_Inc_Input>;
  _set?: InputMaybe<DepartmentDocument_Set_Input>;
  pk_columns: DepartmentDocument_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_DepartmentDocument_ManyArgs {
  updates: Array<DepartmentDocument_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Department_By_PkArgs {
  _inc?: InputMaybe<Department_Inc_Input>;
  _set?: InputMaybe<Department_Set_Input>;
  pk_columns: Department_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Department_ManyArgs {
  updates: Array<Department_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_DoctorsCardArgs {
  _inc?: InputMaybe<DoctorsCard_Inc_Input>;
  _set?: InputMaybe<DoctorsCard_Set_Input>;
  where: DoctorsCard_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_DoctorsCard_By_PkArgs {
  _inc?: InputMaybe<DoctorsCard_Inc_Input>;
  _set?: InputMaybe<DoctorsCard_Set_Input>;
  pk_columns: DoctorsCard_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_DoctorsCard_ManyArgs {
  updates: Array<DoctorsCard_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_DoctorsDepartmentArgs {
  _inc?: InputMaybe<DoctorsDepartment_Inc_Input>;
  _set?: InputMaybe<DoctorsDepartment_Set_Input>;
  where: DoctorsDepartment_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_DoctorsDepartment_By_PkArgs {
  _inc?: InputMaybe<DoctorsDepartment_Inc_Input>;
  _set?: InputMaybe<DoctorsDepartment_Set_Input>;
  pk_columns: DoctorsDepartment_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_DoctorsDepartment_ManyArgs {
  updates: Array<DoctorsDepartment_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_DocumentsArgs {
  _append?: InputMaybe<Documents_Append_Input>;
  _delete_at_path?: InputMaybe<Documents_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Documents_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Documents_Delete_Key_Input>;
  _inc?: InputMaybe<Documents_Inc_Input>;
  _prepend?: InputMaybe<Documents_Prepend_Input>;
  _set?: InputMaybe<Documents_Set_Input>;
  where: Documents_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Documents_By_PkArgs {
  _append?: InputMaybe<Documents_Append_Input>;
  _delete_at_path?: InputMaybe<Documents_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Documents_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Documents_Delete_Key_Input>;
  _inc?: InputMaybe<Documents_Inc_Input>;
  _prepend?: InputMaybe<Documents_Prepend_Input>;
  _set?: InputMaybe<Documents_Set_Input>;
  pk_columns: Documents_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Documents_ManyArgs {
  updates: Array<Documents_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_MedicineArgs {
  _inc?: InputMaybe<Medicine_Inc_Input>;
  _set?: InputMaybe<Medicine_Set_Input>;
  where: Medicine_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_MedicineUnitArgs {
  _inc?: InputMaybe<MedicineUnit_Inc_Input>;
  _set?: InputMaybe<MedicineUnit_Set_Input>;
  where: MedicineUnit_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_MedicineUnit_By_PkArgs {
  _inc?: InputMaybe<MedicineUnit_Inc_Input>;
  _set?: InputMaybe<MedicineUnit_Set_Input>;
  pk_columns: MedicineUnit_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_MedicineUnit_ManyArgs {
  updates: Array<MedicineUnit_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Medicine_By_PkArgs {
  _inc?: InputMaybe<Medicine_Inc_Input>;
  _set?: InputMaybe<Medicine_Set_Input>;
  pk_columns: Medicine_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Medicine_ManyArgs {
  updates: Array<Medicine_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_PetArgs {
  _inc?: InputMaybe<Pet_Inc_Input>;
  _set?: InputMaybe<Pet_Set_Input>;
  where: Pet_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_PetTypeArgs {
  _inc?: InputMaybe<PetType_Inc_Input>;
  _set?: InputMaybe<PetType_Set_Input>;
  where: PetType_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_PetType_By_PkArgs {
  _inc?: InputMaybe<PetType_Inc_Input>;
  _set?: InputMaybe<PetType_Set_Input>;
  pk_columns: PetType_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_PetType_ManyArgs {
  updates: Array<PetType_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Pet_By_PkArgs {
  _inc?: InputMaybe<Pet_Inc_Input>;
  _set?: InputMaybe<Pet_Set_Input>;
  pk_columns: Pet_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Pet_ManyArgs {
  updates: Array<Pet_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_ReceptionArgs {
  _inc?: InputMaybe<Reception_Inc_Input>;
  _set?: InputMaybe<Reception_Set_Input>;
  where: Reception_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_ReceptionMedicinesArgs {
  _inc?: InputMaybe<ReceptionMedicines_Inc_Input>;
  _set?: InputMaybe<ReceptionMedicines_Set_Input>;
  where: ReceptionMedicines_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_ReceptionMedicines_By_PkArgs {
  _inc?: InputMaybe<ReceptionMedicines_Inc_Input>;
  _set?: InputMaybe<ReceptionMedicines_Set_Input>;
  pk_columns: ReceptionMedicines_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_ReceptionMedicines_ManyArgs {
  updates: Array<ReceptionMedicines_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_ReceptionServicesArgs {
  _inc?: InputMaybe<ReceptionServices_Inc_Input>;
  _set?: InputMaybe<ReceptionServices_Set_Input>;
  where: ReceptionServices_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_ReceptionServices_By_PkArgs {
  _inc?: InputMaybe<ReceptionServices_Inc_Input>;
  _set?: InputMaybe<ReceptionServices_Set_Input>;
  pk_columns: ReceptionServices_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_ReceptionServices_ManyArgs {
  updates: Array<ReceptionServices_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Reception_By_PkArgs {
  _inc?: InputMaybe<Reception_Inc_Input>;
  _set?: InputMaybe<Reception_Set_Input>;
  pk_columns: Reception_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Reception_ManyArgs {
  updates: Array<Reception_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_RegistrationDocumentArgs {
  _inc?: InputMaybe<RegistrationDocument_Inc_Input>;
  _set?: InputMaybe<RegistrationDocument_Set_Input>;
  where: RegistrationDocument_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_RegistrationDocument_By_PkArgs {
  _inc?: InputMaybe<RegistrationDocument_Inc_Input>;
  _set?: InputMaybe<RegistrationDocument_Set_Input>;
  pk_columns: RegistrationDocument_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_RegistrationDocument_ManyArgs {
  updates: Array<RegistrationDocument_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_RolesArgs {
  _set?: InputMaybe<Roles_Set_Input>;
  where: Roles_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Roles_By_PkArgs {
  _set?: InputMaybe<Roles_Set_Input>;
  pk_columns: Roles_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Roles_ManyArgs {
  updates: Array<Roles_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_SchemaArgs {
  _inc?: InputMaybe<Schema_Inc_Input>;
  _set?: InputMaybe<Schema_Set_Input>;
  where: Schema_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_SchemaCategoryArgs {
  _inc?: InputMaybe<SchemaCategory_Inc_Input>;
  _set?: InputMaybe<SchemaCategory_Set_Input>;
  where: SchemaCategory_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_SchemaCategory_By_PkArgs {
  _inc?: InputMaybe<SchemaCategory_Inc_Input>;
  _set?: InputMaybe<SchemaCategory_Set_Input>;
  pk_columns: SchemaCategory_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_SchemaCategory_ManyArgs {
  updates: Array<SchemaCategory_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_SchemaMedicineArgs {
  _inc?: InputMaybe<SchemaMedicine_Inc_Input>;
  _set?: InputMaybe<SchemaMedicine_Set_Input>;
  where: SchemaMedicine_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_SchemaMedicine_By_PkArgs {
  _inc?: InputMaybe<SchemaMedicine_Inc_Input>;
  _set?: InputMaybe<SchemaMedicine_Set_Input>;
  pk_columns: SchemaMedicine_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_SchemaMedicine_ManyArgs {
  updates: Array<SchemaMedicine_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_SchemaServiceArgs {
  _inc?: InputMaybe<SchemaService_Inc_Input>;
  _set?: InputMaybe<SchemaService_Set_Input>;
  where: SchemaService_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_SchemaService_By_PkArgs {
  _inc?: InputMaybe<SchemaService_Inc_Input>;
  _set?: InputMaybe<SchemaService_Set_Input>;
  pk_columns: SchemaService_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_SchemaService_ManyArgs {
  updates: Array<SchemaService_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Schema_By_PkArgs {
  _inc?: InputMaybe<Schema_Inc_Input>;
  _set?: InputMaybe<Schema_Set_Input>;
  pk_columns: Schema_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Schema_ManyArgs {
  updates: Array<Schema_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_ServiceArgs {
  _inc?: InputMaybe<Service_Inc_Input>;
  _set?: InputMaybe<Service_Set_Input>;
  where: Service_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Service_By_PkArgs {
  _inc?: InputMaybe<Service_Inc_Input>;
  _set?: InputMaybe<Service_Set_Input>;
  pk_columns: Service_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Service_ManyArgs {
  updates: Array<Service_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_TemplateArgs {
  _inc?: InputMaybe<Template_Inc_Input>;
  _set?: InputMaybe<Template_Set_Input>;
  where: Template_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_TemplateMedicinesArgs {
  _inc?: InputMaybe<TemplateMedicines_Inc_Input>;
  _set?: InputMaybe<TemplateMedicines_Set_Input>;
  where: TemplateMedicines_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_TemplateMedicines_By_PkArgs {
  _inc?: InputMaybe<TemplateMedicines_Inc_Input>;
  _set?: InputMaybe<TemplateMedicines_Set_Input>;
  pk_columns: TemplateMedicines_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_TemplateMedicines_ManyArgs {
  updates: Array<TemplateMedicines_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_TemplateServicesArgs {
  _inc?: InputMaybe<TemplateServices_Inc_Input>;
  _set?: InputMaybe<TemplateServices_Set_Input>;
  where: TemplateServices_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_TemplateServices_By_PkArgs {
  _inc?: InputMaybe<TemplateServices_Inc_Input>;
  _set?: InputMaybe<TemplateServices_Set_Input>;
  pk_columns: TemplateServices_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_TemplateServices_ManyArgs {
  updates: Array<TemplateServices_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Template_By_PkArgs {
  _inc?: InputMaybe<Template_Inc_Input>;
  _set?: InputMaybe<Template_Set_Input>;
  pk_columns: Template_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Template_ManyArgs {
  updates: Array<Template_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_UserMedicinePaymentsArgs {
  _inc?: InputMaybe<UserMedicinePayments_Inc_Input>;
  _set?: InputMaybe<UserMedicinePayments_Set_Input>;
  where: UserMedicinePayments_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_UserMedicinePayments_By_PkArgs {
  _inc?: InputMaybe<UserMedicinePayments_Inc_Input>;
  _set?: InputMaybe<UserMedicinePayments_Set_Input>;
  pk_columns: UserMedicinePayments_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_UserMedicinePayments_ManyArgs {
  updates: Array<UserMedicinePayments_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_UserServicePaymentsArgs {
  _inc?: InputMaybe<UserServicePayments_Inc_Input>;
  _set?: InputMaybe<UserServicePayments_Set_Input>;
  where: UserServicePayments_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_UserServicePayments_By_PkArgs {
  _inc?: InputMaybe<UserServicePayments_Inc_Input>;
  _set?: InputMaybe<UserServicePayments_Set_Input>;
  pk_columns: UserServicePayments_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_UserServicePayments_ManyArgs {
  updates: Array<UserServicePayments_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_UserUtArgs {
  _inc?: InputMaybe<UserUt_Inc_Input>;
  _set?: InputMaybe<UserUt_Set_Input>;
  where: UserUt_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_UserUt_By_PkArgs {
  _inc?: InputMaybe<UserUt_Inc_Input>;
  _set?: InputMaybe<UserUt_Set_Input>;
  pk_columns: UserUt_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_UserUt_ManyArgs {
  updates: Array<UserUt_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_UsersArgs {
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Users_By_PkArgs {
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Users_ManyArgs {
  updates: Array<Users_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_VisitArgs {
  _append?: InputMaybe<Visit_Append_Input>;
  _delete_at_path?: InputMaybe<Visit_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Visit_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Visit_Delete_Key_Input>;
  _inc?: InputMaybe<Visit_Inc_Input>;
  _prepend?: InputMaybe<Visit_Prepend_Input>;
  _set?: InputMaybe<Visit_Set_Input>;
  where: Visit_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_VisitDocumentArgs {
  _inc?: InputMaybe<VisitDocument_Inc_Input>;
  _set?: InputMaybe<VisitDocument_Set_Input>;
  where: VisitDocument_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_VisitDocument_By_PkArgs {
  _inc?: InputMaybe<VisitDocument_Inc_Input>;
  _set?: InputMaybe<VisitDocument_Set_Input>;
  pk_columns: VisitDocument_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_VisitDocument_ManyArgs {
  updates: Array<VisitDocument_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_VisitFilesArgs {
  _inc?: InputMaybe<VisitFiles_Inc_Input>;
  _set?: InputMaybe<VisitFiles_Set_Input>;
  where: VisitFiles_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_VisitFiles_By_PkArgs {
  _inc?: InputMaybe<VisitFiles_Inc_Input>;
  _set?: InputMaybe<VisitFiles_Set_Input>;
  pk_columns: VisitFiles_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_VisitFiles_ManyArgs {
  updates: Array<VisitFiles_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Visit_By_PkArgs {
  _append?: InputMaybe<Visit_Append_Input>;
  _delete_at_path?: InputMaybe<Visit_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Visit_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Visit_Delete_Key_Input>;
  _inc?: InputMaybe<Visit_Inc_Input>;
  _prepend?: InputMaybe<Visit_Prepend_Input>;
  _set?: InputMaybe<Visit_Set_Input>;
  pk_columns: Visit_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Visit_ManyArgs {
  updates: Array<Visit_Updates>;
}

/** column ordering options */
export type Order_By =
  /** in ascending order, nulls last */
  | 'asc'
  /** in ascending order, nulls first */
  | 'asc_nulls_first'
  /** in ascending order, nulls last */
  | 'asc_nulls_last'
  /** in descending order, nulls first */
  | 'desc'
  /** in descending order, nulls first */
  | 'desc_nulls_first'
  /** in descending order, nulls last */
  | 'desc_nulls_last';

export interface Query_Root {
  __typename?: 'query_root';
  /** fetch data from the table: "ChatMessages" */
  ChatMessages: Array<ChatMessages>;
  /** fetch aggregated fields from the table: "ChatMessages" */
  ChatMessages_aggregate: ChatMessages_Aggregate;
  /** fetch data from the table: "ChatMessages" using primary key columns */
  ChatMessages_by_pk?: Maybe<ChatMessages>;
  /** fetch data from the table: "Client" */
  Client: Array<Client>;
  /** fetch aggregated fields from the table: "Client" */
  Client_aggregate: Client_Aggregate;
  /** fetch data from the table: "Client" using primary key columns */
  Client_by_pk?: Maybe<Client>;
  /** fetch data from the table: "Department" */
  Department: Array<Department>;
  /** fetch data from the table: "DepartmentDocument" */
  DepartmentDocument: Array<DepartmentDocument>;
  /** fetch aggregated fields from the table: "DepartmentDocument" */
  DepartmentDocument_aggregate: DepartmentDocument_Aggregate;
  /** fetch data from the table: "DepartmentDocument" using primary key columns */
  DepartmentDocument_by_pk?: Maybe<DepartmentDocument>;
  /** fetch aggregated fields from the table: "Department" */
  Department_aggregate: Department_Aggregate;
  /** fetch data from the table: "Department" using primary key columns */
  Department_by_pk?: Maybe<Department>;
  /** fetch data from the table: "DoctorsCard" */
  DoctorsCard: Array<DoctorsCard>;
  /** fetch aggregated fields from the table: "DoctorsCard" */
  DoctorsCard_aggregate: DoctorsCard_Aggregate;
  /** fetch data from the table: "DoctorsCard" using primary key columns */
  DoctorsCard_by_pk?: Maybe<DoctorsCard>;
  /** fetch data from the table: "DoctorsDepartment" */
  DoctorsDepartment: Array<DoctorsDepartment>;
  /** fetch aggregated fields from the table: "DoctorsDepartment" */
  DoctorsDepartment_aggregate: DoctorsDepartment_Aggregate;
  /** fetch data from the table: "DoctorsDepartment" using primary key columns */
  DoctorsDepartment_by_pk?: Maybe<DoctorsDepartment>;
  /** fetch data from the table: "Documents" */
  Documents: Array<Documents>;
  /** fetch aggregated fields from the table: "Documents" */
  Documents_aggregate: Documents_Aggregate;
  /** fetch data from the table: "Documents" using primary key columns */
  Documents_by_pk?: Maybe<Documents>;
  /** fetch data from the table: "Medicine" */
  Medicine: Array<Medicine>;
  /** fetch data from the table: "MedicineUnit" */
  MedicineUnit: Array<MedicineUnit>;
  /** fetch aggregated fields from the table: "MedicineUnit" */
  MedicineUnit_aggregate: MedicineUnit_Aggregate;
  /** fetch data from the table: "MedicineUnit" using primary key columns */
  MedicineUnit_by_pk?: Maybe<MedicineUnit>;
  /** fetch aggregated fields from the table: "Medicine" */
  Medicine_aggregate: Medicine_Aggregate;
  /** fetch data from the table: "Medicine" using primary key columns */
  Medicine_by_pk?: Maybe<Medicine>;
  /** fetch data from the table: "Pet" */
  Pet: Array<Pet>;
  /** fetch data from the table: "PetType" */
  PetType: Array<PetType>;
  /** fetch aggregated fields from the table: "PetType" */
  PetType_aggregate: PetType_Aggregate;
  /** fetch data from the table: "PetType" using primary key columns */
  PetType_by_pk?: Maybe<PetType>;
  /** fetch aggregated fields from the table: "Pet" */
  Pet_aggregate: Pet_Aggregate;
  /** fetch data from the table: "Pet" using primary key columns */
  Pet_by_pk?: Maybe<Pet>;
  /** fetch data from the table: "Reception" */
  Reception: Array<Reception>;
  /** fetch data from the table: "ReceptionMedicines" */
  ReceptionMedicines: Array<ReceptionMedicines>;
  /** fetch aggregated fields from the table: "ReceptionMedicines" */
  ReceptionMedicines_aggregate: ReceptionMedicines_Aggregate;
  /** fetch data from the table: "ReceptionMedicines" using primary key columns */
  ReceptionMedicines_by_pk?: Maybe<ReceptionMedicines>;
  /** fetch data from the table: "ReceptionServices" */
  ReceptionServices: Array<ReceptionServices>;
  /** fetch aggregated fields from the table: "ReceptionServices" */
  ReceptionServices_aggregate: ReceptionServices_Aggregate;
  /** fetch data from the table: "ReceptionServices" using primary key columns */
  ReceptionServices_by_pk?: Maybe<ReceptionServices>;
  /** fetch aggregated fields from the table: "Reception" */
  Reception_aggregate: Reception_Aggregate;
  /** fetch data from the table: "Reception" using primary key columns */
  Reception_by_pk?: Maybe<Reception>;
  /** fetch data from the table: "RegistrationDocument" */
  RegistrationDocument: Array<RegistrationDocument>;
  /** fetch aggregated fields from the table: "RegistrationDocument" */
  RegistrationDocument_aggregate: RegistrationDocument_Aggregate;
  /** fetch data from the table: "RegistrationDocument" using primary key columns */
  RegistrationDocument_by_pk?: Maybe<RegistrationDocument>;
  /** fetch data from the table: "Roles" */
  Roles: Array<Roles>;
  /** fetch aggregated fields from the table: "Roles" */
  Roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "Roles" using primary key columns */
  Roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table: "Schema" */
  Schema: Array<Schema>;
  /** fetch data from the table: "SchemaCategory" */
  SchemaCategory: Array<SchemaCategory>;
  /** fetch aggregated fields from the table: "SchemaCategory" */
  SchemaCategory_aggregate: SchemaCategory_Aggregate;
  /** fetch data from the table: "SchemaCategory" using primary key columns */
  SchemaCategory_by_pk?: Maybe<SchemaCategory>;
  /** fetch data from the table: "SchemaMedicine" */
  SchemaMedicine: Array<SchemaMedicine>;
  /** fetch aggregated fields from the table: "SchemaMedicine" */
  SchemaMedicine_aggregate: SchemaMedicine_Aggregate;
  /** fetch data from the table: "SchemaMedicine" using primary key columns */
  SchemaMedicine_by_pk?: Maybe<SchemaMedicine>;
  /** fetch data from the table: "SchemaService" */
  SchemaService: Array<SchemaService>;
  /** fetch aggregated fields from the table: "SchemaService" */
  SchemaService_aggregate: SchemaService_Aggregate;
  /** fetch data from the table: "SchemaService" using primary key columns */
  SchemaService_by_pk?: Maybe<SchemaService>;
  /** fetch aggregated fields from the table: "Schema" */
  Schema_aggregate: Schema_Aggregate;
  /** fetch data from the table: "Schema" using primary key columns */
  Schema_by_pk?: Maybe<Schema>;
  /** fetch data from the table: "Service" */
  Service: Array<Service>;
  /** fetch aggregated fields from the table: "Service" */
  Service_aggregate: Service_Aggregate;
  /** fetch data from the table: "Service" using primary key columns */
  Service_by_pk?: Maybe<Service>;
  /** fetch data from the table: "Template" */
  Template: Array<Template>;
  /** An array relationship */
  TemplateMedicines: Array<TemplateMedicines>;
  /** An aggregate relationship */
  TemplateMedicines_aggregate: TemplateMedicines_Aggregate;
  /** fetch data from the table: "TemplateMedicines" using primary key columns */
  TemplateMedicines_by_pk?: Maybe<TemplateMedicines>;
  /** An array relationship */
  TemplateServices: Array<TemplateServices>;
  /** An aggregate relationship */
  TemplateServices_aggregate: TemplateServices_Aggregate;
  /** fetch data from the table: "TemplateServices" using primary key columns */
  TemplateServices_by_pk?: Maybe<TemplateServices>;
  /** fetch aggregated fields from the table: "Template" */
  Template_aggregate: Template_Aggregate;
  /** fetch data from the table: "Template" using primary key columns */
  Template_by_pk?: Maybe<Template>;
  /** An array relationship */
  UserMedicinePayments: Array<UserMedicinePayments>;
  /** An aggregate relationship */
  UserMedicinePayments_aggregate: UserMedicinePayments_Aggregate;
  /** fetch data from the table: "UserMedicinePayments" using primary key columns */
  UserMedicinePayments_by_pk?: Maybe<UserMedicinePayments>;
  /** An array relationship */
  UserServicePayments: Array<UserServicePayments>;
  /** An aggregate relationship */
  UserServicePayments_aggregate: UserServicePayments_Aggregate;
  /** fetch data from the table: "UserServicePayments" using primary key columns */
  UserServicePayments_by_pk?: Maybe<UserServicePayments>;
  /** fetch data from the table: "UserUT" */
  UserUT: Array<UserUt>;
  /** fetch aggregated fields from the table: "UserUT" */
  UserUT_aggregate: UserUt_Aggregate;
  /** fetch data from the table: "UserUT" using primary key columns */
  UserUT_by_pk?: Maybe<UserUt>;
  /** An array relationship */
  Users: Array<Users>;
  /** An aggregate relationship */
  Users_aggregate: Users_Aggregate;
  /** fetch data from the table: "Users" using primary key columns */
  Users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "Visit" */
  Visit: Array<Visit>;
  /** fetch data from the table: "VisitDocument" */
  VisitDocument: Array<VisitDocument>;
  /** fetch aggregated fields from the table: "VisitDocument" */
  VisitDocument_aggregate: VisitDocument_Aggregate;
  /** fetch data from the table: "VisitDocument" using primary key columns */
  VisitDocument_by_pk?: Maybe<VisitDocument>;
  /** An array relationship */
  VisitFiles: Array<VisitFiles>;
  /** An aggregate relationship */
  VisitFiles_aggregate: VisitFiles_Aggregate;
  /** fetch data from the table: "VisitFiles" using primary key columns */
  VisitFiles_by_pk?: Maybe<VisitFiles>;
  /** fetch aggregated fields from the table: "Visit" */
  Visit_aggregate: Visit_Aggregate;
  /** fetch data from the table: "Visit" using primary key columns */
  Visit_by_pk?: Maybe<Visit>;
}


export interface Query_RootChatMessagesArgs {
  distinct_on?: InputMaybe<Array<ChatMessages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChatMessages_Order_By>>;
  where?: InputMaybe<ChatMessages_Bool_Exp>;
}


export interface Query_RootChatMessages_AggregateArgs {
  distinct_on?: InputMaybe<Array<ChatMessages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChatMessages_Order_By>>;
  where?: InputMaybe<ChatMessages_Bool_Exp>;
}


export interface Query_RootChatMessages_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootClientArgs {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
}


export interface Query_RootClient_AggregateArgs {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
}


export interface Query_RootClient_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootDepartmentArgs {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
}


export interface Query_RootDepartmentDocumentArgs {
  distinct_on?: InputMaybe<Array<DepartmentDocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentDocument_Order_By>>;
  where?: InputMaybe<DepartmentDocument_Bool_Exp>;
}


export interface Query_RootDepartmentDocument_AggregateArgs {
  distinct_on?: InputMaybe<Array<DepartmentDocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentDocument_Order_By>>;
  where?: InputMaybe<DepartmentDocument_Bool_Exp>;
}


export interface Query_RootDepartmentDocument_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootDepartment_AggregateArgs {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
}


export interface Query_RootDepartment_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootDoctorsCardArgs {
  distinct_on?: InputMaybe<Array<DoctorsCard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DoctorsCard_Order_By>>;
  where?: InputMaybe<DoctorsCard_Bool_Exp>;
}


export interface Query_RootDoctorsCard_AggregateArgs {
  distinct_on?: InputMaybe<Array<DoctorsCard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DoctorsCard_Order_By>>;
  where?: InputMaybe<DoctorsCard_Bool_Exp>;
}


export interface Query_RootDoctorsCard_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootDoctorsDepartmentArgs {
  distinct_on?: InputMaybe<Array<DoctorsDepartment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DoctorsDepartment_Order_By>>;
  where?: InputMaybe<DoctorsDepartment_Bool_Exp>;
}


export interface Query_RootDoctorsDepartment_AggregateArgs {
  distinct_on?: InputMaybe<Array<DoctorsDepartment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DoctorsDepartment_Order_By>>;
  where?: InputMaybe<DoctorsDepartment_Bool_Exp>;
}


export interface Query_RootDoctorsDepartment_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootDocumentsArgs {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
}


export interface Query_RootDocuments_AggregateArgs {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
}


export interface Query_RootDocuments_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootMedicineArgs {
  distinct_on?: InputMaybe<Array<Medicine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Medicine_Order_By>>;
  where?: InputMaybe<Medicine_Bool_Exp>;
}


export interface Query_RootMedicineUnitArgs {
  distinct_on?: InputMaybe<Array<MedicineUnit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MedicineUnit_Order_By>>;
  where?: InputMaybe<MedicineUnit_Bool_Exp>;
}


export interface Query_RootMedicineUnit_AggregateArgs {
  distinct_on?: InputMaybe<Array<MedicineUnit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MedicineUnit_Order_By>>;
  where?: InputMaybe<MedicineUnit_Bool_Exp>;
}


export interface Query_RootMedicineUnit_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootMedicine_AggregateArgs {
  distinct_on?: InputMaybe<Array<Medicine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Medicine_Order_By>>;
  where?: InputMaybe<Medicine_Bool_Exp>;
}


export interface Query_RootMedicine_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootPetArgs {
  distinct_on?: InputMaybe<Array<Pet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Pet_Order_By>>;
  where?: InputMaybe<Pet_Bool_Exp>;
}


export interface Query_RootPetTypeArgs {
  distinct_on?: InputMaybe<Array<PetType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PetType_Order_By>>;
  where?: InputMaybe<PetType_Bool_Exp>;
}


export interface Query_RootPetType_AggregateArgs {
  distinct_on?: InputMaybe<Array<PetType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PetType_Order_By>>;
  where?: InputMaybe<PetType_Bool_Exp>;
}


export interface Query_RootPetType_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootPet_AggregateArgs {
  distinct_on?: InputMaybe<Array<Pet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Pet_Order_By>>;
  where?: InputMaybe<Pet_Bool_Exp>;
}


export interface Query_RootPet_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootReceptionArgs {
  distinct_on?: InputMaybe<Array<Reception_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reception_Order_By>>;
  where?: InputMaybe<Reception_Bool_Exp>;
}


export interface Query_RootReceptionMedicinesArgs {
  distinct_on?: InputMaybe<Array<ReceptionMedicines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReceptionMedicines_Order_By>>;
  where?: InputMaybe<ReceptionMedicines_Bool_Exp>;
}


export interface Query_RootReceptionMedicines_AggregateArgs {
  distinct_on?: InputMaybe<Array<ReceptionMedicines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReceptionMedicines_Order_By>>;
  where?: InputMaybe<ReceptionMedicines_Bool_Exp>;
}


export interface Query_RootReceptionMedicines_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootReceptionServicesArgs {
  distinct_on?: InputMaybe<Array<ReceptionServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReceptionServices_Order_By>>;
  where?: InputMaybe<ReceptionServices_Bool_Exp>;
}


export interface Query_RootReceptionServices_AggregateArgs {
  distinct_on?: InputMaybe<Array<ReceptionServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReceptionServices_Order_By>>;
  where?: InputMaybe<ReceptionServices_Bool_Exp>;
}


export interface Query_RootReceptionServices_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootReception_AggregateArgs {
  distinct_on?: InputMaybe<Array<Reception_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reception_Order_By>>;
  where?: InputMaybe<Reception_Bool_Exp>;
}


export interface Query_RootReception_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootRegistrationDocumentArgs {
  distinct_on?: InputMaybe<Array<RegistrationDocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RegistrationDocument_Order_By>>;
  where?: InputMaybe<RegistrationDocument_Bool_Exp>;
}


export interface Query_RootRegistrationDocument_AggregateArgs {
  distinct_on?: InputMaybe<Array<RegistrationDocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RegistrationDocument_Order_By>>;
  where?: InputMaybe<RegistrationDocument_Bool_Exp>;
}


export interface Query_RootRegistrationDocument_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootRolesArgs {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
}


export interface Query_RootRoles_AggregateArgs {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
}


export interface Query_RootRoles_By_PkArgs {
  ID: Scalars['uuid']['input'];
}


export interface Query_RootSchemaArgs {
  distinct_on?: InputMaybe<Array<Schema_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Schema_Order_By>>;
  where?: InputMaybe<Schema_Bool_Exp>;
}


export interface Query_RootSchemaCategoryArgs {
  distinct_on?: InputMaybe<Array<SchemaCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SchemaCategory_Order_By>>;
  where?: InputMaybe<SchemaCategory_Bool_Exp>;
}


export interface Query_RootSchemaCategory_AggregateArgs {
  distinct_on?: InputMaybe<Array<SchemaCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SchemaCategory_Order_By>>;
  where?: InputMaybe<SchemaCategory_Bool_Exp>;
}


export interface Query_RootSchemaCategory_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootSchemaMedicineArgs {
  distinct_on?: InputMaybe<Array<SchemaMedicine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SchemaMedicine_Order_By>>;
  where?: InputMaybe<SchemaMedicine_Bool_Exp>;
}


export interface Query_RootSchemaMedicine_AggregateArgs {
  distinct_on?: InputMaybe<Array<SchemaMedicine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SchemaMedicine_Order_By>>;
  where?: InputMaybe<SchemaMedicine_Bool_Exp>;
}


export interface Query_RootSchemaMedicine_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootSchemaServiceArgs {
  distinct_on?: InputMaybe<Array<SchemaService_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SchemaService_Order_By>>;
  where?: InputMaybe<SchemaService_Bool_Exp>;
}


export interface Query_RootSchemaService_AggregateArgs {
  distinct_on?: InputMaybe<Array<SchemaService_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SchemaService_Order_By>>;
  where?: InputMaybe<SchemaService_Bool_Exp>;
}


export interface Query_RootSchemaService_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootSchema_AggregateArgs {
  distinct_on?: InputMaybe<Array<Schema_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Schema_Order_By>>;
  where?: InputMaybe<Schema_Bool_Exp>;
}


export interface Query_RootSchema_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootServiceArgs {
  distinct_on?: InputMaybe<Array<Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Service_Order_By>>;
  where?: InputMaybe<Service_Bool_Exp>;
}


export interface Query_RootService_AggregateArgs {
  distinct_on?: InputMaybe<Array<Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Service_Order_By>>;
  where?: InputMaybe<Service_Bool_Exp>;
}


export interface Query_RootService_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootTemplateArgs {
  distinct_on?: InputMaybe<Array<Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Order_By>>;
  where?: InputMaybe<Template_Bool_Exp>;
}


export interface Query_RootTemplateMedicinesArgs {
  distinct_on?: InputMaybe<Array<TemplateMedicines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TemplateMedicines_Order_By>>;
  where?: InputMaybe<TemplateMedicines_Bool_Exp>;
}


export interface Query_RootTemplateMedicines_AggregateArgs {
  distinct_on?: InputMaybe<Array<TemplateMedicines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TemplateMedicines_Order_By>>;
  where?: InputMaybe<TemplateMedicines_Bool_Exp>;
}


export interface Query_RootTemplateMedicines_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootTemplateServicesArgs {
  distinct_on?: InputMaybe<Array<TemplateServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TemplateServices_Order_By>>;
  where?: InputMaybe<TemplateServices_Bool_Exp>;
}


export interface Query_RootTemplateServices_AggregateArgs {
  distinct_on?: InputMaybe<Array<TemplateServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TemplateServices_Order_By>>;
  where?: InputMaybe<TemplateServices_Bool_Exp>;
}


export interface Query_RootTemplateServices_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootTemplate_AggregateArgs {
  distinct_on?: InputMaybe<Array<Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Order_By>>;
  where?: InputMaybe<Template_Bool_Exp>;
}


export interface Query_RootTemplate_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootUserMedicinePaymentsArgs {
  distinct_on?: InputMaybe<Array<UserMedicinePayments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserMedicinePayments_Order_By>>;
  where?: InputMaybe<UserMedicinePayments_Bool_Exp>;
}


export interface Query_RootUserMedicinePayments_AggregateArgs {
  distinct_on?: InputMaybe<Array<UserMedicinePayments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserMedicinePayments_Order_By>>;
  where?: InputMaybe<UserMedicinePayments_Bool_Exp>;
}


export interface Query_RootUserMedicinePayments_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootUserServicePaymentsArgs {
  distinct_on?: InputMaybe<Array<UserServicePayments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserServicePayments_Order_By>>;
  where?: InputMaybe<UserServicePayments_Bool_Exp>;
}


export interface Query_RootUserServicePayments_AggregateArgs {
  distinct_on?: InputMaybe<Array<UserServicePayments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserServicePayments_Order_By>>;
  where?: InputMaybe<UserServicePayments_Bool_Exp>;
}


export interface Query_RootUserServicePayments_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootUserUtArgs {
  distinct_on?: InputMaybe<Array<UserUt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserUt_Order_By>>;
  where?: InputMaybe<UserUt_Bool_Exp>;
}


export interface Query_RootUserUt_AggregateArgs {
  distinct_on?: InputMaybe<Array<UserUt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserUt_Order_By>>;
  where?: InputMaybe<UserUt_Bool_Exp>;
}


export interface Query_RootUserUt_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootUsersArgs {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
}


export interface Query_RootUsers_AggregateArgs {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
}


export interface Query_RootUsers_By_PkArgs {
  ID: Scalars['uuid']['input'];
}


export interface Query_RootVisitArgs {
  distinct_on?: InputMaybe<Array<Visit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Visit_Order_By>>;
  where?: InputMaybe<Visit_Bool_Exp>;
}


export interface Query_RootVisitDocumentArgs {
  distinct_on?: InputMaybe<Array<VisitDocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VisitDocument_Order_By>>;
  where?: InputMaybe<VisitDocument_Bool_Exp>;
}


export interface Query_RootVisitDocument_AggregateArgs {
  distinct_on?: InputMaybe<Array<VisitDocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VisitDocument_Order_By>>;
  where?: InputMaybe<VisitDocument_Bool_Exp>;
}


export interface Query_RootVisitDocument_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootVisitFilesArgs {
  distinct_on?: InputMaybe<Array<VisitFiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VisitFiles_Order_By>>;
  where?: InputMaybe<VisitFiles_Bool_Exp>;
}


export interface Query_RootVisitFiles_AggregateArgs {
  distinct_on?: InputMaybe<Array<VisitFiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VisitFiles_Order_By>>;
  where?: InputMaybe<VisitFiles_Bool_Exp>;
}


export interface Query_RootVisitFiles_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Query_RootVisit_AggregateArgs {
  distinct_on?: InputMaybe<Array<Visit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Visit_Order_By>>;
  where?: InputMaybe<Visit_Bool_Exp>;
}


export interface Query_RootVisit_By_PkArgs {
  id: Scalars['Int']['input'];
}

export interface Subscription_Root {
  __typename?: 'subscription_root';
  /** fetch data from the table: "ChatMessages" */
  ChatMessages: Array<ChatMessages>;
  /** fetch aggregated fields from the table: "ChatMessages" */
  ChatMessages_aggregate: ChatMessages_Aggregate;
  /** fetch data from the table: "ChatMessages" using primary key columns */
  ChatMessages_by_pk?: Maybe<ChatMessages>;
  /** fetch data from the table in a streaming manner: "ChatMessages" */
  ChatMessages_stream: Array<ChatMessages>;
  /** fetch data from the table: "Client" */
  Client: Array<Client>;
  /** fetch aggregated fields from the table: "Client" */
  Client_aggregate: Client_Aggregate;
  /** fetch data from the table: "Client" using primary key columns */
  Client_by_pk?: Maybe<Client>;
  /** fetch data from the table in a streaming manner: "Client" */
  Client_stream: Array<Client>;
  /** fetch data from the table: "Department" */
  Department: Array<Department>;
  /** fetch data from the table: "DepartmentDocument" */
  DepartmentDocument: Array<DepartmentDocument>;
  /** fetch aggregated fields from the table: "DepartmentDocument" */
  DepartmentDocument_aggregate: DepartmentDocument_Aggregate;
  /** fetch data from the table: "DepartmentDocument" using primary key columns */
  DepartmentDocument_by_pk?: Maybe<DepartmentDocument>;
  /** fetch data from the table in a streaming manner: "DepartmentDocument" */
  DepartmentDocument_stream: Array<DepartmentDocument>;
  /** fetch aggregated fields from the table: "Department" */
  Department_aggregate: Department_Aggregate;
  /** fetch data from the table: "Department" using primary key columns */
  Department_by_pk?: Maybe<Department>;
  /** fetch data from the table in a streaming manner: "Department" */
  Department_stream: Array<Department>;
  /** fetch data from the table: "DoctorsCard" */
  DoctorsCard: Array<DoctorsCard>;
  /** fetch aggregated fields from the table: "DoctorsCard" */
  DoctorsCard_aggregate: DoctorsCard_Aggregate;
  /** fetch data from the table: "DoctorsCard" using primary key columns */
  DoctorsCard_by_pk?: Maybe<DoctorsCard>;
  /** fetch data from the table in a streaming manner: "DoctorsCard" */
  DoctorsCard_stream: Array<DoctorsCard>;
  /** fetch data from the table: "DoctorsDepartment" */
  DoctorsDepartment: Array<DoctorsDepartment>;
  /** fetch aggregated fields from the table: "DoctorsDepartment" */
  DoctorsDepartment_aggregate: DoctorsDepartment_Aggregate;
  /** fetch data from the table: "DoctorsDepartment" using primary key columns */
  DoctorsDepartment_by_pk?: Maybe<DoctorsDepartment>;
  /** fetch data from the table in a streaming manner: "DoctorsDepartment" */
  DoctorsDepartment_stream: Array<DoctorsDepartment>;
  /** fetch data from the table: "Documents" */
  Documents: Array<Documents>;
  /** fetch aggregated fields from the table: "Documents" */
  Documents_aggregate: Documents_Aggregate;
  /** fetch data from the table: "Documents" using primary key columns */
  Documents_by_pk?: Maybe<Documents>;
  /** fetch data from the table in a streaming manner: "Documents" */
  Documents_stream: Array<Documents>;
  /** fetch data from the table: "Medicine" */
  Medicine: Array<Medicine>;
  /** fetch data from the table: "MedicineUnit" */
  MedicineUnit: Array<MedicineUnit>;
  /** fetch aggregated fields from the table: "MedicineUnit" */
  MedicineUnit_aggregate: MedicineUnit_Aggregate;
  /** fetch data from the table: "MedicineUnit" using primary key columns */
  MedicineUnit_by_pk?: Maybe<MedicineUnit>;
  /** fetch data from the table in a streaming manner: "MedicineUnit" */
  MedicineUnit_stream: Array<MedicineUnit>;
  /** fetch aggregated fields from the table: "Medicine" */
  Medicine_aggregate: Medicine_Aggregate;
  /** fetch data from the table: "Medicine" using primary key columns */
  Medicine_by_pk?: Maybe<Medicine>;
  /** fetch data from the table in a streaming manner: "Medicine" */
  Medicine_stream: Array<Medicine>;
  /** fetch data from the table: "Pet" */
  Pet: Array<Pet>;
  /** fetch data from the table: "PetType" */
  PetType: Array<PetType>;
  /** fetch aggregated fields from the table: "PetType" */
  PetType_aggregate: PetType_Aggregate;
  /** fetch data from the table: "PetType" using primary key columns */
  PetType_by_pk?: Maybe<PetType>;
  /** fetch data from the table in a streaming manner: "PetType" */
  PetType_stream: Array<PetType>;
  /** fetch aggregated fields from the table: "Pet" */
  Pet_aggregate: Pet_Aggregate;
  /** fetch data from the table: "Pet" using primary key columns */
  Pet_by_pk?: Maybe<Pet>;
  /** fetch data from the table in a streaming manner: "Pet" */
  Pet_stream: Array<Pet>;
  /** fetch data from the table: "Reception" */
  Reception: Array<Reception>;
  /** fetch data from the table: "ReceptionMedicines" */
  ReceptionMedicines: Array<ReceptionMedicines>;
  /** fetch aggregated fields from the table: "ReceptionMedicines" */
  ReceptionMedicines_aggregate: ReceptionMedicines_Aggregate;
  /** fetch data from the table: "ReceptionMedicines" using primary key columns */
  ReceptionMedicines_by_pk?: Maybe<ReceptionMedicines>;
  /** fetch data from the table in a streaming manner: "ReceptionMedicines" */
  ReceptionMedicines_stream: Array<ReceptionMedicines>;
  /** fetch data from the table: "ReceptionServices" */
  ReceptionServices: Array<ReceptionServices>;
  /** fetch aggregated fields from the table: "ReceptionServices" */
  ReceptionServices_aggregate: ReceptionServices_Aggregate;
  /** fetch data from the table: "ReceptionServices" using primary key columns */
  ReceptionServices_by_pk?: Maybe<ReceptionServices>;
  /** fetch data from the table in a streaming manner: "ReceptionServices" */
  ReceptionServices_stream: Array<ReceptionServices>;
  /** fetch aggregated fields from the table: "Reception" */
  Reception_aggregate: Reception_Aggregate;
  /** fetch data from the table: "Reception" using primary key columns */
  Reception_by_pk?: Maybe<Reception>;
  /** fetch data from the table in a streaming manner: "Reception" */
  Reception_stream: Array<Reception>;
  /** fetch data from the table: "RegistrationDocument" */
  RegistrationDocument: Array<RegistrationDocument>;
  /** fetch aggregated fields from the table: "RegistrationDocument" */
  RegistrationDocument_aggregate: RegistrationDocument_Aggregate;
  /** fetch data from the table: "RegistrationDocument" using primary key columns */
  RegistrationDocument_by_pk?: Maybe<RegistrationDocument>;
  /** fetch data from the table in a streaming manner: "RegistrationDocument" */
  RegistrationDocument_stream: Array<RegistrationDocument>;
  /** fetch data from the table: "Roles" */
  Roles: Array<Roles>;
  /** fetch aggregated fields from the table: "Roles" */
  Roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "Roles" using primary key columns */
  Roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table in a streaming manner: "Roles" */
  Roles_stream: Array<Roles>;
  /** fetch data from the table: "Schema" */
  Schema: Array<Schema>;
  /** fetch data from the table: "SchemaCategory" */
  SchemaCategory: Array<SchemaCategory>;
  /** fetch aggregated fields from the table: "SchemaCategory" */
  SchemaCategory_aggregate: SchemaCategory_Aggregate;
  /** fetch data from the table: "SchemaCategory" using primary key columns */
  SchemaCategory_by_pk?: Maybe<SchemaCategory>;
  /** fetch data from the table in a streaming manner: "SchemaCategory" */
  SchemaCategory_stream: Array<SchemaCategory>;
  /** fetch data from the table: "SchemaMedicine" */
  SchemaMedicine: Array<SchemaMedicine>;
  /** fetch aggregated fields from the table: "SchemaMedicine" */
  SchemaMedicine_aggregate: SchemaMedicine_Aggregate;
  /** fetch data from the table: "SchemaMedicine" using primary key columns */
  SchemaMedicine_by_pk?: Maybe<SchemaMedicine>;
  /** fetch data from the table in a streaming manner: "SchemaMedicine" */
  SchemaMedicine_stream: Array<SchemaMedicine>;
  /** fetch data from the table: "SchemaService" */
  SchemaService: Array<SchemaService>;
  /** fetch aggregated fields from the table: "SchemaService" */
  SchemaService_aggregate: SchemaService_Aggregate;
  /** fetch data from the table: "SchemaService" using primary key columns */
  SchemaService_by_pk?: Maybe<SchemaService>;
  /** fetch data from the table in a streaming manner: "SchemaService" */
  SchemaService_stream: Array<SchemaService>;
  /** fetch aggregated fields from the table: "Schema" */
  Schema_aggregate: Schema_Aggregate;
  /** fetch data from the table: "Schema" using primary key columns */
  Schema_by_pk?: Maybe<Schema>;
  /** fetch data from the table in a streaming manner: "Schema" */
  Schema_stream: Array<Schema>;
  /** fetch data from the table: "Service" */
  Service: Array<Service>;
  /** fetch aggregated fields from the table: "Service" */
  Service_aggregate: Service_Aggregate;
  /** fetch data from the table: "Service" using primary key columns */
  Service_by_pk?: Maybe<Service>;
  /** fetch data from the table in a streaming manner: "Service" */
  Service_stream: Array<Service>;
  /** fetch data from the table: "Template" */
  Template: Array<Template>;
  /** An array relationship */
  TemplateMedicines: Array<TemplateMedicines>;
  /** An aggregate relationship */
  TemplateMedicines_aggregate: TemplateMedicines_Aggregate;
  /** fetch data from the table: "TemplateMedicines" using primary key columns */
  TemplateMedicines_by_pk?: Maybe<TemplateMedicines>;
  /** fetch data from the table in a streaming manner: "TemplateMedicines" */
  TemplateMedicines_stream: Array<TemplateMedicines>;
  /** An array relationship */
  TemplateServices: Array<TemplateServices>;
  /** An aggregate relationship */
  TemplateServices_aggregate: TemplateServices_Aggregate;
  /** fetch data from the table: "TemplateServices" using primary key columns */
  TemplateServices_by_pk?: Maybe<TemplateServices>;
  /** fetch data from the table in a streaming manner: "TemplateServices" */
  TemplateServices_stream: Array<TemplateServices>;
  /** fetch aggregated fields from the table: "Template" */
  Template_aggregate: Template_Aggregate;
  /** fetch data from the table: "Template" using primary key columns */
  Template_by_pk?: Maybe<Template>;
  /** fetch data from the table in a streaming manner: "Template" */
  Template_stream: Array<Template>;
  /** An array relationship */
  UserMedicinePayments: Array<UserMedicinePayments>;
  /** An aggregate relationship */
  UserMedicinePayments_aggregate: UserMedicinePayments_Aggregate;
  /** fetch data from the table: "UserMedicinePayments" using primary key columns */
  UserMedicinePayments_by_pk?: Maybe<UserMedicinePayments>;
  /** fetch data from the table in a streaming manner: "UserMedicinePayments" */
  UserMedicinePayments_stream: Array<UserMedicinePayments>;
  /** An array relationship */
  UserServicePayments: Array<UserServicePayments>;
  /** An aggregate relationship */
  UserServicePayments_aggregate: UserServicePayments_Aggregate;
  /** fetch data from the table: "UserServicePayments" using primary key columns */
  UserServicePayments_by_pk?: Maybe<UserServicePayments>;
  /** fetch data from the table in a streaming manner: "UserServicePayments" */
  UserServicePayments_stream: Array<UserServicePayments>;
  /** fetch data from the table: "UserUT" */
  UserUT: Array<UserUt>;
  /** fetch aggregated fields from the table: "UserUT" */
  UserUT_aggregate: UserUt_Aggregate;
  /** fetch data from the table: "UserUT" using primary key columns */
  UserUT_by_pk?: Maybe<UserUt>;
  /** fetch data from the table in a streaming manner: "UserUT" */
  UserUT_stream: Array<UserUt>;
  /** An array relationship */
  Users: Array<Users>;
  /** An aggregate relationship */
  Users_aggregate: Users_Aggregate;
  /** fetch data from the table: "Users" using primary key columns */
  Users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "Users" */
  Users_stream: Array<Users>;
  /** fetch data from the table: "Visit" */
  Visit: Array<Visit>;
  /** fetch data from the table: "VisitDocument" */
  VisitDocument: Array<VisitDocument>;
  /** fetch aggregated fields from the table: "VisitDocument" */
  VisitDocument_aggregate: VisitDocument_Aggregate;
  /** fetch data from the table: "VisitDocument" using primary key columns */
  VisitDocument_by_pk?: Maybe<VisitDocument>;
  /** fetch data from the table in a streaming manner: "VisitDocument" */
  VisitDocument_stream: Array<VisitDocument>;
  /** An array relationship */
  VisitFiles: Array<VisitFiles>;
  /** An aggregate relationship */
  VisitFiles_aggregate: VisitFiles_Aggregate;
  /** fetch data from the table: "VisitFiles" using primary key columns */
  VisitFiles_by_pk?: Maybe<VisitFiles>;
  /** fetch data from the table in a streaming manner: "VisitFiles" */
  VisitFiles_stream: Array<VisitFiles>;
  /** fetch aggregated fields from the table: "Visit" */
  Visit_aggregate: Visit_Aggregate;
  /** fetch data from the table: "Visit" using primary key columns */
  Visit_by_pk?: Maybe<Visit>;
  /** fetch data from the table in a streaming manner: "Visit" */
  Visit_stream: Array<Visit>;
}


export interface Subscription_RootChatMessagesArgs {
  distinct_on?: InputMaybe<Array<ChatMessages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChatMessages_Order_By>>;
  where?: InputMaybe<ChatMessages_Bool_Exp>;
}


export interface Subscription_RootChatMessages_AggregateArgs {
  distinct_on?: InputMaybe<Array<ChatMessages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChatMessages_Order_By>>;
  where?: InputMaybe<ChatMessages_Bool_Exp>;
}


export interface Subscription_RootChatMessages_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootChatMessages_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ChatMessages_Stream_Cursor_Input>>;
  where?: InputMaybe<ChatMessages_Bool_Exp>;
}


export interface Subscription_RootClientArgs {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
}


export interface Subscription_RootClient_AggregateArgs {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
}


export interface Subscription_RootClient_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootClient_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Client_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Bool_Exp>;
}


export interface Subscription_RootDepartmentArgs {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
}


export interface Subscription_RootDepartmentDocumentArgs {
  distinct_on?: InputMaybe<Array<DepartmentDocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentDocument_Order_By>>;
  where?: InputMaybe<DepartmentDocument_Bool_Exp>;
}


export interface Subscription_RootDepartmentDocument_AggregateArgs {
  distinct_on?: InputMaybe<Array<DepartmentDocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentDocument_Order_By>>;
  where?: InputMaybe<DepartmentDocument_Bool_Exp>;
}


export interface Subscription_RootDepartmentDocument_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootDepartmentDocument_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DepartmentDocument_Stream_Cursor_Input>>;
  where?: InputMaybe<DepartmentDocument_Bool_Exp>;
}


export interface Subscription_RootDepartment_AggregateArgs {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
}


export interface Subscription_RootDepartment_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootDepartment_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Department_Stream_Cursor_Input>>;
  where?: InputMaybe<Department_Bool_Exp>;
}


export interface Subscription_RootDoctorsCardArgs {
  distinct_on?: InputMaybe<Array<DoctorsCard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DoctorsCard_Order_By>>;
  where?: InputMaybe<DoctorsCard_Bool_Exp>;
}


export interface Subscription_RootDoctorsCard_AggregateArgs {
  distinct_on?: InputMaybe<Array<DoctorsCard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DoctorsCard_Order_By>>;
  where?: InputMaybe<DoctorsCard_Bool_Exp>;
}


export interface Subscription_RootDoctorsCard_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootDoctorsCard_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DoctorsCard_Stream_Cursor_Input>>;
  where?: InputMaybe<DoctorsCard_Bool_Exp>;
}


export interface Subscription_RootDoctorsDepartmentArgs {
  distinct_on?: InputMaybe<Array<DoctorsDepartment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DoctorsDepartment_Order_By>>;
  where?: InputMaybe<DoctorsDepartment_Bool_Exp>;
}


export interface Subscription_RootDoctorsDepartment_AggregateArgs {
  distinct_on?: InputMaybe<Array<DoctorsDepartment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DoctorsDepartment_Order_By>>;
  where?: InputMaybe<DoctorsDepartment_Bool_Exp>;
}


export interface Subscription_RootDoctorsDepartment_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootDoctorsDepartment_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DoctorsDepartment_Stream_Cursor_Input>>;
  where?: InputMaybe<DoctorsDepartment_Bool_Exp>;
}


export interface Subscription_RootDocumentsArgs {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
}


export interface Subscription_RootDocuments_AggregateArgs {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
}


export interface Subscription_RootDocuments_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootDocuments_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Documents_Stream_Cursor_Input>>;
  where?: InputMaybe<Documents_Bool_Exp>;
}


export interface Subscription_RootMedicineArgs {
  distinct_on?: InputMaybe<Array<Medicine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Medicine_Order_By>>;
  where?: InputMaybe<Medicine_Bool_Exp>;
}


export interface Subscription_RootMedicineUnitArgs {
  distinct_on?: InputMaybe<Array<MedicineUnit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MedicineUnit_Order_By>>;
  where?: InputMaybe<MedicineUnit_Bool_Exp>;
}


export interface Subscription_RootMedicineUnit_AggregateArgs {
  distinct_on?: InputMaybe<Array<MedicineUnit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MedicineUnit_Order_By>>;
  where?: InputMaybe<MedicineUnit_Bool_Exp>;
}


export interface Subscription_RootMedicineUnit_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootMedicineUnit_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MedicineUnit_Stream_Cursor_Input>>;
  where?: InputMaybe<MedicineUnit_Bool_Exp>;
}


export interface Subscription_RootMedicine_AggregateArgs {
  distinct_on?: InputMaybe<Array<Medicine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Medicine_Order_By>>;
  where?: InputMaybe<Medicine_Bool_Exp>;
}


export interface Subscription_RootMedicine_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootMedicine_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Medicine_Stream_Cursor_Input>>;
  where?: InputMaybe<Medicine_Bool_Exp>;
}


export interface Subscription_RootPetArgs {
  distinct_on?: InputMaybe<Array<Pet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Pet_Order_By>>;
  where?: InputMaybe<Pet_Bool_Exp>;
}


export interface Subscription_RootPetTypeArgs {
  distinct_on?: InputMaybe<Array<PetType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PetType_Order_By>>;
  where?: InputMaybe<PetType_Bool_Exp>;
}


export interface Subscription_RootPetType_AggregateArgs {
  distinct_on?: InputMaybe<Array<PetType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PetType_Order_By>>;
  where?: InputMaybe<PetType_Bool_Exp>;
}


export interface Subscription_RootPetType_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootPetType_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PetType_Stream_Cursor_Input>>;
  where?: InputMaybe<PetType_Bool_Exp>;
}


export interface Subscription_RootPet_AggregateArgs {
  distinct_on?: InputMaybe<Array<Pet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Pet_Order_By>>;
  where?: InputMaybe<Pet_Bool_Exp>;
}


export interface Subscription_RootPet_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootPet_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Pet_Stream_Cursor_Input>>;
  where?: InputMaybe<Pet_Bool_Exp>;
}


export interface Subscription_RootReceptionArgs {
  distinct_on?: InputMaybe<Array<Reception_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reception_Order_By>>;
  where?: InputMaybe<Reception_Bool_Exp>;
}


export interface Subscription_RootReceptionMedicinesArgs {
  distinct_on?: InputMaybe<Array<ReceptionMedicines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReceptionMedicines_Order_By>>;
  where?: InputMaybe<ReceptionMedicines_Bool_Exp>;
}


export interface Subscription_RootReceptionMedicines_AggregateArgs {
  distinct_on?: InputMaybe<Array<ReceptionMedicines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReceptionMedicines_Order_By>>;
  where?: InputMaybe<ReceptionMedicines_Bool_Exp>;
}


export interface Subscription_RootReceptionMedicines_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootReceptionMedicines_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ReceptionMedicines_Stream_Cursor_Input>>;
  where?: InputMaybe<ReceptionMedicines_Bool_Exp>;
}


export interface Subscription_RootReceptionServicesArgs {
  distinct_on?: InputMaybe<Array<ReceptionServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReceptionServices_Order_By>>;
  where?: InputMaybe<ReceptionServices_Bool_Exp>;
}


export interface Subscription_RootReceptionServices_AggregateArgs {
  distinct_on?: InputMaybe<Array<ReceptionServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReceptionServices_Order_By>>;
  where?: InputMaybe<ReceptionServices_Bool_Exp>;
}


export interface Subscription_RootReceptionServices_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootReceptionServices_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ReceptionServices_Stream_Cursor_Input>>;
  where?: InputMaybe<ReceptionServices_Bool_Exp>;
}


export interface Subscription_RootReception_AggregateArgs {
  distinct_on?: InputMaybe<Array<Reception_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reception_Order_By>>;
  where?: InputMaybe<Reception_Bool_Exp>;
}


export interface Subscription_RootReception_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootReception_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Reception_Stream_Cursor_Input>>;
  where?: InputMaybe<Reception_Bool_Exp>;
}


export interface Subscription_RootRegistrationDocumentArgs {
  distinct_on?: InputMaybe<Array<RegistrationDocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RegistrationDocument_Order_By>>;
  where?: InputMaybe<RegistrationDocument_Bool_Exp>;
}


export interface Subscription_RootRegistrationDocument_AggregateArgs {
  distinct_on?: InputMaybe<Array<RegistrationDocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RegistrationDocument_Order_By>>;
  where?: InputMaybe<RegistrationDocument_Bool_Exp>;
}


export interface Subscription_RootRegistrationDocument_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootRegistrationDocument_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RegistrationDocument_Stream_Cursor_Input>>;
  where?: InputMaybe<RegistrationDocument_Bool_Exp>;
}


export interface Subscription_RootRolesArgs {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
}


export interface Subscription_RootRoles_AggregateArgs {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
}


export interface Subscription_RootRoles_By_PkArgs {
  ID: Scalars['uuid']['input'];
}


export interface Subscription_RootRoles_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Roles_Bool_Exp>;
}


export interface Subscription_RootSchemaArgs {
  distinct_on?: InputMaybe<Array<Schema_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Schema_Order_By>>;
  where?: InputMaybe<Schema_Bool_Exp>;
}


export interface Subscription_RootSchemaCategoryArgs {
  distinct_on?: InputMaybe<Array<SchemaCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SchemaCategory_Order_By>>;
  where?: InputMaybe<SchemaCategory_Bool_Exp>;
}


export interface Subscription_RootSchemaCategory_AggregateArgs {
  distinct_on?: InputMaybe<Array<SchemaCategory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SchemaCategory_Order_By>>;
  where?: InputMaybe<SchemaCategory_Bool_Exp>;
}


export interface Subscription_RootSchemaCategory_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootSchemaCategory_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<SchemaCategory_Stream_Cursor_Input>>;
  where?: InputMaybe<SchemaCategory_Bool_Exp>;
}


export interface Subscription_RootSchemaMedicineArgs {
  distinct_on?: InputMaybe<Array<SchemaMedicine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SchemaMedicine_Order_By>>;
  where?: InputMaybe<SchemaMedicine_Bool_Exp>;
}


export interface Subscription_RootSchemaMedicine_AggregateArgs {
  distinct_on?: InputMaybe<Array<SchemaMedicine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SchemaMedicine_Order_By>>;
  where?: InputMaybe<SchemaMedicine_Bool_Exp>;
}


export interface Subscription_RootSchemaMedicine_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootSchemaMedicine_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<SchemaMedicine_Stream_Cursor_Input>>;
  where?: InputMaybe<SchemaMedicine_Bool_Exp>;
}


export interface Subscription_RootSchemaServiceArgs {
  distinct_on?: InputMaybe<Array<SchemaService_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SchemaService_Order_By>>;
  where?: InputMaybe<SchemaService_Bool_Exp>;
}


export interface Subscription_RootSchemaService_AggregateArgs {
  distinct_on?: InputMaybe<Array<SchemaService_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SchemaService_Order_By>>;
  where?: InputMaybe<SchemaService_Bool_Exp>;
}


export interface Subscription_RootSchemaService_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootSchemaService_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<SchemaService_Stream_Cursor_Input>>;
  where?: InputMaybe<SchemaService_Bool_Exp>;
}


export interface Subscription_RootSchema_AggregateArgs {
  distinct_on?: InputMaybe<Array<Schema_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Schema_Order_By>>;
  where?: InputMaybe<Schema_Bool_Exp>;
}


export interface Subscription_RootSchema_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootSchema_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Schema_Stream_Cursor_Input>>;
  where?: InputMaybe<Schema_Bool_Exp>;
}


export interface Subscription_RootServiceArgs {
  distinct_on?: InputMaybe<Array<Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Service_Order_By>>;
  where?: InputMaybe<Service_Bool_Exp>;
}


export interface Subscription_RootService_AggregateArgs {
  distinct_on?: InputMaybe<Array<Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Service_Order_By>>;
  where?: InputMaybe<Service_Bool_Exp>;
}


export interface Subscription_RootService_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootService_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Service_Stream_Cursor_Input>>;
  where?: InputMaybe<Service_Bool_Exp>;
}


export interface Subscription_RootTemplateArgs {
  distinct_on?: InputMaybe<Array<Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Order_By>>;
  where?: InputMaybe<Template_Bool_Exp>;
}


export interface Subscription_RootTemplateMedicinesArgs {
  distinct_on?: InputMaybe<Array<TemplateMedicines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TemplateMedicines_Order_By>>;
  where?: InputMaybe<TemplateMedicines_Bool_Exp>;
}


export interface Subscription_RootTemplateMedicines_AggregateArgs {
  distinct_on?: InputMaybe<Array<TemplateMedicines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TemplateMedicines_Order_By>>;
  where?: InputMaybe<TemplateMedicines_Bool_Exp>;
}


export interface Subscription_RootTemplateMedicines_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootTemplateMedicines_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TemplateMedicines_Stream_Cursor_Input>>;
  where?: InputMaybe<TemplateMedicines_Bool_Exp>;
}


export interface Subscription_RootTemplateServicesArgs {
  distinct_on?: InputMaybe<Array<TemplateServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TemplateServices_Order_By>>;
  where?: InputMaybe<TemplateServices_Bool_Exp>;
}


export interface Subscription_RootTemplateServices_AggregateArgs {
  distinct_on?: InputMaybe<Array<TemplateServices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TemplateServices_Order_By>>;
  where?: InputMaybe<TemplateServices_Bool_Exp>;
}


export interface Subscription_RootTemplateServices_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootTemplateServices_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TemplateServices_Stream_Cursor_Input>>;
  where?: InputMaybe<TemplateServices_Bool_Exp>;
}


export interface Subscription_RootTemplate_AggregateArgs {
  distinct_on?: InputMaybe<Array<Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Order_By>>;
  where?: InputMaybe<Template_Bool_Exp>;
}


export interface Subscription_RootTemplate_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootTemplate_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Template_Stream_Cursor_Input>>;
  where?: InputMaybe<Template_Bool_Exp>;
}


export interface Subscription_RootUserMedicinePaymentsArgs {
  distinct_on?: InputMaybe<Array<UserMedicinePayments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserMedicinePayments_Order_By>>;
  where?: InputMaybe<UserMedicinePayments_Bool_Exp>;
}


export interface Subscription_RootUserMedicinePayments_AggregateArgs {
  distinct_on?: InputMaybe<Array<UserMedicinePayments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserMedicinePayments_Order_By>>;
  where?: InputMaybe<UserMedicinePayments_Bool_Exp>;
}


export interface Subscription_RootUserMedicinePayments_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootUserMedicinePayments_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserMedicinePayments_Stream_Cursor_Input>>;
  where?: InputMaybe<UserMedicinePayments_Bool_Exp>;
}


export interface Subscription_RootUserServicePaymentsArgs {
  distinct_on?: InputMaybe<Array<UserServicePayments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserServicePayments_Order_By>>;
  where?: InputMaybe<UserServicePayments_Bool_Exp>;
}


export interface Subscription_RootUserServicePayments_AggregateArgs {
  distinct_on?: InputMaybe<Array<UserServicePayments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserServicePayments_Order_By>>;
  where?: InputMaybe<UserServicePayments_Bool_Exp>;
}


export interface Subscription_RootUserServicePayments_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootUserServicePayments_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserServicePayments_Stream_Cursor_Input>>;
  where?: InputMaybe<UserServicePayments_Bool_Exp>;
}


export interface Subscription_RootUserUtArgs {
  distinct_on?: InputMaybe<Array<UserUt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserUt_Order_By>>;
  where?: InputMaybe<UserUt_Bool_Exp>;
}


export interface Subscription_RootUserUt_AggregateArgs {
  distinct_on?: InputMaybe<Array<UserUt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserUt_Order_By>>;
  where?: InputMaybe<UserUt_Bool_Exp>;
}


export interface Subscription_RootUserUt_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootUserUt_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserUt_Stream_Cursor_Input>>;
  where?: InputMaybe<UserUt_Bool_Exp>;
}


export interface Subscription_RootUsersArgs {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
}


export interface Subscription_RootUsers_AggregateArgs {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
}


export interface Subscription_RootUsers_By_PkArgs {
  ID: Scalars['uuid']['input'];
}


export interface Subscription_RootUsers_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
}


export interface Subscription_RootVisitArgs {
  distinct_on?: InputMaybe<Array<Visit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Visit_Order_By>>;
  where?: InputMaybe<Visit_Bool_Exp>;
}


export interface Subscription_RootVisitDocumentArgs {
  distinct_on?: InputMaybe<Array<VisitDocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VisitDocument_Order_By>>;
  where?: InputMaybe<VisitDocument_Bool_Exp>;
}


export interface Subscription_RootVisitDocument_AggregateArgs {
  distinct_on?: InputMaybe<Array<VisitDocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VisitDocument_Order_By>>;
  where?: InputMaybe<VisitDocument_Bool_Exp>;
}


export interface Subscription_RootVisitDocument_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootVisitDocument_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<VisitDocument_Stream_Cursor_Input>>;
  where?: InputMaybe<VisitDocument_Bool_Exp>;
}


export interface Subscription_RootVisitFilesArgs {
  distinct_on?: InputMaybe<Array<VisitFiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VisitFiles_Order_By>>;
  where?: InputMaybe<VisitFiles_Bool_Exp>;
}


export interface Subscription_RootVisitFiles_AggregateArgs {
  distinct_on?: InputMaybe<Array<VisitFiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VisitFiles_Order_By>>;
  where?: InputMaybe<VisitFiles_Bool_Exp>;
}


export interface Subscription_RootVisitFiles_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootVisitFiles_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<VisitFiles_Stream_Cursor_Input>>;
  where?: InputMaybe<VisitFiles_Bool_Exp>;
}


export interface Subscription_RootVisit_AggregateArgs {
  distinct_on?: InputMaybe<Array<Visit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Visit_Order_By>>;
  where?: InputMaybe<Visit_Bool_Exp>;
}


export interface Subscription_RootVisit_By_PkArgs {
  id: Scalars['Int']['input'];
}


export interface Subscription_RootVisit_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Visit_Stream_Cursor_Input>>;
  where?: InputMaybe<Visit_Bool_Exp>;
}

/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export interface Time_Comparison_Exp {
  _eq?: InputMaybe<Scalars['time']['input']>;
  _gt?: InputMaybe<Scalars['time']['input']>;
  _gte?: InputMaybe<Scalars['time']['input']>;
  _in?: InputMaybe<Array<Scalars['time']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['time']['input']>;
  _lte?: InputMaybe<Scalars['time']['input']>;
  _neq?: InputMaybe<Scalars['time']['input']>;
  _nin?: InputMaybe<Array<Scalars['time']['input']>>;
}

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export interface Timestamp_Comparison_Exp {
  _eq?: InputMaybe<Scalars['timestamp']['input']>;
  _gt?: InputMaybe<Scalars['timestamp']['input']>;
  _gte?: InputMaybe<Scalars['timestamp']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamp']['input']>;
  _lte?: InputMaybe<Scalars['timestamp']['input']>;
  _neq?: InputMaybe<Scalars['timestamp']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>;
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export interface Timestamptz_Comparison_Exp {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
}

/** Boolean expression to compare columns of type "timetz". All fields are combined with logical 'AND'. */
export interface Timetz_Comparison_Exp {
  _eq?: InputMaybe<Scalars['timetz']['input']>;
  _gt?: InputMaybe<Scalars['timetz']['input']>;
  _gte?: InputMaybe<Scalars['timetz']['input']>;
  _in?: InputMaybe<Array<Scalars['timetz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timetz']['input']>;
  _lte?: InputMaybe<Scalars['timetz']['input']>;
  _neq?: InputMaybe<Scalars['timetz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timetz']['input']>>;
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export interface Uuid_Comparison_Exp {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
}

export type LatestChatMessagesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type LatestChatMessagesSubscription = { __typename?: 'subscription_root', ChatMessages: Array<{ __typename?: 'ChatMessages', id: number, date: any, message: string, user_id: any, User: { __typename?: 'Users', Name: string, Role: { __typename?: 'Roles', Name: string } } }> };

export type LatestChatMessagesFirstQueryVariables = Exact<{ [key: string]: never; }>;


export type LatestChatMessagesFirstQuery = { __typename?: 'query_root', ChatMessages: Array<{ __typename?: 'ChatMessages', id: number, date: any, message: string, user_id: any, User: { __typename?: 'Users', Name: string, Role: { __typename?: 'Roles', Name: string } } }> };

export type SendMessageMutationVariables = Exact<{
  message?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type SendMessageMutation = { __typename?: 'mutation_root', insert_ChatMessages?: { __typename?: 'ChatMessages_mutation_response', returning: Array<{ __typename?: 'ChatMessages', id: number }> } | null };

export type DoctorsCardIndexSubscriptionVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DoctorsCard_Order_By> | DoctorsCard_Order_By>;
  where?: InputMaybe<DoctorsCard_Bool_Exp>;
}>;


export type DoctorsCardIndexSubscription = { __typename?: 'subscription_root', DoctorsCard: Array<{ __typename?: 'DoctorsCard', id: number, medicine_id: number, Medicine: { __typename?: 'Medicine', id: number, name: string, price: any } }> };

export type DoctorsCardIndexCountSubscriptionVariables = Exact<{
  where?: InputMaybe<DoctorsCard_Bool_Exp>;
}>;


export type DoctorsCardIndexCountSubscription = { __typename?: 'subscription_root', DoctorsCard_aggregate: { __typename?: 'DoctorsCard_aggregate', aggregate?: { __typename?: 'DoctorsCard_aggregate_fields', count: number } | null } };

export type AddDoctorsCardMutationVariables = Exact<{
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AddDoctorsCardMutation = { __typename?: 'mutation_root', insert_DoctorsCard?: { __typename?: 'DoctorsCard_mutation_response', returning: Array<{ __typename?: 'DoctorsCard', id: number }> } | null };

export type UpdateDoctorsCardMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UpdateDoctorsCardMutation = { __typename?: 'mutation_root', update_DoctorsCard?: { __typename?: 'DoctorsCard_mutation_response', returning: Array<{ __typename?: 'DoctorsCard', id: number }> } | null };

export type DeleteDoctorsCardMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DeleteDoctorsCardMutation = { __typename?: 'mutation_root', delete_DoctorsCard?: { __typename?: 'DoctorsCard_mutation_response', returning: Array<{ __typename?: 'DoctorsCard', id: number }> } | null };

export type DeleteDoctorsCardByIdsMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type DeleteDoctorsCardByIdsMutation = { __typename?: 'mutation_root', delete_DoctorsCard?: { __typename?: 'DoctorsCard_mutation_response', returning: Array<{ __typename?: 'DoctorsCard', id: number }> } | null };

export type MedicineFkSubscriptionVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
}>;


export type MedicineFkSubscription = { __typename?: 'subscription_root', Medicine: Array<{ __typename?: 'Medicine', id: number, name: string }> };

export type MedicineModelFkQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type MedicineModelFkQuery = { __typename?: 'query_root', Medicine_by_pk?: { __typename?: 'Medicine', id: number, name: string, price: any } | null };

export type ClientsIndexSubscriptionVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Client_Bool_Exp>;
  order_by?: InputMaybe<Array<Client_Order_By> | Client_Order_By>;
}>;


export type ClientsIndexSubscription = { __typename?: 'subscription_root', Client: Array<{ __typename?: 'Client', id: number, email?: string | null, name: string, passport?: string | null, passport_when?: any | null, passport_where?: string | null, phone_number: string, address?: string | null, serial?: string | null }> };

export type ClientsIndexCountSubscriptionVariables = Exact<{
  where?: InputMaybe<Client_Bool_Exp>;
}>;


export type ClientsIndexCountSubscription = { __typename?: 'subscription_root', Client_aggregate: { __typename?: 'Client_aggregate', aggregate?: { __typename?: 'Client_aggregate_fields', count: number } | null } };

export type AddClientMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  passport?: InputMaybe<Scalars['String']['input']>;
  passport_when?: InputMaybe<Scalars['date']['input']>;
  passport_where?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  serial?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddClientMutation = { __typename?: 'mutation_root', insert_Client?: { __typename?: 'Client_mutation_response', returning: Array<{ __typename?: 'Client', id: number }> } | null };

export type UpdateClientMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  passport?: InputMaybe<Scalars['String']['input']>;
  passport_when?: InputMaybe<Scalars['date']['input']>;
  passport_where?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  serial?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateClientMutation = { __typename?: 'mutation_root', update_Client?: { __typename?: 'Client_mutation_response', returning: Array<{ __typename?: 'Client', id: number }> } | null };

export type DeleteClientMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DeleteClientMutation = { __typename?: 'mutation_root', delete_Client?: { __typename?: 'Client_mutation_response', returning: Array<{ __typename?: 'Client', id: number }> } | null };

export type DeleteClientsByIdsMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type DeleteClientsByIdsMutation = { __typename?: 'mutation_root', delete_Client?: { __typename?: 'Client_mutation_response', returning: Array<{ __typename?: 'Client', id: number }> } | null };

export type DepartmentsIndexSubscriptionVariables = Exact<{
  order_by?: InputMaybe<Array<Department_Order_By> | Department_Order_By>;
  where?: InputMaybe<Department_Bool_Exp>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DepartmentsIndexSubscription = { __typename?: 'subscription_root', Department: Array<{ __typename?: 'Department', id: number, name: string }> };

export type DepartmentIndexCountSubscriptionVariables = Exact<{
  where?: InputMaybe<Department_Bool_Exp>;
}>;


export type DepartmentIndexCountSubscription = { __typename?: 'subscription_root', Department_aggregate: { __typename?: 'Department_aggregate', aggregate?: { __typename?: 'Department_aggregate_fields', count: number } | null } };

export type AddDepartmentMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddDepartmentMutation = { __typename?: 'mutation_root', insert_Department?: { __typename?: 'Department_mutation_response', returning: Array<{ __typename?: 'Department', id: number }> } | null };

export type UpdateDepartmentMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UpdateDepartmentMutation = { __typename?: 'mutation_root', update_Department?: { __typename?: 'Department_mutation_response', returning: Array<{ __typename?: 'Department', id: number }> } | null };

export type DeleteDepartmentMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DeleteDepartmentMutation = { __typename?: 'mutation_root', delete_Department?: { __typename?: 'Department_mutation_response', returning: Array<{ __typename?: 'Department', id: number }> } | null };

export type DeleteDepartmentsByIdsMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type DeleteDepartmentsByIdsMutation = { __typename?: 'mutation_root', delete_Department?: { __typename?: 'Department_mutation_response', returning: Array<{ __typename?: 'Department', id: number }> } | null };

export type AllDocumentsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type AllDocumentsSubscription = { __typename?: 'subscription_root', Documents: Array<{ __typename?: 'Documents', name: string, id: number }> };

export type GetDepartmentDocumentsQueryVariables = Exact<{
  department_id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetDepartmentDocumentsQuery = { __typename?: 'query_root', DepartmentDocument: Array<{ __typename?: 'DepartmentDocument', id: number, document_id: number, Document: { __typename?: 'Documents', name: string } }> };

export type DeleteDepartmentDocumentMutationVariables = Exact<{
  _in?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type DeleteDepartmentDocumentMutation = { __typename?: 'mutation_root', delete_DepartmentDocument?: { __typename?: 'DepartmentDocument_mutation_response', returning: Array<{ __typename?: 'DepartmentDocument', id: number }> } | null };

export type AddDepaertmentDocumentMutationVariables = Exact<{
  objects?: InputMaybe<Array<DepartmentDocument_Insert_Input> | DepartmentDocument_Insert_Input>;
}>;


export type AddDepaertmentDocumentMutation = { __typename?: 'mutation_root', insert_DepartmentDocument?: { __typename?: 'DepartmentDocument_mutation_response', returning: Array<{ __typename?: 'DepartmentDocument', id: number }> } | null };

export type DocumentsIndexSubscriptionVariables = Exact<{
  order_by?: InputMaybe<Array<Documents_Order_By> | Documents_Order_By>;
  where?: InputMaybe<Documents_Bool_Exp>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DocumentsIndexSubscription = { __typename?: 'subscription_root', Documents: Array<{ __typename?: 'Documents', id: number, name: string, body: string, variables: any }> };

export type DocumentIndexCountSubscriptionVariables = Exact<{
  where?: InputMaybe<Documents_Bool_Exp>;
}>;


export type DocumentIndexCountSubscription = { __typename?: 'subscription_root', Documents_aggregate: { __typename?: 'Documents_aggregate', aggregate?: { __typename?: 'Documents_aggregate_fields', count: number } | null } };

export type AddDocumentMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  variables?: InputMaybe<Scalars['jsonb']['input']>;
  styles?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddDocumentMutation = { __typename?: 'mutation_root', insert_Documents?: { __typename?: 'Documents_mutation_response', returning: Array<{ __typename?: 'Documents', id: number }> } | null };

export type UpdateDocumentMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  variables?: InputMaybe<Scalars['jsonb']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  styles?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateDocumentMutation = { __typename?: 'mutation_root', update_Documents?: { __typename?: 'Documents_mutation_response', returning: Array<{ __typename?: 'Documents', id: number }> } | null };

export type DeleteDocumentMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DeleteDocumentMutation = { __typename?: 'mutation_root', delete_Documents?: { __typename?: 'Documents_mutation_response', returning: Array<{ __typename?: 'Documents', id: number }> } | null };

export type DeleteDocumentsByIdsMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type DeleteDocumentsByIdsMutation = { __typename?: 'mutation_root', delete_Documents?: { __typename?: 'Documents_mutation_response', returning: Array<{ __typename?: 'Documents', id: number }> } | null };

export type TakeDoctorsVisitsSubscriptionVariables = Exact<{
  date?: InputMaybe<Scalars['date']['input']>;
  doctor_id?: InputMaybe<Scalars['uuid']['input']>;
  doctors_departments?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type TakeDoctorsVisitsSubscription = { __typename?: 'subscription_root', Department: Array<{ __typename?: 'Department', id: number, name: string, Visits: Array<{ __typename?: 'Visit', id: number, time: any, patient_id: number, patient_card: any, from_id: any, doctor_id?: any | null, department_id: number, date: any, current_reception_id?: number | null, client_id: number, status: number, without_time: boolean, visit_start?: any | null, Client: { __typename?: 'Client', name: string, phone_number: string }, Doctor?: { __typename?: 'Users', Name: string } | null, Pet: { __typename?: 'Pet', name: string, birthdate?: any | null, sex: number, breed?: string | null, PetType: { __typename?: 'PetType', name: string } }, From: { __typename?: 'Users', Name: string }, CurrentReception?: { __typename?: 'Reception', internal_recomendation: string, patient_recomendation: string } | null }> }> };

export type GetDoctorsDepartmentsSubscriptionVariables = Exact<{
  doctor_id?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type GetDoctorsDepartmentsSubscription = { __typename?: 'subscription_root', DoctorsDepartment: Array<{ __typename?: 'DoctorsDepartment', department_id: number }> };

export type ChangeVisitStatusMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ChangeVisitStatusMutation = { __typename?: 'mutation_root', update_Visit?: { __typename?: 'Visit_mutation_response', returning: Array<{ __typename?: 'Visit', id: number }> } | null };

export type ChangePatientCardMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  patient_card?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type ChangePatientCardMutation = { __typename?: 'mutation_root', update_Visit?: { __typename?: 'Visit_mutation_response', returning: Array<{ __typename?: 'Visit', id: number }> } | null };

export type ChangeDoctorInVisitMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  doctor_id?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type ChangeDoctorInVisitMutation = { __typename?: 'mutation_root', update_Visit?: { __typename?: 'Visit_mutation_response', returning: Array<{ __typename?: 'Visit', id: number }> } | null };

export type MakeReceptionMutationVariables = Exact<{
  doctor_id?: InputMaybe<Scalars['uuid']['input']>;
  visit_id?: InputMaybe<Scalars['Int']['input']>;
  patient_recomendation?: InputMaybe<Scalars['String']['input']>;
  internal_recomendation?: InputMaybe<Scalars['String']['input']>;
  from_id?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type MakeReceptionMutation = { __typename?: 'mutation_root', insert_Reception?: { __typename?: 'Reception_mutation_response', returning: Array<{ __typename?: 'Reception', id: number }> } | null };

export type ChangeReceptionInVisitMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  current_reception_id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ChangeReceptionInVisitMutation = { __typename?: 'mutation_root', update_Visit?: { __typename?: 'Visit_mutation_response', returning: Array<{ __typename?: 'Visit', id: number }> } | null };

export type SaveReceptionMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  internal_recomendation?: InputMaybe<Scalars['String']['input']>;
  patient_recomendation?: InputMaybe<Scalars['String']['input']>;
}>;


export type SaveReceptionMutation = { __typename?: 'mutation_root', update_Reception?: { __typename?: 'Reception_mutation_response', returning: Array<{ __typename?: 'Reception', id: number }> } | null };

export type GetReceptionsOfVisitQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  current_reception_id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetReceptionsOfVisitQuery = { __typename?: 'query_root', Reception: Array<{ __typename?: 'Reception', id: number, internal_recomendation: string, patient_recomendation: string, doctor_id: any, end_time?: any | null, start_time: any, Doctor: { __typename?: 'Users', Name: string }, From: { __typename?: 'Users', Name: string }, Medicines: Array<{ __typename?: 'ReceptionMedicines', id: number, done: boolean, Medicine: { __typename?: 'Medicine', name: string } }>, Services: Array<{ __typename?: 'ReceptionServices', id: number, price: any, value: any, Service: { __typename?: 'Service', name: string } }> }> };

export type GetReceptionMedicinesAndServicesQueryVariables = Exact<{
  reception_id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetReceptionMedicinesAndServicesQuery = { __typename?: 'query_root', ReceptionMedicines: Array<{ __typename?: 'ReceptionMedicines', id: number, value: any, medicine_id: number, reception_id: number, done: boolean, Medicine: { __typename?: 'Medicine', name: string, id: number, ut_id?: string | null, price: any } }>, ReceptionServices: Array<{ __typename?: 'ReceptionServices', id: number, price: any, value: any, service_id: number, reception_id: number, Service: { __typename?: 'Service', name: string, id: number, price: any, ut_id?: string | null } }>, Service: Array<{ __typename?: 'Service', name: string, id: number, price: any, ut_id?: string | null }>, Medicine: Array<{ __typename?: 'Medicine', name: string, id: number, ut_id?: string | null, price: any }> };

export type DeleteReceptionServiceMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DeleteReceptionServiceMutation = { __typename?: 'mutation_root', delete_ReceptionServices?: { __typename?: 'ReceptionServices_mutation_response', returning: Array<{ __typename?: 'ReceptionServices', id: number }> } | null };

export type UpdateReceptionServiceMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  service_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}>;


export type UpdateReceptionServiceMutation = { __typename?: 'mutation_root', update_ReceptionServices?: { __typename?: 'ReceptionServices_mutation_response', returning: Array<{ __typename?: 'ReceptionServices', id: number }> } | null };

export type UpdateReceptionMedicineMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
  reception_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
  done?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateReceptionMedicineMutation = { __typename?: 'mutation_root', update_ReceptionMedicines?: { __typename?: 'ReceptionMedicines_mutation_response', returning: Array<{ __typename?: 'ReceptionMedicines', id: number }> } | null };

export type DeleteReceptionMedicineMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DeleteReceptionMedicineMutation = { __typename?: 'mutation_root', delete_ReceptionMedicines?: { __typename?: 'ReceptionMedicines_mutation_response', returning: Array<{ __typename?: 'ReceptionMedicines', id: number }> } | null };

export type AddReceptionServicesAndMedicinesMutationVariables = Exact<{
  services?: InputMaybe<Array<ReceptionServices_Insert_Input> | ReceptionServices_Insert_Input>;
  medicines?: InputMaybe<Array<ReceptionMedicines_Insert_Input> | ReceptionMedicines_Insert_Input>;
}>;


export type AddReceptionServicesAndMedicinesMutation = { __typename?: 'mutation_root', insert_ReceptionServices?: { __typename?: 'ReceptionServices_mutation_response', returning: Array<{ __typename?: 'ReceptionServices', id: number }> } | null, insert_ReceptionMedicines?: { __typename?: 'ReceptionMedicines_mutation_response', returning: Array<{ __typename?: 'ReceptionMedicines', id: number }> } | null };

export type SaveVisitMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  patient_card?: InputMaybe<Scalars['jsonb']['input']>;
  reception_id?: InputMaybe<Scalars['Int']['input']>;
  internal_recomendation?: InputMaybe<Scalars['String']['input']>;
  patient_recomendation?: InputMaybe<Scalars['String']['input']>;
  services?: InputMaybe<Array<ReceptionServices_Insert_Input> | ReceptionServices_Insert_Input>;
  medicines?: InputMaybe<Array<ReceptionMedicines_Insert_Input> | ReceptionMedicines_Insert_Input>;
  status?: InputMaybe<Scalars['Int']['input']>;
  end_time?: InputMaybe<Scalars['timetz']['input']>;
}>;


export type SaveVisitMutation = { __typename?: 'mutation_root', update_Visit?: { __typename?: 'Visit_mutation_response', returning: Array<{ __typename?: 'Visit', id: number }> } | null, update_Reception?: { __typename?: 'Reception_mutation_response', returning: Array<{ __typename?: 'Reception', id: number }> } | null, insert_ReceptionServices?: { __typename?: 'ReceptionServices_mutation_response', returning: Array<{ __typename?: 'ReceptionServices', id: number }> } | null, insert_ReceptionMedicines?: { __typename?: 'ReceptionMedicines_mutation_response', returning: Array<{ __typename?: 'ReceptionMedicines', id: number }> } | null };

export type GetDoctorsDocumentsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetDoctorsDocumentsQuery = { __typename?: 'query_root', DepartmentDocument: Array<{ __typename?: 'DepartmentDocument', id: number, Document: { __typename?: 'Documents', name: string, id: number, variables: any, body: string } }> };

export type GetVisitFilesSubscriptionVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetVisitFilesSubscription = { __typename?: 'subscription_root', Visit: Array<{ __typename?: 'Visit', department_id: number, VisitFiles: Array<{ __typename?: 'VisitFiles', id: number, file_name: string, name: string }>, VisitDocuments: Array<{ __typename?: 'VisitDocument', id: number, name: string, data: string }> }> };

export type AddFileToVisitMutationVariables = Exact<{
  file_name?: InputMaybe<Scalars['String']['input']>;
  visit_id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddFileToVisitMutation = { __typename?: 'mutation_root', insert_VisitFiles?: { __typename?: 'VisitFiles_mutation_response', returning: Array<{ __typename?: 'VisitFiles', id: number }> } | null };

export type GetMedicineUnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMedicineUnitsQuery = { __typename?: 'query_root', MedicineUnit: Array<{ __typename?: 'MedicineUnit', id: number, name: string }> };

export type SetVisitTimeMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['timetz']['input']>;
}>;


export type SetVisitTimeMutation = { __typename?: 'mutation_root', update_Visit?: { __typename?: 'Visit_mutation_response', affected_rows: number } | null };

export type CreateVisitDocumentMutationVariables = Exact<{
  visit_id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateVisitDocumentMutation = { __typename?: 'mutation_root', insert_VisitDocument?: { __typename?: 'VisitDocument_mutation_response', returning: Array<{ __typename?: 'VisitDocument', id: number }> } | null };

export type TakeClientVisitsSubscriptionVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type TakeClientVisitsSubscription = { __typename?: 'subscription_root', Visit: Array<{ __typename?: 'Visit', id: number, time: any, patient_id: number, patient_card: any, from_id: any, doctor_id?: any | null, department_id: number, date: any, current_reception_id?: number | null, client_id: number, status: number, without_time: boolean, visit_start?: any | null, Client: { __typename?: 'Client', name: string, phone_number: string }, Doctor?: { __typename?: 'Users', Name: string } | null, Pet: { __typename?: 'Pet', name: string, birthdate?: any | null, sex: number, breed?: string | null, PetType: { __typename?: 'PetType', name: string } }, From: { __typename?: 'Users', Name: string }, ReceptionsList: Array<{ __typename?: 'Reception', id: number, start_time: any, end_time?: any | null, internal_recomendation: string, patient_recomendation: string, doctor_id: any, From: { __typename?: 'Users', Name: string }, Doctor: { __typename?: 'Users', Name: string }, Medicines: Array<{ __typename?: 'ReceptionMedicines', value: any, medicine_id: number, reception_id: number, id: number, done: boolean, Medicine: { __typename?: 'Medicine', name: string, id: number, price: any, ut_id?: string | null } }>, Services: Array<{ __typename?: 'ReceptionServices', id: number, service_id: number, price: any, value: any, reception_id: number, Service: { __typename?: 'Service', id: number, name: string, price: any, ut_id?: string | null } }> }>, VisitFiles: Array<{ __typename?: 'VisitFiles', id: number, file_name: string, name: string }>, VisitDocuments: Array<{ __typename?: 'VisitDocument', name: string, data: string, id: number }> }> };

export type GetDoctorsServicesSubscriptionVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  doctor_id?: InputMaybe<Scalars['uuid']['input']>;
  currentReceptionID?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetDoctorsServicesSubscription = { __typename?: 'subscription_root', Visit: Array<{ __typename?: 'Visit', ReceptionsList: Array<{ __typename?: 'Reception', Services: Array<{ __typename?: 'ReceptionServices', price: any, value: any }> }> }> };

export type TakeClientReceptionsVisitsSubscriptionVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type TakeClientReceptionsVisitsSubscription = { __typename?: 'subscription_root', Visit: Array<{ __typename?: 'Visit', id: number, time: any, patient_id: number, patient_card: any, from_id: any, doctor_id?: any | null, department_id: number, date: any, current_reception_id?: number | null, client_id: number, status: number, without_time: boolean, visit_start?: any | null, Client: { __typename?: 'Client', name: string, phone_number: string }, Doctor?: { __typename?: 'Users', Name: string } | null, Pet: { __typename?: 'Pet', name: string, birthdate?: any | null, sex: number, breed?: string | null, PetType: { __typename?: 'PetType', name: string } }, From: { __typename?: 'Users', Name: string }, ReceptionsList: Array<{ __typename?: 'Reception', id: number, start_time: any, end_time?: any | null, internal_recomendation: string, patient_recomendation: string, doctor_id: any, From: { __typename?: 'Users', Name: string }, Doctor: { __typename?: 'Users', Name: string }, Medicines: Array<{ __typename?: 'ReceptionMedicines', value: any, medicine_id: number, reception_id: number, id: number, done: boolean, Medicine: { __typename?: 'Medicine', name: string, id: number, price: any } }>, Services: Array<{ __typename?: 'ReceptionServices', id: number, service_id: number, price: any, value: any, reception_id: number, Service: { __typename?: 'Service', id: number, name: string, price: any } }> }>, VisitFiles: Array<{ __typename?: 'VisitFiles', id: number, file_name: string, name: string }>, VisitDocuments: Array<{ __typename?: 'VisitDocument', name: string, data: string, id: number }> }> };

export type MedicinesCardsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type MedicinesCardsSubscription = { __typename?: 'subscription_root', DoctorsCard: Array<{ __typename?: 'DoctorsCard', id: number, medicine_id: number, Medicine: { __typename?: 'Medicine', id: number, name: string, price: any, ut_id?: string | null } }> };

export type GetDoctorsTemplatesQueryVariables = Exact<{
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type GetDoctorsTemplatesQuery = { __typename?: 'query_root', Template: Array<{ __typename?: 'Template', id: number, name: string, internal_recomendation?: string | null, patient_recomendation?: string | null, user_id: any, TemplateMedicines: Array<{ __typename?: 'TemplateMedicines', id: number, medicine_id: number, template_id: number, value: any, Medicine: { __typename?: 'Medicine', id: number, name: string, price: any, ut_id?: string | null } }>, TemplateServices: Array<{ __typename?: 'TemplateServices', id: number, price?: any | null, service_id: number, template_id: number, value: any, Service: { __typename?: 'Service', id: number, name: string, price: any, ut_id?: string | null } }> }> };

export type MoveToDoctorMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['timetz']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  doctor_id?: InputMaybe<Scalars['uuid']['input']>;
  department_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  current_reception_id?: InputMaybe<Scalars['Int']['input']>;
  from_id?: InputMaybe<Scalars['uuid']['input']>;
  without_time?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type MoveToDoctorMutation = { __typename?: 'mutation_root', update_Visit?: { __typename?: 'Visit_mutation_response', returning: Array<{ __typename?: 'Visit', id: number }> } | null };

export type PetStepSearchingSubscriptionVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  client_id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type PetStepSearchingSubscription = { __typename?: 'subscription_root', Pet: Array<{ __typename?: 'Pet', id: number, name: string, birthdate?: any | null, breed?: string | null, chip_number?: string | null, client_id: number, sex: number, type_id: number, PetType: { __typename?: 'PetType', name: string } }> };

export type GetDepartmentWithDoctorsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDepartmentWithDoctorsQuery = { __typename?: 'query_root', Department: Array<{ __typename?: 'Department', id: number, name: string, DoctorsDepartments: Array<{ __typename?: 'DoctorsDepartment', id: number, Doctor: { __typename?: 'Users', ID: any, Name: string, Login: string } }> }> };

export type MakeVisitMutationVariables = Exact<{
  client_id?: InputMaybe<Scalars['Int']['input']>;
  current_reception_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  department_id?: InputMaybe<Scalars['Int']['input']>;
  doctor_id?: InputMaybe<Scalars['uuid']['input']>;
  from_id?: InputMaybe<Scalars['uuid']['input']>;
  patient_id?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['timetz']['input']>;
  patient_card?: InputMaybe<Scalars['jsonb']['input']>;
  without_time?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type MakeVisitMutation = { __typename?: 'mutation_root', insert_Visit?: { __typename?: 'Visit_mutation_response', returning: Array<{ __typename?: 'Visit', id: number }> } | null };

export type TakeVisitsSubscriptionVariables = Exact<{
  date?: InputMaybe<Scalars['date']['input']>;
}>;


export type TakeVisitsSubscription = { __typename?: 'subscription_root', Department: Array<{ __typename?: 'Department', id: number, name: string, Visits: Array<{ __typename?: 'Visit', id: number, time: any, patient_id: number, patient_card: any, from_id: any, doctor_id?: any | null, department_id: number, date: any, current_reception_id?: number | null, client_id: number, status: number, without_time: boolean, Client: { __typename?: 'Client', name: string, phone_number: string }, Doctor?: { __typename?: 'Users', Name: string } | null, Pet: { __typename?: 'Pet', name: string, birthdate?: any | null, PetType: { __typename?: 'PetType', name: string } } }> }> };

export type DeleteVisitMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DeleteVisitMutation = { __typename?: 'mutation_root', delete_Visit?: { __typename?: 'Visit_mutation_response', returning: Array<{ __typename?: 'Visit', id: number }> } | null };

export type ChangeVisitDateMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  time?: InputMaybe<Scalars['timetz']['input']>;
}>;


export type ChangeVisitDateMutation = { __typename?: 'mutation_root', update_Visit?: { __typename?: 'Visit_mutation_response', returning: Array<{ __typename?: 'Visit', id: number }> } | null };

export type GetRegistratorDocumentsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetRegistratorDocumentsSubscription = { __typename?: 'subscription_root', RegistrationDocument: Array<{ __typename?: 'RegistrationDocument', id: number, Document: { __typename?: 'Documents', id: number, name: string, body: string, variables: any } }> };

export type GetVisitForDocumentQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetVisitForDocumentQuery = { __typename?: 'query_root', Visit: Array<{ __typename?: 'Visit', date: any, id: number, patient_card: any, time: any, Client: { __typename?: 'Client', name: string, email?: string | null, passport?: string | null, passport_when?: any | null, passport_where?: string | null, phone_number: string, address?: string | null }, CurrentReception?: { __typename?: 'Reception', internal_recomendation: string, patient_recomendation: string, end_time?: any | null, start_time: any, Services: Array<{ __typename?: 'ReceptionServices', price: any, value: any, Service: { __typename?: 'Service', name: string, price: any } }>, Medicines: Array<{ __typename?: 'ReceptionMedicines', value: any, Medicine: { __typename?: 'Medicine', name: string, price: any } }>, From: { __typename?: 'Users', Name: string }, Doctor: { __typename?: 'Users', Name: string } } | null, Department: { __typename?: 'Department', name: string }, Doctor?: { __typename?: 'Users', Name: string } | null, From: { __typename?: 'Users', Name: string }, Pet: { __typename?: 'Pet', birthdate?: any | null, breed?: string | null, chip_number?: string | null, name: string, sex: number, PetType: { __typename?: 'PetType', name: string } }, ReceptionsList: Array<{ __typename?: 'Reception', internal_recomendation: string, patient_recomendation: string, end_time?: any | null, start_time: any, Services: Array<{ __typename?: 'ReceptionServices', price: any, value: any, Service: { __typename?: 'Service', name: string, price: any } }>, Medicines: Array<{ __typename?: 'ReceptionMedicines', value: any, Medicine: { __typename?: 'Medicine', name: string, price: any } }>, From: { __typename?: 'Users', Name: string }, Doctor: { __typename?: 'Users', Name: string } }> }> };

export type ChangeVisitStatusAndSetVisitTimeMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  visit_start?: InputMaybe<Scalars['time']['input']>;
}>;


export type ChangeVisitStatusAndSetVisitTimeMutation = { __typename?: 'mutation_root', update_Visit?: { __typename?: 'Visit_mutation_response', returning: Array<{ __typename?: 'Visit', id: number }> } | null };

export type TakeVisitForInformationSubscriptionVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type TakeVisitForInformationSubscription = { __typename?: 'subscription_root', Visit: Array<{ __typename?: 'Visit', id: number, time: any, patient_id: number, patient_card: any, from_id: any, doctor_id?: any | null, department_id: number, date: any, current_reception_id?: number | null, client_id: number, status: number, without_time: boolean, visit_start?: any | null, Client: { __typename?: 'Client', name: string, phone_number: string }, Doctor?: { __typename?: 'Users', Name: string } | null, Pet: { __typename?: 'Pet', name: string, birthdate?: any | null, sex: number, breed?: string | null, PetType: { __typename?: 'PetType', name: string } }, From: { __typename?: 'Users', Name: string }, ReceptionsList: Array<{ __typename?: 'Reception', id: number, start_time: any, end_time?: any | null, internal_recomendation: string, patient_recomendation: string, doctor_id: any, From: { __typename?: 'Users', Name: string }, Doctor: { __typename?: 'Users', Name: string }, Medicines: Array<{ __typename?: 'ReceptionMedicines', value: any, medicine_id: number, reception_id: number, id: number, done: boolean, Medicine: { __typename?: 'Medicine', name: string, id: number, price: any } }>, Services: Array<{ __typename?: 'ReceptionServices', id: number, service_id: number, reception_id: number, price: any, value: any, Service: { __typename?: 'Service', id: number, name: string, price: any } }> }>, VisitFiles: Array<{ __typename?: 'VisitFiles', id: number, file_name: string, name: string }>, VisitDocuments: Array<{ __typename?: 'VisitDocument', name: string, data: string, id: number }> }> };

export type MedicinesIndexSubscriptionVariables = Exact<{
  order_by?: InputMaybe<Array<Medicine_Order_By> | Medicine_Order_By>;
  where?: InputMaybe<Medicine_Bool_Exp>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type MedicinesIndexSubscription = { __typename?: 'subscription_root', Medicine: Array<{ __typename?: 'Medicine', id: number, name: string, price: any, ut_id?: string | null }> };

export type MedicineIndexCountSubscriptionVariables = Exact<{
  where?: InputMaybe<Medicine_Bool_Exp>;
}>;


export type MedicineIndexCountSubscription = { __typename?: 'subscription_root', Medicine_aggregate: { __typename?: 'Medicine_aggregate', aggregate?: { __typename?: 'Medicine_aggregate_fields', count: number } | null } };

export type AddMedicineMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  ut_id?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddMedicineMutation = { __typename?: 'mutation_root', insert_Medicine?: { __typename?: 'Medicine_mutation_response', returning: Array<{ __typename?: 'Medicine', id: number }> } | null };

export type UpdateMedicineMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  ut_id?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateMedicineMutation = { __typename?: 'mutation_root', update_Medicine?: { __typename?: 'Medicine_mutation_response', returning: Array<{ __typename?: 'Medicine', id: number }> } | null };

export type DeleteMedicineMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DeleteMedicineMutation = { __typename?: 'mutation_root', delete_Medicine?: { __typename?: 'Medicine_mutation_response', returning: Array<{ __typename?: 'Medicine', id: number }> } | null };

export type DeleteMedicinesByIdsMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type DeleteMedicinesByIdsMutation = { __typename?: 'mutation_root', delete_Medicine?: { __typename?: 'Medicine_mutation_response', returning: Array<{ __typename?: 'Medicine', id: number }> } | null };

export type PetTypesIndexSubscriptionVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PetType_Order_By> | PetType_Order_By>;
  where?: InputMaybe<PetType_Bool_Exp>;
}>;


export type PetTypesIndexSubscription = { __typename?: 'subscription_root', PetType: Array<{ __typename?: 'PetType', name: string, id: number }> };

export type PetTypesIndexCountSubscriptionVariables = Exact<{
  where?: InputMaybe<PetType_Bool_Exp>;
}>;


export type PetTypesIndexCountSubscription = { __typename?: 'subscription_root', PetType_aggregate: { __typename?: 'PetType_aggregate', aggregate?: { __typename?: 'PetType_aggregate_fields', count: number } | null } };

export type AddPetsTypeMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddPetsTypeMutation = { __typename?: 'mutation_root', insert_PetType?: { __typename?: 'PetType_mutation_response', returning: Array<{ __typename?: 'PetType', id: number }> } | null };

export type UpdatePetTypeMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UpdatePetTypeMutation = { __typename?: 'mutation_root', update_PetType?: { __typename?: 'PetType_mutation_response', returning: Array<{ __typename?: 'PetType', id: number }> } | null };

export type DeletePetTypeMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DeletePetTypeMutation = { __typename?: 'mutation_root', delete_PetType?: { __typename?: 'PetType_mutation_response', returning: Array<{ __typename?: 'PetType', id: number }> } | null };

export type DeleteTypesByIdsMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type DeleteTypesByIdsMutation = { __typename?: 'mutation_root', delete_PetType?: { __typename?: 'PetType_mutation_response', returning: Array<{ __typename?: 'PetType', id: number }> } | null };

export type PetsIndexSubscriptionVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Pet_Order_By> | Pet_Order_By>;
  where?: InputMaybe<Pet_Bool_Exp>;
}>;


export type PetsIndexSubscription = { __typename?: 'subscription_root', Pet: Array<{ __typename?: 'Pet', id: number, birthdate?: any | null, breed?: string | null, chip_number?: string | null, client_id: number, name: string, sex: number, type_id: number, PetType: { __typename?: 'PetType', name: string }, Client: { __typename?: 'Client', name: string } }> };

export type PetsIndexCountSubscriptionVariables = Exact<{
  where?: InputMaybe<Pet_Bool_Exp>;
}>;


export type PetsIndexCountSubscription = { __typename?: 'subscription_root', Pet_aggregate: { __typename?: 'Pet_aggregate', aggregate?: { __typename?: 'Pet_aggregate_fields', count: number } | null } };

export type AddPetMutationVariables = Exact<{
  birthdate?: InputMaybe<Scalars['date']['input']>;
  breed?: InputMaybe<Scalars['String']['input']>;
  chip_number?: InputMaybe<Scalars['String']['input']>;
  client_id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sex?: InputMaybe<Scalars['Int']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AddPetMutation = { __typename?: 'mutation_root', insert_Pet?: { __typename?: 'Pet_mutation_response', returning: Array<{ __typename?: 'Pet', id: number }> } | null };

export type UpdatePetMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  birthdate?: InputMaybe<Scalars['date']['input']>;
  breed?: InputMaybe<Scalars['String']['input']>;
  chip_number?: InputMaybe<Scalars['String']['input']>;
  client_id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sex?: InputMaybe<Scalars['Int']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UpdatePetMutation = { __typename?: 'mutation_root', update_Pet?: { __typename?: 'Pet_mutation_response', returning: Array<{ __typename?: 'Pet', id: number }> } | null };

export type DeletePetMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DeletePetMutation = { __typename?: 'mutation_root', delete_Pet?: { __typename?: 'Pet_mutation_response', returning: Array<{ __typename?: 'Pet', id: number }> } | null };

export type DeletePetsByIdsMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type DeletePetsByIdsMutation = { __typename?: 'mutation_root', delete_Pet?: { __typename?: 'Pet_mutation_response', returning: Array<{ __typename?: 'Pet', id: number }> } | null };

export type PetTypeFkSubscriptionVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
}>;


export type PetTypeFkSubscription = { __typename?: 'subscription_root', PetType: Array<{ __typename?: 'PetType', id: number, name: string }> };

export type PetTypeModelFkQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type PetTypeModelFkQuery = { __typename?: 'query_root', PetType_by_pk?: { __typename?: 'PetType', id: number, name: string } | null };

export type ClientFkSubscriptionVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
}>;


export type ClientFkSubscription = { __typename?: 'subscription_root', Client: Array<{ __typename?: 'Client', id: number, name: string, email?: string | null, passport?: string | null, passport_when?: any | null, passport_where?: string | null, phone_number: string }> };

export type ClientModelFkQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ClientModelFkQuery = { __typename?: 'query_root', Client_by_pk?: { __typename?: 'Client', id: number, name: string, email?: string | null, passport?: string | null, passport_when?: any | null, passport_where?: string | null, phone_number: string } | null };

export type GetRegistrationDocumentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRegistrationDocumentsQuery = { __typename?: 'query_root', RegistrationDocument: Array<{ __typename?: 'RegistrationDocument', id: number, document_id: number, Document: { __typename?: 'Documents', name: string } }> };

export type DeleteRegistrationDocumentMutationVariables = Exact<{
  _in?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type DeleteRegistrationDocumentMutation = { __typename?: 'mutation_root', delete_RegistrationDocument?: { __typename?: 'RegistrationDocument_mutation_response', returning: Array<{ __typename?: 'RegistrationDocument', id: number }> } | null };

export type AddRegistrationDocumentMutationVariables = Exact<{
  objects?: InputMaybe<Array<RegistrationDocument_Insert_Input> | RegistrationDocument_Insert_Input>;
}>;


export type AddRegistrationDocumentMutation = { __typename?: 'mutation_root', insert_RegistrationDocument?: { __typename?: 'RegistrationDocument_mutation_response', returning: Array<{ __typename?: 'RegistrationDocument', id: number }> } | null };

export type TakeDoctorsEditVisitsSubscriptionVariables = Exact<{
  date?: InputMaybe<Scalars['date']['input']>;
  doctor_id?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type TakeDoctorsEditVisitsSubscription = { __typename?: 'subscription_root', Department: Array<{ __typename?: 'Department', id: number, name: string, Visits: Array<{ __typename?: 'Visit', id: number, time: any, patient_id: number, patient_card: any, from_id: any, doctor_id?: any | null, department_id: number, date: any, current_reception_id?: number | null, client_id: number, status: number, without_time: boolean, visit_start?: any | null, Client: { __typename?: 'Client', name: string, phone_number: string }, Doctor?: { __typename?: 'Users', Name: string } | null, Pet: { __typename?: 'Pet', name: string, birthdate?: any | null, sex: number, breed?: string | null, PetType: { __typename?: 'PetType', name: string } }, From: { __typename?: 'Users', Name: string }, ReceptionsList: Array<{ __typename?: 'Reception', id: number, start_time: any, end_time?: any | null, internal_recomendation: string, patient_recomendation: string, doctor_id: any, From: { __typename?: 'Users', Name: string }, Doctor: { __typename?: 'Users', Name: string }, Medicines: Array<{ __typename?: 'ReceptionMedicines', value: any, medicine_id: number, reception_id: number, id: number, done: boolean, Medicine: { __typename?: 'Medicine', name: string, id: number, price: any } }>, Services: Array<{ __typename?: 'ReceptionServices', id: number, service_id: number, price: any, value: any, reception_id: number, Service: { __typename?: 'Service', id: number, name: string, price: any } }> }>, VisitFiles: Array<{ __typename?: 'VisitFiles', id: number, file_name: string, name: string }>, VisitDocuments: Array<{ __typename?: 'VisitDocument', name: string, data: string, id: number }> }> }> };

export type SaveEditVisitMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  patient_card?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type SaveEditVisitMutation = { __typename?: 'mutation_root', update_Visit?: { __typename?: 'Visit_mutation_response', returning: Array<{ __typename?: 'Visit', id: number }> } | null };

export type SaveVisitReceptionMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  internal_recomendation?: InputMaybe<Scalars['String']['input']>;
  patient_recomendation?: InputMaybe<Scalars['String']['input']>;
  services?: InputMaybe<Array<ReceptionServices_Insert_Input> | ReceptionServices_Insert_Input>;
  medicines?: InputMaybe<Array<ReceptionMedicines_Insert_Input> | ReceptionMedicines_Insert_Input>;
}>;


export type SaveVisitReceptionMutation = { __typename?: 'mutation_root', update_Reception?: { __typename?: 'Reception_mutation_response', returning: Array<{ __typename?: 'Reception', id: number }> } | null, insert_ReceptionServices?: { __typename?: 'ReceptionServices_mutation_response', returning: Array<{ __typename?: 'ReceptionServices', id: number }> } | null, insert_ReceptionMedicines?: { __typename?: 'ReceptionMedicines_mutation_response', returning: Array<{ __typename?: 'ReceptionMedicines', id: number }> } | null };

export type GetClientForEditQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetClientForEditQuery = { __typename?: 'query_root', Client: Array<{ __typename?: 'Client', phone_number: string, id: number, address?: string | null, email?: string | null, name: string, passport?: string | null, passport_when?: any | null, passport_where?: string | null, serial?: string | null }> };

export type GetPetForEditQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetPetForEditQuery = { __typename?: 'query_root', Pet: Array<{ __typename?: 'Pet', id: number, name: string, birthdate?: any | null, breed?: string | null, chip_number?: string | null, client_id: number, sex: number, type_id: number, PetType: { __typename?: 'PetType', name: string }, Client: { __typename?: 'Client', name: string } }> };

export type GetSchemasWithCategoriesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetSchemasWithCategoriesSubscription = { __typename?: 'subscription_root', SchemaCategory: Array<{ __typename?: 'SchemaCategory', id: number, name: string, Childs: Array<{ __typename?: 'SchemaCategory', id: number, name: string }> }> };

export type AddSchemaCategoryMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AddSchemaCategoryMutation = { __typename?: 'mutation_root', insert_SchemaCategory?: { __typename?: 'SchemaCategory_mutation_response', returning: Array<{ __typename?: 'SchemaCategory', id: number }> } | null };

export type UpdateSchemaCategoryMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateSchemaCategoryMutation = { __typename?: 'mutation_root', update_SchemaCategory?: { __typename?: 'SchemaCategory_mutation_response', returning: Array<{ __typename?: 'SchemaCategory', id: number }> } | null };

export type DeleteSchemaCategoryMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DeleteSchemaCategoryMutation = { __typename?: 'mutation_root', delete_SchemaCategory?: { __typename?: 'SchemaCategory_mutation_response', returning: Array<{ __typename?: 'SchemaCategory', id: number }> } | null };

export type GetSchemasForCategorySubscriptionVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Schema_Order_By> | Schema_Order_By>;
}>;


export type GetSchemasForCategorySubscription = { __typename?: 'subscription_root', Schema: Array<{ __typename?: 'Schema', id: number, category_id: number, name: string, SchemaMedicines: Array<{ __typename?: 'SchemaMedicine', id: number, schema_id: number, medicine_id: number, value?: any | null, Medicine: { __typename?: 'Medicine', name: string, price: any, id: number, ut_id?: string | null } }>, SchemaServices: Array<{ __typename?: 'SchemaService', id: number, schema_id: number, price?: any | null, service_id: number, value: any, Service: { __typename?: 'Service', name: string, price: any, id: number, ut_id?: string | null } }> }> };

export type GetSchemasForCategoryCountSubscriptionVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Schema_Order_By> | Schema_Order_By>;
}>;


export type GetSchemasForCategoryCountSubscription = { __typename?: 'subscription_root', Schema_aggregate: { __typename?: 'Schema_aggregate', aggregate?: { __typename?: 'Schema_aggregate_fields', count: number } | null } };

export type AddSchemaMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  category_id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AddSchemaMutation = { __typename?: 'mutation_root', insert_Schema?: { __typename?: 'Schema_mutation_response', returning: Array<{ __typename?: 'Schema', id: number, name: string, category_id: number }> } | null };

export type UpdateSchemaMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateSchemaMutation = { __typename?: 'mutation_root', update_Schema?: { __typename?: 'Schema_mutation_response', returning: Array<{ __typename?: 'Schema', id: number }> } | null };

export type DeleteSchemaMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DeleteSchemaMutation = { __typename?: 'mutation_root', delete_Schema?: { __typename?: 'Schema_mutation_response', returning: Array<{ __typename?: 'Schema', id: number }> } | null };

export type DeleteSchemasByIdsMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type DeleteSchemasByIdsMutation = { __typename?: 'mutation_root', delete_Schema?: { __typename?: 'Schema_mutation_response', returning: Array<{ __typename?: 'Schema', id: number }> } | null };

export type MedicineTypeFkSubscriptionVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
}>;


export type MedicineTypeFkSubscription = { __typename?: 'subscription_root', Medicine: Array<{ __typename?: 'Medicine', id: number, name: string, price: any, ut_id?: string | null }> };

export type MedicineTypeModelFkQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type MedicineTypeModelFkQuery = { __typename?: 'query_root', Medicine_by_pk?: { __typename?: 'Medicine', id: number, name: string, price: any, ut_id?: string | null } | null };

export type UnitTypeFkSubscriptionVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
}>;


export type UnitTypeFkSubscription = { __typename?: 'subscription_root', MedicineUnit: Array<{ __typename?: 'MedicineUnit', id: number, name: string }> };

export type ServiceTypeFkSubscriptionVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
}>;


export type ServiceTypeFkSubscription = { __typename?: 'subscription_root', Service: Array<{ __typename?: 'Service', id: number, name: string, price: any, ut_id?: string | null }> };

export type ServiceTypeModelFkQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ServiceTypeModelFkQuery = { __typename?: 'query_root', Service_by_pk?: { __typename?: 'Service', id: number, name: string, price: any, ut_id?: string | null } | null };

export type DeleteSchemaMedicineMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DeleteSchemaMedicineMutation = { __typename?: 'mutation_root', delete_SchemaMedicine?: { __typename?: 'SchemaMedicine_mutation_response', returning: Array<{ __typename?: 'SchemaMedicine', id: number }> } | null };

export type UpdateSchemaMedicineMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}>;


export type UpdateSchemaMedicineMutation = { __typename?: 'mutation_root', update_SchemaMedicine?: { __typename?: 'SchemaMedicine_mutation_response', returning: Array<{ __typename?: 'SchemaMedicine', id: number }> } | null };

export type AddSchemaMedicinesMutationVariables = Exact<{
  objects?: InputMaybe<Array<SchemaMedicine_Insert_Input> | SchemaMedicine_Insert_Input>;
}>;


export type AddSchemaMedicinesMutation = { __typename?: 'mutation_root', insert_SchemaMedicine?: { __typename?: 'SchemaMedicine_mutation_response', returning: Array<{ __typename?: 'SchemaMedicine', id: number }> } | null };

export type DeleteSchemaServiceMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DeleteSchemaServiceMutation = { __typename?: 'mutation_root', delete_SchemaService?: { __typename?: 'SchemaService_mutation_response', returning: Array<{ __typename?: 'SchemaService', id: number }> } | null };

export type UpdateSchemaServiceMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  service_id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}>;


export type UpdateSchemaServiceMutation = { __typename?: 'mutation_root', update_SchemaService?: { __typename?: 'SchemaService_mutation_response', returning: Array<{ __typename?: 'SchemaService', id: number }> } | null };

export type AddSchemaServicesMutationVariables = Exact<{
  objects?: InputMaybe<Array<SchemaService_Insert_Input> | SchemaService_Insert_Input>;
}>;


export type AddSchemaServicesMutation = { __typename?: 'mutation_root', insert_SchemaService?: { __typename?: 'SchemaService_mutation_response', returning: Array<{ __typename?: 'SchemaService', id: number }> } | null };

export type ServicesIndexSubscriptionVariables = Exact<{
  order_by?: InputMaybe<Array<Service_Order_By> | Service_Order_By>;
  where?: InputMaybe<Service_Bool_Exp>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ServicesIndexSubscription = { __typename?: 'subscription_root', Service: Array<{ __typename?: 'Service', id: number, name: string, price: any, ut_id?: string | null }> };

export type ServiceIndexCountSubscriptionVariables = Exact<{
  where?: InputMaybe<Service_Bool_Exp>;
}>;


export type ServiceIndexCountSubscription = { __typename?: 'subscription_root', Service_aggregate: { __typename?: 'Service_aggregate', aggregate?: { __typename?: 'Service_aggregate_fields', count: number } | null } };

export type AddServiceMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  ut_id?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddServiceMutation = { __typename?: 'mutation_root', insert_Service?: { __typename?: 'Service_mutation_response', returning: Array<{ __typename?: 'Service', id: number }> } | null };

export type UpdateServiceMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  ut_id?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateServiceMutation = { __typename?: 'mutation_root', update_Service?: { __typename?: 'Service_mutation_response', returning: Array<{ __typename?: 'Service', id: number }> } | null };

export type DeleteServiceMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DeleteServiceMutation = { __typename?: 'mutation_root', delete_Service?: { __typename?: 'Service_mutation_response', returning: Array<{ __typename?: 'Service', id: number }> } | null };

export type DeleteServicesByIdsMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type DeleteServicesByIdsMutation = { __typename?: 'mutation_root', delete_Service?: { __typename?: 'Service_mutation_response', returning: Array<{ __typename?: 'Service', id: number }> } | null };

export type GetTemplatesByUserSubscriptionVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Order_By> | Template_Order_By>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type GetTemplatesByUserSubscription = { __typename?: 'subscription_root', Template: Array<{ __typename?: 'Template', id: number, name: string, internal_recomendation?: string | null, patient_recomendation?: string | null, TemplateMedicines: Array<{ __typename?: 'TemplateMedicines', id: number, medicine_id: number, value: any, template_id: number, Medicine: { __typename?: 'Medicine', name: string, price: any, ut_id?: string | null, id: number } }>, TemplateServices: Array<{ __typename?: 'TemplateServices', id: number, price?: any | null, service_id: number, template_id: number, value: any, Service: { __typename?: 'Service', name: string, price: any, id: number, ut_id?: string | null } }> }> };

export type GetTemplatesByUserCountSubscriptionVariables = Exact<{
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type GetTemplatesByUserCountSubscription = { __typename?: 'subscription_root', Template_aggregate: { __typename?: 'Template_aggregate', aggregate?: { __typename?: 'Template_aggregate_fields', count: number } | null } };

export type AddTemplateMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  internal_recomendation?: InputMaybe<Scalars['String']['input']>;
  patient_recomendation?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type AddTemplateMutation = { __typename?: 'mutation_root', insert_Template?: { __typename?: 'Template_mutation_response', returning: Array<{ __typename?: 'Template', id: number }> } | null };

export type UpdateTemplateMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  internal_recomendation?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  patient_recomendation?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateTemplateMutation = { __typename?: 'mutation_root', update_Template?: { __typename?: 'Template_mutation_response', returning: Array<{ __typename?: 'Template', id: number }> } | null };

export type DeleteTemplateMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DeleteTemplateMutation = { __typename?: 'mutation_root', delete_Template?: { __typename?: 'Template_mutation_response', returning: Array<{ __typename?: 'Template', id: number }> } | null };

export type DeleteTemplatesByIdsMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type DeleteTemplatesByIdsMutation = { __typename?: 'mutation_root', delete_Template?: { __typename?: 'Template_mutation_response', returning: Array<{ __typename?: 'Template', id: number }> } | null };

export type DeleteTemplateMedicineMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DeleteTemplateMedicineMutation = { __typename?: 'mutation_root', delete_TemplateMedicines?: { __typename?: 'TemplateMedicines_mutation_response', returning: Array<{ __typename?: 'TemplateMedicines', id: number }> } | null };

export type UpdateTemplateMedicineMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}>;


export type UpdateTemplateMedicineMutation = { __typename?: 'mutation_root', update_TemplateMedicines?: { __typename?: 'TemplateMedicines_mutation_response', returning: Array<{ __typename?: 'TemplateMedicines', id: number }> } | null };

export type AddTemplateMedicinesMutationVariables = Exact<{
  objects?: InputMaybe<Array<TemplateMedicines_Insert_Input> | TemplateMedicines_Insert_Input>;
}>;


export type AddTemplateMedicinesMutation = { __typename?: 'mutation_root', insert_TemplateMedicines?: { __typename?: 'TemplateMedicines_mutation_response', returning: Array<{ __typename?: 'TemplateMedicines', id: number }> } | null };

export type DeleteTemplateServiceMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DeleteTemplateServiceMutation = { __typename?: 'mutation_root', delete_TemplateServices?: { __typename?: 'TemplateServices_mutation_response', returning: Array<{ __typename?: 'TemplateServices', id: number }> } | null };

export type UpdateTemplateServiceMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  service_id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
}>;


export type UpdateTemplateServiceMutation = { __typename?: 'mutation_root', update_TemplateServices?: { __typename?: 'TemplateServices_mutation_response', returning: Array<{ __typename?: 'TemplateServices', id: number }> } | null };

export type AddTemplateServicesMutationVariables = Exact<{
  objects?: InputMaybe<Array<TemplateServices_Insert_Input> | TemplateServices_Insert_Input>;
}>;


export type AddTemplateServicesMutation = { __typename?: 'mutation_root', insert_TemplateServices?: { __typename?: 'TemplateServices_mutation_response', returning: Array<{ __typename?: 'TemplateServices', id: number }> } | null };

export type MedicineUnitsIndexSubscriptionVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MedicineUnit_Order_By> | MedicineUnit_Order_By>;
  where?: InputMaybe<MedicineUnit_Bool_Exp>;
}>;


export type MedicineUnitsIndexSubscription = { __typename?: 'subscription_root', MedicineUnit: Array<{ __typename?: 'MedicineUnit', name: string, id: number }> };

export type MedicineUnitsIndexCountSubscriptionVariables = Exact<{
  where?: InputMaybe<MedicineUnit_Bool_Exp>;
}>;


export type MedicineUnitsIndexCountSubscription = { __typename?: 'subscription_root', MedicineUnit_aggregate: { __typename?: 'MedicineUnit_aggregate', aggregate?: { __typename?: 'MedicineUnit_aggregate_fields', count: number } | null } };

export type AddMedicineUnitMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddMedicineUnitMutation = { __typename?: 'mutation_root', insert_MedicineUnit?: { __typename?: 'MedicineUnit_mutation_response', returning: Array<{ __typename?: 'MedicineUnit', id: number }> } | null };

export type UpdateMedicineUnitMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UpdateMedicineUnitMutation = { __typename?: 'mutation_root', update_MedicineUnit?: { __typename?: 'MedicineUnit_mutation_response', returning: Array<{ __typename?: 'MedicineUnit', id: number }> } | null };

export type DeleteMedicineUnitMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DeleteMedicineUnitMutation = { __typename?: 'mutation_root', delete_MedicineUnit?: { __typename?: 'MedicineUnit_mutation_response', returning: Array<{ __typename?: 'MedicineUnit', id: number }> } | null };

export type DeleteMedicineUnitsByIdsMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type DeleteMedicineUnitsByIdsMutation = { __typename?: 'mutation_root', delete_MedicineUnit?: { __typename?: 'MedicineUnit_mutation_response', returning: Array<{ __typename?: 'MedicineUnit', id: number }> } | null };

export type UsersIndexSubscriptionVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By> | Users_Order_By>;
  where?: InputMaybe<Users_Bool_Exp>;
}>;


export type UsersIndexSubscription = { __typename?: 'subscription_root', Users: Array<{ __typename?: 'Users', ID: any, Login: string, Name: string, IsActive: boolean, LastSeen: any, DefaultRoleId: any, Role: { __typename?: 'Roles', Name: string } }> };

export type UsersIndexCountSubscriptionVariables = Exact<{
  where?: InputMaybe<Users_Bool_Exp>;
}>;


export type UsersIndexCountSubscription = { __typename?: 'subscription_root', Users_aggregate: { __typename?: 'Users_aggregate', aggregate?: { __typename?: 'Users_aggregate_fields', count: number } | null } };

export type AddUserMutationVariables = Exact<{
  DefaultRoleId?: InputMaybe<Scalars['uuid']['input']>;
  IsActive?: InputMaybe<Scalars['Boolean']['input']>;
  Login?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  Password?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddUserMutation = { __typename?: 'mutation_root', insert_Users?: { __typename?: 'Users_mutation_response', returning: Array<{ __typename?: 'Users', ID: any }> } | null };

export type GetRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolesQuery = { __typename?: 'query_root', Roles: Array<{ __typename?: 'Roles', Name: string, ID: any }> };

export type UpdateUserMutationVariables = Exact<{
  DefaultRoleId?: InputMaybe<Scalars['uuid']['input']>;
  IsActive?: InputMaybe<Scalars['Boolean']['input']>;
  Login?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  ID?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type UpdateUserMutation = { __typename?: 'mutation_root', update_Users?: { __typename?: 'Users_mutation_response', returning: Array<{ __typename?: 'Users', ID: any }> } | null };

export type DeleteUserMutationVariables = Exact<{
  ID?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type DeleteUserMutation = { __typename?: 'mutation_root', delete_Users?: { __typename?: 'Users_mutation_response', returning: Array<{ __typename?: 'Users', ID: any }> } | null };

export type DeleteUsersByIdsMutationVariables = Exact<{
  IDS?: InputMaybe<Array<Scalars['uuid']['input']> | Scalars['uuid']['input']>;
}>;


export type DeleteUsersByIdsMutation = { __typename?: 'mutation_root', delete_Users?: { __typename?: 'Users_mutation_response', returning: Array<{ __typename?: 'Users', ID: any }> } | null };

export type AllDepartmentsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type AllDepartmentsSubscription = { __typename?: 'subscription_root', Department: Array<{ __typename?: 'Department', name: string, id: number }> };

export type GetUsersDepartmentsQueryVariables = Exact<{
  doctor_id?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type GetUsersDepartmentsQuery = { __typename?: 'query_root', DoctorsDepartment: Array<{ __typename?: 'DoctorsDepartment', id: number, department_id: number, Department: { __typename?: 'Department', name: string } }> };

export type DeleteUserDepartmentsMutationVariables = Exact<{
  _in?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type DeleteUserDepartmentsMutation = { __typename?: 'mutation_root', delete_DoctorsDepartment?: { __typename?: 'DoctorsDepartment_mutation_response', returning: Array<{ __typename?: 'DoctorsDepartment', id: number }> } | null };

export type AddUserDepartmentsMutationVariables = Exact<{
  objects?: InputMaybe<Array<DoctorsDepartment_Insert_Input> | DoctorsDepartment_Insert_Input>;
}>;


export type AddUserDepartmentsMutation = { __typename?: 'mutation_root', insert_DoctorsDepartment?: { __typename?: 'DoctorsDepartment_mutation_response', returning: Array<{ __typename?: 'DoctorsDepartment', id: number }> } | null };

export type GetUsersPricesSubscriptionVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type GetUsersPricesSubscription = { __typename?: 'subscription_root', Users: Array<{ __typename?: 'Users', Name: string, ID: any, UserMedicinePayments: Array<{ __typename?: 'UserMedicinePayments', id: number, in_percent: boolean, medicine_id: number, user_id: any, value: any, Medicine: { __typename?: 'Medicine', id: number, name: string, price: any, ut_id?: string | null } }>, UserServicePayments: Array<{ __typename?: 'UserServicePayments', id: number, in_percent: boolean, service_id: number, user_id: any, value: any, Service: { __typename?: 'Service', id: number, name: string, price: any, ut_id?: string | null } }> }> };

export type GetMedicinesUnitsAndServicesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMedicinesUnitsAndServicesQuery = { __typename?: 'query_root', Medicine: Array<{ __typename?: 'Medicine', id: number, name: string, price: any, ut_id?: string | null }>, Service: Array<{ __typename?: 'Service', id: number, name: string, price: any, ut_id?: string | null }> };

export type DeleteUserMedicineMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DeleteUserMedicineMutation = { __typename?: 'mutation_root', delete_UserMedicinePayments?: { __typename?: 'UserMedicinePayments_mutation_response', returning: Array<{ __typename?: 'UserMedicinePayments', id: number }> } | null };

export type UpdateUserMedicineMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  medicine_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  in_percent?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateUserMedicineMutation = { __typename?: 'mutation_root', update_UserMedicinePayments?: { __typename?: 'UserMedicinePayments_mutation_response', returning: Array<{ __typename?: 'UserMedicinePayments', id: number }> } | null };

export type AddUserMedicinesMutationVariables = Exact<{
  objects?: InputMaybe<Array<UserMedicinePayments_Insert_Input> | UserMedicinePayments_Insert_Input>;
}>;


export type AddUserMedicinesMutation = { __typename?: 'mutation_root', insert_UserMedicinePayments?: { __typename?: 'UserMedicinePayments_mutation_response', returning: Array<{ __typename?: 'UserMedicinePayments', id: number }> } | null };

export type DeleteUserServiceMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DeleteUserServiceMutation = { __typename?: 'mutation_root', delete_UserServicePayments?: { __typename?: 'UserServicePayments_mutation_response', returning: Array<{ __typename?: 'UserServicePayments', id: number }> } | null };

export type UpdateUserServiceMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  service_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  in_percent?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateUserServiceMutation = { __typename?: 'mutation_root', update_UserServicePayments?: { __typename?: 'UserServicePayments_mutation_response', returning: Array<{ __typename?: 'UserServicePayments', id: number }> } | null };

export type AddUserServicesMutationVariables = Exact<{
  objects?: InputMaybe<Array<UserServicePayments_Insert_Input> | UserServicePayments_Insert_Input>;
}>;


export type AddUserServicesMutation = { __typename?: 'mutation_root', insert_UserServicePayments?: { __typename?: 'UserServicePayments_mutation_response', returning: Array<{ __typename?: 'UserServicePayments', id: number }> } | null };

export type GetUserNameQueryVariables = Exact<{
  ID?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type GetUserNameQuery = { __typename?: 'query_root', Users_by_pk?: { __typename?: 'Users', Name: string } | null };


export const LatestChatMessagesDocument = gql`
    subscription LatestChatMessages {
  ChatMessages(limit: 100, order_by: {date: asc}) {
    id
    date
    message
    user_id
    User {
      Name
      Role {
        Name
      }
    }
  }
}
    `;

/**
 * __useLatestChatMessagesSubscription__
 *
 * To run a query within a React component, call `useLatestChatMessagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLatestChatMessagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestChatMessagesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useLatestChatMessagesSubscription(baseOptions?: Apollo.SubscriptionHookOptions<LatestChatMessagesSubscription, LatestChatMessagesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<LatestChatMessagesSubscription, LatestChatMessagesSubscriptionVariables>(LatestChatMessagesDocument, options);
      }
export type LatestChatMessagesSubscriptionHookResult = ReturnType<typeof useLatestChatMessagesSubscription>;
export type LatestChatMessagesSubscriptionResult = Apollo.SubscriptionResult<LatestChatMessagesSubscription>;
export const LatestChatMessagesFirstDocument = gql`
    query LatestChatMessagesFirst {
  ChatMessages(limit: 100, order_by: {date: asc}) {
    id
    date
    message
    user_id
    User {
      Name
      Role {
        Name
      }
    }
  }
}
    `;

/**
 * __useLatestChatMessagesFirstQuery__
 *
 * To run a query within a React component, call `useLatestChatMessagesFirstQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestChatMessagesFirstQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestChatMessagesFirstQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestChatMessagesFirstQuery(baseOptions?: Apollo.QueryHookOptions<LatestChatMessagesFirstQuery, LatestChatMessagesFirstQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LatestChatMessagesFirstQuery, LatestChatMessagesFirstQueryVariables>(LatestChatMessagesFirstDocument, options);
      }
export function useLatestChatMessagesFirstLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LatestChatMessagesFirstQuery, LatestChatMessagesFirstQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LatestChatMessagesFirstQuery, LatestChatMessagesFirstQueryVariables>(LatestChatMessagesFirstDocument, options);
        }
export type LatestChatMessagesFirstQueryHookResult = ReturnType<typeof useLatestChatMessagesFirstQuery>;
export type LatestChatMessagesFirstLazyQueryHookResult = ReturnType<typeof useLatestChatMessagesFirstLazyQuery>;
export type LatestChatMessagesFirstQueryResult = Apollo.QueryResult<LatestChatMessagesFirstQuery, LatestChatMessagesFirstQueryVariables>;
export const SendMessageDocument = gql`
    mutation SendMessage($message: String = "", $user_id: uuid = "") {
  insert_ChatMessages(objects: {message: $message, user_id: $user_id}) {
    returning {
      id
    }
  }
}
    `;
export type SendMessageMutationFn = Apollo.MutationFunction<SendMessageMutation, SendMessageMutationVariables>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      message: // value for 'message'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageMutation, SendMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(SendMessageDocument, options);
      }
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<SendMessageMutation, SendMessageMutationVariables>;
export const DoctorsCardIndexDocument = gql`
    subscription DoctorsCardIndex($limit: Int = 0, $offset: Int = 0, $order_by: [DoctorsCard_order_by!] = {}, $where: DoctorsCard_bool_exp = {}) {
  DoctorsCard(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    id
    medicine_id
    Medicine {
      id
      name
      price
    }
  }
}
    `;

/**
 * __useDoctorsCardIndexSubscription__
 *
 * To run a query within a React component, call `useDoctorsCardIndexSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDoctorsCardIndexSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorsCardIndexSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDoctorsCardIndexSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DoctorsCardIndexSubscription, DoctorsCardIndexSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DoctorsCardIndexSubscription, DoctorsCardIndexSubscriptionVariables>(DoctorsCardIndexDocument, options);
      }
export type DoctorsCardIndexSubscriptionHookResult = ReturnType<typeof useDoctorsCardIndexSubscription>;
export type DoctorsCardIndexSubscriptionResult = Apollo.SubscriptionResult<DoctorsCardIndexSubscription>;
export const DoctorsCardIndexCountDocument = gql`
    subscription DoctorsCardIndexCount($where: DoctorsCard_bool_exp = {}) {
  DoctorsCard_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useDoctorsCardIndexCountSubscription__
 *
 * To run a query within a React component, call `useDoctorsCardIndexCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDoctorsCardIndexCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorsCardIndexCountSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDoctorsCardIndexCountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DoctorsCardIndexCountSubscription, DoctorsCardIndexCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DoctorsCardIndexCountSubscription, DoctorsCardIndexCountSubscriptionVariables>(DoctorsCardIndexCountDocument, options);
      }
export type DoctorsCardIndexCountSubscriptionHookResult = ReturnType<typeof useDoctorsCardIndexCountSubscription>;
export type DoctorsCardIndexCountSubscriptionResult = Apollo.SubscriptionResult<DoctorsCardIndexCountSubscription>;
export const AddDoctorsCardDocument = gql`
    mutation AddDoctorsCard($medicine_id: Int = 0) {
  insert_DoctorsCard(objects: {medicine_id: $medicine_id}) {
    returning {
      id
    }
  }
}
    `;
export type AddDoctorsCardMutationFn = Apollo.MutationFunction<AddDoctorsCardMutation, AddDoctorsCardMutationVariables>;

/**
 * __useAddDoctorsCardMutation__
 *
 * To run a mutation, you first call `useAddDoctorsCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDoctorsCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDoctorsCardMutation, { data, loading, error }] = useAddDoctorsCardMutation({
 *   variables: {
 *      medicine_id: // value for 'medicine_id'
 *   },
 * });
 */
export function useAddDoctorsCardMutation(baseOptions?: Apollo.MutationHookOptions<AddDoctorsCardMutation, AddDoctorsCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDoctorsCardMutation, AddDoctorsCardMutationVariables>(AddDoctorsCardDocument, options);
      }
export type AddDoctorsCardMutationHookResult = ReturnType<typeof useAddDoctorsCardMutation>;
export type AddDoctorsCardMutationResult = Apollo.MutationResult<AddDoctorsCardMutation>;
export type AddDoctorsCardMutationOptions = Apollo.BaseMutationOptions<AddDoctorsCardMutation, AddDoctorsCardMutationVariables>;
export const UpdateDoctorsCardDocument = gql`
    mutation UpdateDoctorsCard($id: Int = 0, $medicine_id: Int = 0) {
  update_DoctorsCard(where: {id: {_eq: $id}}, _set: {medicine_id: $medicine_id}) {
    returning {
      id
    }
  }
}
    `;
export type UpdateDoctorsCardMutationFn = Apollo.MutationFunction<UpdateDoctorsCardMutation, UpdateDoctorsCardMutationVariables>;

/**
 * __useUpdateDoctorsCardMutation__
 *
 * To run a mutation, you first call `useUpdateDoctorsCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDoctorsCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDoctorsCardMutation, { data, loading, error }] = useUpdateDoctorsCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      medicine_id: // value for 'medicine_id'
 *   },
 * });
 */
export function useUpdateDoctorsCardMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDoctorsCardMutation, UpdateDoctorsCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDoctorsCardMutation, UpdateDoctorsCardMutationVariables>(UpdateDoctorsCardDocument, options);
      }
export type UpdateDoctorsCardMutationHookResult = ReturnType<typeof useUpdateDoctorsCardMutation>;
export type UpdateDoctorsCardMutationResult = Apollo.MutationResult<UpdateDoctorsCardMutation>;
export type UpdateDoctorsCardMutationOptions = Apollo.BaseMutationOptions<UpdateDoctorsCardMutation, UpdateDoctorsCardMutationVariables>;
export const DeleteDoctorsCardDocument = gql`
    mutation DeleteDoctorsCard($id: Int = 0) {
  delete_DoctorsCard(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteDoctorsCardMutationFn = Apollo.MutationFunction<DeleteDoctorsCardMutation, DeleteDoctorsCardMutationVariables>;

/**
 * __useDeleteDoctorsCardMutation__
 *
 * To run a mutation, you first call `useDeleteDoctorsCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDoctorsCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDoctorsCardMutation, { data, loading, error }] = useDeleteDoctorsCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDoctorsCardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDoctorsCardMutation, DeleteDoctorsCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDoctorsCardMutation, DeleteDoctorsCardMutationVariables>(DeleteDoctorsCardDocument, options);
      }
export type DeleteDoctorsCardMutationHookResult = ReturnType<typeof useDeleteDoctorsCardMutation>;
export type DeleteDoctorsCardMutationResult = Apollo.MutationResult<DeleteDoctorsCardMutation>;
export type DeleteDoctorsCardMutationOptions = Apollo.BaseMutationOptions<DeleteDoctorsCardMutation, DeleteDoctorsCardMutationVariables>;
export const DeleteDoctorsCardByIdsDocument = gql`
    mutation DeleteDoctorsCardByIds($ids: [Int!] = 0) {
  delete_DoctorsCard(where: {id: {_in: $ids}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteDoctorsCardByIdsMutationFn = Apollo.MutationFunction<DeleteDoctorsCardByIdsMutation, DeleteDoctorsCardByIdsMutationVariables>;

/**
 * __useDeleteDoctorsCardByIdsMutation__
 *
 * To run a mutation, you first call `useDeleteDoctorsCardByIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDoctorsCardByIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDoctorsCardByIdsMutation, { data, loading, error }] = useDeleteDoctorsCardByIdsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteDoctorsCardByIdsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDoctorsCardByIdsMutation, DeleteDoctorsCardByIdsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDoctorsCardByIdsMutation, DeleteDoctorsCardByIdsMutationVariables>(DeleteDoctorsCardByIdsDocument, options);
      }
export type DeleteDoctorsCardByIdsMutationHookResult = ReturnType<typeof useDeleteDoctorsCardByIdsMutation>;
export type DeleteDoctorsCardByIdsMutationResult = Apollo.MutationResult<DeleteDoctorsCardByIdsMutation>;
export type DeleteDoctorsCardByIdsMutationOptions = Apollo.BaseMutationOptions<DeleteDoctorsCardByIdsMutation, DeleteDoctorsCardByIdsMutationVariables>;
export const MedicineFkDocument = gql`
    subscription MedicineFk($limit: Int = 10, $searchText: String = "%%") {
  Medicine(where: {name: {_ilike: $searchText}}, limit: $limit) {
    id
    name
  }
}
    `;

/**
 * __useMedicineFkSubscription__
 *
 * To run a query within a React component, call `useMedicineFkSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMedicineFkSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicineFkSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useMedicineFkSubscription(baseOptions?: Apollo.SubscriptionHookOptions<MedicineFkSubscription, MedicineFkSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<MedicineFkSubscription, MedicineFkSubscriptionVariables>(MedicineFkDocument, options);
      }
export type MedicineFkSubscriptionHookResult = ReturnType<typeof useMedicineFkSubscription>;
export type MedicineFkSubscriptionResult = Apollo.SubscriptionResult<MedicineFkSubscription>;
export const MedicineModelFkDocument = gql`
    query MedicineModelFk($id: Int = 0) {
  Medicine_by_pk(id: $id) {
    id
    name
    price
  }
}
    `;

/**
 * __useMedicineModelFkQuery__
 *
 * To run a query within a React component, call `useMedicineModelFkQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicineModelFkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicineModelFkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMedicineModelFkQuery(baseOptions?: Apollo.QueryHookOptions<MedicineModelFkQuery, MedicineModelFkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MedicineModelFkQuery, MedicineModelFkQueryVariables>(MedicineModelFkDocument, options);
      }
export function useMedicineModelFkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MedicineModelFkQuery, MedicineModelFkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MedicineModelFkQuery, MedicineModelFkQueryVariables>(MedicineModelFkDocument, options);
        }
export type MedicineModelFkQueryHookResult = ReturnType<typeof useMedicineModelFkQuery>;
export type MedicineModelFkLazyQueryHookResult = ReturnType<typeof useMedicineModelFkLazyQuery>;
export type MedicineModelFkQueryResult = Apollo.QueryResult<MedicineModelFkQuery, MedicineModelFkQueryVariables>;
export const ClientsIndexDocument = gql`
    subscription ClientsIndex($limit: Int = 10, $offset: Int = 0, $where: Client_bool_exp = {}, $order_by: [Client_order_by!] = {}) {
  Client(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
    id
    email
    name
    passport
    passport_when
    passport_where
    phone_number
    address
    serial
  }
}
    `;

/**
 * __useClientsIndexSubscription__
 *
 * To run a query within a React component, call `useClientsIndexSubscription` and pass it any options that fit your needs.
 * When your component renders, `useClientsIndexSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientsIndexSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useClientsIndexSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ClientsIndexSubscription, ClientsIndexSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ClientsIndexSubscription, ClientsIndexSubscriptionVariables>(ClientsIndexDocument, options);
      }
export type ClientsIndexSubscriptionHookResult = ReturnType<typeof useClientsIndexSubscription>;
export type ClientsIndexSubscriptionResult = Apollo.SubscriptionResult<ClientsIndexSubscription>;
export const ClientsIndexCountDocument = gql`
    subscription ClientsIndexCount($where: Client_bool_exp = {}) {
  Client_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useClientsIndexCountSubscription__
 *
 * To run a query within a React component, call `useClientsIndexCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useClientsIndexCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientsIndexCountSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useClientsIndexCountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ClientsIndexCountSubscription, ClientsIndexCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ClientsIndexCountSubscription, ClientsIndexCountSubscriptionVariables>(ClientsIndexCountDocument, options);
      }
export type ClientsIndexCountSubscriptionHookResult = ReturnType<typeof useClientsIndexCountSubscription>;
export type ClientsIndexCountSubscriptionResult = Apollo.SubscriptionResult<ClientsIndexCountSubscription>;
export const AddClientDocument = gql`
    mutation AddClient($email: String = "", $name: String = "", $passport: String = "", $passport_when: date = "", $passport_where: String = "", $phone_number: String = "", $address: String = "", $serial: String = "") {
  insert_Client(
    objects: {email: $email, name: $name, passport: $passport, passport_when: $passport_when, passport_where: $passport_where, phone_number: $phone_number, address: $address, serial: $serial}
  ) {
    returning {
      id
    }
  }
}
    `;
export type AddClientMutationFn = Apollo.MutationFunction<AddClientMutation, AddClientMutationVariables>;

/**
 * __useAddClientMutation__
 *
 * To run a mutation, you first call `useAddClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClientMutation, { data, loading, error }] = useAddClientMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      passport: // value for 'passport'
 *      passport_when: // value for 'passport_when'
 *      passport_where: // value for 'passport_where'
 *      phone_number: // value for 'phone_number'
 *      address: // value for 'address'
 *      serial: // value for 'serial'
 *   },
 * });
 */
export function useAddClientMutation(baseOptions?: Apollo.MutationHookOptions<AddClientMutation, AddClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddClientMutation, AddClientMutationVariables>(AddClientDocument, options);
      }
export type AddClientMutationHookResult = ReturnType<typeof useAddClientMutation>;
export type AddClientMutationResult = Apollo.MutationResult<AddClientMutation>;
export type AddClientMutationOptions = Apollo.BaseMutationOptions<AddClientMutation, AddClientMutationVariables>;
export const UpdateClientDocument = gql`
    mutation UpdateClient($id: Int = 0, $email: String = "", $name: String = "", $passport: String = "", $passport_when: date = "", $passport_where: String = "", $phone_number: String = "", $address: String = "", $serial: String = "") {
  update_Client(
    where: {id: {_eq: $id}}
    _set: {email: $email, name: $name, passport: $passport, passport_when: $passport_when, passport_where: $passport_where, phone_number: $phone_number, address: $address, serial: $serial}
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpdateClientMutationFn = Apollo.MutationFunction<UpdateClientMutation, UpdateClientMutationVariables>;

/**
 * __useUpdateClientMutation__
 *
 * To run a mutation, you first call `useUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientMutation, { data, loading, error }] = useUpdateClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      passport: // value for 'passport'
 *      passport_when: // value for 'passport_when'
 *      passport_where: // value for 'passport_where'
 *      phone_number: // value for 'phone_number'
 *      address: // value for 'address'
 *      serial: // value for 'serial'
 *   },
 * });
 */
export function useUpdateClientMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientMutation, UpdateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientMutation, UpdateClientMutationVariables>(UpdateClientDocument, options);
      }
export type UpdateClientMutationHookResult = ReturnType<typeof useUpdateClientMutation>;
export type UpdateClientMutationResult = Apollo.MutationResult<UpdateClientMutation>;
export type UpdateClientMutationOptions = Apollo.BaseMutationOptions<UpdateClientMutation, UpdateClientMutationVariables>;
export const DeleteClientDocument = gql`
    mutation DeleteClient($id: Int = 0) {
  delete_Client(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteClientMutationFn = Apollo.MutationFunction<DeleteClientMutation, DeleteClientMutationVariables>;

/**
 * __useDeleteClientMutation__
 *
 * To run a mutation, you first call `useDeleteClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientMutation, { data, loading, error }] = useDeleteClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClientMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClientMutation, DeleteClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClientMutation, DeleteClientMutationVariables>(DeleteClientDocument, options);
      }
export type DeleteClientMutationHookResult = ReturnType<typeof useDeleteClientMutation>;
export type DeleteClientMutationResult = Apollo.MutationResult<DeleteClientMutation>;
export type DeleteClientMutationOptions = Apollo.BaseMutationOptions<DeleteClientMutation, DeleteClientMutationVariables>;
export const DeleteClientsByIdsDocument = gql`
    mutation DeleteClientsByIds($ids: [Int!] = 0) {
  delete_Client(where: {id: {_in: $ids}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteClientsByIdsMutationFn = Apollo.MutationFunction<DeleteClientsByIdsMutation, DeleteClientsByIdsMutationVariables>;

/**
 * __useDeleteClientsByIdsMutation__
 *
 * To run a mutation, you first call `useDeleteClientsByIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientsByIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientsByIdsMutation, { data, loading, error }] = useDeleteClientsByIdsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteClientsByIdsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClientsByIdsMutation, DeleteClientsByIdsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClientsByIdsMutation, DeleteClientsByIdsMutationVariables>(DeleteClientsByIdsDocument, options);
      }
export type DeleteClientsByIdsMutationHookResult = ReturnType<typeof useDeleteClientsByIdsMutation>;
export type DeleteClientsByIdsMutationResult = Apollo.MutationResult<DeleteClientsByIdsMutation>;
export type DeleteClientsByIdsMutationOptions = Apollo.BaseMutationOptions<DeleteClientsByIdsMutation, DeleteClientsByIdsMutationVariables>;
export const DepartmentsIndexDocument = gql`
    subscription DepartmentsIndex($order_by: [Department_order_by!] = {}, $where: Department_bool_exp = {}, $offset: Int = 0, $limit: Int = 10) {
  Department(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    id
    name
  }
}
    `;

/**
 * __useDepartmentsIndexSubscription__
 *
 * To run a query within a React component, call `useDepartmentsIndexSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentsIndexSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentsIndexSubscription({
 *   variables: {
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDepartmentsIndexSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DepartmentsIndexSubscription, DepartmentsIndexSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DepartmentsIndexSubscription, DepartmentsIndexSubscriptionVariables>(DepartmentsIndexDocument, options);
      }
export type DepartmentsIndexSubscriptionHookResult = ReturnType<typeof useDepartmentsIndexSubscription>;
export type DepartmentsIndexSubscriptionResult = Apollo.SubscriptionResult<DepartmentsIndexSubscription>;
export const DepartmentIndexCountDocument = gql`
    subscription DepartmentIndexCount($where: Department_bool_exp = {}) {
  Department_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useDepartmentIndexCountSubscription__
 *
 * To run a query within a React component, call `useDepartmentIndexCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentIndexCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentIndexCountSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDepartmentIndexCountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DepartmentIndexCountSubscription, DepartmentIndexCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DepartmentIndexCountSubscription, DepartmentIndexCountSubscriptionVariables>(DepartmentIndexCountDocument, options);
      }
export type DepartmentIndexCountSubscriptionHookResult = ReturnType<typeof useDepartmentIndexCountSubscription>;
export type DepartmentIndexCountSubscriptionResult = Apollo.SubscriptionResult<DepartmentIndexCountSubscription>;
export const AddDepartmentDocument = gql`
    mutation AddDepartment($name: String = "") {
  insert_Department(objects: {name: $name}) {
    returning {
      id
    }
  }
}
    `;
export type AddDepartmentMutationFn = Apollo.MutationFunction<AddDepartmentMutation, AddDepartmentMutationVariables>;

/**
 * __useAddDepartmentMutation__
 *
 * To run a mutation, you first call `useAddDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDepartmentMutation, { data, loading, error }] = useAddDepartmentMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddDepartmentMutation(baseOptions?: Apollo.MutationHookOptions<AddDepartmentMutation, AddDepartmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDepartmentMutation, AddDepartmentMutationVariables>(AddDepartmentDocument, options);
      }
export type AddDepartmentMutationHookResult = ReturnType<typeof useAddDepartmentMutation>;
export type AddDepartmentMutationResult = Apollo.MutationResult<AddDepartmentMutation>;
export type AddDepartmentMutationOptions = Apollo.BaseMutationOptions<AddDepartmentMutation, AddDepartmentMutationVariables>;
export const UpdateDepartmentDocument = gql`
    mutation UpdateDepartment($name: String = "", $id: Int = 10) {
  update_Department(where: {id: {_eq: $id}}, _set: {name: $name}) {
    returning {
      id
    }
  }
}
    `;
export type UpdateDepartmentMutationFn = Apollo.MutationFunction<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>;

/**
 * __useUpdateDepartmentMutation__
 *
 * To run a mutation, you first call `useUpdateDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDepartmentMutation, { data, loading, error }] = useUpdateDepartmentMutation({
 *   variables: {
 *      name: // value for 'name'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateDepartmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>(UpdateDepartmentDocument, options);
      }
export type UpdateDepartmentMutationHookResult = ReturnType<typeof useUpdateDepartmentMutation>;
export type UpdateDepartmentMutationResult = Apollo.MutationResult<UpdateDepartmentMutation>;
export type UpdateDepartmentMutationOptions = Apollo.BaseMutationOptions<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>;
export const DeleteDepartmentDocument = gql`
    mutation DeleteDepartment($id: Int = 10) {
  delete_Department(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteDepartmentMutationFn = Apollo.MutationFunction<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>;

/**
 * __useDeleteDepartmentMutation__
 *
 * To run a mutation, you first call `useDeleteDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDepartmentMutation, { data, loading, error }] = useDeleteDepartmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDepartmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>(DeleteDepartmentDocument, options);
      }
export type DeleteDepartmentMutationHookResult = ReturnType<typeof useDeleteDepartmentMutation>;
export type DeleteDepartmentMutationResult = Apollo.MutationResult<DeleteDepartmentMutation>;
export type DeleteDepartmentMutationOptions = Apollo.BaseMutationOptions<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>;
export const DeleteDepartmentsByIdsDocument = gql`
    mutation DeleteDepartmentsByIds($ids: [Int!] = 10) {
  delete_Department(where: {id: {_in: $ids}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteDepartmentsByIdsMutationFn = Apollo.MutationFunction<DeleteDepartmentsByIdsMutation, DeleteDepartmentsByIdsMutationVariables>;

/**
 * __useDeleteDepartmentsByIdsMutation__
 *
 * To run a mutation, you first call `useDeleteDepartmentsByIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDepartmentsByIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDepartmentsByIdsMutation, { data, loading, error }] = useDeleteDepartmentsByIdsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteDepartmentsByIdsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDepartmentsByIdsMutation, DeleteDepartmentsByIdsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDepartmentsByIdsMutation, DeleteDepartmentsByIdsMutationVariables>(DeleteDepartmentsByIdsDocument, options);
      }
export type DeleteDepartmentsByIdsMutationHookResult = ReturnType<typeof useDeleteDepartmentsByIdsMutation>;
export type DeleteDepartmentsByIdsMutationResult = Apollo.MutationResult<DeleteDepartmentsByIdsMutation>;
export type DeleteDepartmentsByIdsMutationOptions = Apollo.BaseMutationOptions<DeleteDepartmentsByIdsMutation, DeleteDepartmentsByIdsMutationVariables>;
export const AllDocumentsDocument = gql`
    subscription AllDocuments {
  Documents {
    name
    id
  }
}
    `;

/**
 * __useAllDocumentsSubscription__
 *
 * To run a query within a React component, call `useAllDocumentsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAllDocumentsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDocumentsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAllDocumentsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<AllDocumentsSubscription, AllDocumentsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AllDocumentsSubscription, AllDocumentsSubscriptionVariables>(AllDocumentsDocument, options);
      }
export type AllDocumentsSubscriptionHookResult = ReturnType<typeof useAllDocumentsSubscription>;
export type AllDocumentsSubscriptionResult = Apollo.SubscriptionResult<AllDocumentsSubscription>;
export const GetDepartmentDocumentsDocument = gql`
    query GetDepartmentDocuments($department_id: Int = 0) {
  DepartmentDocument(where: {department_id: {_eq: $department_id}}) {
    id
    Document {
      name
    }
    document_id
  }
}
    `;

/**
 * __useGetDepartmentDocumentsQuery__
 *
 * To run a query within a React component, call `useGetDepartmentDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepartmentDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepartmentDocumentsQuery({
 *   variables: {
 *      department_id: // value for 'department_id'
 *   },
 * });
 */
export function useGetDepartmentDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<GetDepartmentDocumentsQuery, GetDepartmentDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDepartmentDocumentsQuery, GetDepartmentDocumentsQueryVariables>(GetDepartmentDocumentsDocument, options);
      }
export function useGetDepartmentDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDepartmentDocumentsQuery, GetDepartmentDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDepartmentDocumentsQuery, GetDepartmentDocumentsQueryVariables>(GetDepartmentDocumentsDocument, options);
        }
export type GetDepartmentDocumentsQueryHookResult = ReturnType<typeof useGetDepartmentDocumentsQuery>;
export type GetDepartmentDocumentsLazyQueryHookResult = ReturnType<typeof useGetDepartmentDocumentsLazyQuery>;
export type GetDepartmentDocumentsQueryResult = Apollo.QueryResult<GetDepartmentDocumentsQuery, GetDepartmentDocumentsQueryVariables>;
export const DeleteDepartmentDocumentDocument = gql`
    mutation DeleteDepartmentDocument($_in: [Int!] = 0) {
  delete_DepartmentDocument(where: {id: {_in: $_in}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteDepartmentDocumentMutationFn = Apollo.MutationFunction<DeleteDepartmentDocumentMutation, DeleteDepartmentDocumentMutationVariables>;

/**
 * __useDeleteDepartmentDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDepartmentDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDepartmentDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDepartmentDocumentMutation, { data, loading, error }] = useDeleteDepartmentDocumentMutation({
 *   variables: {
 *      _in: // value for '_in'
 *   },
 * });
 */
export function useDeleteDepartmentDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDepartmentDocumentMutation, DeleteDepartmentDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDepartmentDocumentMutation, DeleteDepartmentDocumentMutationVariables>(DeleteDepartmentDocumentDocument, options);
      }
export type DeleteDepartmentDocumentMutationHookResult = ReturnType<typeof useDeleteDepartmentDocumentMutation>;
export type DeleteDepartmentDocumentMutationResult = Apollo.MutationResult<DeleteDepartmentDocumentMutation>;
export type DeleteDepartmentDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteDepartmentDocumentMutation, DeleteDepartmentDocumentMutationVariables>;
export const AddDepaertmentDocumentDocument = gql`
    mutation AddDepaertmentDocument($objects: [DepartmentDocument_insert_input!] = {}) {
  insert_DepartmentDocument(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type AddDepaertmentDocumentMutationFn = Apollo.MutationFunction<AddDepaertmentDocumentMutation, AddDepaertmentDocumentMutationVariables>;

/**
 * __useAddDepaertmentDocumentMutation__
 *
 * To run a mutation, you first call `useAddDepaertmentDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDepaertmentDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDepaertmentDocumentMutation, { data, loading, error }] = useAddDepaertmentDocumentMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddDepaertmentDocumentMutation(baseOptions?: Apollo.MutationHookOptions<AddDepaertmentDocumentMutation, AddDepaertmentDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDepaertmentDocumentMutation, AddDepaertmentDocumentMutationVariables>(AddDepaertmentDocumentDocument, options);
      }
export type AddDepaertmentDocumentMutationHookResult = ReturnType<typeof useAddDepaertmentDocumentMutation>;
export type AddDepaertmentDocumentMutationResult = Apollo.MutationResult<AddDepaertmentDocumentMutation>;
export type AddDepaertmentDocumentMutationOptions = Apollo.BaseMutationOptions<AddDepaertmentDocumentMutation, AddDepaertmentDocumentMutationVariables>;
export const DocumentsIndexDocument = gql`
    subscription DocumentsIndex($order_by: [Documents_order_by!] = {}, $where: Documents_bool_exp = {}, $offset: Int = 0, $limit: Int = 10) {
  Documents(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    id
    name
    body
    variables
  }
}
    `;

/**
 * __useDocumentsIndexSubscription__
 *
 * To run a query within a React component, call `useDocumentsIndexSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsIndexSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsIndexSubscription({
 *   variables: {
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDocumentsIndexSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DocumentsIndexSubscription, DocumentsIndexSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DocumentsIndexSubscription, DocumentsIndexSubscriptionVariables>(DocumentsIndexDocument, options);
      }
export type DocumentsIndexSubscriptionHookResult = ReturnType<typeof useDocumentsIndexSubscription>;
export type DocumentsIndexSubscriptionResult = Apollo.SubscriptionResult<DocumentsIndexSubscription>;
export const DocumentIndexCountDocument = gql`
    subscription DocumentIndexCount($where: Documents_bool_exp = {}) {
  Documents_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useDocumentIndexCountSubscription__
 *
 * To run a query within a React component, call `useDocumentIndexCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDocumentIndexCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentIndexCountSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDocumentIndexCountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DocumentIndexCountSubscription, DocumentIndexCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DocumentIndexCountSubscription, DocumentIndexCountSubscriptionVariables>(DocumentIndexCountDocument, options);
      }
export type DocumentIndexCountSubscriptionHookResult = ReturnType<typeof useDocumentIndexCountSubscription>;
export type DocumentIndexCountSubscriptionResult = Apollo.SubscriptionResult<DocumentIndexCountSubscription>;
export const AddDocumentDocument = gql`
    mutation AddDocument($name: String = "", $body: String = "", $variables: jsonb = "", $styles: String = "") {
  insert_Documents(
    objects: {name: $name, body: $body, variables: $variables, styles: $styles}
  ) {
    returning {
      id
    }
  }
}
    `;
export type AddDocumentMutationFn = Apollo.MutationFunction<AddDocumentMutation, AddDocumentMutationVariables>;

/**
 * __useAddDocumentMutation__
 *
 * To run a mutation, you first call `useAddDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDocumentMutation, { data, loading, error }] = useAddDocumentMutation({
 *   variables: {
 *      name: // value for 'name'
 *      body: // value for 'body'
 *      variables: // value for 'variables'
 *      styles: // value for 'styles'
 *   },
 * });
 */
export function useAddDocumentMutation(baseOptions?: Apollo.MutationHookOptions<AddDocumentMutation, AddDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDocumentMutation, AddDocumentMutationVariables>(AddDocumentDocument, options);
      }
export type AddDocumentMutationHookResult = ReturnType<typeof useAddDocumentMutation>;
export type AddDocumentMutationResult = Apollo.MutationResult<AddDocumentMutation>;
export type AddDocumentMutationOptions = Apollo.BaseMutationOptions<AddDocumentMutation, AddDocumentMutationVariables>;
export const UpdateDocumentDocument = gql`
    mutation UpdateDocument($name: String = "", $id: Int = 10, $variables: jsonb = "", $body: String = "", $styles: String = "") {
  update_Documents(
    where: {id: {_eq: $id}}
    _set: {name: $name, variables: $variables, body: $body, styles: $styles}
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpdateDocumentMutationFn = Apollo.MutationFunction<UpdateDocumentMutation, UpdateDocumentMutationVariables>;

/**
 * __useUpdateDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentMutation, { data, loading, error }] = useUpdateDocumentMutation({
 *   variables: {
 *      name: // value for 'name'
 *      id: // value for 'id'
 *      variables: // value for 'variables'
 *      body: // value for 'body'
 *      styles: // value for 'styles'
 *   },
 * });
 */
export function useUpdateDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentMutation, UpdateDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentMutation, UpdateDocumentMutationVariables>(UpdateDocumentDocument, options);
      }
export type UpdateDocumentMutationHookResult = ReturnType<typeof useUpdateDocumentMutation>;
export type UpdateDocumentMutationResult = Apollo.MutationResult<UpdateDocumentMutation>;
export type UpdateDocumentMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentMutation, UpdateDocumentMutationVariables>;
export const DeleteDocumentDocument = gql`
    mutation DeleteDocument($id: Int = 10) {
  delete_Documents(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteDocumentMutationFn = Apollo.MutationFunction<DeleteDocumentMutation, DeleteDocumentMutationVariables>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentMutation, DeleteDocumentMutationVariables>(DeleteDocumentDocument, options);
      }
export type DeleteDocumentMutationHookResult = ReturnType<typeof useDeleteDocumentMutation>;
export type DeleteDocumentMutationResult = Apollo.MutationResult<DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>;
export const DeleteDocumentsByIdsDocument = gql`
    mutation DeleteDocumentsByIds($ids: [Int!] = 10) {
  delete_Documents(where: {id: {_in: $ids}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteDocumentsByIdsMutationFn = Apollo.MutationFunction<DeleteDocumentsByIdsMutation, DeleteDocumentsByIdsMutationVariables>;

/**
 * __useDeleteDocumentsByIdsMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentsByIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentsByIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentsByIdsMutation, { data, loading, error }] = useDeleteDocumentsByIdsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteDocumentsByIdsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentsByIdsMutation, DeleteDocumentsByIdsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentsByIdsMutation, DeleteDocumentsByIdsMutationVariables>(DeleteDocumentsByIdsDocument, options);
      }
export type DeleteDocumentsByIdsMutationHookResult = ReturnType<typeof useDeleteDocumentsByIdsMutation>;
export type DeleteDocumentsByIdsMutationResult = Apollo.MutationResult<DeleteDocumentsByIdsMutation>;
export type DeleteDocumentsByIdsMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentsByIdsMutation, DeleteDocumentsByIdsMutationVariables>;
export const TakeDoctorsVisitsDocument = gql`
    subscription TakeDoctorsVisits($date: date = "", $doctor_id: uuid = "", $doctors_departments: [Int!] = []) {
  Department(
    where: {Visits_aggregate: {count: {predicate: {_neq: 0}, filter: {_and: [{date: {_eq: $date}}, {status: {_neq: 4}}, {department_id: {_in: $doctors_departments}}, {_or: [{doctor_id: {_is_null: true}}, {doctor_id: {_eq: $doctor_id}}]}]}}}}
  ) {
    id
    name
    Visits(
      where: {_and: [{date: {_eq: $date}}, {status: {_neq: 4}}, {department_id: {_in: $doctors_departments}}, {_or: [{doctor_id: {_is_null: true}}, {doctor_id: {_eq: $doctor_id}}]}]}
      order_by: [{without_time: asc}, {time: asc}]
    ) {
      id
      time
      patient_id
      patient_card
      from_id
      doctor_id
      department_id
      date
      current_reception_id
      client_id
      status
      Client {
        name
        phone_number
      }
      Doctor {
        Name
      }
      Pet {
        name
        PetType {
          name
        }
        birthdate
        sex
        breed
      }
      From {
        Name
      }
      CurrentReception {
        internal_recomendation
        patient_recomendation
      }
      without_time
      visit_start
    }
  }
}
    `;

/**
 * __useTakeDoctorsVisitsSubscription__
 *
 * To run a query within a React component, call `useTakeDoctorsVisitsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTakeDoctorsVisitsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTakeDoctorsVisitsSubscription({
 *   variables: {
 *      date: // value for 'date'
 *      doctor_id: // value for 'doctor_id'
 *      doctors_departments: // value for 'doctors_departments'
 *   },
 * });
 */
export function useTakeDoctorsVisitsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<TakeDoctorsVisitsSubscription, TakeDoctorsVisitsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TakeDoctorsVisitsSubscription, TakeDoctorsVisitsSubscriptionVariables>(TakeDoctorsVisitsDocument, options);
      }
export type TakeDoctorsVisitsSubscriptionHookResult = ReturnType<typeof useTakeDoctorsVisitsSubscription>;
export type TakeDoctorsVisitsSubscriptionResult = Apollo.SubscriptionResult<TakeDoctorsVisitsSubscription>;
export const GetDoctorsDepartmentsDocument = gql`
    subscription GetDoctorsDepartments($doctor_id: uuid = "") {
  DoctorsDepartment(where: {doctor_id: {_eq: $doctor_id}}) {
    department_id
  }
}
    `;

/**
 * __useGetDoctorsDepartmentsSubscription__
 *
 * To run a query within a React component, call `useGetDoctorsDepartmentsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetDoctorsDepartmentsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDoctorsDepartmentsSubscription({
 *   variables: {
 *      doctor_id: // value for 'doctor_id'
 *   },
 * });
 */
export function useGetDoctorsDepartmentsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetDoctorsDepartmentsSubscription, GetDoctorsDepartmentsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetDoctorsDepartmentsSubscription, GetDoctorsDepartmentsSubscriptionVariables>(GetDoctorsDepartmentsDocument, options);
      }
export type GetDoctorsDepartmentsSubscriptionHookResult = ReturnType<typeof useGetDoctorsDepartmentsSubscription>;
export type GetDoctorsDepartmentsSubscriptionResult = Apollo.SubscriptionResult<GetDoctorsDepartmentsSubscription>;
export const ChangeVisitStatusDocument = gql`
    mutation ChangeVisitStatus($id: Int = 10, $status: Int = 0) {
  update_Visit(where: {id: {_eq: $id}}, _set: {status: $status}) {
    returning {
      id
    }
  }
}
    `;
export type ChangeVisitStatusMutationFn = Apollo.MutationFunction<ChangeVisitStatusMutation, ChangeVisitStatusMutationVariables>;

/**
 * __useChangeVisitStatusMutation__
 *
 * To run a mutation, you first call `useChangeVisitStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeVisitStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeVisitStatusMutation, { data, loading, error }] = useChangeVisitStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useChangeVisitStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeVisitStatusMutation, ChangeVisitStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeVisitStatusMutation, ChangeVisitStatusMutationVariables>(ChangeVisitStatusDocument, options);
      }
export type ChangeVisitStatusMutationHookResult = ReturnType<typeof useChangeVisitStatusMutation>;
export type ChangeVisitStatusMutationResult = Apollo.MutationResult<ChangeVisitStatusMutation>;
export type ChangeVisitStatusMutationOptions = Apollo.BaseMutationOptions<ChangeVisitStatusMutation, ChangeVisitStatusMutationVariables>;
export const ChangePatientCardDocument = gql`
    mutation ChangePatientCard($id: Int = 10, $patient_card: jsonb = "") {
  update_Visit(where: {id: {_eq: $id}}, _set: {patient_card: $patient_card}) {
    returning {
      id
    }
  }
}
    `;
export type ChangePatientCardMutationFn = Apollo.MutationFunction<ChangePatientCardMutation, ChangePatientCardMutationVariables>;

/**
 * __useChangePatientCardMutation__
 *
 * To run a mutation, you first call `useChangePatientCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePatientCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePatientCardMutation, { data, loading, error }] = useChangePatientCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patient_card: // value for 'patient_card'
 *   },
 * });
 */
export function useChangePatientCardMutation(baseOptions?: Apollo.MutationHookOptions<ChangePatientCardMutation, ChangePatientCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePatientCardMutation, ChangePatientCardMutationVariables>(ChangePatientCardDocument, options);
      }
export type ChangePatientCardMutationHookResult = ReturnType<typeof useChangePatientCardMutation>;
export type ChangePatientCardMutationResult = Apollo.MutationResult<ChangePatientCardMutation>;
export type ChangePatientCardMutationOptions = Apollo.BaseMutationOptions<ChangePatientCardMutation, ChangePatientCardMutationVariables>;
export const ChangeDoctorInVisitDocument = gql`
    mutation ChangeDoctorInVisit($id: Int = 0, $doctor_id: uuid = "") {
  update_Visit(where: {id: {_eq: $id}}, _set: {doctor_id: $doctor_id}) {
    returning {
      id
    }
  }
}
    `;
export type ChangeDoctorInVisitMutationFn = Apollo.MutationFunction<ChangeDoctorInVisitMutation, ChangeDoctorInVisitMutationVariables>;

/**
 * __useChangeDoctorInVisitMutation__
 *
 * To run a mutation, you first call `useChangeDoctorInVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeDoctorInVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeDoctorInVisitMutation, { data, loading, error }] = useChangeDoctorInVisitMutation({
 *   variables: {
 *      id: // value for 'id'
 *      doctor_id: // value for 'doctor_id'
 *   },
 * });
 */
export function useChangeDoctorInVisitMutation(baseOptions?: Apollo.MutationHookOptions<ChangeDoctorInVisitMutation, ChangeDoctorInVisitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeDoctorInVisitMutation, ChangeDoctorInVisitMutationVariables>(ChangeDoctorInVisitDocument, options);
      }
export type ChangeDoctorInVisitMutationHookResult = ReturnType<typeof useChangeDoctorInVisitMutation>;
export type ChangeDoctorInVisitMutationResult = Apollo.MutationResult<ChangeDoctorInVisitMutation>;
export type ChangeDoctorInVisitMutationOptions = Apollo.BaseMutationOptions<ChangeDoctorInVisitMutation, ChangeDoctorInVisitMutationVariables>;
export const MakeReceptionDocument = gql`
    mutation MakeReception($doctor_id: uuid = "", $visit_id: Int = 0, $patient_recomendation: String = "", $internal_recomendation: String = "", $from_id: uuid = "") {
  insert_Reception(
    objects: {doctor_id: $doctor_id, from_id: $from_id, internal_recomendation: $internal_recomendation, patient_recomendation: $patient_recomendation, visit_id: $visit_id}
  ) {
    returning {
      id
    }
  }
}
    `;
export type MakeReceptionMutationFn = Apollo.MutationFunction<MakeReceptionMutation, MakeReceptionMutationVariables>;

/**
 * __useMakeReceptionMutation__
 *
 * To run a mutation, you first call `useMakeReceptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeReceptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeReceptionMutation, { data, loading, error }] = useMakeReceptionMutation({
 *   variables: {
 *      doctor_id: // value for 'doctor_id'
 *      visit_id: // value for 'visit_id'
 *      patient_recomendation: // value for 'patient_recomendation'
 *      internal_recomendation: // value for 'internal_recomendation'
 *      from_id: // value for 'from_id'
 *   },
 * });
 */
export function useMakeReceptionMutation(baseOptions?: Apollo.MutationHookOptions<MakeReceptionMutation, MakeReceptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeReceptionMutation, MakeReceptionMutationVariables>(MakeReceptionDocument, options);
      }
export type MakeReceptionMutationHookResult = ReturnType<typeof useMakeReceptionMutation>;
export type MakeReceptionMutationResult = Apollo.MutationResult<MakeReceptionMutation>;
export type MakeReceptionMutationOptions = Apollo.BaseMutationOptions<MakeReceptionMutation, MakeReceptionMutationVariables>;
export const ChangeReceptionInVisitDocument = gql`
    mutation ChangeReceptionInVisit($id: Int = 0, $current_reception_id: Int = null) {
  update_Visit(
    where: {id: {_eq: $id}}
    _set: {current_reception_id: $current_reception_id}
  ) {
    returning {
      id
    }
  }
}
    `;
export type ChangeReceptionInVisitMutationFn = Apollo.MutationFunction<ChangeReceptionInVisitMutation, ChangeReceptionInVisitMutationVariables>;

/**
 * __useChangeReceptionInVisitMutation__
 *
 * To run a mutation, you first call `useChangeReceptionInVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeReceptionInVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeReceptionInVisitMutation, { data, loading, error }] = useChangeReceptionInVisitMutation({
 *   variables: {
 *      id: // value for 'id'
 *      current_reception_id: // value for 'current_reception_id'
 *   },
 * });
 */
export function useChangeReceptionInVisitMutation(baseOptions?: Apollo.MutationHookOptions<ChangeReceptionInVisitMutation, ChangeReceptionInVisitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeReceptionInVisitMutation, ChangeReceptionInVisitMutationVariables>(ChangeReceptionInVisitDocument, options);
      }
export type ChangeReceptionInVisitMutationHookResult = ReturnType<typeof useChangeReceptionInVisitMutation>;
export type ChangeReceptionInVisitMutationResult = Apollo.MutationResult<ChangeReceptionInVisitMutation>;
export type ChangeReceptionInVisitMutationOptions = Apollo.BaseMutationOptions<ChangeReceptionInVisitMutation, ChangeReceptionInVisitMutationVariables>;
export const SaveReceptionDocument = gql`
    mutation SaveReception($id: Int = 0, $internal_recomendation: String = "", $patient_recomendation: String = "") {
  update_Reception(
    where: {id: {_eq: $id}}
    _set: {internal_recomendation: $internal_recomendation, patient_recomendation: $patient_recomendation}
  ) {
    returning {
      id
    }
  }
}
    `;
export type SaveReceptionMutationFn = Apollo.MutationFunction<SaveReceptionMutation, SaveReceptionMutationVariables>;

/**
 * __useSaveReceptionMutation__
 *
 * To run a mutation, you first call `useSaveReceptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveReceptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveReceptionMutation, { data, loading, error }] = useSaveReceptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      internal_recomendation: // value for 'internal_recomendation'
 *      patient_recomendation: // value for 'patient_recomendation'
 *   },
 * });
 */
export function useSaveReceptionMutation(baseOptions?: Apollo.MutationHookOptions<SaveReceptionMutation, SaveReceptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveReceptionMutation, SaveReceptionMutationVariables>(SaveReceptionDocument, options);
      }
export type SaveReceptionMutationHookResult = ReturnType<typeof useSaveReceptionMutation>;
export type SaveReceptionMutationResult = Apollo.MutationResult<SaveReceptionMutation>;
export type SaveReceptionMutationOptions = Apollo.BaseMutationOptions<SaveReceptionMutation, SaveReceptionMutationVariables>;
export const GetReceptionsOfVisitDocument = gql`
    query GetReceptionsOfVisit($id: Int = 0, $current_reception_id: Int = 0) {
  Reception(
    where: {_and: [{visit_id: {_eq: $id}}, {id: {_neq: $current_reception_id}}]}
  ) {
    id
    internal_recomendation
    patient_recomendation
    Doctor {
      Name
    }
    From {
      Name
    }
    Medicines {
      id
      Medicine {
        name
      }
      done
    }
    Services {
      id
      Service {
        name
      }
      price
      value
    }
    doctor_id
    end_time
    start_time
  }
}
    `;

/**
 * __useGetReceptionsOfVisitQuery__
 *
 * To run a query within a React component, call `useGetReceptionsOfVisitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceptionsOfVisitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceptionsOfVisitQuery({
 *   variables: {
 *      id: // value for 'id'
 *      current_reception_id: // value for 'current_reception_id'
 *   },
 * });
 */
export function useGetReceptionsOfVisitQuery(baseOptions?: Apollo.QueryHookOptions<GetReceptionsOfVisitQuery, GetReceptionsOfVisitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReceptionsOfVisitQuery, GetReceptionsOfVisitQueryVariables>(GetReceptionsOfVisitDocument, options);
      }
export function useGetReceptionsOfVisitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReceptionsOfVisitQuery, GetReceptionsOfVisitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReceptionsOfVisitQuery, GetReceptionsOfVisitQueryVariables>(GetReceptionsOfVisitDocument, options);
        }
export type GetReceptionsOfVisitQueryHookResult = ReturnType<typeof useGetReceptionsOfVisitQuery>;
export type GetReceptionsOfVisitLazyQueryHookResult = ReturnType<typeof useGetReceptionsOfVisitLazyQuery>;
export type GetReceptionsOfVisitQueryResult = Apollo.QueryResult<GetReceptionsOfVisitQuery, GetReceptionsOfVisitQueryVariables>;
export const GetReceptionMedicinesAndServicesDocument = gql`
    query GetReceptionMedicinesAndServices($reception_id: Int = 0) {
  ReceptionMedicines(where: {reception_id: {_eq: $reception_id}}) {
    id
    Medicine {
      name
      id
      ut_id
      price
    }
    value
    medicine_id
    reception_id
    done
  }
  ReceptionServices(where: {reception_id: {_eq: $reception_id}}) {
    id
    Service {
      name
      id
      price
      ut_id
    }
    price
    value
    service_id
    reception_id
  }
  Service {
    name
    id
    price
    ut_id
  }
  Medicine {
    name
    id
    ut_id
    price
  }
}
    `;

/**
 * __useGetReceptionMedicinesAndServicesQuery__
 *
 * To run a query within a React component, call `useGetReceptionMedicinesAndServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceptionMedicinesAndServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceptionMedicinesAndServicesQuery({
 *   variables: {
 *      reception_id: // value for 'reception_id'
 *   },
 * });
 */
export function useGetReceptionMedicinesAndServicesQuery(baseOptions?: Apollo.QueryHookOptions<GetReceptionMedicinesAndServicesQuery, GetReceptionMedicinesAndServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReceptionMedicinesAndServicesQuery, GetReceptionMedicinesAndServicesQueryVariables>(GetReceptionMedicinesAndServicesDocument, options);
      }
export function useGetReceptionMedicinesAndServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReceptionMedicinesAndServicesQuery, GetReceptionMedicinesAndServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReceptionMedicinesAndServicesQuery, GetReceptionMedicinesAndServicesQueryVariables>(GetReceptionMedicinesAndServicesDocument, options);
        }
export type GetReceptionMedicinesAndServicesQueryHookResult = ReturnType<typeof useGetReceptionMedicinesAndServicesQuery>;
export type GetReceptionMedicinesAndServicesLazyQueryHookResult = ReturnType<typeof useGetReceptionMedicinesAndServicesLazyQuery>;
export type GetReceptionMedicinesAndServicesQueryResult = Apollo.QueryResult<GetReceptionMedicinesAndServicesQuery, GetReceptionMedicinesAndServicesQueryVariables>;
export const DeleteReceptionServiceDocument = gql`
    mutation DeleteReceptionService($id: Int = 0) {
  delete_ReceptionServices(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteReceptionServiceMutationFn = Apollo.MutationFunction<DeleteReceptionServiceMutation, DeleteReceptionServiceMutationVariables>;

/**
 * __useDeleteReceptionServiceMutation__
 *
 * To run a mutation, you first call `useDeleteReceptionServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReceptionServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReceptionServiceMutation, { data, loading, error }] = useDeleteReceptionServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReceptionServiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReceptionServiceMutation, DeleteReceptionServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReceptionServiceMutation, DeleteReceptionServiceMutationVariables>(DeleteReceptionServiceDocument, options);
      }
export type DeleteReceptionServiceMutationHookResult = ReturnType<typeof useDeleteReceptionServiceMutation>;
export type DeleteReceptionServiceMutationResult = Apollo.MutationResult<DeleteReceptionServiceMutation>;
export type DeleteReceptionServiceMutationOptions = Apollo.BaseMutationOptions<DeleteReceptionServiceMutation, DeleteReceptionServiceMutationVariables>;
export const UpdateReceptionServiceDocument = gql`
    mutation UpdateReceptionService($id: Int = 0, $price: float8 = "", $service_id: Int = 0, $value: float8 = "") {
  update_ReceptionServices(
    where: {id: {_eq: $id}}
    _set: {price: $price, service_id: $service_id, value: $value}
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpdateReceptionServiceMutationFn = Apollo.MutationFunction<UpdateReceptionServiceMutation, UpdateReceptionServiceMutationVariables>;

/**
 * __useUpdateReceptionServiceMutation__
 *
 * To run a mutation, you first call `useUpdateReceptionServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReceptionServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReceptionServiceMutation, { data, loading, error }] = useUpdateReceptionServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      price: // value for 'price'
 *      service_id: // value for 'service_id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateReceptionServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReceptionServiceMutation, UpdateReceptionServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReceptionServiceMutation, UpdateReceptionServiceMutationVariables>(UpdateReceptionServiceDocument, options);
      }
export type UpdateReceptionServiceMutationHookResult = ReturnType<typeof useUpdateReceptionServiceMutation>;
export type UpdateReceptionServiceMutationResult = Apollo.MutationResult<UpdateReceptionServiceMutation>;
export type UpdateReceptionServiceMutationOptions = Apollo.BaseMutationOptions<UpdateReceptionServiceMutation, UpdateReceptionServiceMutationVariables>;
export const UpdateReceptionMedicineDocument = gql`
    mutation UpdateReceptionMedicine($id: Int = 0, $medicine_id: Int = 0, $reception_id: Int = 0, $value: float8 = 0, $done: Boolean = false) {
  update_ReceptionMedicines(
    where: {id: {_eq: $id}}
    _set: {medicine_id: $medicine_id, reception_id: $reception_id, value: $value, done: $done}
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpdateReceptionMedicineMutationFn = Apollo.MutationFunction<UpdateReceptionMedicineMutation, UpdateReceptionMedicineMutationVariables>;

/**
 * __useUpdateReceptionMedicineMutation__
 *
 * To run a mutation, you first call `useUpdateReceptionMedicineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReceptionMedicineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReceptionMedicineMutation, { data, loading, error }] = useUpdateReceptionMedicineMutation({
 *   variables: {
 *      id: // value for 'id'
 *      medicine_id: // value for 'medicine_id'
 *      reception_id: // value for 'reception_id'
 *      value: // value for 'value'
 *      done: // value for 'done'
 *   },
 * });
 */
export function useUpdateReceptionMedicineMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReceptionMedicineMutation, UpdateReceptionMedicineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReceptionMedicineMutation, UpdateReceptionMedicineMutationVariables>(UpdateReceptionMedicineDocument, options);
      }
export type UpdateReceptionMedicineMutationHookResult = ReturnType<typeof useUpdateReceptionMedicineMutation>;
export type UpdateReceptionMedicineMutationResult = Apollo.MutationResult<UpdateReceptionMedicineMutation>;
export type UpdateReceptionMedicineMutationOptions = Apollo.BaseMutationOptions<UpdateReceptionMedicineMutation, UpdateReceptionMedicineMutationVariables>;
export const DeleteReceptionMedicineDocument = gql`
    mutation DeleteReceptionMedicine($id: Int = 0) {
  delete_ReceptionMedicines(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteReceptionMedicineMutationFn = Apollo.MutationFunction<DeleteReceptionMedicineMutation, DeleteReceptionMedicineMutationVariables>;

/**
 * __useDeleteReceptionMedicineMutation__
 *
 * To run a mutation, you first call `useDeleteReceptionMedicineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReceptionMedicineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReceptionMedicineMutation, { data, loading, error }] = useDeleteReceptionMedicineMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReceptionMedicineMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReceptionMedicineMutation, DeleteReceptionMedicineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReceptionMedicineMutation, DeleteReceptionMedicineMutationVariables>(DeleteReceptionMedicineDocument, options);
      }
export type DeleteReceptionMedicineMutationHookResult = ReturnType<typeof useDeleteReceptionMedicineMutation>;
export type DeleteReceptionMedicineMutationResult = Apollo.MutationResult<DeleteReceptionMedicineMutation>;
export type DeleteReceptionMedicineMutationOptions = Apollo.BaseMutationOptions<DeleteReceptionMedicineMutation, DeleteReceptionMedicineMutationVariables>;
export const AddReceptionServicesAndMedicinesDocument = gql`
    mutation AddReceptionServicesAndMedicines($services: [ReceptionServices_insert_input!] = {}, $medicines: [ReceptionMedicines_insert_input!] = {}) {
  insert_ReceptionServices(objects: $services) {
    returning {
      id
    }
  }
  insert_ReceptionMedicines(objects: $medicines) {
    returning {
      id
    }
  }
}
    `;
export type AddReceptionServicesAndMedicinesMutationFn = Apollo.MutationFunction<AddReceptionServicesAndMedicinesMutation, AddReceptionServicesAndMedicinesMutationVariables>;

/**
 * __useAddReceptionServicesAndMedicinesMutation__
 *
 * To run a mutation, you first call `useAddReceptionServicesAndMedicinesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReceptionServicesAndMedicinesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReceptionServicesAndMedicinesMutation, { data, loading, error }] = useAddReceptionServicesAndMedicinesMutation({
 *   variables: {
 *      services: // value for 'services'
 *      medicines: // value for 'medicines'
 *   },
 * });
 */
export function useAddReceptionServicesAndMedicinesMutation(baseOptions?: Apollo.MutationHookOptions<AddReceptionServicesAndMedicinesMutation, AddReceptionServicesAndMedicinesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddReceptionServicesAndMedicinesMutation, AddReceptionServicesAndMedicinesMutationVariables>(AddReceptionServicesAndMedicinesDocument, options);
      }
export type AddReceptionServicesAndMedicinesMutationHookResult = ReturnType<typeof useAddReceptionServicesAndMedicinesMutation>;
export type AddReceptionServicesAndMedicinesMutationResult = Apollo.MutationResult<AddReceptionServicesAndMedicinesMutation>;
export type AddReceptionServicesAndMedicinesMutationOptions = Apollo.BaseMutationOptions<AddReceptionServicesAndMedicinesMutation, AddReceptionServicesAndMedicinesMutationVariables>;
export const SaveVisitDocument = gql`
    mutation SaveVisit($id: Int = 10, $patient_card: jsonb = "", $reception_id: Int = 0, $internal_recomendation: String = "", $patient_recomendation: String = "", $services: [ReceptionServices_insert_input!] = {}, $medicines: [ReceptionMedicines_insert_input!] = {}, $status: Int = null, $end_time: timetz = null) {
  update_Visit(
    where: {id: {_eq: $id}}
    _set: {patient_card: $patient_card, status: $status}
  ) {
    returning {
      id
    }
  }
  update_Reception(
    where: {id: {_eq: $reception_id}}
    _set: {internal_recomendation: $internal_recomendation, patient_recomendation: $patient_recomendation, end_time: $end_time}
  ) {
    returning {
      id
    }
  }
  insert_ReceptionServices(objects: $services) {
    returning {
      id
    }
  }
  insert_ReceptionMedicines(objects: $medicines) {
    returning {
      id
    }
  }
}
    `;
export type SaveVisitMutationFn = Apollo.MutationFunction<SaveVisitMutation, SaveVisitMutationVariables>;

/**
 * __useSaveVisitMutation__
 *
 * To run a mutation, you first call `useSaveVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveVisitMutation, { data, loading, error }] = useSaveVisitMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patient_card: // value for 'patient_card'
 *      reception_id: // value for 'reception_id'
 *      internal_recomendation: // value for 'internal_recomendation'
 *      patient_recomendation: // value for 'patient_recomendation'
 *      services: // value for 'services'
 *      medicines: // value for 'medicines'
 *      status: // value for 'status'
 *      end_time: // value for 'end_time'
 *   },
 * });
 */
export function useSaveVisitMutation(baseOptions?: Apollo.MutationHookOptions<SaveVisitMutation, SaveVisitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveVisitMutation, SaveVisitMutationVariables>(SaveVisitDocument, options);
      }
export type SaveVisitMutationHookResult = ReturnType<typeof useSaveVisitMutation>;
export type SaveVisitMutationResult = Apollo.MutationResult<SaveVisitMutation>;
export type SaveVisitMutationOptions = Apollo.BaseMutationOptions<SaveVisitMutation, SaveVisitMutationVariables>;
export const GetDoctorsDocumentsDocument = gql`
    query GetDoctorsDocuments($id: Int = 10) {
  DepartmentDocument(where: {department_id: {_eq: $id}}) {
    Document {
      name
      id
      variables
      body
    }
    id
  }
}
    `;

/**
 * __useGetDoctorsDocumentsQuery__
 *
 * To run a query within a React component, call `useGetDoctorsDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDoctorsDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDoctorsDocumentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDoctorsDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<GetDoctorsDocumentsQuery, GetDoctorsDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDoctorsDocumentsQuery, GetDoctorsDocumentsQueryVariables>(GetDoctorsDocumentsDocument, options);
      }
export function useGetDoctorsDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDoctorsDocumentsQuery, GetDoctorsDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDoctorsDocumentsQuery, GetDoctorsDocumentsQueryVariables>(GetDoctorsDocumentsDocument, options);
        }
export type GetDoctorsDocumentsQueryHookResult = ReturnType<typeof useGetDoctorsDocumentsQuery>;
export type GetDoctorsDocumentsLazyQueryHookResult = ReturnType<typeof useGetDoctorsDocumentsLazyQuery>;
export type GetDoctorsDocumentsQueryResult = Apollo.QueryResult<GetDoctorsDocumentsQuery, GetDoctorsDocumentsQueryVariables>;
export const GetVisitFilesDocument = gql`
    subscription GetVisitFiles($id: Int = 0) {
  Visit(where: {id: {_eq: $id}}) {
    VisitFiles {
      id
      file_name
      name
    }
    VisitDocuments {
      id
      name
      data
    }
    department_id
  }
}
    `;

/**
 * __useGetVisitFilesSubscription__
 *
 * To run a query within a React component, call `useGetVisitFilesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetVisitFilesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisitFilesSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVisitFilesSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetVisitFilesSubscription, GetVisitFilesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetVisitFilesSubscription, GetVisitFilesSubscriptionVariables>(GetVisitFilesDocument, options);
      }
export type GetVisitFilesSubscriptionHookResult = ReturnType<typeof useGetVisitFilesSubscription>;
export type GetVisitFilesSubscriptionResult = Apollo.SubscriptionResult<GetVisitFilesSubscription>;
export const AddFileToVisitDocument = gql`
    mutation AddFileToVisit($file_name: String = "", $visit_id: Int = 0, $name: String = "") {
  insert_VisitFiles(
    objects: {file_name: $file_name, visit_id: $visit_id, name: $name}
  ) {
    returning {
      id
    }
  }
}
    `;
export type AddFileToVisitMutationFn = Apollo.MutationFunction<AddFileToVisitMutation, AddFileToVisitMutationVariables>;

/**
 * __useAddFileToVisitMutation__
 *
 * To run a mutation, you first call `useAddFileToVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFileToVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFileToVisitMutation, { data, loading, error }] = useAddFileToVisitMutation({
 *   variables: {
 *      file_name: // value for 'file_name'
 *      visit_id: // value for 'visit_id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddFileToVisitMutation(baseOptions?: Apollo.MutationHookOptions<AddFileToVisitMutation, AddFileToVisitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFileToVisitMutation, AddFileToVisitMutationVariables>(AddFileToVisitDocument, options);
      }
export type AddFileToVisitMutationHookResult = ReturnType<typeof useAddFileToVisitMutation>;
export type AddFileToVisitMutationResult = Apollo.MutationResult<AddFileToVisitMutation>;
export type AddFileToVisitMutationOptions = Apollo.BaseMutationOptions<AddFileToVisitMutation, AddFileToVisitMutationVariables>;
export const GetMedicineUnitsDocument = gql`
    query GetMedicineUnits {
  MedicineUnit {
    id
    name
  }
}
    `;

/**
 * __useGetMedicineUnitsQuery__
 *
 * To run a query within a React component, call `useGetMedicineUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMedicineUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMedicineUnitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMedicineUnitsQuery(baseOptions?: Apollo.QueryHookOptions<GetMedicineUnitsQuery, GetMedicineUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMedicineUnitsQuery, GetMedicineUnitsQueryVariables>(GetMedicineUnitsDocument, options);
      }
export function useGetMedicineUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMedicineUnitsQuery, GetMedicineUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMedicineUnitsQuery, GetMedicineUnitsQueryVariables>(GetMedicineUnitsDocument, options);
        }
export type GetMedicineUnitsQueryHookResult = ReturnType<typeof useGetMedicineUnitsQuery>;
export type GetMedicineUnitsLazyQueryHookResult = ReturnType<typeof useGetMedicineUnitsLazyQuery>;
export type GetMedicineUnitsQueryResult = Apollo.QueryResult<GetMedicineUnitsQuery, GetMedicineUnitsQueryVariables>;
export const SetVisitTimeDocument = gql`
    mutation SetVisitTime($id: Int = 0, $time: timetz = "") {
  update_Visit(where: {id: {_eq: $id}}, _set: {time: $time, without_time: false}) {
    affected_rows
  }
}
    `;
export type SetVisitTimeMutationFn = Apollo.MutationFunction<SetVisitTimeMutation, SetVisitTimeMutationVariables>;

/**
 * __useSetVisitTimeMutation__
 *
 * To run a mutation, you first call `useSetVisitTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetVisitTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setVisitTimeMutation, { data, loading, error }] = useSetVisitTimeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      time: // value for 'time'
 *   },
 * });
 */
export function useSetVisitTimeMutation(baseOptions?: Apollo.MutationHookOptions<SetVisitTimeMutation, SetVisitTimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetVisitTimeMutation, SetVisitTimeMutationVariables>(SetVisitTimeDocument, options);
      }
export type SetVisitTimeMutationHookResult = ReturnType<typeof useSetVisitTimeMutation>;
export type SetVisitTimeMutationResult = Apollo.MutationResult<SetVisitTimeMutation>;
export type SetVisitTimeMutationOptions = Apollo.BaseMutationOptions<SetVisitTimeMutation, SetVisitTimeMutationVariables>;
export const CreateVisitDocumentDocument = gql`
    mutation CreateVisitDocument($visit_id: Int = 10, $name: String = "", $data: String = "") {
  insert_VisitDocument(objects: {visit_id: $visit_id, name: $name, data: $data}) {
    returning {
      id
    }
  }
}
    `;
export type CreateVisitDocumentMutationFn = Apollo.MutationFunction<CreateVisitDocumentMutation, CreateVisitDocumentMutationVariables>;

/**
 * __useCreateVisitDocumentMutation__
 *
 * To run a mutation, you first call `useCreateVisitDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVisitDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVisitDocumentMutation, { data, loading, error }] = useCreateVisitDocumentMutation({
 *   variables: {
 *      visit_id: // value for 'visit_id'
 *      name: // value for 'name'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateVisitDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateVisitDocumentMutation, CreateVisitDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVisitDocumentMutation, CreateVisitDocumentMutationVariables>(CreateVisitDocumentDocument, options);
      }
export type CreateVisitDocumentMutationHookResult = ReturnType<typeof useCreateVisitDocumentMutation>;
export type CreateVisitDocumentMutationResult = Apollo.MutationResult<CreateVisitDocumentMutation>;
export type CreateVisitDocumentMutationOptions = Apollo.BaseMutationOptions<CreateVisitDocumentMutation, CreateVisitDocumentMutationVariables>;
export const TakeClientVisitsDocument = gql`
    subscription TakeClientVisits($id: Int = 0) {
  Visit(
    order_by: [{date: desc}, {without_time: asc}, {time: asc}]
    where: {patient_id: {_eq: $id}}
  ) {
    id
    time
    patient_id
    patient_card
    from_id
    doctor_id
    department_id
    date
    current_reception_id
    client_id
    status
    Client {
      name
      phone_number
    }
    Doctor {
      Name
    }
    Pet {
      name
      PetType {
        name
      }
      birthdate
      sex
      breed
    }
    From {
      Name
    }
    without_time
    visit_start
    ReceptionsList(order_by: {start_time: asc}) {
      id
      start_time
      end_time
      internal_recomendation
      patient_recomendation
      From {
        Name
      }
      Doctor {
        Name
      }
      Medicines {
        value
        Medicine {
          name
          id
          price
          ut_id
        }
        medicine_id
        reception_id
        id
        done
      }
      Services {
        id
        service_id
        Service {
          id
          name
          price
          ut_id
        }
        price
        value
        reception_id
      }
      doctor_id
    }
    VisitFiles {
      id
      file_name
      name
    }
    VisitDocuments {
      name
      data
      id
    }
  }
}
    `;

/**
 * __useTakeClientVisitsSubscription__
 *
 * To run a query within a React component, call `useTakeClientVisitsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTakeClientVisitsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTakeClientVisitsSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTakeClientVisitsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<TakeClientVisitsSubscription, TakeClientVisitsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TakeClientVisitsSubscription, TakeClientVisitsSubscriptionVariables>(TakeClientVisitsDocument, options);
      }
export type TakeClientVisitsSubscriptionHookResult = ReturnType<typeof useTakeClientVisitsSubscription>;
export type TakeClientVisitsSubscriptionResult = Apollo.SubscriptionResult<TakeClientVisitsSubscription>;
export const GetDoctorsServicesDocument = gql`
    subscription GetDoctorsServices($id: Int = 0, $doctor_id: uuid = "", $currentReceptionID: Int = 0) {
  Visit(where: {id: {_eq: $id}}) {
    ReceptionsList(
      where: {doctor_id: {_eq: $doctor_id}, id: {_neq: $currentReceptionID}}
    ) {
      Services {
        price
        value
      }
    }
  }
}
    `;

/**
 * __useGetDoctorsServicesSubscription__
 *
 * To run a query within a React component, call `useGetDoctorsServicesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetDoctorsServicesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDoctorsServicesSubscription({
 *   variables: {
 *      id: // value for 'id'
 *      doctor_id: // value for 'doctor_id'
 *      currentReceptionID: // value for 'currentReceptionID'
 *   },
 * });
 */
export function useGetDoctorsServicesSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetDoctorsServicesSubscription, GetDoctorsServicesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetDoctorsServicesSubscription, GetDoctorsServicesSubscriptionVariables>(GetDoctorsServicesDocument, options);
      }
export type GetDoctorsServicesSubscriptionHookResult = ReturnType<typeof useGetDoctorsServicesSubscription>;
export type GetDoctorsServicesSubscriptionResult = Apollo.SubscriptionResult<GetDoctorsServicesSubscription>;
export const TakeClientReceptionsVisitsDocument = gql`
    subscription TakeClientReceptionsVisits($id: Int = 0) {
  Visit(
    order_by: [{date: desc}, {without_time: asc}, {time: asc}]
    where: {client_id: {_eq: $id}}
  ) {
    id
    time
    patient_id
    patient_card
    from_id
    doctor_id
    department_id
    date
    current_reception_id
    client_id
    status
    Client {
      name
      phone_number
    }
    Doctor {
      Name
    }
    Pet {
      name
      PetType {
        name
      }
      birthdate
      sex
      breed
    }
    From {
      Name
    }
    without_time
    visit_start
    ReceptionsList(order_by: {start_time: asc}) {
      id
      start_time
      end_time
      internal_recomendation
      patient_recomendation
      From {
        Name
      }
      Doctor {
        Name
      }
      Medicines {
        value
        Medicine {
          name
          id
          price
        }
        medicine_id
        reception_id
        id
        done
      }
      Services {
        id
        service_id
        Service {
          id
          name
          price
        }
        price
        value
        reception_id
      }
      doctor_id
    }
    VisitFiles {
      id
      file_name
      name
    }
    VisitDocuments {
      name
      data
      id
    }
  }
}
    `;

/**
 * __useTakeClientReceptionsVisitsSubscription__
 *
 * To run a query within a React component, call `useTakeClientReceptionsVisitsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTakeClientReceptionsVisitsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTakeClientReceptionsVisitsSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTakeClientReceptionsVisitsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<TakeClientReceptionsVisitsSubscription, TakeClientReceptionsVisitsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TakeClientReceptionsVisitsSubscription, TakeClientReceptionsVisitsSubscriptionVariables>(TakeClientReceptionsVisitsDocument, options);
      }
export type TakeClientReceptionsVisitsSubscriptionHookResult = ReturnType<typeof useTakeClientReceptionsVisitsSubscription>;
export type TakeClientReceptionsVisitsSubscriptionResult = Apollo.SubscriptionResult<TakeClientReceptionsVisitsSubscription>;
export const MedicinesCardsDocument = gql`
    subscription MedicinesCards {
  DoctorsCard {
    id
    medicine_id
    Medicine {
      id
      name
      price
      ut_id
    }
  }
}
    `;

/**
 * __useMedicinesCardsSubscription__
 *
 * To run a query within a React component, call `useMedicinesCardsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMedicinesCardsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicinesCardsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMedicinesCardsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<MedicinesCardsSubscription, MedicinesCardsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<MedicinesCardsSubscription, MedicinesCardsSubscriptionVariables>(MedicinesCardsDocument, options);
      }
export type MedicinesCardsSubscriptionHookResult = ReturnType<typeof useMedicinesCardsSubscription>;
export type MedicinesCardsSubscriptionResult = Apollo.SubscriptionResult<MedicinesCardsSubscription>;
export const GetDoctorsTemplatesDocument = gql`
    query GetDoctorsTemplates($user_id: uuid = "") {
  Template(where: {user_id: {_eq: $user_id}}) {
    id
    name
    internal_recomendation
    patient_recomendation
    user_id
    TemplateMedicines {
      id
      medicine_id
      template_id
      value
      Medicine {
        id
        name
        price
        ut_id
      }
    }
    TemplateServices {
      id
      price
      service_id
      template_id
      value
      Service {
        id
        name
        price
        ut_id
      }
    }
  }
}
    `;

/**
 * __useGetDoctorsTemplatesQuery__
 *
 * To run a query within a React component, call `useGetDoctorsTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDoctorsTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDoctorsTemplatesQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetDoctorsTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetDoctorsTemplatesQuery, GetDoctorsTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDoctorsTemplatesQuery, GetDoctorsTemplatesQueryVariables>(GetDoctorsTemplatesDocument, options);
      }
export function useGetDoctorsTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDoctorsTemplatesQuery, GetDoctorsTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDoctorsTemplatesQuery, GetDoctorsTemplatesQueryVariables>(GetDoctorsTemplatesDocument, options);
        }
export type GetDoctorsTemplatesQueryHookResult = ReturnType<typeof useGetDoctorsTemplatesQuery>;
export type GetDoctorsTemplatesLazyQueryHookResult = ReturnType<typeof useGetDoctorsTemplatesLazyQuery>;
export type GetDoctorsTemplatesQueryResult = Apollo.QueryResult<GetDoctorsTemplatesQuery, GetDoctorsTemplatesQueryVariables>;
export const MoveToDoctorDocument = gql`
    mutation MoveToDoctor($id: Int = 0, $time: timetz = "", $status: Int = 0, $doctor_id: uuid = "", $department_id: Int = 0, $date: date = "", $current_reception_id: Int = 0, $from_id: uuid = "", $without_time: Boolean = false) {
  update_Visit(
    where: {id: {_eq: $id}}
    _set: {current_reception_id: $current_reception_id, date: $date, department_id: $department_id, doctor_id: $doctor_id, status: $status, time: $time, from_id: $from_id, without_time: $without_time}
  ) {
    returning {
      id
    }
  }
}
    `;
export type MoveToDoctorMutationFn = Apollo.MutationFunction<MoveToDoctorMutation, MoveToDoctorMutationVariables>;

/**
 * __useMoveToDoctorMutation__
 *
 * To run a mutation, you first call `useMoveToDoctorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveToDoctorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveToDoctorMutation, { data, loading, error }] = useMoveToDoctorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      time: // value for 'time'
 *      status: // value for 'status'
 *      doctor_id: // value for 'doctor_id'
 *      department_id: // value for 'department_id'
 *      date: // value for 'date'
 *      current_reception_id: // value for 'current_reception_id'
 *      from_id: // value for 'from_id'
 *      without_time: // value for 'without_time'
 *   },
 * });
 */
export function useMoveToDoctorMutation(baseOptions?: Apollo.MutationHookOptions<MoveToDoctorMutation, MoveToDoctorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveToDoctorMutation, MoveToDoctorMutationVariables>(MoveToDoctorDocument, options);
      }
export type MoveToDoctorMutationHookResult = ReturnType<typeof useMoveToDoctorMutation>;
export type MoveToDoctorMutationResult = Apollo.MutationResult<MoveToDoctorMutation>;
export type MoveToDoctorMutationOptions = Apollo.BaseMutationOptions<MoveToDoctorMutation, MoveToDoctorMutationVariables>;
export const PetStepSearchingDocument = gql`
    subscription PetStepSearching($limit: Int = 5, $searchText: String = "%%", $client_id: Int = 1) {
  Pet(
    limit: $limit
    where: {client_id: {_eq: $client_id}, name: {_ilike: $searchText}}
  ) {
    id
    name
    birthdate
    breed
    chip_number
    client_id
    sex
    type_id
    PetType {
      name
    }
  }
}
    `;

/**
 * __usePetStepSearchingSubscription__
 *
 * To run a query within a React component, call `usePetStepSearchingSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePetStepSearchingSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePetStepSearchingSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      searchText: // value for 'searchText'
 *      client_id: // value for 'client_id'
 *   },
 * });
 */
export function usePetStepSearchingSubscription(baseOptions?: Apollo.SubscriptionHookOptions<PetStepSearchingSubscription, PetStepSearchingSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PetStepSearchingSubscription, PetStepSearchingSubscriptionVariables>(PetStepSearchingDocument, options);
      }
export type PetStepSearchingSubscriptionHookResult = ReturnType<typeof usePetStepSearchingSubscription>;
export type PetStepSearchingSubscriptionResult = Apollo.SubscriptionResult<PetStepSearchingSubscription>;
export const GetDepartmentWithDoctorsDocument = gql`
    query GetDepartmentWithDoctors {
  Department {
    id
    name
    DoctorsDepartments {
      id
      Doctor {
        ID
        Name
        Login
      }
    }
  }
}
    `;

/**
 * __useGetDepartmentWithDoctorsQuery__
 *
 * To run a query within a React component, call `useGetDepartmentWithDoctorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepartmentWithDoctorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepartmentWithDoctorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDepartmentWithDoctorsQuery(baseOptions?: Apollo.QueryHookOptions<GetDepartmentWithDoctorsQuery, GetDepartmentWithDoctorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDepartmentWithDoctorsQuery, GetDepartmentWithDoctorsQueryVariables>(GetDepartmentWithDoctorsDocument, options);
      }
export function useGetDepartmentWithDoctorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDepartmentWithDoctorsQuery, GetDepartmentWithDoctorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDepartmentWithDoctorsQuery, GetDepartmentWithDoctorsQueryVariables>(GetDepartmentWithDoctorsDocument, options);
        }
export type GetDepartmentWithDoctorsQueryHookResult = ReturnType<typeof useGetDepartmentWithDoctorsQuery>;
export type GetDepartmentWithDoctorsLazyQueryHookResult = ReturnType<typeof useGetDepartmentWithDoctorsLazyQuery>;
export type GetDepartmentWithDoctorsQueryResult = Apollo.QueryResult<GetDepartmentWithDoctorsQuery, GetDepartmentWithDoctorsQueryVariables>;
export const MakeVisitDocument = gql`
    mutation MakeVisit($client_id: Int = 0, $current_reception_id: Int = null, $date: date = "", $department_id: Int = 0, $doctor_id: uuid = null, $from_id: uuid = "", $patient_id: Int = 0, $time: timetz = "", $patient_card: jsonb = "{}", $without_time: Boolean = false) {
  insert_Visit(
    objects: {client_id: $client_id, current_reception_id: $current_reception_id, date: $date, department_id: $department_id, doctor_id: $doctor_id, from_id: $from_id, patient_card: $patient_card, patient_id: $patient_id, time: $time, without_time: $without_time}
  ) {
    returning {
      id
    }
  }
}
    `;
export type MakeVisitMutationFn = Apollo.MutationFunction<MakeVisitMutation, MakeVisitMutationVariables>;

/**
 * __useMakeVisitMutation__
 *
 * To run a mutation, you first call `useMakeVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeVisitMutation, { data, loading, error }] = useMakeVisitMutation({
 *   variables: {
 *      client_id: // value for 'client_id'
 *      current_reception_id: // value for 'current_reception_id'
 *      date: // value for 'date'
 *      department_id: // value for 'department_id'
 *      doctor_id: // value for 'doctor_id'
 *      from_id: // value for 'from_id'
 *      patient_id: // value for 'patient_id'
 *      time: // value for 'time'
 *      patient_card: // value for 'patient_card'
 *      without_time: // value for 'without_time'
 *   },
 * });
 */
export function useMakeVisitMutation(baseOptions?: Apollo.MutationHookOptions<MakeVisitMutation, MakeVisitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeVisitMutation, MakeVisitMutationVariables>(MakeVisitDocument, options);
      }
export type MakeVisitMutationHookResult = ReturnType<typeof useMakeVisitMutation>;
export type MakeVisitMutationResult = Apollo.MutationResult<MakeVisitMutation>;
export type MakeVisitMutationOptions = Apollo.BaseMutationOptions<MakeVisitMutation, MakeVisitMutationVariables>;
export const TakeVisitsDocument = gql`
    subscription TakeVisits($date: date = "09-10-2023") {
  Department(
    where: {Visits_aggregate: {count: {predicate: {_neq: 0}, filter: {date: {_eq: $date}}}}}
  ) {
    id
    name
    Visits(
      where: {date: {_eq: $date}}
      order_by: [{without_time: asc}, {time: asc}]
    ) {
      id
      time
      patient_id
      patient_card
      from_id
      doctor_id
      department_id
      date
      current_reception_id
      client_id
      status
      Client {
        name
        phone_number
      }
      Doctor {
        Name
      }
      Pet {
        name
        PetType {
          name
        }
        birthdate
      }
      without_time
    }
  }
}
    `;

/**
 * __useTakeVisitsSubscription__
 *
 * To run a query within a React component, call `useTakeVisitsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTakeVisitsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTakeVisitsSubscription({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useTakeVisitsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<TakeVisitsSubscription, TakeVisitsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TakeVisitsSubscription, TakeVisitsSubscriptionVariables>(TakeVisitsDocument, options);
      }
export type TakeVisitsSubscriptionHookResult = ReturnType<typeof useTakeVisitsSubscription>;
export type TakeVisitsSubscriptionResult = Apollo.SubscriptionResult<TakeVisitsSubscription>;
export const DeleteVisitDocument = gql`
    mutation DeleteVisit($id: Int = 0) {
  delete_Visit(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteVisitMutationFn = Apollo.MutationFunction<DeleteVisitMutation, DeleteVisitMutationVariables>;

/**
 * __useDeleteVisitMutation__
 *
 * To run a mutation, you first call `useDeleteVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVisitMutation, { data, loading, error }] = useDeleteVisitMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVisitMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVisitMutation, DeleteVisitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVisitMutation, DeleteVisitMutationVariables>(DeleteVisitDocument, options);
      }
export type DeleteVisitMutationHookResult = ReturnType<typeof useDeleteVisitMutation>;
export type DeleteVisitMutationResult = Apollo.MutationResult<DeleteVisitMutation>;
export type DeleteVisitMutationOptions = Apollo.BaseMutationOptions<DeleteVisitMutation, DeleteVisitMutationVariables>;
export const ChangeVisitDateDocument = gql`
    mutation ChangeVisitDate($id: Int = 0, $date: date = "", $time: timetz = "") {
  update_Visit(where: {id: {_eq: $id}}, _set: {date: $date, time: $time}) {
    returning {
      id
    }
  }
}
    `;
export type ChangeVisitDateMutationFn = Apollo.MutationFunction<ChangeVisitDateMutation, ChangeVisitDateMutationVariables>;

/**
 * __useChangeVisitDateMutation__
 *
 * To run a mutation, you first call `useChangeVisitDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeVisitDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeVisitDateMutation, { data, loading, error }] = useChangeVisitDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *      time: // value for 'time'
 *   },
 * });
 */
export function useChangeVisitDateMutation(baseOptions?: Apollo.MutationHookOptions<ChangeVisitDateMutation, ChangeVisitDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeVisitDateMutation, ChangeVisitDateMutationVariables>(ChangeVisitDateDocument, options);
      }
export type ChangeVisitDateMutationHookResult = ReturnType<typeof useChangeVisitDateMutation>;
export type ChangeVisitDateMutationResult = Apollo.MutationResult<ChangeVisitDateMutation>;
export type ChangeVisitDateMutationOptions = Apollo.BaseMutationOptions<ChangeVisitDateMutation, ChangeVisitDateMutationVariables>;
export const GetRegistratorDocumentsDocument = gql`
    subscription GetRegistratorDocuments {
  RegistrationDocument {
    id
    Document {
      id
      name
      body
      variables
    }
  }
}
    `;

/**
 * __useGetRegistratorDocumentsSubscription__
 *
 * To run a query within a React component, call `useGetRegistratorDocumentsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetRegistratorDocumentsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegistratorDocumentsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetRegistratorDocumentsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetRegistratorDocumentsSubscription, GetRegistratorDocumentsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetRegistratorDocumentsSubscription, GetRegistratorDocumentsSubscriptionVariables>(GetRegistratorDocumentsDocument, options);
      }
export type GetRegistratorDocumentsSubscriptionHookResult = ReturnType<typeof useGetRegistratorDocumentsSubscription>;
export type GetRegistratorDocumentsSubscriptionResult = Apollo.SubscriptionResult<GetRegistratorDocumentsSubscription>;
export const GetVisitForDocumentDocument = gql`
    query GetVisitForDocument($id: Int = 0) {
  Visit(where: {id: {_eq: $id}}) {
    date
    id
    patient_card
    time
    Client {
      name
      email
      passport
      passport_when
      passport_where
      phone_number
      address
    }
    CurrentReception {
      internal_recomendation
      patient_recomendation
      Services {
        Service {
          name
          price
        }
        price
        value
      }
      Medicines {
        Medicine {
          name
          price
        }
        value
      }
      From {
        Name
      }
      Doctor {
        Name
      }
      end_time
      start_time
    }
    Department {
      name
    }
    Doctor {
      Name
    }
    From {
      Name
    }
    Pet {
      birthdate
      breed
      chip_number
      name
      sex
      PetType {
        name
      }
    }
    ReceptionsList {
      internal_recomendation
      patient_recomendation
      Services {
        Service {
          name
          price
        }
        price
        value
      }
      Medicines {
        Medicine {
          name
          price
        }
        value
      }
      From {
        Name
      }
      Doctor {
        Name
      }
      end_time
      start_time
    }
  }
}
    `;

/**
 * __useGetVisitForDocumentQuery__
 *
 * To run a query within a React component, call `useGetVisitForDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVisitForDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisitForDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVisitForDocumentQuery(baseOptions?: Apollo.QueryHookOptions<GetVisitForDocumentQuery, GetVisitForDocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVisitForDocumentQuery, GetVisitForDocumentQueryVariables>(GetVisitForDocumentDocument, options);
      }
export function useGetVisitForDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVisitForDocumentQuery, GetVisitForDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVisitForDocumentQuery, GetVisitForDocumentQueryVariables>(GetVisitForDocumentDocument, options);
        }
export type GetVisitForDocumentQueryHookResult = ReturnType<typeof useGetVisitForDocumentQuery>;
export type GetVisitForDocumentLazyQueryHookResult = ReturnType<typeof useGetVisitForDocumentLazyQuery>;
export type GetVisitForDocumentQueryResult = Apollo.QueryResult<GetVisitForDocumentQuery, GetVisitForDocumentQueryVariables>;
export const ChangeVisitStatusAndSetVisitTimeDocument = gql`
    mutation ChangeVisitStatusAndSetVisitTime($id: Int = 0, $status: Int = 0, $visit_start: time = null) {
  update_Visit(
    where: {id: {_eq: $id}}
    _set: {status: $status, visit_start: $visit_start}
  ) {
    returning {
      id
    }
  }
}
    `;
export type ChangeVisitStatusAndSetVisitTimeMutationFn = Apollo.MutationFunction<ChangeVisitStatusAndSetVisitTimeMutation, ChangeVisitStatusAndSetVisitTimeMutationVariables>;

/**
 * __useChangeVisitStatusAndSetVisitTimeMutation__
 *
 * To run a mutation, you first call `useChangeVisitStatusAndSetVisitTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeVisitStatusAndSetVisitTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeVisitStatusAndSetVisitTimeMutation, { data, loading, error }] = useChangeVisitStatusAndSetVisitTimeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *      visit_start: // value for 'visit_start'
 *   },
 * });
 */
export function useChangeVisitStatusAndSetVisitTimeMutation(baseOptions?: Apollo.MutationHookOptions<ChangeVisitStatusAndSetVisitTimeMutation, ChangeVisitStatusAndSetVisitTimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeVisitStatusAndSetVisitTimeMutation, ChangeVisitStatusAndSetVisitTimeMutationVariables>(ChangeVisitStatusAndSetVisitTimeDocument, options);
      }
export type ChangeVisitStatusAndSetVisitTimeMutationHookResult = ReturnType<typeof useChangeVisitStatusAndSetVisitTimeMutation>;
export type ChangeVisitStatusAndSetVisitTimeMutationResult = Apollo.MutationResult<ChangeVisitStatusAndSetVisitTimeMutation>;
export type ChangeVisitStatusAndSetVisitTimeMutationOptions = Apollo.BaseMutationOptions<ChangeVisitStatusAndSetVisitTimeMutation, ChangeVisitStatusAndSetVisitTimeMutationVariables>;
export const TakeVisitForInformationDocument = gql`
    subscription TakeVisitForInformation($id: Int = 0) {
  Visit(where: {id: {_eq: $id}}) {
    id
    time
    patient_id
    patient_card
    from_id
    doctor_id
    department_id
    date
    current_reception_id
    client_id
    status
    Client {
      name
      phone_number
    }
    Doctor {
      Name
    }
    Pet {
      name
      PetType {
        name
      }
      birthdate
      sex
      breed
    }
    From {
      Name
    }
    without_time
    visit_start
    ReceptionsList(order_by: {start_time: asc}) {
      id
      start_time
      end_time
      internal_recomendation
      patient_recomendation
      From {
        Name
      }
      Doctor {
        Name
      }
      Medicines {
        value
        Medicine {
          name
          id
          price
        }
        medicine_id
        reception_id
        id
        done
      }
      Services {
        id
        service_id
        reception_id
        Service {
          id
          name
          price
        }
        price
        value
      }
      doctor_id
    }
    VisitFiles {
      id
      file_name
      name
    }
    VisitDocuments {
      name
      data
      id
    }
  }
}
    `;

/**
 * __useTakeVisitForInformationSubscription__
 *
 * To run a query within a React component, call `useTakeVisitForInformationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTakeVisitForInformationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTakeVisitForInformationSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTakeVisitForInformationSubscription(baseOptions?: Apollo.SubscriptionHookOptions<TakeVisitForInformationSubscription, TakeVisitForInformationSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TakeVisitForInformationSubscription, TakeVisitForInformationSubscriptionVariables>(TakeVisitForInformationDocument, options);
      }
export type TakeVisitForInformationSubscriptionHookResult = ReturnType<typeof useTakeVisitForInformationSubscription>;
export type TakeVisitForInformationSubscriptionResult = Apollo.SubscriptionResult<TakeVisitForInformationSubscription>;
export const MedicinesIndexDocument = gql`
    subscription MedicinesIndex($order_by: [Medicine_order_by!] = {}, $where: Medicine_bool_exp = {}, $offset: Int = 0, $limit: Int = 10) {
  Medicine(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    id
    name
    price
    ut_id
  }
}
    `;

/**
 * __useMedicinesIndexSubscription__
 *
 * To run a query within a React component, call `useMedicinesIndexSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMedicinesIndexSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicinesIndexSubscription({
 *   variables: {
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useMedicinesIndexSubscription(baseOptions?: Apollo.SubscriptionHookOptions<MedicinesIndexSubscription, MedicinesIndexSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<MedicinesIndexSubscription, MedicinesIndexSubscriptionVariables>(MedicinesIndexDocument, options);
      }
export type MedicinesIndexSubscriptionHookResult = ReturnType<typeof useMedicinesIndexSubscription>;
export type MedicinesIndexSubscriptionResult = Apollo.SubscriptionResult<MedicinesIndexSubscription>;
export const MedicineIndexCountDocument = gql`
    subscription MedicineIndexCount($where: Medicine_bool_exp = {}) {
  Medicine_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useMedicineIndexCountSubscription__
 *
 * To run a query within a React component, call `useMedicineIndexCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMedicineIndexCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicineIndexCountSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMedicineIndexCountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<MedicineIndexCountSubscription, MedicineIndexCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<MedicineIndexCountSubscription, MedicineIndexCountSubscriptionVariables>(MedicineIndexCountDocument, options);
      }
export type MedicineIndexCountSubscriptionHookResult = ReturnType<typeof useMedicineIndexCountSubscription>;
export type MedicineIndexCountSubscriptionResult = Apollo.SubscriptionResult<MedicineIndexCountSubscription>;
export const AddMedicineDocument = gql`
    mutation AddMedicine($name: String = "", $price: float8 = "", $ut_id: String = "") {
  insert_Medicine(objects: {name: $name, price: $price, ut_id: $ut_id}) {
    returning {
      id
    }
  }
}
    `;
export type AddMedicineMutationFn = Apollo.MutationFunction<AddMedicineMutation, AddMedicineMutationVariables>;

/**
 * __useAddMedicineMutation__
 *
 * To run a mutation, you first call `useAddMedicineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMedicineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMedicineMutation, { data, loading, error }] = useAddMedicineMutation({
 *   variables: {
 *      name: // value for 'name'
 *      price: // value for 'price'
 *      ut_id: // value for 'ut_id'
 *   },
 * });
 */
export function useAddMedicineMutation(baseOptions?: Apollo.MutationHookOptions<AddMedicineMutation, AddMedicineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMedicineMutation, AddMedicineMutationVariables>(AddMedicineDocument, options);
      }
export type AddMedicineMutationHookResult = ReturnType<typeof useAddMedicineMutation>;
export type AddMedicineMutationResult = Apollo.MutationResult<AddMedicineMutation>;
export type AddMedicineMutationOptions = Apollo.BaseMutationOptions<AddMedicineMutation, AddMedicineMutationVariables>;
export const UpdateMedicineDocument = gql`
    mutation UpdateMedicine($name: String = "", $id: Int = 0, $price: float8 = "", $ut_id: String = "") {
  update_Medicine(
    where: {id: {_eq: $id}}
    _set: {name: $name, price: $price, ut_id: $ut_id}
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpdateMedicineMutationFn = Apollo.MutationFunction<UpdateMedicineMutation, UpdateMedicineMutationVariables>;

/**
 * __useUpdateMedicineMutation__
 *
 * To run a mutation, you first call `useUpdateMedicineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMedicineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMedicineMutation, { data, loading, error }] = useUpdateMedicineMutation({
 *   variables: {
 *      name: // value for 'name'
 *      id: // value for 'id'
 *      price: // value for 'price'
 *      ut_id: // value for 'ut_id'
 *   },
 * });
 */
export function useUpdateMedicineMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMedicineMutation, UpdateMedicineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMedicineMutation, UpdateMedicineMutationVariables>(UpdateMedicineDocument, options);
      }
export type UpdateMedicineMutationHookResult = ReturnType<typeof useUpdateMedicineMutation>;
export type UpdateMedicineMutationResult = Apollo.MutationResult<UpdateMedicineMutation>;
export type UpdateMedicineMutationOptions = Apollo.BaseMutationOptions<UpdateMedicineMutation, UpdateMedicineMutationVariables>;
export const DeleteMedicineDocument = gql`
    mutation DeleteMedicine($id: Int = 10) {
  delete_Medicine(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteMedicineMutationFn = Apollo.MutationFunction<DeleteMedicineMutation, DeleteMedicineMutationVariables>;

/**
 * __useDeleteMedicineMutation__
 *
 * To run a mutation, you first call `useDeleteMedicineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMedicineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMedicineMutation, { data, loading, error }] = useDeleteMedicineMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMedicineMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMedicineMutation, DeleteMedicineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMedicineMutation, DeleteMedicineMutationVariables>(DeleteMedicineDocument, options);
      }
export type DeleteMedicineMutationHookResult = ReturnType<typeof useDeleteMedicineMutation>;
export type DeleteMedicineMutationResult = Apollo.MutationResult<DeleteMedicineMutation>;
export type DeleteMedicineMutationOptions = Apollo.BaseMutationOptions<DeleteMedicineMutation, DeleteMedicineMutationVariables>;
export const DeleteMedicinesByIdsDocument = gql`
    mutation DeleteMedicinesByIds($ids: [Int!] = 10) {
  delete_Medicine(where: {id: {_in: $ids}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteMedicinesByIdsMutationFn = Apollo.MutationFunction<DeleteMedicinesByIdsMutation, DeleteMedicinesByIdsMutationVariables>;

/**
 * __useDeleteMedicinesByIdsMutation__
 *
 * To run a mutation, you first call `useDeleteMedicinesByIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMedicinesByIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMedicinesByIdsMutation, { data, loading, error }] = useDeleteMedicinesByIdsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteMedicinesByIdsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMedicinesByIdsMutation, DeleteMedicinesByIdsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMedicinesByIdsMutation, DeleteMedicinesByIdsMutationVariables>(DeleteMedicinesByIdsDocument, options);
      }
export type DeleteMedicinesByIdsMutationHookResult = ReturnType<typeof useDeleteMedicinesByIdsMutation>;
export type DeleteMedicinesByIdsMutationResult = Apollo.MutationResult<DeleteMedicinesByIdsMutation>;
export type DeleteMedicinesByIdsMutationOptions = Apollo.BaseMutationOptions<DeleteMedicinesByIdsMutation, DeleteMedicinesByIdsMutationVariables>;
export const PetTypesIndexDocument = gql`
    subscription PetTypesIndex($limit: Int = 10, $offset: Int = 0, $order_by: [PetType_order_by!] = {}, $where: PetType_bool_exp = {}) {
  PetType(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    name
    id
  }
}
    `;

/**
 * __usePetTypesIndexSubscription__
 *
 * To run a query within a React component, call `usePetTypesIndexSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePetTypesIndexSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePetTypesIndexSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePetTypesIndexSubscription(baseOptions?: Apollo.SubscriptionHookOptions<PetTypesIndexSubscription, PetTypesIndexSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PetTypesIndexSubscription, PetTypesIndexSubscriptionVariables>(PetTypesIndexDocument, options);
      }
export type PetTypesIndexSubscriptionHookResult = ReturnType<typeof usePetTypesIndexSubscription>;
export type PetTypesIndexSubscriptionResult = Apollo.SubscriptionResult<PetTypesIndexSubscription>;
export const PetTypesIndexCountDocument = gql`
    subscription PetTypesIndexCount($where: PetType_bool_exp = {}) {
  PetType_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __usePetTypesIndexCountSubscription__
 *
 * To run a query within a React component, call `usePetTypesIndexCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePetTypesIndexCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePetTypesIndexCountSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePetTypesIndexCountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<PetTypesIndexCountSubscription, PetTypesIndexCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PetTypesIndexCountSubscription, PetTypesIndexCountSubscriptionVariables>(PetTypesIndexCountDocument, options);
      }
export type PetTypesIndexCountSubscriptionHookResult = ReturnType<typeof usePetTypesIndexCountSubscription>;
export type PetTypesIndexCountSubscriptionResult = Apollo.SubscriptionResult<PetTypesIndexCountSubscription>;
export const AddPetsTypeDocument = gql`
    mutation AddPetsType($name: String = "") {
  insert_PetType(objects: {name: $name}) {
    returning {
      id
    }
  }
}
    `;
export type AddPetsTypeMutationFn = Apollo.MutationFunction<AddPetsTypeMutation, AddPetsTypeMutationVariables>;

/**
 * __useAddPetsTypeMutation__
 *
 * To run a mutation, you first call `useAddPetsTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPetsTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPetsTypeMutation, { data, loading, error }] = useAddPetsTypeMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddPetsTypeMutation(baseOptions?: Apollo.MutationHookOptions<AddPetsTypeMutation, AddPetsTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPetsTypeMutation, AddPetsTypeMutationVariables>(AddPetsTypeDocument, options);
      }
export type AddPetsTypeMutationHookResult = ReturnType<typeof useAddPetsTypeMutation>;
export type AddPetsTypeMutationResult = Apollo.MutationResult<AddPetsTypeMutation>;
export type AddPetsTypeMutationOptions = Apollo.BaseMutationOptions<AddPetsTypeMutation, AddPetsTypeMutationVariables>;
export const UpdatePetTypeDocument = gql`
    mutation UpdatePetType($name: String = "", $id: Int = 10) {
  update_PetType(where: {id: {_eq: $id}}, _set: {name: $name}) {
    returning {
      id
    }
  }
}
    `;
export type UpdatePetTypeMutationFn = Apollo.MutationFunction<UpdatePetTypeMutation, UpdatePetTypeMutationVariables>;

/**
 * __useUpdatePetTypeMutation__
 *
 * To run a mutation, you first call `useUpdatePetTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePetTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePetTypeMutation, { data, loading, error }] = useUpdatePetTypeMutation({
 *   variables: {
 *      name: // value for 'name'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdatePetTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePetTypeMutation, UpdatePetTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePetTypeMutation, UpdatePetTypeMutationVariables>(UpdatePetTypeDocument, options);
      }
export type UpdatePetTypeMutationHookResult = ReturnType<typeof useUpdatePetTypeMutation>;
export type UpdatePetTypeMutationResult = Apollo.MutationResult<UpdatePetTypeMutation>;
export type UpdatePetTypeMutationOptions = Apollo.BaseMutationOptions<UpdatePetTypeMutation, UpdatePetTypeMutationVariables>;
export const DeletePetTypeDocument = gql`
    mutation DeletePetType($id: Int = 10) {
  delete_PetType(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeletePetTypeMutationFn = Apollo.MutationFunction<DeletePetTypeMutation, DeletePetTypeMutationVariables>;

/**
 * __useDeletePetTypeMutation__
 *
 * To run a mutation, you first call `useDeletePetTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePetTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePetTypeMutation, { data, loading, error }] = useDeletePetTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePetTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeletePetTypeMutation, DeletePetTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePetTypeMutation, DeletePetTypeMutationVariables>(DeletePetTypeDocument, options);
      }
export type DeletePetTypeMutationHookResult = ReturnType<typeof useDeletePetTypeMutation>;
export type DeletePetTypeMutationResult = Apollo.MutationResult<DeletePetTypeMutation>;
export type DeletePetTypeMutationOptions = Apollo.BaseMutationOptions<DeletePetTypeMutation, DeletePetTypeMutationVariables>;
export const DeleteTypesByIdsDocument = gql`
    mutation DeleteTypesByIds($ids: [Int!] = 10) {
  delete_PetType(where: {id: {_in: $ids}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteTypesByIdsMutationFn = Apollo.MutationFunction<DeleteTypesByIdsMutation, DeleteTypesByIdsMutationVariables>;

/**
 * __useDeleteTypesByIdsMutation__
 *
 * To run a mutation, you first call `useDeleteTypesByIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTypesByIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTypesByIdsMutation, { data, loading, error }] = useDeleteTypesByIdsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteTypesByIdsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTypesByIdsMutation, DeleteTypesByIdsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTypesByIdsMutation, DeleteTypesByIdsMutationVariables>(DeleteTypesByIdsDocument, options);
      }
export type DeleteTypesByIdsMutationHookResult = ReturnType<typeof useDeleteTypesByIdsMutation>;
export type DeleteTypesByIdsMutationResult = Apollo.MutationResult<DeleteTypesByIdsMutation>;
export type DeleteTypesByIdsMutationOptions = Apollo.BaseMutationOptions<DeleteTypesByIdsMutation, DeleteTypesByIdsMutationVariables>;
export const PetsIndexDocument = gql`
    subscription PetsIndex($limit: Int = 10, $offset: Int = 0, $order_by: [Pet_order_by!] = {}, $where: Pet_bool_exp = {}) {
  Pet(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    id
    birthdate
    breed
    chip_number
    client_id
    name
    sex
    type_id
    PetType {
      name
    }
    Client {
      name
    }
  }
}
    `;

/**
 * __usePetsIndexSubscription__
 *
 * To run a query within a React component, call `usePetsIndexSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePetsIndexSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePetsIndexSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePetsIndexSubscription(baseOptions?: Apollo.SubscriptionHookOptions<PetsIndexSubscription, PetsIndexSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PetsIndexSubscription, PetsIndexSubscriptionVariables>(PetsIndexDocument, options);
      }
export type PetsIndexSubscriptionHookResult = ReturnType<typeof usePetsIndexSubscription>;
export type PetsIndexSubscriptionResult = Apollo.SubscriptionResult<PetsIndexSubscription>;
export const PetsIndexCountDocument = gql`
    subscription PetsIndexCount($where: Pet_bool_exp = {}) {
  Pet_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __usePetsIndexCountSubscription__
 *
 * To run a query within a React component, call `usePetsIndexCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePetsIndexCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePetsIndexCountSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePetsIndexCountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<PetsIndexCountSubscription, PetsIndexCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PetsIndexCountSubscription, PetsIndexCountSubscriptionVariables>(PetsIndexCountDocument, options);
      }
export type PetsIndexCountSubscriptionHookResult = ReturnType<typeof usePetsIndexCountSubscription>;
export type PetsIndexCountSubscriptionResult = Apollo.SubscriptionResult<PetsIndexCountSubscription>;
export const AddPetDocument = gql`
    mutation AddPet($birthdate: date = "", $breed: String = "", $chip_number: String = "", $client_id: Int = 10, $name: String = "", $sex: Int = 10, $type_id: Int = 10) {
  insert_Pet(
    objects: {birthdate: $birthdate, breed: $breed, chip_number: $chip_number, client_id: $client_id, name: $name, sex: $sex, type_id: $type_id}
  ) {
    returning {
      id
    }
  }
}
    `;
export type AddPetMutationFn = Apollo.MutationFunction<AddPetMutation, AddPetMutationVariables>;

/**
 * __useAddPetMutation__
 *
 * To run a mutation, you first call `useAddPetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPetMutation, { data, loading, error }] = useAddPetMutation({
 *   variables: {
 *      birthdate: // value for 'birthdate'
 *      breed: // value for 'breed'
 *      chip_number: // value for 'chip_number'
 *      client_id: // value for 'client_id'
 *      name: // value for 'name'
 *      sex: // value for 'sex'
 *      type_id: // value for 'type_id'
 *   },
 * });
 */
export function useAddPetMutation(baseOptions?: Apollo.MutationHookOptions<AddPetMutation, AddPetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPetMutation, AddPetMutationVariables>(AddPetDocument, options);
      }
export type AddPetMutationHookResult = ReturnType<typeof useAddPetMutation>;
export type AddPetMutationResult = Apollo.MutationResult<AddPetMutation>;
export type AddPetMutationOptions = Apollo.BaseMutationOptions<AddPetMutation, AddPetMutationVariables>;
export const UpdatePetDocument = gql`
    mutation UpdatePet($id: Int = 10, $birthdate: date = "", $breed: String = "", $chip_number: String = "", $client_id: Int = 10, $name: String = "", $sex: Int = 10, $type_id: Int = 10) {
  update_Pet(
    where: {id: {_eq: $id}}
    _set: {birthdate: $birthdate, breed: $breed, chip_number: $chip_number, client_id: $client_id, name: $name, sex: $sex, type_id: $type_id}
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpdatePetMutationFn = Apollo.MutationFunction<UpdatePetMutation, UpdatePetMutationVariables>;

/**
 * __useUpdatePetMutation__
 *
 * To run a mutation, you first call `useUpdatePetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePetMutation, { data, loading, error }] = useUpdatePetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      birthdate: // value for 'birthdate'
 *      breed: // value for 'breed'
 *      chip_number: // value for 'chip_number'
 *      client_id: // value for 'client_id'
 *      name: // value for 'name'
 *      sex: // value for 'sex'
 *      type_id: // value for 'type_id'
 *   },
 * });
 */
export function useUpdatePetMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePetMutation, UpdatePetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePetMutation, UpdatePetMutationVariables>(UpdatePetDocument, options);
      }
export type UpdatePetMutationHookResult = ReturnType<typeof useUpdatePetMutation>;
export type UpdatePetMutationResult = Apollo.MutationResult<UpdatePetMutation>;
export type UpdatePetMutationOptions = Apollo.BaseMutationOptions<UpdatePetMutation, UpdatePetMutationVariables>;
export const DeletePetDocument = gql`
    mutation DeletePet($id: Int = 10) {
  delete_Pet(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeletePetMutationFn = Apollo.MutationFunction<DeletePetMutation, DeletePetMutationVariables>;

/**
 * __useDeletePetMutation__
 *
 * To run a mutation, you first call `useDeletePetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePetMutation, { data, loading, error }] = useDeletePetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePetMutation(baseOptions?: Apollo.MutationHookOptions<DeletePetMutation, DeletePetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePetMutation, DeletePetMutationVariables>(DeletePetDocument, options);
      }
export type DeletePetMutationHookResult = ReturnType<typeof useDeletePetMutation>;
export type DeletePetMutationResult = Apollo.MutationResult<DeletePetMutation>;
export type DeletePetMutationOptions = Apollo.BaseMutationOptions<DeletePetMutation, DeletePetMutationVariables>;
export const DeletePetsByIdsDocument = gql`
    mutation DeletePetsByIds($ids: [Int!] = 10) {
  delete_Pet(where: {id: {_in: $ids}}) {
    returning {
      id
    }
  }
}
    `;
export type DeletePetsByIdsMutationFn = Apollo.MutationFunction<DeletePetsByIdsMutation, DeletePetsByIdsMutationVariables>;

/**
 * __useDeletePetsByIdsMutation__
 *
 * To run a mutation, you first call `useDeletePetsByIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePetsByIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePetsByIdsMutation, { data, loading, error }] = useDeletePetsByIdsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeletePetsByIdsMutation(baseOptions?: Apollo.MutationHookOptions<DeletePetsByIdsMutation, DeletePetsByIdsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePetsByIdsMutation, DeletePetsByIdsMutationVariables>(DeletePetsByIdsDocument, options);
      }
export type DeletePetsByIdsMutationHookResult = ReturnType<typeof useDeletePetsByIdsMutation>;
export type DeletePetsByIdsMutationResult = Apollo.MutationResult<DeletePetsByIdsMutation>;
export type DeletePetsByIdsMutationOptions = Apollo.BaseMutationOptions<DeletePetsByIdsMutation, DeletePetsByIdsMutationVariables>;
export const PetTypeFkDocument = gql`
    subscription PetTypeFk($limit: Int = 10, $searchText: String = "%%") {
  PetType(where: {name: {_ilike: $searchText}}, limit: $limit) {
    id
    name
  }
}
    `;

/**
 * __usePetTypeFkSubscription__
 *
 * To run a query within a React component, call `usePetTypeFkSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePetTypeFkSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePetTypeFkSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function usePetTypeFkSubscription(baseOptions?: Apollo.SubscriptionHookOptions<PetTypeFkSubscription, PetTypeFkSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PetTypeFkSubscription, PetTypeFkSubscriptionVariables>(PetTypeFkDocument, options);
      }
export type PetTypeFkSubscriptionHookResult = ReturnType<typeof usePetTypeFkSubscription>;
export type PetTypeFkSubscriptionResult = Apollo.SubscriptionResult<PetTypeFkSubscription>;
export const PetTypeModelFkDocument = gql`
    query PetTypeModelFk($id: Int = 0) {
  PetType_by_pk(id: $id) {
    id
    name
  }
}
    `;

/**
 * __usePetTypeModelFkQuery__
 *
 * To run a query within a React component, call `usePetTypeModelFkQuery` and pass it any options that fit your needs.
 * When your component renders, `usePetTypeModelFkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePetTypeModelFkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePetTypeModelFkQuery(baseOptions?: Apollo.QueryHookOptions<PetTypeModelFkQuery, PetTypeModelFkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PetTypeModelFkQuery, PetTypeModelFkQueryVariables>(PetTypeModelFkDocument, options);
      }
export function usePetTypeModelFkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PetTypeModelFkQuery, PetTypeModelFkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PetTypeModelFkQuery, PetTypeModelFkQueryVariables>(PetTypeModelFkDocument, options);
        }
export type PetTypeModelFkQueryHookResult = ReturnType<typeof usePetTypeModelFkQuery>;
export type PetTypeModelFkLazyQueryHookResult = ReturnType<typeof usePetTypeModelFkLazyQuery>;
export type PetTypeModelFkQueryResult = Apollo.QueryResult<PetTypeModelFkQuery, PetTypeModelFkQueryVariables>;
export const ClientFkDocument = gql`
    subscription ClientFk($limit: Int = 10, $searchText: String = "%%") {
  Client(
    limit: $limit
    where: {_or: [{name: {_ilike: $searchText}}, {email: {_ilike: $searchText}}, {phone_number: {_ilike: $searchText}}, {passport: {_ilike: $searchText}}]}
  ) {
    id
    name
    email
    passport
    passport_when
    passport_where
    phone_number
  }
}
    `;

/**
 * __useClientFkSubscription__
 *
 * To run a query within a React component, call `useClientFkSubscription` and pass it any options that fit your needs.
 * When your component renders, `useClientFkSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientFkSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useClientFkSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ClientFkSubscription, ClientFkSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ClientFkSubscription, ClientFkSubscriptionVariables>(ClientFkDocument, options);
      }
export type ClientFkSubscriptionHookResult = ReturnType<typeof useClientFkSubscription>;
export type ClientFkSubscriptionResult = Apollo.SubscriptionResult<ClientFkSubscription>;
export const ClientModelFkDocument = gql`
    query ClientModelFk($id: Int = 0) {
  Client_by_pk(id: $id) {
    id
    name
    email
    passport
    passport_when
    passport_where
    phone_number
  }
}
    `;

/**
 * __useClientModelFkQuery__
 *
 * To run a query within a React component, call `useClientModelFkQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientModelFkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientModelFkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClientModelFkQuery(baseOptions?: Apollo.QueryHookOptions<ClientModelFkQuery, ClientModelFkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientModelFkQuery, ClientModelFkQueryVariables>(ClientModelFkDocument, options);
      }
export function useClientModelFkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientModelFkQuery, ClientModelFkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientModelFkQuery, ClientModelFkQueryVariables>(ClientModelFkDocument, options);
        }
export type ClientModelFkQueryHookResult = ReturnType<typeof useClientModelFkQuery>;
export type ClientModelFkLazyQueryHookResult = ReturnType<typeof useClientModelFkLazyQuery>;
export type ClientModelFkQueryResult = Apollo.QueryResult<ClientModelFkQuery, ClientModelFkQueryVariables>;
export const GetRegistrationDocumentsDocument = gql`
    query GetRegistrationDocuments {
  RegistrationDocument {
    id
    document_id
    Document {
      name
    }
  }
}
    `;

/**
 * __useGetRegistrationDocumentsQuery__
 *
 * To run a query within a React component, call `useGetRegistrationDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegistrationDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegistrationDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRegistrationDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<GetRegistrationDocumentsQuery, GetRegistrationDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegistrationDocumentsQuery, GetRegistrationDocumentsQueryVariables>(GetRegistrationDocumentsDocument, options);
      }
export function useGetRegistrationDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegistrationDocumentsQuery, GetRegistrationDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegistrationDocumentsQuery, GetRegistrationDocumentsQueryVariables>(GetRegistrationDocumentsDocument, options);
        }
export type GetRegistrationDocumentsQueryHookResult = ReturnType<typeof useGetRegistrationDocumentsQuery>;
export type GetRegistrationDocumentsLazyQueryHookResult = ReturnType<typeof useGetRegistrationDocumentsLazyQuery>;
export type GetRegistrationDocumentsQueryResult = Apollo.QueryResult<GetRegistrationDocumentsQuery, GetRegistrationDocumentsQueryVariables>;
export const DeleteRegistrationDocumentDocument = gql`
    mutation DeleteRegistrationDocument($_in: [Int!] = 0) {
  delete_RegistrationDocument(where: {id: {_in: $_in}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteRegistrationDocumentMutationFn = Apollo.MutationFunction<DeleteRegistrationDocumentMutation, DeleteRegistrationDocumentMutationVariables>;

/**
 * __useDeleteRegistrationDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteRegistrationDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegistrationDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegistrationDocumentMutation, { data, loading, error }] = useDeleteRegistrationDocumentMutation({
 *   variables: {
 *      _in: // value for '_in'
 *   },
 * });
 */
export function useDeleteRegistrationDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRegistrationDocumentMutation, DeleteRegistrationDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRegistrationDocumentMutation, DeleteRegistrationDocumentMutationVariables>(DeleteRegistrationDocumentDocument, options);
      }
export type DeleteRegistrationDocumentMutationHookResult = ReturnType<typeof useDeleteRegistrationDocumentMutation>;
export type DeleteRegistrationDocumentMutationResult = Apollo.MutationResult<DeleteRegistrationDocumentMutation>;
export type DeleteRegistrationDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteRegistrationDocumentMutation, DeleteRegistrationDocumentMutationVariables>;
export const AddRegistrationDocumentDocument = gql`
    mutation AddRegistrationDocument($objects: [RegistrationDocument_insert_input!] = {}) {
  insert_RegistrationDocument(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type AddRegistrationDocumentMutationFn = Apollo.MutationFunction<AddRegistrationDocumentMutation, AddRegistrationDocumentMutationVariables>;

/**
 * __useAddRegistrationDocumentMutation__
 *
 * To run a mutation, you first call `useAddRegistrationDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRegistrationDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRegistrationDocumentMutation, { data, loading, error }] = useAddRegistrationDocumentMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddRegistrationDocumentMutation(baseOptions?: Apollo.MutationHookOptions<AddRegistrationDocumentMutation, AddRegistrationDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRegistrationDocumentMutation, AddRegistrationDocumentMutationVariables>(AddRegistrationDocumentDocument, options);
      }
export type AddRegistrationDocumentMutationHookResult = ReturnType<typeof useAddRegistrationDocumentMutation>;
export type AddRegistrationDocumentMutationResult = Apollo.MutationResult<AddRegistrationDocumentMutation>;
export type AddRegistrationDocumentMutationOptions = Apollo.BaseMutationOptions<AddRegistrationDocumentMutation, AddRegistrationDocumentMutationVariables>;
export const TakeDoctorsEditVisitsDocument = gql`
    subscription TakeDoctorsEditVisits($date: date = "", $doctor_id: uuid = "") {
  Department(
    where: {Visits_aggregate: {count: {predicate: {_neq: 0}, filter: {_and: [{date: {_eq: $date}}, {ReceptionsList: {doctor_id: {_eq: $doctor_id}}}]}}}}
  ) {
    id
    name
    Visits(
      where: {_and: [{date: {_eq: $date}}, {ReceptionsList: {doctor_id: {_eq: $doctor_id}}}]}
      order_by: [{without_time: asc}, {time: asc}]
    ) {
      id
      time
      patient_id
      patient_card
      from_id
      doctor_id
      department_id
      date
      current_reception_id
      client_id
      status
      Client {
        name
        phone_number
      }
      Doctor {
        Name
      }
      Pet {
        name
        PetType {
          name
        }
        birthdate
        sex
        breed
      }
      From {
        Name
      }
      without_time
      visit_start
      ReceptionsList(order_by: {start_time: asc}) {
        id
        start_time
        end_time
        internal_recomendation
        patient_recomendation
        From {
          Name
        }
        Doctor {
          Name
        }
        Medicines {
          value
          Medicine {
            name
            id
            price
          }
          medicine_id
          reception_id
          id
          done
        }
        Services {
          id
          service_id
          Service {
            id
            name
            price
          }
          price
          value
          reception_id
        }
        doctor_id
      }
      VisitFiles {
        id
        file_name
        name
      }
      VisitDocuments {
        name
        data
        id
      }
    }
  }
}
    `;

/**
 * __useTakeDoctorsEditVisitsSubscription__
 *
 * To run a query within a React component, call `useTakeDoctorsEditVisitsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTakeDoctorsEditVisitsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTakeDoctorsEditVisitsSubscription({
 *   variables: {
 *      date: // value for 'date'
 *      doctor_id: // value for 'doctor_id'
 *   },
 * });
 */
export function useTakeDoctorsEditVisitsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<TakeDoctorsEditVisitsSubscription, TakeDoctorsEditVisitsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TakeDoctorsEditVisitsSubscription, TakeDoctorsEditVisitsSubscriptionVariables>(TakeDoctorsEditVisitsDocument, options);
      }
export type TakeDoctorsEditVisitsSubscriptionHookResult = ReturnType<typeof useTakeDoctorsEditVisitsSubscription>;
export type TakeDoctorsEditVisitsSubscriptionResult = Apollo.SubscriptionResult<TakeDoctorsEditVisitsSubscription>;
export const SaveEditVisitDocument = gql`
    mutation SaveEditVisit($id: Int = 10, $patient_card: jsonb = "") {
  update_Visit(where: {id: {_eq: $id}}, _set: {patient_card: $patient_card}) {
    returning {
      id
    }
  }
}
    `;
export type SaveEditVisitMutationFn = Apollo.MutationFunction<SaveEditVisitMutation, SaveEditVisitMutationVariables>;

/**
 * __useSaveEditVisitMutation__
 *
 * To run a mutation, you first call `useSaveEditVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEditVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEditVisitMutation, { data, loading, error }] = useSaveEditVisitMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patient_card: // value for 'patient_card'
 *   },
 * });
 */
export function useSaveEditVisitMutation(baseOptions?: Apollo.MutationHookOptions<SaveEditVisitMutation, SaveEditVisitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveEditVisitMutation, SaveEditVisitMutationVariables>(SaveEditVisitDocument, options);
      }
export type SaveEditVisitMutationHookResult = ReturnType<typeof useSaveEditVisitMutation>;
export type SaveEditVisitMutationResult = Apollo.MutationResult<SaveEditVisitMutation>;
export type SaveEditVisitMutationOptions = Apollo.BaseMutationOptions<SaveEditVisitMutation, SaveEditVisitMutationVariables>;
export const SaveVisitReceptionDocument = gql`
    mutation SaveVisitReception($id: Int = 0, $internal_recomendation: String = "", $patient_recomendation: String = "", $services: [ReceptionServices_insert_input!] = {}, $medicines: [ReceptionMedicines_insert_input!] = {}) {
  update_Reception(
    where: {id: {_eq: $id}}
    _set: {internal_recomendation: $internal_recomendation, patient_recomendation: $patient_recomendation}
  ) {
    returning {
      id
    }
  }
  insert_ReceptionServices(objects: $services) {
    returning {
      id
    }
  }
  insert_ReceptionMedicines(objects: $medicines) {
    returning {
      id
    }
  }
}
    `;
export type SaveVisitReceptionMutationFn = Apollo.MutationFunction<SaveVisitReceptionMutation, SaveVisitReceptionMutationVariables>;

/**
 * __useSaveVisitReceptionMutation__
 *
 * To run a mutation, you first call `useSaveVisitReceptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveVisitReceptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveVisitReceptionMutation, { data, loading, error }] = useSaveVisitReceptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      internal_recomendation: // value for 'internal_recomendation'
 *      patient_recomendation: // value for 'patient_recomendation'
 *      services: // value for 'services'
 *      medicines: // value for 'medicines'
 *   },
 * });
 */
export function useSaveVisitReceptionMutation(baseOptions?: Apollo.MutationHookOptions<SaveVisitReceptionMutation, SaveVisitReceptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveVisitReceptionMutation, SaveVisitReceptionMutationVariables>(SaveVisitReceptionDocument, options);
      }
export type SaveVisitReceptionMutationHookResult = ReturnType<typeof useSaveVisitReceptionMutation>;
export type SaveVisitReceptionMutationResult = Apollo.MutationResult<SaveVisitReceptionMutation>;
export type SaveVisitReceptionMutationOptions = Apollo.BaseMutationOptions<SaveVisitReceptionMutation, SaveVisitReceptionMutationVariables>;
export const GetClientForEditDocument = gql`
    query GetClientForEdit($id: Int = 0) {
  Client(where: {id: {_eq: $id}}) {
    phone_number
    id
    address
    email
    name
    passport
    passport_when
    passport_where
    serial
  }
}
    `;

/**
 * __useGetClientForEditQuery__
 *
 * To run a query within a React component, call `useGetClientForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientForEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientForEditQuery(baseOptions?: Apollo.QueryHookOptions<GetClientForEditQuery, GetClientForEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientForEditQuery, GetClientForEditQueryVariables>(GetClientForEditDocument, options);
      }
export function useGetClientForEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientForEditQuery, GetClientForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientForEditQuery, GetClientForEditQueryVariables>(GetClientForEditDocument, options);
        }
export type GetClientForEditQueryHookResult = ReturnType<typeof useGetClientForEditQuery>;
export type GetClientForEditLazyQueryHookResult = ReturnType<typeof useGetClientForEditLazyQuery>;
export type GetClientForEditQueryResult = Apollo.QueryResult<GetClientForEditQuery, GetClientForEditQueryVariables>;
export const GetPetForEditDocument = gql`
    query GetPetForEdit($id: Int = 0) {
  Pet(where: {id: {_eq: $id}}) {
    id
    name
    birthdate
    breed
    chip_number
    client_id
    sex
    type_id
    PetType {
      name
    }
    Client {
      name
    }
  }
}
    `;

/**
 * __useGetPetForEditQuery__
 *
 * To run a query within a React component, call `useGetPetForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPetForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPetForEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPetForEditQuery(baseOptions?: Apollo.QueryHookOptions<GetPetForEditQuery, GetPetForEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPetForEditQuery, GetPetForEditQueryVariables>(GetPetForEditDocument, options);
      }
export function useGetPetForEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPetForEditQuery, GetPetForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPetForEditQuery, GetPetForEditQueryVariables>(GetPetForEditDocument, options);
        }
export type GetPetForEditQueryHookResult = ReturnType<typeof useGetPetForEditQuery>;
export type GetPetForEditLazyQueryHookResult = ReturnType<typeof useGetPetForEditLazyQuery>;
export type GetPetForEditQueryResult = Apollo.QueryResult<GetPetForEditQuery, GetPetForEditQueryVariables>;
export const GetSchemasWithCategoriesDocument = gql`
    subscription GetSchemasWithCategories {
  SchemaCategory(where: {parent_id: {_is_null: true}}, order_by: {name: asc}) {
    id
    name
    Childs(order_by: {name: asc}) {
      id
      name
    }
  }
}
    `;

/**
 * __useGetSchemasWithCategoriesSubscription__
 *
 * To run a query within a React component, call `useGetSchemasWithCategoriesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetSchemasWithCategoriesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchemasWithCategoriesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetSchemasWithCategoriesSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetSchemasWithCategoriesSubscription, GetSchemasWithCategoriesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetSchemasWithCategoriesSubscription, GetSchemasWithCategoriesSubscriptionVariables>(GetSchemasWithCategoriesDocument, options);
      }
export type GetSchemasWithCategoriesSubscriptionHookResult = ReturnType<typeof useGetSchemasWithCategoriesSubscription>;
export type GetSchemasWithCategoriesSubscriptionResult = Apollo.SubscriptionResult<GetSchemasWithCategoriesSubscription>;
export const AddSchemaCategoryDocument = gql`
    mutation AddSchemaCategory($name: String = "", $parent_id: Int = null) {
  insert_SchemaCategory(objects: {name: $name, parent_id: $parent_id}) {
    returning {
      id
    }
  }
}
    `;
export type AddSchemaCategoryMutationFn = Apollo.MutationFunction<AddSchemaCategoryMutation, AddSchemaCategoryMutationVariables>;

/**
 * __useAddSchemaCategoryMutation__
 *
 * To run a mutation, you first call `useAddSchemaCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSchemaCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSchemaCategoryMutation, { data, loading, error }] = useAddSchemaCategoryMutation({
 *   variables: {
 *      name: // value for 'name'
 *      parent_id: // value for 'parent_id'
 *   },
 * });
 */
export function useAddSchemaCategoryMutation(baseOptions?: Apollo.MutationHookOptions<AddSchemaCategoryMutation, AddSchemaCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSchemaCategoryMutation, AddSchemaCategoryMutationVariables>(AddSchemaCategoryDocument, options);
      }
export type AddSchemaCategoryMutationHookResult = ReturnType<typeof useAddSchemaCategoryMutation>;
export type AddSchemaCategoryMutationResult = Apollo.MutationResult<AddSchemaCategoryMutation>;
export type AddSchemaCategoryMutationOptions = Apollo.BaseMutationOptions<AddSchemaCategoryMutation, AddSchemaCategoryMutationVariables>;
export const UpdateSchemaCategoryDocument = gql`
    mutation UpdateSchemaCategory($id: Int = 0, $name: String = "") {
  update_SchemaCategory(where: {id: {_eq: $id}}, _set: {name: $name}) {
    returning {
      id
    }
  }
}
    `;
export type UpdateSchemaCategoryMutationFn = Apollo.MutationFunction<UpdateSchemaCategoryMutation, UpdateSchemaCategoryMutationVariables>;

/**
 * __useUpdateSchemaCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateSchemaCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchemaCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchemaCategoryMutation, { data, loading, error }] = useUpdateSchemaCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateSchemaCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSchemaCategoryMutation, UpdateSchemaCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSchemaCategoryMutation, UpdateSchemaCategoryMutationVariables>(UpdateSchemaCategoryDocument, options);
      }
export type UpdateSchemaCategoryMutationHookResult = ReturnType<typeof useUpdateSchemaCategoryMutation>;
export type UpdateSchemaCategoryMutationResult = Apollo.MutationResult<UpdateSchemaCategoryMutation>;
export type UpdateSchemaCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateSchemaCategoryMutation, UpdateSchemaCategoryMutationVariables>;
export const DeleteSchemaCategoryDocument = gql`
    mutation DeleteSchemaCategory($id: Int = 0) {
  delete_SchemaCategory(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteSchemaCategoryMutationFn = Apollo.MutationFunction<DeleteSchemaCategoryMutation, DeleteSchemaCategoryMutationVariables>;

/**
 * __useDeleteSchemaCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteSchemaCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSchemaCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSchemaCategoryMutation, { data, loading, error }] = useDeleteSchemaCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSchemaCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSchemaCategoryMutation, DeleteSchemaCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSchemaCategoryMutation, DeleteSchemaCategoryMutationVariables>(DeleteSchemaCategoryDocument, options);
      }
export type DeleteSchemaCategoryMutationHookResult = ReturnType<typeof useDeleteSchemaCategoryMutation>;
export type DeleteSchemaCategoryMutationResult = Apollo.MutationResult<DeleteSchemaCategoryMutation>;
export type DeleteSchemaCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteSchemaCategoryMutation, DeleteSchemaCategoryMutationVariables>;
export const GetSchemasForCategoryDocument = gql`
    subscription GetSchemasForCategory($ids: [Int!] = 0, $order_by: [Schema_order_by!] = {}) {
  Schema(where: {category_id: {_in: $ids}}, order_by: $order_by) {
    id
    category_id
    name
    SchemaMedicines {
      id
      schema_id
      medicine_id
      value
      Medicine {
        name
        price
        id
        ut_id
      }
    }
    SchemaServices {
      id
      schema_id
      price
      service_id
      value
      Service {
        name
        price
        id
        ut_id
      }
    }
  }
}
    `;

/**
 * __useGetSchemasForCategorySubscription__
 *
 * To run a query within a React component, call `useGetSchemasForCategorySubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetSchemasForCategorySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchemasForCategorySubscription({
 *   variables: {
 *      ids: // value for 'ids'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetSchemasForCategorySubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetSchemasForCategorySubscription, GetSchemasForCategorySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetSchemasForCategorySubscription, GetSchemasForCategorySubscriptionVariables>(GetSchemasForCategoryDocument, options);
      }
export type GetSchemasForCategorySubscriptionHookResult = ReturnType<typeof useGetSchemasForCategorySubscription>;
export type GetSchemasForCategorySubscriptionResult = Apollo.SubscriptionResult<GetSchemasForCategorySubscription>;
export const GetSchemasForCategoryCountDocument = gql`
    subscription GetSchemasForCategoryCount($ids: [Int!] = 0, $order_by: [Schema_order_by!] = {}) {
  Schema_aggregate(where: {category_id: {_in: $ids}}, order_by: $order_by) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetSchemasForCategoryCountSubscription__
 *
 * To run a query within a React component, call `useGetSchemasForCategoryCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetSchemasForCategoryCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchemasForCategoryCountSubscription({
 *   variables: {
 *      ids: // value for 'ids'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetSchemasForCategoryCountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetSchemasForCategoryCountSubscription, GetSchemasForCategoryCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetSchemasForCategoryCountSubscription, GetSchemasForCategoryCountSubscriptionVariables>(GetSchemasForCategoryCountDocument, options);
      }
export type GetSchemasForCategoryCountSubscriptionHookResult = ReturnType<typeof useGetSchemasForCategoryCountSubscription>;
export type GetSchemasForCategoryCountSubscriptionResult = Apollo.SubscriptionResult<GetSchemasForCategoryCountSubscription>;
export const AddSchemaDocument = gql`
    mutation AddSchema($name: String = "", $category_id: Int = 0) {
  insert_Schema(objects: {name: $name, category_id: $category_id}) {
    returning {
      id
      name
      category_id
    }
  }
}
    `;
export type AddSchemaMutationFn = Apollo.MutationFunction<AddSchemaMutation, AddSchemaMutationVariables>;

/**
 * __useAddSchemaMutation__
 *
 * To run a mutation, you first call `useAddSchemaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSchemaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSchemaMutation, { data, loading, error }] = useAddSchemaMutation({
 *   variables: {
 *      name: // value for 'name'
 *      category_id: // value for 'category_id'
 *   },
 * });
 */
export function useAddSchemaMutation(baseOptions?: Apollo.MutationHookOptions<AddSchemaMutation, AddSchemaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSchemaMutation, AddSchemaMutationVariables>(AddSchemaDocument, options);
      }
export type AddSchemaMutationHookResult = ReturnType<typeof useAddSchemaMutation>;
export type AddSchemaMutationResult = Apollo.MutationResult<AddSchemaMutation>;
export type AddSchemaMutationOptions = Apollo.BaseMutationOptions<AddSchemaMutation, AddSchemaMutationVariables>;
export const UpdateSchemaDocument = gql`
    mutation UpdateSchema($id: Int = 0, $name: String = "") {
  update_Schema(where: {id: {_eq: $id}}, _set: {name: $name}) {
    returning {
      id
    }
  }
}
    `;
export type UpdateSchemaMutationFn = Apollo.MutationFunction<UpdateSchemaMutation, UpdateSchemaMutationVariables>;

/**
 * __useUpdateSchemaMutation__
 *
 * To run a mutation, you first call `useUpdateSchemaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchemaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchemaMutation, { data, loading, error }] = useUpdateSchemaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateSchemaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSchemaMutation, UpdateSchemaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSchemaMutation, UpdateSchemaMutationVariables>(UpdateSchemaDocument, options);
      }
export type UpdateSchemaMutationHookResult = ReturnType<typeof useUpdateSchemaMutation>;
export type UpdateSchemaMutationResult = Apollo.MutationResult<UpdateSchemaMutation>;
export type UpdateSchemaMutationOptions = Apollo.BaseMutationOptions<UpdateSchemaMutation, UpdateSchemaMutationVariables>;
export const DeleteSchemaDocument = gql`
    mutation DeleteSchema($id: Int = 0) {
  delete_Schema(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteSchemaMutationFn = Apollo.MutationFunction<DeleteSchemaMutation, DeleteSchemaMutationVariables>;

/**
 * __useDeleteSchemaMutation__
 *
 * To run a mutation, you first call `useDeleteSchemaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSchemaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSchemaMutation, { data, loading, error }] = useDeleteSchemaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSchemaMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSchemaMutation, DeleteSchemaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSchemaMutation, DeleteSchemaMutationVariables>(DeleteSchemaDocument, options);
      }
export type DeleteSchemaMutationHookResult = ReturnType<typeof useDeleteSchemaMutation>;
export type DeleteSchemaMutationResult = Apollo.MutationResult<DeleteSchemaMutation>;
export type DeleteSchemaMutationOptions = Apollo.BaseMutationOptions<DeleteSchemaMutation, DeleteSchemaMutationVariables>;
export const DeleteSchemasByIdsDocument = gql`
    mutation DeleteSchemasByIds($ids: [Int!] = 0) {
  delete_Schema(where: {id: {_in: $ids}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteSchemasByIdsMutationFn = Apollo.MutationFunction<DeleteSchemasByIdsMutation, DeleteSchemasByIdsMutationVariables>;

/**
 * __useDeleteSchemasByIdsMutation__
 *
 * To run a mutation, you first call `useDeleteSchemasByIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSchemasByIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSchemasByIdsMutation, { data, loading, error }] = useDeleteSchemasByIdsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteSchemasByIdsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSchemasByIdsMutation, DeleteSchemasByIdsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSchemasByIdsMutation, DeleteSchemasByIdsMutationVariables>(DeleteSchemasByIdsDocument, options);
      }
export type DeleteSchemasByIdsMutationHookResult = ReturnType<typeof useDeleteSchemasByIdsMutation>;
export type DeleteSchemasByIdsMutationResult = Apollo.MutationResult<DeleteSchemasByIdsMutation>;
export type DeleteSchemasByIdsMutationOptions = Apollo.BaseMutationOptions<DeleteSchemasByIdsMutation, DeleteSchemasByIdsMutationVariables>;
export const MedicineTypeFkDocument = gql`
    subscription MedicineTypeFk($limit: Int = 10, $searchText: String = "%%") {
  Medicine(where: {name: {_ilike: $searchText}}, limit: $limit) {
    id
    name
    price
    ut_id
  }
}
    `;

/**
 * __useMedicineTypeFkSubscription__
 *
 * To run a query within a React component, call `useMedicineTypeFkSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMedicineTypeFkSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicineTypeFkSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useMedicineTypeFkSubscription(baseOptions?: Apollo.SubscriptionHookOptions<MedicineTypeFkSubscription, MedicineTypeFkSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<MedicineTypeFkSubscription, MedicineTypeFkSubscriptionVariables>(MedicineTypeFkDocument, options);
      }
export type MedicineTypeFkSubscriptionHookResult = ReturnType<typeof useMedicineTypeFkSubscription>;
export type MedicineTypeFkSubscriptionResult = Apollo.SubscriptionResult<MedicineTypeFkSubscription>;
export const MedicineTypeModelFkDocument = gql`
    query MedicineTypeModelFk($id: Int = 0) {
  Medicine_by_pk(id: $id) {
    id
    name
    price
    ut_id
  }
}
    `;

/**
 * __useMedicineTypeModelFkQuery__
 *
 * To run a query within a React component, call `useMedicineTypeModelFkQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicineTypeModelFkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicineTypeModelFkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMedicineTypeModelFkQuery(baseOptions?: Apollo.QueryHookOptions<MedicineTypeModelFkQuery, MedicineTypeModelFkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MedicineTypeModelFkQuery, MedicineTypeModelFkQueryVariables>(MedicineTypeModelFkDocument, options);
      }
export function useMedicineTypeModelFkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MedicineTypeModelFkQuery, MedicineTypeModelFkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MedicineTypeModelFkQuery, MedicineTypeModelFkQueryVariables>(MedicineTypeModelFkDocument, options);
        }
export type MedicineTypeModelFkQueryHookResult = ReturnType<typeof useMedicineTypeModelFkQuery>;
export type MedicineTypeModelFkLazyQueryHookResult = ReturnType<typeof useMedicineTypeModelFkLazyQuery>;
export type MedicineTypeModelFkQueryResult = Apollo.QueryResult<MedicineTypeModelFkQuery, MedicineTypeModelFkQueryVariables>;
export const UnitTypeFkDocument = gql`
    subscription UnitTypeFk($limit: Int = 10, $searchText: String = "%%") {
  MedicineUnit(where: {name: {_ilike: $searchText}}, limit: $limit) {
    id
    name
  }
}
    `;

/**
 * __useUnitTypeFkSubscription__
 *
 * To run a query within a React component, call `useUnitTypeFkSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUnitTypeFkSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitTypeFkSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useUnitTypeFkSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UnitTypeFkSubscription, UnitTypeFkSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UnitTypeFkSubscription, UnitTypeFkSubscriptionVariables>(UnitTypeFkDocument, options);
      }
export type UnitTypeFkSubscriptionHookResult = ReturnType<typeof useUnitTypeFkSubscription>;
export type UnitTypeFkSubscriptionResult = Apollo.SubscriptionResult<UnitTypeFkSubscription>;
export const ServiceTypeFkDocument = gql`
    subscription ServiceTypeFk($limit: Int = 10, $searchText: String = "%%") {
  Service(where: {name: {_ilike: $searchText}}, limit: $limit) {
    id
    name
    price
    ut_id
  }
}
    `;

/**
 * __useServiceTypeFkSubscription__
 *
 * To run a query within a React component, call `useServiceTypeFkSubscription` and pass it any options that fit your needs.
 * When your component renders, `useServiceTypeFkSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceTypeFkSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useServiceTypeFkSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ServiceTypeFkSubscription, ServiceTypeFkSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ServiceTypeFkSubscription, ServiceTypeFkSubscriptionVariables>(ServiceTypeFkDocument, options);
      }
export type ServiceTypeFkSubscriptionHookResult = ReturnType<typeof useServiceTypeFkSubscription>;
export type ServiceTypeFkSubscriptionResult = Apollo.SubscriptionResult<ServiceTypeFkSubscription>;
export const ServiceTypeModelFkDocument = gql`
    query ServiceTypeModelFk($id: Int = 0) {
  Service_by_pk(id: $id) {
    id
    name
    price
    ut_id
  }
}
    `;

/**
 * __useServiceTypeModelFkQuery__
 *
 * To run a query within a React component, call `useServiceTypeModelFkQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceTypeModelFkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceTypeModelFkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceTypeModelFkQuery(baseOptions?: Apollo.QueryHookOptions<ServiceTypeModelFkQuery, ServiceTypeModelFkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceTypeModelFkQuery, ServiceTypeModelFkQueryVariables>(ServiceTypeModelFkDocument, options);
      }
export function useServiceTypeModelFkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceTypeModelFkQuery, ServiceTypeModelFkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceTypeModelFkQuery, ServiceTypeModelFkQueryVariables>(ServiceTypeModelFkDocument, options);
        }
export type ServiceTypeModelFkQueryHookResult = ReturnType<typeof useServiceTypeModelFkQuery>;
export type ServiceTypeModelFkLazyQueryHookResult = ReturnType<typeof useServiceTypeModelFkLazyQuery>;
export type ServiceTypeModelFkQueryResult = Apollo.QueryResult<ServiceTypeModelFkQuery, ServiceTypeModelFkQueryVariables>;
export const DeleteSchemaMedicineDocument = gql`
    mutation DeleteSchemaMedicine($id: Int = 0) {
  delete_SchemaMedicine(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteSchemaMedicineMutationFn = Apollo.MutationFunction<DeleteSchemaMedicineMutation, DeleteSchemaMedicineMutationVariables>;

/**
 * __useDeleteSchemaMedicineMutation__
 *
 * To run a mutation, you first call `useDeleteSchemaMedicineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSchemaMedicineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSchemaMedicineMutation, { data, loading, error }] = useDeleteSchemaMedicineMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSchemaMedicineMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSchemaMedicineMutation, DeleteSchemaMedicineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSchemaMedicineMutation, DeleteSchemaMedicineMutationVariables>(DeleteSchemaMedicineDocument, options);
      }
export type DeleteSchemaMedicineMutationHookResult = ReturnType<typeof useDeleteSchemaMedicineMutation>;
export type DeleteSchemaMedicineMutationResult = Apollo.MutationResult<DeleteSchemaMedicineMutation>;
export type DeleteSchemaMedicineMutationOptions = Apollo.BaseMutationOptions<DeleteSchemaMedicineMutation, DeleteSchemaMedicineMutationVariables>;
export const UpdateSchemaMedicineDocument = gql`
    mutation UpdateSchemaMedicine($id: Int = 0, $medicine_id: Int = 0, $value: float8 = "") {
  update_SchemaMedicine(
    where: {id: {_eq: $id}}
    _set: {medicine_id: $medicine_id, value: $value}
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpdateSchemaMedicineMutationFn = Apollo.MutationFunction<UpdateSchemaMedicineMutation, UpdateSchemaMedicineMutationVariables>;

/**
 * __useUpdateSchemaMedicineMutation__
 *
 * To run a mutation, you first call `useUpdateSchemaMedicineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchemaMedicineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchemaMedicineMutation, { data, loading, error }] = useUpdateSchemaMedicineMutation({
 *   variables: {
 *      id: // value for 'id'
 *      medicine_id: // value for 'medicine_id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateSchemaMedicineMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSchemaMedicineMutation, UpdateSchemaMedicineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSchemaMedicineMutation, UpdateSchemaMedicineMutationVariables>(UpdateSchemaMedicineDocument, options);
      }
export type UpdateSchemaMedicineMutationHookResult = ReturnType<typeof useUpdateSchemaMedicineMutation>;
export type UpdateSchemaMedicineMutationResult = Apollo.MutationResult<UpdateSchemaMedicineMutation>;
export type UpdateSchemaMedicineMutationOptions = Apollo.BaseMutationOptions<UpdateSchemaMedicineMutation, UpdateSchemaMedicineMutationVariables>;
export const AddSchemaMedicinesDocument = gql`
    mutation AddSchemaMedicines($objects: [SchemaMedicine_insert_input!] = {}) {
  insert_SchemaMedicine(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type AddSchemaMedicinesMutationFn = Apollo.MutationFunction<AddSchemaMedicinesMutation, AddSchemaMedicinesMutationVariables>;

/**
 * __useAddSchemaMedicinesMutation__
 *
 * To run a mutation, you first call `useAddSchemaMedicinesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSchemaMedicinesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSchemaMedicinesMutation, { data, loading, error }] = useAddSchemaMedicinesMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddSchemaMedicinesMutation(baseOptions?: Apollo.MutationHookOptions<AddSchemaMedicinesMutation, AddSchemaMedicinesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSchemaMedicinesMutation, AddSchemaMedicinesMutationVariables>(AddSchemaMedicinesDocument, options);
      }
export type AddSchemaMedicinesMutationHookResult = ReturnType<typeof useAddSchemaMedicinesMutation>;
export type AddSchemaMedicinesMutationResult = Apollo.MutationResult<AddSchemaMedicinesMutation>;
export type AddSchemaMedicinesMutationOptions = Apollo.BaseMutationOptions<AddSchemaMedicinesMutation, AddSchemaMedicinesMutationVariables>;
export const DeleteSchemaServiceDocument = gql`
    mutation DeleteSchemaService($id: Int = 0) {
  delete_SchemaService(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteSchemaServiceMutationFn = Apollo.MutationFunction<DeleteSchemaServiceMutation, DeleteSchemaServiceMutationVariables>;

/**
 * __useDeleteSchemaServiceMutation__
 *
 * To run a mutation, you first call `useDeleteSchemaServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSchemaServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSchemaServiceMutation, { data, loading, error }] = useDeleteSchemaServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSchemaServiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSchemaServiceMutation, DeleteSchemaServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSchemaServiceMutation, DeleteSchemaServiceMutationVariables>(DeleteSchemaServiceDocument, options);
      }
export type DeleteSchemaServiceMutationHookResult = ReturnType<typeof useDeleteSchemaServiceMutation>;
export type DeleteSchemaServiceMutationResult = Apollo.MutationResult<DeleteSchemaServiceMutation>;
export type DeleteSchemaServiceMutationOptions = Apollo.BaseMutationOptions<DeleteSchemaServiceMutation, DeleteSchemaServiceMutationVariables>;
export const UpdateSchemaServiceDocument = gql`
    mutation UpdateSchemaService($id: Int = 0, $service_id: Int = 0, $price: float8 = "", $value: float8 = "") {
  update_SchemaService(
    where: {id: {_eq: $id}}
    _set: {service_id: $service_id, value: $value, price: $price}
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpdateSchemaServiceMutationFn = Apollo.MutationFunction<UpdateSchemaServiceMutation, UpdateSchemaServiceMutationVariables>;

/**
 * __useUpdateSchemaServiceMutation__
 *
 * To run a mutation, you first call `useUpdateSchemaServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchemaServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchemaServiceMutation, { data, loading, error }] = useUpdateSchemaServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      service_id: // value for 'service_id'
 *      price: // value for 'price'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateSchemaServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSchemaServiceMutation, UpdateSchemaServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSchemaServiceMutation, UpdateSchemaServiceMutationVariables>(UpdateSchemaServiceDocument, options);
      }
export type UpdateSchemaServiceMutationHookResult = ReturnType<typeof useUpdateSchemaServiceMutation>;
export type UpdateSchemaServiceMutationResult = Apollo.MutationResult<UpdateSchemaServiceMutation>;
export type UpdateSchemaServiceMutationOptions = Apollo.BaseMutationOptions<UpdateSchemaServiceMutation, UpdateSchemaServiceMutationVariables>;
export const AddSchemaServicesDocument = gql`
    mutation AddSchemaServices($objects: [SchemaService_insert_input!] = {}) {
  insert_SchemaService(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type AddSchemaServicesMutationFn = Apollo.MutationFunction<AddSchemaServicesMutation, AddSchemaServicesMutationVariables>;

/**
 * __useAddSchemaServicesMutation__
 *
 * To run a mutation, you first call `useAddSchemaServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSchemaServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSchemaServicesMutation, { data, loading, error }] = useAddSchemaServicesMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddSchemaServicesMutation(baseOptions?: Apollo.MutationHookOptions<AddSchemaServicesMutation, AddSchemaServicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSchemaServicesMutation, AddSchemaServicesMutationVariables>(AddSchemaServicesDocument, options);
      }
export type AddSchemaServicesMutationHookResult = ReturnType<typeof useAddSchemaServicesMutation>;
export type AddSchemaServicesMutationResult = Apollo.MutationResult<AddSchemaServicesMutation>;
export type AddSchemaServicesMutationOptions = Apollo.BaseMutationOptions<AddSchemaServicesMutation, AddSchemaServicesMutationVariables>;
export const ServicesIndexDocument = gql`
    subscription ServicesIndex($order_by: [Service_order_by!] = {}, $where: Service_bool_exp = {}, $offset: Int = 0, $limit: Int = 0) {
  Service(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    id
    name
    price
    ut_id
  }
}
    `;

/**
 * __useServicesIndexSubscription__
 *
 * To run a query within a React component, call `useServicesIndexSubscription` and pass it any options that fit your needs.
 * When your component renders, `useServicesIndexSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicesIndexSubscription({
 *   variables: {
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useServicesIndexSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ServicesIndexSubscription, ServicesIndexSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ServicesIndexSubscription, ServicesIndexSubscriptionVariables>(ServicesIndexDocument, options);
      }
export type ServicesIndexSubscriptionHookResult = ReturnType<typeof useServicesIndexSubscription>;
export type ServicesIndexSubscriptionResult = Apollo.SubscriptionResult<ServicesIndexSubscription>;
export const ServiceIndexCountDocument = gql`
    subscription ServiceIndexCount($where: Service_bool_exp = {}) {
  Service_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useServiceIndexCountSubscription__
 *
 * To run a query within a React component, call `useServiceIndexCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useServiceIndexCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceIndexCountSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useServiceIndexCountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ServiceIndexCountSubscription, ServiceIndexCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ServiceIndexCountSubscription, ServiceIndexCountSubscriptionVariables>(ServiceIndexCountDocument, options);
      }
export type ServiceIndexCountSubscriptionHookResult = ReturnType<typeof useServiceIndexCountSubscription>;
export type ServiceIndexCountSubscriptionResult = Apollo.SubscriptionResult<ServiceIndexCountSubscription>;
export const AddServiceDocument = gql`
    mutation AddService($name: String = "", $price: float8 = "", $ut_id: String = "") {
  insert_Service(objects: {name: $name, price: $price, ut_id: $ut_id}) {
    returning {
      id
    }
  }
}
    `;
export type AddServiceMutationFn = Apollo.MutationFunction<AddServiceMutation, AddServiceMutationVariables>;

/**
 * __useAddServiceMutation__
 *
 * To run a mutation, you first call `useAddServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addServiceMutation, { data, loading, error }] = useAddServiceMutation({
 *   variables: {
 *      name: // value for 'name'
 *      price: // value for 'price'
 *      ut_id: // value for 'ut_id'
 *   },
 * });
 */
export function useAddServiceMutation(baseOptions?: Apollo.MutationHookOptions<AddServiceMutation, AddServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddServiceMutation, AddServiceMutationVariables>(AddServiceDocument, options);
      }
export type AddServiceMutationHookResult = ReturnType<typeof useAddServiceMutation>;
export type AddServiceMutationResult = Apollo.MutationResult<AddServiceMutation>;
export type AddServiceMutationOptions = Apollo.BaseMutationOptions<AddServiceMutation, AddServiceMutationVariables>;
export const UpdateServiceDocument = gql`
    mutation UpdateService($name: String = "", $id: Int = 10, $price: float8 = "", $ut_id: String = "") {
  update_Service(
    where: {id: {_eq: $id}}
    _set: {name: $name, price: $price, ut_id: $ut_id}
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpdateServiceMutationFn = Apollo.MutationFunction<UpdateServiceMutation, UpdateServiceMutationVariables>;

/**
 * __useUpdateServiceMutation__
 *
 * To run a mutation, you first call `useUpdateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceMutation, { data, loading, error }] = useUpdateServiceMutation({
 *   variables: {
 *      name: // value for 'name'
 *      id: // value for 'id'
 *      price: // value for 'price'
 *      ut_id: // value for 'ut_id'
 *   },
 * });
 */
export function useUpdateServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceMutation, UpdateServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceMutation, UpdateServiceMutationVariables>(UpdateServiceDocument, options);
      }
export type UpdateServiceMutationHookResult = ReturnType<typeof useUpdateServiceMutation>;
export type UpdateServiceMutationResult = Apollo.MutationResult<UpdateServiceMutation>;
export type UpdateServiceMutationOptions = Apollo.BaseMutationOptions<UpdateServiceMutation, UpdateServiceMutationVariables>;
export const DeleteServiceDocument = gql`
    mutation DeleteService($id: Int = 10) {
  delete_Service(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteServiceMutationFn = Apollo.MutationFunction<DeleteServiceMutation, DeleteServiceMutationVariables>;

/**
 * __useDeleteServiceMutation__
 *
 * To run a mutation, you first call `useDeleteServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceMutation, { data, loading, error }] = useDeleteServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteServiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteServiceMutation, DeleteServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteServiceMutation, DeleteServiceMutationVariables>(DeleteServiceDocument, options);
      }
export type DeleteServiceMutationHookResult = ReturnType<typeof useDeleteServiceMutation>;
export type DeleteServiceMutationResult = Apollo.MutationResult<DeleteServiceMutation>;
export type DeleteServiceMutationOptions = Apollo.BaseMutationOptions<DeleteServiceMutation, DeleteServiceMutationVariables>;
export const DeleteServicesByIdsDocument = gql`
    mutation DeleteServicesByIds($ids: [Int!] = 10) {
  delete_Service(where: {id: {_in: $ids}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteServicesByIdsMutationFn = Apollo.MutationFunction<DeleteServicesByIdsMutation, DeleteServicesByIdsMutationVariables>;

/**
 * __useDeleteServicesByIdsMutation__
 *
 * To run a mutation, you first call `useDeleteServicesByIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServicesByIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServicesByIdsMutation, { data, loading, error }] = useDeleteServicesByIdsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteServicesByIdsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteServicesByIdsMutation, DeleteServicesByIdsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteServicesByIdsMutation, DeleteServicesByIdsMutationVariables>(DeleteServicesByIdsDocument, options);
      }
export type DeleteServicesByIdsMutationHookResult = ReturnType<typeof useDeleteServicesByIdsMutation>;
export type DeleteServicesByIdsMutationResult = Apollo.MutationResult<DeleteServicesByIdsMutation>;
export type DeleteServicesByIdsMutationOptions = Apollo.BaseMutationOptions<DeleteServicesByIdsMutation, DeleteServicesByIdsMutationVariables>;
export const GetTemplatesByUserDocument = gql`
    subscription GetTemplatesByUser($limit: Int = 10, $offset: Int = 0, $order_by: [Template_order_by!] = {}, $user_id: uuid = "") {
  Template(
    limit: $limit
    offset: $offset
    order_by: $order_by
    where: {user_id: {_eq: $user_id}}
  ) {
    id
    name
    internal_recomendation
    patient_recomendation
    TemplateMedicines {
      id
      medicine_id
      value
      Medicine {
        name
        price
        ut_id
        id
      }
      template_id
    }
    TemplateServices {
      id
      price
      service_id
      template_id
      value
      Service {
        name
        price
        id
        ut_id
      }
    }
  }
}
    `;

/**
 * __useGetTemplatesByUserSubscription__
 *
 * To run a query within a React component, call `useGetTemplatesByUserSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplatesByUserSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplatesByUserSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetTemplatesByUserSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetTemplatesByUserSubscription, GetTemplatesByUserSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetTemplatesByUserSubscription, GetTemplatesByUserSubscriptionVariables>(GetTemplatesByUserDocument, options);
      }
export type GetTemplatesByUserSubscriptionHookResult = ReturnType<typeof useGetTemplatesByUserSubscription>;
export type GetTemplatesByUserSubscriptionResult = Apollo.SubscriptionResult<GetTemplatesByUserSubscription>;
export const GetTemplatesByUserCountDocument = gql`
    subscription GetTemplatesByUserCount($user_id: uuid = "") {
  Template_aggregate(where: {user_id: {_eq: $user_id}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetTemplatesByUserCountSubscription__
 *
 * To run a query within a React component, call `useGetTemplatesByUserCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplatesByUserCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplatesByUserCountSubscription({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetTemplatesByUserCountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetTemplatesByUserCountSubscription, GetTemplatesByUserCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetTemplatesByUserCountSubscription, GetTemplatesByUserCountSubscriptionVariables>(GetTemplatesByUserCountDocument, options);
      }
export type GetTemplatesByUserCountSubscriptionHookResult = ReturnType<typeof useGetTemplatesByUserCountSubscription>;
export type GetTemplatesByUserCountSubscriptionResult = Apollo.SubscriptionResult<GetTemplatesByUserCountSubscription>;
export const AddTemplateDocument = gql`
    mutation AddTemplate($name: String = "", $internal_recomendation: String = "", $patient_recomendation: String = "", $user_id: uuid = "") {
  insert_Template(
    objects: {name: $name, internal_recomendation: $internal_recomendation, patient_recomendation: $patient_recomendation, user_id: $user_id}
  ) {
    returning {
      id
    }
  }
}
    `;
export type AddTemplateMutationFn = Apollo.MutationFunction<AddTemplateMutation, AddTemplateMutationVariables>;

/**
 * __useAddTemplateMutation__
 *
 * To run a mutation, you first call `useAddTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTemplateMutation, { data, loading, error }] = useAddTemplateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      internal_recomendation: // value for 'internal_recomendation'
 *      patient_recomendation: // value for 'patient_recomendation'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useAddTemplateMutation(baseOptions?: Apollo.MutationHookOptions<AddTemplateMutation, AddTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTemplateMutation, AddTemplateMutationVariables>(AddTemplateDocument, options);
      }
export type AddTemplateMutationHookResult = ReturnType<typeof useAddTemplateMutation>;
export type AddTemplateMutationResult = Apollo.MutationResult<AddTemplateMutation>;
export type AddTemplateMutationOptions = Apollo.BaseMutationOptions<AddTemplateMutation, AddTemplateMutationVariables>;
export const UpdateTemplateDocument = gql`
    mutation UpdateTemplate($id: Int = 0, $internal_recomendation: String = "", $name: String = "", $patient_recomendation: String = "") {
  update_Template(
    where: {id: {_eq: $id}}
    _set: {internal_recomendation: $internal_recomendation, name: $name, patient_recomendation: $patient_recomendation}
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpdateTemplateMutationFn = Apollo.MutationFunction<UpdateTemplateMutation, UpdateTemplateMutationVariables>;

/**
 * __useUpdateTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateMutation, { data, loading, error }] = useUpdateTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      internal_recomendation: // value for 'internal_recomendation'
 *      name: // value for 'name'
 *      patient_recomendation: // value for 'patient_recomendation'
 *   },
 * });
 */
export function useUpdateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTemplateMutation, UpdateTemplateMutationVariables>(UpdateTemplateDocument, options);
      }
export type UpdateTemplateMutationHookResult = ReturnType<typeof useUpdateTemplateMutation>;
export type UpdateTemplateMutationResult = Apollo.MutationResult<UpdateTemplateMutation>;
export type UpdateTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>;
export const DeleteTemplateDocument = gql`
    mutation DeleteTemplate($id: Int = 0) {
  delete_Template(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteTemplateMutationFn = Apollo.MutationFunction<DeleteTemplateMutation, DeleteTemplateMutationVariables>;

/**
 * __useDeleteTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateMutation, { data, loading, error }] = useDeleteTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTemplateMutation, DeleteTemplateMutationVariables>(DeleteTemplateDocument, options);
      }
export type DeleteTemplateMutationHookResult = ReturnType<typeof useDeleteTemplateMutation>;
export type DeleteTemplateMutationResult = Apollo.MutationResult<DeleteTemplateMutation>;
export type DeleteTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>;
export const DeleteTemplatesByIdsDocument = gql`
    mutation DeleteTemplatesByIds($ids: [Int!] = 0) {
  delete_Template(where: {id: {_in: $ids}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteTemplatesByIdsMutationFn = Apollo.MutationFunction<DeleteTemplatesByIdsMutation, DeleteTemplatesByIdsMutationVariables>;

/**
 * __useDeleteTemplatesByIdsMutation__
 *
 * To run a mutation, you first call `useDeleteTemplatesByIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplatesByIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplatesByIdsMutation, { data, loading, error }] = useDeleteTemplatesByIdsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteTemplatesByIdsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTemplatesByIdsMutation, DeleteTemplatesByIdsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTemplatesByIdsMutation, DeleteTemplatesByIdsMutationVariables>(DeleteTemplatesByIdsDocument, options);
      }
export type DeleteTemplatesByIdsMutationHookResult = ReturnType<typeof useDeleteTemplatesByIdsMutation>;
export type DeleteTemplatesByIdsMutationResult = Apollo.MutationResult<DeleteTemplatesByIdsMutation>;
export type DeleteTemplatesByIdsMutationOptions = Apollo.BaseMutationOptions<DeleteTemplatesByIdsMutation, DeleteTemplatesByIdsMutationVariables>;
export const DeleteTemplateMedicineDocument = gql`
    mutation DeleteTemplateMedicine($id: Int = 0) {
  delete_TemplateMedicines(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteTemplateMedicineMutationFn = Apollo.MutationFunction<DeleteTemplateMedicineMutation, DeleteTemplateMedicineMutationVariables>;

/**
 * __useDeleteTemplateMedicineMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateMedicineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateMedicineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateMedicineMutation, { data, loading, error }] = useDeleteTemplateMedicineMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTemplateMedicineMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTemplateMedicineMutation, DeleteTemplateMedicineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTemplateMedicineMutation, DeleteTemplateMedicineMutationVariables>(DeleteTemplateMedicineDocument, options);
      }
export type DeleteTemplateMedicineMutationHookResult = ReturnType<typeof useDeleteTemplateMedicineMutation>;
export type DeleteTemplateMedicineMutationResult = Apollo.MutationResult<DeleteTemplateMedicineMutation>;
export type DeleteTemplateMedicineMutationOptions = Apollo.BaseMutationOptions<DeleteTemplateMedicineMutation, DeleteTemplateMedicineMutationVariables>;
export const UpdateTemplateMedicineDocument = gql`
    mutation UpdateTemplateMedicine($id: Int = 0, $medicine_id: Int = 0, $value: float8 = "") {
  update_TemplateMedicines(
    where: {id: {_eq: $id}}
    _set: {medicine_id: $medicine_id, value: $value}
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpdateTemplateMedicineMutationFn = Apollo.MutationFunction<UpdateTemplateMedicineMutation, UpdateTemplateMedicineMutationVariables>;

/**
 * __useUpdateTemplateMedicineMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateMedicineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateMedicineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateMedicineMutation, { data, loading, error }] = useUpdateTemplateMedicineMutation({
 *   variables: {
 *      id: // value for 'id'
 *      medicine_id: // value for 'medicine_id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateTemplateMedicineMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTemplateMedicineMutation, UpdateTemplateMedicineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTemplateMedicineMutation, UpdateTemplateMedicineMutationVariables>(UpdateTemplateMedicineDocument, options);
      }
export type UpdateTemplateMedicineMutationHookResult = ReturnType<typeof useUpdateTemplateMedicineMutation>;
export type UpdateTemplateMedicineMutationResult = Apollo.MutationResult<UpdateTemplateMedicineMutation>;
export type UpdateTemplateMedicineMutationOptions = Apollo.BaseMutationOptions<UpdateTemplateMedicineMutation, UpdateTemplateMedicineMutationVariables>;
export const AddTemplateMedicinesDocument = gql`
    mutation AddTemplateMedicines($objects: [TemplateMedicines_insert_input!] = {}) {
  insert_TemplateMedicines(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type AddTemplateMedicinesMutationFn = Apollo.MutationFunction<AddTemplateMedicinesMutation, AddTemplateMedicinesMutationVariables>;

/**
 * __useAddTemplateMedicinesMutation__
 *
 * To run a mutation, you first call `useAddTemplateMedicinesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTemplateMedicinesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTemplateMedicinesMutation, { data, loading, error }] = useAddTemplateMedicinesMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddTemplateMedicinesMutation(baseOptions?: Apollo.MutationHookOptions<AddTemplateMedicinesMutation, AddTemplateMedicinesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTemplateMedicinesMutation, AddTemplateMedicinesMutationVariables>(AddTemplateMedicinesDocument, options);
      }
export type AddTemplateMedicinesMutationHookResult = ReturnType<typeof useAddTemplateMedicinesMutation>;
export type AddTemplateMedicinesMutationResult = Apollo.MutationResult<AddTemplateMedicinesMutation>;
export type AddTemplateMedicinesMutationOptions = Apollo.BaseMutationOptions<AddTemplateMedicinesMutation, AddTemplateMedicinesMutationVariables>;
export const DeleteTemplateServiceDocument = gql`
    mutation DeleteTemplateService($id: Int = 0) {
  delete_TemplateServices(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteTemplateServiceMutationFn = Apollo.MutationFunction<DeleteTemplateServiceMutation, DeleteTemplateServiceMutationVariables>;

/**
 * __useDeleteTemplateServiceMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateServiceMutation, { data, loading, error }] = useDeleteTemplateServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTemplateServiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTemplateServiceMutation, DeleteTemplateServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTemplateServiceMutation, DeleteTemplateServiceMutationVariables>(DeleteTemplateServiceDocument, options);
      }
export type DeleteTemplateServiceMutationHookResult = ReturnType<typeof useDeleteTemplateServiceMutation>;
export type DeleteTemplateServiceMutationResult = Apollo.MutationResult<DeleteTemplateServiceMutation>;
export type DeleteTemplateServiceMutationOptions = Apollo.BaseMutationOptions<DeleteTemplateServiceMutation, DeleteTemplateServiceMutationVariables>;
export const UpdateTemplateServiceDocument = gql`
    mutation UpdateTemplateService($id: Int = 0, $service_id: Int = 0, $price: float8 = "", $value: float8 = "") {
  update_TemplateServices(
    where: {id: {_eq: $id}}
    _set: {service_id: $service_id, value: $value, price: $price}
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpdateTemplateServiceMutationFn = Apollo.MutationFunction<UpdateTemplateServiceMutation, UpdateTemplateServiceMutationVariables>;

/**
 * __useUpdateTemplateServiceMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateServiceMutation, { data, loading, error }] = useUpdateTemplateServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      service_id: // value for 'service_id'
 *      price: // value for 'price'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateTemplateServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTemplateServiceMutation, UpdateTemplateServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTemplateServiceMutation, UpdateTemplateServiceMutationVariables>(UpdateTemplateServiceDocument, options);
      }
export type UpdateTemplateServiceMutationHookResult = ReturnType<typeof useUpdateTemplateServiceMutation>;
export type UpdateTemplateServiceMutationResult = Apollo.MutationResult<UpdateTemplateServiceMutation>;
export type UpdateTemplateServiceMutationOptions = Apollo.BaseMutationOptions<UpdateTemplateServiceMutation, UpdateTemplateServiceMutationVariables>;
export const AddTemplateServicesDocument = gql`
    mutation AddTemplateServices($objects: [TemplateServices_insert_input!] = {}) {
  insert_TemplateServices(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type AddTemplateServicesMutationFn = Apollo.MutationFunction<AddTemplateServicesMutation, AddTemplateServicesMutationVariables>;

/**
 * __useAddTemplateServicesMutation__
 *
 * To run a mutation, you first call `useAddTemplateServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTemplateServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTemplateServicesMutation, { data, loading, error }] = useAddTemplateServicesMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddTemplateServicesMutation(baseOptions?: Apollo.MutationHookOptions<AddTemplateServicesMutation, AddTemplateServicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTemplateServicesMutation, AddTemplateServicesMutationVariables>(AddTemplateServicesDocument, options);
      }
export type AddTemplateServicesMutationHookResult = ReturnType<typeof useAddTemplateServicesMutation>;
export type AddTemplateServicesMutationResult = Apollo.MutationResult<AddTemplateServicesMutation>;
export type AddTemplateServicesMutationOptions = Apollo.BaseMutationOptions<AddTemplateServicesMutation, AddTemplateServicesMutationVariables>;
export const MedicineUnitsIndexDocument = gql`
    subscription MedicineUnitsIndex($limit: Int = 10, $offset: Int = 0, $order_by: [MedicineUnit_order_by!] = {}, $where: MedicineUnit_bool_exp = {}) {
  MedicineUnit(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    name
    id
  }
}
    `;

/**
 * __useMedicineUnitsIndexSubscription__
 *
 * To run a query within a React component, call `useMedicineUnitsIndexSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMedicineUnitsIndexSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicineUnitsIndexSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMedicineUnitsIndexSubscription(baseOptions?: Apollo.SubscriptionHookOptions<MedicineUnitsIndexSubscription, MedicineUnitsIndexSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<MedicineUnitsIndexSubscription, MedicineUnitsIndexSubscriptionVariables>(MedicineUnitsIndexDocument, options);
      }
export type MedicineUnitsIndexSubscriptionHookResult = ReturnType<typeof useMedicineUnitsIndexSubscription>;
export type MedicineUnitsIndexSubscriptionResult = Apollo.SubscriptionResult<MedicineUnitsIndexSubscription>;
export const MedicineUnitsIndexCountDocument = gql`
    subscription MedicineUnitsIndexCount($where: MedicineUnit_bool_exp = {}) {
  MedicineUnit_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useMedicineUnitsIndexCountSubscription__
 *
 * To run a query within a React component, call `useMedicineUnitsIndexCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMedicineUnitsIndexCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicineUnitsIndexCountSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMedicineUnitsIndexCountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<MedicineUnitsIndexCountSubscription, MedicineUnitsIndexCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<MedicineUnitsIndexCountSubscription, MedicineUnitsIndexCountSubscriptionVariables>(MedicineUnitsIndexCountDocument, options);
      }
export type MedicineUnitsIndexCountSubscriptionHookResult = ReturnType<typeof useMedicineUnitsIndexCountSubscription>;
export type MedicineUnitsIndexCountSubscriptionResult = Apollo.SubscriptionResult<MedicineUnitsIndexCountSubscription>;
export const AddMedicineUnitDocument = gql`
    mutation AddMedicineUnit($name: String = "") {
  insert_MedicineUnit(objects: {name: $name}) {
    returning {
      id
    }
  }
}
    `;
export type AddMedicineUnitMutationFn = Apollo.MutationFunction<AddMedicineUnitMutation, AddMedicineUnitMutationVariables>;

/**
 * __useAddMedicineUnitMutation__
 *
 * To run a mutation, you first call `useAddMedicineUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMedicineUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMedicineUnitMutation, { data, loading, error }] = useAddMedicineUnitMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddMedicineUnitMutation(baseOptions?: Apollo.MutationHookOptions<AddMedicineUnitMutation, AddMedicineUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMedicineUnitMutation, AddMedicineUnitMutationVariables>(AddMedicineUnitDocument, options);
      }
export type AddMedicineUnitMutationHookResult = ReturnType<typeof useAddMedicineUnitMutation>;
export type AddMedicineUnitMutationResult = Apollo.MutationResult<AddMedicineUnitMutation>;
export type AddMedicineUnitMutationOptions = Apollo.BaseMutationOptions<AddMedicineUnitMutation, AddMedicineUnitMutationVariables>;
export const UpdateMedicineUnitDocument = gql`
    mutation UpdateMedicineUnit($name: String = "", $id: Int = 10) {
  update_MedicineUnit(where: {id: {_eq: $id}}, _set: {name: $name}) {
    returning {
      id
    }
  }
}
    `;
export type UpdateMedicineUnitMutationFn = Apollo.MutationFunction<UpdateMedicineUnitMutation, UpdateMedicineUnitMutationVariables>;

/**
 * __useUpdateMedicineUnitMutation__
 *
 * To run a mutation, you first call `useUpdateMedicineUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMedicineUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMedicineUnitMutation, { data, loading, error }] = useUpdateMedicineUnitMutation({
 *   variables: {
 *      name: // value for 'name'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateMedicineUnitMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMedicineUnitMutation, UpdateMedicineUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMedicineUnitMutation, UpdateMedicineUnitMutationVariables>(UpdateMedicineUnitDocument, options);
      }
export type UpdateMedicineUnitMutationHookResult = ReturnType<typeof useUpdateMedicineUnitMutation>;
export type UpdateMedicineUnitMutationResult = Apollo.MutationResult<UpdateMedicineUnitMutation>;
export type UpdateMedicineUnitMutationOptions = Apollo.BaseMutationOptions<UpdateMedicineUnitMutation, UpdateMedicineUnitMutationVariables>;
export const DeleteMedicineUnitDocument = gql`
    mutation DeleteMedicineUnit($id: Int = 10) {
  delete_MedicineUnit(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteMedicineUnitMutationFn = Apollo.MutationFunction<DeleteMedicineUnitMutation, DeleteMedicineUnitMutationVariables>;

/**
 * __useDeleteMedicineUnitMutation__
 *
 * To run a mutation, you first call `useDeleteMedicineUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMedicineUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMedicineUnitMutation, { data, loading, error }] = useDeleteMedicineUnitMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMedicineUnitMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMedicineUnitMutation, DeleteMedicineUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMedicineUnitMutation, DeleteMedicineUnitMutationVariables>(DeleteMedicineUnitDocument, options);
      }
export type DeleteMedicineUnitMutationHookResult = ReturnType<typeof useDeleteMedicineUnitMutation>;
export type DeleteMedicineUnitMutationResult = Apollo.MutationResult<DeleteMedicineUnitMutation>;
export type DeleteMedicineUnitMutationOptions = Apollo.BaseMutationOptions<DeleteMedicineUnitMutation, DeleteMedicineUnitMutationVariables>;
export const DeleteMedicineUnitsByIdsDocument = gql`
    mutation DeleteMedicineUnitsByIds($ids: [Int!] = 10) {
  delete_MedicineUnit(where: {id: {_in: $ids}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteMedicineUnitsByIdsMutationFn = Apollo.MutationFunction<DeleteMedicineUnitsByIdsMutation, DeleteMedicineUnitsByIdsMutationVariables>;

/**
 * __useDeleteMedicineUnitsByIdsMutation__
 *
 * To run a mutation, you first call `useDeleteMedicineUnitsByIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMedicineUnitsByIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMedicineUnitsByIdsMutation, { data, loading, error }] = useDeleteMedicineUnitsByIdsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteMedicineUnitsByIdsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMedicineUnitsByIdsMutation, DeleteMedicineUnitsByIdsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMedicineUnitsByIdsMutation, DeleteMedicineUnitsByIdsMutationVariables>(DeleteMedicineUnitsByIdsDocument, options);
      }
export type DeleteMedicineUnitsByIdsMutationHookResult = ReturnType<typeof useDeleteMedicineUnitsByIdsMutation>;
export type DeleteMedicineUnitsByIdsMutationResult = Apollo.MutationResult<DeleteMedicineUnitsByIdsMutation>;
export type DeleteMedicineUnitsByIdsMutationOptions = Apollo.BaseMutationOptions<DeleteMedicineUnitsByIdsMutation, DeleteMedicineUnitsByIdsMutationVariables>;
export const UsersIndexDocument = gql`
    subscription UsersIndex($limit: Int = 10, $offset: Int = 0, $order_by: [Users_order_by!] = {}, $where: Users_bool_exp = {}) {
  Users(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    ID
    Login
    Name
    IsActive
    LastSeen
    DefaultRoleId
    Role {
      Name
    }
  }
}
    `;

/**
 * __useUsersIndexSubscription__
 *
 * To run a query within a React component, call `useUsersIndexSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUsersIndexSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersIndexSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUsersIndexSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UsersIndexSubscription, UsersIndexSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UsersIndexSubscription, UsersIndexSubscriptionVariables>(UsersIndexDocument, options);
      }
export type UsersIndexSubscriptionHookResult = ReturnType<typeof useUsersIndexSubscription>;
export type UsersIndexSubscriptionResult = Apollo.SubscriptionResult<UsersIndexSubscription>;
export const UsersIndexCountDocument = gql`
    subscription UsersIndexCount($where: Users_bool_exp = {}) {
  Users_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useUsersIndexCountSubscription__
 *
 * To run a query within a React component, call `useUsersIndexCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUsersIndexCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersIndexCountSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUsersIndexCountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UsersIndexCountSubscription, UsersIndexCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UsersIndexCountSubscription, UsersIndexCountSubscriptionVariables>(UsersIndexCountDocument, options);
      }
export type UsersIndexCountSubscriptionHookResult = ReturnType<typeof useUsersIndexCountSubscription>;
export type UsersIndexCountSubscriptionResult = Apollo.SubscriptionResult<UsersIndexCountSubscription>;
export const AddUserDocument = gql`
    mutation AddUser($DefaultRoleId: uuid = "c83bea64-1035-4672-a93f-865633e20c77", $IsActive: Boolean = true, $Login: String = "test_login", $Name: String = "test_name", $Password: String = "test_password") {
  insert_Users(
    objects: {DefaultRoleId: $DefaultRoleId, IsActive: $IsActive, Login: $Login, Name: $Name, Password: $Password}
  ) {
    returning {
      ID
    }
  }
}
    `;
export type AddUserMutationFn = Apollo.MutationFunction<AddUserMutation, AddUserMutationVariables>;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      DefaultRoleId: // value for 'DefaultRoleId'
 *      IsActive: // value for 'IsActive'
 *      Login: // value for 'Login'
 *      Name: // value for 'Name'
 *      Password: // value for 'Password'
 *   },
 * });
 */
export function useAddUserMutation(baseOptions?: Apollo.MutationHookOptions<AddUserMutation, AddUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument, options);
      }
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = Apollo.MutationResult<AddUserMutation>;
export type AddUserMutationOptions = Apollo.BaseMutationOptions<AddUserMutation, AddUserMutationVariables>;
export const GetRolesDocument = gql`
    query GetRoles {
  Roles {
    Name
    ID
  }
}
    `;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
      }
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
        }
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($DefaultRoleId: uuid = "", $IsActive: Boolean = false, $Login: String = "", $Name: String = "", $ID: uuid = "") {
  update_Users(
    _set: {Name: $Name, Login: $Login, IsActive: $IsActive, DefaultRoleId: $DefaultRoleId}
    where: {ID: {_eq: $ID}}
  ) {
    returning {
      ID
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      DefaultRoleId: // value for 'DefaultRoleId'
 *      IsActive: // value for 'IsActive'
 *      Login: // value for 'Login'
 *      Name: // value for 'Name'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($ID: uuid = "") {
  delete_Users(where: {ID: {_eq: $ID}}) {
    returning {
      ID
    }
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const DeleteUsersByIdsDocument = gql`
    mutation DeleteUsersByIds($IDS: [uuid!] = "") {
  delete_Users(where: {ID: {_in: $IDS}}) {
    returning {
      ID
    }
  }
}
    `;
export type DeleteUsersByIdsMutationFn = Apollo.MutationFunction<DeleteUsersByIdsMutation, DeleteUsersByIdsMutationVariables>;

/**
 * __useDeleteUsersByIdsMutation__
 *
 * To run a mutation, you first call `useDeleteUsersByIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUsersByIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUsersByIdsMutation, { data, loading, error }] = useDeleteUsersByIdsMutation({
 *   variables: {
 *      IDS: // value for 'IDS'
 *   },
 * });
 */
export function useDeleteUsersByIdsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUsersByIdsMutation, DeleteUsersByIdsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUsersByIdsMutation, DeleteUsersByIdsMutationVariables>(DeleteUsersByIdsDocument, options);
      }
export type DeleteUsersByIdsMutationHookResult = ReturnType<typeof useDeleteUsersByIdsMutation>;
export type DeleteUsersByIdsMutationResult = Apollo.MutationResult<DeleteUsersByIdsMutation>;
export type DeleteUsersByIdsMutationOptions = Apollo.BaseMutationOptions<DeleteUsersByIdsMutation, DeleteUsersByIdsMutationVariables>;
export const AllDepartmentsDocument = gql`
    subscription AllDepartments {
  Department {
    name
    id
  }
}
    `;

/**
 * __useAllDepartmentsSubscription__
 *
 * To run a query within a React component, call `useAllDepartmentsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAllDepartmentsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDepartmentsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAllDepartmentsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<AllDepartmentsSubscription, AllDepartmentsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AllDepartmentsSubscription, AllDepartmentsSubscriptionVariables>(AllDepartmentsDocument, options);
      }
export type AllDepartmentsSubscriptionHookResult = ReturnType<typeof useAllDepartmentsSubscription>;
export type AllDepartmentsSubscriptionResult = Apollo.SubscriptionResult<AllDepartmentsSubscription>;
export const GetUsersDepartmentsDocument = gql`
    query GetUsersDepartments($doctor_id: uuid = "") {
  DoctorsDepartment(where: {doctor_id: {_eq: $doctor_id}}) {
    id
    department_id
    Department {
      name
    }
  }
}
    `;

/**
 * __useGetUsersDepartmentsQuery__
 *
 * To run a query within a React component, call `useGetUsersDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersDepartmentsQuery({
 *   variables: {
 *      doctor_id: // value for 'doctor_id'
 *   },
 * });
 */
export function useGetUsersDepartmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersDepartmentsQuery, GetUsersDepartmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersDepartmentsQuery, GetUsersDepartmentsQueryVariables>(GetUsersDepartmentsDocument, options);
      }
export function useGetUsersDepartmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersDepartmentsQuery, GetUsersDepartmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersDepartmentsQuery, GetUsersDepartmentsQueryVariables>(GetUsersDepartmentsDocument, options);
        }
export type GetUsersDepartmentsQueryHookResult = ReturnType<typeof useGetUsersDepartmentsQuery>;
export type GetUsersDepartmentsLazyQueryHookResult = ReturnType<typeof useGetUsersDepartmentsLazyQuery>;
export type GetUsersDepartmentsQueryResult = Apollo.QueryResult<GetUsersDepartmentsQuery, GetUsersDepartmentsQueryVariables>;
export const DeleteUserDepartmentsDocument = gql`
    mutation DeleteUserDepartments($_in: [Int!] = 0) {
  delete_DoctorsDepartment(where: {id: {_in: $_in}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteUserDepartmentsMutationFn = Apollo.MutationFunction<DeleteUserDepartmentsMutation, DeleteUserDepartmentsMutationVariables>;

/**
 * __useDeleteUserDepartmentsMutation__
 *
 * To run a mutation, you first call `useDeleteUserDepartmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserDepartmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserDepartmentsMutation, { data, loading, error }] = useDeleteUserDepartmentsMutation({
 *   variables: {
 *      _in: // value for '_in'
 *   },
 * });
 */
export function useDeleteUserDepartmentsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserDepartmentsMutation, DeleteUserDepartmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserDepartmentsMutation, DeleteUserDepartmentsMutationVariables>(DeleteUserDepartmentsDocument, options);
      }
export type DeleteUserDepartmentsMutationHookResult = ReturnType<typeof useDeleteUserDepartmentsMutation>;
export type DeleteUserDepartmentsMutationResult = Apollo.MutationResult<DeleteUserDepartmentsMutation>;
export type DeleteUserDepartmentsMutationOptions = Apollo.BaseMutationOptions<DeleteUserDepartmentsMutation, DeleteUserDepartmentsMutationVariables>;
export const AddUserDepartmentsDocument = gql`
    mutation AddUserDepartments($objects: [DoctorsDepartment_insert_input!] = {}) {
  insert_DoctorsDepartment(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type AddUserDepartmentsMutationFn = Apollo.MutationFunction<AddUserDepartmentsMutation, AddUserDepartmentsMutationVariables>;

/**
 * __useAddUserDepartmentsMutation__
 *
 * To run a mutation, you first call `useAddUserDepartmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserDepartmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserDepartmentsMutation, { data, loading, error }] = useAddUserDepartmentsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddUserDepartmentsMutation(baseOptions?: Apollo.MutationHookOptions<AddUserDepartmentsMutation, AddUserDepartmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserDepartmentsMutation, AddUserDepartmentsMutationVariables>(AddUserDepartmentsDocument, options);
      }
export type AddUserDepartmentsMutationHookResult = ReturnType<typeof useAddUserDepartmentsMutation>;
export type AddUserDepartmentsMutationResult = Apollo.MutationResult<AddUserDepartmentsMutation>;
export type AddUserDepartmentsMutationOptions = Apollo.BaseMutationOptions<AddUserDepartmentsMutation, AddUserDepartmentsMutationVariables>;
export const GetUsersPricesDocument = gql`
    subscription GetUsersPrices($id: uuid = "") {
  Users(where: {ID: {_eq: $id}}) {
    Name
    ID
    UserMedicinePayments {
      id
      in_percent
      medicine_id
      user_id
      value
      Medicine {
        id
        name
        price
        ut_id
      }
    }
    UserServicePayments {
      id
      in_percent
      service_id
      user_id
      value
      Service {
        id
        name
        price
        ut_id
      }
    }
  }
}
    `;

/**
 * __useGetUsersPricesSubscription__
 *
 * To run a query within a React component, call `useGetUsersPricesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersPricesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersPricesSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUsersPricesSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetUsersPricesSubscription, GetUsersPricesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetUsersPricesSubscription, GetUsersPricesSubscriptionVariables>(GetUsersPricesDocument, options);
      }
export type GetUsersPricesSubscriptionHookResult = ReturnType<typeof useGetUsersPricesSubscription>;
export type GetUsersPricesSubscriptionResult = Apollo.SubscriptionResult<GetUsersPricesSubscription>;
export const GetMedicinesUnitsAndServicesDocument = gql`
    query GetMedicinesUnitsAndServices {
  Medicine {
    id
    name
    price
    ut_id
  }
  Service {
    id
    name
    price
    ut_id
  }
}
    `;

/**
 * __useGetMedicinesUnitsAndServicesQuery__
 *
 * To run a query within a React component, call `useGetMedicinesUnitsAndServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMedicinesUnitsAndServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMedicinesUnitsAndServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMedicinesUnitsAndServicesQuery(baseOptions?: Apollo.QueryHookOptions<GetMedicinesUnitsAndServicesQuery, GetMedicinesUnitsAndServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMedicinesUnitsAndServicesQuery, GetMedicinesUnitsAndServicesQueryVariables>(GetMedicinesUnitsAndServicesDocument, options);
      }
export function useGetMedicinesUnitsAndServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMedicinesUnitsAndServicesQuery, GetMedicinesUnitsAndServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMedicinesUnitsAndServicesQuery, GetMedicinesUnitsAndServicesQueryVariables>(GetMedicinesUnitsAndServicesDocument, options);
        }
export type GetMedicinesUnitsAndServicesQueryHookResult = ReturnType<typeof useGetMedicinesUnitsAndServicesQuery>;
export type GetMedicinesUnitsAndServicesLazyQueryHookResult = ReturnType<typeof useGetMedicinesUnitsAndServicesLazyQuery>;
export type GetMedicinesUnitsAndServicesQueryResult = Apollo.QueryResult<GetMedicinesUnitsAndServicesQuery, GetMedicinesUnitsAndServicesQueryVariables>;
export const DeleteUserMedicineDocument = gql`
    mutation DeleteUserMedicine($id: Int = 0) {
  delete_UserMedicinePayments(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteUserMedicineMutationFn = Apollo.MutationFunction<DeleteUserMedicineMutation, DeleteUserMedicineMutationVariables>;

/**
 * __useDeleteUserMedicineMutation__
 *
 * To run a mutation, you first call `useDeleteUserMedicineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMedicineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMedicineMutation, { data, loading, error }] = useDeleteUserMedicineMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMedicineMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMedicineMutation, DeleteUserMedicineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMedicineMutation, DeleteUserMedicineMutationVariables>(DeleteUserMedicineDocument, options);
      }
export type DeleteUserMedicineMutationHookResult = ReturnType<typeof useDeleteUserMedicineMutation>;
export type DeleteUserMedicineMutationResult = Apollo.MutationResult<DeleteUserMedicineMutation>;
export type DeleteUserMedicineMutationOptions = Apollo.BaseMutationOptions<DeleteUserMedicineMutation, DeleteUserMedicineMutationVariables>;
export const UpdateUserMedicineDocument = gql`
    mutation UpdateUserMedicine($id: Int = 0, $medicine_id: Int = 0, $value: float8 = "", $user_id: uuid = "", $in_percent: Boolean = false) {
  update_UserMedicinePayments(
    where: {id: {_eq: $id}}
    _set: {medicine_id: $medicine_id, value: $value, user_id: $user_id, in_percent: $in_percent}
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpdateUserMedicineMutationFn = Apollo.MutationFunction<UpdateUserMedicineMutation, UpdateUserMedicineMutationVariables>;

/**
 * __useUpdateUserMedicineMutation__
 *
 * To run a mutation, you first call `useUpdateUserMedicineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMedicineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMedicineMutation, { data, loading, error }] = useUpdateUserMedicineMutation({
 *   variables: {
 *      id: // value for 'id'
 *      medicine_id: // value for 'medicine_id'
 *      value: // value for 'value'
 *      user_id: // value for 'user_id'
 *      in_percent: // value for 'in_percent'
 *   },
 * });
 */
export function useUpdateUserMedicineMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMedicineMutation, UpdateUserMedicineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMedicineMutation, UpdateUserMedicineMutationVariables>(UpdateUserMedicineDocument, options);
      }
export type UpdateUserMedicineMutationHookResult = ReturnType<typeof useUpdateUserMedicineMutation>;
export type UpdateUserMedicineMutationResult = Apollo.MutationResult<UpdateUserMedicineMutation>;
export type UpdateUserMedicineMutationOptions = Apollo.BaseMutationOptions<UpdateUserMedicineMutation, UpdateUserMedicineMutationVariables>;
export const AddUserMedicinesDocument = gql`
    mutation AddUserMedicines($objects: [UserMedicinePayments_insert_input!] = {}) {
  insert_UserMedicinePayments(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type AddUserMedicinesMutationFn = Apollo.MutationFunction<AddUserMedicinesMutation, AddUserMedicinesMutationVariables>;

/**
 * __useAddUserMedicinesMutation__
 *
 * To run a mutation, you first call `useAddUserMedicinesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMedicinesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMedicinesMutation, { data, loading, error }] = useAddUserMedicinesMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddUserMedicinesMutation(baseOptions?: Apollo.MutationHookOptions<AddUserMedicinesMutation, AddUserMedicinesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserMedicinesMutation, AddUserMedicinesMutationVariables>(AddUserMedicinesDocument, options);
      }
export type AddUserMedicinesMutationHookResult = ReturnType<typeof useAddUserMedicinesMutation>;
export type AddUserMedicinesMutationResult = Apollo.MutationResult<AddUserMedicinesMutation>;
export type AddUserMedicinesMutationOptions = Apollo.BaseMutationOptions<AddUserMedicinesMutation, AddUserMedicinesMutationVariables>;
export const DeleteUserServiceDocument = gql`
    mutation DeleteUserService($id: Int = 0) {
  delete_UserServicePayments(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteUserServiceMutationFn = Apollo.MutationFunction<DeleteUserServiceMutation, DeleteUserServiceMutationVariables>;

/**
 * __useDeleteUserServiceMutation__
 *
 * To run a mutation, you first call `useDeleteUserServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserServiceMutation, { data, loading, error }] = useDeleteUserServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserServiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserServiceMutation, DeleteUserServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserServiceMutation, DeleteUserServiceMutationVariables>(DeleteUserServiceDocument, options);
      }
export type DeleteUserServiceMutationHookResult = ReturnType<typeof useDeleteUserServiceMutation>;
export type DeleteUserServiceMutationResult = Apollo.MutationResult<DeleteUserServiceMutation>;
export type DeleteUserServiceMutationOptions = Apollo.BaseMutationOptions<DeleteUserServiceMutation, DeleteUserServiceMutationVariables>;
export const UpdateUserServiceDocument = gql`
    mutation UpdateUserService($id: Int = 0, $service_id: Int = 0, $value: float8 = "", $user_id: uuid = "", $in_percent: Boolean = false) {
  update_UserServicePayments(
    where: {id: {_eq: $id}}
    _set: {service_id: $service_id, value: $value, user_id: $user_id, in_percent: $in_percent}
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpdateUserServiceMutationFn = Apollo.MutationFunction<UpdateUserServiceMutation, UpdateUserServiceMutationVariables>;

/**
 * __useUpdateUserServiceMutation__
 *
 * To run a mutation, you first call `useUpdateUserServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserServiceMutation, { data, loading, error }] = useUpdateUserServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      service_id: // value for 'service_id'
 *      value: // value for 'value'
 *      user_id: // value for 'user_id'
 *      in_percent: // value for 'in_percent'
 *   },
 * });
 */
export function useUpdateUserServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserServiceMutation, UpdateUserServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserServiceMutation, UpdateUserServiceMutationVariables>(UpdateUserServiceDocument, options);
      }
export type UpdateUserServiceMutationHookResult = ReturnType<typeof useUpdateUserServiceMutation>;
export type UpdateUserServiceMutationResult = Apollo.MutationResult<UpdateUserServiceMutation>;
export type UpdateUserServiceMutationOptions = Apollo.BaseMutationOptions<UpdateUserServiceMutation, UpdateUserServiceMutationVariables>;
export const AddUserServicesDocument = gql`
    mutation AddUserServices($objects: [UserServicePayments_insert_input!] = {}) {
  insert_UserServicePayments(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type AddUserServicesMutationFn = Apollo.MutationFunction<AddUserServicesMutation, AddUserServicesMutationVariables>;

/**
 * __useAddUserServicesMutation__
 *
 * To run a mutation, you first call `useAddUserServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserServicesMutation, { data, loading, error }] = useAddUserServicesMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddUserServicesMutation(baseOptions?: Apollo.MutationHookOptions<AddUserServicesMutation, AddUserServicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserServicesMutation, AddUserServicesMutationVariables>(AddUserServicesDocument, options);
      }
export type AddUserServicesMutationHookResult = ReturnType<typeof useAddUserServicesMutation>;
export type AddUserServicesMutationResult = Apollo.MutationResult<AddUserServicesMutation>;
export type AddUserServicesMutationOptions = Apollo.BaseMutationOptions<AddUserServicesMutation, AddUserServicesMutationVariables>;
export const GetUserNameDocument = gql`
    query GetUserName($ID: uuid = "b4d05ee7-42fe-4953-a833-1890da4b6332") {
  Users_by_pk(ID: $ID) {
    Name
  }
}
    `;

/**
 * __useGetUserNameQuery__
 *
 * To run a query within a React component, call `useGetUserNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNameQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetUserNameQuery(baseOptions?: Apollo.QueryHookOptions<GetUserNameQuery, GetUserNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserNameQuery, GetUserNameQueryVariables>(GetUserNameDocument, options);
      }
export function useGetUserNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserNameQuery, GetUserNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserNameQuery, GetUserNameQueryVariables>(GetUserNameDocument, options);
        }
export type GetUserNameQueryHookResult = ReturnType<typeof useGetUserNameQuery>;
export type GetUserNameLazyQueryHookResult = ReturnType<typeof useGetUserNameLazyQuery>;
export type GetUserNameQueryResult = Apollo.QueryResult<GetUserNameQuery, GetUserNameQueryVariables>;