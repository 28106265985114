import React from 'react';
import { Users, useAddUserMutation } from 'gql';
import Modal from 'hex/components/material/modal'
import Loading from 'hex/components/loading'
import TextFieldData from 'hex/components/material/operations/fields/text';
import BoolField from 'hex/components/material/operations/fields/bool';

import {useGetRolesQuery} from 'gql'
import ForeignKey from 'hex/components/material/operations/fields/foreign_key';
import { Alert } from '@mui/material';
import { ApolloError } from '@apollo/client';
import { StringLengthMinMax, StringWithoutSpaces } from 'hex/validations';
import { ConvertToFieldErrors, useValidator } from 'hex/hooks/validator';

// Props:
type CreateProps = {
    open: boolean;
    close: () => void;
};

const translateError = (error: ApolloError) => {
    if (error.message.includes("IX_Users_Login"))
        return "Пользователь с таким логином уже существует!";

    return "Ошибка создания!";
}

const defaultModel = {
    Login: "",
    Name: "",
    IsActive: true,
    DefaultRoleId: "c83bea64-1035-4672-a93f-865633e20c76"
};

const validations : Array<[keyof Partial<Users>, (value: any) => string | undefined]> = [
    ["Login", StringWithoutSpaces()],
    ["Login", StringLengthMinMax(5, 100)],
    ["Name", StringLengthMinMax(5, 100)],
];

const Create: React.FunctionComponent<CreateProps> = ({open, close}) => {
    const rolesQuery = useGetRolesQuery({fetchPolicy: 'network-only'});

    const [model, setModel] = React.useState<Partial<Users>>(defaultModel);
    const [validation, validator, modelValidation] = useValidator<Partial<Users>>(validations);

    const [addUserMutation, { loading, error }] = useAddUserMutation({
        variables: {
            Login: model.Login,
            Name: model.Name,
            IsActive: model.IsActive,
            DefaultRoleId: model.DefaultRoleId
        },
    });

    const createAction = () => {
        if (modelValidation(model))
            addUserMutation().then(() => {
                setModel(defaultModel);
                close();
            });
    }

    const defaultSetValue = (field: keyof Users) => (value: any) => {
        setModel(oldModel => {
            var copy = {...oldModel};

            copy[field] = value;

            return copy;
        });
    }

    return (
        <>
            <Loading loading={loading} />
            <Loading loading={rolesQuery.loading} />
            <Modal
                open={open}
                title='Создание'
                contentTitle='Новый пользователь'
                closeButtonText='Отмена'
                closeButtonAction={() => {
                    setModel(defaultModel);
                    close();
                }}
                actionButtonText='Добавить'
                actionButtonAction={createAction}
                maxWidth='xl'
            >
                {error !== undefined && (
                    <Alert severity="error">{translateError(error)}</Alert>
                )}

                <TextFieldData label='ФИО' value={model.Name} onChange={defaultSetValue("Name")} validator={validator("Name")} errors={ConvertToFieldErrors("Name", validation)} />
                <TextFieldData label='Логин' value={model.Login} onChange={defaultSetValue("Login")} validator={validator("Login")} errors={ConvertToFieldErrors("Login", validation)} />
                <BoolField label='Активен' value={model.IsActive} onChange={defaultSetValue("IsActive")} />
                <ForeignKey label="Роль" value={model.DefaultRoleId} onChange={defaultSetValue("DefaultRoleId")} captionKey="Name" models={rolesQuery.data === undefined ? undefined : rolesQuery.data.Roles} />
            </Modal>
        </>
    )
}

export default Create;