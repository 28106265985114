import {processHttpError} from 'hex/utils/errors';


// Описываем клиента Http для работы с нашим сервером
export default class HttpClient
{
    // Путь к серверу
    private _serverPath: string;

    // Токен доступа к серверу
    private _token?: string;

    // На случай, если будет появляться ошибка доступа, сразу рубим все концы
    private _onAccessError?: Function;

    public constructor(serverPath: string, token?: string, onAccessError?: Function)
    {
        this._serverPath = serverPath;
        this._token = token;
        this._onAccessError = onAccessError;
    }

    public post(path: string, data?: any) : Promise<any>
    {
        return this._send(path, 'POST', data);
    }

    public postFile(path: string, data: any) : Promise<any>
    {
        return this._send(path, 'POST', data, true);
    }

    public get(path: string) : Promise<any>
    {
        return this._send(path, 'GET');
    }

    public get_clean(path: string) : Promise<any>
    {
        return this._send_clean(path, 'GET');
    }

    public patch(path: string, data?: any) : Promise<any>
    {
        return this._send(path, 'PATCH', data);
    }

    public put(path: string, data?: any) : Promise<any>
    {
        return this._send(path, 'PUT', data);
    }

    public delete(path: string, data?: any) : Promise<any>
    {
        return this._send(path, 'DELETE', data);
    }

    private _send(path: string, method: string, data?: any, file?: boolean) : Promise<any>
    {
        let headers : Record<string, string> = {};

        if (!file)
        {
            headers['Content-Type'] = 'application/json';
        }
        
        if (this._token)
        {
            headers["Authorization"] = 'Bearer ' + this._token;
        }

        let options = {
            method: method,
            headers: headers,
            body: data ? 
                        (file ? data : JSON.stringify(data))
                        :
                        undefined,
        };


        return fetch(this._serverPath + path, options).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json();
                } else {
                    return response.text();
                }
            } else {
                if (((response.status === 401) || (response.status === 403)) && this._onAccessError)
                {
                    this._onAccessError();
                }
                
                processHttpError(response);
                throw response;
            }
        });
    }

    private _send_clean(path: string, method: string, data?: any, file?: boolean) : Promise<any>
    {
        let headers : Record<string, string> = {};

        if (!file)
        {
            headers['Content-Type'] = 'application/json';
        }
        
        if (this._token)
        {
            headers["Authorization"] = 'Bearer ' + this._token;
        }

        let options = {
            method: method,
            headers: headers,
            body: data ? 
                        (file ? data : JSON.stringify(data))
                        :
                        undefined,
        };


        return fetch(this._serverPath + path, options);
    }
}