import React from 'react';
import moment from 'moment';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Paper, Typography } from '@mui/material';
import { timeFormat } from 'pages/index/doctor/steps/date';
import { dateDiff } from 'hex/utils/dates';
import { AUTH_SERVER_PATH, VisitStatus } from 'config';

import { GetPatientCardName, PatientCard, VisitDocumentDataType, defaultPatientCardValue } from 'pages/index/doctor/types';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PetSexToString } from 'pages/pets/types';
import { ReceptionDataType, VisitDataType } from './types';
import { useAuth } from 'hex/hooks/auth';
import { useErrorAndLoadChecking } from 'hex/components/material/hooks';
import { useAddFileToVisitMutation } from 'gql';
import FileField from 'hex/components/material/operations/fields/file';

import Preview from 'components/documents/preview';
import ClientEdit from './client_edit';
import PetEdit from './pet_edit';
import { ComponentToPrint } from 'components/documents/printer';
import { useReactToPrint } from 'react-to-print';

const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

type DocumentsProps = {
    onClose: () => void;
    visit_id: number;
    department_id: number;
};


type ReceptionViewProps = 
{
    visit: VisitDataType;
    isEditingIsDisabled: boolean;
    onCommonInformationEdit: () => void;
    onReceptionEdit: (reception: ReceptionDataType) => void;
    ShowAddDocumentsAndAddFilesButtons?: boolean;
    documentsBlock: (props: DocumentsProps) => React.ReactNode;
    hideServicesSum?: boolean;
    onCopy?: (model: ReceptionDataType) => void;
}

const writeEmptyIfEmpty = (data?: string | null) => {
    if ((data === undefined) || (data === null) || (data.trim() === ''))
        return "<Отсутствует>";

    return data;
}

const renderPatientCard = (from: number, to: number, data: any) => {
    const fields = Object.keys(defaultPatientCardValue);

    return fields.slice(from, to).map((fieldName, index) => {
        return (
            <React.Fragment key={index}>
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography variant='body1'>{GetPatientCardName(fieldName as keyof PatientCard)}:</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='caption'>{data.hasOwnProperty(fieldName) ? writeEmptyIfEmpty(data[fieldName]) : "<Отсутствует>"}</Typography>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    });
}


const ReceptionView: React.FunctionComponent<ReceptionViewProps> = ({visit, isEditingIsDisabled, onCommonInformationEdit, onReceptionEdit, ShowAddDocumentsAndAddFilesButtons, documentsBlock, hideServicesSum, onCopy}) => {
    const auth = useAuth();

    const [addFileToVisitMutation, addMutationData] = useAddFileToVisitMutation();

    const [documentsOpen, setDocumentsOpen] = React.useState<boolean>(false);
    const [previewOpen, setPreviewOpen] = React.useState<boolean>(false);
    const [selectedForPreview, setSelectedForPreview] = React.useState<VisitDocumentDataType | undefined>(undefined);

    const [clientEditOpen, setClientEditOpen] = React.useState<boolean>(false);
    const [petEditOpen, setPetEditOpen] = React.useState<boolean>(false);

    const componentRef = React.useRef<HTMLDivElement>();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current as any,
    });

    const [dataToPrint, setDataToPrint] = React.useState<string>("");

    useErrorAndLoadChecking(addMutationData);
    
    const calculateSum = (services: Array<{price: any, value: any}>) => {
        let sum = 0;
        services.forEach((element) => sum += (element.price as number * element.value as number));

        return sum;
    }

    return (
        <>
            <Paper sx={{ p: 1, marginBottom: 1, bgcolor: 'grey.200' }}>
                <Grid container spacing={2}>
                    <Grid item sx={{ flexBasis: '33.33333333333%' }}>
                        <Paper sx={{ p: 1, height: '100%' }}>
                            <Typography variant='h6'>Карточка пациента:</Typography>
                            <Typography variant='body1'>Владелец: <b>{visit.Client.name}</b></Typography>
                            <Typography variant='body1'>Телефон: <b>{visit.Client.phone_number}</b></Typography>
                            <Typography variant='body1'>Кличка животного: <b>{visit.Pet.name}</b></Typography>
                            <Typography variant='body1'>Порода: <b>{visit.Pet.breed || '<Не указано>'}</b></Typography>
                            <Typography variant='body1'>Вид: <b>{visit.Pet.PetType.name}</b></Typography>
                            <Typography variant='body1'>Возраст: <b>{visit.Pet.birthdate ? dateDiff(new Date(visit.Pet.birthdate), new Date(Date.now())) : '<Не указан>'}</b></Typography>
                            <Typography variant='body1'>Пол животного: <b>{PetSexToString(visit.Pet.sex)}</b></Typography>
                            <br />
                            <Typography variant='body1'>Назначил: <b><i>{visit.From.Name}</i></b></Typography>
                        </Paper>
                    </Grid>
                    <Grid item sx={{ flexBasis: '33.33333333333%' }}>
                        <Paper sx={{ p: 1, height: '100%' }}>
                            {renderPatientCard(0, 13, visit.patient_card)}
                        </Paper>
                    </Grid>
                    <Grid item sx={{ flexBasis: '33.33333333333%' }}>
                        <Paper sx={{ p: 1, height: '100%' }}>
                            {renderPatientCard(13, 26, visit.patient_card)}
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>
            {ShowAddDocumentsAndAddFilesButtons && <Paper sx={{ p: 1, marginBottom: 1, bgcolor: 'grey.200' }}>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                    <Grid item>
                        <Button variant="contained" onClick={() => setClientEditOpen(true)} color="secondary">Редактировать клиента</Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={() => setPetEditOpen(true)} color="secondary">Редактировать питомца</Button>
                    </Grid>
                </Grid>
            </Paper>}
            <Paper sx={{ p: 1, marginBottom: 1, bgcolor: 'grey.200' }}>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                    <Grid item>
                        <Button variant="contained" disabled={isEditingIsDisabled || visit.status !== VisitStatus.Done} onClick={onCommonInformationEdit} color="secondary">Редактировать общую информацию</Button>
                    </Grid>
                </Grid>
            </Paper>
            <Paper sx={{ p: 1, marginBottom: 1, bgcolor: 'grey.200' }}>
                <Paper sx={{ p: 1, height: '100%' }}>
                    <Box sx={{ marginBottom: 3 }}>
                        <Typography variant='h6'>Приемы:</Typography>
                    </Box>
                    <Paper sx={{ p: 1, marginBottom: 1, bgcolor: 'grey.200' }}>
                        {visit.ReceptionsList.length === 0 && (<Typography variant='button'>Отсутствуют</Typography>)}
                        {visit.ReceptionsList.map((reception) => (
                            <Box key={reception.id} sx={{ marginBottom: 3 }}>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Grid container spacing={2} alignItems='center'>
                                            <Grid item>
                                                <Typography variant='button'>{reception.Doctor.Name}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant='caption'>{`Направил: ${reception.From.Name}`}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant='caption'>{`Начало приема: ${moment.utc(reception.start_time, timeFormat).tz(clientTimeZone).format('HH:mm')}`}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant='caption'>{`Окончание приема: ${reception.end_time !== undefined && reception.end_time !== null ? moment.utc(reception.end_time, timeFormat).tz(clientTimeZone).format('HH:mm') : "<Не завершен>"}`}</Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Paper sx={{ p: 1, marginBottom: 1, bgcolor: 'grey.200' }}>
                                            <Paper sx={{ p: 1, height: '100%', marginBottom: 1 }}>
                                                <Box sx={{ marginBottom: 1 }}>
                                                    <Typography variant='button'>Примечание (для личной карточки пациента):</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant='body1' style={{whiteSpace: 'pre-line'}}>{reception.internal_recomendation}</Typography>
                                                </Box>
                                            </Paper>
                                            <Paper sx={{ p: 1, height: '100%', marginBottom: 1 }}>
                                                <Box sx={{ marginBottom: 1 }}>
                                                    <Typography variant='button' style={{whiteSpace: 'pre-line'}}>Рекомендации (для пациента):</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant='body1'>{reception.patient_recomendation}</Typography>
                                                </Box>
                                            </Paper>
                                            <Paper sx={{ p: 1, height: '100%', marginBottom: 1 }}>
                                                <Box sx={{ marginBottom: 1 }}>
                                                    <Typography variant='button'>Услуги:</Typography>
                                                </Box>
                                                <Box>
                                                    {reception.Services.length === 0 && <Typography variant='button'>Отсутствуют</Typography>}
                                                    {reception.Services.map((service) => (
                                                        <Grid container spacing={2} alignItems='center' key={service.id}>
                                                            <Grid item>
                                                                <Typography variant='button'>{service.Service.name} {!hideServicesSum ? `${service.value} * ${service.price} руб.` : ''}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                    {!hideServicesSum && <Typography>Итоговая сумма: {calculateSum(reception.Services)} руб.</Typography>}
                                                </Box>
                                            </Paper>
                                            <Paper sx={{ p: 1, height: '100%', marginBottom: 1 }}>
                                                <Box sx={{ marginBottom: 1 }}>
                                                    <Typography variant='button'>Препараты:</Typography>
                                                </Box>
                                                <Box>
                                                    {reception.Medicines.length === 0 && <Typography variant='button'>Отсутствуют</Typography>}
                                                    {reception.Medicines.map((medicine) => (
                                                        <Grid container spacing={2} alignItems='center' key={medicine.id}>
                                                            <Grid item>
                                                                <Typography variant='button'>{medicine.done ? '✓' : ''}{medicine.Medicine.name}</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant='caption'>{medicine.value}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </Box>
                                            </Paper>
                                            <Paper sx={{ p: 1, height: '100%' }}>
                                                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                                                    {onCopy && <Grid item>
                                                        <Button variant="contained" onClick={() => onCopy(reception)} color="secondary">Копировать</Button>
                                                    </Grid>}
                                                    <Grid item>
                                                        <Button variant="contained" disabled={isEditingIsDisabled || visit.status !== VisitStatus.Done || reception.doctor_id !== auth.getUserId()} onClick={() => onReceptionEdit(reception)} color="primary">Редактировать</Button>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Paper>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        ))}
                    </Paper>
                </Paper>
            </Paper>
            <Paper sx={{ p: 1, marginBottom: 1, bgcolor: 'grey.200' }}>
                <Paper sx={{ p: 1, height: '100%' }}>
                    <Box sx={{ marginBottom: 3 }}>
                        <Typography variant='h6'>Документы:</Typography>
                    </Box>
                    <Paper sx={{ p: 1, marginBottom: 1, bgcolor: 'grey.200' }}>
                        <Box>
                            <Grid container>
                                <Grid item sx={{ m: 1 }}>
                                    <Typography variant='button'>Выгруженные файлы:</Typography>
                                </Grid>
                            </Grid>
                            { ShowAddDocumentsAndAddFilesButtons !== undefined && ShowAddDocumentsAndAddFilesButtons && <Box sx={{marginBottom: 3}}>
                                <Grid container>
                                    <Grid item>
                                        <FileField label='файла' onChange={(val, oldName) => {
                                            addFileToVisitMutation({
                                                variables: {
                                                    visit_id: visit.id,
                                                    file_name: val,
                                                    name: oldName
                                                }
                                            });
                                        }} />
                                    </Grid>
                                </Grid>
                            </Box>}
                            <Grid container>
                                {visit.VisitFiles.length === 0 && (<Grid item sx={{ m: 1 }}><Typography variant='button'>Отсутствуют</Typography></Grid>)}
                                {visit.VisitFiles.map(x => (
                                    
                                    <Grid key={x.id} item sx={{ m: 1 }}>
                                        <Button href={`${AUTH_SERVER_PATH}/api/Files/${x.file_name}`} target="_blank" variant="contained">{x.name}</Button>
                                    </Grid>
                                    
                                ))}
                            </Grid>
                        </Box>
                    </Paper>
                    <Paper sx={{ p: 1, marginBottom: 1, bgcolor: 'grey.200' }}>
                        <Box>
                            <Grid container>
                                <Grid item sx={{ m: 1 }}>
                                    <Typography variant='button'>Распечатанные документы:</Typography>
                                </Grid>
                            </Grid>
                            { ShowAddDocumentsAndAddFilesButtons !== undefined && ShowAddDocumentsAndAddFilesButtons && <Box sx={{marginBottom: 3}}>
                                <Grid container>
                                    <Grid item>
                                        <Button variant="contained" component="label" onClick={() => setDocumentsOpen(true)}>
                                            Создать документ
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>}
                            <Grid container>
                                {visit.VisitDocuments.length === 0 && (<Grid item sx={{ m: 1 }}><Typography variant='button'>Отсутствуют</Typography></Grid>)}
                                {visit.VisitDocuments.map(x => (
                                    <Grid key={x.id} item sx={{ m: 1 }}>
                                        <Button variant="contained" onClick={() => {
                                            setPreviewOpen(true);
                                            setSelectedForPreview(x);
                                        }}>{x.name}</Button>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Paper>
                </Paper>
            </Paper>
            {documentsOpen && documentsBlock({visit_id: visit.id, department_id: visit.department_id, onClose: () => setDocumentsOpen(false)})}
            {previewOpen && selectedForPreview !== undefined && <Preview name={selectedForPreview.name} text={selectedForPreview.data} onClose={() => {setPreviewOpen(false); setSelectedForPreview(undefined);}} onPrint={(text) => {
                setDataToPrint(text);

                setTimeout(() => {
                    handlePrint();
                    setPreviewOpen(false); 
                    setSelectedForPreview(undefined);
                }, 500);
            }} />}
            {clientEditOpen && <ClientEdit client_id={visit.client_id} onClose={() => setClientEditOpen(false)} />}
            {petEditOpen && <PetEdit client_id={visit.client_id} pet_id={visit.patient_id} onClose={() => setPetEditOpen(false)} />}
            <ComponentToPrint ref={componentRef as any} text={dataToPrint}/>
        </>
    );
}

export default ReceptionView;