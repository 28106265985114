import HttpClient from "hex/http/client";


export const uploadFile = async (client: HttpClient, imageFile?: any): Promise<string> => {
    try
    {
        const localVarFormParams = new FormData();

        if (imageFile !== undefined) 
        {
            localVarFormParams.append('ImageFile', imageFile);
    
            var result : string = await client.postFile(`/api/Files/`, localVarFormParams);

            return result;
        }
    }
    catch (error)
    {
        alert(JSON.stringify(error));
    }

    return "<unknown>";
}
