import React from 'react';
import Title from 'hex/components/title'
import { Box, Paper, Typography, Zoom } from '@mui/material';
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import ReceptionCard from './reception_card';
import Document from './document';
import MakeVisit from './actions/make_visit';
import { dateFormat, timeFormat } from './actions/steps/date';

import {useTakeVisitsSubscription, useChangeVisitStatusAndSetVisitTimeMutation} from 'gql'
import { useErrorChecking } from 'hex/components/material/hooks';
import Loading from 'hex/components/loading';

import moment from 'moment-timezone';
import 'moment/locale/ru';
import { dateDiff } from 'hex/utils/dates';
import SelectDate from './actions/date';
import DeleteVisit from './actions/delete';
import { VisitStatus } from 'config';

import MoveTo from '../doctor/move_to';

const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// Props:
type IndexPageProps = {

};

function initials(str: string) {
    try
    {
        return str.split(/\s+/).map((w,i) => i ? w.substring(0,1).toUpperCase() + '.' : w).join(' ');
    }
    catch (error)
    {
        return str;
    }
  }

const IndexPage: React.FunctionComponent<IndexPageProps> = () => {
    const [makeVisitOpen, setMakeVisitOpen] = React.useState<boolean>(false);
    const [dateOpen, setDateOpen] = React.useState<boolean>(false);
    const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);
    const [changeDateOpen, setChangeDateOpen] = React.useState<boolean>(false);

    const [currentDate, setCurrentDate] = React.useState<string>(moment(Date.now()).format(dateFormat));
    const [selectedId, setSelectedId] = React.useState<number>(0);

    const [generateDocumentOpen, setGenerateDocumentOpen] = React.useState<boolean>(false);

    const isEditingIsDisabled = moment(currentDate, dateFormat).diff(moment(Date.now()), 'days') < 0;

    const { data, loading, error } = useTakeVisitsSubscription({
        variables: {
            date: currentDate
        },
    });

    const [changeVisitStatusMutation, visitStatusData] = useChangeVisitStatusAndSetVisitTimeMutation();

    useErrorChecking(error);
    useErrorChecking(visitStatusData.error);

    const selectedVisit = React.useMemo(() => {
        if ((data === undefined) || (selectedId === 0))
            return undefined;

        let outputModel = undefined;

        for (let index = 0; index < data.Department.length; index++) {
            const department = data.Department[index];
            
            for (let jndex = 0; jndex < department.Visits.length; jndex++) {
                const element = department.Visits[jndex];
                
                if (selectedId === element.id)
                {
                    outputModel = element;
                    break;
                }
            }

            if (outputModel !== undefined)
                break;
        }

        return outputModel;
    }, [selectedId, data]);

    const renderDepartments = () => {
        if (data === undefined)
            return (<></>);

        return data.Department.map((department) => (
            <Box
                key={department.id}
                sx={{
                    p: 1,
                    m: 1,
                    color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                    border: '1px solid',
                    borderColor: (theme) => theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                    borderRadius: 2,
                    fontSize: '0.875rem',
                    fontWeight: '700',
                    bgcolor: 'grey.200',
                    width: '500px',
                }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Paper sx={{p: 1, m: 1, bgcolor: 'primary.main', color: 'common.white', textAlign: 'center'}}>{department.name}</Paper>
                        </Grid>
                        {
                            department.Visits.map((visit) => 
                            (
                                <Grid item xs={12} key={visit.id}>
                                    <ReceptionCard 
                                        time={moment.utc(visit.time, timeFormat).tz(clientTimeZone).format('HH:mm')}
                                        doctor={visit.Doctor === null || visit.Doctor === undefined ? "<Врач не указан>" : initials(visit.Doctor.Name)}
                                        animal={visit.Pet.name}
                                        animalType={visit.Pet.PetType.name}
                                        animalAge={visit.Pet.birthdate ? dateDiff(new Date(visit.Pet.birthdate), new Date(Date.now())) : '<Не указан>'}
                                        client={visit.Client.name}
                                        phone={visit.Client.phone_number}
                                        isDisabled={isEditingIsDisabled || (visit.status === VisitStatus.Done) || (visit.status === VisitStatus.InProgress)}
                                        status={visit.status}
                                        hasDoctor={!(visit.Doctor === null || visit.Doctor === undefined)}
                                        withoutTime={visit.without_time}
                                        onDelete={() => {
                                            setSelectedId(visit.id);
                                            setDeleteOpen(true);
                                        }}
                                        onTime={() => {
                                            setSelectedId(visit.id);
                                            setChangeDateOpen(true);
                                        }}
                                        onDocument={() => {
                                            setGenerateDocumentOpen(true);
                                            setSelectedId(visit.id);
                                        }}
                                        onChangeStatus={visit.status === VisitStatus.Created ? () => {
                                            changeVisitStatusMutation({variables: {
                                                id: visit.id,
                                                status: VisitStatus.InCenter,
                                                visit_start: moment(new Date(Date.now())).format(timeFormat)
                                            }})
                                        } : undefined}
                                        onReturn={!isEditingIsDisabled && (visit.status === VisitStatus.Done) ? () => {
                                            changeVisitStatusMutation({variables: {
                                                id: visit.id,
                                                status: VisitStatus.InProgress
                                            }})
                                        } : undefined}
                                        canBeTurned={!isEditingIsDisabled && (visit.status === VisitStatus.Done)}
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>
            </Box>
        ));
    }

    const renderBody = () =>
    {
        if (data === undefined)
            return (<></>);
        
        if (data.Department.length === 0)
            return (<Typography variant='h6'>Нет ни одной записи о приеме</Typography>)
        
        return (
            <Box sx={{display: 'flex', flexWrap: 'nowrap', minWidth: '100%', width: 'fit-content', p: 1, bgcolor: 'grey.200'}}>
                {renderDepartments()}
            </Box>
        )
    }

    return (
        <React.Fragment>
            <Title title='Главная страница: регистратура'/>
            <Loading loading={loading || visitStatusData.loading} />
            <h1>Регистратура</h1>

            {renderBody()}

            <Zoom in={true} timeout={1000} style={{ transitionDelay: '100ms'}} unmountOnExit>
                <Fab sx={{ position: 'absolute', bottom: 50, right: 50}} color='secondary' onClick={() => setMakeVisitOpen(true)}>
                    <AddIcon />
                </Fab>
            </Zoom>
            <Zoom in={true} timeout={1000} style={{ transitionDelay: '100ms'}} unmountOnExit>
                <Fab sx={{ position: 'absolute', bottom: 50, right: 120}} color='primary' onClick={() => setDateOpen(true)}>
                    <CalendarTodayIcon />
                </Fab>
            </Zoom>

            {makeVisitOpen && <MakeVisit open={makeVisitOpen} close={() => setMakeVisitOpen(false)}></MakeVisit>}
            {dateOpen && <SelectDate open={dateOpen} close={() => setDateOpen(false)} date={currentDate} setDate={setCurrentDate} />}
            {deleteOpen && selectedId !== 0 && <DeleteVisit open={deleteOpen} close={() => setDeleteOpen(false)} visit_id={selectedId} />}
            {changeDateOpen && selectedId !== 0 && selectedVisit !== undefined && <MoveTo forwardDisable open={changeDateOpen} onDone={() => setChangeDateOpen(false)} onCancel={() => setChangeDateOpen(false)} visit_id={selectedId} />}
            {generateDocumentOpen && selectedId !== 0 && selectedVisit !== undefined && <Document disabled={isEditingIsDisabled || (selectedVisit.status === VisitStatus.Done)} onClose={() => {setGenerateDocumentOpen(false); setSelectedId(0)}} visit_id={selectedId} />}
        </React.Fragment>
    )
}


export default IndexPage;