import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useAddSchemaMedicinesMutation, useAddSchemaMutation, useAddSchemaServicesMutation, useGetSchemasForCategoryCountSubscription, useGetSchemasForCategorySubscription } from 'gql';
import { useErrorAndLoadChecking } from 'hex/components/material/hooks';

import HexTable from 'hex/components/material/table'

import { HeadCell } from 'hex/components/material/table/types';
import { useOperations, usePagination, useSorting } from 'hex/components/material/hooks';

import { FullSchema } from './types';
import { OperationType } from 'hex/components/material/types';
import Operations from 'hex/components/material/operations';

import {CreateOperation, DeleteOperation, DeleteSelectedOperation, UpdateOperation} from './schemasOperations'



type SchemasProps = {
    selectedID: Array<number>;
}


const headCells : HeadCell<FullSchema>[] = [
    {
        id: "name",
        label: "Наименование",
        sort: true,
        render: (schema) => (<React.Fragment>{schema.name}</React.Fragment>)
    }
];

const Schemas: React.FunctionComponent<SchemasProps> = ({selectedID}) => {
    const [pagination, setPagination] = usePagination();
    const [order, onSetOrder] = useSorting<FullSchema>({orderBy: "name", order: "asc"});
    const operationsArray = React.useMemo<Array<OperationType<FullSchema>>>(() => {
        if (selectedID.length !== 1) // Если выделено несколько, то создать не даем
            return [
                UpdateOperation(),
                DeleteOperation(),
                DeleteSelectedOperation(),
            ];

        return [
            CreateOperation(() => {
                return {
                    id: 0, 
                    name: "",
                    category_id: selectedID[0], // Будем брать корневой
                    SchemaMedicines: [],
                    SchemaServices: []
                }
            }),
            UpdateOperation(),
            DeleteOperation(),
            DeleteSelectedOperation(),
        ];
    }, [selectedID]);
    const operations = useOperations<FullSchema>(operationsArray);

    // Made copy:
    const [addSchemaMutation, addSchemaData] = useAddSchemaMutation();
    const [addMedicinesMutation, addMedicinesData] = useAddSchemaMedicinesMutation();
    const [addServicesMutation, addServicesData] = useAddSchemaServicesMutation();

    useErrorAndLoadChecking(addSchemaData);
    useErrorAndLoadChecking(addMedicinesData);
    useErrorAndLoadChecking(addServicesData);

    const fixedActions = React.useMemo(() => {
        return [{
            label: "Копировать",
            enabled: true,
            action: async (model: FullSchema) => {
                const schemaModel = await addSchemaMutation({variables: {
                    name: `${model.name} (копия)`,
                    category_id: model.category_id,
                }});

                if (schemaModel.errors !== undefined)
                    return;

                const schema_id = schemaModel.data?.insert_Schema?.returning[0].id;

                await addMedicinesMutation({variables: {
                    objects: model.SchemaMedicines.map((medicine) => ({
                        medicine_id: medicine.medicine_id,
                        schema_id: schema_id,
                        value: medicine.value
                    })),
                }});

                await addServicesMutation({variables: {
                    objects: model.SchemaServices.map((service) => ({
                        schema_id: schema_id,
                        price: service.price,
                        service_id: service.service_id,
                        value: service.value,
                    })),
                }});
            }
        }, ...operations.actions];
    }, [operations])

    const { data, loading, error } = useGetSchemasForCategorySubscription({
        variables: {
            ids: selectedID,
        }
    });

    const countResult = useGetSchemasForCategoryCountSubscription({
        variables: {
            ids: selectedID,
        }
    });

    useErrorAndLoadChecking({loading, error});
    useErrorAndLoadChecking(countResult);

    const models : FullSchema[] | undefined = (data === undefined) ? undefined : data.Schema as FullSchema[];

    var count = React.useMemo<number>(() => {
        if ((countResult.data !== undefined) && (countResult.data.Schema_aggregate.aggregate !== undefined) && (countResult.data.Schema_aggregate.aggregate !== null))
            return countResult.data.Schema_aggregate.aggregate.count;
        
        return 0;
    }, [countResult]);

    if (data === undefined)
        return (
            <>
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>
            </>
        )

    return (
        <React.Fragment>
            <HexTable {...pagination} order={order.order} orderBy={order.orderBy as keyof FullSchema} 
                      count={count} onPageChange={setPagination} models={models} headCells={headCells} onOrderSet={onSetOrder}
                      globalActions={operations.globalActions} selectedActions={operations.selectedActions} actions={fixedActions}
            /> 
            <Operations args={operationsArray} eventIndex={operations.eventIndex} model={operations.model} selectedModels={operations.selectedModels} close={operations.close} />
        </React.Fragment>
    )
}

export default Schemas;