import React from 'react';
import Modal from 'hex/components/material/modal'

import TextFilter from 'hex/components/material/operations/filters/text_filter';

export type FiltersType = {
    Name: string;
    Login: string;
};

// Props:
type FilterProps = {
    open: boolean;
    close: () => void;
    filters: FiltersType;
    setFilters: (model: FiltersType) => void;
};

export const defaultModel : FiltersType = {Name: "", Login: ""}


const Filter: React.FunctionComponent<FilterProps> = ({open, close, filters, setFilters}) => {
    const [model, setModel] = React.useState<FiltersType>(filters);

    // const { data, loading, error } = useGetRolesQuery({fetchPolicy: 'network-only'});

    const filterAction = () => {
        setFilters(model);
        close();
    }

    return (
        <>
            <Modal
                open={open}
                title='Фильтры'
                contentTitle='Выбрать параметры фильтрации'
                closeButtonText='Сбросить'
                closeButtonAction={() => {
                    setFilters(defaultModel);
                    setModel(defaultModel);
                    close();
                }}
                actionButtonText='Применить'
                actionButtonAction={filterAction}
                maxWidth='xl'
            >
                <TextFilter label='ФИО' value={model.Name} onChange={(value) => {
                        setModel((oldModel) => {
                            let copy = {...oldModel};

                            copy.Name = value;

                            return copy;
                        });
                    }
                } />

                <TextFilter label='Логин' value={model.Login} onChange={(value) => {
                        setModel((oldModel) => {
                            let copy = {...oldModel};

                            copy.Login = value;

                            return copy;
                        });
                    }
                } />
            </Modal>
        </>
    )
}

export default Filter;