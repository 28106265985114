import React from 'react';

import ListItemButton from '@mui/material/ListItemButton';

import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";


type NavLinkProps = {
    to: string;
    exact?: boolean;
    children?: React.ReactNode;
};

const NavLink: React.FunctionComponent<NavLinkProps> = ({to, exact, children}) => {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <ListItemButton onClick={() => {navigate(to)}} selected={(exact === true ? location.pathname === to : location.pathname.startsWith(to))}>
            {children}
        </ListItemButton>
    );
}

export default NavLink;