import Handlebars from 'handlebars';

// Юзать так: https://nodejsdev.ru/guides/webdraftt/templates/
export function proceedDocument(template: string, predefinedVariables: Record<string, string>, variables: Record<string, string>) : string
{
    var compiledTemplate = Handlebars.compile(template);

    return compiledTemplate({
        ...predefinedVariables,
        ...variables,
    });
}
