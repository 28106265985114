import React from 'react'
import { OperationType } from 'hex/components/material/types'
import { TableCard } from './types'

import AddIcon from '@mui/icons-material/Add';
import Create from 'hex/components/material/operations/create';
import Update from 'hex/components/material/operations/update';
import { ApolloError } from '@apollo/client';
import { ValidationResultData, Validations, ValidatorCallback } from 'hex/hooks/validator';
import { NumberNotZero } from 'hex/validations';

import {useAddDoctorsCardMutation, useUpdateDoctorsCardMutation, useDeleteDoctorsCardMutation, useDeleteDoctorsCardByIdsMutation} from 'gql'
import Delete from 'hex/components/material/operations/delete';
import { DeleteForever } from '@mui/icons-material';
import DeleteSelected from 'hex/components/material/operations/delete_selected';
import ForeignKeyModal from 'hex/components/material/operations/fields/foreign_key_modal';

import {RenderMedicineModel, RenderSelectedMedicineModel, useMedicineModel, useMedicineSearch} from './foreign_keys'


export const validations : Validations<TableCard> = [
    ["medicine_id", NumberNotZero()],
]

export const renderFields = (model: TableCard, setFieldValue: (field: keyof TableCard) => (value: any) => void, validator: ValidatorCallback<TableCard>, validation: ValidationResultData<TableCard>) => {
    return (
        <>
            <ForeignKeyModal label='Препарат' value={model.medicine_id} onChange={setFieldValue("medicine_id")} renderModel={RenderMedicineModel} renderSelectedModel={RenderSelectedMedicineModel} useSearch={useMedicineSearch} useModel={useMedicineModel} />
        </>
    );
}

const renderDeleteText = (model: TableCard) => {
    return `Вы действительно уверены, что хотите удалить ${model.Medicine.name} из карточки?`;
}

const renderDeleteSelectedTitle = () => {
    return `Вы действительно уверены, что хотите удалить следующие препараты из карточки?`;
}

const renderDeleteSelectedText = (model: TableCard) => {
    return `${model.Medicine.name}`;
}

export function createMutation(model: TableCard)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useAddDoctorsCardMutation({variables: {
        medicine_id: model.medicine_id,
    }});
}

export function updateMutation(model: TableCard)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useUpdateDoctorsCardMutation({
        variables: {
            id: model.id,
            medicine_id: model.medicine_id
        }
    })
}

function deleteMutation(model: TableCard)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useDeleteDoctorsCardMutation({
        variables: {
            id: model.id,
        }
    });
}

function deleteByIdsMutation(models: Array<TableCard>)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useDeleteDoctorsCardByIdsMutation({
        variables: {
            ids: models.map((x) => x.id),
        }
    });
}

const translateError = (error: ApolloError) => {
    return error.message;
}

export const defaultModel : TableCard = 
{
    id: 0, 
    medicine_id: 0,
    Medicine: {
        id: 0,
        name: "",
        price: 0,
    }
};

export function CreateOperation() : OperationType<TableCard>
{
    return {
        type: 'global',
        label: 'Создать',
        icon: (<AddIcon />),
        render: (close) => (
            <Create 
                close={close} 
                defaultModel={defaultModel} 
                translateError={translateError} 
                validations={validations} 
                renderFields={renderFields} 
                useMutation={createMutation} 
            />
        ),
    }
}

export function UpdateOperation() : OperationType<TableCard>
{
    return {
        type: 'individual',
        label: 'Изменить',
        enabled: true,
        render: (model, close) => (
            <Update 
                close={close} 
                defaultModel={model} 
                translateError={translateError}
                validations={validations}
                renderFields={renderFields}
                useMutation={updateMutation}
            />
        ),
    }
}

export function DeleteOperation() : OperationType<TableCard>
{
    return {
        type: 'individual',
        label: 'Удалить',
        enabled: true,
        render: (model, close) => (
            <Delete 
                close={close} 
                model={model}
                translateError={translateError}
                renderText={renderDeleteText}
                useMutation={deleteMutation}
            />
        ),
    }
}

export function DeleteSelectedOperation() : OperationType<TableCard>
{
    return {
        type: 'selected',
        label: 'Удалить выбранное',
        icon: (<DeleteForever />),
        render: (models, close) => (
            <DeleteSelected
                close={close} 
                models={models}
                translateError={translateError}
                renderTitle={renderDeleteSelectedTitle}
                renderText={renderDeleteSelectedText}
                useMutation={deleteByIdsMutation}
            />
        ),
    }
}