import React from 'react';
import Title from 'hex/components/title'
import Loading from 'hex/components/loading'

import HexTable from 'hex/components/material/table'

import { useSnackbar } from 'notistack';
import { HeadCell } from 'hex/components/material/table/types';
import { useOperations, usePagination, useSorting } from 'hex/components/material/hooks';
import { TableCard } from './types';
import { OperationType } from 'hex/components/material/types';
import Operations from 'hex/components/material/operations';

import {CreateOperation, DeleteOperation, DeleteSelectedOperation, UpdateOperation} from './operations'
import {useDoctorsCardIndexSubscription, useDoctorsCardIndexCountSubscription} from 'gql'

type IndexProps = {

}

const headCells : HeadCell<TableCard>[] = [
    {
        id: "medicine_id",
        label: "Препарат",
        sort: true,
        render: (card) => (<React.Fragment>{card.Medicine.name}</React.Fragment>)
    },
];

const Index : React.FunctionComponent<IndexProps> = ({}) => {
    // eslint-disable-next-line
    const { enqueueSnackbar } = useSnackbar();

    const [pagination, setPagination] = usePagination();
    const [order, onSetOrder] = useSorting<TableCard>({orderBy: "medicine_id", order: "asc"});
    const operationsArray = React.useMemo<Array<OperationType<TableCard>>>(() => {
        return [
            CreateOperation(),
            UpdateOperation(),
            DeleteOperation(),
            DeleteSelectedOperation(),
        ];
    }, []);
    const operations = useOperations<TableCard>(operationsArray);

    const { data, loading, error } = useDoctorsCardIndexSubscription({variables: { offset: pagination.offset, limit: pagination.limit, order_by: { [order.orderBy]: order.order, }, }});
    const countResult = useDoctorsCardIndexCountSubscription();

    const models : TableCard[] | undefined = (data === undefined) ? undefined : data.DoctorsCard;

    var count = React.useMemo<number>(() => {
        if ((countResult.data !== undefined) && (countResult.data.DoctorsCard_aggregate.aggregate !== undefined) && (countResult.data.DoctorsCard_aggregate.aggregate !== null))
            return countResult.data.DoctorsCard_aggregate.aggregate.count;
        
        return 0;
    }, [countResult]);

    React.useMemo(() => {
        if (error !== undefined)
        {
            enqueueSnackbar(`Ошибка получения данных! ${error.message}`, {variant: 'error'});
        }
        else if (countResult.error !== undefined)
        {
            enqueueSnackbar(`Ошибка получения данных! ${countResult.error.message}`, {variant: 'error'});
        }
    }, [error, enqueueSnackbar, countResult]);
    

    return (
        <React.Fragment>
            <Title title='Позиции в карточке'/>
            <Loading loading={loading || countResult.loading} />
            <h1>Позиции в карточке</h1>
            <HexTable {...pagination} order={order.order} orderBy={order.orderBy as keyof TableCard} 
                      count={count} onPageChange={setPagination} models={models} headCells={headCells} onOrderSet={onSetOrder}
                      globalActions={operations.globalActions} selectedActions={operations.selectedActions} actions={operations.actions}
            /> 
            <Operations args={operationsArray} eventIndex={operations.eventIndex} model={operations.model} selectedModels={operations.selectedModels} close={operations.close} />
        </React.Fragment>
    )
}

export default Index;