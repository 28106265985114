import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, FormGroup, Grid, Paper, Slide, Switch, TextField, Typography, useTheme } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { CheckPatientCard, GetPatientCardName, MedicinesReceptionVisitType, PatientCard, ServicesReceptionVisitType, VisitType, defaultPatientCardValue } from './types';

import moment from 'moment-timezone';
import 'moment/locale/ru';

import { dateDiff } from 'hex/utils/dates';
import { PetSexToString } from 'pages/pets/types';

import { useGetReceptionMedicinesAndServicesQuery, useSaveVisitMutation, useGetMedicineUnitsQuery, useGetDoctorsServicesSubscription } from 'gql'
import { useErrorAndLoadChecking, useErrorChecking } from 'hex/components/material/hooks';
import Loading from 'hex/components/loading';
import VisitHistory from './visit_history';
import ListData from './list_data';
import { VisitStatus } from 'config';
import MoveTo from './move_to';
import FilesUploader from './files_uploader';

import ListDataMed from './list_data_med'

import { timeFormat } from './steps/date';
import Schemas from './schemas';
import { AsyncWrappedButton } from 'hex/utils/buttons';
import ReceptionsList from './receptions_list';
import { useAuth } from 'hex/hooks/auth';
import Templates from './template';
import { useSnackbar } from 'notistack';
import useSavedValue from 'hex/cache/hooks';
import VisitHistoryStack from './visit_history_stack';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type VisitDetailProps = {
    open: boolean;
    close: () => void;
    visit: VisitType;
    reception_id: number;
};

type DescriptionState = {
    internal_recomendation: string;
    patient_recomendation: string;
}

function modelIsNotEmpty(value: any): boolean {
    return (value !== undefined) && (value !== null);
}

function GetRecomendationsInitialState(visit: VisitType): DescriptionState {
    if (modelIsNotEmpty(visit.current_reception_id) && modelIsNotEmpty(visit.CurrentReception))
        return visit.CurrentReception as DescriptionState;

    return { internal_recomendation: "", patient_recomendation: "" };
}

const VisitDetail: React.FunctionComponent<VisitDetailProps> = ({ open, close, visit, reception_id }) => {
    const theme = useTheme();
    const auth = useAuth();

    const {enqueueSnackbar} = useSnackbar();
    const [patientCard, setPatientCard] = React.useState<PatientCard>(CheckPatientCard(visit.patient_card) ? visit.patient_card : defaultPatientCardValue);
    const [textState, setTextState] = React.useState<DescriptionState>(GetRecomendationsInitialState(visit));

    const [services, setServices] = React.useState<ServicesReceptionVisitType[]>([]);
    const [medicines, setMedicines] = React.useState<MedicinesReceptionVisitType[]>([]);

    const [moveOpen, setMoveOpen] = React.useState<boolean>(false);
    const [schemasOpen, setSchemasOpen] = React.useState<boolean>(false);
    const [receptionsOpen, setReceptionsOpen] = React.useState<boolean>(false);
    const [templatesOpen, setTemplatesOpen] = React.useState<boolean>(false);

    const [fakeIndex, setFakeIndex] = React.useState<number>(-10000);

    const [disableButtons, setDisableButtons] = React.useState<boolean>(false);
    const [visitViewState, setVisitViewState] = useSavedValue<boolean>("visit-view-state-" + auth.getUserId(), false);

    const { data, loading, error } = useGetReceptionMedicinesAndServicesQuery({
        variables: {
            reception_id: reception_id
        },
        fetchPolicy: 'network-only'
    });

    const medicineUnitsData = useGetMedicineUnitsQuery({ fetchPolicy: 'network-only' });

    const doctorsServicesData = useGetDoctorsServicesSubscription({
        variables: {
            id: visit.id,
            doctor_id: auth.getUserId(),
            currentReceptionID: visit.current_reception_id ? visit.current_reception_id : undefined,
        }
    })

    const [saveVisitMutation, saveVisitMutationData] = useSaveVisitMutation();

    useErrorChecking(saveVisitMutationData.error);
    useErrorChecking(medicineUnitsData.error);
    useErrorChecking(error);

    useErrorAndLoadChecking(doctorsServicesData);

    const doctorsServicesList = React.useMemo(() => {
        let result : Array<{price: any, value: any}> = [];

        if (!doctorsServicesData.data)
            return undefined;

        doctorsServicesData.data.Visit[0].ReceptionsList.forEach(element => result = result.concat(element.Services));

        return result;
    }, [doctorsServicesData])

    React.useEffect(
        () => {
            if (data !== undefined) {
                setServices(data.ReceptionServices);
                setMedicines(data.ReceptionMedicines);
            }
        },
        [data, setServices, setMedicines]
    );

    const renderPatientCard = (from: number, to: number) => {
        const fields = Object.keys(defaultPatientCardValue);

        return fields.slice(from, to).map((fieldName, index) => {
            const onPatientCardChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setPatientCard((oldValue) => {
                    var copy = { ...oldValue };

                    (copy as any)[fieldName] = event.target.value;

                    return copy;
                })
            }

            return (
                <Box sx={{ m: 0, width: "100%" }} key={index}>
                    <TextField margin="dense" label={GetPatientCardName(fieldName as keyof PatientCard)} type="text" fullWidth onChange={onPatientCardChange} value={patientCard[fieldName as keyof PatientCard]} />
                </Box>
            )
        });
    }

    const onPostponeVisit = async (disableClose?: boolean) => {
        if (!modelIsNotEmpty(visit.current_reception_id))
            return;

        setDisableButtons(true);
        
        const result = await saveVisitMutation({
            variables: {
                id: visit.id,
                patient_card: patientCard,
                reception_id: visit.current_reception_id,
                internal_recomendation: textState.internal_recomendation,
                patient_recomendation: textState.patient_recomendation,
                services: services.filter((obj) => obj.id <= 0).map((service) => ({ reception_id: visit.current_reception_id, service_id: service.service_id, price: service.price, value: service.value })),
                medicines: medicines.filter((obj) => obj.id <= 0).map((medicine) => ({ reception_id: visit.current_reception_id, medicine_id: medicine.medicine_id, value: medicine.value, done: medicine.done })),
                status: visit.status,
            }
        });

        if (result.errors !== undefined)
        {
            setDisableButtons(false);
            return;
        }

        if (!disableClose)
            close();

        setDisableButtons(false);
    }

    const onMoveToVisit = async () => {
        if (!modelIsNotEmpty(visit.current_reception_id))
            return;

        setDisableButtons(true);

        const result = await saveVisitMutation({
            variables: {
                id: visit.id,
                patient_card: patientCard,
                reception_id: visit.current_reception_id,
                internal_recomendation: textState.internal_recomendation,
                patient_recomendation: textState.patient_recomendation,
                services: services.filter((obj) => obj.id <= 0).map((service) => ({ reception_id: visit.current_reception_id, service_id: service.service_id, price: service.price, value: service.value })),
                medicines: medicines.filter((obj) => obj.id <= 0).map((medicine) => ({ reception_id: visit.current_reception_id, medicine_id: medicine.medicine_id, value: medicine.value, done: medicine.done })),
                status: visit.status,
                end_time: moment(new Date(Date.now())).format(timeFormat)
            }
        });

        
        if (result.errors !== undefined)
        {
            setDisableButtons(false);
            return;
        }

        setMoveOpen(true);
        setDisableButtons(false);
    }

    const onEndVisit = async () => {
        if (!modelIsNotEmpty(visit.current_reception_id))
            return;

        setDisableButtons(true);

        const result = await saveVisitMutation({
            variables: {
                id: visit.id,
                patient_card: patientCard,
                reception_id: visit.current_reception_id,
                internal_recomendation: textState.internal_recomendation,
                patient_recomendation: textState.patient_recomendation,
                services: services.filter((obj) => obj.id <= 0).map((service) => ({ reception_id: visit.current_reception_id, service_id: service.service_id, price: service.price, value: service.value })),
                medicines: medicines.filter((obj) => obj.id <= 0).map((medicine) => ({ reception_id: visit.current_reception_id, medicine_id: medicine.medicine_id, value: medicine.value, done: medicine.done })),
                status: VisitStatus.Done,
                end_time: moment(new Date(Date.now())).format(timeFormat)
            }
        });

        if (result.errors !== undefined)
        {
            setDisableButtons(false);
            return;
        }

        close();
        setDisableButtons(false);
    }

    return (
        <Dialog
            open={open}
            onClose={close}
            TransitionComponent={Transition}
            fullScreen
            PaperProps={{
                style: {
                    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800],
                }
            }}
        >
            <Loading loading={saveVisitMutationData.loading || loading || medicineUnitsData.loading} />
            <DialogTitle>{`Прием пациента: ${visit.Client.name}`}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ marginBottom: 1 }}>{'Проведение приема'}</DialogContentText>
                <Divider sx={{ marginBottom: 3 }} />
                <Grid container spacing={2}>
                    <Grid item sx={{ flexBasis: '33.33333333333%' }}>
                        <Paper sx={{ p: 1, height: '100%' }}>
                            <Typography variant='h6'>Карточка пациента:</Typography>
                            <Typography variant='body1'>Владелец: <b>{visit.Client.name}</b></Typography>
                            <Typography variant='body1'>Телефон: <b>{visit.Client.phone_number}</b></Typography>
                            <Typography variant='body1'>Кличка животного: <b>{visit.Pet.name}</b></Typography>
                            <Typography variant='body1'>Порода: <b>{visit.Pet.breed || '<Не указано>'}</b></Typography>
                            <Typography variant='body1'>Вид: <b>{visit.Pet.PetType.name}</b></Typography>
                            <Typography variant='body1'>Возраст: <b>{visit.Pet.birthdate ? dateDiff(new Date(visit.Pet.birthdate), new Date(Date.now())) : 'Не указан'}</b></Typography>
                            <Typography variant='body1'>Пол животного: <b>{PetSexToString(visit.Pet.sex)}</b></Typography>
                            <br />
                            <Typography variant='body1'>Назначил: <b><i>{visit.From.Name}</i></b></Typography>
                        </Paper>
                    </Grid>
                    <Grid item sx={{ flexBasis: '33.33333333333%' }}>
                        <Paper sx={{ p: 1 }}>
                            {renderPatientCard(0, 13)}
                        </Paper>
                    </Grid>
                    <Grid item sx={{ flexBasis: '33.33333333333%' }}>
                        <Paper sx={{ p: 1 }}>
                            {renderPatientCard(13, 26)}
                        </Paper>
                    </Grid>
                </Grid>
                <Box sx={{ marginTop: 3, marginBottom: 1 }}>
                    <Paper sx={{ p: 1 }}>
                        <Box sx={{ marginBottom: 3 }}>
                            <Typography variant='h6'>{'Действия:'}</Typography>
                        </Box>
                        <Box sx={{ marginBottom: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item flexBasis={'25%'}>
                                    <FormGroup>
                                        <Button variant="contained" component="label" onClick={() => setReceptionsOpen(true)}>
                                            Просмотр истории приемов
                                        </Button>
                                    </FormGroup>
                                </Grid>
                                <Grid item flexBasis={'25%'}>
                                    <FormGroup>
                                        <Button color='secondary' variant="contained" component="label" onClick={() => setSchemasOpen(true)}>
                                            Применить схему
                                        </Button>
                                    </FormGroup>
                                </Grid>
                                <Grid item flexBasis={'25%'}>
                                    <FormGroup>
                                        <Button color='info' variant="contained" component="label" onClick={() => setTemplatesOpen(true)}>
                                            Применить шаблон
                                        </Button>
                                    </FormGroup>
                                </Grid>
                                <Grid item flexBasis={'25%'}>
                                    <FormGroup>
                                        <FormControlLabel
                                            value="top"
                                            control={<Switch color="primary" checked={visitViewState} onChange={(event) => setVisitViewState(event.target.checked)} />}
                                            label="Приемы в одну страницу"
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>
                {/* ЗДЕСЬ БУДЕТ ИСТОРИЯ ПРИЕМОВ */}
                {!visitViewState && <VisitHistory visit_id={visit.id} current_reception_id={reception_id} />}
                {visitViewState && <VisitHistoryStack visit_id={visit.id} current_reception_id={reception_id} />}

                {/* ЗДЕСЬ БУДЕТ Назначение специалиста */}
                <Box sx={{ marginTop: 3, marginBottom: 1 }}>
                    <Paper sx={{ p: 1 }}>
                        <Box sx={{ marginBottom: 3 }}>
                            <Typography variant='h6'>{'Назначение специалиста:'}</Typography>
                        </Box>
                        <Box sx={{ marginBottom: 3 }}>
                            <TextField label="Примечание (для личной карточки пациента)" multiline rows={10} fullWidth value={textState.internal_recomendation} onChange={(event) => setTextState((oldState) => ({ internal_recomendation: event.target.value, patient_recomendation: oldState.patient_recomendation }))} />
                        </Box>
                        <Box sx={{ marginBottom: 3 }}>
                            <TextField label="Рекомендации (для пациента)" multiline rows={10} fullWidth value={textState.patient_recomendation} onChange={(event) => setTextState((oldState) => ({ patient_recomendation: event.target.value, internal_recomendation: oldState.internal_recomendation }))} />
                        </Box>
                    </Paper>
                </Box>

                {/* ЗДЕСЬ БУДЕТ Список услуг */}
                <ListData
                    data={services}
                    services={(data !== undefined) ? data.Service : []}
                    on_delete={async (model) => {
                        setServices(
                            (oldServices) => {
                                var copy = [...oldServices];

                                const index = copy.findIndex((element) => element.id === model.id);

                                if (index !== -1) {
                                    copy.splice(index, 1);
                                }

                                return copy;
                            }
                        );
                    }}
                    on_add={async (model) => {
                        setServices(
                            (oldServices) => {
                                var copy = [...oldServices];

                                copy.push(model);

                                return copy;
                            }
                        );
                    }}
                    on_update={async (model) => {
                        setServices(
                            (oldServices) => {
                                var copy = [...oldServices];

                                const index = copy.findIndex((element) => element.id === model.id);

                                if (index !== -1) {
                                    copy[index] = model;
                                }

                                return copy;
                            }
                        );
                    }}
                    doctorsServices={doctorsServicesList}
                />
                {/* ЗДЕСЬ БУДЕТ Список препаратов */}
                <ListDataMed
                    data={medicines}
                    medicines={(data !== undefined) ? data.Medicine : []}
                    on_delete={async (model) => {
                        setMedicines(
                            (oldMedicines) => {
                                var copy = [...oldMedicines];

                                const index = copy.findIndex((element) => element.id === model.id);

                                if (index !== -1) {
                                    copy.splice(index, 1);
                                }

                                return copy;
                            }
                        );
                    }}
                    on_add={async (model) => {
                        setMedicines(
                            (oldMedicines) => {
                                var copy = [...oldMedicines];

                                let index = copy.findIndex(x => x.medicine_id === model.medicine_id);
                                if (index === -1)
                                    copy.push(model);
                                else
                                {
                                    var elementCopy = { ...copy[index] };
                                    elementCopy.value += model.value;
                                    copy[index] = elementCopy;
                                }

                                return copy;
                            }
                        );
                    }}
                    on_update={async (model) => {
                        setMedicines(
                            (oldMedicines) => {
                                var copy = [...oldMedicines];

                                const index = copy.findIndex((element) => element.id === model.id);

                                if (index !== -1) {
                                    copy[index] = model;
                                }

                                return copy;
                            }
                        );
                    }}
                />

                <FilesUploader visit_id={visit.id} onBeforeDocumentOpen={async () => {
                    // перед открытием документа, нам нужно сохранить данные приема.
                    if (!modelIsNotEmpty(visit.current_reception_id))
                        return;
        
                    const result = await saveVisitMutation({
                        variables: {
                            id: visit.id,
                            patient_card: patientCard,
                            reception_id: visit.current_reception_id,
                            internal_recomendation: textState.internal_recomendation,
                            patient_recomendation: textState.patient_recomendation,
                            services: [],
                            medicines: [],
                            status: visit.status,
                        }
                    });
                }} />

                <Divider sx={{ marginBottom: 3, marginTop: 3 }} />

                {moveOpen && <MoveTo open={moveOpen} visit_id={visit.id} onDone={() => { setMoveOpen(false); close(); }} onCancel={() => setMoveOpen(false)} />}
                {schemasOpen && <Schemas open={schemasOpen} onClose={() => setSchemasOpen(false)} onAccept={(models) => {
                    var newFakeIndex = fakeIndex - 1;
                    const newServices : ServicesReceptionVisitType[] = models.SchemaServices.map(element => ({
                        id: --newFakeIndex,
                        service_id: element.service_id,
                        reception_id: 0,
                        price: element.price ? element.price : element.Service.price,
                        value: element.value,
                        Service: element.Service,
                    }));

                    const newMedicines : MedicinesReceptionVisitType[] = models.SchemaMedicines.map(element => ({
                        id: --newFakeIndex,
                        medicine_id: element.medicine_id,
                        reception_id: 0,
                        value: element.value,
                        Medicine: element.Medicine,
                        done: false,
                    }));

                    setFakeIndex(newFakeIndex);

                    setServices((oldServices) => {
                        var copy = [...oldServices];

                        copy = copy.concat(newServices);

                        return copy;
                    });

                    setMedicines((oldMedicines) => {
                        var copy = [...oldMedicines];

                        copy = copy.concat(newMedicines);

                        return copy;
                    });
                }} />}

                {visit.status !== VisitStatus.Done && <DialogActions>
                    <AsyncWrappedButton 
                        onClick={async () => await onPostponeVisit(false)}
                        renderElement={(onClick, ref) => (<Button onClick={onClick} ref={ref} disabled={disableButtons} variant="contained"  color="secondary">Отложить</Button>)}/>
                    
                    <AsyncWrappedButton 
                        onClick={onMoveToVisit}
                        renderElement={(onClick, ref) => (<Button onClick={onClick} ref={ref} disabled={disableButtons} variant="contained"  color="success">Направить на другого специалиста</Button>)} 
                    />

                    <AsyncWrappedButton 
                        onClick={onEndVisit}
                        renderElement={(onClick, ref) => (<Button onClick={onClick} ref={ref} disabled={disableButtons} variant="contained"  color="primary">Завершить прием</Button>)} 
                    />
                </DialogActions>}
                {visit.status === VisitStatus.Done && <DialogActions>
                    <Button variant="contained" onClick={close} color="secondary">Отмена</Button>
                    <AsyncWrappedButton 
                        onClick={async () => await onPostponeVisit(false)}
                        renderElement={(onClick, ref) => (<Button onClick={onClick} ref={ref} disabled={disableButtons} variant="contained"  color="primary">Сохранить</Button>)}/>
                    
                </DialogActions>}
                {receptionsOpen && <ReceptionsList pet_id={visit.patient_id} onClose={() => setReceptionsOpen(false)} onCopy={(model) => {
                    if (model.internal_recomendation !== "" || model.patient_recomendation !== "")
                        setTextState(
                            (prev) => 
                            {
                                var internal_recomendation = prev.internal_recomendation;

                                if (model.internal_recomendation)
                                {
                                    internal_recomendation = prev.internal_recomendation === "" ? model.internal_recomendation : prev.internal_recomendation + "\n" + model.internal_recomendation;
                                }

                                var patient_recomendation = prev.patient_recomendation;
                                if (model.patient_recomendation)
                                {
                                    patient_recomendation = prev.patient_recomendation === "" ? model.patient_recomendation : prev.patient_recomendation + "\n" + model.patient_recomendation;
                                }

                                return {
                                    internal_recomendation: internal_recomendation,
                                    patient_recomendation: patient_recomendation,
                                };
                            }
                        );
                    
                    var newFakeIndex = fakeIndex - 1;
                    const newServices : ServicesReceptionVisitType[] = model.Services.map(element => ({
                        id: --newFakeIndex,
                        service_id: element.service_id,
                        reception_id: 0,
                        price: element.price ? element.price : element.Service.price,
                        value: element.value,
                        Service: element.Service,
                    }));

                    const newMedicines : MedicinesReceptionVisitType[] = model.Medicines.map(element => ({
                        id: --newFakeIndex,
                        medicine_id: element.medicine_id,
                        reception_id: 0,
                        value: element.value,
                        Medicine: element.Medicine,
                        done: false,
                    }));

                    setFakeIndex(newFakeIndex);
                    
                    if (model.Services.length !== 0)
                        setServices((oldServices) => {
                            var copy = [...oldServices];

                            copy = copy.concat(newServices);

                            return copy;
                        });

                    if (model.Medicines.length !== 0)
                        setMedicines((oldMedicines) => {
                            var copy = [...oldMedicines];

                            copy = copy.concat(newMedicines);

                            return copy;
                        });

                    enqueueSnackbar("Скопировано!", {variant: "success"});
                }} />}
                <Templates onClose={() => setTemplatesOpen(false)} open={templatesOpen} onAccept={(model) => {
                    if (model.internal_recomendation || model.patient_recomendation)
                        setTextState(
                            (prev) => 
                            {
                                var internal_recomendation = prev.internal_recomendation;

                                if (model.internal_recomendation)
                                {
                                    internal_recomendation = prev.internal_recomendation === "" ? model.internal_recomendation : prev.internal_recomendation + "\n" + model.internal_recomendation;
                                }

                                var patient_recomendation = prev.patient_recomendation;
                                if (model.patient_recomendation)
                                {
                                    patient_recomendation = prev.patient_recomendation === "" ? model.patient_recomendation : prev.patient_recomendation + "\n" + model.patient_recomendation;
                                }

                                return {
                                    internal_recomendation: internal_recomendation,
                                    patient_recomendation: patient_recomendation,
                                };
                            }
                        );
                    
                    var newFakeIndex = fakeIndex - 1;
                    const newServices : ServicesReceptionVisitType[] = model.TemplateServices.map(element => ({
                        id: --newFakeIndex,
                        service_id: element.service_id,
                        reception_id: 0,
                        price: element.price ? element.price : element.Service.price,
                        value: element.value,
                        Service: element.Service,
                    }));

                    const newMedicines : MedicinesReceptionVisitType[] = model.TemplateMedicines.map(element => ({
                        id: --newFakeIndex,
                        medicine_id: element.medicine_id,
                        reception_id: 0,
                        value: element.value,
                        Medicine: element.Medicine,
                        done: false,
                    }));

                    setFakeIndex(newFakeIndex);
                    
                    if (model.TemplateServices.length !== 0)
                        setServices((oldServices) => {
                            var copy = [...oldServices];

                            copy = copy.concat(newServices);

                            return copy;
                        });

                    if (model.TemplateMedicines.length !== 0)
                        setMedicines((oldMedicines) => {
                            var copy = [...oldMedicines];

                            copy = copy.concat(newMedicines);

                            return copy;
                        });
                }} />
            </DialogContent>
        </Dialog>
    );
}

export default VisitDetail;