import moment from 'moment';
import { roundTimeQuarterHour } from '../registrator/actions/make_visit';
import { dateFormat, timeFormat } from './steps/date';
import {GetReceptionMedicinesAndServicesQuery, MakeOptional } from 'gql';

export type VisitType = 
{ 
    __typename?: 'Visit', 
    id: number, 
    time: any, 
    patient_id: number, 
    patient_card: any, 
    from_id: any, 
    doctor_id?: any | null, 
    department_id: number, 
    date: any, 
    current_reception_id?: number | null, 
    client_id: number, 
    status: number, 
    Client: 
    { 
        __typename?: 'Client', 
        name: string,
        phone_number: string,
    }, 
    Doctor?: 
    { 
        __typename?: 'Users', 
        Name: string 
    } | null, 
    Pet: 
    { 
        __typename?: 'Pet', 
        name: string, 
        birthdate?: any,
        sex: number, 
        breed?: string | null,
        PetType: 
        { 
            __typename?: 'PetType', 
            name: string 
        } 
    },
    From: 
    { 
        __typename?: 'Users', 
        Name: string 
    }, 
    CurrentReception?: 
    { 
        __typename?: 'Reception', 
        internal_recomendation: string, 
        patient_recomendation: string 
    } | null 
};

export type PatientCard = {
    temperature: string;
    weight: string;
    pressure: string;
    vactination: string;
    ecto_parasites: string;
    gelm_parasites: string;
    heart_freq: string;
    breath_freq: string;
    contains: string;
    another_animals: string;
    nutrition: string;
    complaints: string;
    symptoms: string;
    diagnosis: string;
    anamnesis: string;
    snk: string;
    general_state: string;
    abdomen: string;
    dehidration_degree: string;
    spleen: string;
    edema: string;
    lymph_nodes: string;
    cath_limb: string;
    cath_heart: string;
    diagnostics: string;
    diagnostics_recomended: string;
};
            

export const defaultPatientCardValue : PatientCard = {
    temperature: "",
    weight: "",
    pressure: "",
    vactination: "",
    ecto_parasites: "",
    gelm_parasites: "",
    heart_freq: "",
    breath_freq: "",
    contains: "",
    another_animals: "",
    nutrition: "",
    complaints: "",
    symptoms: "",
    diagnosis: "",
    anamnesis: "",
    snk: "",
    general_state: "",
    abdomen: "",
    dehidration_degree: "",
    spleen: "",
    edema: "",
    lymph_nodes: "",
    cath_limb: "",
    cath_heart: "",
    diagnostics: "",
    diagnostics_recomended: "",
}

export function CheckPatientCard(object : any)
{
    if (object.hasOwnProperty('temperature'))
        return true;
    
    return false;
}

export function GetPatientCardName(field: keyof PatientCard) {
    switch (field) {
        case "temperature":
            return "Температура";
        case "weight":
            return "Масса";
        case "pressure":
            return "Давление";
        case "vactination":
            return "Вакцинация";
        case "ecto_parasites":
            return "Обработка против эктопаразитов";
        case "gelm_parasites":
            return "Обработка против гельминтов";
        case "heart_freq":
            return "Частота сердечного ритма (ЧСС)";
        case "breath_freq":
            return "Частота дыхательных движений (ЧДД)";
        case "contains":
            return "Содержание";
        case "another_animals":
            return "Другие животные в непосредственном контакте";
        case "nutrition":
            return "Питание";
        case "complaints":
            return "Жалобы";
        case "diagnosis":
            return "Предварительный диагноз";
        case "symptoms":
            return "Симптомы";
        case "anamnesis":
            return "Анамнез";
        case "snk":
            return "СНК";
        case "general_state":
            return "Общее состояние";
        case "abdomen":
            return "Брюшная полость";
        case "dehidration_degree":
            return "Степень дегидратации";
        case "spleen":
            return "Слизистые";
        case "edema":
            return "Отеки";
        case "lymph_nodes":
            return "Лимфатические узлы";
        case "cath_limb":
            return "Аускультация легких";
        case "cath_heart":
            return "Аускультация сердца";
        case "diagnostics":
            return "Диагностические исследования";
        case "diagnostics_recomended":
            return "Рекомендуемые дополнительные исследования";
        default:
            return "<Не определено>";
    }
}

export type MoveToData = {
    date: string;
    time: string;
    department_id: number;
    doctor_id?: string;
    without_time: boolean;
};

export const defaultMoveTo : () => MoveToData = () => ({
    department_id: 0,
    doctor_id: undefined,
    date: moment(Date.now()).format(dateFormat),
    time: moment(roundTimeQuarterHour(new Date(Date.now()))).format(timeFormat),
    without_time: false
})

export type VisitDocumentDataType = { __typename?: 'VisitDocument', id: number, name: string, data: string };

export type ServicesReceptionVisitType = MakeOptional<GetReceptionMedicinesAndServicesQuery["ReceptionServices"][number], "Service" >;
export type MedicinesReceptionVisitType = MakeOptional<GetReceptionMedicinesAndServicesQuery["ReceptionMedicines"][number], "Medicine">;
export type ServiceVisitType = GetReceptionMedicinesAndServicesQuery["ReceptionServices"][number]["Service"];
export type MedicineVisitType = GetReceptionMedicinesAndServicesQuery["ReceptionMedicines"][number]["Medicine"];