import { Box, CircularProgress } from '@mui/material';
import { useGetClientForEditQuery } from 'gql';
import { useErrorAndLoadChecking } from 'hex/components/material/hooks';
import Modal from 'hex/components/material/modal';
import { useValidator } from 'hex/hooks/validator';
import { defaultModel, updateMutation as useUpdateMutation, validations, renderFields } from 'pages/clients/operations';
import { TableClient } from 'pages/clients/types';
import React from 'react'

type ClientEditProps = {
    client_id: number;
    onClose: () => void;
}

const ClientEdit : React.FC<ClientEditProps> = ({client_id, onClose}) => {
    const { data, loading, error } = useGetClientForEditQuery({fetchPolicy: 'network-only', variables: {
        id: client_id,
    }});

    const [client, setClient] = React.useState<TableClient>(defaultModel);
    
    const [updateMutation, updateMutationData] = useUpdateMutation(client);
    const [validation, validator, modelValidation] = useValidator<TableClient>(validations);

    React.useEffect(() => {
        if ((data !== undefined) && (data.Client.length !== 0))
        {
            setClient(data.Client[0]);
        }
    }, [data, setClient]);

    useErrorAndLoadChecking({loading, error});
    useErrorAndLoadChecking(updateMutationData);

    const setModelField = React.useCallback((field: keyof TableClient) => (value: any) => {
        setClient(oldModel => {
            var copy = {...oldModel};

            copy[field] = value as never;

            return copy;
        });
    }, [setClient]);

    const onSave = async () => {
        if (modelValidation(client))
        {
            await updateMutation();
            onClose();
        }
    }

    const renderBody = () => {
        if (data === undefined)
            return (
                <>
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>
                </>
            );
        return (
            <>
                {renderFields(client, setModelField, validator, validation)}
            </>
        )
    }

    return (
        <Modal open title='Редактирование клиента' contentTitle='Редактирование: ' closeButtonText='Отмена' closeButtonAction={onClose} actionButtonText='Сохранить' actionButtonAction={onSave} >
            {renderBody()}
        </Modal>
    )
}

export default ClientEdit;