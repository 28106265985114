import React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import LinearProgress from '@mui/material/LinearProgress'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChatIcon from '@mui/icons-material/Chat';

import {useLoading} from 'hex/hooks/loading'
import Chat from 'components/chat';


const drawerWidth: number = 240;
const drawerChatWidth: number = 360;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
    openChat?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open, openChat }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && !openChat && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    ...(openChat && !open && {
        marginRight: drawerChatWidth,
        width: `calc(100% - ${drawerChatWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    ...(open && openChat && {
        marginLeft: drawerWidth,
        marginRight: drawerChatWidth,
        width: `calc(100% - ${drawerWidth}px - ${drawerChatWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const ChatDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerChatWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: 0,
                [theme.breakpoints.up('sm')]: {
                    width: 0,
                },
            }),
        },
    }),
);

// Props:
type LayoutProps = {
    sidebar?: React.ReactNode
    children?: React.ReactNode
    layoutText: string
    toolbarData?: React.ReactNode
};

const Layout: React.FunctionComponent<LayoutProps> = ({ children, sidebar, layoutText, toolbarData }) => {
    const [open, setOpen] = React.useState(false);
    const [openChat, setOpenChat] = React.useState(false);

    // eslint-disable-next-line
    const [loading, _setLoading] = useLoading();

    const toggleDrawer = () => {
        setOpen(!open);
    };
    const toggleChatDrawer = () => {
        setOpenChat(!openChat);
    };

    if (sidebar !== undefined)
    {
        return (
            <Box sx={{ display: 'flex' }}>
                <AppBar position="absolute" open={open} openChat={openChat}>
                    <Toolbar sx={{ pr: '24px', }}>
                        <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer} sx={{ marginRight: '36px', ...(open && { display: 'none' }), }}>
                            <MenuIcon />
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            {layoutText}
                        </Typography>
                        {toolbarData}
                        <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleChatDrawer} sx={{ marginLeft: '36px', ...(openChat && { display: 'none' }), }}>
                            <ChatIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', px: [1], }}>
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    {sidebar}
                </Drawer>
                <Box component="main" sx={{ backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900], flexGrow: 1, height: '100vh', overflow: 'auto', }}>
                    <Toolbar />
                    <LinearProgress sx={{opacity: loading ? 1 : 0 }} />
                    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                        {children}
                    </Container>
                </Box>
                <ChatDrawer variant="permanent" open={openChat}>
                    <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', px: [1], }}>
                        <IconButton onClick={toggleChatDrawer}>
                            <ChevronRightIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <Chat open={openChat} />
                </ChatDrawer>
            </Box>
        );
    }
    else
    {
        return (
            <Box sx={{ display: 'flex' }}>
                <AppBar position="absolute" open={false}>
                    <Toolbar sx={{ pr: '24px', }}>
                        <IconButton disabled edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer} sx={{ marginRight: '36px', ...(open && { display: 'none' }), }}>
                            <MenuIcon />
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            {layoutText}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box component="main" sx={{ backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900], flexGrow: 1, height: '100vh', overflow: 'auto', }}>
                    <Toolbar />
                    <LinearProgress sx={{opacity: loading ? 1 : 0 }} />
                    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                        {children}
                    </Container>
                </Box>
            </Box>
        );
    }

}

export default Layout;