import Auth from 'hex/auth';

import { ApolloClient, createHttpLink, InMemoryCache, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { Theme } from '@mui/material';

import { SentryLink } from 'apollo-link-sentry';

export const AUTH_SERVER_PATH: string = import.meta.env.VITE_UNIVERSAL_AUTH_HOST;

export const auth = new Auth(AUTH_SERVER_PATH);

const httpLink = createHttpLink({
    uri: `${import.meta.env.VITE_UNIVERSAL_API_HOST}/v1/graphql`,
});

const wsLink = new GraphQLWsLink(createClient({
    url: `${import.meta.env.VITE_UNIVERSAL_API_WS_HOST}/v1/graphql`,
    connectionParams: () => {
        return {
            headers: {
                authorization: auth.getAccessToken() !== null ? `Bearer ${auth.getAccessToken()}` : "",
            }
        }
    }
}));

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink,
);

const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            authorization: auth.getAccessToken() !== null ? `Bearer ${auth.getAccessToken()}` : "",
        }
    }
});

export const client = new ApolloClient({
    link: authLink.concat(new SentryLink({
            uri: `${import.meta.env.VITE_UNIVERSAL_API_HOST}/v1/graphql`,
            attachBreadcrumbs: {
                includeQuery: true,
                includeVariables: true,
                includeFetchResult: true,
                includeError: true,
            }
        })).concat(splitLink),
    cache: new InMemoryCache()
});

export enum UserRoles {
    Developer = 'Разработчик',
    Administrator = 'Администратор',
    Doctor = 'Врач',
    Assistant = 'Ассистент',
    Registrator = 'Регистратор'
}

export const AccessMap = {
    usersPage: [UserRoles.Developer, UserRoles.Administrator],
    medicinesPage: [UserRoles.Developer, UserRoles.Administrator, UserRoles.Registrator],
    departmentsPage: [UserRoles.Developer, UserRoles.Administrator],
    servicesPage: [UserRoles.Developer, UserRoles.Administrator, UserRoles.Registrator],
    petsTypesPage: [UserRoles.Developer, UserRoles.Administrator],
    petsPage: [UserRoles.Developer, UserRoles.Administrator, UserRoles.Registrator, UserRoles.Doctor, UserRoles.Assistant],
    clientsPage: [UserRoles.Developer, UserRoles.Administrator, UserRoles.Registrator, UserRoles.Doctor, UserRoles.Assistant],
    receptionDocumentsPage: [UserRoles.Developer],
    documentsPage: [UserRoles.Developer],
    unitsPage: [UserRoles.Developer],
    receptionEdit: [UserRoles.Doctor, UserRoles.Assistant],
    schemas: [UserRoles.Developer, UserRoles.Administrator],
    cards: [UserRoles.Developer, UserRoles.Administrator, UserRoles.Doctor, UserRoles.Assistant],
    templates: [UserRoles.Doctor, UserRoles.Assistant],
};

export enum VisitStatus {
    // Создан:
    Created     = 0,
    // Перенаправлен:
    Redirected  = 1,
    // Прибыл в центр:
    InCenter    = 2,
    // На приеме:
    InProgress  = 3,
    // Окончен:
    Done        = 4,
}

export function TranslateStatus(status: VisitStatus) : string
{
    switch (status) {
        case VisitStatus.Created:
            return "Создан";
        case VisitStatus.Redirected:
            return "Перенаправлен";
        case VisitStatus.InCenter:
            return "Пациент находится в центре";
        case VisitStatus.InProgress:
            return "Начат приём";
        case VisitStatus.Done:
            return "Прием окончен";
        default:
            return "<Статус не определен>";
    }
}

export const getPaperColor = (theme: Theme, status: VisitStatus, hasDoctor: boolean = true) => {
    if (hasDoctor)
    {
        switch (status) {
            case VisitStatus.Created:
                return theme.palette.background.paper;
            case VisitStatus.Redirected:
                return theme.palette.secondary.light;
            case VisitStatus.InCenter:
                return theme.palette.info.light;
            case VisitStatus.InProgress:
                return theme.palette.warning.light;
            case VisitStatus.Done:
                return theme.palette.success.light;
            default:
                return "grey";
        }
    }
    else
    {
        return theme.palette.grey[400];
    }
}