import { OperationType } from 'hex/components/material/types'
import { FullSchema } from './types'

import AddIcon from '@mui/icons-material/Add';
import Create from 'hex/components/material/operations/create';
import Update from 'hex/components/material/operations/update';
import { ApolloError } from '@apollo/client';
import { ConvertToFieldErrors, ValidationResultData, Validations, ValidatorCallback } from 'hex/hooks/validator';
import { StringLengthMinMax } from 'hex/validations';
import TextFieldData from 'hex/components/material/operations/fields/text';

import {useAddSchemaMutation, useUpdateSchemaMutation, useDeleteSchemaMutation, useDeleteSchemasByIdsMutation} from 'gql'
import Delete from 'hex/components/material/operations/delete';
import { DeleteForever } from '@mui/icons-material';
import DeleteSelected from 'hex/components/material/operations/delete_selected';
import ManyToManyField from 'hex/components/material/operations/fields/many_to_many';

import MedicinesMtm from './schemas_mtm/medicines'
import ServicesMtm from './schemas_mtm/services'
import OperationNotifier from 'hex/components/material/operations/operation_notifier';


const validations : Validations<FullSchema> = [
    ["name", StringLengthMinMax(2, 100)],
]

var operationNotifier = new OperationNotifier<FullSchema>();

const renderFields = (model: FullSchema, setFieldValue: (field: keyof FullSchema) => (value: any) => void, validator: ValidatorCallback<FullSchema>, validation: ValidationResultData<FullSchema>) => {
    return (
        <>
            <TextFieldData label='Наименование' value={model.name} onChange={setFieldValue("name")} validator={validator("name")} errors={ConvertToFieldErrors("name", validation)} />
            <ManyToManyField values={model.SchemaMedicines} {...MedicinesMtm} operationNotifierAfterCreation={operationNotifier.AddOperationNotifierAfterOperation("MedicinesMtm")} />
            <ManyToManyField values={model.SchemaServices} {...ServicesMtm} operationNotifierAfterCreation={operationNotifier.AddOperationNotifierAfterOperation("ServicesMtm")} />
        </>
    );
}

const renderDeleteText = (model: FullSchema) => {
    return `Вы действительно уверены, что хотите удалить услугу ${model.name}?`;
}

const renderDeleteSelectedTitle = () => {
    return `Вы действительно уверены, что хотите удалить следующие услуги?`;
}

const renderDeleteSelectedText = (model: FullSchema) => {
    return `${model.name}`;
}

function createMutation(model: FullSchema)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useAddSchemaMutation({variables: {
        name: model.name,
        category_id: model.category_id,
    }});
}

function updateMutation(model: FullSchema)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useUpdateSchemaMutation({
        variables: {
            id: model.id,
            name: model.name,
        }
    })
}

function deleteMutation(model: FullSchema)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useDeleteSchemaMutation({
        variables: {
            id: model.id,
        }
    });
}

function deleteByIdsMutation(models: Array<FullSchema>)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useDeleteSchemasByIdsMutation({
        variables: {
            ids: models.map((x) => x.id),
        }
    });
}

const translateError = (error: ApolloError) => {
    return error.message;
}

const defaultModel : FullSchema = {
    id: 0, 
    name: "",
    category_id: 0,
    SchemaMedicines: [],
    SchemaServices: []
};

export function CreateOperation(patching: (model: FullSchema) => FullSchema) : OperationType<FullSchema>
{
    return {
        type: 'global',
        label: 'Создать',
        icon: (<AddIcon />),
        render: (close) => (
            <Create 
                close={close} 
                defaultModel={defaultModel} 
                translateError={translateError} 
                validations={validations} 
                renderFields={renderFields} 
                useMutation={createMutation}
                prefillDefaultModel={patching}
                operationNotifier={operationNotifier}
            />
        ),
    }
}

export function UpdateOperation() : OperationType<FullSchema>
{
    return {
        type: 'individual',
        label: 'Изменить',
        enabled: true,
        render: (model, close) => (
            <Update 
                close={close} 
                defaultModel={model} 
                translateError={translateError}
                validations={validations}
                renderFields={renderFields}
                useMutation={updateMutation}
                operationNotifier={operationNotifier}
            />
        ),
    }
}

export function DeleteOperation() : OperationType<FullSchema>
{
    return {
        type: 'individual',
        label: 'Удалить',
        enabled: true,
        render: (model, close) => (
            <Delete 
                close={close} 
                model={model}
                translateError={translateError}
                renderText={renderDeleteText}
                useMutation={deleteMutation}
            />
        ),
    }
}

export function DeleteSelectedOperation() : OperationType<FullSchema>
{
    return {
        type: 'selected',
        label: 'Удалить выбранное',
        icon: (<DeleteForever />),
        render: (models, close) => (
            <DeleteSelected
                close={close} 
                models={models}
                translateError={translateError}
                renderTitle={renderDeleteSelectedTitle}
                renderText={renderDeleteSelectedText}
                useMutation={deleteByIdsMutation}
            />
        ),
    }
}