import React from 'react'
import { OperationType } from 'hex/components/material/types'
import { PetSex, TablePet } from './types'

import AddIcon from '@mui/icons-material/Add';
import Create from 'hex/components/material/operations/create';
import Update from 'hex/components/material/operations/update';
import { ApolloError } from '@apollo/client';
import { ConvertToFieldErrors, ValidationResultData, Validations, ValidatorCallback } from 'hex/hooks/validator';
import { StringLengthMinMax } from 'hex/validations';
import TextFieldData from 'hex/components/material/operations/fields/text';

import {useAddPetMutation, useUpdatePetMutation, useDeletePetMutation, useDeletePetsByIdsMutation, Pet_Bool_Exp} from 'gql'
import Delete from 'hex/components/material/operations/delete';
import { DeleteForever, FilterList } from '@mui/icons-material';
import DeleteSelected from 'hex/components/material/operations/delete_selected';
import Filters from 'hex/components/material/operations/filters';
import TextFilter from 'hex/components/material/operations/filters/text_filter';
import DateInput from 'hex/components/material/operations/fields/date';
import LookupField from 'hex/components/material/operations/fields/lookup';
import ForeignKeyModal from 'hex/components/material/operations/fields/foreign_key_modal';

import {RenderPetTypeModel, RenderSelectedPetTypeModel, usePetTypeSearch, RenderClientModel, RenderSelectedClientModel, useClientSearch, usePetTypeModel, useClientModel} from './foreign_keys'


export type FilterType = {
    Name: string;
}

export const validations : Validations<TablePet> = [
    ["name", StringLengthMinMax(2, 100)],
]

export const renderFields = (model: TablePet, setFieldValue: (field: keyof TablePet) => (value: any) => void, validator: ValidatorCallback<TablePet>, validation: ValidationResultData<TablePet>, disabled?: boolean, withoutClient?: boolean) => {
    return (
        <>
            <TextFieldData label='Кличка' value={model.name} onChange={setFieldValue("name")} validator={validator("name")} errors={ConvertToFieldErrors("name", validation)} disabled={disabled} />
            <TextFieldData label='Порода' value={model.breed} onChange={setFieldValue("breed")} validator={validator("breed")} errors={ConvertToFieldErrors("breed", validation)} disabled={disabled} />
            <TextFieldData label='Номер чипа' value={model.chip_number} onChange={setFieldValue("chip_number")} validator={validator("chip_number")} errors={ConvertToFieldErrors("chip_number", validation)} disabled={disabled} />
            <DateInput label='Дата рождения' value={model.birthdate} onChange={setFieldValue("birthdate")} disabled={disabled} />
            <LookupField label='Пол' value={model.sex} onChange={setFieldValue("sex")} validator={validator("sex")} errors={ConvertToFieldErrors("sex", validation)} nullable={false} variants={PetSex} disabled={disabled} />
            <ForeignKeyModal label='Тип животного' value={model.type_id} onChange={setFieldValue("type_id")} renderModel={RenderPetTypeModel} renderSelectedModel={RenderSelectedPetTypeModel} useSearch={usePetTypeSearch} disabled={disabled} useModel={usePetTypeModel} />
            {((withoutClient === undefined) || (withoutClient === false)) && <ForeignKeyModal label='Хозяин животного' value={model.client_id} onChange={setFieldValue("client_id")} renderModel={RenderClientModel} renderSelectedModel={RenderSelectedClientModel} useSearch={useClientSearch} disabled={disabled} useModel={useClientModel} />}
        </>
    );
}

const renderFilterFields = (model: FilterType, setFieldValue: (field: keyof FilterType) => (value: any) => void) => {
    return (
        <>
            <TextFilter label='Кличка' value={model.Name} onChange={setFieldValue("Name")} />
        </>
    );
}

const renderDeleteText = (model: TablePet) => {
    return `Вы действительно уверены, что хотите удалить животное ${model.name}?`;
}

const renderDeleteSelectedTitle = () => {
    return `Вы действительно уверены, что хотите удалить следующих животных?`;
}

const renderDeleteSelectedText = (model: TablePet) => {
    return `${model.name}`;
}

export function createMutation(model: TablePet)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useAddPetMutation({variables: {
        name: model.name,
        chip_number: model.chip_number,
        birthdate: model.birthdate,
        breed: model.breed,
        client_id: model.client_id,
        sex: model.sex,
        type_id: model.type_id
    }});
}

export function updateMutation(model: TablePet)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useUpdatePetMutation({
        variables: {
            id: model.id,
            name: model.name,
            chip_number: model.chip_number,
            birthdate: model.birthdate,
            breed: model.breed,
            client_id: model.client_id,
            sex: model.sex,
            type_id: model.type_id
        }
    })
}

function deleteMutation(model: TablePet)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useDeletePetMutation({
        variables: {
            id: model.id,
        }
    });
}

function deleteByIdsMutation(models: Array<TablePet>)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useDeletePetsByIdsMutation({
        variables: {
            ids: models.map((x) => x.id),
        }
    });
}

const translateError = (error: ApolloError) => {
    return error.message;
}

export const defaultModel : TablePet = 
{
    id: 0, 
    name: "",
    birthdate: null,
    breed: null,
    chip_number: null,
    sex: 0,
    client_id: 0,
    Client: {
        name: ""
    },
    type_id: 0,
    PetType: {
        name: ""
    }
};

export const defaultModelWithClient : (client_id: number) => TablePet = (client_id: number) => 
({
    id: 0, 
    name: "",
    birthdate: null,
    breed: null,
    chip_number: null,
    sex: 0,
    client_id,
    Client: {
        name: ""
    },
    type_id: 0,
    PetType: {
        name: ""
    }
});

export const defaultFilter : FilterType = {
    Name: ""
}

export function CreateOperation() : OperationType<TablePet>
{
    return {
        type: 'global',
        label: 'Создать',
        icon: (<AddIcon />),
        render: (close) => (
            <Create 
                close={close} 
                defaultModel={defaultModel} 
                translateError={translateError} 
                validations={validations} 
                renderFields={renderFields} 
                useMutation={createMutation} 
            />
        ),
    }
}

export function UpdateOperation() : OperationType<TablePet>
{
    return {
        type: 'individual',
        label: 'Изменить',
        enabled: true,
        render: (model, close) => (
            <Update 
                close={close} 
                defaultModel={model} 
                translateError={translateError}
                validations={validations}
                renderFields={renderFields}
                useMutation={updateMutation}
            />
        ),
    }
}

export function DeleteOperation() : OperationType<TablePet>
{
    return {
        type: 'individual',
        label: 'Удалить',
        enabled: true,
        render: (model, close) => (
            <Delete 
                close={close} 
                model={model}
                translateError={translateError}
                renderText={renderDeleteText}
                useMutation={deleteMutation}
            />
        ),
    }
}

export function DeleteSelectedOperation() : OperationType<TablePet>
{
    return {
        type: 'selected',
        label: 'Удалить выбранное',
        icon: (<DeleteForever />),
        render: (models, close) => (
            <DeleteSelected
                close={close} 
                models={models}
                translateError={translateError}
                renderTitle={renderDeleteSelectedTitle}
                renderText={renderDeleteSelectedText}
                useMutation={deleteByIdsMutation}
            />
        ),
    }
}

export function FiltrationOperation(filter: FilterType, setFilter: React.Dispatch<React.SetStateAction<FilterType>>)  : OperationType<TablePet>
{
    return {
        type: 'global',
        icon: (<FilterList />),
        label: 'Поиск',
        render: (close) => (
            <Filters 
                close={close}
                defaultModel={defaultFilter}
                model={filter}
                setModel={setFilter}
                renderFields={renderFilterFields}
            />
        )
    }
}

export function TranslateFiltrationToWhere(filter: FilterType) : Pet_Bool_Exp | undefined
{
    if (filter.Name.trim() !== "")
    {
        return {
            name: {
                _ilike: `%${filter.Name}%`
            }
        }
    }
    
    return undefined;
}