import React from 'react';
import { SchemaCategoryMaybeWithChilds } from './types';
import { Grid, Typography } from '@mui/material';
import TreeViewItemMenu from './treeview_item_menu';

// Props:
type TreeViewLabelProps = {
    category: SchemaCategoryMaybeWithChilds;
    onSelect: (category: SchemaCategoryMaybeWithChilds) => void;
    canHaveChilds: boolean;
    onAdd: (category: SchemaCategoryMaybeWithChilds) => void;
    onUpdate: (category: SchemaCategoryMaybeWithChilds) => void;
    onDelete: (category: SchemaCategoryMaybeWithChilds) => void;
    disableOperations?: boolean;
};

const TreeViewLabel : React.FunctionComponent<TreeViewLabelProps> = ({category, canHaveChilds, onSelect, onAdd, onUpdate, onDelete, disableOperations}) => {

    return (
        <div onClick={(event) => {event.stopPropagation(); event.preventDefault(); if (onSelect) onSelect(category);}}>
            <Grid container>
                <Grid item xs={8}>
                    <Typography variant="subtitle1" style={{marginTop: '10px'}}>
                        {category.name}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Grid container alignItems="stretch" sx={{height: "100%"}} flexDirection='row-reverse'>
                        <Grid item>
                            {!disableOperations && <TreeViewItemMenu elements={[
                                {
                                    name: "Добавить",
                                    disabled: !canHaveChilds,
                                    onClick: () => onAdd(category)
                                },
                                {
                                    name: "Изменить",
                                    disabled: false,
                                    onClick: () => onUpdate(category)
                                },
                                {
                                    name: "Удалить",
                                    disabled: false,
                                    onClick: () => onDelete(category)
                                },
                            ]} />}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default TreeViewLabel;