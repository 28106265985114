import React from "react";
import { ApolloError } from "@apollo/client";
import { useCreateVisitDocumentMutation, useGetRegistratorDocumentsSubscription, useGetVisitForDocumentQuery } from "gql";
import Loading from "hex/components/loading";
import { useSnackbar } from "notistack";

import DocumentPrinter from 'components/documents';
import moment from "moment";
import { PetSexToString } from "pages/pets/types";
import { dateDiff } from "hex/utils/dates";
import { useErrorAndLoadChecking } from "hex/components/material/hooks";
import { PatientCard } from "../doctor/types";

// Props:
type DocumentBlockProps = {
    onClose: () => void;
    visit_id: number;
};


const DocumentBlock: React.FunctionComponent<DocumentBlockProps> = ({onClose, visit_id}) => {
    const { data, loading, error } = useGetRegistratorDocumentsSubscription();
    const visitData = useGetVisitForDocumentQuery({variables: {id: visit_id}, fetchPolicy: 'network-only'});

    const [createVisitDocumentMutation, createVisitDocumentMutationData] = useCreateVisitDocumentMutation();

    // eslint-disable-next-line
    const { enqueueSnackbar } = useSnackbar();

    useErrorAndLoadChecking(createVisitDocumentMutationData);

    React.useEffect(() => {
        const checkError = (error : ApolloError | undefined) => {
            if (error !== undefined)
            {
                enqueueSnackbar(`Ошибка получения данных! ${error.message}`, {variant: 'error'});
            }
        }

        checkError(error);
        checkError(visitData.error);
    }, [error, visitData, enqueueSnackbar]);

    const variables : Record<string, string> = React.useMemo(() : Record<string, string> => {
        if (visitData.data === undefined)
            return {};
        
        const visit = visitData.data.Visit[0];

        return {
            // Data:
            doc_number: visit.id.toString(),
            current_date: moment(new Date()).format("DD.MM.YYYY"),

            // Client:
            client_name: visit.Client.name,
            client_phone_number: visit.Client.phone_number || '<Не указан>',
            client_passport: visit.Client.passport || '<Не указан>',
            client_passport_where: visit.Client.passport_where || '<Не указан>',
            client_passport_where_date: visit.Client.passport_when ? moment(visit.Client.passport_when).format("DD.MM.YYYY") : '<Не указан>',
            client_address: visit.Client.address || '<Не указан>',
            // Pet:
            animal_kind: visit.Pet.PetType.name,
            animal_name: visit.Pet.name,
            animal_sex: PetSexToString(visit.Pet.sex),
            animal_age: visit.Pet.birthdate ? dateDiff(new Date(visit.Pet.birthdate), new Date(Date.now())) : '<Не указан>',
            animal_breed: visit.Pet.breed || '<Не указан>',
            // Visit:
            temperature: visit.patient_card ? (visit.patient_card as PatientCard).temperature : '<Не указана>',
            weight: visit.patient_card ? (visit.patient_card as PatientCard).weight : '<Не указан>',
            pressure: visit.patient_card ? (visit.patient_card as PatientCard).pressure : '<Не указан>',
            vactination: visit.patient_card ? (visit.patient_card as PatientCard).vactination : '<Не указан>',
            ecto_parasites: visit.patient_card ? (visit.patient_card as PatientCard).ecto_parasites : '<Не указан>',
            gelm_parasites: visit.patient_card ? (visit.patient_card as PatientCard).gelm_parasites : '<Не указан>',
            heart_freq: visit.patient_card ? (visit.patient_card as PatientCard).heart_freq : '<Не указан>',
            breath_freq: visit.patient_card ? (visit.patient_card as PatientCard).breath_freq : '<Не указан>',
            contains: visit.patient_card ? (visit.patient_card as PatientCard).contains : '<Не указан>',
            another_animals: visit.patient_card ? (visit.patient_card as PatientCard).another_animals : '<Не указан>',
            nutrition: visit.patient_card ? (visit.patient_card as PatientCard).nutrition : '<Не указан>',
            complaints: visit.patient_card ? (visit.patient_card as PatientCard).complaints : '<Не указан>',
            symptoms: visit.patient_card ? (visit.patient_card as PatientCard).symptoms : '<Не указаны>',
            diagnosis: visit.patient_card ? (visit.patient_card as PatientCard).diagnosis : '<Не указан>',
            anamnesis: visit.patient_card ? (visit.patient_card as PatientCard).anamnesis : '<Не указан>',
            snk: visit.patient_card ? (visit.patient_card as PatientCard).snk : '<Не указан>',
            general_state: visit.patient_card ? (visit.patient_card as PatientCard).general_state : '<Не указан>',
            abdomen: visit.patient_card ? (visit.patient_card as PatientCard).abdomen : '<Не указан>',
            dehidration_degree: visit.patient_card ? (visit.patient_card as PatientCard).dehidration_degree : '<Не указан>',
            spleen: visit.patient_card ? (visit.patient_card as PatientCard).spleen : '<Не указан>',
            edema: visit.patient_card ? (visit.patient_card as PatientCard).edema : '<Не указан>',
            lymph_nodes: visit.patient_card ? (visit.patient_card as PatientCard).lymph_nodes : '<Не указан>',
            cath_limb: visit.patient_card ? (visit.patient_card as PatientCard).cath_limb : '<Не указан>',
            cath_heart: visit.patient_card ? (visit.patient_card as PatientCard).cath_heart : '<Не указан>',
            diagnostics: visit.patient_card ? (visit.patient_card as PatientCard).diagnostics : '<Не указан>',
            diagnostics_recomended: visit.patient_card ? (visit.patient_card as PatientCard).diagnostics_recomended : '<Не указан>',
            // Reception:
            current_reception_recomendations: visit.CurrentReception ? visit.CurrentReception.patient_recomendation.split("\n").join("<br/>") : '<Не указан>',
            // Doctor:
            doctor_name: (visit.Doctor === undefined) || (visit.Doctor === null) ? "" : visit.Doctor.Name,
            department_name: visitData.data.Visit[0].Department.name,
        }
    }, [visitData]);

    if ((visitData.data === undefined) || (data === undefined))
        return (
            <></>
        );
    
    return (
        <>
            <Loading loading={loading || visitData.loading} />
            <DocumentPrinter 
                documents={data.RegistrationDocument.map(x => x.Document)} 
                variables={variables} 
                text={`Документы для клиента: ${visitData.data.Visit[0].Client.name}`} 
                onClose={onClose} 
                onPrint={(name, text) => {
                    createVisitDocumentMutation({
                        variables: {
                            visit_id: visit_id,
                            data: text,
                            name: name
                        }
                    });
                }}
            />
        </>
    );
};

export default DocumentBlock;