import React from 'react';
import {useTitle} from 'hex/hooks/title'

import Layout from 'components/layout'

import {useAuthState} from 'hex/hooks/auth'
import Sidebar from './sidebar'
import Timer from 'hex/components/material/timer';
import UsernameBlock from './username';
import { Grid } from '@mui/material';


type AuthLayoutProps = {
    children?: React.ReactNode
};

const AuthLayout: React.FunctionComponent<AuthLayoutProps> = ({children}) => {
    // eslint-disable-next-line
    const [text, _setText] = useTitle();
    const authState = useAuthState();

    if (authState === true)
    {
        return (
            <Layout layoutText={text} sidebar={<Sidebar />} toolbarData={<Timer />}>
                {children}
            </Layout>
        );
    }
    else
    {
        return (
            <Layout layoutText={text} toolbarData={<></>}>
                {children}
            </Layout>
        );
    }
}

export default AuthLayout;
