export function StringLengthMinMax(min: number, max: number)
{
    return (value: any) => {
        if ((value === undefined) || (value === null))
            return undefined;

        if (((value as string).length < min) || ((value as string).length > max))
            return `Значение должно быть в пределах от ${min} до ${max} символов. Текущее значение: ${(value as string).length}`;
        
        return undefined;
    };
}

export function StringLengthMin(min: number)
{
    return (value: any) => {
        if ((value === undefined) || (value === null))
            return undefined;

        if ((value as string).length < min)
            return `Значение должно быть минимум ${min} символа(ов). Текущее значение: ${(value as string).length}`;
        
        return undefined;
    };
}

export function StringLengthMax(max: number)
{
    return (value: any) => {
        if ((value === undefined) || (value === null))
            return undefined;

        if (((value as string).length > max))
            return `Значение должно быть максимум ${max} символов. Текущее значение: ${(value as string).length}`;
        
        return undefined;
    };
}

export function StringNotEmpty()
{
    return (value: any) => {
        if ((value === undefined) || (value === null))
            return "Пустое значение недопустимо.";

        if ((value as string).trim() === "")
            return "Пустое значение недопустимо.";
        
        return undefined;
    };
}

export function StringWithoutSpaces()
{
    return (value: any) => {
        if ((value === undefined) || (value === null))
            return undefined;

        if ((value as string).includes(" "))
            return "Запрещено использовать пробелы.";
        
        return undefined;
    };
}

export function NumberNotZero()
{
    return (value: any) => {
        if ((value === undefined) || (value === null))
            return undefined;

        if ((value as number) === 0)
            return "Запрещено нулевое значение.";
        
        return undefined;
    };
}

export function NumberNotNegative()
{
    return (value: any) => {
        if ((value === undefined) || (value === null))
            return undefined;

        if ((value as number) < 0)
            return "Запрещено значение меньше нуля.";
        
        return undefined;
    };
}