import React from 'react';
import { ModelType } from '../table/types';
import { OperationType } from '../types';


// Props:
type OperationsProps<TModel extends ModelType> = 
{
    args: Array<OperationType<TModel>>;
    eventIndex: number | undefined;
    model: TModel | undefined;
    selectedModels: TModel[] | undefined;
    close: () => void;
};


function renderElement<TModel extends ModelType>(operation: OperationType<TModel>, model: TModel | undefined, selectedModels: TModel[] | undefined, close: () => void) : React.ReactNode
{
    switch (operation.type) {
        case 'global':
            return operation.render(close);
        case 'selected':
            return selectedModels !== undefined ? operation.render(selectedModels, close) : (<></>);
        case 'individual':
            return model !== undefined ? operation.render(model, close) : (<></>);
        default:
            return (<></>);
    }
}


function Operations<TModel extends ModelType>(props: OperationsProps<TModel>)
{
    var {args, eventIndex, model, selectedModels, close} = props;

    return (
        <>
            {eventIndex !== undefined && 
                renderElement(args[eventIndex], model, selectedModels, close)
            }
        </>
    );
};

export default Operations;