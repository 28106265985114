import React from 'react';
import { useGetTemplatesByUserCountSubscription, useGetTemplatesByUserSubscription } from 'gql';
import { useErrorAndLoadChecking } from 'hex/components/material/hooks';

import HexTable from 'hex/components/material/table'

import { HeadCell } from 'hex/components/material/table/types';
import { useOperations, usePagination, useSorting } from 'hex/components/material/hooks';

import { TableTemplate } from './types';
import { OperationType } from 'hex/components/material/types';
import Operations from 'hex/components/material/operations';

import {CreateOperation, DeleteOperation, DeleteSelectedOperation, UpdateOperation} from './operations'
import Title from 'hex/components/title';
import { useAuth } from 'hex/hooks/auth';

type IndexProps = {

}

const headCells : HeadCell<TableTemplate>[] = [
    {
        id: "name",
        label: "Наименование",
        sort: true,
        render: (schema) => (<React.Fragment>{schema.name}</React.Fragment>)
    }
];

const Index: React.FunctionComponent<IndexProps> = () => {
    const auth = useAuth();

    const [pagination, setPagination] = usePagination();
    const [order, onSetOrder] = useSorting<TableTemplate>({orderBy: "name", order: "asc"});
    const operationsArray = React.useMemo<Array<OperationType<TableTemplate>>>(() => {
        return [
            CreateOperation(),
            UpdateOperation(),
            DeleteOperation(),
            DeleteSelectedOperation(),
        ];
    }, []);
    const operations = useOperations<TableTemplate>(operationsArray);

    const { data, loading, error } = useGetTemplatesByUserSubscription({variables: { offset: pagination.offset, limit: pagination.limit, order_by: { [order.orderBy]: order.order, }, user_id: auth.getUserId()}});
    const countResult = useGetTemplatesByUserCountSubscription({ variables: { user_id: auth.getUserId() }});

    const models : TableTemplate[] | undefined = (data === undefined) ? undefined : data.Template;

    var count = React.useMemo<number>(() => {
        if ((countResult.data !== undefined) && (countResult.data.Template_aggregate.aggregate !== undefined) && (countResult.data.Template_aggregate.aggregate !== null))
            return countResult.data.Template_aggregate.aggregate.count;
        
        return 0;
    }, [countResult]);

    useErrorAndLoadChecking({loading, error});
    

    return (
        <React.Fragment>
            <Title title='Шаблоны'/>
            <h1>Шаблоны</h1>
            <HexTable {...pagination} order={order.order} orderBy={order.orderBy as keyof TableTemplate} 
                      count={count} onPageChange={setPagination} models={models} headCells={headCells} onOrderSet={onSetOrder}
                      globalActions={operations.globalActions} selectedActions={operations.selectedActions} actions={operations.actions}
            /> 
            <Operations args={operationsArray} eventIndex={operations.eventIndex} model={operations.model} selectedModels={operations.selectedModels} close={operations.close} />
        </React.Fragment>
    )
}

export default Index;