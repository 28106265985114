import { ConvertToFieldErrors, ValidationResultData, Validations, ValidatorCallback } from 'hex/hooks/validator';
import { NumberNotNegative, NumberNotZero } from 'hex/validations';
import NumericFieldData from 'hex/components/material/operations/fields/numeric';
import BooleanFieldData from 'hex/components/material/operations/fields/bool';

import { MtmServices } from './types';
import { ManyToManyFieldProps } from 'hex/components/material/operations/fields/many_to_many';
import ForeignKeyModal from 'hex/components/material/operations/fields/foreign_key_modal';
import { ServiceFkData } from './foreign_keys';
import { ListItemText } from '@mui/material';
import { useAddUserServicesMutation, useDeleteUserServiceMutation, useUpdateUserServiceMutation } from 'gql';


const validations : Validations<MtmServices> = [
    ["value", NumberNotZero()],
    ["value", NumberNotNegative()],
]

const renderFields = (model: MtmServices, setFieldValue: (field: keyof MtmServices) => (value: any) => void, validator: ValidatorCallback<MtmServices>, validation: ValidationResultData<MtmServices>) => {
    return (
        <>
            <ForeignKeyModal label='Услуга' value={model.service_id} onChange={setFieldValue("service_id")} {...ServiceFkData} onChangeModel={setFieldValue("Service")} />
            <BooleanFieldData label='В процентах' value={model.in_percent} onChange={setFieldValue("in_percent")} />
            <NumericFieldData label='Значение' value={model.value} onChange={setFieldValue("value")} validator={validator("value")} errors={ConvertToFieldErrors("value", validation)} decimal />
        </>
    );
}

const renderModel = (model: MtmServices) => {
    return (
        <ListItemText primary={`Услуга: ${model.Service.name} Значение: ${model.value} ${model.in_percent ? '%' : 'руб.'}`} />
    );
}

const useDeleteMutation = (model: MtmServices) => {
    return useDeleteUserServiceMutation({
        variables: {
            id: model.id,
        }
    });
}

const useUpdateMutation = (model: MtmServices) => {
    return useUpdateUserServiceMutation({
        variables: {
            id: model.id,
            service_id: model.service_id,
            in_percent: model.in_percent,
            value: model.value,
            user_id: model.user_id,
        }
    })
}

const useAddMutation = (models: Array<MtmServices>) => {
    return useAddUserServicesMutation({
        variables: {
            objects: models.map((element) => {
                return {
                    service_id: element.service_id,
                    in_percent: element.in_percent,
                    value: element.value,
                    user_id: element.user_id,
                }
            })
        }
    })
}

const setParentID: (models: Array<MtmServices>, parentId: any) => Array<MtmServices> = (models: Array<MtmServices>, parentId: any) => {
    var newArray = [...models];

    newArray.forEach((element) => element.user_id = parentId);

    return newArray.map((element) => {
        return {
            service_id: element.service_id,
            in_percent: element.in_percent,
            value: element.value,
            user_id: element.user_id,
        } as any
    });
}

const getIdFromNotifyer = (data: any) => {
    return data;
}


const defaultModel : MtmServices = {
    id: 0,
    user_id: "",
    service_id: 0,
    in_percent: false,
    value: 0,
    Service: {
        id: 0,
        name: "",
        price: 0,
    },
};

const defaultMtm : Omit<ManyToManyFieldProps<MtmServices>, "baseModelID" | "values" | "operationNotifierAfterCreation"> = {
    label: "Услуги",
    renderFields,
    renderModel,
    defaultModel,
    validations,
    useAddMutation,
    useDeleteMutation,
    useUpdateMutation,
    setParentID,
    getIdFromNotifyer
}

export default defaultMtm;