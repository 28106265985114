import { useAddFileToVisitMutation, useGetVisitFilesSubscription } from "gql";
import Loading from "hex/components/loading";
import { useErrorChecking } from "hex/components/material/hooks";
import React from "react";
import { Box, Button, CircularProgress, FormGroup, Grid, Paper, Typography } from '@mui/material';
import FileField from 'hex/components/material/operations/fields/file';
import { AUTH_SERVER_PATH } from "config";
import DocumentsBlock from './documents';
import { VisitDocumentDataType } from "./types";
import Preview from "components/documents/preview";


type FilesUploaderProps = {
    visit_id: number;
    onBeforeDocumentOpen?: () => void;
};

function FilesUploader(props: FilesUploaderProps) {
    const { data, loading, error } = useGetVisitFilesSubscription({ variables: { id: props.visit_id } });
    const [addFileToVisitMutation, addMutationData] = useAddFileToVisitMutation();

    const [documentsOpen, setDocumentsOpen] = React.useState<boolean>(false);
    const [previewOpen, setPreviewOpen] = React.useState<boolean>(false);
    const [selectedForPreview, setSelectedForPreview] = React.useState<VisitDocumentDataType | undefined>(undefined);

    useErrorChecking(addMutationData.error)
    useErrorChecking(error);

    if (data === undefined)
        return (
            <>
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>
            </>
        );

    if (data.Visit.length === 0)
        return (
            <>
                <Typography>Запись о приеме отсутствует</Typography>
            </>
        );

    return (
        <Paper sx={{ p: 1, marginBottom: 1, bgcolor: 'grey.200' }}>
            <Loading loading={loading || addMutationData.loading} />
            <Paper sx={{ p: 1, height: '100%' }}>
                <Box sx={{ marginBottom: 3 }}>
                    <Typography variant='h6'>Документы:</Typography>
                </Box>
                <Paper sx={{ p: 1, marginBottom: 1, bgcolor: 'grey.200' }}>
                    <Box>
                        <Grid container>
                            <Grid item sx={{ m: 1 }}>
                                <Typography variant='button'>Выгруженные файлы:</Typography>
                            </Grid>
                        </Grid>
                        <Box sx={{marginBottom: 3}}>
                            <Grid container>
                                <Grid item>
                                    <FileField label='файла' onChange={(val, oldName) => {
                                        addFileToVisitMutation({
                                            variables: {
                                                visit_id: props.visit_id,
                                                file_name: val,
                                                name: oldName
                                            }
                                        });
                                    }} />
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid container>
                            {data.Visit[0].VisitFiles.length === 0 && (<Grid item sx={{ m: 1 }}><Typography variant='button'>Отсутствуют</Typography></Grid>)}
                            {data.Visit[0].VisitFiles.map((x) => (
                                    <Grid key={x.id} item sx={{ m: 1 }}>
                                        <Button href={`${AUTH_SERVER_PATH}/api/Files/${x.file_name}`} target="_blank" variant="contained">{x.name}</Button>
                                    </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Paper>
                <Paper sx={{ p: 1, marginBottom: 1, bgcolor: 'grey.200' }}>
                    <Box>
                        <Grid container>
                            <Grid item sx={{ m: 1 }}>
                                <Typography variant='button'>Распечатанные документы:</Typography>
                            </Grid>
                        </Grid>
                        <Box sx={{marginBottom: 3}}>
                            <Grid container>
                                <Grid item>
                                    <FormGroup>
                                        <Button variant="contained" component="label" onClick={() => {
                                            if (props.onBeforeDocumentOpen) props.onBeforeDocumentOpen();
                                            setDocumentsOpen(true);
                                        }}>
                                            Создать документ
                                        </Button>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid container>
                            {data.Visit[0].VisitDocuments.length === 0 && (<Grid item sx={{ m: 1 }}><Typography variant='button'>Отсутствуют</Typography></Grid>)}
                            {data.Visit[0].VisitDocuments.map((x) => (
                                
                                    <Grid key={x.id} item sx={{ m: 1 }}>
                                        <Button variant="contained" onClick={() => {
                                            setPreviewOpen(true);
                                            setSelectedForPreview(x);
                                        }}>{x.name}</Button>
                                    </Grid>
                                
                            ))}
                        </Grid>
                    </Box>
                </Paper>
            </Paper>
            {documentsOpen && <DocumentsBlock visit_id={props.visit_id} department_id={data.Visit[0].department_id} onClose={() => setDocumentsOpen(false)} />}
            {previewOpen && selectedForPreview !== undefined && <Preview name={selectedForPreview.name} text={selectedForPreview.data} onClose={() => {setPreviewOpen(false); setSelectedForPreview(undefined);}} />}
        </Paper>
    );
}

export default FilesUploader;