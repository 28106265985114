import { GetReceptionsOfVisitQuery } from "gql";
import React from "react";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Grid, Typography } from "@mui/material";
import { useAuth } from "hex/hooks/auth";
import { flatten, groupByToMap } from "hex/utils/array";
import { timeFormat } from "pages/index/registrator/actions/steps/date";
import { clientTimeZone } from "hex/utils/dates";
import moment from "moment";

type DoctorsHistoryProps = {
    data: GetReceptionsOfVisitQuery;
};

const calculateSum = (services: Array<{price: any, value: any}>) => {
    let sum = 0;
    services.forEach((element) => sum += (element.price as number * element.value as number));

    return sum;
}

const DoctorsHistory : React.FC<DoctorsHistoryProps> = ({data}) => {
    const auth = useAuth();

    const groupedData = groupByToMap(data.Reception, element => element.doctor_id);

    const [expanded, setExpanded] = React.useState<Array<number>>([]);

    const handleChange =
        (id: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            if (!isExpanded)
            {
                setExpanded((oldValue) => {
                    var copy = [...oldValue];

                    copy.splice(copy.indexOf(id), 1);

                    return copy;
                });
            }
            else
            {
                setExpanded((oldValue) => {
                    var copy = [...oldValue];

                    copy.push(id);

                    return copy;
                });
            }
        };

    const onExpandAll = () => {
        setExpanded(data.Reception.map(x => x.id));
    }

    const onCloseAll = () => {
        setExpanded([]);
    }

    const renderServices = (receptions: GetReceptionsOfVisitQuery["Reception"]) => {
        let services = flatten(receptions.map(reception => reception.Services));

        if (services.length === 0) return (<Typography>Отсутствуют</Typography>);

        return (
            <>
                <ul style={{margin: 0}}>
                    {services.map((service) => (<li key={service.id}>{service.Service.name} {auth.getUserId() === receptions[0].doctor_id ? `${service.price} * ${service.value} = ${service.price as number * service.value as number}` : ''}</li>))}
                </ul>
                {auth.getUserId() === receptions[0].doctor_id && <Typography>Сумма: {calculateSum(services)} руб.</Typography>}
            </>
        )
    }

    const renderMedicines = (receptions: GetReceptionsOfVisitQuery["Reception"]) => {
        let medicines = flatten(receptions.map(reception => reception.Medicines));

        if (medicines.length === 0) return (<Typography>Отсутствуют</Typography>);

        return (
            <>
                <ul style={{margin: 0}}>
                    {medicines.map((medicine) => (<li key={medicine.id}>{medicine.done ? '✓' : ''}{medicine.Medicine.name}</li>))}
                </ul>
            </>
        )
    }

    const renderAll = () => {
        return Array.from(groupedData, ([doctor_id, receptions]) => (receptions)).map(receptions => {
            const doctorName = receptions[0].Doctor.Name;
            const receptionId = receptions[0].id;

            return (
                <Accordion 
                    expanded={expanded.indexOf(receptionId) !== -1} 
                    onChange={handleChange(receptionId)}
                    key={receptionId} 
                    sx={(theme) => ({bgcolor: theme.palette.info.light, color: theme.palette.info.contrastText})}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{'Врач'} - <b><i>{doctorName}</i></b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant='button'>
                            <b>Примечание:</b>
                        </Typography>
                        <Typography style={{whiteSpace: 'pre-line'}}>
                            {receptions.map(reception => (<><b>{moment.utc(reception.start_time, timeFormat).tz(clientTimeZone).format('HH:mm')}:</b> {reception.internal_recomendation} <br/></>))}
                        </Typography>
                        <Typography variant='button'>
                            <b>Рекомендации:</b>
                        </Typography>
                        <Typography style={{whiteSpace: 'pre-line'}}>
                            {receptions.map(reception => (<><b>{moment.utc(reception.start_time, timeFormat).tz(clientTimeZone).format('HH:mm')}:</b> {reception.patient_recomendation} <br/></>))}
                        </Typography>
                        <Typography variant='button'>
                            <b>Услуги:</b>
                        </Typography>
                        <Typography>
                            {renderServices(receptions)}
                        </Typography>
                        <Typography variant='button'>
                            <b>Препараты:</b>
                        </Typography>
                        <Typography>
                            {renderMedicines(receptions)}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            )
        })
    }
    
    return (
        <>
        <Grid container flexDirection="row" sx={{marginBottom: 2}}>
            <Grid item>
                <Button onClick={onExpandAll} variant="outlined" color="secondary">Раскрыть все</Button>
            </Grid>
            <Grid item sx={{marginLeft: "auto"}}>
                <Button onClick={onCloseAll} variant="outlined" color="secondary">Свернуть все</Button>
            </Grid>
        </Grid>
            {renderAll()}
        </>
    )
}

export default DoctorsHistory;