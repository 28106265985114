import { FormGroup, FormControlLabel, Switch } from '@mui/material';
import React from 'react';

// Props:
type BoolFieldProps = {
    label?: string;
    value?: any;
    onChange: (value: any) => any;
};

// Element:
const BoolField : React.FunctionComponent<BoolFieldProps> = ({label, value, onChange}) => {
    const onChangeEventWrapper = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, checked: boolean) => {
        onChange(checked);
    };

    return (
        <FormGroup>
            <br/>
            <FormControlLabel control={<Switch checked={value as boolean} onChange={onChangeEventWrapper} />} label={label} />
            <br/>
        </FormGroup>
    );
}

export default BoolField;