import React from 'react';
import { Box, Grid, IconButton, List, ListItem, Paper, Tooltip, Typography } from '@mui/material';
import { ModelType, getModelId, setModelId } from '../../table/types';
import { ValidationResultData, Validations, ValidatorCallback } from 'hex/hooks/validator';
import CreateWindow from './mtm/create';
import DeleteWindow from './mtm/delete';
import UpdateWindow from './mtm/update';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';

import { ApolloCache, DefaultContext, MutationTuple, OperationVariables } from '@apollo/client';
import { useErrorAndLoadChecking } from '../../hooks';
import { Exact, InputMaybe } from 'gql';

// Add variables:
type MtmAddVariables = Exact<{
    objects?: InputMaybe<Array<any> | any>;
}>;

// Props:
export type ManyToManyFieldProps<TModel extends ModelType, TData = any, TVariables = OperationVariables, TContext = DefaultContext, TCache extends ApolloCache<any> = ApolloCache<any>> = {
    label: string;
    values: Array<TModel>;
    defaultModel: TModel;
    renderFields: (model: TModel, setFieldValue: (field: keyof TModel) => (value: any) => void, validator: ValidatorCallback<TModel>, validation: ValidationResultData<TModel>) => React.ReactNode;
    renderModel: (model: TModel) => React.ReactNode;
    validations: Validations<TModel>;
    operationNotifierAfterCreation: (action: (model: any, id: any) => void) => void;
    getIdFromNotifyer: (data: any) => any;
    useDeleteMutation: (model: TModel) => MutationTuple<TData, TVariables, TContext, TCache>;
    useUpdateMutation: (model: TModel) => MutationTuple<TData, TVariables, TContext, TCache>;
    useAddMutation: (models: Array<TModel>) => MutationTuple<TData, MtmAddVariables, TContext, TCache>;
    setParentID: (models: Array<TModel>, parentId: any) => Array<TModel>;
};

// Element:
function ManyToManyField<TModel extends ModelType>({label, values, defaultModel, validations, renderFields, renderModel, operationNotifierAfterCreation, getIdFromNotifyer, useDeleteMutation, useUpdateMutation, useAddMutation, setParentID} : ManyToManyFieldProps<TModel>) {
    const [models, setModels] = React.useState<Array<TModel>>(values);

    const [createOpen, setCreateOpen] = React.useState<boolean>(false);
    const [updateOpen, setUpdateOpen] = React.useState<boolean>(false);
    const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);

    const [selectedModel, setSelectedModel] = React.useState<TModel | undefined>(undefined);

    const [deleteMutation, deleteMutationData] = useDeleteMutation(selectedModel || defaultModel);
    const [addMutation, addMutationData] = useAddMutation(models);

    useErrorAndLoadChecking(deleteMutationData);
    useErrorAndLoadChecking(addMutationData);

    const [fakeIndex, setFakeIndex] = React.useState<number>(-1);


    const afterObjectCreation = React.useMemo(() => {
        return (model: any, id: any) => {
            const objs = setParentID(models.filter(dataModel => getModelId(dataModel) < 0), getIdFromNotifyer(id));

            if (objs.length !== 0)
                addMutation({
                    variables: {
                        objects: objs,
                    }
                });
        }
    }, [models, getIdFromNotifyer]);

    React.useMemo(() => {
        return operationNotifierAfterCreation(afterObjectCreation);
    }, [operationNotifierAfterCreation, afterObjectCreation])
    
    const renderModels = () => {
        if (models.length === 0)
            return (<Typography variant='caption'>Отсутствуют</Typography>);

        return (
            <List>
              {models.map((element, index) => (
                <ListItem key={getModelId(element)}
                    secondaryAction={
                        <Grid container spacing={2}>
                            <Grid item>
                                <Tooltip title='Изменить'>
                                    <IconButton edge="end" onClick={() => {
                                        setUpdateOpen(true);
                                        setSelectedModel(element);
                                    }}>
                                        <CreateIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title='Удалить'>
                                    <IconButton edge="end" onClick={() => {
                                        setDeleteOpen(true);
                                        setSelectedModel(element);
                                    }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    }
                >
                    {renderModel(element)}
                </ListItem>
              ))}
            </List>
        )
    }

    const onCreate = (model: TModel) => {
        setModelId(model, fakeIndex);
        setFakeIndex(fakeIndex - 1);
        setModels((oldModels) => {
            return [...oldModels, model];
        });
    }

    const onDelete = async (model: TModel) => {
        if (getModelId(model) > 0)
        {
            await deleteMutation();
        }

        setModels((oldModels) => {
            var newArray = [...oldModels];
            var index = oldModels.findIndex((element) => getModelId(element) === getModelId(model));

            if (index !== -1)
                newArray.splice(index, 1);
            
            return newArray;
        });
    }

    const onUpdate = async (model: TModel) => {
        setModels((oldModels) => {
            var newArray = [...oldModels];
            var index = oldModels.findIndex((element) => getModelId(element) === getModelId(model));

            if (index !== -1)
                newArray[index] = model;
            
            return newArray;
        });
    }
    
    return (
        <Paper sx={{padding: 1, marginTop: 1, marginBottom: 1}}>
            <Grid container sx={{marginBottom: 1}}>
                <Grid item>
                    <Typography variant='body1'>{label}:</Typography>
                </Grid>
                <Grid item marginLeft='auto' paddingRight={1}>
                    <Tooltip title='Добавить'>
                        <IconButton edge="end" onClick={() => setCreateOpen(true)}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <Box>
                {renderModels()}
            </Box>
            {createOpen && <CreateWindow close={() => setCreateOpen(false)} defaultModel={defaultModel} validations={validations} renderFields={renderFields} onAction={onCreate} />}
            {deleteOpen && selectedModel && <DeleteWindow close={() => setDeleteOpen(false)} model={selectedModel} onAction={onDelete} />}
            {updateOpen && selectedModel && <UpdateWindow close={() => setUpdateOpen(false)} validations={validations} renderFields={renderFields} defaultModel={selectedModel} onAction={onUpdate} useUpdateMutation={useUpdateMutation} />}
        </Paper>
    );
}

export default ManyToManyField;