import React from 'react';
import { Navigate } from "react-router-dom";
import {useAuth, useAuthState} from 'hex/hooks/auth'
import { Typography } from '@mui/material';

// Props:
type AuthGateProps = {
    children?: React.ReactNode;
    loginLink: string;
    roles?: Array<string>;
};

const AuthGate: React.FunctionComponent<AuthGateProps> = ({ children, loginLink, roles }) => {
    const auth = useAuth();
    const state = useAuthState();
    const role = auth.getUserRole();


    if (state === true)
    {
        if (roles !== undefined)
        {
            if ((role !== null) && (roles.indexOf(role) !== -1))
                return (
                    <>
                        {children}    
                    </>
                );
            else
                return (
                    <>
                        <Typography variant='h1'>У вас недосстаточно прав для открытия этой страницы</Typography>
                    </>
                );
        }
        else
        {
            return (
                <>
                    {children}
                </>)
        }
    }
    else if (state === false)
        return (<Navigate to={loginLink} replace={true} />);
    else
        return (
            <React.Fragment>
                <h1>Авторизация...</h1>
            </React.Fragment>
        );
}

export default AuthGate;