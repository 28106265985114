import { Avatar, Box, Button, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from "@mui/material";
import { LatestChatMessagesDocument, useLatestChatMessagesFirstQuery, useLatestChatMessagesSubscription, useSendMessageMutation } from "gql";
import { useErrorAndLoadChecking } from "hex/components/material/hooks";
import { useAuth } from "hex/hooks/auth";
import React from "react";

import moment from 'moment-timezone';
import 'moment/locale/ru';
import { useSnackbar } from "notistack";

const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function shortText(text: string) : string
{
    if (text.length > 25)
    {
        return text.substring(0, 25).replace("\n", " ").trim() + "...";
    }
    else
    {
        return text.replace("\n", " ");
    }
}

type ChatProps = {
    open: boolean;
};

var chatOpen: boolean = false;

const Chat: React.FunctionComponent<ChatProps> = ({open}) => {
    const auth = useAuth();
    const [message, setMessage] = React.useState<string>("");

    const scrollRef = React.createRef<HTMLDivElement>();
    
    const { enqueueSnackbar } = useSnackbar();

    const { data, loading, error, subscribeToMore } = useLatestChatMessagesFirstQuery({
        fetchPolicy: 'network-only',
    });

    const [sendMessageMutation, sendMessageData] = useSendMessageMutation({
        variables: {
            message,
            user_id: auth.getUserId(),
        }
    });

    React.useEffect(() => {
        chatOpen = open;
    }, [open]);

    React.useEffect(() => {
        if ((scrollRef.current !== null) && (data !== undefined))
        {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, [scrollRef, data]);

    React.useEffect(() => {
        subscribeToMore({
          document: LatestChatMessagesDocument,
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev
            
            if (!chatOpen)
            {
                const newMessages = subscriptionData.data.ChatMessages.filter((message) => (prev.ChatMessages.findIndex((prevMessage) => prevMessage.id === message.id) === -1));
                newMessages.forEach((message) => {
                    if (message.user_id !== auth.getUserId())
                        enqueueSnackbar(`${message.User.Name}: ${shortText(message.message)}`, {variant: 'default', anchorOrigin: {horizontal: 'right', vertical: 'bottom'}, autoHideDuration: 3000});
                });
            }

            return subscriptionData.data;
          },
        })
      }, [subscribeToMore, enqueueSnackbar]);

    useErrorAndLoadChecking({loading, error});

    const onChangeEventWrapper = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMessage(event.target.value);
    };

    const onSend = () => {
        sendMessageMutation();
        setMessage("");
    }

    return (
        <Box sx={(theme) => ({p: 1, height: "calc(100vh - 65px)"})}>
            <Box sx={{pb: 1}}>
                <Typography variant="h6">Чат:</Typography>
            </Box>
            <Box sx={{height: "calc(100vh - 330px)", overflowY: "auto"}} ref={scrollRef}>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}> 
                    {
                        data && data.ChatMessages.map((message) => (
                            <ListItem alignItems="flex-start" key={message.id}>
                                <ListItemAvatar>
                                    <Avatar alt={message.User.Name} />
                                </ListItemAvatar>
                                <ListItemText
                                primary={message.User.Name}
                                secondary={
                                    <>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            sx={{textWrap: "wrap"}}
                                        >
                                            {moment.utc(message.date).tz(clientTimeZone).format("DD-MM-YYYY HH:mm")}
                                        </Typography>
                                        <Typography
                                        variant="body2"
                                        color="text.primary"
                                        sx={{textWrap: "wrap"}}
                                    >
                                        {message.message}
                                    </Typography>
                                    </>
                                }
                                />
                            </ListItem>
                        ))
                    }
                </List>
            </Box>
            <Box>
                <Box>
                    <TextField 
                        margin="dense" 
                        label="Сообщение:" 
                        multiline
                        rows={5}
                        maxRows={5}
                        fullWidth
                        onChange={onChangeEventWrapper}
                        onKeyDown={(event) => {
                            if ((event.key === "Enter") && (event.ctrlKey || event.metaKey)) {
                                onSend();
                            }
                        }}
                        value={message}
                    />
                </Box>
                <Box sx={{pt: 1}}>
                    <Button variant="contained" onClick={onSend}>Отправить</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default Chat;
