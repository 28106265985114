import React from 'react';
import Auth from 'hex/auth';
import {ContextType} from './types';

// Context:
export const HexAuthContext = React.createContext<ContextType>({signedIn: false});

// Props:
type HexAuthProviderProps = {
    auth: Auth;
    children?: React.ReactNode
};

const HexAuthProvider : React.FunctionComponent<HexAuthProviderProps> = ({auth, children}) => {
    // States:
    const [contextData, setContextData] = React.useState<ContextType>({
        signedIn: auth.isAuthentificated(),
        auth: auth,
    });

    // Effects:
    React.useEffect(() => {
        const unsubscribe = auth.subscribe((signedIn: boolean | null) => {
            setContextData({
                signedIn: signedIn,
                auth: auth
            });
        });

        auth.initialize();

        return () => unsubscribe();
    }, [setContextData, auth])

    return (
        <HexAuthContext.Provider value={contextData}>
            {children}
        </HexAuthContext.Provider>
    );
};

export default HexAuthProvider;