import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';
import {AsyncWrappedButton, WrappedButton} from 'hex/utils/buttons'

export type Step = {
    name: string;
    optional: boolean;
    body: React.ReactNode;
    isNextEnabled: () => boolean;
    actionOnNext?: () => Promise<boolean>;
    disableBack?: boolean;
};

type StepperProps = {
    steps: Array<Step>;
    onClose: () => void;
};

export default function HorizontalLinearStepper(props: StepperProps) {
    const {steps, onClose} = props;
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());

    const isStepOptional = (index: number) => {
        return steps[index].optional;
    };

    const isStepSkipped = (index: number) => {
        return skipped.has(index);
    };

    const handleNext = async () => {
        const currentStep = steps[activeStep];

        if ((currentStep.actionOnNext !== undefined) && ((!await currentStep.actionOnNext())))
            return;
        
        let newSkipped = skipped;

        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((stepData, index) => {
                    const stepProps: { completed?: boolean } = {};

                    const labelProps: {optional?: React.ReactNode; } = {};

                    if (isStepOptional(index)) {
                        labelProps.optional = (<Typography variant="caption">Опционально</Typography>);
                    }

                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }

                    return (
                        <Step key={stepData.name} {...stepProps}>
                            <StepLabel {...labelProps}>{stepData.name}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            <Divider sx={{marginBottom: 3, marginTop: 3}} />

            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        Заполнение окончено.
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <WrappedButton 
                            onClick={onClose}
                            renderElement={(onClick, ref) => (<Button onClick={onClick} ref={ref}>Выход</Button>)}
                        />
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Box sx={{p: 1, marginTop: 2, minHeight: '50vh'}}>
                        {steps[activeStep].body}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <WrappedButton 
                            onClick={handleBack}
                            renderElement={(onClick, ref) => (
                                <Button ref={ref} color="inherit" disabled={(activeStep === 0) || ((steps[activeStep].disableBack !== undefined) && steps[activeStep].disableBack)} onClick={onClick} sx={{ mr: 1 }}>
                                    Назад
                                </Button>
                            )}
                        />
                        <Box sx={{ flex: '1 1 auto' }} />

                        {isStepOptional(activeStep) && (
                            <WrappedButton 
                                onClick={handleSkip}
                                renderElement={(onClick, ref) => (
                                    <Button ref={ref} color="inherit" onClick={onClick} sx={{ mr: 1 }}>
                                        Пропустить
                                    </Button>
                                )}
                            />
                        )}

                        {activeStep !== steps.length - 1 && 
                            <WrappedButton 
                                onClick={handleNext}
                                renderElement={(onClick, ref) => (
                                    <Button ref={ref} onClick={onClick} disabled={!steps[activeStep].isNextEnabled()}>
                                        {'Далее'}
                                    </Button>
                                )}
                            />
                        }

                        <Button onClick={onClose}>Выход</Button>
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
}