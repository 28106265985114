import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Slide, TextField, TextFieldProps } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { StaticDatePicker } from '@mui/x-date-pickers';
import dayjs, {Dayjs} from 'dayjs'
import React from 'react';
import { dateFormat } from 'pages/index/registrator/actions/steps/date';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type SelectDateProps = {
    open: boolean;
    close: () => void;
    date: string;
    setDate: React.Dispatch<React.SetStateAction<string>>;
    disableFuture?: boolean;
};

const SelectDate: React.FunctionComponent<SelectDateProps> = ({ date, setDate, open, close, disableFuture }) => {
    const onDateChange = (value: Dayjs | null): void => {
        if (value)
        {
            setDate(value.format(dateFormat));
            close();
        }
    }

    return (
        <Dialog open={open} onClose={close} TransitionComponent={Transition} fullWidth maxWidth='xs'>
            <DialogTitle>{'Выбор даты'}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ marginBottom: 1 }}>{'Выбор даты приемов'}</DialogContentText>
                <Divider sx={{ marginBottom: 3 }} />
                    <StaticDatePicker
                        value={dayjs(date, dateFormat)}
                        onChange={onDateChange}
                        componentsProps={
                            {
                                actionBar: {
                                    actions: []
                                },
                            }
                        }
                        disableFuture={disableFuture}
                    />
                <Divider sx={{ marginBottom: 3 }} />
                <DialogActions>
                    <Button variant="contained" onClick={close} color="secondary">Отмена</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}

export default SelectDate;