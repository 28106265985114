import React from 'react';
import Title from 'hex/components/title'
import { Paper, ScopedCssBaseline } from '@mui/material';

// Props:
type IndexPageProps = {

};

const IndexPage: React.FunctionComponent<IndexPageProps> = () => {
    return (
        <React.Fragment>
            <Title title='Главная страница'/>
            <h1>Developer Page</h1>
            <Paper sx={{padding: 1}}>
                <ScopedCssBaseline>
                    
                </ScopedCssBaseline>
            </Paper>
        </React.Fragment>
    )
}

export default IndexPage;