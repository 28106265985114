import React from 'react';
import { ModelType } from '../table/types';
import Modal from 'hex/components/material/modal'


// Props:
type FiltersProps<TModel> = {
    renderFields: (model: TModel, setFieldValue: (field: keyof TModel) => (value: any) => void) => React.ReactNode;
    close: () => void;
    model: TModel;
    defaultModel: TModel;
    setModel: React.Dispatch<React.SetStateAction<TModel>>;
};


function Filters<TModel>(props: FiltersProps<TModel>)
{
    var {model, renderFields, setModel, close, defaultModel} = props;

    const setModelField = React.useCallback((field: keyof TModel) => (value: any) => {
        setModel(oldModel => {
            var copy = {...oldModel};

            copy[field] = value;

            return copy;
        });
    }, [setModel]);

    const onFilters = () => {
        close();
    }

    const onClose = () => {
        setModel(defaultModel);
        close();
    }

    return (
        <>
            <Modal open={true} title='Параметры фильтрации' contentTitle='Установите значения для поиска' closeButtonText='Отменить' closeButtonAction={onClose} actionButtonText='Применить' actionButtonAction={onFilters}>
                {renderFields(model, setModelField)}
            </Modal>
        </>
    );
};

export default Filters;