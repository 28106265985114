import React from 'react';
import { ModelType, getModelId } from '../table/types';
import Modal from 'hex/components/material/modal'
import { ApolloCache, ApolloError, DefaultContext, MutationTuple, OperationVariables } from '@apollo/client';
import Loading from 'hex/components/loading';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';


// Props:
type DeleteSelectedProps<TModel extends ModelType, TData = any, TVariables = OperationVariables, TContext = DefaultContext, TCache extends ApolloCache<any> = ApolloCache<any>> = {
    renderTitle: () => React.ReactNode;
    renderText: (model: TModel) => React.ReactNode;
    useMutation: (model: Array<TModel>) => MutationTuple<TData, TVariables, TContext, TCache>;
    translateError: (error: ApolloError) => string;
    close: () => void;
    models: Array<TModel>;
};


function DeleteSelected<TModel extends ModelType>(props: DeleteSelectedProps<TModel>)
{
    var {useMutation, close, translateError, models, renderText, renderTitle} = props;

    const [deleteMutation, { data, loading, error }] = useMutation(models);

    const onDeleteSelected = () => {
        deleteMutation().then(() => {
            close();
        });
    }

    return (
        <>
            <Loading loading={loading} />
            <Modal open={true} title='Удалить объекты' contentTitle='Удаление объектов' closeButtonText='Отмена' closeButtonAction={close} actionButtonText='Удалить' actionButtonAction={onDeleteSelected}>
                {error !== undefined && (
                    <Alert severity="error">{translateError(error)}</Alert>
                )}
                <Typography variant='body1'>
                    {renderTitle()}
                </Typography>
                <ul>
                    {models.map((model) => (
                        <li>
                            <Typography key={getModelId(model)} variant='body1'>
                                {renderText(model)}
                            </Typography>
                        </li>
                    ))}
                </ul>
            </Modal>
        </>
    );
};

export default DeleteSelected;