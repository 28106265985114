import { ConvertToFieldErrors, ValidationResultData, Validations, ValidatorCallback } from 'hex/hooks/validator';
import { NumberNotNegative, NumberNotZero } from 'hex/validations';

import NumericFieldData from 'hex/components/material/operations/fields/numeric';
import BooleanFieldData from 'hex/components/material/operations/fields/bool';

import { MtmMedicines } from './types';
import { ManyToManyFieldProps } from 'hex/components/material/operations/fields/many_to_many';
import ForeignKeyModal from 'hex/components/material/operations/fields/foreign_key_modal';
import { MedicineFkData } from './foreign_keys';
import { ListItemText } from '@mui/material';
import { useAddUserMedicinesMutation, useDeleteUserMedicineMutation, useUpdateUserMedicineMutation } from 'gql';


const validations : Validations<MtmMedicines> = [
    ["value", NumberNotZero()],
    ["value", NumberNotNegative()],
]

const renderFields = (model: MtmMedicines, setFieldValue: (field: keyof MtmMedicines) => (value: any) => void, validator: ValidatorCallback<MtmMedicines>, validation: ValidationResultData<MtmMedicines>) => {
    return (
        <>
            <ForeignKeyModal label='Препарат' value={model.medicine_id} onChange={setFieldValue("medicine_id")} {...MedicineFkData} onChangeModel={setFieldValue("Medicine")} />
            <BooleanFieldData label='В процентах' value={model.in_percent} onChange={setFieldValue("in_percent")} />
            <NumericFieldData label='Значение' value={model.value} onChange={setFieldValue("value")} validator={validator("value")} errors={ConvertToFieldErrors("value", validation)} decimal />
        </>
    );
}

const renderModel = (model: MtmMedicines) => {
    return (
        <ListItemText primary={`Препарат: ${model.Medicine.name} Значение: ${model.value} ${model.in_percent ? "%" : "руб."}`} />
    );
}

const useDeleteMutation = (model: MtmMedicines) => {
    return useDeleteUserMedicineMutation({
        variables: {
            id: model.id,
        }
    });
}

const useUpdateMutation = (model: MtmMedicines) => {
    return useUpdateUserMedicineMutation({
        variables: {
            id: model.id,
            medicine_id: model.medicine_id,
            in_percent: model.in_percent,
            value: model.value,
            user_id: model.user_id,
        }
    })
}

const useAddMutation = (models: Array<MtmMedicines>) => {
    return useAddUserMedicinesMutation({
        variables: {
            objects: models.map((element) => {
                return {
                    medicine_id: element.medicine_id,
                    in_percent: element.in_percent,
                    value: element.value,
                    user_id: element.user_id,
                }
            })
        }
    })
}

const setParentID: (models: Array<MtmMedicines>, parentId: any) => Array<MtmMedicines> = (models: Array<MtmMedicines>, parentId: any) => {
    var newArray = [...models];

    newArray.forEach((element) => element.user_id = parentId);

    return newArray.map((element) => {
        return {
            medicine_id: element.medicine_id,
            in_percent: element.in_percent,
            value: element.value,
            user_id: element.user_id,
        } as any
    });
}

const getIdFromNotifyer = (data: any) => {
    return data;
}


const defaultModel : MtmMedicines = {
    id: 0,
    user_id: "",
    medicine_id: 0,
    value: 0,
    in_percent: false,
    Medicine: {
        name: "",
        price: 0,
        id: 0,
    },
};

const defaultMtm : Omit<ManyToManyFieldProps<MtmMedicines>, "baseModelID" | "values" | "operationNotifierAfterCreation"> = {
    label: "Препараты",
    renderFields,
    renderModel,
    defaultModel,
    validations,
    useAddMutation,
    useDeleteMutation,
    useUpdateMutation,
    setParentID,
    getIdFromNotifyer
}

export default defaultMtm;