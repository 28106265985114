import React from 'react';
import { StepData } from './types';
import { Box, Grid, List, ListItemButton, ListItemText, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

import {useClientFkSubscription} from 'gql'
import Loading from 'hex/components/loading';

import moment from 'moment-timezone';
import 'moment/locale/ru';
import { TableClient } from 'pages/clients/types';
import { defaultModel, createMutation, renderFields, validations } from 'pages/clients/operations';
import { useErrorChecking } from 'hex/components/material/hooks';
import { useLoading } from 'hex/hooks/loading';
import { ValidationResultData, ValidatorCallback, useValidator } from 'hex/hooks/validator';

const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export enum ClientStepVariant {
    SEARCHING = 1,
    CREATING = 2
};

export type ClientStepState = {
    variant: ClientStepVariant;
    setVariant: React.Dispatch<React.SetStateAction<ClientStepVariant>>;
    onNext: () => Promise<boolean>;
    isEnabled: () => boolean;
    client: TableClient;
    setClient: React.Dispatch<React.SetStateAction<TableClient>>;
    setModelField: (field: keyof TableClient) => (value: any) => void;
    validation: ValidationResultData<TableClient>;
    validator: ValidatorCallback<TableClient>
};

type ClientStepProps = {
    
} & StepData & ClientStepState;

export function useClientStep(data : StepData) : ClientStepState
{
    const [variant, setVariant] = React.useState<ClientStepVariant>(ClientStepVariant.SEARCHING);
    const [client, setClient] = React.useState<TableClient>(defaultModel);

    const [validation, validator, modelValidation] = useValidator<TableClient>(validations);
    const [clientMutation, mutationData] = createMutation(client);
    useErrorChecking(mutationData.error);

    // eslint-disable-next-line
    const [loadingState, setLoading] = useLoading();

    React.useEffect(() => {
        setLoading(mutationData.loading);

        return () => {setLoading(false);};
    }, [setLoading, mutationData.loading]);

    const setModelField = React.useCallback((field: keyof TableClient) => (value: any) => {
        setClient(oldModel => {
            var copy = {...oldModel};

            (copy as any)[field] = value;

            return copy;
        });
    }, [setClient]);

    const onNext = async () => {
        if (variant === ClientStepVariant.SEARCHING)
            return true;
        else 
        {
            if (data.visitData.client_id !== 0)
                return true;

            if (modelValidation(client))
            {
                const result = await clientMutation();
                
                if ((result.data !== undefined) && (result.data?.insert_Client !== undefined) && (result.data.insert_Client?.returning.length !== 0))
                {
                    let visitCopy = {...data.visitData};

                    visitCopy.client_id = result.data.insert_Client?.returning[0].id as number;
                    visitCopy.patient_id = 0;

                    data.setVisit(visitCopy);
                }

                return result.errors === undefined;
            }

            return false;
        }
    }

    const isEnabled = () => {
        if (variant === ClientStepVariant.SEARCHING)
            return data.visitData.client_id !== 0;
        
        return true;
    }

    return {
        variant,
        setVariant,
        onNext,
        isEnabled,
        client,
        setClient,
        setModelField,
        validation,
        validator
    };
}

const ClientStep: React.FunctionComponent<ClientStepProps> = ({visitData, setVisit, variant, setVariant, client, setClient, setModelField, validator, validation}) => {
    const [searchText, setSearchText] = React.useState<string>("");
    const { data, loading, error } = useClientFkSubscription({ variables: {limit: 5, searchText: `%${searchText}%`}});
    useErrorChecking(error);


    const handleChange = (event: React.MouseEvent<HTMLElement>, newVariant: string) => {
        const parsed = parseInt(newVariant, 10);

        if (!isNaN(parsed))
            setVariant(parsed);
        
        setSearchText("");

        let visitCopy = {...visitData};
        visitCopy.client_id = 0; 
        visitCopy.patient_id = 0;
        setVisit(visitCopy);
        setClient(defaultModel);
    };

    const renderSearching = () => (
        <>
            <Box sx={{ width: '100%', marginTop: 0, marginBottom: '4px' }}>
                <TextField value={searchText} onChange={(event) => setSearchText(event.target.value)} margin="dense" label='Поиск' type="text" fullWidth />
            </Box>
            <List component="nav" aria-label="main mailbox folders" sx={{minWidth: '540px'}}>
                {data !== undefined && data.Client.map((client) => 
                (
                    <ListItemButton key={client.id} onClick={() => {
                        let visitCopy = {...visitData};
                        visitCopy.client_id = client.id;
                        visitCopy.patient_id = 0;
                        setVisit(visitCopy);
                    }} sx={(theme) => ({bgcolor: visitData.client_id === client.id ? theme.palette.secondary.light : undefined})}>
                        <ListItemText primary={client.name} secondary={
                            <React.Fragment>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    {client.phone_number}{(client.email ? ` - ${client.email}` : "")}
                                </Typography>
                                {` Паспорт: ${client.passport} от ${moment.utc(client.passport_when).tz(clientTimeZone).format("DD-MM-YYYY")}`}
                            </React.Fragment>
                        } />
                    </ListItemButton>   
                ))}
            </List>
        </>
    )

    const renderCreating = () => (<Box sx={{minWidth: '540px'}}>{renderFields(client, setModelField, validator, validation, visitData.client_id !== 0)}</Box>)

    return (
        <>
            <Loading loading={loading} />
            <Grid container sx={{flexDirection: 'row'}} spacing={2}>
                <Grid item sx={{marginTop: '8px'}}>
                    <ToggleButtonGroup color="primary" value={variant} exclusive onChange={handleChange} orientation="vertical" >
                        <ToggleButton value={ClientStepVariant.SEARCHING}>Поиск</ToggleButton>
                        <ToggleButton value={ClientStepVariant.CREATING}>Создание</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item>
                    {variant === ClientStepVariant.SEARCHING && renderSearching()}
                    {variant === ClientStepVariant.CREATING && renderCreating()}
                </Grid>
            </Grid>
        </>
    );
}

export default ClientStep;