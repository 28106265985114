import React from 'react';
import { MedicineVisitType, MedicinesReceptionVisitType } from './types';
import { useMedicinesCardsSubscription } from 'gql';
import { useErrorAndLoadChecking } from 'hex/components/material/hooks';
import Modal from 'hex/components/material/modal';
import { Box, Button, CircularProgress, Grid, IconButton, Paper, TextField, Typography } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

type MedicinesCardProps = {
    open: boolean;
    onClose: () => void;
    medicines: Array<MedicineVisitType>;
    dataReceptions: MedicinesReceptionVisitType[] | undefined;
    on_delete: (model: MedicinesReceptionVisitType) => Promise<void>;
    on_add: (model: MedicinesReceptionVisitType) => Promise<void>;
    on_update: (model: MedicinesReceptionVisitType) => Promise<void>;
};

const MedicinesCard : React.FunctionComponent<MedicinesCardProps> = ({open, onClose, medicines, dataReceptions, on_delete, on_add, on_update}) => {
    const { data, loading, error } = useMedicinesCardsSubscription();

    useErrorAndLoadChecking({error, loading});

    const preparedList : MedicinesReceptionVisitType[] = React.useMemo(() => {
        if (data === undefined) return [];

        var cards : MedicinesReceptionVisitType[] = data.DoctorsCard.map(x => ({
            id: 0,
            value: 0,
            medicine_id: x.medicine_id,
            reception_id: 0,
            Medicine: x.Medicine,
            done: false,
        }));

        if (dataReceptions !== undefined)
        {
            var haveInCards = dataReceptions.filter(item => cards.findIndex(x => x.medicine_id === item.medicine_id) !== -1);
            var haventInCards = dataReceptions.filter(item => cards.findIndex(x => x.medicine_id === item.medicine_id) === -1);

            haveInCards.forEach(item => {
                var index = cards.findIndex(x => x.medicine_id === item.medicine_id);

                cards[index].id = item.id;
                cards[index].value = item.value;
                cards[index].reception_id = item.reception_id;
            });

            cards = cards.concat(haventInCards);
        }

        return cards;
    }, [data, medicines, dataReceptions]);

    const IncrementData = (item : MedicinesReceptionVisitType) => async () => {
        let new_item = {...item};
        new_item.value++;

        if (item.value === 0)
        {
            // ADD:
            await on_add(new_item);
        }
        else
        {
            // UPDATE:
            await on_update(new_item);
        }
    }

    const DecrementData = (item : MedicinesReceptionVisitType) => async () => {
        let new_item = {...item};
        new_item.value--;

        if (item.value === 1)
        {
            // REMOVE:
            await on_delete(new_item);
        }
        else
        {
            // UPDATE:
            await on_update(new_item);
        }
    }

    const OnChangeData = (item : MedicinesReceptionVisitType) => async (event: React.ChangeEvent<HTMLInputElement>) => {
        let new_item = {...item};
        let value = parseInt(event.target.value, 10);
        new_item.value = value;

        if ((value === 0) && (item.id > 0))
            await on_delete(new_item);
        else if ((value > 0) && (item.id == 0))
            await on_add(new_item)
        else
            await on_update(new_item);
    }

    const renderBody = () => {
        if (data === undefined)
            return (
                <>
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>
                </>
            );

        return (
            <Grid container spacing={2}>
                {preparedList.map(item => (
                    <Grid item alignContent='center' justifyContent='center' sx={{mb: 2}} key={item.Medicine?.name}>
                        <Box>
                            <Paper sx={theme => ({p: 2, backgroundColor: "#eeeeee"})}>
                                <Typography sx={{width: "100%", textAlign: "center", mb: 2}}>{item.Medicine?.name}</Typography>
                                <Grid container alignContent='center' justifyContent='center' spacing={1}>
                                    <Grid item>
                                        <IconButton size='large' sx={{mt: "4px"}} onClick={IncrementData(item)}><AddIcon /></IconButton>
                                    </Grid>
                                    <Grid item>
                                        <TextField type="number" value={item.value} onChange={OnChangeData(item)} InputProps={{
                                            inputProps: { min: 0, step: 0.01 }
                                        }}/>
                                    </Grid>
                                    <Grid item>
                                        <IconButton size='large' sx={{mt: "4px"}} onClick={DecrementData(item)} disabled={item.value === 0}><RemoveIcon /></IconButton>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        )
    }

    return (
        <Modal open={open} title='Амбулаторная карточка' contentTitle='Список расходных материалов' fullScreen closeButtonText='Закрыть' closeButtonAction={onClose}>
            {renderBody()}
        </Modal>
    )
}

export default MedicinesCard;