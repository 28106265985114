import React from 'react';
import TextField from '@mui/material/TextField';
import { FormGroup } from '@mui/material';

// Props:
type TextAreaFieldDataProps = {
    label?: string;
    value: any;
    onChange: (value: any) => any;
    validator?: (value: any) => void;
    errors?: Array<string>;
    disabled?: boolean;
    rows: number;
    maxRows: number;
};

// Element:
const TextAreaFieldData : React.FunctionComponent<TextAreaFieldDataProps> = ({label, value, onChange, validator, errors, disabled, rows, maxRows}) => {
    const onChangeEventWrapper = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChange(event.target.value);
        if (validator !== undefined) validator(event.target.value);
    };

    const error = (errors !== undefined) ? errors.length !== 0 : false;
    const helperText = (errors !== undefined) ? errors.join("; ") : undefined;

    return (
        <FormGroup>
            <TextField 
                disabled={disabled} 
                error={error} 
                helperText={helperText} 
                margin="dense" 
                label={label} 
                multiline
                rows={rows}
                maxRows={maxRows}
                fullWidth
                onChange={onChangeEventWrapper} 
                value={value || ""}
            />
        </FormGroup>
    );
}

export default TextAreaFieldData;