import React from 'react';

import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Pagination, {PaginationProps} from './pagination';

import Table, {EnhancedTableData} from './table'
import { GlobalActionType, ModelType } from './types';

// Props:
type HexTableProps<TModel extends ModelType> = {
    globalActions?: Array<GlobalActionType>;
} & PaginationProps & EnhancedTableData<TModel>;

function HexTable<TModel extends ModelType>({ limit, offset, count, onPageChange, models, headCells, onOrderSet, order, orderBy, globalActions, actions, selectedActions }: HexTableProps<TModel>) {
    // Avoid a layout jump when reaching the last page with empty rows.
    var modelsLength = (limit > count) ? limit : count;
    const emptyRows = offset > 0 ? Math.max(0, (1 + (offset / limit)) * limit - modelsLength) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Paper>
                <Table actions={actions} selectedActions={selectedActions} limit={limit} count={count} emptyRows={emptyRows} models={models} headCells={headCells} onOrderSet={onOrderSet} order={order} orderBy={orderBy} globalActions={globalActions} />
                <Pagination limit={limit} offset={offset} count={count} onPageChange={onPageChange} />
            </Paper>
        </Box>
    )
}

export default HexTable;