import React from 'react'

export type ModelType = {
    ID: any;
} | {
    id: any;
}

export function getModelId<TModel extends ModelType>(model: ModelType)
{
    if ("ID" in model)
        return model.ID;
    else
        return model.id;
}

export function setModelId<TModel extends ModelType>(model: ModelType, id: any)
{
    if ("ID" in model)
        model.ID = id;
    else
        model.id = id;
}

export type Order = 'asc' | 'desc';

export interface HeadCell<TModel extends ModelType> {
    id: keyof TModel;
    label: string;
    sort: boolean;
    render: (data: TModel) => React.ReactNode;
}

export type GlobalActionType = {
    label: string;
    icon: React.ReactNode;
    action: () => void;
};

export type SelectedActionType<TModel extends ModelType> = {
    label: string;
    icon: React.ReactNode;
    action: (models: Array<TModel>) => void;
};

export type ActionType<TModel extends ModelType> = {
    label: string;
    enabled: boolean | ((model: TModel) => boolean);
    action: (model: TModel) => void;
};

// Enable --strictNullChecks
type NullableKeys<T, K extends keyof T> = K extends unknown ? (undefined extends T[K] ? K : never) : never;
type NullableKeysOf<T> = NullableKeys<T, keyof T>;