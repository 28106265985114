import React from 'react';
import {useLoading} from 'hex/hooks/loading'

type LoadingProps = {
    loading: boolean
};

const Loading: React.FunctionComponent<LoadingProps> = ({loading}) => {
    // eslint-disable-next-line
    const [loadingState, setLoading] = useLoading();

    React.useEffect(() => {
        setLoading(loading);

        return () => {setLoading(false);};
    }, [setLoading, loading]);

    return (
        <React.Fragment />
    );
}

export default Loading;
