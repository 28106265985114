import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Paper, Slide, TextField, Typography, useTheme } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { CheckPatientCard, GetPatientCardName, PatientCard, VisitType, defaultPatientCardValue } from 'pages/index/doctor/types';

import 'moment/locale/ru';

import { dateDiff } from 'hex/utils/dates';
import { PetSexToString } from 'pages/pets/types';

import {useSaveEditVisitMutation, useGetMedicineUnitsQuery} from 'gql'
import { useErrorChecking } from 'hex/components/material/hooks';
import Loading from 'hex/components/loading';
import FilesUploader from 'pages/index/doctor/files_uploader';

import DocumentsBlock from 'pages/index/doctor/documents';


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type VisitDetailProps = {
    open: boolean;
    close: () => void;
    visit: VisitType;
};

const VisitDetail: React.FunctionComponent<VisitDetailProps> = ({open, close, visit}) => {
    const theme = useTheme();
    const [patientCard, setPatientCard] = React.useState<PatientCard>(CheckPatientCard(visit.patient_card) ? visit.patient_card : defaultPatientCardValue);
    const [documentsOpen, setDocumentsOpen] = React.useState<boolean>(false);

    const medicineUnitsData = useGetMedicineUnitsQuery({fetchPolicy: 'network-only'});

    const [saveVisitMutation, saveVisitMutationData] = useSaveEditVisitMutation();
    
    useErrorChecking(saveVisitMutationData.error);
    useErrorChecking(medicineUnitsData.error);


    const renderPatientCard = (from: number, to: number) => {
        const fields = Object.keys(defaultPatientCardValue);

        return fields.slice(from, to).map((fieldName, index) => {
            const onPatientCardChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setPatientCard((oldValue) => {
                    var copy = {...oldValue};

                    (copy as any)[fieldName] = event.target.value;

                    return copy;
                })
            }

            return (
                <Box sx={{m: 0, width: "100%"}} key={index}>
                    <TextField margin="dense" label={GetPatientCardName(fieldName as keyof PatientCard)} type="text" fullWidth onChange={onPatientCardChange} value={patientCard[fieldName as keyof PatientCard]} />
                </Box>
        )});
    }

    const onEndVisit = async () => {
        const result = await saveVisitMutation({variables: {
            id: visit.id,
            patient_card: patientCard,
        }});

        if (result.errors !== undefined)
            return;
            
        close();
    }

    return (
        <Dialog 
            open={open} 
            onClose={close} 
            TransitionComponent={Transition} 
            fullScreen 
            PaperProps={{
                style: {
                    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800],
                }
            }}
        >
            <Loading loading={saveVisitMutationData.loading} />
            <DialogTitle>{`Прием пациента: ${visit.Client.name}`}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ marginBottom: 1 }}>{'Редактирование приема'}</DialogContentText>
                <Divider sx={{ marginBottom: 3 }} />
                <Grid container spacing={2}>
                    <Grid item sx={{flexBasis: '33.33333333333%'}}>
                        <Paper sx={{p: 1, height: '100%'}}>
                            <Typography variant='h6'>Карточка пациента:</Typography>
                            <Typography variant='body1'>Владелец: <b>{visit.Client.name}</b></Typography>
                            <Typography variant='body1'>Телефон: <b>{visit.Client.phone_number}</b></Typography>
                            <Typography variant='body1'>Кличка животного: <b>{visit.Pet.name}</b></Typography>
                            <Typography variant='body1'>Порода: <b>{visit.Pet.breed || '<Не указано>'}</b></Typography>
                            <Typography variant='body1'>Вид: <b>{visit.Pet.PetType.name}</b></Typography>
                            <Typography variant='body1'>Возраст: <b>{visit.Pet.birthdate ? dateDiff(new Date(visit.Pet.birthdate), new Date(Date.now())) : '<Не указан>'}</b></Typography>
                            <Typography variant='body1'>Пол животного: <b>{PetSexToString(visit.Pet.sex)}</b></Typography>
                            <br />
                            <Typography variant='body1'>Назначил: <b><i>{visit.From.Name}</i></b></Typography>
                        </Paper>
                    </Grid>
                    <Grid item sx={{flexBasis: '33.33333333333%'}}>
                        <Paper sx={{p: 1}}>
                            {renderPatientCard(0, 13)}
                        </Paper>
                    </Grid>
                    <Grid item sx={{flexBasis: '33.33333333333%'}}>
                        <Paper sx={{p: 1}}>
                            {renderPatientCard(13, 26)}
                        </Paper>
                    </Grid>
                </Grid>

                <FilesUploader visit_id={visit.id} />

                <Divider sx={{ marginBottom: 3, marginTop: 3 }} />

                {documentsOpen && <DocumentsBlock visit_id={visit.id} department_id={visit.department_id} onClose={() => setDocumentsOpen(false)} />}

                <DialogActions>
                    <Button variant="contained" onClick={close} color="secondary">Отмена</Button>
                    <Button variant="contained" onClick={onEndVisit} color="primary">Сохранить</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}

export default VisitDetail;