import * as React from 'react';
import { IMaskInput } from 'react-imask';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { FormHelperText, OutlinedInput } from '@mui/material';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="0000 000000"
        inputRef={ref as any}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);


interface State {
  textmask: string;
}

type PhoneFieldProps = {
    label?: string;
    value: any;
    onChange: (value: any) => any;
    validator?: (value: any) => void;
    errors?: Array<string>;
    disabled?: boolean;
};

export default function FormattedInputs(props: PhoneFieldProps) {
  const {label, onChange, value, errors, validator, disabled} = props;

  const id = React.useId();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
      if (validator !== undefined) validator(event.target.value);
  };

    const error = (errors !== undefined) ? errors.length !== 0 : false;
    const helperText = (errors !== undefined) ? errors.join("; ") : undefined;

  return (
    <Box
      sx={{ width: '100%', marginTop: '10px', marginBottom: '4px'}}
    >
      <FormControl error={error} variant="outlined" sx={{ width: '100%'}}>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <OutlinedInput
          id={id}
          value={value}
          onChange={handleChange}
          inputComponent={TextMaskCustom as any}
          label={label}
          disabled={disabled}
        />
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
}