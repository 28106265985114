import React from 'react';
import { Box, FormControl, FormGroup, IconButton, InputAdornment, InputLabel, List, ListItemButton, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { getModelId, ModelType } from '../../table/types';
import Modal from '../../modal';
import { Visibility } from '@mui/icons-material';

// Props:
export type ForeignKeyProps<TModel extends ModelType> = {
    label?: string;
    value: any;
    onChange: (value: any) => any;
    onChangeModel?: (model: any) => any;
    useSearch: (searchText: string) => Array<TModel> | undefined;
    useModel: (id: any) => TModel | undefined;
    renderModel: (model: TModel) => React.ReactNode;
    renderSelectedModel: (model: TModel) => string;
    disabled?: boolean;
};

function ForeignKeyModal<TModel extends ModelType>({label, value, onChange, useSearch, renderModel, renderSelectedModel, onChangeModel, disabled, useModel}: ForeignKeyProps<TModel>)
{
    const id = React.useId();
    const [searchText, setSearchText] = React.useState<string>("");
    const selectedModel = useModel(value);
    const [open, setOpen] = React.useState<boolean>(false);
    const models = useSearch(searchText);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleClickBrowse = () => setOpen(true);

    const renderModels = () =>
    {
        if (models === undefined)
            return (<></>)
        
        return models.map((model) => {
            return (
                <ListItemButton onClick={(event) => {
                    if (onChangeModel) onChangeModel(model);
                    onChange(getModelId(model));
                    setOpen(false); 
                    setSearchText("");
                }} key={getModelId(model)}>
                    {renderModel(model)}
                </ListItemButton>
            )
        });
    }

    const renderModelStrWithCheck = () => {
        if (selectedModel !== undefined)
            return renderSelectedModel(selectedModel);
        else
            return '<Загрузка объектов...>';
    }

    const renderValue = () => {
        if (typeof value === 'string' || value instanceof String)
            return (value === "") ? "<Не выбрано>" : renderModelStrWithCheck();
        else
            return (value === 0) ? "<Не выбрано>" : renderModelStrWithCheck();
    }

    return (
        <>
            <FormGroup>
                <FormControl sx={{ width: '100%', marginTop: '10px', marginBottom: '4px' }} variant="outlined">
                <InputLabel htmlFor={id}>{label}</InputLabel>
                <OutlinedInput
                    id={id}
                    type="text"
                    disabled
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleClickBrowse}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                disabled={disabled}
                            >
                                <Visibility />
                            </IconButton>
                        </InputAdornment>
                    }
                    label={label}
                    value={renderValue()}
                />
                </FormControl>
            </FormGroup>
            <Modal open={open} title={`Выбор: ${label}`} contentTitle='Поиск по возможным вариантам' closeButtonText='Отмена' closeButtonAction={() => setOpen(false)}>
                <Box sx={{ width: '100%', marginTop: '10px', marginBottom: '4px' }}>
                    <TextField label="Поисковый запрос" variant="standard" value={searchText} onChange={(event) => setSearchText(event.target.value)} sx={{ width: '100%' }} />
                </Box>
                <List component="nav" aria-label="main mailbox folders">
                    {renderModels()}
                </List>
            </Modal>
        </>
    );
};

export default ForeignKeyModal;