import React from 'react';
import Title from 'hex/components/title'

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';

import Loading from 'hex/components/loading';
import {useAuth, useAuthState} from 'hex/hooks/auth';
import { useSnackbar } from 'notistack';
import {Navigate} from 'react-router-dom'
import { getTextError } from 'hex/utils/errors';

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Developer © '}
            <Link color="inherit" href="https://alex-raven.com/">
                Hex Soft
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// Props:
type LoginPageProps = {

};

const LoginPage: React.FunctionComponent<LoginPageProps> = () => {
    // Auth:
    // eslint-disable-next-line
    const { enqueueSnackbar } = useSnackbar();
    const auth = useAuth();
    const signedIn = useAuthState();
    var isLoading = signedIn === null;

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        var login = data.get('login');
        var password = data.get('password');

        if (login !== '' && password !== '') {
            auth.login(login as string, password as string)
            .catch((reason) => {
                var text = getTextError(reason);
                enqueueSnackbar("Ошибка: " + text, {variant: 'error'});
            });
        }
    };

    if ((auth !== undefined) && (auth.isAuthentificated()))
    {
        return (<Navigate to="/" />);
    }

    return (
        <React.Fragment>
            <Title title='Вход в систему' />
            <Loading loading={isLoading} />
            <Grid container component="main" sx={{ height: '100vh' }}>
                <Grid item xs={false} sm={4} md={7}  sx={{ backgroundImage: 'url(/background.jpg)', backgroundRepeat: 'no-repeat', backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900], backgroundSize: 'cover', backgroundPosition: 'center', }} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box sx={{ my: 8, mx: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Авторизация в системе
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField margin="normal" required fullWidth id="login" label="Логин" name="login" autoComplete="login" autoFocus disabled={isLoading} />
                            <TextField margin="normal" required fullWidth name="password" label="Пароль" type="password" id="password" autoComplete="current-password" disabled={isLoading} />
                            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={isLoading} >
                                Вход
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link href="mailto:owner@alex-raven.com" variant="body2">
                                        Забыли пароль?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="mailto:owner@alex-raven.com" variant="body2">
                                        {"Нет аккаунта? Вопросы с регистрацией?"}
                                    </Link>
                                </Grid>
                            </Grid>
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default LoginPage;