import { ModelType } from "../table/types"



class OperationNotifier<TModel extends ModelType> {
    onBeforeOperation: Array<{ name: string, action: (model: TModel) => void}>;
    onAfterOperation: Array<{ name: string, action: (model: TModel, id: any) => void}>;

    constructor() {
        this.onBeforeOperation = [];
        this.onAfterOperation = [];
    }

    
    AddOperationNotifierBeforeOperation(name: string)
    {
        return (action: (model: TModel) => void) => {
            const index = this.onBeforeOperation.findIndex((element) => element.name === name);
            if (index === -1)
                this.onBeforeOperation.push({name, action});
            else
                this.onBeforeOperation[index] = {name, action};
        }
    }

    AddOperationNotifierAfterOperation(name: string)
    {
        return (action: (model: TModel, id: any) => void) => {
            const index = this.onAfterOperation.findIndex((element) => element.name === name);
            if (index === -1)
                this.onAfterOperation.push({name, action});
            else
                this.onAfterOperation[index] = {name, action};
        }
    }

    CallOperationNotifierBeforeOperation(name: string, model: TModel)
    {
        this.onBeforeOperation.filter((element) => element.name === name).forEach((element) => element.action(model));
    }

    CallOperationNotifierAfterOperation(name: string, model: TModel, id: any)
    {
        this.onAfterOperation.filter((element) => element.name === name).forEach((element) => element.action(model, id));
    }

    CallAllOperationNotifierBeforeOperation(model: TModel)
    {
        this.onBeforeOperation.forEach((element) => element.action(model));
    }

    CallAllOperationNotifierAfterOperation(model: TModel, id: any)
    {
        this.onAfterOperation.forEach((element) => element.action(model, id));
    }
}


export default OperationNotifier;