import React from "react";

export type CustomEvent = {
    currentTarget: {
        disabled: boolean;
    }
}

export type EventCallback<TModel extends CustomEvent> = (event: TModel) => void;
export type EventCallbackAsync<TModel extends CustomEvent> = (event: TModel) => Promise<void>;

export type WrappedBtnProps<TModel extends CustomEvent> = {
    onClick: EventCallback<TModel>,
    renderElement: (onClick: EventCallback<TModel>, ref: React.MutableRefObject<any>) => void,
}

export function WrappedButton<TModel extends CustomEvent>({onClick, renderElement} : WrappedBtnProps<TModel>) 
{
    const ref = React.useRef<any>();

    const onClickWrapper = React.useMemo<EventCallback<TModel>>(() => {
        return async (event: TModel) => {
            if (ref.current) ref.current.disabled = true;
        
            setTimeout(() => {
                try {
                    onClick(event);
                } 
                catch (error) 
                {
                    console.log(error);
                }
        
                if (ref.current) ref.current.disabled = false;
            }, 200);
        }
    }, [onClick, ref])

    return (
        <>
            {renderElement(onClickWrapper, ref)}
        </>
    )
}

export type AsyncWrappedBtnProps<TModel extends CustomEvent> = {
    onClick: EventCallbackAsync<TModel>,
    renderElement: (onClick: EventCallbackAsync<TModel>, ref: React.MutableRefObject<any>) => void,
}

export function AsyncWrappedButton<TModel extends CustomEvent>({onClick, renderElement} : AsyncWrappedBtnProps<TModel>) 
{
    const ref = React.useRef<any>();

    const onClickWrapper = React.useMemo<EventCallbackAsync<TModel>>(() => {
        return async (event: TModel) => {
            if (ref.current) ref.current.disabled = true;
        
            setTimeout(async () => {
                try {
                    await onClick(event);
                } 
                catch (error) 
                {
                    console.log(error);
                }
        
                if (ref.current) ref.current.disabled = false;
            }, 200);
        }
    }, [onClick, ref])

    return (
        <>
            {renderElement(onClickWrapper, ref)}
        </>
    )
}