import React from 'react'
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import { useGetMedicinesUnitsAndServicesQuery, useGetUsersPricesSubscription } from 'gql';
import { useErrorAndLoadChecking } from 'hex/components/material/hooks';
import Modal from 'hex/components/material/modal';

import ManyToManyField from 'hex/components/material/operations/fields/many_to_many';

import MtmMedicinesData from './mtm/medicines'
import MtmServicesData from './mtm/services'
import OperationNotifier from 'hex/components/material/operations/operation_notifier';
import { TableUsers } from './types';

type PricesProps = {
    onClose: () =>void;
    doctor_id: any; // UUID
};

const PricesBlock : React.FC<PricesProps> = ({onClose, doctor_id}) => {
    const servicesAndMedicinesUnitsData = useGetMedicinesUnitsAndServicesQuery({
        fetchPolicy: 'network-only',
    });

    const { data, loading, error } = useGetUsersPricesSubscription({
        variables: {
            id: doctor_id,
        }
    });

    const operationNotifier = React.useMemo<OperationNotifier<TableUsers>>(() => new OperationNotifier<TableUsers>(), []);

    useErrorAndLoadChecking({loading, error});
    useErrorAndLoadChecking(servicesAndMedicinesUnitsData);

    const onSave = () => {
        operationNotifier.CallAllOperationNotifierAfterOperation({} as any, doctor_id);
        onClose();
    }

    const renderBody = () => {
        if ((data === undefined) || (servicesAndMedicinesUnitsData.data === undefined))
            return (
                <>
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>
                </>
            );

        return (
            <>
                <ManyToManyField values={data.Users[0].UserMedicinePayments} {...MtmMedicinesData} operationNotifierAfterCreation={operationNotifier.AddOperationNotifierAfterOperation("MedicinesMtm")} />
                <ManyToManyField values={data.Users[0].UserServicePayments} {...MtmServicesData} operationNotifierAfterCreation={operationNotifier.AddOperationNotifierAfterOperation("ServicesMtm")} />
            </>
        )
    }

    return (
        <Modal fullScreen open title='Зарплата' contentTitle='Заработная плата врача' closeButtonText='Отмена' closeButtonAction={onClose} actionButtonText='Сохранить' actionButtonAction={onSave}>
            {renderBody()}
        </Modal>
    );
}

export default PricesBlock;