import React from 'react';
import moment from 'moment';
import Title from 'hex/components/title'
import { useAuth } from 'hex/hooks/auth';
import { Accordion, AccordionDetails, AccordionSummary, Box, Fab, Grid, Paper, Typography, Zoom } from '@mui/material';
import { dateFormat, timeFormat } from 'pages/index/doctor/steps/date';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useErrorAndLoadChecking } from 'hex/components/material/hooks';
import { useTakeDoctorsEditVisitsSubscription } from 'gql';
import SelectDate from 'pages/index/doctor/actions/date';
import { dateDiff } from 'hex/utils/dates';
import { TranslateStatus } from 'config';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { VisitType } from 'pages/index/doctor/types';

import DocumentsBlock from 'pages/index/doctor/documents';

import VisitForm from './editors/visit'
import ReceptionForm from './editors/reception'
import { Reception } from './editors/types';
import ReceptionView from './reception_view';

const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;


type IndexProps = {

}

const Index: React.FunctionComponent<IndexProps> = () => {
    const auth = useAuth();

    const [dateOpen, setDateOpen] = React.useState<boolean>(false);
    const [visitEditOpen, setVisitEditOpen] = React.useState<boolean>(false);
    const [receptionEditOpen, setReceptionEditOpen] = React.useState<boolean>(false);

    const [currentDate, setCurrentDate] = React.useState<string>(moment(Date.now()).format(dateFormat));
    const [currentVisitEdit, setCurrentVisitEdit] = React.useState<VisitType | undefined>(undefined);
    const [currentReceptionEdit, setCurrentReceptionEdit] = React.useState<Reception | undefined>(undefined);

    const { data, loading, error } = useTakeDoctorsEditVisitsSubscription({
        variables: {
            date: currentDate,
            doctor_id: auth.getUserId(),
        },
    });

    const isEditingIsDisabled = React.useMemo(() => moment(currentDate, dateFormat).diff(moment(Date.now()), 'days') < -31, [currentDate]);

    useErrorAndLoadChecking({ loading, error });

    const renderElements = () => {
        if (data === undefined)
            return (<></>);

        return data.Department.map((element) => (
            <>
                <Box key={element.id} sx={{ m: 1 }}>
                    <Paper sx={{ p: 2, bgcolor: 'primary.main', color: 'common.white', textAlign: 'left' }}>{element.name}</Paper>
                </Box>
                {element.Visits.map((visit) => (
                    <Box key={visit.id} sx={{ m: 1 }}>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Grid container spacing={2} alignItems='center'>
                                    <Grid item>
                                        <Typography variant='button'>{!visit.without_time ? moment.utc(visit.time, timeFormat).tz(clientTimeZone).format('HH:mm') : '<--:-->'}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='button'>{visit.Client.name}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='caption'>{`${visit.Pet.name} (${visit.Pet.PetType.name})`}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='caption'>{`${visit.Pet.birthdate ? dateDiff(new Date(visit.Pet.birthdate), new Date(Date.now())) : '<Не указан>'} (${visit.Pet.birthdate ? moment.utc(visit.Pet.birthdate).tz(clientTimeZone).format("DD-MM-YYYY") : 'Не указан'})`}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='caption'>{TranslateStatus(visit.status)}</Typography>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ReceptionView hideServicesSum visit={visit} isEditingIsDisabled={isEditingIsDisabled} onCommonInformationEdit={() => {
                                    setCurrentVisitEdit(visit); 
                                    setVisitEditOpen(true);
                                }} onReceptionEdit={(reception) => {
                                    setCurrentVisitEdit(visit);
                                    setCurrentReceptionEdit(reception);
                                    setReceptionEditOpen(true);
                                }}
                                documentsBlock={(props) => (<DocumentsBlock {...props} />)} />
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                ))}
            </>
        ))
    }

    const renderBody = () => {
        if (data === undefined)
            return (<></>);

        if (data.Department.length === 0)
            return (<Typography variant='h6'>Нет ни одной записи о приеме</Typography>)

        return (
            <>
                {renderElements()}
            </>
        )
    }

    return (
        <React.Fragment>
            <Title title='Редактирование приемов' />
            <h1>Редактирование приемов</h1>

            {renderBody()}

            <Zoom in={true} timeout={1000} style={{ transitionDelay: '100ms' }} unmountOnExit>
                <Fab sx={{ position: 'absolute', bottom: 50, right: 50 }} color='primary' onClick={() => setDateOpen(true)}>
                    <CalendarTodayIcon />
                </Fab>
            </Zoom>
            {dateOpen && <SelectDate disableFuture open={dateOpen} close={() => setDateOpen(false)} date={currentDate} setDate={setCurrentDate} />}
            {visitEditOpen && currentVisitEdit !== undefined && <VisitForm open={visitEditOpen} close={() => { setVisitEditOpen(false); setCurrentVisitEdit(undefined); }} visit={currentVisitEdit} />}
            {receptionEditOpen && currentVisitEdit !== undefined && currentReceptionEdit !== undefined && <ReceptionForm open={receptionEditOpen} close={() => { setReceptionEditOpen(false); setCurrentVisitEdit(undefined); setCurrentReceptionEdit(undefined); }} reception={currentReceptionEdit} patient_name={currentVisitEdit.Client.name} />}
        </React.Fragment>
    )
}

export default Index;