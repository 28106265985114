import React from 'react';
import Modal from 'hex/components/material/modal'
import Typography from '@mui/material/Typography';
import { ModelType } from 'hex/components/material/table/types';


// Props:
type DeleteProps<TModel extends ModelType> = {
    onAction: (model: TModel) => Promise<void>;
    close: () => void;
    model: TModel;
};


function Delete<TModel extends ModelType>(props: DeleteProps<TModel>)
{
    var {onAction, close, model} = props;

    const onDelete = async () => {
        await onAction(model);
        close();
    }

    return (
        <>
            <Modal open={true} title='Удалить объект' contentTitle='Удаление объекта' closeButtonText='Отмена' closeButtonAction={close} actionButtonText='Удалить' actionButtonAction={onDelete}>
                <Typography variant='body1'>
                    {'Вы действительно хотите удалить объект?'}
                </Typography>
            </Modal>
        </>
    );
};

export default Delete;