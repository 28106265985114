import { GetReceptionsOfVisitQuery } from "gql";
import React from "react";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Grid, StepButton, Typography } from "@mui/material";
import { useAuth } from "hex/hooks/auth";

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';


type StepperHistoryProps = {
    data: GetReceptionsOfVisitQuery;
};

const calculateSum = (services: Array<{price: any, value: any}>) => {
    let sum = 0;
    services.forEach((element) => sum += (element.price as number * element.value as number));

    return sum;
}

const StepperHistory : React.FC<StepperHistoryProps> = ({data}) => {
    const auth = useAuth();

    const steps = data.Reception.map((reception) => `Врач - ${reception.Doctor.Name} - (направил: ${reception.From.Name})`);
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const renderReception = (reception: GetReceptionsOfVisitQuery["Reception"][0]) => {
        return (
            <>
                <Typography variant='button'>
                    <b>Примечание:</b>
                </Typography>
                <Typography style={{whiteSpace: 'pre-line'}}>
                    {reception.internal_recomendation}
                </Typography>
                <Typography variant='button'>
                    <b>Рекомендации:</b>
                </Typography>
                <Typography style={{whiteSpace: 'pre-line'}}>
                    {reception.patient_recomendation}
                </Typography>
                <Typography variant='button'>
                    <b>Услуги:</b>
                </Typography>
                <Typography>
                    {reception.Services.length === 0 && <Typography>Отсутствуют</Typography>}
                    {reception.Services.length !== 0 && 
                        <ul style={{margin: 0}}>
                            {reception.Services.map((service) => (<li key={service.id}>{service.Service.name} {auth.getUserId() === reception.doctor_id ? `${service.price} * ${service.value} = ${service.price as number * service.value as number}` : ''}</li>))}
                        </ul>
                    }
                    {
                        auth.getUserId() === reception.doctor_id && <Typography>Сумма: {calculateSum(reception.Services)} руб.</Typography>
                    }
                </Typography>
                <Typography variant='button'>
                    <b>Препараты:</b>
                </Typography>
                <Typography>
                    {reception.Medicines.length === 0 && <Typography>Отсутствуют</Typography>}
                    {reception.Medicines.length !== 0 && 
                        <ul style={{margin: 0}}>
                            {reception.Medicines.map((medicine) => (<li key={medicine.id}>{medicine.done ? '✓' : ''}{medicine.Medicine.name}</li>))}
                        </ul>
                    }
                </Typography>
            </>
        );
    }
    
    return (
        <Box sx={{ width: '100%' }}>
            <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => {
                const labelProps: {
                    optional?: React.ReactNode;
                } = {};

                return (
                    <Step key={label}>
                        <StepButton color="inherit" onClick={() => setActiveStep(index)}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </StepButton>
                    </Step>
                );
            })}
            </Stepper>
            
            <React.Fragment>
                <Box sx={{pt: 2}}>
                    <Grid container>
                        <Grid item>
                            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>Назад</Button>
                        </Grid>
                        <Grid item sx={{marginLeft: 'auto'}}>
                            <Button onClick={handleNext} disabled={activeStep === steps.length - 1}>Далее</Button>
                        </Grid>
                    </Grid>
                    <Box sx={(theme) => ({bgcolor: theme.palette.info.light, color: theme.palette.info.contrastText, p: 2})}>
                        {renderReception(data.Reception[activeStep])}
                    </Box>
                </Box>
            </React.Fragment>
        </Box>
    )
}

export default StepperHistory;