import React from 'react';
import Modal from 'hex/components/material/modal'
import Loading from 'hex/components/loading'

import { StringLengthMinMax, StringWithoutSpaces } from 'hex/validations';
import { ConvertToFieldErrors, useValidator } from 'hex/hooks/validator';
import { useAuthHttpClient } from 'hex/hooks/auth';
import PasswordFieldData from 'hex/components/material/operations/fields/password';

// Props:
type PasswordProps = {
    open: boolean;
    close: () => void;
    login: string;
    name: string;
};

type PasswordChangeModel = {
    Password: string;
};

const validations : Array<[keyof PasswordChangeModel, (value: any) => string | undefined]> = [
    ["Password", StringWithoutSpaces()],
    ["Password", StringLengthMinMax(5, 100)],
];

const defaultModel : PasswordChangeModel = {Password: ""};

const Password: React.FunctionComponent<PasswordProps> = ({open, close, login, name}) => {
    const [loading, _setLoading] = React.useState<boolean>(false);
    const [model, setModel] = React.useState<PasswordChangeModel>(defaultModel);
    const [validation, validator, modelValidation] = useValidator<PasswordChangeModel>(validations);

    const client = useAuthHttpClient();

    const createAction = () => {
        if (modelValidation(model))
        {
            client.post("/api/Auth/change", {username: login, password: model.Password})
            .then(() => {
                close();
            })
            .catch((e) => {
                alert(JSON.stringify(e));
            });
        }
    }

    const defaultSetValue = (field: keyof PasswordChangeModel) => (value: any) => {
        setModel(oldModel => {
            var copy = {...oldModel};

            copy[field] = value;

            return copy;
        });
    }

    return (
        <>
            <Loading loading={loading} />
            <Modal
                open={open}
                title='Изменение пароля'
                contentTitle={`Изменение пароля для пользователя: ${name}`}
                closeButtonText='Отмена'
                closeButtonAction={() => {
                    setModel(defaultModel);
                    close();
                }}
                actionButtonText='Сохранить'
                actionButtonAction={createAction}
                maxWidth='xl'
            >
                <PasswordFieldData label='Пароль' value={model.Password} onChange={defaultSetValue("Password")} validator={validator("Password")} errors={ConvertToFieldErrors("Password", validation)} />
            </Modal>
        </>
    )
}

export default Password;