import React from "react";
import { MedicineVisitType, MedicinesReceptionVisitType, } from "./types";
import { Autocomplete, Avatar, Box, Checkbox, Grid, IconButton, ListItem, ListItemAvatar, ListItemText, TextField } from "@mui/material";
import { Delete } from "@mui/icons-material";
import CreateIcon from '@mui/icons-material/Create';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';


type MedicinesDataItemProps = {
    model: MedicinesReceptionVisitType;
    medicines: Array<MedicineVisitType>;
    onUpdate: (model: MedicinesReceptionVisitType) => Promise<void>;
    onDelete: (model: MedicinesReceptionVisitType) => Promise<void>;
    onDone: (model: MedicinesReceptionVisitType) => Promise<void>;
};

function ParseFloatFromString(data: string)
{
    const result = parseFloat(data);
    if (isNaN(result))
        return 0;

    return result;
}

const MedicinesDataItem : React.FC<MedicinesDataItemProps> = ({model, onUpdate, onDelete, medicines, onDone}) => {
    const [edit, setEdit] = React.useState<boolean>(false);

    const [selectedMedicine, setSelectedMedicine] = React.useState<number>(model.medicine_id);
    const [selectedValue, setSelectedValue] = React.useState<number>(model.value);
    const [selectedDone, setSelectedDone] = React.useState<boolean>(model.done);

    const medicinesRef = React.useRef<HTMLInputElement>();
    const countRef = React.useRef<HTMLInputElement>();

    const getMedicineByID = (medicineId : number) => {
        const index = medicines.findIndex((medicine) => medicine.id === medicineId);

        if (index === -1)
            return undefined;

        return medicines[index];
    }

    const getMedicineName = (medicineId : number) => {
        const medicine = getMedicineByID(medicineId);

        if (medicine === undefined)
            return "<Не определено>";

        return medicine.name;
    }

    const setSearchingText = (data: string) => {
        const index = medicines.findIndex(element => element.name.toLowerCase().includes(data.toLowerCase()));

        if (index !== -1) 
        {
            setSelectedMedicine(medicines[index].id);
            if (countRef.current) countRef.current.focus();
        }
    }

    const onUpdateFunc = async () => {
        var newModel = {...model};

        newModel.medicine_id = selectedMedicine;
        newModel.value = selectedValue;
        newModel.done = selectedDone;

        await onUpdate(newModel);

        setSelectedMedicine(newModel.medicine_id);
        setSelectedValue(newModel.value);

        setEdit(false);
    }

    const renderReadableElement = () => (
        <ListItem sx={{
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: 'grey',
            borderRadius: '4px',
            marginBottom: '5px'
        }} secondaryAction={
            <Grid container spacing={2}>
                <Grid item>
                    <IconButton edge="end" onClick={() => {
                        setEdit(true);
                        setTimeout(() => {
                            if (medicinesRef.current) medicinesRef.current.focus();
                        }, 500);
                    }}><CreateIcon /></IconButton>
                </Grid>
                <Grid item>
                    <IconButton edge="end" onClick={async () =>  await onDelete(model)}><Delete /></IconButton>
                </Grid>
            </Grid>
        }>
            
            <Checkbox sx={{mr: 2}} checked={model.done} onChange={(event, checked) => onDone({...model, done: checked})} />
            <ListItemAvatar>
                <Avatar>
                    <MedicalServicesIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={model.Medicine ? `${model.Medicine.name} - ${model.value}` : '<не определено>'} />
        </ListItem>
    )

    const renderEditableElement = () => (
        <Box sx={{marginBottom: 3}}>
            <Grid container alignItems='center' spacing={2}>
                <Grid item flexBasis='40px'>
                    <Checkbox checked={selectedDone} onChange={(data, checked) => setSelectedDone(checked)} />
                </Grid>
                <Grid item flexBasis='calc(60% - 120px)'>
                    <Autocomplete 
                        value={(selectedMedicine === 0) ? null : {label: getMedicineName(selectedMedicine), id: selectedMedicine}}
                        disablePortal
                        options={medicines.map((medicine) => ({label: medicine.name, id: medicine.id}))}
                        sx={{ width: '100%' }}
                        renderInput={(params) => 
                            <TextField {...params} inputRef={medicinesRef} label="Введите название" onKeyDown={event => {
                                if (event.code.toLowerCase() === 'enter' && (event.target as any).value) {
                                    setSearchingText((event.target as any).value);
                                }
                              }} />
                        }
                        onChange={(event, value) => {
                            if (value !== null)
                            {
                                const service = getMedicineByID(value.id);

                                if (service)
                                {
                                    setSelectedValue(1);
                                    setSelectedMedicine(value.id);

                                    return;
                                }
                            }
                            
                            setSelectedMedicine(0);
                            setSelectedValue(0);
                        }}
                    />
                </Grid>
                <Grid item flexBasis='calc(20% - 120px)'>
                    <TextField inputRef={countRef} inputProps={{ step: 0.01 }} margin="dense" label="Значение" type="number" fullWidth onChange={(val) => setSelectedValue(ParseFloatFromString(val.target.value))} value={selectedValue.toString()} onKeyDown={event => {
                                        if (event.code.toLowerCase() === 'enter' && (event.target as any).value) {
                                            onUpdateFunc();
                                            if (medicinesRef.current) medicinesRef.current.focus();
                                        }
                                    }} />
                </Grid>
                <Grid item flexBasis='40px'>
                    <IconButton onClick={onUpdateFunc}>
                        <SaveIcon />
                    </IconButton>
                </Grid>
                <Grid item flexBasis='40px'>
                    <IconButton onClick={async () => {
                        setSelectedMedicine(model.medicine_id);
                        setSelectedValue(model.value);
                        
                        setEdit(false);
                    }}>
                        <CloseIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        </Box>
    )

    const renderBody = () => {
        if (edit)
            return renderEditableElement();
        else
            return renderReadableElement();
    }

    return (
        <>
            {renderBody()}
        </>
    );
}

export default MedicinesDataItem;