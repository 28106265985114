import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';

import {useGetReceptionsOfVisitQuery} from 'gql'
import { useErrorChecking } from 'hex/components/material/hooks';
import Loading from 'hex/components/loading';
import LoadingText from 'hex/components/material/loader_text';

import HistoryVariant, { HistoryVariants } from './history/variant';
import StandartHistory from './history/standart';
import DoctorsHistory from './history/doctors';
import StepperHistory from './history/stepper';

type VisitHistoryProps = {
    visit_id: number;
    current_reception_id: number;
};

const VisitHistory: React.FunctionComponent<VisitHistoryProps> = ({visit_id, current_reception_id}) => {
    const [variant, setVariant] = React.useState<HistoryVariants>(HistoryVariants.STANDART);

    const { data, loading, error } = useGetReceptionsOfVisitQuery({variables: {
        id: visit_id,
        current_reception_id: current_reception_id
    }, fetchPolicy: 'network-only'});

    useErrorChecking(error);

    const renderPage = () => {
        if (data === undefined)
            return (<></>);

        switch (variant)
        {
            case HistoryVariants.STANDART: return               (<StandartHistory data={data} />);
            case HistoryVariants.GROUP_BY_DOCTORS: return (     <DoctorsHistory data={data} />);
            case HistoryVariants.STEPPER: return                (<StepperHistory data={data} />);
            default:
                return (<></>);
        }
    }

    return (
        <Box sx={{marginTop: 3}}>    
            <Loading loading={loading} />
            <Paper sx={{p: 1}}>
                <Typography variant='h6'>{'Приемы:'}</Typography>
                <Grid container flexDirection='row'>
                    <Grid item flexBasis="200px">
                        <HistoryVariant variant={variant} setVariant={setVariant} />
                    </Grid>
                    <Grid item flexBasis="calc(100% - 200px)">
                        {data === undefined && <LoadingText text='Загрузка истории приемов' />}
                        {data !== undefined && data.Reception.length === 0 && <Typography variant='body1'>{'Информация отсутствует'}</Typography>}
                        {data !== undefined && data.Reception.length !== 0 && renderPage()}
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

export default VisitHistory;