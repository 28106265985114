import React from 'react';
import Modal from 'hex/components/material/modal'
import Loading from 'hex/components/loading'
import {useDeleteUsersByIdsMutation} from 'gql'
import { Typography } from '@mui/material';
import { TableUsers } from './types';
import { getModelId } from 'hex/components/material/table/types';

// Props:
type DeleteSelectedProps = {
    open: boolean;
    close: () => void;
    models: Array<TableUsers>;
};


const DeleteSelected: React.FunctionComponent<DeleteSelectedProps> = ({open, close, models}) => {
    const [deleteUsersMutation, { loading }] = useDeleteUsersByIdsMutation({
        variables: {
            IDS: models.map((x) => x.ID)
        },
    });

    const DeleteAction = () => {
        deleteUsersMutation().then(() => {
            close();
        });
    }

    return (
        <>
            <Loading loading={loading} />
            <Modal
                open={open}
                title='Удаление'
                contentTitle={`Удаление пользователей`}
                closeButtonText='Отмена'
                closeButtonAction={() => {
                    close();
                }}
                actionButtonText='Удалить'
                actionButtonAction={DeleteAction}
                maxWidth='xl'
            >
                <Typography variant='body1'>
                    {`Вы действительно уверены, что хотите удалить следующих пользователей?`}
                </Typography>
                <ul>
                    {models.map((model) => (
                        <li key={getModelId(model)}>
                            <Typography variant='h6' >
                                {model.Name}
                            </Typography>
                        </li>
                    ))}
                </ul>
            </Modal>
        </>
    )
}

export default DeleteSelected;