import React from 'react';
import {useTitle} from 'hex/hooks/title'

type TitleProps = {
    title: string
};

const Title: React.FunctionComponent<TitleProps> = ({title}) => {
    // eslint-disable-next-line
    const [text, setText] = useTitle();

    React.useEffect(() => {
        setText(title);

        return () => {};
    }, [setText, title]);

    return (
        <React.Fragment />
    );
}

export default Title;
