import { ConvertToFieldErrors, ValidationResultData, Validations, ValidatorCallback } from 'hex/hooks/validator';
import { NumberNotNegative, NumberNotZero } from 'hex/validations';
import NumericFieldData from 'hex/components/material/operations/fields/numeric';

import { MtmServices } from '../types';
import { ManyToManyFieldProps } from 'hex/components/material/operations/fields/many_to_many';
import ForeignKeyModal from 'hex/components/material/operations/fields/foreign_key_modal';
import { ServiceFkData } from './foreign_keys';
import { ListItemText } from '@mui/material';
import { AddSchemaMutation, UpdateSchemaMutation, useAddSchemaServicesMutation, useDeleteSchemaServiceMutation, useUpdateSchemaServiceMutation } from 'gql';


const validations : Validations<MtmServices> = [
    ["price", NumberNotZero()],
    ["price", NumberNotNegative()],
    ["value", NumberNotZero()],
    ["value", NumberNotNegative()],
]

const renderFields = (model: MtmServices, setFieldValue: (field: keyof MtmServices) => (value: any) => void, validator: ValidatorCallback<MtmServices>, validation: ValidationResultData<MtmServices>) => {
    return (
        <>
            <ForeignKeyModal label='Услуга' value={model.service_id} onChange={setFieldValue("service_id")} {...ServiceFkData} onChangeModel={setFieldValue("Service")} />
            <NumericFieldData label='Значение' value={model.value} onChange={setFieldValue("value")} validator={validator("value")} errors={ConvertToFieldErrors("value", validation)} decimal />
            <NumericFieldData label='Цена' value={model.price} onChange={setFieldValue("price")} validator={validator("price")} errors={ConvertToFieldErrors("price", validation)} decimal />
        </>
    );
}

const renderModel = (model: MtmServices) => {
    return (
        <ListItemText primary={`Услуга: ${model.Service.name} Значение: ${model.value ? model.value : '<Отсутствует>'}  Цена: ${model.price ? model.price : '<Отсутствует>'}`} />
    );
}

const useDeleteMutation = (model: MtmServices) => {
    return useDeleteSchemaServiceMutation({
        variables: {
            id: model.id,
        }
    });
}

const useUpdateMutation = (model: MtmServices) => {
    return useUpdateSchemaServiceMutation({
        variables: {
            id: model.id,
            service_id: model.service_id,
            price: model.price,
            value: model.value
        }
    })
}

const useAddMutation = (models: Array<MtmServices>) => {
    return useAddSchemaServicesMutation({
        variables: {
            objects: models.map((element) => {
                return {
                    service_id: element.service_id,
                    schema_id: element.schema_id,
                    price: element.price,
                    value: element.value,
                }
            })
        }
    })
}

const setParentID: (models: Array<MtmServices>, parentId: any) => Array<MtmServices> = (models: Array<MtmServices>, parentId: any) => {
    var newArray = [...models];

    newArray.forEach((element) => element.schema_id = parentId);

    return newArray.map((element) => {
        return {
            service_id: element.service_id,
            schema_id: element.schema_id,
            price: element.price,
            value: element.value,
        } as any
    });
}

const getIdFromNotifyer = (data: any) => {
    {
        const returnData : AddSchemaMutation = data.data;
    
        if (returnData.insert_Schema && returnData.insert_Schema.returning.length !== 0)
            return returnData.insert_Schema.returning[0].id;
    }
    {
        const returnData : UpdateSchemaMutation = data.data;
        if (returnData.update_Schema && returnData.update_Schema.returning.length !== 0)
            return returnData.update_Schema.returning[0].id;
    }
    
    return 0;
}


const defaultModel : MtmServices = {
    id: 0,
    schema_id: 0,
    service_id: 0,
    price: undefined,
    value: undefined,
    Service: {
        name: "",
        price: 0,
    },
};

const defaultMtm : Omit<ManyToManyFieldProps<MtmServices>, "baseModelID" | "values" | "operationNotifierAfterCreation"> = {
    label: "Услуги",
    renderFields,
    renderModel,
    defaultModel,
    validations,
    useAddMutation,
    useDeleteMutation,
    useUpdateMutation,
    setParentID,
    getIdFromNotifyer
}

export default defaultMtm;