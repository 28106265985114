import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { GlobalActionType } from './types';

interface EnhancedTableToolbarProps {
    numSelected: number;
    globalActions?: Array<GlobalActionType>;
    selectedButtons?: React.ReactNode;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected, globalActions, selectedButtons } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} выбрано
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Данные
                </Typography>
            )}
            {numSelected > 0 ? (
                <>
                    {selectedButtons}
                </>
            ) : (
                <>
                    {globalActions && globalActions.map((action, index) => {
                        return (
                        <Tooltip title={action.label} key={action.label}>
                            <IconButton aria-label={action.label} onClick={action.action}>
                                {action.icon}
                            </IconButton>
                        </Tooltip>
                        );
                    })}
                </>
            )}
        </Toolbar>
    );
}

export default EnhancedTableToolbar;