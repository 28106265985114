import React from 'react';
import {MedicineFkSubscription, useMedicineFkSubscription, useMedicineModelFkQuery} from 'gql'
import { ListItemText } from '@mui/material';
import { useSnackbar } from 'notistack';

import 'moment/locale/ru';
import { useErrorAndLoadChecking } from 'hex/components/material/hooks';

// Medicine:
export type MedicineFk = MedicineFkSubscription["Medicine"][0];

export function RenderMedicineModel(model: MedicineFk) : React.ReactNode 
{
    return (
        <ListItemText primary={model.name} />
    );
}

export function RenderSelectedMedicineModel(model: MedicineFk) : string
{
    return model.name;
}

export function useMedicineSearch(searchText: string) : Array<MedicineFk> | undefined
{
    // eslint-disable-next-line
    const { enqueueSnackbar } = useSnackbar();
    const {data, loading, error} = useMedicineFkSubscription({variables: {searchText: `%${searchText}%`}});

    React.useMemo(() => {
        if (error !== undefined)
        {
            enqueueSnackbar(`Ошибка получения данных! ${error.message}`, {variant: 'error'});
        }
    }, [error, enqueueSnackbar]);

    if ((loading === false) && (data !== undefined))
        return data.Medicine;

    return undefined;
}

export function useMedicineModel(id: any) : MedicineFk | undefined
{
    const idCalc = id !== 0 && id !== "" ? id as number : 0;

    const {data, loading, error} = useMedicineModelFkQuery({variables: {id: idCalc}, fetchPolicy: 'network-only'});
    useErrorAndLoadChecking({loading, error});

    if ((data !== undefined) && (data.Medicine_by_pk))
        return data.Medicine_by_pk;

    return undefined;
}