import React from 'react';

import Divider from '@mui/material/Divider';

import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PetsIcon from '@mui/icons-material/Pets';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import SchemaIcon from '@mui/icons-material/Schema';
import AddCardIcon from '@mui/icons-material/AddCard';

import PeopleIcon from '@mui/icons-material/People';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import LogoutIcon from '@mui/icons-material/Logout';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import SummarizeIcon from '@mui/icons-material/Summarize';


import NavLink from "components/nav_link";
import Authorized from 'hex/components/authorized';
import { AccessMap } from 'config';
import { useAuth } from 'hex/hooks/auth';
import { ListItemButton } from '@mui/material';

type SidebarProps = {

};

const Sidebar: React.FunctionComponent<SidebarProps> = () => {
    // Auth:
    const auth = useAuth();
    
    return (
        <List component="nav">
            <NavLink to='/' exact>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Главная страница" />
            </NavLink>
            <Authorized roles={AccessMap.receptionEdit}>
                <NavLink to='/reception-edit/'>
                    <ListItemIcon>
                        <EditRoadIcon />
                    </ListItemIcon>
                    <ListItemText primary="Просмотр приемов" />
                </NavLink>
            </Authorized>
            <Authorized roles={AccessMap.clientsPage}>
                <NavLink to='/clients/'>
                    <ListItemIcon>
                        <AccessibilityNewIcon />
                    </ListItemIcon>
                    <ListItemText primary="Клиенты" />
                </NavLink>
            </Authorized>
            <Authorized roles={AccessMap.petsPage}>
                <NavLink to='/pets/'>
                    <ListItemIcon>
                        <PetsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Животные" />
                </NavLink>
            </Authorized>
            <Authorized roles={AccessMap.petsTypesPage}>
                <NavLink to='/pet-types/'>
                    <ListItemIcon>
                        <SnippetFolderIcon />
                    </ListItemIcon>
                    <ListItemText primary="Виды животных" />
                </NavLink>
            </Authorized>
            <Authorized roles={AccessMap.servicesPage}>
                <NavLink to='/services/'>
                    <ListItemIcon>
                        <MedicalServicesIcon />
                    </ListItemIcon>
                    <ListItemText primary="Услуги" />
                </NavLink>
            </Authorized>
            <Authorized roles={AccessMap.medicinesPage}>
                <NavLink to='/medicine/'>
                    <ListItemIcon>
                        <VaccinesIcon />
                    </ListItemIcon>
                    <ListItemText primary="Препараты" />
                </NavLink>
            </Authorized>
            <Authorized roles={AccessMap.departmentsPage}>
                <NavLink to='/departments/'>
                    <ListItemIcon>
                        <CardTravelIcon />
                    </ListItemIcon>
                    <ListItemText primary="Отделения" />
                </NavLink>
            </Authorized>
            <Authorized roles={AccessMap.receptionDocumentsPage}>
                <NavLink to='/reception-documents/'>
                    <ListItemIcon>
                        <FilePresentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Документы регистр." />
                </NavLink>
            </Authorized>
            <Authorized roles={AccessMap.documentsPage}>
                <NavLink to='/documents/'>
                    <ListItemIcon>
                        <DocumentScannerIcon />
                    </ListItemIcon>
                    <ListItemText primary="Документы" />
                </NavLink>
            </Authorized>
            <Authorized roles={AccessMap.unitsPage}>
                <NavLink to='/units/'>
                    <ListItemIcon>
                        <AdUnitsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Единицы измерения" />
                </NavLink>
            </Authorized>
            <Authorized roles={AccessMap.schemas}>
                <NavLink to='/schemas/'>
                    <ListItemIcon>
                        <SchemaIcon />
                    </ListItemIcon>
                    <ListItemText primary="Схемы" />
                </NavLink>
            </Authorized>
            <Authorized roles={AccessMap.cards}>
                <NavLink to='/cards/'>
                    <ListItemIcon>
                        <AddCardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Карточки" />
                </NavLink>
            </Authorized>
            <Authorized roles={AccessMap.templates}>
                <NavLink to='/templates/'>
                    <ListItemIcon>
                        <SummarizeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Шаблоны" />
                </NavLink>
            </Authorized>
            <Divider sx={{ my: 1 }} />
            <Authorized roles={AccessMap.usersPage}>
                <NavLink to='/users/'>
                    <ListItemIcon>
                        <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Пользователи" />
                </NavLink>
                <Divider sx={{ my: 1 }} />
            </Authorized>

            <ListItemButton onClick={() => auth.logout()}>
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Выход" />
            </ListItemButton>
        </List>
    );
}

export default Sidebar;
