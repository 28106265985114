import React from 'react';
import Title from 'hex/components/title'

// Props:
type IndexPageProps = {

};

const IndexPage: React.FunctionComponent<IndexPageProps> = () => {
    return (
        <React.Fragment>
            <Title title='Главная страница'/>
            <h1>Administrator Page</h1>
        </React.Fragment>
    )
}

export default IndexPage;