import { FormGroup, TextField, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { DocumentVariables } from "./types";

type VariablesBlockProps = {
    data: DocumentVariables[];
    value: Record<string, string>;
    onChange: Dispatch<SetStateAction<Record<string, string>>>;
};

export const VariablesBlock: React.FunctionComponent<VariablesBlockProps> = ({data, value, onChange}) => {
    const setModelField = React.useCallback((field: string) => (value: any) => {
        onChange(oldModel => {
            var copy = {...oldModel};

            copy[field] = value.target.value;

            return copy;
        });
    }, [onChange]);

    const getValue = (field: string) => {
        if (field in value)
            return value[field];
        
        return "";
    }

    if (data.length === 0)
        return (<><Typography variant="body2">Поля отсутствуют.</Typography></>);

    return (
        <>
            {data.map((x) => {
                if (x.type === "text")
                    return (
                        <FormGroup key={x.id}>
                            <TextField helperText={x.description} margin="dense" label={x.name} type="text" fullWidth onChange={setModelField(x.id)} value={getValue(x.id)} />
                        </FormGroup>
                    );
                if (x.type === "number")
                    return (
                        <FormGroup key={x.id}>
                            <TextField type="number" helperText={x.description} margin="dense" label={x.name} fullWidth onChange={setModelField(x.id)} value={getValue(x.id)} />
                        </FormGroup>
                    );
            })}
        </>
    );
};