import React from 'react';

import Authorized from 'hex/components/authorized'
import { UserRoles } from 'config';

import Administrator from './administrator'
import Assistant from './assistant'
import Developer from './developer'
import Doctor from './doctor'
import Registrator from './registrator'

// Props:
type IndexPageProps = {

};

const IndexPage: React.FunctionComponent<IndexPageProps> = () => {
    return (
        <React.Fragment>
            <Authorized roles={[UserRoles.Registrator]}>
                <Registrator />
            </Authorized>
            <Authorized roles={[UserRoles.Doctor]}>
                <Doctor />
            </Authorized>
            <Authorized roles={[UserRoles.Assistant]}>
                <Doctor />
            </Authorized>
            <Authorized roles={[UserRoles.Administrator]}>
                <Administrator />
            </Authorized>
            <Authorized roles={[UserRoles.Developer]}>
                <Developer />
            </Authorized>
        </React.Fragment>
    )
}

export default IndexPage;