import React from 'react';

import TablePagination from '@mui/material/TablePagination';

// Types:
export type PaginationParameters = {
    limit: number;
    offset: number;
};

// Props:
export type PaginationProps = {
    count: number;
    onPageChange: (limit: number, offset: number) => void;
} & PaginationParameters;

const rowsPerPageOptionsConst: Array<number> = [10, 25, 50, 100];

const Pagination: React.FunctionComponent<PaginationProps> = ({ limit, offset, count, onPageChange }) => {
    const handleChangePage = (event: unknown, newPage: number) => {
        onPageChange(limit, newPage * limit);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = parseInt(event.target.value, 10);
        onPageChange(newValue, 0);
    };

    let page = React.useMemo(() => (offset === 0) ? 0 : Math.round(offset / limit), [limit, offset]);

    return (
        <TablePagination rowsPerPageOptions={rowsPerPageOptionsConst} component="div" count={count} rowsPerPage={limit}
            page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} labelRowsPerPage="Строк на странице"
            labelDisplayedRows={({ from, to, count }) => `${from}–${to} из ${count !== -1 ? count : `больше чем ${to}`}`}
        />
    )
}

export default Pagination;