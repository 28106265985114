import { Typography } from '@mui/material';
import * as React from 'react';

// Props
type LoadingProps = {
    text?: string
}

const LoadingText : React.FunctionComponent<LoadingProps> = ({text}) => 
{
    // Refs:
    const timerRef = React.useRef<NodeJS.Timeout | null>(null);

    // States:
    const [value, setValue] = React.useState("");

    // Effects:
    React.useEffect(() => {
        if (timerRef.current !== null)
            clearInterval(timerRef.current);
        
        timerRef.current = setInterval(() => setValue((value.length === 3) ? "" : value + '.') , 250);
        
        return () => {
            if (timerRef.current !== null)
                clearInterval(timerRef.current);
        }
    }, [value]);

    return (
        <Typography variant='h6'>
            {text + value}
        </Typography>
    );
}

export default LoadingText;