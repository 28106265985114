import { Add } from '@mui/icons-material';
import { Autocomplete, Box, Button, Grid, IconButton, List, Paper, TextField, Typography } from '@mui/material';
import LoadingText from 'hex/components/material/loader_text';
import React from 'react';
import { MedicineVisitType, MedicinesReceptionVisitType } from './types';
import MedicinesDataItem from './medicines_data_item';
import { useDeleteReceptionMedicineMutation, useUpdateReceptionMedicineMutation } from 'gql';
import { useErrorAndLoadChecking } from 'hex/components/material/hooks';
import MedicinesCard from './medicines_card';


type MedicinesDataProps = {
    medicines: MedicineVisitType[];
    data: MedicinesReceptionVisitType[] | undefined;
    on_delete: (model: MedicinesReceptionVisitType) => Promise<void>;
    on_add: (model: MedicinesReceptionVisitType) => Promise<void>;
    on_update: (model: MedicinesReceptionVisitType) => Promise<void>;
};

function ParseFloatFromString(data: string)
{
    const result = parseFloat(data);
    if (isNaN(result))
        return 0;

    return result;
}

function MedicinesData(props: MedicinesDataProps)
{
    const {medicines, data, on_delete, on_add, on_update} = props;

    const [selectedMedicine, setSelectedMedicine] = React.useState<number>(0);
    const [selectedValue, setSelectedValue] = React.useState<number>(0);
    const [cardOpen, setCardOpen] = React.useState<boolean>(false);

    const [fakeIndex, setFakeIndex] = React.useState<number>(-1);

    const medicinesRef = React.useRef<HTMLInputElement>();
    const countRef = React.useRef<HTMLInputElement>();

    const [deleteReceptionMedicineMutation, deleteReceptionMedicineMutationData] = useDeleteReceptionMedicineMutation();
    const [updateReceptionMedicineMutation, updateReceptionMedicineMutationData] = useUpdateReceptionMedicineMutation();

    useErrorAndLoadChecking(deleteReceptionMedicineMutationData);
    useErrorAndLoadChecking(updateReceptionMedicineMutationData);

    if (data === undefined)
        return (
            <LoadingText text='Загрузка данных'/>
        );
    
    const getMedicineByID = (medicineId : number) => {
        const index = medicines.findIndex((medicine) => medicine.id === medicineId);

        if (index === -1)
            return undefined;

        return medicines[index];
    }

    const getMedicineName = (medicineId : number) => {
        const medicine = getMedicineByID(medicineId);

        if (medicine === undefined)
            return "<Не определено>";

        return medicine.name;
    }

    const onAddFunc = async () => {
        if (selectedValue === 0)
                return;

        if (selectedMedicine <= 0)
            return;

        await on_add({
            id: fakeIndex,
            medicine_id: selectedMedicine,
            value: selectedValue,
            reception_id: 0,
            Medicine: getMedicineByID(selectedMedicine),
            done: false,
        });

        setFakeIndex(fakeIndex - 1);
        setSelectedMedicine(0);
        setSelectedValue(0);
    }

    return (
        <Paper sx={{p: 1, marginBottom: 1}}>
            <Box sx={{marginBottom: 3}}>
                <Typography variant='h6'>Список препаратов:</Typography>
            </Box>
            <Box sx={{marginBottom: 3}}>
                <Grid container>
                    <Grid item>
                        <Typography variant='body1'>{'Добавление:'}</Typography>
                    </Grid>
                    <Grid item sx={{marginLeft: "auto"}}>
                        <Button variant='outlined' color='secondary' onClick={() => setCardOpen(true)}>Открыть карточку</Button>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{marginBottom: 3}}>
                <Grid container alignItems='center' spacing={2}>
                    <Grid item flexBasis='calc(60% - 80px)'>
                        <Autocomplete
                            value={(selectedMedicine === 0) ? null : {label: getMedicineName(selectedMedicine), id: selectedMedicine}}
                            disablePortal
                            autoHighlight
                            options={medicines.map((medicine) => ({label: medicine.name, id: medicine.id}))}
                            sx={{ width: '100%' }}
                            renderInput={(params) => 
                                <TextField {...params} inputRef={medicinesRef} label="Введите название" onKeyDown={event => {
                                    if (event.code.toLowerCase() === 'enter' && (event.target as any).value) {
                                        if (selectedMedicine !== 0 && countRef.current) countRef.current.focus();
                                    }
                                  }} />
                            }
                            onChange={(event, value) => {
                                if (value !== null)
                                {
                                    const service = getMedicineByID(value.id);

                                    if (service)
                                    {
                                        setSelectedValue(0);
                                        setSelectedMedicine(value.id);

                                        return;
                                    }
                                }
                                
                                setSelectedMedicine(0);
                                setSelectedValue(0);
                            }}
                        />
                    </Grid>
                    <Grid item flexBasis='calc(40% - 80px)'>
                        <TextField inputRef={countRef} inputProps={{ step: 0.01 }} margin="dense" label="Значение" type="number" fullWidth onChange={(val) => setSelectedValue(ParseFloatFromString(val.target.value))} value={selectedValue.toString()} onKeyDown={event => {
                                    if (event.code.toLowerCase() === 'enter' && (event.target as any).value) {
                                        if (selectedValue === 0)
                                            return;
                                        if (selectedMedicine <= 0)
                                            return;
                                        
                                        onAddFunc();
                                        if (medicinesRef.current) medicinesRef.current.focus();
                                    }
                                  }} />
                    </Grid>
                    <Grid item flexBasis='40px'>
                        <IconButton onClick={onAddFunc}><Add/></IconButton>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{marginBottom: 3}}>
                <List>
                    {
                        data.map((element) => (
                            <MedicinesDataItem medicines={medicines} model={element} key={element.id} onDelete={async (model) => {
                                if (element.id !== undefined && element.id !== null && element.id > 0)
                                    await deleteReceptionMedicineMutation({
                                        variables: {
                                            id: element.id
                                        }
                                    });
                                
                                on_delete(element);
                            }} onUpdate={async (model) => {          
                                if (model.id > 0)
                                    await updateReceptionMedicineMutation({
                                        variables: {
                                            id: model.id,
                                            medicine_id: model.medicine_id,
                                            value: model.value,
                                            reception_id: model.reception_id,
                                            done: model.done
                                        }
                                    });

                                await on_update(model);
                            }} onDone={async (model) => {
                                if (model.id > 0)
                                    await updateReceptionMedicineMutation({
                                        variables: {
                                            id: model.id,
                                            medicine_id: model.medicine_id,
                                            value: model.value,
                                            reception_id: model.reception_id,
                                            done: model.done
                                        }
                                    });

                                await on_update(model);
                            }} />
                        ))
                    }
                </List>
            </Box>
            <MedicinesCard 
                open={cardOpen} 
                onClose={() => setCardOpen(false)} 
                dataReceptions={data} 
                medicines={medicines}
                on_add={async (model) => {
                    await on_add({
                        id: fakeIndex,
                        medicine_id: model.medicine_id,
                        value: model.value,
                        reception_id: 0,
                        Medicine: getMedicineByID(model.medicine_id),
                        done: false,
                    });
            
                    setFakeIndex(fakeIndex - 1);
                }}
                on_update={async (model) => {
                    if (model.id > 0)
                        await updateReceptionMedicineMutation({
                            variables: {
                                id: model.id,
                                medicine_id: model.medicine_id,
                                value: model.value,
                                reception_id: model.reception_id,
                            }
                        });

                    await on_update(model);
                }}
                on_delete={async (model) => {
                    if (model.id !== undefined && model.id !== null && model.id > 0)
                        await deleteReceptionMedicineMutation({
                            variables: {
                                id: model.id
                            }
                        });
                    
                    on_delete(model);
                }}
            />
        </Paper>
    );
}

export default MedicinesData;