import React from 'react';
import Modal from 'hex/components/material/modal'
import Loading from 'hex/components/loading'

import {useDeleteUserDepartmentsMutation, useAddUserDepartmentsMutation, useGetUsersDepartmentsQuery, useAllDepartmentsSubscription} from 'gql'
import { useSnackbar } from 'notistack';
import { ApolloError } from '@apollo/client';
import { Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// Props:
type DepartmentsProps = {
    open: boolean;
    close: () => void;
    user_id: string;
    name: string;
};


const Departments: React.FunctionComponent<DepartmentsProps> = ({open, close, user_id, name}) => {
    const allDepartmentsState = useAllDepartmentsSubscription();
    const userDepartmentsState = useGetUsersDepartmentsQuery({variables: {doctor_id: user_id}, fetchPolicy: 'network-only'});
    const [addMutation, addState] = useAddUserDepartmentsMutation();
    const [deleteMutation, deleteState] = useDeleteUserDepartmentsMutation();

    const [selected, setSelected] = React.useState<Array<number>>([]);

    React.useEffect(() => {
        if (userDepartmentsState.data !== undefined)
        {
            const newSelected = userDepartmentsState.data.DoctorsDepartment.map((model) => model.department_id);
            setSelected(newSelected);
        }

    }, [userDepartmentsState, setSelected]);

    // eslint-disable-next-line
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        const checkError = (error : ApolloError | undefined) => {
            if (error !== undefined)
            {
                enqueueSnackbar(`Ошибка получения данных! ${error.message}`, {variant: 'error'});
            }
        }

        checkError(allDepartmentsState.error);
        checkError(userDepartmentsState.error);
        checkError(addState.error);
        checkError(deleteState.error);
    }, [allDepartmentsState, userDepartmentsState, addState, deleteState, enqueueSnackbar]);

    

    const saveAction = async () => {
        if (userDepartmentsState.data !== undefined)
        {
            const toRemove = userDepartmentsState.data.DoctorsDepartment.filter((model) => selected.indexOf(model.department_id) === -1).map((model) => model.id);
            const toAdd = selected.filter((id) => userDepartmentsState.data!.DoctorsDepartment.findIndex((model) => model.department_id === id) === -1);

            if (toRemove.length !== 0)
            {
                await deleteMutation({variables: {_in: toRemove}});
            }

            if (toAdd.length !== 0)
            {
                await addMutation({variables: {objects: toAdd.map((id) => ({department_id: id, doctor_id: user_id}))}});
            }

            setSelected([]);
            close();
        }
    }

    const modelIsChecked = (id: number) => {
        return selected.indexOf(id) !== -1;
    }

    const toogleDepartment = (id: number) => {
        const selectedClone = [...selected];
        const index = selectedClone.indexOf(id);

        if (index !== -1)
            selectedClone.splice(index, 1);
        else
            selectedClone.push(id);

        setSelected(selectedClone);
    }

    const renderBody = () => {
        if ((allDepartmentsState.data === undefined) || (userDepartmentsState.data === undefined))
            return (<Typography variant='h5'>Загрузка...</Typography>)

        return (
            <List sx={{ width: '100%', minWidth: 360, bgcolor: 'background.paper' }}>
                {allDepartmentsState.data.Department.map((model, index) => {
                        const labelId = `department-list-id-${index}`
                        return (
                            <ListItem key={model.id}>
                                <ListItemButton role={undefined} onClick={() => toogleDepartment(model.id)} dense>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={modelIsChecked(model.id)}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={model.name} />
                                </ListItemButton>
                            </ListItem>)
                    }
                )}
            </List>
        );
    }

    return (
        <>
            <Loading loading={addState.loading || deleteState.loading || userDepartmentsState.loading || allDepartmentsState.loading} />
            <Modal
                open={open}
                title='Выбор отделений'
                contentTitle={`Выбор отделений для пользователя: ${name}`}
                closeButtonText='Отмена'
                closeButtonAction={() => {
                    close();
                }}
                actionButtonText='Сохранить'
                actionButtonAction={saveAction}
                maxWidth='xl'
            >
                {renderBody()}
            </Modal>
        </>
    )
}

export default Departments;