import React from 'react';
import { Box, CircularProgress, Grid, Paper } from '@mui/material';
import Title from 'hex/components/title';
import Categories from './categories';
import Shemas from './schemas'

import {useGetSchemasWithCategoriesSubscription} from 'gql'
import { useErrorAndLoadChecking } from 'hex/components/material/hooks';
import { SchemaCategoryMaybeWithChilds } from './types';


type IndexProps = {

}

const Index: React.FunctionComponent<IndexProps> = () => {
    const { data, loading, error } = useGetSchemasWithCategoriesSubscription();
    const [selectedId, setSelectedId] = React.useState<Array<number>>([]);

    useErrorAndLoadChecking({loading, error});

    if (data === undefined)
        return (
            <>
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>
            </>
        )
    
    const onSelect = (category : SchemaCategoryMaybeWithChilds) => {
        if ((category.Childs !== undefined) && (category.Childs.length !== 0))
            setSelectedId([category.id, ...category.Childs.map(x => x.id)]);
        else
            setSelectedId([category.id]);
    }

    return (
        <React.Fragment>
            <Title title='Редактирование схем' />
            <h1>Редактирование схем</h1>
            <Grid container spacing={2} sx={{height: "100%"}}>
                <Grid item flexBasis="25%">
                    <Paper sx={{padding: 1, width: "100%", height: "78vh;"}}>
                        <Categories categories={data.SchemaCategory} onSelect={onSelect} selected={selectedId} />
                    </Paper>
                </Grid>
                <Grid item flexBasis="75%">
                    <Paper sx={{padding: 1, width: "100%", height: "78vh;"}}>
                        {selectedId.length !== 0 && <Shemas selectedID={selectedId} />}
                    </Paper>
                </Grid>
            </Grid> 
        </React.Fragment>
    )
}

export default Index;