import { Box, CircularProgress, Grid } from '@mui/material';
import { GetSchemasForCategorySubscription, MakeOptional, useGetSchemasForCategorySubscription, useGetSchemasWithCategoriesSubscription } from 'gql';
import { useErrorAndLoadChecking } from 'hex/components/material/hooks';
import Modal from 'hex/components/material/modal';
import React from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Categories from 'pages/schemas/categories'

type SchemasProps = {
    open: boolean;
    onClose: () => void;
    onAccept: (model: GetSchemasForCategorySubscription["Schema"][number]) => void;
};

const Schemas: React.FC<SchemasProps> = ({ open, onClose, onAccept }) => {
    const { data, loading, error } = useGetSchemasWithCategoriesSubscription();
    const [selectedCategories, setSelectedCategories] = React.useState<number[]>([]);
    const [selectedSchema, setSelectedSchema] = React.useState<number>(0);

    const schemasData = useGetSchemasForCategorySubscription({
        variables: {
            ids: selectedCategories,
        }
    })

    useErrorAndLoadChecking({ loading, error });
    useErrorAndLoadChecking(schemasData);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedSchema(parseInt((event.target as HTMLInputElement).value, 10));
    };

    const getSelectedSchema = () => {
        if ((selectedSchema === 0) || schemasData.data === undefined)
            return undefined;

        const index = schemasData.data.Schema.findIndex(element => element.id === selectedSchema);
        if (index === -1)
            return undefined;
        
        return schemasData.data.Schema[index];
    }

    const renderSchemas = () => {
        if (schemasData.data === undefined)
            return (
                <>
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>
                </>
            );
        
        return (
            <FormControl>
                <FormLabel>Доступные схемы:</FormLabel>
                <RadioGroup
                    value={selectedSchema.toString()}
                    onChange={handleChange}
                >
                    {schemasData.data.Schema.map(element => (
                        <FormControlLabel key={element.id} value={element.id} control={<Radio />} label={element.name} />
                    ))}
                </RadioGroup>
            </FormControl>
        )
    }

    const renderBody = () => {
        if (data === undefined)
            return (
                <>
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>
                </>
            );

        return (
            <Grid container spacing={2} marginBottom={5}>
                <Grid item flexBasis={'25%'}>
                    <Categories categories={data.SchemaCategory} selected={selectedCategories} disableOperations onSelect={(category) => {
                        setSelectedSchema(0);

                        if (category.Childs)
                            return setSelectedCategories([category.id, ...category.Childs.map(element => element.id)]);

                        return setSelectedCategories([category.id,]);
                    }}  />
                </Grid>
                <Grid item flexBasis={'75%'}>
                    {selectedCategories.length !== 0 && renderSchemas()}
                </Grid>
            </Grid>
        )
    }

    return (
        <Modal actionButtonEnabled={selectedSchema !== 0} fullScreen open={open} title='Схемы' contentTitle='Выбор схемы' closeButtonText='Отмена' closeButtonAction={onClose} actionButtonText='Применить' actionButtonAction={() => {
            const schema = getSelectedSchema();

            if (schema !== undefined)
            {
                onAccept(schema);
                onClose();
            }
         }}>
            {renderBody()}
        </Modal>
    );
}

export default Schemas;