import React from 'react';
import { Add } from '@mui/icons-material';
import { Autocomplete, Box, Grid, IconButton, List, Paper, TextField, Typography } from '@mui/material';
import LoadingText from 'hex/components/material/loader_text';
import { ServiceVisitType, ServicesReceptionVisitType } from './types';
import { useDeleteReceptionServiceMutation, useUpdateReceptionServiceMutation } from 'gql';
import { useErrorAndLoadChecking } from 'hex/components/material/hooks';
import ServicesDataItem from './services_data_item';


type ServicesDataProps = {
    data: ServicesReceptionVisitType[] | undefined;
    services: Array<ServiceVisitType>;
    on_delete: (model: ServicesReceptionVisitType) => Promise<void>;
    on_update: (model: ServicesReceptionVisitType) => Promise<void>;
    on_add: (model: ServicesReceptionVisitType) => Promise<void>;
    doctorsServices?: Array<{price: any, value: any}>;
};

function ParseFloatFromString(data: string)
{
    const result = parseFloat(data);
    if (isNaN(result))
        return 0;

    return result;
}

function ServicesData(props: ServicesDataProps) 
{
    const {data, services, on_delete, on_add, on_update, doctorsServices} = props;

    const [selectedService, setSelectedService] = React.useState<number>(0);
    const [selectedValue, setSelectedValue] = React.useState<number>(0);
    const [selectedPrice, setSelectedPrice] = React.useState<number>(0);

    const [fakeIndex, setFakeIndex] = React.useState<number>(-1);

    const servicesRef = React.useRef<HTMLInputElement>();
    const priceRef = React.useRef<HTMLInputElement>();
    const countRef = React.useRef<HTMLInputElement>();

    const servicesSum = React.useMemo(() => {
        if (data !== undefined)
        {
            let sum = 0;
            data.forEach((element) => sum += (element.price as number * element.value as number));

            return sum;
        }

        return 0;
    }, [data, ]);

    const calculateSum = (services: Array<{price: any, value: any}>) => {
        let sum = 0;
        services.forEach((element) => sum += (element.price as number * element.value as number));

        return sum;
    }

    const [deleteReceptionServiceMutation, deleteReceptionServiceMutationData] = useDeleteReceptionServiceMutation();
    const [updateReceptionServiceMutation, updateReceptionServiceMutationData] = useUpdateReceptionServiceMutation();

    useErrorAndLoadChecking(deleteReceptionServiceMutationData);
    useErrorAndLoadChecking(updateReceptionServiceMutationData);

    const getServiceByID = (serviceId : number) => {
        const index = services.findIndex((service) => service.id === serviceId);

        if (index === -1)
            return undefined;

        return services[index];
    }

    const getServiceName = (serviceId : number) => {
        const service = getServiceByID(serviceId);

        if (service === undefined)
            return "<Не определено>";

        return service.name;
    }

    if ((data === undefined))
        return (
            <LoadingText text='Загрузка данных'/>
        );

    const onAddData = async () => {
        if (selectedValue === 0)
            return;

        if (selectedService <= 0)
            return;

        await on_add({
            id: fakeIndex,
            service_id: selectedService,
            price: selectedPrice,
            value: selectedValue,
            reception_id: 0,
            Service: getServiceByID(selectedService),
        });

        setFakeIndex(fakeIndex - 1);
        setSelectedService(0);
        setSelectedPrice(0);
        setSelectedValue(0);
    }

    const onServiceKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.code.toLowerCase() === 'enter' && (event.target as any).value) {
            if (selectedService !== 0 && priceRef.current) priceRef.current.focus();
        }
    }

    const onPriceKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.code.toLowerCase() === 'enter' && (event.target as any).value) {
            if (countRef.current) countRef.current.focus();
        }
    }

    const onValueKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.code.toLowerCase() === 'enter' && (event.target as any).value) {
            if (selectedValue === 0)
                return;

            if (selectedService <= 0)
                return;

            onAddData();
            if (servicesRef.current) servicesRef.current.focus();
        }
    }

    return (
        <Paper sx={{p: 1, marginBottom: 1}}>
            <Box sx={{marginBottom: 3}}>
                <Typography variant='h6'>Список услуг:</Typography>
            </Box>
            <Box sx={{marginBottom: 3}}>
                <Typography variant='body1'>{'Добавление:'}</Typography>
            </Box>
            <Box sx={{marginBottom: 3}}>
                <Grid container alignItems='center' spacing={2}>
                    <Grid item flexBasis='calc(60% - 40px)'>
                        <Autocomplete 
                            autoHighlight
                            value={(selectedService === 0) ? null : {label: getServiceName(selectedService), id: selectedService}}
                            disablePortal
                            options={services.map((service) => ({label: service.name, id: service.id}))}
                            sx={{ width: '100%' }}
                            renderInput={(params) => <TextField onKeyDown={onServiceKeyDown} inputRef={servicesRef} {...params} label="Введите название" />}
                            onChange={(event, value) => {
                                if (value !== null)
                                {
                                    const service = getServiceByID(value.id);
                                    if (service)
                                    {
                                        setSelectedPrice(service.price);
                                        setSelectedValue(1);
                                        setSelectedService(value.id);

                                        return;
                                    }
                                }
                                
                                setSelectedService(0);
                                setSelectedPrice(0);
                                setSelectedValue(0);
                            }}
                        />
                    </Grid>
                    <Grid item flexBasis='calc(20% - 40px)'>
                        <TextField onKeyDown={onPriceKeyDown} inputRef={priceRef} inputProps={{ step: 0.01 }} margin="dense" label="Цена" type="number" fullWidth onChange={(val) => setSelectedPrice(ParseFloatFromString(val.target.value))} value={selectedPrice.toString()} />
                    </Grid>
                    <Grid item flexBasis='calc(20% - 40px)'>
                        <TextField onKeyDown={onValueKeyDown} inputRef={countRef} inputProps={{ step: 0.01 }} margin="dense" label="Значение" type="number" fullWidth onChange={(val) => setSelectedValue(ParseFloatFromString(val.target.value))} value={selectedValue.toString()} />
                    </Grid>
                    <Grid item flexBasis='40px'>
                        <IconButton onClick={onAddData}>
                            <Add/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{marginBottom: 3}}>
                <List>
                    {
                        data.map((element) => (
                            <ServicesDataItem services={services} model={element} key={element.id} onDelete={async (model) => {
                                if (element.id !== undefined && element.id !== null && element.id > 0)
                                    await deleteReceptionServiceMutation({
                                        variables: {
                                            id: element.id
                                        }
                                    });
                                
                                on_delete(element);
                            }} onUpdate={async (model) => {          
                                if (model.id > 0)
                                    await updateReceptionServiceMutation({
                                        variables: {
                                            id: model.id,
                                            price: model.price,
                                            service_id: model.service_id,
                                            value: model.value,
                                        }
                                    });

                                await on_update(model);
                            }} />
                        ))
                    }
                </List>
            </Box>
            <Box sx={{marginBottom: 3}}>
                <Grid container>
                    <Grid item sx={{marginLeft: "auto"}}>
                        <Typography variant='button'>Итоговая сумма за текущий прием: {servicesSum} руб.</Typography>
                    </Grid>
                </Grid>
                {doctorsServices && <Grid container>
                    <Grid item sx={{marginLeft: "auto"}}>
                        <Typography variant='button'>Итоговая сумма за визит: {servicesSum + calculateSum(doctorsServices)} руб.</Typography>
                    </Grid>
                </Grid>}
            </Box>
        </Paper>
    );
}

export default ServicesData;