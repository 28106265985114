import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import TreeViewLabel from './tree_view_label';
import { SchemaCategory, SchemaCategoryWithParent, SchemaCategoryWithChilds, SchemaCategoryMaybeWithChilds } from './types';
import { Add } from '@mui/icons-material';
import { OperationType } from 'hex/components/material/types';
import { CreateOperation, DeleteOperation, UpdateOperation } from './categoriesOperations';
import { useOperations } from 'hex/components/material/hooks';
import Operations from 'hex/components/material/operations';


type CategoriesProps = {
    categories: Array<SchemaCategoryWithChilds>;
    onSelect: (category: SchemaCategoryMaybeWithChilds) => void;
    selected: Array<number>;
    disableOperations?: boolean;
}

const Categories: React.FunctionComponent<CategoriesProps> = ({onSelect, categories, selected, disableOperations}) => {
    const [selectedCategory, setSelectedCategory] = React.useState<SchemaCategoryWithParent | undefined>();
    const operationsArray = React.useMemo<Array<OperationType<SchemaCategoryWithParent>>>(() => {
        return [
            CreateOperation((defaultModel) => {
                return {
                    id: defaultModel.id,
                    name: defaultModel.name,
                    parent_id: selectedCategory ? selectedCategory.id : undefined,
                };
            }),
            UpdateOperation(),
            DeleteOperation(),
        ];
    }, [selectedCategory]);

    const operations = useOperations<SchemaCategoryWithParent>(operationsArray);

    const onAddCategory = () => {
        setSelectedCategory(undefined);
        // Знаем, что создание - нулевая операция
        operations.globalActions[0].action();
    }

    const onAddSubcategory = (category: SchemaCategory) => 
    {
        setSelectedCategory(category);
        // Знаем, что создание - нулевая операция
        operations.globalActions[0].action();
    }

    const onUpdateCategory = (category: SchemaCategory) => {
        operations.actions[0].action(category);
    }

    const onDeleteCategory = (category: SchemaCategory) => {
        operations.actions[1].action(category);
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid item>
                    <Typography variant='h6'>Категории:</Typography>
                </Grid>
                <Grid item sx={{marginLeft: "auto"}}>
                    {!disableOperations &&<Tooltip title="Добавить">
                            <IconButton onClick={onAddCategory}>
                                <Add />
                            </IconButton>
                        </Tooltip>}
                </Grid>
            </Grid>
            <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} sx={{ height: "100%", flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}>
                {categories.map((category) => {
                    if (category.Childs.length == 0)
                        return (
                            <TreeItem key={category.id} nodeId={category.id.toString()} label={<TreeViewLabel disableOperations={disableOperations} category={category} canHaveChilds={true} onSelect={onSelect} onAdd={onAddSubcategory} onUpdate={onUpdateCategory} onDelete={onDeleteCategory} />} sx={(theme) => selected.indexOf(category.id) !== -1 ? { background: theme.palette.primary.main, color: theme.palette.primary.contrastText } : {}} />
                        );
                    
                    return (
                        <TreeItem key={category.id} nodeId={category.id.toString()} label={<TreeViewLabel disableOperations={disableOperations} category={category} canHaveChilds={true} onSelect={onSelect} onAdd={onAddSubcategory} onUpdate={onUpdateCategory} onDelete={onDeleteCategory} />} sx={(theme) => selected.indexOf(category.id) !== -1 ? { background: theme.palette.primary.main, color: theme.palette.primary.contrastText } : {}} >
                            {category.Childs.map((childCategory) => (
                                <TreeItem key={childCategory.id} nodeId={childCategory.id.toString()} label={<TreeViewLabel disableOperations={disableOperations} category={childCategory} canHaveChilds={false} onSelect={onSelect} onAdd={onAddSubcategory} onUpdate={onUpdateCategory} onDelete={onDeleteCategory} />} sx={(theme) => selected.indexOf(childCategory.id) !== -1 ? { background: theme.palette.primary.main, color: theme.palette.primary.contrastText } : {}} />
                            ))}
                        </TreeItem>
                    );
                })}
            </TreeView>
            {!disableOperations && <Operations args={operationsArray} eventIndex={operations.eventIndex} model={operations.model} selectedModels={operations.selectedModels} close={operations.close} />}
        </React.Fragment>
    )
}

export default Categories;