import { LookupVariant } from "hex/components/material/operations/fields/lookup";

export type TablePet = {
    id: number, 
    birthdate?: any, 
    breed?: string | null, 
    chip_number?: string | null, 
    client_id: number, 
    name: string,
    sex: number,
    type_id: number,
    PetType:
    { 
        name: string
    }, 
    Client: 
    {
        name: string
    }
};

export const PetSex : Array<LookupVariant> = [
    {
        name: "Самец",
        value: 1
    },
    {
        name: "Самка",
        value: 2
    },
    {
        name: "Неизвестно/гермафродит",
        value: 3
    },
];


export const PetSexToString = (sex : number) => 
{
    const index = PetSex.findIndex((x) => x.value === sex);

    if (index !== -1)
        return PetSex[index].name;

    return '<Неизвестно>'
}