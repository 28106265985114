import { FormGroup, FormControl, InputLabel, Select, SelectChangeEvent, MenuItem, FormHelperText } from '@mui/material';
import React from 'react';


export type LookupVariant = {
    name: string;
    value: number;
};

// Props:
type LookupFieldProps = {
    label?: string;
    value?: any;
    nullable: boolean;
    onChange: (value: any) => any;
    variants: Array<LookupVariant>;
    validator?: (value: any) => void;
    errors?: Array<string>;
    disabled?: boolean;
};


// Element:
const LookupField : React.FunctionComponent<LookupFieldProps> = ({label, value, onChange, nullable, variants, errors, validator, disabled}) => {
    const onChangeEventWrapper = (event: SelectChangeEvent) => {
        const parsed = parseInt(event.target.value, 10);

        if (!isNaN(parsed))
        {
            onChange(parsed);
            if (validator !== undefined) validator(parsed);
        }
        else
        {
            if (validator !== undefined) validator(0);
        }
    };

    const labelId = React.useId();

    const menuItems = () => {
        return variants.map((item, index) => (<MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>))
    }

    const error = (errors !== undefined) ? errors.length !== 0 : false;
    const helperText = (errors !== undefined) ? errors.join("; ") : undefined;

    return (
        <FormGroup style={{marginTop: "12px", marginBottom: "4px"}}>
            <FormControl error={error}>
                <InputLabel id={labelId}>{label}</InputLabel>
                <Select labelId={labelId} id={`select-${{labelId}}`} value={value} label={label} onChange={onChangeEventWrapper} disabled={disabled} >
                    {
                        nullable && 
                        (<MenuItem value={0}>
                            <em>Не выбрано</em>
                        </MenuItem>)
                    }
                    
                    {menuItems()}
                </Select>
                {error && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        </FormGroup>
    );
}

export default LookupField;