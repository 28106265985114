import React from 'react';
import Modal from 'hex/components/material/modal';

import RichText from './rich_text';
import { Button, Grid, Typography } from '@mui/material';

type RichTextPopupProps = {
    text: string;
    setText: (text: string) => void;
    name: string;
};

const RichTextPopup : React.FunctionComponent<RichTextPopupProps> = ({text, setText, name}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [dataText, setDataText] = React.useState<string>(text);

    return (
        <Grid container spacing={2} alignItems='center' sx={{marginTop: 1, marginBottom: 1}}>
            <Grid item>
                <Typography>
                    {name}:
                </Typography>
            </Grid>
            <Grid item>
                <Button variant="contained" onClick={() => setOpen(true)} color="secondary">Редактировать</Button>
            </Grid>
            <Modal fullScreen open={open} title='Редактирование документа' contentTitle={name} closeButtonText='Отмена' closeButtonAction={() => {
                setOpen(false);
                setDataText(text);
            }} actionButtonText='Сохранить' actionButtonAction={() => {
                setText(dataText);
                setOpen(false);
            }}>
                <RichText text={dataText} setText={setDataText} />
            </Modal>
        </Grid>
    )
}

export default RichTextPopup;