import React from 'react';
import { FormControl, FormGroup, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { getModelId, ModelType } from '../../table/types';

// Props:
type ForeignKeyProps<TModel extends ModelType> = {
    label?: string;
    value: any;
    onChange: (value: any) => any;
    captionKey: keyof TModel;
    models: Array<TModel> | undefined;
};


function ForeignKey<TModel extends ModelType>({label, value, onChange, captionKey, models}: ForeignKeyProps<TModel>)
{
    const id = React.useId();

    const eventOnChange = (event: SelectChangeEvent<any>, child: React.ReactNode) =>
    {
        onChange(event.target.value);
    }

    if (models === undefined)
        return (
            <Typography>Загрузка...</Typography>
        );

    return (
        <FormGroup>
            <FormControl fullWidth disabled={models === undefined}>
                <InputLabel id={`fk-id-data-${id}`}>{label}</InputLabel>
                <Select labelId={`fk-id-data-${id}`} id={`fk-id-select-data-${id}`} value={value} label={label} onChange={eventOnChange} >
                    {(models !== undefined) && (
                        models.map((model, index) => 
                        (
                            <MenuItem key={getModelId(model)} value={getModelId(model) as string}>{model[captionKey] as string}</MenuItem>
                        ))
                    )}
                </Select>
            </FormControl>
        </FormGroup>
    );
};

export default ForeignKey;