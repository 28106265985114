import * as React from 'react';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import { ModelType, Order, HeadCell} from './types';



interface EnhancedTableProps<TModel extends ModelType> {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TModel) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: keyof TModel;
    rowCount: number;
    headCells: HeadCell<TModel>[];
    actions: boolean;
}

function EnhancedTableHead<TModel extends ModelType>({numSelected, onRequestSort, onSelectAllClick, order, orderBy, rowCount, headCells, actions} : EnhancedTableProps<TModel>) {
    const createSortHandler =
    (property: keyof TModel) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {actions && 
                    <TableCell>
                        
                    </TableCell>
                }
                {headCells.map((headCell) => {
                    if (headCell.sort)
                    {
                        return (
                            <TableCell key={headCell.id as string} align='left' padding='normal' sortDirection={orderBy === headCell.id ? order : false}>
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        )
                    }
                    else 
                    {
                        return (
                            <TableCell key={headCell.id as string} align='left' padding='normal'>
                                {headCell.label}
                            </TableCell>
                        )
                    }
                })}
            </TableRow>
        </TableHead>
    );
}

export default EnhancedTableHead;