import React from "react";

import Loading from "hex/components/loading";
import Title from "hex/components/title";

import {useDeleteRegistrationDocumentMutation, useAddRegistrationDocumentMutation, useGetRegistrationDocumentsQuery, useAllDocumentsSubscription} from 'gql'
import { useSnackbar } from 'notistack';
import { ApolloError } from '@apollo/client';
import { Button, Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';

type IndexProps = {

}

const Index : React.FunctionComponent<IndexProps> = ({}) => 
{
    const allDepartmentsState = useAllDocumentsSubscription();
    const userDepartmentsState = useGetRegistrationDocumentsQuery({fetchPolicy: 'network-only'});
    const [addMutation, addState] = useAddRegistrationDocumentMutation();
    const [deleteMutation, deleteState] = useDeleteRegistrationDocumentMutation();

    const [selected, setSelected] = React.useState<Array<number>>([]);

    React.useEffect(() => {
        if (userDepartmentsState.data !== undefined)
        {
            const newSelected = userDepartmentsState.data.RegistrationDocument.map((model) => model.document_id);
            setSelected(newSelected);
        }
    }, [userDepartmentsState, setSelected]);

    // eslint-disable-next-line
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        const checkError = (error : ApolloError | undefined) => {
            if (error !== undefined)
            {
                enqueueSnackbar(`Ошибка получения данных! ${error.message}`, {variant: 'error'});
            }
        }

        checkError(allDepartmentsState.error);
        checkError(userDepartmentsState.error);
        checkError(addState.error);
        checkError(deleteState.error);
    }, [allDepartmentsState, userDepartmentsState, addState, deleteState, enqueueSnackbar]);

    

    const saveAction = async () => {
        if (userDepartmentsState.data !== undefined)
        {
            const toRemove = userDepartmentsState.data.RegistrationDocument.filter((model) => selected.indexOf(model.document_id) === -1).map((model) => model.id);
            const toAdd = selected.filter((id) => userDepartmentsState.data!.RegistrationDocument.findIndex((model) => model.document_id === id) === -1);

            if (toRemove.length !== 0)
            {
                await deleteMutation({variables: {_in: toRemove}});
            }

            if (toAdd.length !== 0)
            {
                await addMutation({variables: {objects: toAdd.map((id) => ({document_id: id}))}});
            }

            // setSelected([]);
        }
    }

    const modelIsChecked = (id: number) => {
        return selected.indexOf(id) !== -1;
    }

    const toogleDepartment = (id: number) => {
        const selectedClone = [...selected];
        const index = selectedClone.indexOf(id);

        if (index !== -1)
            selectedClone.splice(index, 1);
        else
            selectedClone.push(id);

        setSelected(selectedClone);
    }

    const renderBody = () => {
        if ((allDepartmentsState.data === undefined) || (userDepartmentsState.data === undefined))
            return (<Typography variant='h5'>Загрузка...</Typography>)

        return (
            <List sx={{ width: '100%', minWidth: 360, bgcolor: 'background.paper' }}>
                {allDepartmentsState.data.Documents.map((model, index) => {
                        const labelId = `department-list-id-${index}`
                        return (
                            <ListItem key={model.id}>
                                <ListItemButton role={undefined} onClick={() => toogleDepartment(model.id)} dense>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={modelIsChecked(model.id)}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={model.name} />
                                </ListItemButton>
                            </ListItem>)
                    }
                )}
            </List>
        );
    }

    return (
        <React.Fragment>
            <Title title='Документы регистратуры'/>
            <Loading loading={addState.loading || deleteState.loading || userDepartmentsState.loading || allDepartmentsState.loading} />
            <h1>Документы регистратуры</h1>
            <Button variant="contained" onClick={saveAction} color="secondary">Сохранить</Button>
            <Paper sx={{marginTop: 2}}>
                {renderBody()}
            </Paper>
        </React.Fragment>
    )
};

export default Index;