import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import theme from './theme';
import HexAuthProvider from 'hex/providers';
import HexTitleProvider from 'hex/providers/title'
import HexLoadingProvider from 'hex/providers/loading'

// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

// import ru from 'date-fns/locale/ru';
import 'dayjs/locale/ru';

import Startup from 'startup'

import {auth, client} from 'config'

import { ApolloProvider} from '@apollo/client';
import { LocalizationProvider } from '@mui/x-date-pickers';

import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { SnackbarKey, useSnackbar } from "notistack";
import { Close } from "@mui/icons-material";
import { IconButton } from '@mui/material';

dayjs.extend(utc);
dayjs.extend(timezone);

function SnackbarCloseButton({ snackbarKey } : { snackbarKey: SnackbarKey}) {
    const { closeSnackbar } = useSnackbar();
  
    return (
      <IconButton onClick={() => closeSnackbar(snackbarKey)} color='primary'>
        <Close />
      </IconButton>
    );
  }

type HexAppProps = {

};


const HexApp: React.FunctionComponent<HexAppProps> = () => {
    return (
        <div className="App">
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
                <ThemeProvider theme={theme}>
                    <HexAuthProvider auth={auth}>
                        <HexTitleProvider>
                            <HexLoadingProvider>
                                <ApolloProvider client={client}>
                                    <CssBaseline />
                                    <SnackbarProvider maxSnack={5} preventDuplicate action={snackKey => <SnackbarCloseButton snackbarKey={snackKey} />}>
                                        <Startup />
                                    </SnackbarProvider>
                                </ApolloProvider>
                            </HexLoadingProvider>
                        </HexTitleProvider>
                    </HexAuthProvider>
                </ThemeProvider>
            </LocalizationProvider>
        </div>
    );
}

export default HexApp;
