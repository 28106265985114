import React from 'react';

import { RouterProvider} from "react-router-dom";
import { baseRouter } from 'router'


type HexStartupProps = {

};

const HexStartup: React.FunctionComponent<HexStartupProps> = () => {
    return (
        <RouterProvider router={baseRouter} />
    );
}

export default HexStartup;
