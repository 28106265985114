import React from 'react';
import Modal from 'hex/components/material/modal';

import RichText from 'components/wysiwyg/rich_text'

type PreviewProps = {
    name: string;
    text: string;
    styles?: string;
    onSave?: (text: string) => void;
    onPrint?: (text: string) => void;
    onClose: () => void;
};

const Preview : React.FunctionComponent<PreviewProps> = ({onSave, onClose, onPrint, text, name, styles}) =>
{
    const [dataText, setDataText] = React.useState<string>(text);
    
    const onSaveProps = onSave ? {
        secondActionButtonText: 'Сохранить',
        secondActionButtonAction: () => {
            if (onSave !== undefined) onSave(dataText);
        },
    } : {};

    const onPrintProps = onPrint ? {
        actionButtonText: 'Печать',
        actionButtonAction: () => {
            if (onPrint !== undefined) onPrint(dataText);
        },
    } : {};

    return (
        <>
            <Modal
                open={true}
                title='Предпросмотр документа'
                contentTitle={name}
                closeButtonText='Отмена'
                closeButtonAction={onClose}
                {...onPrintProps}
                {...onSaveProps}
                maxWidth='lg'
                fullScreen
                actionButtonEnabled={true}
            >
                {styles !== undefined && <div dangerouslySetInnerHTML={{
                    __html: styles
                    }}>
                </div>}
                <>
                    <RichText text={dataText} setText={setDataText} />
                </>
            </Modal>
        </>
    );
}

export default Preview;