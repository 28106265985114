import React from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import {Dehaze} from '@mui/icons-material'

import { ActionType, ModelType } from './types';


// Props:
type ItemMenuProps<TModel extends ModelType> = {
    actions: Array<ActionType<TModel>>,
    model: TModel;
};

function ItemMenu<TModel extends ModelType>({actions, model} : ItemMenuProps<TModel>) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div onClick={(event) => {event.stopPropagation(); event.preventDefault();}}>
            <IconButton onClick={handleClick}>
                <Dehaze />
            </IconButton>
            <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                {actions.map((action) => 
                    {
                        let enabled = (typeof action.enabled === 'function') ? action.enabled(model) : action.enabled;

                        return (
                            <MenuItem key={action.label} onClick={(event) => {action.action(model); handleClose()}} disabled={!enabled}>
                                {action.label}
                            </MenuItem>
                        );
                    })
                }
            </Menu>
        </div>
    );
};

export default ItemMenu;