import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import React from 'react';
import { StepData } from './types';
import { useAuth } from 'hex/hooks/auth';

import {useMoveToDoctorMutation} from 'gql'
import { useLoading } from 'hex/hooks/loading';
import { useErrorChecking } from 'hex/components/material/hooks';
import dayjs, {Dayjs} from 'dayjs'
import { VisitStatus } from 'config';

export type DateStepState = {
    onNext: () => Promise<boolean>;
    isEnabled: () => boolean;
    done: boolean;
};

type DateStepProps = StepData & DateStepState;

export function useDateStep({setVisit, visitData, onDone} : StepData, visit_id: number, forwardDisable?: boolean) : DateStepState
{
    const auth = useAuth();

    const [moveToDoctorMutation, { data, loading, error }] = useMoveToDoctorMutation({
        variables: {
            id: visit_id,
            current_reception_id: null,
            doctor_id: visitData.doctor_id === undefined ? null : visitData.doctor_id,
            status: forwardDisable !==undefined && forwardDisable === true ? undefined : VisitStatus.Redirected,
            department_id: visitData.department_id,
            date: visitData.date,
            time: visitData.time,
            from_id: auth.getUserId(),
            without_time: visitData.without_time
        }
    });

    useErrorChecking(error);

    // eslint-disable-next-line
    const [loadingState, setLoading] = useLoading();

    React.useEffect(() => {
        setLoading(loading);

        return () => {setLoading(false);};
    }, [setLoading, loading]);

    const onNext = async () => {
        const result = await moveToDoctorMutation();

        if (result.errors === undefined)
        {
            // onDone();
            return true;
        }

        return false;
    }
    
    const isEnabled = () => {
        return true;
    }

    return {
        onNext,
        isEnabled,
        done: (data !== undefined) && (error === undefined),
    };
}

export const dateFormat = 'YYYY-MM-DD';
export const timeFormat = 'HH:mm:ssZ';

const DateStep: React.FunctionComponent<DateStepProps> = ({setVisit, visitData}) => {
    const onDateChange = (value: Dayjs | null): void => {
        if (value) setVisit(oldModel => {
            var copy = {...oldModel};

            copy.date = value.format(dateFormat);

            return copy;
        });
    }

    const onTimeChange = (value: Dayjs | null): void => {
        if (value) setVisit(oldModel => {
            var copy = {...oldModel};

            copy.time = value.format(timeFormat);

            return copy;
        });
    }

    const onWithoutTimeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setVisit(oldModel => {
            var copy = {...oldModel};

            copy.without_time = event.target.checked;

            return copy;
        });
    }

    return (
        <>
            <Grid container sx={{flexDirection: 'row', marginTop: '8px'}} spacing={2}>
                <Grid item>
                    <DatePicker label="Дата" value={dayjs(visitData.date, dateFormat)} onChange={onDateChange} disablePast />
                </Grid>
                <Grid item>
                    <TimePicker ampm={false} disabled={visitData.without_time} label="Время" value={dayjs(visitData.time, timeFormat)} onChange={onTimeChange} minutesStep={5} />
                </Grid>
                <Grid item>
                    <FormControlLabel control={<Checkbox onChange={onWithoutTimeChange} checked={visitData.without_time} />} label="Без времени" />
                </Grid>
            </Grid>
        </>
    );
}

export default DateStep;