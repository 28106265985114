import { createBrowserRouter } from "react-router-dom";
import AuthGate from 'hex/components/auth_gate'
import Layout from 'startup/auth_layout'

// Pages:
import IndexPage from "pages/index";
import LoginPage from "pages/login";
import UsersPage from "pages/users";
import ServicesPage from 'pages/services'
import ClientsPage from 'pages/clients'
import PetTypesPage from 'pages/pet_types'
import PetsPage from 'pages/pets'
import Medicine from 'pages/medicine'
import Departments from 'pages/departments'
import DocumentsPage from 'pages/documents'
import ReceptionDocumentsPage from 'pages/reception_documents'
import UnitsPage from 'pages/units'
import ReceptionsPage from 'pages/receptions_edit'
import SchemasPage from 'pages/schemas'
import CardsPage from 'pages/cards'
import TemplatesPage from 'pages/templates'

import { AccessMap } from "config";

const loginPagePath: string = "/login/";

export const baseRouter = createBrowserRouter([
    {
        path: "/",
        element:
            <AuthGate loginLink={loginPagePath}>
                <Layout>
                    <IndexPage />
                </Layout>
            </AuthGate>,
    },
    {
        path: loginPagePath,
        element:
            <Layout>
                <LoginPage />,
            </Layout>
    },
    {
        path: "/clients/",
        element:
            <AuthGate loginLink={loginPagePath} roles={AccessMap.clientsPage}>
                <Layout>
                    <ClientsPage />
                </Layout>
            </AuthGate>,
    },
    {
        path: "/pet-types/",
        element:
            <AuthGate loginLink={loginPagePath} roles={AccessMap.petsTypesPage}>
                <Layout>
                    <PetTypesPage />
                </Layout>
            </AuthGate>,
    },
    {
        path: "/pets/",
        element:
            <AuthGate loginLink={loginPagePath} roles={AccessMap.petsPage}>
                <Layout>
                    <PetsPage />
                </Layout>
            </AuthGate>,
    },
    {
        path: "/services/",
        element:
            <AuthGate loginLink={loginPagePath} roles={AccessMap.servicesPage}>
                <Layout>
                    <ServicesPage />
                </Layout>
            </AuthGate>,
    },
    {
        path: "/medicine/",
        element:
            <AuthGate loginLink={loginPagePath} roles={AccessMap.medicinesPage}>
                <Layout>
                    <Medicine />
                </Layout>
            </AuthGate>,
    },
    {
        path: "/departments/",
        element:
            <AuthGate loginLink={loginPagePath} roles={AccessMap.departmentsPage}>
                <Layout>
                    <Departments />
                </Layout>
            </AuthGate>,
    },
    {
        path: "/users/",
        element:
            <AuthGate loginLink={loginPagePath} roles={AccessMap.usersPage}>
                <Layout>
                    <UsersPage />
                </Layout>
            </AuthGate>,
    },
    {
        path: "/documents/",
        element:
            <AuthGate loginLink={loginPagePath} roles={AccessMap.documentsPage}>
                <Layout>
                    <DocumentsPage />
                </Layout>
            </AuthGate>,
    },
    {
        path: "/reception-documents/",
        element:
            <AuthGate loginLink={loginPagePath} roles={AccessMap.receptionDocumentsPage}>
                <Layout>
                    <ReceptionDocumentsPage />
                </Layout>
            </AuthGate>,
    },
    {
        path: "/units/",
        element:
            <AuthGate loginLink={loginPagePath} roles={AccessMap.unitsPage}>
                <Layout>
                    <UnitsPage />
                </Layout>
            </AuthGate>,
    },
    {
        path: "/reception-edit/",
        element:
            <AuthGate loginLink={loginPagePath} roles={AccessMap.receptionEdit}>
                <Layout>
                    <ReceptionsPage />
                </Layout>
            </AuthGate>,
    },
    {
        path: "/schemas/",
        element:
            <AuthGate loginLink={loginPagePath} roles={AccessMap.schemas}>
                <Layout>
                    <SchemasPage />
                </Layout>
            </AuthGate>,
    },
    {
        path: "/cards/",
        element:
            <AuthGate loginLink={loginPagePath} roles={AccessMap.cards}>
                <Layout>
                    <CardsPage />
                </Layout>
            </AuthGate>,
    },
    {
        path: "/templates/",
        element:
            <AuthGate loginLink={loginPagePath} roles={AccessMap.templates}>
                <Layout>
                    <TemplatesPage />
                </Layout>
            </AuthGate>,
    },
]);