import React from 'react';
import Title from 'hex/components/title'
import Loading from 'hex/components/loading'

import HexTable from 'hex/components/material/table'

import { useSnackbar } from 'notistack';
import { HeadCell } from 'hex/components/material/table/types';
import { useOperations, usePagination, useSorting } from 'hex/components/material/hooks';

import {useDepartmentsIndexSubscription, useDepartmentIndexCountSubscription} from 'gql'
import { TableDepartments } from './types';
import { OperationType } from 'hex/components/material/types';
import Operations from 'hex/components/material/operations';

import {CreateOperation, defaultFilter, DeleteOperation, DeleteSelectedOperation, FilterType, FiltrationOperation, TranslateFiltrationToWhere, UpdateOperation} from './operations'
import DocumentsPart from './documents';

// Props:
type DepartmentsPageProps = {

};

const headCells : HeadCell<TableDepartments>[] = [
    {
        id: "name",
        label: "Наименование",
        sort: true,
        render: (service) => (<React.Fragment>{service.name}</React.Fragment>)
    }
];

const UsersPage: React.FunctionComponent<DepartmentsPageProps> = () => {
    // eslint-disable-next-line
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [pagination, setPagination] = usePagination();
    const [order, onSetOrder] = useSorting<TableDepartments>({orderBy: "name", order: "asc"});
    const [filter, setFilter] = React.useState<FilterType>(defaultFilter);

    const [documentsOpen, setDocumentsOpen] = React.useState<boolean>(false);
    const [department, setDepartment] = React.useState<TableDepartments | undefined>(undefined);

    const operationsArray = React.useMemo<Array<OperationType<TableDepartments>>>(() => {
        return [
            FiltrationOperation(filter, setFilter),
            CreateOperation(),
            UpdateOperation(),
            DeleteOperation(),
            DeleteSelectedOperation(),
        ];
    }, [filter, setFilter]);
    const operations = useOperations<TableDepartments>(operationsArray);

    const fixedActions = React.useMemo(() => {
        return [{
            label: "Список документов",
            enabled: true,
            action: (model: TableDepartments) => {
                setDocumentsOpen(true);
                setDepartment(model);
            }
        }, ...operations.actions];
    }, [operations])

    const { data, loading, error } = useDepartmentsIndexSubscription({variables: { offset: pagination.offset, limit: pagination.limit, order_by: { [order.orderBy]: order.order, }, where: TranslateFiltrationToWhere(filter)}});
    const countResult = useDepartmentIndexCountSubscription({variables: { where: TranslateFiltrationToWhere(filter) }});

    const models : TableDepartments[] | undefined = (data === undefined) ? undefined : data.Department;

    var count = React.useMemo<number>(() => {
        if ((countResult.data !== undefined) && (countResult.data.Department_aggregate.aggregate !== undefined) && (countResult.data.Department_aggregate.aggregate !== null))
            return countResult.data.Department_aggregate.aggregate.count;
        
        return 0;
    }, [countResult]);

    React.useMemo(() => {
        if (error !== undefined)
        {
            enqueueSnackbar(`Ошибка получения данных! ${error.message}`, {variant: 'error'});
        }
        else if (countResult.error !== undefined)
        {
            enqueueSnackbar(`Ошибка получения данных! ${countResult.error.message}`, {variant: 'error'});
        }
    }, [error, enqueueSnackbar, countResult]);
    

    return (
        <React.Fragment>
            <Title title='Отделения'/>
            <Loading loading={loading || countResult.loading} />
            <h1>Отделения</h1>
            <HexTable {...pagination} order={order.order} orderBy={order.orderBy as keyof TableDepartments} 
                      count={count} onPageChange={setPagination} models={models} headCells={headCells} onOrderSet={onSetOrder}
                      globalActions={operations.globalActions} selectedActions={operations.selectedActions} actions={fixedActions}
            /> 
            <Operations args={operationsArray} eventIndex={operations.eventIndex} model={operations.model} selectedModels={operations.selectedModels} close={operations.close} />
            {department !== undefined && documentsOpen && <DocumentsPart open={documentsOpen} close={() => {setDocumentsOpen(false); setDepartment(undefined);}} department_id={department.id} name={department.name} />}
        </React.Fragment>
    )
}

export default UsersPage;