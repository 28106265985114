import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';


type MenuElement = {
    name: string;
    disabled?: boolean;
    onClick: () => void;
};

// Props:
type TreeViewItemMenuProps = {
    elements: Array<MenuElement>;
};

const TreeViewItemMenu : React.FunctionComponent<TreeViewItemMenuProps> = ({elements}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div onClick={(event) => {event.stopPropagation(); event.preventDefault();}}>
            <IconButton onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                {elements.map((element) => (
                    <MenuItem key={element.name} onClick={() => {element.onClick(); handleClose()}} disabled={element.disabled}>
                        {<Typography variant='body1'>{element.name}</Typography>}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default TreeViewItemMenu;