import React from 'react';

export type Validations<TModel> = Array<[keyof TModel, (value: any) => string | undefined]>;
export type ValidationResultData<TModel> = Array<[keyof TModel, string]> | undefined;
export type ValidatorCallback<TModel> = (field: keyof TModel) => (value: any) => void;
export type ValidationModel<TModel> = (model: TModel) => boolean;

export function useValidator<TModel>(validations: Validations<TModel>) : [ValidationResultData<TModel>, ValidatorCallback<TModel>, ValidationModel<TModel>]
{
    const [validation, setValidation] = React.useState<ValidationResultData<TModel>>();
    const validator : ValidatorCallback<TModel> = React.useCallback( 
        (field: keyof TModel) => {
            let fieldTest = validations.filter((validationFromArray) => validationFromArray[0] === field);

            return (value: any) => {

                if (fieldTest.length !== 0)
                {
                    let resultArray : Array<string> = [];

                    for (let index = 0; index < fieldTest.length; index++) {
                        const element = fieldTest[index];
                        
                        const result = element[1](value);
                        if (result !== undefined)
                            resultArray.push(result);
                    }

                    setValidation((oldValue) => {
                        var result : Array<[keyof TModel, string]> = [];
                        
                        if (oldValue !== undefined)
                        {
                            for (let ol = 0; ol < oldValue.length; ol++) {
                                const element = oldValue[ol];
                                
                                if (element[0] !== field)
                                    result.push([element[0], element[1]]);
                            }
                        }

                        for (let index = 0; index < resultArray.length; index++) {
                            const element = resultArray[index];
                            
                            result.push([field, element]);
                        }

                        return result.length === 0 ? undefined : result;
                    });
                }
            }
        }
    , [setValidation, validations]);

    const modelValidation : ValidationModel<TModel> = React.useCallback(
        (model: TModel) => {
            let resultArray : Array<[keyof TModel, string]> = [];

            for (let index = 0; index < validations.length; index++) {
                const element = validations[index];
                
                const result = element[1](model[element[0]]);
                if (result !== undefined)
                    resultArray.push([element[0], result]);
            }

            setValidation(resultArray.length === 0 ? undefined : resultArray);

            return resultArray.length === 0;
        }
        , [setValidation, validations]);

    return [validation, validator, modelValidation];
}

export function ConvertToFieldErrors<TModel>(field: keyof TModel, data: ValidationResultData<TModel>) : Array<string> | undefined
{
    if (data !== undefined)
    {
        let result = data.filter((val) => val[0] === field);

        if (result.length !== 0)
            return result.map((val) => val[1]);
    }

    return undefined;
}