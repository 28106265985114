import { Box, CircularProgress, Grid } from '@mui/material';
import { useErrorAndLoadChecking } from 'hex/components/material/hooks';
import Modal from 'hex/components/material/modal';
import React from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useAuth } from 'hex/hooks/auth';
import { GetDoctorsTemplatesQuery, useGetDoctorsTemplatesQuery } from 'gql';


type TemplatesProps = {
    open: boolean;
    onClose: () => void;
    onAccept: (model : GetDoctorsTemplatesQuery["Template"][number]) => void;
};

const Templates: React.FC<TemplatesProps> = ({ open, onClose, onAccept }) => {
    const auth = useAuth();

    const [selectedSchema, setSelectedSchema] = React.useState<number>(0);

    const {data, loading, error} = useGetDoctorsTemplatesQuery({
        variables: {
            user_id: auth.getUserId(),
        },
        fetchPolicy: 'network-only',
    });

    useErrorAndLoadChecking({error, loading});

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedSchema(parseInt((event.target as HTMLInputElement).value, 10));
    };

    const renderTemplates = () => {
        if (data === undefined)
            return (
                <>
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>
                </>
            );
        
        return (
            <FormControl>
                <FormLabel>Доступные шаблоны:</FormLabel>
                <RadioGroup
                    value={selectedSchema.toString()}
                    onChange={handleChange}
                >
                    {data.Template.map(element => (
                        <FormControlLabel key={element.id} value={element.id} control={<Radio />} label={element.name} />
                    ))}
                </RadioGroup>
            </FormControl>
        )
    }

    return (
        <Modal actionButtonEnabled={selectedSchema !== 0} fullScreen open={open} title='Шаблоны' contentTitle='Выбор шаблона' closeButtonText='Отмена' closeButtonAction={onClose} actionButtonText='Применить' actionButtonAction={() => {
            onAccept(data?.Template.find(element => element.id === selectedSchema) as GetDoctorsTemplatesQuery["Template"][number]);
            setSelectedSchema(0);
            onClose();
         }}>
            {renderTemplates()}
        </Modal>
    );
}

export default Templates;