import React, { SetStateAction } from 'react'

import {OperationType, PaginationParameters} from 'hex/components/material/types'
import { ActionType, GlobalActionType, ModelType, Order, SelectedActionType } from '../table/types';
import { ApolloError } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useLoading } from 'hex/hooks/loading';

export function usePagination() : [PaginationParameters, (limit: number, offset: number) => void]
{
    const [paginationState, setPaginationState] = React.useState<PaginationParameters>({limit: 10, offset: 0});

    const onPageChange = (limit : number, offset : number) => {
        setPaginationState({limit: limit, offset: offset})
    }

    return [paginationState, onPageChange];
}

export function useSorting<TOrderBy>(args : {orderBy: keyof TOrderBy; order: Order}) : [{orderBy: keyof TOrderBy; order: Order}, (orderBy: keyof TOrderBy, order: Order) => void]
{
    const [orderBy, setOrder] = React.useState<{orderBy: keyof TOrderBy; order: Order}>(args)

    const onSetOrder = (orderBy: keyof TOrderBy, order: Order) => {
        setOrder({orderBy: orderBy as keyof TOrderBy, order: order});
    }

    return [orderBy, onSetOrder];
}

export type UseOperationsResult<TModel extends ModelType> = {
    globalActions: Array<GlobalActionType>;
    selectedActions: Array<SelectedActionType<TModel>>;
    actions: Array<ActionType<TModel>>;
    eventIndex: number | undefined;
    model: TModel | undefined;
    selectedModels: TModel[] | undefined;
    close: () => void;
};

export function useOperations<TModel extends ModelType>(args: Array<OperationType<TModel>>) 
:   UseOperationsResult<TModel>
{
    const [eventIndex, setEventIndex] = React.useState<number | undefined>(undefined);
    const [selectedModels, setSelectedModels] = React.useState<TModel[] | undefined>(undefined);
    const [model, setModel] = React.useState<TModel | undefined>(undefined);

    const globalActions : Array<GlobalActionType> = React.useMemo(() => 
    {
        let result : Array<GlobalActionType> = [];

        for (let index = 0; index < args.length; index++) {
            const element = args[index];
            
            if (element.type === 'global')
            {
                result.push({
                    label: element.label,
                    icon: element.icon,
                    action: () => {
                        setEventIndex(index);
                    }
                });
            }
        }

        return result;
    }    
    , [args, setEventIndex]);

    const selectedActions : Array<SelectedActionType<TModel>> = React.useMemo(() => {
        let result : Array<SelectedActionType<TModel>> = [];

        for (let index = 0; index < args.length; index++) {
            const element = args[index];
            
            if (element.type === 'selected')
            {
                result.push({
                    label: element.label,
                    icon: element.icon,
                    action: (models) => {
                        setEventIndex(index);
                        setSelectedModels(models);
                    }
                });
            }
        }

        return result;
    }, [args, setEventIndex, setSelectedModels]);

    const actions : Array<ActionType<TModel>> = React.useMemo(() => {
        let result : Array<ActionType<TModel>> = [];

        for (let index = 0; index < args.length; index++) {
            const element = args[index];
            
            if (element.type === 'individual')
            {
                result.push({
                    label: element.label,
                    enabled: element.enabled,
                    action: (model) => {
                        setEventIndex(index);
                        setModel(model);
                    }
                });
            }
        }

        return result;
    }, [setEventIndex, setModel, args]);

    const close = React.useCallback(() => {
        setEventIndex(undefined);
        setSelectedModels(undefined);
        setModel(undefined);
    }, [setEventIndex, setSelectedModels, setModel]);

    return {
        globalActions,
        selectedActions,
        actions,
        eventIndex,
        model,
        selectedModels,
        close
    }
}

export function useErrorChecking(error: ApolloError | undefined)
{
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    
    React.useMemo(() => {
        if (error !== undefined)
        {
            enqueueSnackbar(`Ошибка получения данных! ${error.message}`, {variant: 'error'});
        }
    }, [error, enqueueSnackbar]);
}

export function useErrorAndLoadChecking(data: {error?: ApolloError, loading: boolean})
{
    // eslint-disable-next-line
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    // eslint-disable-next-line
    const [loadingState, setLoading] = useLoading();

    React.useEffect(() => {
        setLoading(data.loading);

        return () => {setLoading(false);};
    }, [setLoading, data.loading]);
    
    React.useMemo(() => {
        if (data.error !== undefined)
        {
            enqueueSnackbar(`Ошибка получения данных! ${data.error.message}`, {variant: 'error'});
        }
    }, [data.error, enqueueSnackbar]);
}