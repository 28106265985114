import React from 'react';
import { Navigate } from "react-router-dom";
import {useAuth} from 'hex/hooks/auth'

// Props:
type AuthorizedProps = {
    children?: React.ReactNode
    roles: Array<string>;
};

const Authorized: React.FunctionComponent<AuthorizedProps> = ({ children, roles }) => {
    const auth = useAuth();

    const role = auth.getUserRole();
    const isAuthentificated = auth.isAuthentificated();

    if (isAuthentificated && (role !== null) && (roles.indexOf(role) !== -1))
        return (
            <>
                {children}    
            </>
        );
    
    return (<></>);
}

export default Authorized;