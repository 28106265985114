import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { useTakeClientVisitsSubscription } from "gql";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useErrorAndLoadChecking } from "hex/components/material/hooks";
import Modal from "hex/components/material/modal";
import React from "react";
import moment from "moment";
import { timeFormat } from "./steps/date";
import { dateDiff } from "hex/utils/dates";
import { TranslateStatus } from "config";
import ReceptionView from "pages/receptions_edit/reception_view";

import DocumentsBlock from 'pages/index/doctor/documents';
import { ReceptionDataType } from "pages/receptions_edit/types";

const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;


type ReceptionsListProps = {
    pet_id: any;
    onClose: () => void;
    onCopy: (model: ReceptionDataType) => void;
};

const ReceptionsList : React.FC<ReceptionsListProps> = ({pet_id, onClose, onCopy}) => {
    const { data, loading, error } = useTakeClientVisitsSubscription({
        variables: {
            id: pet_id,
        }
    });

    useErrorAndLoadChecking({loading, error});

    const renderBody = () => {
        if (data === undefined)
            return (
                <>
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>
                </>
            );
        
        return (
            <>
                {data.Visit.map((visit) => (
                    <Box key={visit.id} sx={{ m: 1 }}>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Grid container spacing={2} alignItems='center'>
                                    <Grid item>
                                        <Typography variant='button'>{moment(visit.date).format('DD-MM-YYYY')} - {!visit.without_time ? moment.utc(visit.time, timeFormat).tz(clientTimeZone).format('HH:mm') : '<--:-->'}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='button'>{visit.Client.name}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='caption'>{`${visit.Pet.name} (${visit.Pet.PetType.name})`}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='caption'>{`${visit.Pet.birthdate ? dateDiff(new Date(visit.Pet.birthdate), new Date(Date.now())) : '<Не указан>'} (${visit.Pet.birthdate ? moment.utc(visit.Pet.birthdate).tz(clientTimeZone).format("DD-MM-YYYY") : 'Не указан'})`}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='caption'>{TranslateStatus(visit.status)}</Typography>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ReceptionView hideServicesSum visit={visit} isEditingIsDisabled={true} onCommonInformationEdit={() => {}} onReceptionEdit={() => {}}
                                documentsBlock={(props) => (<DocumentsBlock {...props} />)} onCopy={onCopy} />
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                ))}
            </>
        )
    }

    return (
        <Modal open title="Просмотр предыдущих приемов" contentTitle="Приемы пациента" fullScreen closeButtonText="Закрыть" closeButtonAction={onClose}>
            <Paper sx={{backgroundColor: 'grey.300', p: 2}}>
                {renderBody()}
            </Paper>
        </Modal>
    )
}

export default ReceptionsList;