import { OperationType } from 'hex/components/material/types'
import { SchemaCategoryWithParent } from './types'

import AddIcon from '@mui/icons-material/Add';
import Create from 'hex/components/material/operations/create';
import Update from 'hex/components/material/operations/update';
import { ApolloError } from '@apollo/client';
import { ConvertToFieldErrors, ValidationResultData, Validations, ValidatorCallback } from 'hex/hooks/validator';
import { StringLengthMinMax } from 'hex/validations';
import TextFieldData from 'hex/components/material/operations/fields/text';


import Delete from 'hex/components/material/operations/delete';
import { useAddSchemaCategoryMutation, useDeleteSchemaCategoryMutation, useUpdateSchemaCategoryMutation } from 'gql';


const validations : Validations<SchemaCategoryWithParent> = [
    ["name", StringLengthMinMax(2, 100)],
]

const renderFields = (model: SchemaCategoryWithParent, setFieldValue: (field: keyof SchemaCategoryWithParent) => (value: any) => void, validator: ValidatorCallback<SchemaCategoryWithParent>, validation: ValidationResultData<SchemaCategoryWithParent>) => {
    return (
        <>
            <TextFieldData label='Наименование' value={model.name} onChange={setFieldValue("name")} validator={validator("name")} errors={ConvertToFieldErrors("name", validation)} />
        </>
    );
}

const renderDeleteText = (model: SchemaCategoryWithParent) => {
    return `Вы действительно уверены, что хотите удалить категорию ${model.name}? Все связанные подкатегории и схемы будут удалены.`;
}

function createMutation(model: SchemaCategoryWithParent)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useAddSchemaCategoryMutation({variables: {
        name: model.name,
        parent_id: model.parent_id
    }});
}

function updateMutation(model: SchemaCategoryWithParent)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useUpdateSchemaCategoryMutation({
        variables: {
            id: model.id,
            name: model.name
        }
    })
}

function deleteMutation(model: SchemaCategoryWithParent)
{
    // Обязательно, ибо eslint негодует, если вызывать хуки не в пределах элемента
    // eslint-disable-next-line
    return useDeleteSchemaCategoryMutation({
        variables: {
            id: model.id,
        }
    });
}

const translateError = (error: ApolloError) => {
    return error.message;
}

const defaultModel : SchemaCategoryWithParent = {id: 0, name: ""};

export function CreateOperation(patching: (model: SchemaCategoryWithParent) => SchemaCategoryWithParent) : OperationType<SchemaCategoryWithParent>
{
    return {
        type: 'global',
        label: 'Создать',
        icon: (<AddIcon />),
        render: (close) => (
            <Create
                close={close}
                defaultModel={defaultModel}
                translateError={translateError}
                validations={validations}
                renderFields={renderFields}
                useMutation={createMutation}
                prefillDefaultModel={patching}
            />
        ),
    }
}

export function UpdateOperation() : OperationType<SchemaCategoryWithParent>
{
    return {
        type: 'individual',
        label: 'Изменить',
        enabled: true,
        render: (model, close) => (
            <Update 
                close={close} 
                defaultModel={model} 
                translateError={translateError}
                validations={validations}
                renderFields={renderFields}
                useMutation={updateMutation}
            />
        ),
    }
}

export function DeleteOperation() : OperationType<SchemaCategoryWithParent>
{
    return {
        type: 'individual',
        label: 'Удалить',
        enabled: true,
        render: (model, close) => (
            <Delete 
                close={close} 
                model={model}
                translateError={translateError}
                renderText={renderDeleteText}
                useMutation={deleteMutation}
            />
        ),
    }
}