import React from 'react';
import Modal from 'hex/components/material/modal'
import { useValidator, ValidationResultData, Validations, ValidatorCallback } from 'hex/hooks/validator';
import { ModelType } from 'hex/components/material/table/types';


// Props:
type CreateProps<TModel extends ModelType> = {
    defaultModel: TModel;
    renderFields: (model: TModel, setFieldValue: (field: keyof TModel) => (value: any) => void, validator: ValidatorCallback<TModel>, validation: ValidationResultData<TModel>) => React.ReactNode;
    validations: Validations<TModel>;
    onAction: (model: TModel) => void;
    close: () => void;
};


function Create<TModel extends ModelType>(props: CreateProps<TModel>)
{
    var {defaultModel, renderFields, onAction, validations, close} = props;

    const [model, setModel] = React.useState<TModel>(defaultModel);
    const [validation, validator, modelValidation] = useValidator<TModel>(validations);

    const setModelField = React.useCallback((field: keyof TModel) => (value: any) => {
        setModel(oldModel => {
            var copy = {...oldModel};

            copy[field] = value;

            return copy;
        });
    }, [setModel]);

    const onCreate = () => {
        if (modelValidation(model))
        {

            onAction(model);
            setModel(defaultModel);
            close();
        }
    }

    return (
        <>
            <Modal open={true} title='Добавить объект' contentTitle='Создание объекта' closeButtonText='Отмена' closeButtonAction={() => {setModel(defaultModel); close();}} actionButtonText='Создать' actionButtonAction={onCreate}>
                {renderFields(model, setModelField, validator, validation)}
            </Modal>
        </>
    );
};

export default Create;