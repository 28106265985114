import React from 'react';

import { useAuth } from 'hex/hooks/auth';
import { uploadFile } from 'hex/utils/upload';
import { Button, FormGroup } from '@mui/material';

// Props:
type FileFieldProps = {
    label?: string;
    value?: any;
    onChange: (value: any, oldName: string) => any;
};

// Element:
const FileField : React.FunctionComponent<FileFieldProps> = ({label, value, onChange}) => {
    // Auth:
    const auth = useAuth();

    // Events:
    const handleCapture = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if ((event.target.files !== null) && (event.target.files.length !== 0))
        {
            try
            {   
                var result = await uploadFile(auth.getHttpClient(), event.target.files[0] as any);

                onChange(result, event.target.files[0].name);
            }
            catch (error)
            {
                alert(JSON.stringify(error));
            }
        }
    }

    return (
        <FormGroup>
            <Button variant="contained" component="label">
                Загрузка {label}
                <input type="file" hidden onChange={handleCapture} />
            </Button>
        </FormGroup>
    );
}

export default FileField;