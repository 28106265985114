import { GetReceptionsOfVisitQuery } from "gql";
import React from "react";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Grid, Typography } from "@mui/material";
import { useAuth } from "hex/hooks/auth";


type StandartHistoryProps = {
    data: GetReceptionsOfVisitQuery;
};

const calculateSum = (services: Array<{price: any, value: any}>) => {
    let sum = 0;
    services.forEach((element) => sum += (element.price as number * element.value as number));

    return sum;
}

const StandartHistory : React.FC<StandartHistoryProps> = ({data}) => {
    const auth = useAuth();

    const [expanded, setExpanded] = React.useState<Array<number>>([]);

    const handleChange =
        (id: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            if (!isExpanded)
            {
                setExpanded((oldValue) => {
                    var copy = [...oldValue];

                    copy.splice(copy.indexOf(id), 1);

                    return copy;
                });
            }
            else
            {
                setExpanded((oldValue) => {
                    var copy = [...oldValue];

                    copy.push(id);

                    return copy;
                });
            }
        };

    const onExpandAll = () => {
        setExpanded(data.Reception.map(x => x.id));
    }

    const onCloseAll = () => {
        setExpanded([]);
    }
    
    return (
        <>
        <Grid container flexDirection="row" sx={{marginBottom: 2}}>
            <Grid item>
                <Button onClick={onExpandAll} variant="outlined" color="secondary">Раскрыть все</Button>
            </Grid>
            <Grid item sx={{marginLeft: "auto"}}>
                <Button onClick={onCloseAll} variant="outlined" color="secondary">Свернуть все</Button>
            </Grid>
        </Grid>
            {data.Reception.map((reception) => 
            ( 
                <Accordion 
                    expanded={expanded.indexOf(reception.id) !== -1} 
                    onChange={handleChange(reception.id)}
                    key={reception.id} 
                    sx={(theme) => ({bgcolor: theme.palette.info.light, color: theme.palette.info.contrastText})}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{'Врач'} - <b><i>{reception.Doctor.Name}</i></b> - <i>{`(направил: ${reception.From.Name})`}</i></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant='button'>
                            <b>Примечание:</b>
                        </Typography>
                        <Typography style={{whiteSpace: 'pre-line'}}>
                            {reception.internal_recomendation}
                        </Typography>
                        <Typography variant='button'>
                            <b>Рекомендации:</b>
                        </Typography>
                        <Typography style={{whiteSpace: 'pre-line'}}>
                            {reception.patient_recomendation}
                        </Typography>
                        <Typography variant='button'>
                            <b>Услуги:</b>
                        </Typography>
                        <Typography>
                            {reception.Services.length === 0 && <Typography>Отсутствуют</Typography>}
                            {reception.Services.length !== 0 && 
                                <ul style={{margin: 0}}>
                                    {reception.Services.map((service) => (<li key={service.id}>{service.Service.name} {auth.getUserId() === reception.doctor_id ? `${service.price} * ${service.value} = ${service.price as number * service.value as number}` : ''}</li>))}
                                </ul>
                            }
                            {
                                auth.getUserId() === reception.doctor_id && <Typography>Сумма: {calculateSum(reception.Services)} руб.</Typography>
                            }
                        </Typography>
                        <Typography variant='button'>
                            <b>Препараты:</b>
                        </Typography>
                        <Typography>
                            {reception.Medicines.length === 0 && <Typography>Отсутствуют</Typography>}
                            {reception.Medicines.length !== 0 && 
                                <ul style={{margin: 0}}>
                                    {reception.Medicines.map((medicine) => (<li key={medicine.id}>{medicine.done ? '✓' : ''}{medicine.Medicine.name}</li>))}
                                </ul>
                            }
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))} 
        </>
    )
}

export default StandartHistory;