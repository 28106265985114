import React from 'react';
import Title from 'hex/components/title'
import Loading from 'hex/components/loading'

import HexTable from 'hex/components/material/table'

import { useSnackbar } from 'notistack';
import { HeadCell } from 'hex/components/material/table/types';
import { useOperations, usePagination, useSorting } from 'hex/components/material/hooks';

import { OperationType } from 'hex/components/material/types';
import Operations from 'hex/components/material/operations';

import {useClientsIndexSubscription, useClientsIndexCountSubscription} from 'gql'
import {CreateOperation, defaultFilter, DeleteOperation, DeleteSelectedOperation, FilterType, FiltrationOperation, TranslateFiltrationToWhere, UpdateOperation} from './operations'
import { TableClient } from './types';
import ClientReceptionsList from 'pages/index/doctor/client_receptions_list';

type IndexProps = {

}

const headCells : HeadCell<TableClient>[] = [
    {
        id: "name",
        label: "ФИО",
        sort: true,
        render: (client) => (<React.Fragment>{client.name}</React.Fragment>)
    },
    {
        id: "email",
        label: "Email",
        sort: true,
        render: (client) => (<React.Fragment>{client.email}</React.Fragment>)
    },
    {
        id: "phone_number",
        label: "Номер телефона",
        sort: true,
        render: (client) => (<React.Fragment>{client.phone_number}</React.Fragment>)
    },
];

const Index : React.FunctionComponent<IndexProps> = ({}) => {
    // eslint-disable-next-line
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [pagination, setPagination] = usePagination();
    const [order, onSetOrder] = useSorting<TableClient>({orderBy: "name", order: "asc"});
    const [filter, setFilter] = React.useState<FilterType>(defaultFilter);
    const [receptionsOpen, setReceptionsOpen] = React.useState<boolean>(false);
    const [client, setClient] = React.useState<TableClient | undefined>(undefined);
    const operationsArray = React.useMemo<Array<OperationType<TableClient>>>(() => {
        return [
            FiltrationOperation(filter, setFilter),
            CreateOperation(),
            UpdateOperation(),
            DeleteOperation(),
            DeleteSelectedOperation(),
        ];
    }, [filter, setFilter]);
    const operations = useOperations<TableClient>(operationsArray);

    const fixedActions = React.useMemo(() => {
        return [{
            label: "Список приемов",
            enabled: true,
            action: (model: TableClient) => {
                setReceptionsOpen(true);
                setClient(model);
            }
        }, ...operations.actions];
    }, [operations])

    const { data, loading, error } = useClientsIndexSubscription({variables: { offset: pagination.offset, limit: pagination.limit, order_by: { [order.orderBy]: order.order, }, where: TranslateFiltrationToWhere(filter)}});
    const countResult = useClientsIndexCountSubscription({variables: { where: TranslateFiltrationToWhere(filter) }});

    const models : TableClient[] | undefined = (data === undefined) ? undefined : data.Client;

    var count = React.useMemo<number>(() => {
        if ((countResult.data !== undefined) && (countResult.data.Client_aggregate !== undefined) && (countResult.data.Client_aggregate.aggregate !== undefined) && (countResult.data.Client_aggregate.aggregate !== null))
            return countResult.data.Client_aggregate.aggregate.count;
        
        return 0;
    }, [countResult]);

    React.useMemo(() => {
        if (error !== undefined)
        {
            enqueueSnackbar(`Ошибка получения данных! ${error.message}`, {variant: 'error'});
        }
        else if (countResult.error !== undefined)
        {
            enqueueSnackbar(`Ошибка получения данных! ${countResult.error.message}`, {variant: 'error'});
        }
    }, [error, enqueueSnackbar, countResult]);
    

    return (
        <React.Fragment>
            <Title title='Клиенты'/>
            <Loading loading={loading || countResult.loading} />
            <h1>Клиенты</h1>
            <HexTable {...pagination} order={order.order} orderBy={order.orderBy as keyof TableClient} 
                      count={count} onPageChange={setPagination} models={models} headCells={headCells} onOrderSet={onSetOrder}
                      globalActions={operations.globalActions} selectedActions={operations.selectedActions} actions={fixedActions}
            /> 
            <Operations args={operationsArray} eventIndex={operations.eventIndex} model={operations.model} selectedModels={operations.selectedModels} close={operations.close} />
            {receptionsOpen && client && <ClientReceptionsList client_id={client.id} onClose={() => { setReceptionsOpen(false); setClient(undefined)}} />}
        </React.Fragment>
    )
}

export default Index;