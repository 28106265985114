import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { ActionType, getModelId, GlobalActionType, HeadCell, ModelType, Order, SelectedActionType } from './types';
import EnhancedTableToolbar from './toolbar';
import EnhancedTableHead from './table_head';
import Skeleton from '@mui/material/Skeleton';
import { IconButton, Tooltip } from '@mui/material';
import ItemMenu from './item_menu';

// Props:
export type EnhancedTableData<TModel extends ModelType> =
{
    models: TModel[] | undefined;
    orderBy: keyof TModel;
    order: Order;
    onOrderSet: (field: keyof TModel, order: Order) => void;
    headCells: HeadCell<TModel>[];
    globalActions?: Array<GlobalActionType>;
    actions?: Array<ActionType<TModel>>;
    selectedActions?: Array<SelectedActionType<TModel>>;
}

export type EnhancedTableProps<TModel extends ModelType> =
{
    limit: number;
    emptyRows: number;
    count: number;
} & EnhancedTableData<TModel>;

export default function EnhancedTable<TModel extends ModelType>(props: EnhancedTableProps<TModel>) {
    const {models, orderBy, order, onOrderSet, emptyRows, headCells, limit, count, globalActions, actions, selectedActions} = props;
    const [selected, setSelected] = React.useState<any[]>([]);

    let rowsCount = (limit > count) ? limit : count;

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof TModel) => {
        const isAsc = orderBy === property && order === 'asc';
        onOrderSet(property, isAsc ? 'desc' : 'asc');
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if ((event.target.checked) && (models !== undefined)) {
            if (selected.length !== 0)
            {
                setSelected([]);
                return;
            }
            else
            {
                const newSelected = models.map((model) => getModelId(model));
                setSelected(newSelected);
                return;
            }
        } 

        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: any) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: any[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const isSelected = (name: any) => selected.indexOf(name) !== -1;

    const renderSelectedBtns = () => {
        if ((selectedActions === undefined) || (models === undefined))
        {
            return <></>
        }

        return selectedActions.map((action) => {
            return (
                <Tooltip title={action.label} key={action.label}>
                    <IconButton aria-label={action.label} onClick={() => {
                        const selectedModels = models.filter(model => selected.indexOf(getModelId(model)) !== -1);
                        action.action(selectedModels);
                    }}>
                        {action.icon}
                    </IconButton>
                </Tooltip>
            );
        })
    }

    const rows = React.useMemo(() => {
        if (models !== undefined)
        {
            return models.map((row, index) => {
                const isItemSelected = isSelected(getModelId(row));
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                    <TableRow hover onClick={(event) => handleClick(event, getModelId(row))} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={getModelId(row)} selected={isItemSelected}>
                        <TableCell key={`cell-zero`} padding="checkbox">
                            <Checkbox color="primary" checked={isItemSelected} inputProps={{'aria-labelledby': labelId, }}/>
                        </TableCell>
                        {
                            actions &&
                            <TableCell key='actions'>
                                <ItemMenu actions={actions} model={row} />
                            </TableCell>
                        }
                        {headCells.map((cell, index) => {
                            if (index === 0)
                            {
                                return (
                                    <TableCell key={cell.id as string} component="th" id={labelId} scope="row" padding="none">
                                        {cell.render(row)}
                                    </TableCell>
                                );
                            }

                            return (<TableCell key={cell.id as string} align="left">{cell.render(row)}</TableCell>);
                        })}
                    </TableRow>
                );
            });
        }
        else
        {
            return [...Array(rowsCount)].map((x, i) =>
                    <TableRow hover role="checkbox" tabIndex={-1} key={`row-${i}`}>
                        <TableCell key={`cell-zero`} padding="checkbox">
                            <Skeleton key={i} variant="rectangular" />
                        </TableCell>
                        {
                            actions &&
                            <TableCell key={`cell-actions-${i}`}>
                                <Skeleton key={i} variant="rectangular" />
                            </TableCell>
                        }
                        {headCells.map((cell, index) => {
                            if (index === 0)
                            {
                                return (
                                    <TableCell key={cell.id as string}  component="th" scope="row" padding="none">
                                        <Skeleton key={`${i}-${index}`} variant="rectangular" />
                                    </TableCell>
                                );
                            }

                            return (<TableCell key={cell.id as string} align="left"><Skeleton key={`${i}-${index}`} variant="rectangular" /></TableCell>);
                        })}
                    </TableRow>
              )
        }
    }, [models, count, headCells, limit, selected, handleClick, isSelected, rowsCount, count, limit, actions]);

    // Для снятия флажков при изменении модели
    React.useMemo(() => {
        setSelected((oldSelected) => {
            if (models !== undefined)
                return oldSelected.filter(selectedId => models.findIndex((model) => getModelId(model) === selectedId) !== -1)
            else 
                return [];
        });
    }, [models, setSelected]);

    return (
        <React.Fragment>
            <EnhancedTableToolbar numSelected={selected.length} globalActions={globalActions} selectedButtons={renderSelectedBtns()} />
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='medium'>
                    <EnhancedTableHead headCells={headCells} numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={rowsCount}  actions={actions !== undefined} />
                    <TableBody>
                        {
                            rows
                        }
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
}