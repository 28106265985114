import React from "react";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export enum HistoryVariants {
    STANDART = "standart",
    GROUP_BY_DOCTORS = "gdoctors",
    STEPPER = "stepper"
};

export function TranslateName(variant: HistoryVariants)
{
    switch (variant) {
        case HistoryVariants.STANDART: return "Стандартное";
        case HistoryVariants.GROUP_BY_DOCTORS: return "Группировка по врачам";
        case HistoryVariants.STEPPER: return "Последовательное";
        default:
            return "<Неопределено>";
    }
}

type HistoryVariantProps = {
    variant: HistoryVariants;
    setVariant: (variant: HistoryVariants) => void;
};


const HistoryVariant : React.FC<HistoryVariantProps> = ({variant, setVariant}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVariant((event.target as HTMLInputElement).value as HistoryVariants);
    };

    return (
        <FormControl>
          <FormLabel id="history-variant">Отображение</FormLabel>
          <RadioGroup
            aria-labelledby="history-variant"
            value={variant}
            onChange={handleChange}
          >
            {Object.values(HistoryVariants).filter(k => !Number.isNaN(k)).map((value) => (
                <FormControlLabel value={value} control={<Radio />} label={TranslateName(value as HistoryVariants)} />
            ))}
          </RadioGroup>
        </FormControl>
      );
}

export default HistoryVariant;