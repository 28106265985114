import React from 'react';
import { ModelType } from '../table/types';
import Modal from 'hex/components/material/modal'
import { ApolloCache, ApolloError, DefaultContext, MutationTuple, OperationVariables } from '@apollo/client';
import Loading from 'hex/components/loading';
import { useValidator, ValidationResultData, Validations, ValidatorCallback } from 'hex/hooks/validator';
import Alert from '@mui/material/Alert';
import OperationNotifier from './operation_notifier';


// Props:
type CreateProps<TModel extends ModelType, TData = any, TVariables = OperationVariables, TContext = DefaultContext, TCache extends ApolloCache<any> = ApolloCache<any>> = {
    defaultModel: TModel;
    renderFields: (model: TModel, setFieldValue: (field: keyof TModel) => (value: any) => void, validator: ValidatorCallback<TModel>, validation: ValidationResultData<TModel>) => React.ReactNode;
    useMutation: (model: TModel) => MutationTuple<TData, TVariables, TContext, TCache>;
    validations: Validations<TModel>;
    translateError: (error: ApolloError) => string;
    close: () => void;
    prefillDefaultModel?: (model: TModel) => TModel;
    operationNotifier?: OperationNotifier<TModel>;
};


function Create<TModel extends ModelType>(props: CreateProps<TModel>)
{
    var {defaultModel, renderFields, useMutation, validations, close, translateError, prefillDefaultModel, operationNotifier} = props;

    const [model, setModel] = React.useState<TModel>(prefillDefaultModel ? prefillDefaultModel(defaultModel) : defaultModel);
    const [createMutation, { data, loading, error }] = useMutation(model);
    const [validation, validator, modelValidation] = useValidator<TModel>(validations);

    const setModelField = React.useCallback((field: keyof TModel) => (value: any) => {
        setModel(oldModel => {
            var copy = {...oldModel};

            copy[field] = value;

            return copy;
        });
    }, [setModel]);

    const onCreate = () => {
        if (modelValidation(model))
        {
            if (operationNotifier) operationNotifier.CallAllOperationNotifierBeforeOperation(model);
            
            createMutation().then((data) => {
                if (operationNotifier) operationNotifier.CallAllOperationNotifierAfterOperation(model, data);
                setModel(defaultModel);
                close();
            });
        }
    }

    return (
        <>
            <Loading loading={loading} />
            <Modal open={true} title='Добавить объект' contentTitle='Создание объекта' closeButtonText='Отмена' closeButtonAction={() => {setModel(defaultModel); close();}} actionButtonText='Создать' actionButtonAction={onCreate}>
                {error !== undefined && (
                    <Alert severity="error">{translateError(error)}</Alert>
                )}
                {renderFields(model, setModelField, validator, validation)}
            </Modal>
        </>
    );
};

export default Create;